.PCOM {
  .payment-container {
    border-radius: 4px;
    border: 1px solid var(--ui-elements-border-02, #878d99);
    background: var(--ui-elements-ui-02, #fefefe);
    font-family: var(--typefamily--primary-stack);
    padding: 20px;
    cursor: pointer;

    &.disabled {
      opacity: .25;
      cursor: auto;
      pointer-events: none;
    }

    .radio-container {
      display: block;
      padding: 0;

      input[type="radio"] {
        display: none;
      }

      label {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        // justify-content: space-between;
        cursor: pointer;

        p {
          color: var(--color-text-secondary, #333);
          font-size: 1rem;
          font-weight: 600;
          flex-grow: 1;
        }
      }
      &:hover{
        .radio-icon-unchecked {
          box-shadow: 0 0 0 8px #e6e6e6;
        }
        .radio-icon-checked  {
          box-shadow: 0 0 0 8px #e6e6e6;
        }
      }
      &:active, &:focus {
        .radio-icon-unchecked {
          color: #151515;
          outline-offset: 5px;
          outline: 3px solid #2192fb;
          box-shadow: none;
      }
      .radio-icon-checked  {
        outline-offset:5px;
        outline: 3px solid #2192fb;
        box-shadow: none;
      }
    }
  }

    .description {
      display: none;
    }
    &:has(input:checked) .description {
      display: block;
    }
    &:has(input:checked) {
      border-color: var(--ui-elements-ui-03, #05112a);
    }
  }

  .credit-card-inputs {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    padding-bottom: 30px;
    border-bottom: 1px solid #dbdbdb;
  }

  .billing-inputs {
    display: flex;
    flex-direction: column;
    gap:30px;
    margin-top: 30px;
    h2 {
      color: #000;
      font-size: 1.125rem;
      font-weight: 600;
    }
  }
  p.legal-policy {
    color: #333;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 20px;
    span {
      color: var(--color-text-link, #007a9c);
      font-weight: 600;
    }
  }

  .paypal {
    margin-top: 20px;
  }

  .apple-modal {
    z-index: 1000;
    position: fixed;
    padding: 24px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(21, 21, 21, 0.6);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: scroll;
    max-height: 100vh;

    .modal-content {
      display: block;
      width: 409px;
      padding: 24px 24px 20px 24px !important;
      flex-direction: column;
      align-items: center;
      border-radius: 16px;
      border: 1px solid #cac9c9;
      background: #eaeaea;
      box-shadow: 4px 8px 32px 0px rgba(0, 0, 0, 0.5);
      z-index: 99;

      .headline {
        margin: 0;
        padding: 0;
        color: #000;
        font-family: "SF Compact Display", sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -1.05px;

        + .regular-para {
          margin-top: 3px;
        }

        + .note {
          margin-top: 3px;
        }
      }

      .regular-para {
        margin: 0;
        padding: 0;
        color: #717171;
        font-family: "SF Compact Display", sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        letter-spacing: -0.91px;

        &.single-line {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        + .headline {
          margin-top: 4px;
        }

        + .regular-para {
          margin-top: 2px;
        }
      }

      button {
        margin: 0;
        padding: 0;
        display: block;
        appearance: none;
        background: transparent;
        border: none;
        align-self: center;

        img {
          display: block;
        }

        .icon-info,
        .icon-edit {
          width: 16px;
          height: auto;
        }

        &.touchid {
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          gap: 8px;
          align-items: center;

          color: #000;
          text-align: center;
          font-family: "SF Compact Display";
          font-size: 14px;
          font-style: normal;
          font-weight: 350;
          line-height: 18px; /* 128.571% */
          letter-spacing: 0.4px;

          img {
            width: 37px;
            height: auto;
          }
        }
      }

      .header {
        margin: 0 0 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        img {
          height: 22px;
          width: auto;
        }

        button {
          border-radius: 4px;
          border: none;
          background: #d2d2d2;
          padding: 4px 8px;
          justify-content: center;
          align-items: center;
          color: #5b5b5b;
          font-family: "SF Compact Display", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 1;
          letter-spacing: -0.6px;
        }
      }

      .body {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
      }

      .group {
        margin: 0;
        padding: 16px;
        background-color: #ffffff;
        border-radius: 12px;

        &.card-info {
          padding-top: 14px;
          padding-bottom: 14px;
          display: flex;
          flex-direction: row;
          gap: 15px;
          align-items: flex-start;

          img {
            width: 37px;
            height: auto;
          }
        }

        &.bill-details {
          padding-top: 14px;
          display: flex;
          flex-direction: column;
          gap: 12px;

          hr {
            margin: 0;
            padding: 0;
            height: 0;
            border: 0;
            border-top: 1px solid #e8eefa;
          }

          .bill-details-header {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: flex-start;

            .biller-logo {
              height: 32px;
              width: auto;
            }

            > div {
              flex-grow: 1;
            }
          }

          .item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;

            p {
              display: flex;
              flex-direction: column;
              gap: 4px;

              &.price {
                text-align: right;
              }
            }

            strong,
            span {
              display: block;
            }

            strong {
              color: #000;
              font-family: "SF Compact Display";
              font-size: 15px;
              font-style: normal;
              font-weight: 500;
              line-height: 1;
              letter-spacing: -1.05px;
            }
          }
        }

        .note {
          ul {
            margin: 0;
            padding: 0;
          }

          li {
            list-style-type: disc;
            list-style-position: inside;
          }

          p,
          li {
            margin: 0;
            padding: 0;
            color: #717171;
            font-family: "SF Compact Display";
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 138.462% */
            letter-spacing: 0;
          }
        }

        &.contact {
          display: flex;
          flex-direction: row;
          gap: 15px;
          align-items: flex-start;

          div {
            flex-grow: 1;
          }

          .icon-contact {
            height: 32px;
            width: auto;
          }
        }
      }

      .pay-total {
        margin: 42px 0 20px;
        border-bottom: 1px solid #d3d3d3;

        .who {
          margin: 0 0 8px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;

          p {
            margin: 0;
            padding: 0;
            color: #717171;
            font-family: "SF Compact Display", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 1;
            letter-spacing: -0.6px;
          }

          button {
            margin: 2px 14px 0 0;
            display: block;
          }
        }

        .big-price {
          margin: 0;
          padding: 0 0 8px;

          color: #000;
          font-family: "SF Compact Rounded";
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 1;
          letter-spacing: -1.6px;
        }
      }

      .payee {
        margin: 0 0 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;

        .biller-logo {
          width: 40px;
          height: auto;
        }

        .payee-name {
          color: #000;
          text-align: center;
          font-family: "SF Compact Display";
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 1;
          letter-spacing: -1.4px;
        }
      }

      .done {
        margin-top: 44px;
        display: flex;
        align-items: flex-end;

        button {
          margin: 0 0 0 auto;
          padding: 6px 8px;
          display: block;
          border-radius: 4px;
          background-color: #fff;
          color: #5b5b5b;

          text-align: center;
          font-family: "SF Compact Display";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 1;
          letter-spacing: -0.6px;
        }
      }
    }
  }
}
