:root {
  --he-scrollPaddingTop: calc(var(--he-header-min-h) - -90px)
}

html {
  font-family: unset;
  line-height: inherit;
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-tap-highlight-color: transparent
}

@media(prefers-reduced-motion:no-preference) {
  html {
    scroll-behavior: smooth;
    scroll-padding-top: var(--he-scrollPaddingTop);
    overflow-y: auto
  }
}

body {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-default);
  font-style: var(--typestyle-normal);
  font-weight: var(--typeweight--default);
  font-synthesis: none;
  line-height: inherit;
  text-rendering: optimizeLegibility;
  text-align: unset;
  -webkit-font-feature-settings: "lnum" 1;
  font-feature-settings: "lnum" 1;
  color: var(--color--text-secondary);
  direction: ltr;
  overflow-x: hidden;
  background-color: var(--color--ui-background-light, var(--color--background-light));
  opacity: 1;
  position: relative;
  padding: 0;
  margin: 0
}

body,
main {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-width: 100vw
}

main {
  flex: 1 0 auto
}

img {
  display: block;
  max-width: 100%;
  height: auto
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
blockquote,
table {
  margin: 0
}

@media(min-width:769px) {

  *+h1,
  *+h2,
  *+h3,
  *+h4,
  *+h5,
  *+h6,
  *+p,
  *+ul,
  *+ol,
  *+blockquote,
  *+table {
    margin-top: var(--space-small)
  }
}

@media(max-width:768px) {

  *+h1,
  *+h2,
  *+h3,
  *+h4,
  *+h5,
  *+h6,
  *+p,
  *+ul,
  *+ol,
  *+blockquote,
  *+table {
    margin-top: var(--space-xsmall)
  }
}

em,
i {
  font-style: italic
}

@supports(font-variation-settings:normal) {

  em,
  i {
    font-family: var(--typefamily-primary-italic) !important
  }
}

b,
strong {
  font-weight: var(--typeweight--semibold)
}

@supports(font-variation-settings:normal) {

  b,
  strong {
    font-weight: var(--typeweight--semibold-plus) !important
  }
}

*:focus:not(:focus-visible) {
  outline: 0
}

.container {
  --grid-margin-sm: 30px;
  --grid-margin-md: 55px;
  --grid-margin-lg: 75px;
  max-width: 100%
}

@media(max-width:768px) {
  .container {
    padding-inline-end: var(--grid-margin-sm);
    padding-inline-start: var(--grid-margin-sm)
  }
}

@media(min-width:769px) and (max-width:1589px) {
  .container {
    padding-inline-end: var(--grid-margin-md);
    padding-inline-start: var(--grid-margin-md)
  }
}

@media(min-width:1590px) {
  .container {
    padding-inline-end: var(--grid-margin-lg);
    padding-inline-start: var(--grid-margin-lg);
    max-width: 1590px
  }
}

@media(max-width:767px) {
  main .row {
    row-gap: var(--space-small)
  }
}

@media(min-width:768px) {
  main .row {
    row-gap: var(--space-medium)
  }
}

@media(max-width:767px) {
  main .col-12:empty {
    display: none
  }
}

.h1-plus,
.h1-plus * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h1-plus);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-15);
  margin-bottom: 0
}

h1,
.h1,
.h1 * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h1);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-15);
  margin-bottom: 0
}

.typefamily-primary h1,
.typefamily-primary .h1,
.typefamily-primary .h1 * {
  font-weight: var(--typeweight--light)
}

.h1-catalog,
.h1-catalog * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h1-catalog);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-15);
  margin-bottom: 0
}

h2,
.h2,
.h2 * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h2);
  font-weight: var(--typeweight--light);
  line-height: var(--leading--1-3);
  margin-bottom: 0
}

h3,
.h3,
.h3 * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h3);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

h4,
.h4,
.h4 * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-h4);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

h5,
.h5,
.h5 * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-h5);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

h6,
.h6,
.h6 * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

main h1,
main h2,
main h4,
main h5,
main h6,
main .h1,
main .h2,
main .h4,
main .h5,
main .h6,
main .exa,
main .peta,
main .tera,
main .giga,
main .mega,
main .kilo {
  color: var(--color--text-primary)
}

main h1 *:not(a[href], a[href] *, button),
main h2 *:not(a[href], a[href] *, button),
main h4 *:not(a[href], a[href] *, button),
main h5 *:not(a[href], a[href] *, button),
main h6 *:not(a[href], a[href] *, button),
main .h1 *:not(a[href], a[href] *, button),
main .h2 *:not(a[href], a[href] *, button),
main .h4 *:not(a[href], a[href] *, button),
main .h5 *:not(a[href], a[href] *, button),
main .h6 *:not(a[href], a[href] *, button),
main .exa *:not(a[href], a[href] *, button),
main .peta *:not(a[href], a[href] *, button),
main .tera *:not(a[href], a[href] *, button),
main .giga *:not(a[href], a[href] *, button),
main .mega *:not(a[href], a[href] *, button),
main .kilo *:not(a[href], a[href] *, button) {
  color: var(--color--text-primary)
}

main h3,
main .h3 {
  color: var(--color--text-secondary)
}

main h3 *:not(a[href], a[href] *, button),
main .h3 *:not(a[href], a[href] *, button) {
  color: var(--color--text-secondary)
}

p,
.p,
.p *,
blockquote,
figcaption,
cite,
dl,
ol,
ul {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-p);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default)
}

cite {
  font-style: normal;
  font-weight: inherit;
  display: inline-block
}

.exa,
.exa * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-exa);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-1)
}

.peta,
.peta * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-peta);
  font-weight: var(--typeweight--light);
  line-height: var(--leading--1-2)
}

.tera,
.tera * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-tera);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2)
}

.giga,
.giga * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-giga);
  font-weight: var(--typeweight--light);
  line-height: var(--leading--1-2)
}

.mega,
.mega * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-mega);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-2)
}

.kilo,
.kilo * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-kilo);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2)
}

.minor,
.minor * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-minor);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default)
}

.milli,
.milli * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-milli);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default)
}

.micro,
.micro * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-micro);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default)
}

a {
  font-weight: var(--typeweight--semibold);
  text-decoration: none;
  color: var(--color--text-link)
}

a:hover {
  color: var(--color--text-link-hover);
  text-decoration: underline;
  text-decoration-thickness: .0825em;
  text-underline-offset: .125em
}

a:active {
  color: var(--color--text-link-active)
}

a:focus,
a:focus-visible {
  color: var(--color--text-link-focus);
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--color--interactive-focus-field);
  text-decoration: none
}

a:not([href]):not([tabindex]) {
  color: var(--color--text-link);
  font-weight: var(--typeweight--bold);
  text-decoration: none;
  transition: all .2s ease-in-out
}

a:not([href]):not([tabindex]):hover {
  color: var(--color--text-link-hover);
  text-decoration: underline
}

a:not([href]):not([tabindex]):active {
  color: var(--color--text-link-active)
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):focus-visible {
  color: var(--color--text-link-focus);
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--color--interactive-focus-field);
  text-decoration: none;
  border: unset
}

.content-tile[class*="color--text"][class*="-inverse"] a,
.text[class*="color--text"][class*="-inverse"] a,
.content-tile.color--text-link-inverse a,
.text.color--text-link-inverse a {
  color: var(--color--text-link-inverse)
}

.content-tile[class*="color--text"][class*="-inverse"] a:hover,
.text[class*="color--text"][class*="-inverse"] a:hover,
.content-tile.color--text-link-inverse a:hover,
.text.color--text-link-inverse a:hover,
.content-tile[class*="color--text"][class*="-inverse"] a:hover *,
.text[class*="color--text"][class*="-inverse"] a:hover *,
.content-tile.color--text-link-inverse a:hover *,
.text.color--text-link-inverse a:hover * {
  color: var(--color--text-link-inverse-hover, var(--color--text-link-hover-inverse))
}

.content-tile[class*="color--text"][class*="-inverse"] a:active,
.text[class*="color--text"][class*="-inverse"] a:active,
.content-tile.color--text-link-inverse a:active,
.text.color--text-link-inverse a:active,
.content-tile[class*="color--text"][class*="-inverse"] a:active *,
.text[class*="color--text"][class*="-inverse"] a:active *,
.content-tile.color--text-link-inverse a:active *,
.text.color--text-link-inverse a:active * {
  color: var(--color--text-link-inverse-active, var(--color--text-link-active-inverse))
}

.content-tile[class*="color--text"][class*="-inverse"] a:focus,
.text[class*="color--text"][class*="-inverse"] a:focus,
.content-tile.color--text-link-inverse a:focus,
.text.color--text-link-inverse a:focus,
.content-tile[class*="color--text"][class*="-inverse"] a:focus *,
.text[class*="color--text"][class*="-inverse"] a:focus *,
.content-tile.color--text-link-inverse a:focus *,
.text.color--text-link-inverse a:focus *,
.content-tile[class*="color--text"][class*="-inverse"] a:focus-visible,
.text[class*="color--text"][class*="-inverse"] a:focus-visible,
.content-tile.color--text-link-inverse a:focus-visible,
.text.color--text-link-inverse a:focus-visible,
.content-tile[class*="color--text"][class*="-inverse"] a:focus-visible,
.text[class*="color--text"][class*="-inverse"] a:focus-visible,
.content-tile.color--text-link-inverse a:focus-visible,
.text.color--text-link-inverse a:focus-visible {
  color: var(--color--text-link-inverse-hover, var(--color--text-link-hover-inverse))
}

a.color--text-link-inverse {
  color: var(--color--text-link-inverse)
}

a.color--text-link-inverse:hover,
a.color--text-link-inverse:hover * {
  color: var(--color--text-link-inverse-hover, var(--color--text-link-hover-inverse))
}

a.color--text-link-inverse:active,
a.color--text-link-inverse:active * {
  color: var(--color--text-link-inverse-active, var(--color--text-link-active-inverse))
}

a.color--text-link-inverse:focus,
a.color--text-link-inverse:focus *,
a.color--text-link-inverse:focus-visible,
a.color--text-link-inverse:focus-visible * {
  color: var(--color--text-link-inverse-hover, var(--color--text-link-hover-inverse))
}

.form-control:not([type="radio"], [type="checkbox"], [type="submit"], [type="file"]) {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  color: var(--color--text-secondary);
  background-color: var(--color--ui-field, var(--color--field));
  border: 0;
  border-radius: .25em;
  outline-width: .0625em;
  outline-style: solid;
  outline-color: var(--color--ui-border-03, var(--color--border-03));
  display: block;
  height: 2.9375em;
  width: 100%;
  padding: .75em .8125em;
  margin: 0;
  transition: all .1125s ease-in-out
}

.form-control:not([type="radio"], [type="checkbox"], [type="submit"], [type="file"])::-webkit-input-placeholder,
.form-control:not([type="radio"], [type="checkbox"], [type="submit"], [type="file"])::placeholder {
  color: var(--color--text-secondary)
}

.form-control:not([type="radio"], [type="checkbox"], [type="submit"], [type="file"]):not([disabled]):hover {
  outline-color: var(--color--ui-border-02, var(--color--border-02))
}

.form-control:not([type="radio"], [type="checkbox"], [type="submit"], [type="file"]):not([disabled]):active,
.form-control:not([type="radio"], [type="checkbox"], [type="submit"], [type="file"]):not([disabled]):focus,
.form-control:not([type="radio"], [type="checkbox"], [type="submit"], [type="file"]):not([disabled]):focus-visible {
  background-color: var(--color--ui-field, var(--color--field));
  outline-color: var(--color--interactive-focus-field);
  outline-width: .1875em;
  outline-offset: -0.125em;
  -webkit-box-shadow: unset;
  -box-shadow: unset
}

.form-control:not([type="radio"], [type="checkbox"], [type="submit"], [type="file"])[disabled] {
  color: var(--color--interactive-disabled-02);
  background-color: var(--color--ui-01);
  outline-color: var(--color--interactive-disabled-02);
  pointer-events: none
}

.form-control:not([type="radio"], [type="checkbox"], [type="submit"], [type="file"])[disabled]::-webkit-input-placeholder,
.form-control:not([type="radio"], [type="checkbox"], [type="submit"], [type="file"])[disabled]::placeholder {
  color: var(--color--interactive-disabled-02)
}

.form-inverse .form-control:not([type="radio"], [type="checkbox"], [type="submit"], [type="file"])[disabled] {
  color: var(--color--interactive-disabled-01);
  background-color: var(--color--interactive-disabled-02)
}

.form-inverse .form-control:not([type="radio"], [type="checkbox"], [type="submit"], [type="file"])[disabled]::-webkit-input-placeholder,
.form-inverse .form-control:not([type="radio"], [type="checkbox"], [type="submit"], [type="file"])[disabled]::placeholder {
  color: var(--color--interactive-disabled-01)
}

.form-control[type="number"] {
  text-align: center;
  padding-right: .5em;
  max-width: 6rem
}

.form-control[type="file"] {
  font-family: var(--typefamily-primary);
  line-height: var(--leading--1-2);
  min-height: unset;
  height: unset;
  max-width: 100%;
  padding: var(--space-xxxsmall) var(--space-zero) var(--space-xxxsmall) 1px;
  background-color: transparent;
  border: unset;
  outline-color: transparent;
  -webkit-box-shadow: unset;
  -box-shadow: unset;
  transition: all .125s ease-in-out
}

.form-control[type="file"]:focus-visible {
  border: unset;
  outline-color: var(--color--interactive-focus-field);
  -webkit-box-shadow: unset;
  -box-shadow: unset
}

.form-inverse .form-control[type="file"] {
  color: var(--color--text-primary-inverse)
}

.form-control[type="file"]::-webkit-file-upload-button,
.form-control[type="file"]::file-selector-button {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary);
  transition: color .125s ease-in-out;
  outline-style: solid;
  outline-width: 2px;
  outline-color: var(--color--interactive-tertiary);
  outline-offset: -2px;
  background-color: transparent;
  border-radius: var(--button-corners);
  transition: all .125s ease-in-out;
  margin: 0 var(--space-xxsmall) 0 0 !important;
  border: none !important
}

@media(min-width:376px) {

  .form-control[type="file"]::-webkit-file-upload-button,
  .form-control[type="file"]::file-selector-button {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {

  .form-control[type="file"]::-webkit-file-upload-button,
  .form-control[type="file"]::file-selector-button {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.form-control[type="file"]::-webkit-file-upload-button::before,
.form-control[type="file"]::file-selector-button::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.form-control[type="file"]::-webkit-file-upload-button:hover,
.form-control[type="file"]::file-selector-button:hover,
.form-control[type="file"]::-webkit-file-upload-button:focus,
.form-control[type="file"]::file-selector-button:focus,
.form-control[type="file"]::-webkit-file-upload-button:focus-visible,
.form-control[type="file"]::file-selector-button:focus-visible,
.form-control[type="file"]::-webkit-file-upload-button:active,
.form-control[type="file"]::file-selector-button:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.form-control[type="file"]::-webkit-file-upload-button:hover::before,
.form-control[type="file"]::file-selector-button:hover::before,
.form-control[type="file"]::-webkit-file-upload-button:focus::before,
.form-control[type="file"]::file-selector-button:focus::before,
.form-control[type="file"]::-webkit-file-upload-button:focus-visible::before,
.form-control[type="file"]::file-selector-button:focus-visible::before,
.form-control[type="file"]::-webkit-file-upload-button:active::before,
.form-control[type="file"]::file-selector-button:active::before {
  transform: scale(1.05)
}

.form-control[type="file"]::-webkit-file-upload-button:focus,
.form-control[type="file"]::file-selector-button:focus,
.form-control[type="file"]::-webkit-file-upload-button:focus-visible,
.form-control[type="file"]::file-selector-button:focus-visible {
  outline: 0
}

.form-control[type="file"]::-webkit-file-upload-button:focus::before,
.form-control[type="file"]::file-selector-button:focus::before,
.form-control[type="file"]::-webkit-file-upload-button:focus-visible::before,
.form-control[type="file"]::file-selector-button:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.form-control[type="file"]::-webkit-file-upload-button:active::before,
.form-control[type="file"]::file-selector-button:active::before {
  outline-color: transparent
}

.form-control[type="file"]::-webkit-file-upload-button:only-of-type,
.form-control[type="file"]::file-selector-button:only-of-type {
  margin: 0
}

.form-control[type="file"]::-webkit-file-upload-button.icon-only,
.form-control[type="file"]::file-selector-button.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.form-control[type="file"]::-webkit-file-upload-button::before,
.form-control[type="file"]::file-selector-button::before {
  outline-color: var(--color--interactive-tertiary);
  outline-offset: -2px;
  background-color: transparent
}

.form-control[type="file"]::-webkit-file-upload-button:hover,
.form-control[type="file"]::file-selector-button:hover {
  color: var(--color--text-primary-inverse)
}

.form-control[type="file"]::-webkit-file-upload-button:hover::before,
.form-control[type="file"]::file-selector-button:hover::before {
  outline-color: var(--color--interactive-tertiary-hover);
  background: var(--color--interactive-tertiary-hover)
}

.form-control[type="file"]::-webkit-file-upload-button:focus,
.form-control[type="file"]::file-selector-button:focus,
.form-control[type="file"]::-webkit-file-upload-button:focus-visible,
.form-control[type="file"]::file-selector-button:focus-visible {
  color: var(--color--text-tertiary)
}

.form-control[type="file"]::-webkit-file-upload-button:focus::before,
.form-control[type="file"]::file-selector-button:focus::before,
.form-control[type="file"]::-webkit-file-upload-button:focus-visible::before,
.form-control[type="file"]::file-selector-button:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

.form-control[type="file"]::-webkit-file-upload-button:active,
.form-control[type="file"]::file-selector-button:active {
  color: var(--color--text-primary-inverse)
}

.form-control[type="file"]::-webkit-file-upload-button:active::before,
.form-control[type="file"]::file-selector-button:active::before {
  background: var(--color--interactive-tertiary-active)
}

.form-control[type="file"]::-webkit-file-upload-button[disabled],
.form-control[type="file"]::file-selector-button[disabled],
.form-control[type="file"]::-webkit-file-upload-button.button-disabled,
.form-control[type="file"]::file-selector-button.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.form-control[type="file"]::-webkit-file-upload-button[disabled]::before,
.form-control[type="file"]::file-selector-button[disabled]::before,
.form-control[type="file"]::-webkit-file-upload-button.button-disabled::before,
.form-control[type="file"]::file-selector-button.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.form-control[type="file"]::-webkit-file-upload-button[disabled]:hover::before,
.form-control[type="file"]::file-selector-button[disabled]:hover::before,
.form-control[type="file"]::-webkit-file-upload-button.button-disabled:hover::before,
.form-control[type="file"]::file-selector-button.button-disabled:hover::before,
.form-control[type="file"]::-webkit-file-upload-button[disabled]:focus::before,
.form-control[type="file"]::file-selector-button[disabled]:focus::before,
.form-control[type="file"]::-webkit-file-upload-button.button-disabled:focus::before,
.form-control[type="file"]::file-selector-button.button-disabled:focus::before,
.form-control[type="file"]::-webkit-file-upload-button[disabled]:focus-visible::before,
.form-control[type="file"]::file-selector-button[disabled]:focus-visible::before,
.form-control[type="file"]::-webkit-file-upload-button.button-disabled:focus-visible::before,
.form-control[type="file"]::file-selector-button.button-disabled:focus-visible::before,
.form-control[type="file"]::-webkit-file-upload-button[disabled]:active::before,
.form-control[type="file"]::file-selector-button[disabled]:active::before,
.form-control[type="file"]::-webkit-file-upload-button.button-disabled:active::before,
.form-control[type="file"]::file-selector-button.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.form-control[type="file"]::-webkit-file-upload-button[disabled]::before,
.form-control[type="file"]::file-selector-button[disabled]::before,
.form-control[type="file"]::-webkit-file-upload-button.button-disabled::before,
.form-control[type="file"]::file-selector-button.button-disabled::before {
  outline-color: var(--color--interactive-disabled-01);
  outline-offset: -2px;
  background: transparent
}

.form-control[type="file"]::-webkit-file-upload-button[disabled]:hover::before,
.form-control[type="file"]::file-selector-button[disabled]:hover::before,
.form-control[type="file"]::-webkit-file-upload-button.button-disabled:hover::before,
.form-control[type="file"]::file-selector-button.button-disabled:hover::before,
.form-control[type="file"]::-webkit-file-upload-button[disabled]:focus::before,
.form-control[type="file"]::file-selector-button[disabled]:focus::before,
.form-control[type="file"]::-webkit-file-upload-button.button-disabled:focus::before,
.form-control[type="file"]::file-selector-button.button-disabled:focus::before,
.form-control[type="file"]::-webkit-file-upload-button[disabled]:focus-visible::before,
.form-control[type="file"]::file-selector-button[disabled]:focus-visible::before,
.form-control[type="file"]::-webkit-file-upload-button.button-disabled:focus-visible::before,
.form-control[type="file"]::file-selector-button.button-disabled:focus-visible::before,
.form-control[type="file"]::-webkit-file-upload-button[disabled]:active::before,
.form-control[type="file"]::file-selector-button[disabled]:active::before,
.form-control[type="file"]::-webkit-file-upload-button.button-disabled:active::before,
.form-control[type="file"]::file-selector-button.button-disabled:active::before {
  background: transparent
}

.form-control[type="file"]::-webkit-file-upload-button:hover,
.form-control[type="file"]::file-selector-button:hover {
  color: var(--color--text-primary-inverse);
  outline-color: var(--color--interactive-tertiary-hover);
  background: var(--color--interactive-tertiary-hover)
}

.form-control[type="file"]::-webkit-file-upload-button:focus,
.form-control[type="file"]::file-selector-button:focus,
.form-control[type="file"]::-webkit-file-upload-button:focus-visible,
.form-control[type="file"]::file-selector-button:focus-visible {
  color: var(--color--text-tertiary);
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--color--interactive-focus);
  background: transparent
}

.form-control[type="file"]::-webkit-file-upload-button:active,
.form-control[type="file"]::file-selector-button:active {
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-tertiary-active)
}

.form-inverse .form-control[type="file"]::-webkit-file-upload-button,
.form-inverse .form-control[type="file"]::file-selector-button {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary-inverse);
  outline-color: var(--color--interactive-tertiary-inverse)
}

@media(min-width:376px) {

  .form-inverse .form-control[type="file"]::-webkit-file-upload-button,
  .form-inverse .form-control[type="file"]::file-selector-button {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {

  .form-inverse .form-control[type="file"]::-webkit-file-upload-button,
  .form-inverse .form-control[type="file"]::file-selector-button {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.form-inverse .form-control[type="file"]::-webkit-file-upload-button::before,
.form-inverse .form-control[type="file"]::file-selector-button::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.form-inverse .form-control[type="file"]::-webkit-file-upload-button:hover,
.form-inverse .form-control[type="file"]::file-selector-button:hover,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button:focus,
.form-inverse .form-control[type="file"]::file-selector-button:focus,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button:focus-visible,
.form-inverse .form-control[type="file"]::file-selector-button:focus-visible,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button:active,
.form-inverse .form-control[type="file"]::file-selector-button:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.form-inverse .form-control[type="file"]::-webkit-file-upload-button:hover::before,
.form-inverse .form-control[type="file"]::file-selector-button:hover::before,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button:focus::before,
.form-inverse .form-control[type="file"]::file-selector-button:focus::before,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button:focus-visible::before,
.form-inverse .form-control[type="file"]::file-selector-button:focus-visible::before,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button:active::before,
.form-inverse .form-control[type="file"]::file-selector-button:active::before {
  transform: scale(1.05)
}

.form-inverse .form-control[type="file"]::-webkit-file-upload-button:focus,
.form-inverse .form-control[type="file"]::file-selector-button:focus,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button:focus-visible,
.form-inverse .form-control[type="file"]::file-selector-button:focus-visible {
  outline: 0
}

.form-inverse .form-control[type="file"]::-webkit-file-upload-button:focus::before,
.form-inverse .form-control[type="file"]::file-selector-button:focus::before,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button:focus-visible::before,
.form-inverse .form-control[type="file"]::file-selector-button:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.form-inverse .form-control[type="file"]::-webkit-file-upload-button:active::before,
.form-inverse .form-control[type="file"]::file-selector-button:active::before {
  outline-color: transparent
}

.form-inverse .form-control[type="file"]::-webkit-file-upload-button:only-of-type,
.form-inverse .form-control[type="file"]::file-selector-button:only-of-type {
  margin: 0
}

.form-inverse .form-control[type="file"]::-webkit-file-upload-button.icon-only,
.form-inverse .form-control[type="file"]::file-selector-button.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.form-inverse .form-control[type="file"]::-webkit-file-upload-button::before,
.form-inverse .form-control[type="file"]::file-selector-button::before {
  outline-color: var(--color--interactive-tertiary-inverse);
  outline-offset: -2px;
  background: transparent
}

.form-inverse .form-control[type="file"]::-webkit-file-upload-button:hover,
.form-inverse .form-control[type="file"]::file-selector-button:hover {
  color: var(--color--text-primary)
}

.form-inverse .form-control[type="file"]::-webkit-file-upload-button:hover::before,
.form-inverse .form-control[type="file"]::file-selector-button:hover::before {
  outline-color: transparent;
  background: var(--color--interactive-tertiary-inverse-hover, var(--color--interactive-tertiary-hover-inverse))
}

.form-inverse .form-control[type="file"]::-webkit-file-upload-button:focus,
.form-inverse .form-control[type="file"]::file-selector-button:focus,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button:focus-visible,
.form-inverse .form-control[type="file"]::file-selector-button:focus-visible {
  color: var(--color--text-primary-inverse)
}

.form-inverse .form-control[type="file"]::-webkit-file-upload-button:focus::before,
.form-inverse .form-control[type="file"]::file-selector-button:focus::before,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button:focus-visible::before,
.form-inverse .form-control[type="file"]::file-selector-button:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

.form-inverse .form-control[type="file"]::-webkit-file-upload-button:active,
.form-inverse .form-control[type="file"]::file-selector-button:active {
  color: var(--color--text-primary)
}

.form-inverse .form-control[type="file"]::-webkit-file-upload-button:active::before,
.form-inverse .form-control[type="file"]::file-selector-button:active::before {
  background: var(--color--interactive-tertiary-inverse-active, var(--color--interactive-tertiary-active-inverse))
}

.form-inverse .form-control[type="file"]::-webkit-file-upload-button[disabled],
.form-inverse .form-control[type="file"]::file-selector-button[disabled],
.form-inverse .form-control[type="file"]::-webkit-file-upload-button.button-disabled,
.form-inverse .form-control[type="file"]::file-selector-button.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.form-inverse .form-control[type="file"]::-webkit-file-upload-button[disabled]::before,
.form-inverse .form-control[type="file"]::file-selector-button[disabled]::before,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button.button-disabled::before,
.form-inverse .form-control[type="file"]::file-selector-button.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.form-inverse .form-control[type="file"]::-webkit-file-upload-button[disabled]:hover::before,
.form-inverse .form-control[type="file"]::file-selector-button[disabled]:hover::before,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button.button-disabled:hover::before,
.form-inverse .form-control[type="file"]::file-selector-button.button-disabled:hover::before,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button[disabled]:focus::before,
.form-inverse .form-control[type="file"]::file-selector-button[disabled]:focus::before,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button.button-disabled:focus::before,
.form-inverse .form-control[type="file"]::file-selector-button.button-disabled:focus::before,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button[disabled]:focus-visible::before,
.form-inverse .form-control[type="file"]::file-selector-button[disabled]:focus-visible::before,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button.button-disabled:focus-visible::before,
.form-inverse .form-control[type="file"]::file-selector-button.button-disabled:focus-visible::before,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button[disabled]:active::before,
.form-inverse .form-control[type="file"]::file-selector-button[disabled]:active::before,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button.button-disabled:active::before,
.form-inverse .form-control[type="file"]::file-selector-button.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.form-inverse .form-control[type="file"]::-webkit-file-upload-button[disabled]::before,
.form-inverse .form-control[type="file"]::file-selector-button[disabled]::before,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button.button-disabled::before,
.form-inverse .form-control[type="file"]::file-selector-button.button-disabled::before {
  outline-color: var(--color--interactive-disabled-02);
  background: transparent
}

.form-inverse .form-control[type="file"]::-webkit-file-upload-button[disabled]:hover::before,
.form-inverse .form-control[type="file"]::file-selector-button[disabled]:hover::before,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button.button-disabled:hover::before,
.form-inverse .form-control[type="file"]::file-selector-button.button-disabled:hover::before,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button[disabled]:focus::before,
.form-inverse .form-control[type="file"]::file-selector-button[disabled]:focus::before,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button.button-disabled:focus::before,
.form-inverse .form-control[type="file"]::file-selector-button.button-disabled:focus::before,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button[disabled]:focus-visible::before,
.form-inverse .form-control[type="file"]::file-selector-button[disabled]:focus-visible::before,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button.button-disabled:focus-visible::before,
.form-inverse .form-control[type="file"]::file-selector-button.button-disabled:focus-visible::before,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button[disabled]:active::before,
.form-inverse .form-control[type="file"]::file-selector-button[disabled]:active::before,
.form-inverse .form-control[type="file"]::-webkit-file-upload-button.button-disabled:active::before,
.form-inverse .form-control[type="file"]::file-selector-button.button-disabled:active::before {
  background: transparent
}

.form-control[type="file"]:hover::-webkit-file-upload-button,
.form-control[type="file"]:hover::file-selector-button {
  color: var(--color--text-primary-inverse);
  outline-color: var(--color--interactive-tertiary-hover);
  background: var(--color--interactive-tertiary-hover)
}

.form-control[type="file"]:focus::-webkit-file-upload-button,
.form-control[type="file"]:focus-visible::-webkit-file-upload-button,
.form-control[type="file"]:focus::file-selector-button,
.form-control[type="file"]:focus-visible::file-selector-button {
  color: var(--color--text-tertiary);
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--color--interactive-focus);
  background: transparent
}

.form-control[type="file"]:active::-webkit-file-upload-button,
.form-control[type="file"]:active::file-selector-button {
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-tertiary-active)
}

.form-control[type="file"][disabled] {
  background-color: transparent
}

.form-control[type="file"][disabled]::-webkit-file-upload-button,
.form-control[type="file"][disabled]::file-selector-button {
  pointer-events: none;
  color: var(--color--interactive-disabled-02);
  background: var(--color--interactive-disabled-01);
  outline-color: transparent
}

.form-control[type="file"][disabled]::-webkit-file-upload-button::before,
.form-control[type="file"][disabled]::file-selector-button::before {
  background: var(--color--interactive-disabled-01)
}

.form-control[type="file"][disabled]::-webkit-file-upload-button:hover::before,
.form-control[type="file"][disabled]::file-selector-button:hover::before,
.form-control[type="file"][disabled]::-webkit-file-upload-button:focus::before,
.form-control[type="file"][disabled]::file-selector-button:focus::before,
.form-control[type="file"][disabled]::-webkit-file-upload-button:focus-visible::before,
.form-control[type="file"][disabled]::file-selector-button:focus-visible::before,
.form-control[type="file"][disabled]::-webkit-file-upload-button:active::before,
.form-control[type="file"][disabled]::file-selector-button:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.form-control[type="file"][disabled]:hover::-webkit-file-upload-button,
.form-control[type="file"][disabled]:focus::-webkit-file-upload-button,
.form-control[type="file"][disabled]:active::-webkit-file-upload-button,
.form-control[type="file"][disabled]:hover::file-selector-button,
.form-control[type="file"][disabled]:focus::file-selector-button,
.form-control[type="file"][disabled]:active::file-selector-button {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.form-inverse .form-control[type="file"][disabled]:hover::-webkit-file-upload-button,
.form-inverse .form-control[type="file"][disabled]:focus::-webkit-file-upload-button,
.form-inverse .form-control[type="file"][disabled]:active::-webkit-file-upload-button,
.form-inverse .form-control[type="file"][disabled]:hover::file-selector-button,
.form-inverse .form-control[type="file"][disabled]:focus::file-selector-button,
.form-inverse .form-control[type="file"][disabled]:active::file-selector-button {
  color: var(--color--interactive-disabled-02)
}

textarea,
textarea.form-control {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  color: var(--color--text-secondary);
  background-color: var(--color--ui-field, var(--color--field));
  border: 0;
  border-radius: .25em;
  outline-width: .0625em;
  outline-style: solid;
  outline-color: var(--color--ui-border-03, var(--color--border-03));
  display: block;
  height: 2.9375em;
  padding: .75em .8125em;
  margin: 0;
  transition: all .1125s ease-in-out;
  height: auto;
  min-height: 120px;
  width: 100%
}

textarea::-webkit-input-placeholder,
textarea.form-control::-webkit-input-placeholder,
textarea::placeholder,
textarea.form-control::placeholder {
  color: var(--color--text-secondary)
}

textarea:not([disabled]):hover,
textarea.form-control:not([disabled]):hover {
  outline-color: var(--color--ui-border-02, var(--color--border-02))
}

textarea:not([disabled]):active,
textarea.form-control:not([disabled]):active,
textarea:not([disabled]):focus,
textarea.form-control:not([disabled]):focus,
textarea:not([disabled]):focus-visible,
textarea.form-control:not([disabled]):focus-visible {
  background-color: var(--color--ui-field, var(--color--field));
  outline-color: var(--color--interactive-focus-field);
  outline-width: .1875em;
  outline-offset: -0.125em;
  -webkit-box-shadow: unset;
  -box-shadow: unset
}

textarea[disabled],
textarea.form-control[disabled] {
  color: var(--color--interactive-disabled-02);
  background-color: var(--color--ui-01);
  outline-color: var(--color--interactive-disabled-02);
  pointer-events: none
}

textarea[disabled]::-webkit-input-placeholder,
textarea.form-control[disabled]::-webkit-input-placeholder,
textarea[disabled]::placeholder,
textarea.form-control[disabled]::placeholder {
  color: var(--color--interactive-disabled-02)
}

.form-inverse textarea[disabled],
.form-inverse textarea.form-control[disabled] {
  color: var(--color--interactive-disabled-01);
  background-color: var(--color--interactive-disabled-02)
}

.form-inverse textarea[disabled]::-webkit-input-placeholder,
.form-inverse textarea.form-control[disabled]::-webkit-input-placeholder,
.form-inverse textarea[disabled]::placeholder,
.form-inverse textarea.form-control[disabled]::placeholder {
  color: var(--color--interactive-disabled-01)
}

input.form-control[type="checkbox"]+label,
input.form-control[type="radio"]+label {
  display: inline-block;
  margin-left: 10px
}

legend,
.control-label {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-minor);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-5);
  color: var(--color--text-secondary);
  cursor: default;
  display: block;
  order: 1;
  max-width: max-content;
  margin-bottom: .5rem
}

.form-inverse legend,
.form-inverse .control-label {
  color: var(--color--text-primary-inverse)
}

legend.control-label {
  font-weight: var(--typeweight--bold);
  margin-bottom: 7.5px
}

.help-block {
  font-size: var(--typesize-minor);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-5);
  display: inline-flex;
  align-items: center;
  margin: .3125rem 0 0 0;
  color: var(--color--text-secondary)
}

.form-inverse .help-block {
  color: var(--color--text-secondary-inverse)
}

.help-with-icon .help-block::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-information-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-information-sm");
  margin-left: .625em
}

.help-block:empty {
  display: none
}

.form-control__status label,
.form-control__status .control-label {
  order: 1
}

.form-control__status .text-field__wrapper {
  order: 2;
  position: relative;
  margin: 0
}

.form-control__status .error-message {
  order: 3
}

.form-control__status .help-block {
  order: 4
}

[data-password-hidden] {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 50%;
  right: .8125rem;
  transform: translateY(-50%);
  width: 18px;
  aspect-ratio: 1;
  line-height: 1;
  border-radius: var(--corners-semiround-sm);
  outline-style: solid;
  outline-color: transparent;
  outline-width: 1em
}

[data-password-hidden]::before {
  display: none;
  background: transparent;
  border: 0;
  box-shadow: none
}

[data-password-hidden]:focus,
[data-password-hidden]:focus-visible {
  outline-width: .1875em;
  outline-color: var(--color--interactive-focus-field)
}

[data-password-hidden][disabled]::after {
  background-color: var(--color--interactive-disabled-03)
}

.text [data-password-hidden] {
  margin-top: 0
}

[data-password-hidden="true"]::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-hide-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-hide-md")
}

[data-password-hidden="false"]::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-show-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-show-md")
}

input[type="password"].form-control.c-field-password.c-field-password,
input[type="text"].form-control.c-field-password.c-field-password {
  padding: .75rem 2.875rem .75rem .8125rem
}

ol,
ul {
  padding: 0 0 0 20px;
  margin: 0
}

*+ol,
*+ul {
  margin: 20px 0 0 0
}

ol li+li,
ul li+li {
  margin-block-start: .5em
}

header ol,
footer ol,
header ul,
footer ul,
header li,
footer li {
  padding: 0;
  margin: 0
}

header li+li,
footer li+li {
  margin: 0
}

.view-switcher__form {
  all: unset
}

.view-switcher__fieldset {
  all: unset
}

.view-switcher__legend {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  height: 1px;
  width: 1px;
  min-height: unset;
  overflow: hidden;
  position: absolute;
  padding: 0;
  margin: 0
}

.view-switcher__control {
  appearance: unset;
  cursor: pointer;
  width: 24px;
  height: 24px;
  aspect-ratio: 1
}

.view-switcher__control+.view-switcher__control {
  margin-inline-start: var(--space-small)
}

.view-switcher__control:focus-visible,
.view-switcher__control:focus {
  outline-width: 3px;
  outline-style: solid;
  outline-offset: 0;
  outline-color: var(--color--interactive-focus-field)
}

.view-switcher__control::before {
  width: inherit;
  height: inherit;
  aspect-ratio: inherit
}

.view-switcher__control.gridViewRadio::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-grid-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-grid-lg")
}

.view-switcher__control.gridViewRadio:checked::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-grid-solid-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-grid-solid-lg")
}

.view-switcher__control.listViewRadio::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-list-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-list-lg")
}

.view-switcher__control.listViewRadio:checked::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-list-solid-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-list-solid-lg")
}

label[for=ais-CustomRefinements-list] {
  font-family: var(--typefamily-alt);
  font-style: var(--typestyle-normal);
  font-weight: var(--typeweight--bold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-1);
  color: transparent;
  margin: 0;
  padding: 0;
  padding-left: 0;
  list-style-type: none;
  display: block
}

@media all and (max-width:991px) {
  label[for=ais-CustomRefinements-list] {
    padding-bottom: 20px;
    border-bottom: 1px solid transparent
  }
}

ul.ais-CustomRefinements-list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  font-size: 0 !important
}

ul.ais-CustomRefinements-list ul {
  padding-left: 0
}

@media all and (min-width:561px) and (max-width:991px) {
  ul.ais-CustomRefinements-list ul {
    display: flex;
    flex-wrap: wrap
  }
}

ul.ais-CustomRefinements-list li {
  padding: 0
}

ul.ais-CustomRefinements-list .ais-CustomRefinements-category {
  display: flex;
  justify-content: space-between;
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  font-weight: var(--typestyle-normal);
  color: transparent;
  border: 2px solid transparent;
  background-color: transparent;
  padding: 10px 20px;
  text-align: left;
  transition: background-color .3s ease;
  border-radius: 4px;
  margin-top: 15px
}

@media all and (min-width:560px) {
  ul.ais-CustomRefinements-list .ais-CustomRefinements-category {
    width: 100%
  }
}

@media all and (min-width:561px) and (max-width:991px) {
  ul.ais-CustomRefinements-list .ais-CustomRefinements-category {
    width: calc(50% - 5px)
  }
}

@media all and (min-width:561px) and (max-width:991px) {
  ul.ais-CustomRefinements-list .ais-CustomRefinements-category:nth-child(even) {
    margin-left: 10px
  }
}

ul.ais-CustomRefinements-list .ais-CustomRefinements-category strong {
  display: none
}

ul.ais-CustomRefinements-list .ais-CustomRefinements-category:hover,
ul.ais-CustomRefinements-list .ais-CustomRefinements-category:active,
ul.ais-CustomRefinements-list .ais-CustomRefinements-category:focus {
  transform: none;
  text-decoration: none
}

@media all and (min-width:1025px) {
  ul.ais-CustomRefinements-list .ais-CustomRefinements-category:hover {
    background-color: transparent
  }
}

ul.ais-CustomRefinements-list .ais-CustomRefinements-category button {
  border: 0;
  background-color: transparent;
  position: relative
}

// ul.ais-CustomRefinements-list .ais-CustomRefinements-category button::after{position:absolute;top:7px;right:0;display:inline-block;content:"";width:10px;height:10px;background:url(highered-reimagined/assets/icons/ic_close-mojo.svg) center center / 10px 10px no-repeat transparent}
.facet__category {
  height: auto;
  background: transparent;
  border-radius: 0 !important;
  box-shadow: none !important
}

@media all and (min-width:992px) {
  .facet__category {
    margin-top: 20px
  }
}

@media all and (max-width:991px) {

  .programList__group,
  .ais-Panel-header {
    padding-bottom: 20px;
    border-bottom: 1px solid transparent
  }
}

@media all and (max-width:992px) {

  .programList__group,
  .ais-Panel-header {
    margin: 0 0 5px 0
  }
}

.programList__group .ais-Panel-collapseButton,
.ais-Panel-header .ais-Panel-collapseButton {
  display: none
}

.programList__group h2,
.ais-Panel-header h2,
.programList__group span,
.ais-Panel-header span {
  font-family: var(--typefamily-alt);
  font-style: var(--typestyle-normal);
  font-weight: var(--typeweight--bold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-1);
  color: transparent;
  margin: 0;
  padding: 0
}

.programList__group,
.ais-Panel-body {
  font-weight: var(--typestyle-normal)
}

.programList__group ul,
.ais-Panel-body ul,
.programList__group ul.ais-HierarchicalMenu-list,
.ais-Panel-body ul.ais-HierarchicalMenu-list {
  list-style-type: none;
  margin: 0;
  padding: 0
}

.programList__group li,
.ais-Panel-body li,
.programList__group li.ais-HierarchicalMenu-item,
.ais-Panel-body li.ais-HierarchicalMenu-item {
  padding: 0;
  margin-top: 10px;
  margin-left: -5px
}

.programList__group button,
.ais-Panel-body button,
.programList__group a,
.ais-Panel-body a {
  display: block;
  width: 100%;
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  font-weight: var(--typestyle-normal);
  color: transparent;
  border-image: none;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  padding: 5px;
  background-color: transparent;
  text-align: left;
  transition: background-color .3s ease;
  border-radius: 4px
}

.programList__group button strong,
.ais-Panel-body button strong,
.programList__group a strong,
.ais-Panel-body a strong {
  display: none
}

.programList__group button:hover,
.ais-Panel-body button:hover,
.programList__group a:hover,
.ais-Panel-body a:hover,
.programList__group button:active,
.ais-Panel-body button:active,
.programList__group a:active,
.ais-Panel-body a:active,
.programList__group button:focus,
.ais-Panel-body button:focus,
.programList__group a:focus,
.ais-Panel-body a:focus {
  transform: none;
  text-decoration: none
}

@media all and (min-width:1025px) {

  .programList__group button:hover,
  .ais-Panel-body button:hover,
  .programList__group a:hover,
  .ais-Panel-body a:hover {
    background-color: transparent
  }
}

.programList__group .ais-HierarchicalMenu-showMore,
.ais-Panel-body .ais-HierarchicalMenu-showMore {
  margin-left: -5px !important;
  font-weight: var(--typeweight--bold);
  color: transparent;
  margin-top: 10px !important
}

.programList__group .ais-HierarchicalMenu-showMore:hover,
.ais-Panel-body .ais-HierarchicalMenu-showMore:hover {
  background-color: transparent
}

@media(max-width:991px) {

  .programList__header .col-md-6,
  .programList__content .col-md-6 {
    webkit-box-flex: 0;
    -webkit-flex: 0 0 100% !important;
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important
  }
}

@media(min-width:992px) {

  .programList__header .col-md-6,
  .programList__content .col-md-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }
}

@media(max-width:991px) {

  .programList__header .col-md-3,
  .programList__content .col-md-3,
  .programList__header .col-md-9,
  .programList__content .col-md-9 {
    webkit-box-flex: 0;
    -webkit-flex: 0 0 100% !important;
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important
  }
}

@media all and (min-width:992px) {
  .par-main>section.programList__header:first-child section.programList__title {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }
}

@media all and (min-width:992px) {
  .par-main>section.programList__header:first-child .programList__stats p {
    padding-bottom: 0
  }
}

.programList__header {
  background-color: transparent;
  overflow: hidden;
  padding-top: 90px !important
}

@media all and (min-width:768px) {
  .programList__header {
    padding-top: 110px !important
  }
}

.programList__header::before {
  content: '';
  width: 200%;
  height: 200px;
  background-color: transparent;
  position: absolute;
  z-index: 1;
  border-radius: 0 0 50% 0;
  box-shadow: 0 100px 0 0 transparent
}

@media all and (min-width:992px) {
  .programList__header::before {
    right: -5%;
    bottom: 0
  }
}

@media all and (max-width:500px) {
  .programList__header::before {
    right: -25%
  }
}

@media all and (min-width:501px) and (max-width:991px) {
  .programList__header::before {
    right: -25%
  }
}

@media all and (max-width:375px) {
  .programList__header:not(.hasNoResults)::before {
    bottom: 80px
  }
}

@media all and (min-width:376px) and (max-width:991px) {
  .programList__header:not(.hasNoResults)::before {
    bottom: 100px
  }
}

@media all and (max-width:991px) {
  .programList__header::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    z-index: 1;
    height: 92px;
    background-color: transparent
  }
}

@media all and (max-width:375px) {
  .programList__header::after {
    height: 82px
  }
}

.programList__header.hasNoResults::after {
  display: none
}

@media all and (max-width:500px) {
  .programList__header.hasNoResults::before {
    bottom: 0
  }
}

@media all and (min-width:501px) and (max-width:991px) {
  .programList__header.hasNoResults::before {
    bottom: 0
  }
}

.programList__header>.container {
  position: relative;
  z-index: 2
}

.programList__header>.container::before,
.programList__header>.container::after {
  position: absolute;
  display: block;
  content: '';
  transform: rotate(-35deg);
  background: linear-gradient(0, rgba(240, 240, 240, 0) 0, #f0f0f0 50%, rgba(240, 240, 240, 0) 100%);
  opacity: .1;
  z-index: 1
}

@media all and (max-width:768px) {

  .programList__header>.container::before,
  .programList__header>.container::after {
    width: 170px;
    height: 170px
  }
}

@media all and (min-width:769px) and (max-width:1024px) {

  .programList__header>.container::before,
  .programList__header>.container::after {
    width: 350px;
    height: 350px
  }
}

@media all and (min-width:1025px) {

  .programList__header>.container::before,
  .programList__header>.container::after {
    width: 450px;
    height: 450px
  }
}

.programList__header>.container::before {
  left: 0
}

@media all and (max-width:768px) {
  .programList__header>.container::before {
    bottom: 100%
  }
}

@media all and (min-width:769px) and (max-width:1024px) {
  .programList__header>.container::before {
    bottom: 0
  }
}

@media all and (min-width:1025px) {
  .programList__header>.container::before {
    bottom: 0
  }
}

.programList__header>.container::after {
  right: 0
}

@media all and (max-width:768px) {
  .programList__header>.container::after {
    top: 0
  }
}

@media all and (min-width:769px) and (max-width:1024px) {
  .programList__header>.container::after {
    top: 0
  }
}

@media all and (min-width:1025px) {
  .programList__header>.container::after {
    top: 0
  }
}

.programList__header>.container>.row {
  position: relative;
  z-index: 2
}

.programList__header .title+* {
  margin-top: 0
}

.programList__title,
.programList__title>h1,
.programList__title>h2,
.programList__title>div>h1 {
  text-align: left;
  font-style: var(--typestyle-normal);
  color: transparent;
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-1);
  margin-bottom: 15px
}

@media(max-width:1024px) {

  .programList__title,
  .programList__title>h1,
  .programList__title>h2,
  .programList__title>div>h1 {
    margin-top: 0;
    font-size: 38px !important
  }
}

@media(min-width:1025px) {

  .programList__title,
  .programList__title>h1,
  .programList__title>h2,
  .programList__title>div>h1 {
    font-size: 42px
  }
}

.programList__meta {
  display: flex;
  padding-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  margin-top: 0 !important
}

@media all and (min-width:769px) {
  .programList__meta {
    height: 64px
  }
}

.programList__stats,
.programList__stats p.a-search__count,
.programList__stats p {
  font-style: var(--typestyle-normal);
  font-weight: var(--typestyle-normal);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  color: transparent;
  margin-top: 0 !important
}

@media all and (min-width:992px) {

  .programList__stats,
  .programList__stats p.a-search__count,
  .programList__stats p {
    margin-bottom: 32px
  }
}

@media all and (max-width:991px) {

  .programList__stats+div,
  .programList__stats p.a-search__count+div,
  .programList__stats p+div {
    margin-top: 30px !important
  }
}

@media all and (min-width:992px) {

  .programList__stats+div,
  .programList__stats p.a-search__count+div,
  .programList__stats p+div {
    margin-top: 0 !important
  }
}

@media all and (max-width:991px) {

  .hasNoResults .programList__stats+div,
  .hasNoResults .programList__stats p.a-search__count+div,
  .hasNoResults .programList__stats p+div {
    margin-top: 0 !important
  }
}

@media(max-width:991px) {

  .programList__stats,
  .programList__stats p.a-search__count,
  .programList__stats p {
    width: 100%
  }
}

@media all and (max-width:991px) {
  .programList__stats p {
    padding-bottom: 20px
  }
}

.algolia-buttons {
  display: flex
}

@media all and (max-width:375px) {
  .algolia-buttons {
    margin-top: 20px;
    margin-bottom: 30px
  }
}

@media all and (min-width:376px) and (max-width:991px) {
  .algolia-buttons {
    margin-top: 30px !important;
    margin-bottom: 30px
  }
}

@media all and (max-width:991px) {
  .algolia-buttons {
    justify-content: space-between
  }
}

@media all and (min-width:992px) {
  .algolia-buttons {
    justify-content: flex-end
  }
}

@media all and (max-width:991px) {
  .algolia-buttons .sort-by--container {
    order: 2
  }
}

@media all and (max-width:991px) {
  .algolia-buttons .a-search__filters {
    order: 1
  }
}

.current_refinements {
  margin: 0 0 40px
}

.current_refinements label {
  color: transparent
}

@media all and (max-width:991px) {
  .current_refinements label {
    padding-bottom: 20px;
    border-bottom: 1px solid transparent;
    margin-bottom: 5px !important
  }
}

.programList__filters,
.a-search__filters {
  display: none
}

.programList__sortBy,
.sort-by--container {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-style: var(--typestyle-normal);
  font-weight: var(--typestyle-normal);
  padding: 7px 16px;
  margin-top: 0;
  color: transparent;
  background: transparent;
  border: 2px solid transparent;
  box-sizing: border-box;
  border-radius: 40px;
  transition: background .3s ease
}

@media all and (max-width:991px) {

  .programList__sortBy::before,
  .sort-by--container::before {
    content: attr(data-select-value);
    font-size: 1.6rem;
    line-height: 1.5;
    margin-right: 10px
  }
}

@media all and (min-width:1025px) {

  .programList__sortBy:hover,
  .sort-by--container:hover {
    background: transparent
  }
}

@media all and (max-width:991px) {

  .programList__sortBy,
  .sort-by--container {
    width: calc(50% - 5px);
    justify-content: center;
    height: 42px;
    border-radius: 54px
  }
}

// .programList__sortBy::after,.sort-by--container::after{content:'';width:8px;height:14px;background:transparent url("highered-reimagined/assets/icons/ic_chevron-gray.svg") center center no-repeat;background-size:8px 14px;transform:translate(0,0) rotate(90deg);pointer-events:none}
@media all and (min-width:992px) {

  .programList__sortBy::after,
  .sort-by--container::after {
    position: absolute;
    right: 20px
  }
}

.programList__sortBy label,
.sort-by--container label {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute
}

.programList__sortBy select,
.sort-by--container select {
  width: auto;
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  border-image: none;
  border-color: transparent;
  margin: 0 !important;
  padding: 0 20px 0 0;
  height: auto;
  background: transparent;
  -webkit-appearance: none
}

@media all and (min-width:992px) {

  .programList__sortBy select,
  .sort-by--container select {
    position: relative;
    width: 100%
  }
}

@media all and (max-width:991px) {

  .programList__sortBy select,
  .sort-by--container select {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 42px;
    opacity: 0
  }
}

.programList__content {
  margin-top: 40px !important
}

.programList__content h3+.row {
  margin-top: 0 !important
}

.programList__content .col-md-9 {
  margin-top: 0 !important
}

@media all and (max-width:991px) {
  .programList__content .col-md-9.col-12>.function-panel {
    margin-top: 0 !important
  }
}

.programList__content .col-lg-3.col-md-4.col-sm-12 {
  margin-top: 0 !important;
  padding: 0
}

.programList__content h3 {
  font-family: sans-serif;
  font-weight: var(--typestyle-normal);
  font-size: var(--typesize-h4);
  line-height: var(--leading--1-2);
  color: transparent;
  margin-bottom: 20px
}

.programList__msg {
  font-family: var(--typefamily-primary);
  font-weight: var(--typestyle-normal);
  box-sizing: border-box;
  margin-bottom: 30px
}

@media all and (max-width:991px) {
  .programList__msg {
    padding: 30px 20px 15px 0;
    margin-bottom: 0
  }
}

@media all and (min-width:992px) {
  .programList__msg {
    padding: 45px 20px 30px 0
  }
}

@media all and (max-width:500px) {
  .programList__msg {
    padding: 30px 20px 10px 0;
    margin-bottom: 20px
  }

  .programList__msg p {
    font-weight: var(--typestyle-normal)
  }

  .programList__msg .programList__btn {
    display: none
  }
}

.programList__msg p {
  font-size: var(--typesize-h4);
  line-height: var(--leading--1-2);
  color: transparent
}

.programList__msg a {
  color: transparent;
  font-weight: var(--typeweight--bold)
}

.hiddenSearchResults {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  height: 1px;
  width: 1px;
  min-height: unset;
  overflow: hidden;
  position: absolute;
  padding: 0;
  margin: 0
}

div .col-md-9 div .col-md-3 {
  margin-top: 0;
  margin-bottom: 45px
}

.programItem {
  border-radius: 8px;
  padding: 20px;
  transition: all .3s ease
}

@media all and (min-width:1025px) {
  .programItem:hover {
    box-shadow: var(--shadow-blur)
  }
}

.programItem__author {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1
}

@media all and (max-width:991px) {
  .programItem__author {
    -webkit-line-clamp: 2
  }
}

.programItem__image {
  display: flex;
  justify-content: center;
  border-radius: 16px;
  flex-shrink: 0
}

@media all and (max-width:768px) {
  .programItem__image {
    width: 90px
  }
}

@media all and (min-width:769px) and (max-width:1024px) {
  .programItem__image {
    width: 120px
  }
}

@media all and (min-width:1025px) {
  .programItem__image {
    height: 196px
  }
}

.programItem__image>a {
  display: block
}

.programItem__image img {
  height: 100%;
  width: auto;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 1.66071px 8.30357px rgba(28, 34, 46, 0.2)
}

@media all and (max-width:1024px) {
  .programItem__image img {
    height: auto;
    width: 100%;
    border-radius: 5px
  }
}

.programItem__details {
  margin-top: 0
}

.programItem__details p {
  font-size: var(--typesize-minor)
}

.programItem__details p:nth-child(2) {
  margin-top: 10px
}

.programItem__details p:nth-child(3) {
  margin-top: 0
}

.programItem__details .programItem__isbn {
  margin-top: 10px
}

.programItem__title {
  font-family: var(--typefamily-primary);
  margin-top: 20px;
  font-style: var(--typestyle-normal);
  font-weight: var(--typeweight--bold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2
}

@media all and (max-width:991px) {
  .programItem__title {
    -webkit-line-clamp: 3
  }
}

.programItem__title>a {
  color: var(--color--text-link)
}

.programItem__title>a:hover {
  text-decoration: none
}

@media all and (min-width:1025px) {
  .programItem:hover .programItem__title a {
    text-decoration: underline;
    text-underline-offset: 0
  }
}

button.appliedFilters__item {
  font-family: var(--typefamily-primary);
  position: relative;
  display: block;
  width: 100%;
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  text-align: left;
  padding: 10px 20px;
  background: transparent;
  border: 2px solid transparent;
  box-sizing: border-box;
  border-radius: 4px
}

@media all and (max-width:768px) {
  button.appliedFilters__item {
    margin-bottom: 20px
  }
}

button.appliedFilters__item:hover,
button.appliedFilters__item:active,
button.appliedFilters__item:focus {
  transform: none
}

// button.appliedFilters__item::after{position:absolute;top:17px;right:20px;display:inline-block;content:'';width:10px;height:10px;background:transparent url("highered-reimagined/assets/icons/ic_close-mojo.svg") center center no-repeat;background-size:10px 10px}
ul.appliedFilters {
  margin-top: 20px
}

@media all and (min-width:501px) and (max-width:768px) {
  ul.appliedFilters {
    display: flex;
    width: 100%;
    justify-content: space-between
  }

  ul.appliedFilters li:not(:first-child) {
    margin-top: 0
  }
}

@media all and (max-width:500px) {
  ul.appliedFilters li {
    width: 100%
  }
}

@media all and (min-width:501px) and (max-width:768px) {
  ul.appliedFilters li {
    width: 48%
  }
}

.btn__subject {
  display: block;
  width: 100%;
  border-radius: 54px;
  padding: 9px 16px;
  font-style: var(--typestyle-normal);
  font-weight: var(--typestyle-normal);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  color: transparent;
  background: transparent;
  border: 2px solid transparent;
  box-sizing: border-box;
  transition: background .3s ease;
  text-align: center
}

.btn__subject:hover {
  text-decoration: none
}

@media all and (min-width:993px) {
  .btn__subject:hover {
    background: transparent
  }
}

div.title.subjects__title,
.subjects__title,
.subjects__title>h2 {
  font-size: var(--typesize-h3);
  line-height: var(--leading--1-1);
  color: transparent;
  margin-top: 30px
}

@media all and (max-width:991px) {

  div.title.subjects__title,
  .subjects__title,
  .subjects__title>h2 {
    margin-top: 0;
    font-size: 2.8rem
  }
}

@media(max-width:991px) {

  .programList__sidebar,
  .programList__content .col-md-9 {
    flex: 0 0 100%;
    max-width: 100%
  }

  .programList__content .col-lg-3.col-md-4.col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%
  }
}

@media(max-width:991px) {
  .programList__meta {
    margin-top: 0;
    border: 0;
    flex-direction: column
  }

  .programList__meta>div {
    width: 100%;
    display: flex;
    justify-content: space-between
  }

  .programList__filters,
  .a-search__filters {
    display: block;
    width: calc(50% - 5px);
    height: 42px;
    border: 0;
    border-radius: 54px;
    padding: 9px 16px;
    font-style: var(--typestyle-normal);
    font-weight: var(--typestyle-normal);
    font-size: var(--typesize-default);
    line-height: var(--leading--1-5);
    color: transparent;
    background: transparent;
    border: 2px solid transparent;
    box-sizing: border-box
  }

  // .programList__filters::after,.a-search__filters::after{display:inline-block;content:'';width:8px;height:14px;background:transparent url("highered-reimagined/assets/icons/ic_chevron-gray.svg") center center no-repeat;background-size:8px 14px;transform:translate(0,2px) rotate(90deg);margin-left:10px}
  .programList__filters.isActive,
  .a-search__filters.isActive {
    background-color: transparent;
    color: transparent;
    font-weight: var(--typeweight--bold);
    border-color: transparent
  }

  // .programList__filters.isActive::after,.a-search__filters.isActive::after{transform:translate(0,2px) rotate(-90deg);background:transparent url("highered-reimagined/assets/icons/ic_chevron_white.svg") center center no-repeat}
  .programList__sidebar,
  .programList__content .col-md-3.col-12 {
    display: none;
    flex-direction: column;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e8eefa
  }

  .programList__sidebar.isOpen,
  .programList__content .col-md-3.col-12.isOpen,
  .filters--open .programList__sidebar,
  .filters--open .programList__content .col-md-3.col-12 {
    display: flex
  }

  .programList__group+.programList__group {
    margin-top: 20px
  }

  .programList__group>.programList__title {
    border-bottom: 1px solid transparent;
    padding-bottom: 20px;
    margin-bottom: 0
  }

  button.programList__filter {
    margin-top: 20px;
    width: 100%;
    text-align: left
  }

  button.programList__filter:hover,
  button.programList__filter:active,
  button.programList__filter:focus {
    transform: none
  }

  .programList__content {
    margin-top: 0 !important
  }

  .programList__content h3 {
    font-size: 22px;
    margin-bottom: 5px
  }

  .programList__content div .col-sm-12 div .col-sm-12 {
    margin-bottom: 20px
  }

  .programList__content .col-sm-12+.col-sm-12 {
    border-top: 1px solid transparent
  }

  .programList__sidebar+.col-sm-12 {
    border-top: 0
  }

  .programItem {
    display: flex;
    padding-top: 20px
  }

  .programItem__title {
    margin-top: 0
  }

  .programItem__details {
    margin-left: 20px
  }
}

@media(max-width:375px) {
  .programList__meta {
    margin-top: 0
  }

  .programList__meta>div {
    margin-top: 15px
  }
}

@media all and (768px) {
  .ais-Hits {
    margin-top: 20px
  }
}

.ais-Hits-list {
  padding: 0;
  list-style-type: none
}

.ais-Hits-list li {
  padding: 0
}

@media all and (max-width:991px) {
  .ais-Hits-item:first-child>.programItem {
    padding-top: 0
  }
}

p.a-search__count>strong {
  font-weight: normal !important
}

.forms *+.text-field,
.forms *+.text-field-v2,
.forms *+.dropdown-list,
.forms *+.dropdown-list-v2,
.forms *+.check-box-list,
.forms *+.check-box-list-v2,
.forms *+.radio-button-list,
.forms *+.radio-button-list-v2,
.forms *+.drilldown,
.forms *+.multiline-text-field,
.forms *+.submit-button,
.forms *+.google-recaptcha,
.forms *+.salesforce-lead-form,
.forms *+.user-role-selector,
.forms *+.text {
  margin-top: 20px
}

input.button-secondary {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1
}

@media(min-width:376px) {
  input.button-secondary {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  input.button-secondary {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

input.button-secondary::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

input.button-secondary:hover,
input.button-secondary:focus,
input.button-secondary:focus-visible,
input.button-secondary:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

input.button-secondary:hover::before,
input.button-secondary:focus::before,
input.button-secondary:focus-visible::before,
input.button-secondary:active::before {
  transform: scale(1.05)
}

input.button-secondary:focus,
input.button-secondary:focus-visible {
  outline: 0
}

input.button-secondary:focus::before,
input.button-secondary:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

input.button-secondary:active::before {
  outline-color: transparent
}

input.button-secondary:only-of-type {
  margin: 0
}

input.button-secondary.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

input.button-secondary::before {
  background: var(--color--interactive-secondary) border-box
}

input.button-secondary:hover {
  color: var(--color--text-primary-inverse)
}

input.button-secondary:hover::before {
  background: var(--color--interactive-secondary-hover) border-box
}

input.button-secondary:focus,
input.button-secondary:focus-visible {
  color: var(--color--text-primary-inverse)
}

input.button-secondary:focus::before,
input.button-secondary:focus-visible::before {
  background: var(--color--interactive-secondary) border-box
}

input.button-secondary:active {
  color: var(--color--text-primary-inverse)
}

input.button-secondary:active::before {
  background: var(--color--interactive-secondary-active) border-box
}

input.button-secondary[disabled],
input.button-secondary.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

input.button-secondary[disabled]::before,
input.button-secondary.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

input.button-secondary[disabled]:hover::before,
input.button-secondary.button-disabled:hover::before,
input.button-secondary[disabled]:focus::before,
input.button-secondary.button-disabled:focus::before,
input.button-secondary[disabled]:focus-visible::before,
input.button-secondary.button-disabled:focus-visible::before,
input.button-secondary[disabled]:active::before,
input.button-secondary.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.form-field-required {
  display: inline-block;
  content: "*";
  color: inherit;
  margin-left: .135em
}

.text-field>div,
.text-field-v2>div {
  display: flex;
  flex-direction: column
}

.text-field>div.has-error input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]),
.text-field-v2>div.has-error input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]),
.text-field>div.has-error select,
.text-field-v2>div.has-error select,
.text-field>div.has-error textarea,
.text-field-v2>div.has-error textarea,
.text-field>div.has-error .input,
.text-field-v2>div.has-error .input,
.text-field>div.has-error .form-control,
.text-field-v2>div.has-error .form-control {
  outline-width: .125em;
  outline-color: var(--color--alert-error)
}

.form-inverse .text-field>div.has-error input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]),
.form-inverse .text-field-v2>div.has-error input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]),
.form-inverse .text-field>div.has-error select,
.form-inverse .text-field-v2>div.has-error select,
.form-inverse .text-field>div.has-error textarea,
.form-inverse .text-field-v2>div.has-error textarea,
.form-inverse .text-field>div.has-error .input,
.form-inverse .text-field-v2>div.has-error .input,
.form-inverse .text-field>div.has-error .form-control,
.form-inverse .text-field-v2>div.has-error .form-control {
  outline-color: var(--color--alert-error-inverse)
}

.text-field>div.has-error input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([disabled]):focus,
.text-field-v2>div.has-error input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([disabled]):focus,
.text-field>div.has-error select:not([disabled]):focus,
.text-field-v2>div.has-error select:not([disabled]):focus,
.text-field>div.has-error textarea:not([disabled]):focus,
.text-field-v2>div.has-error textarea:not([disabled]):focus,
.text-field>div.has-error .input:not([disabled]):focus,
.text-field-v2>div.has-error .input:not([disabled]):focus,
.text-field>div.has-error .form-control:not([disabled]):focus,
.text-field-v2>div.has-error .form-control:not([disabled]):focus {
  outline-offset: unset
}

.text-field>div.has-error .help-block,
.text-field-v2>div.has-error .help-block {
  display: none
}

.text-field>div.has-error .error-message,
.text-field-v2>div.has-error .error-message {
  display: inline-flex
}

.text-field input,
.text-field-v2 input {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2
}

.text-field .help-block,
.text-field-v2 .help-block {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3
}

.text-field .error-message,
.text-field-v2 .error-message {
  font-size: var(--typesize-minor);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-5);
  display: inline-flex;
  align-items: center;
  margin: .3125rem 0 0 0;
  color: var(--color--alert-error);
  display: none;
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4
}

.form-inverse .text-field .error-message,
.form-inverse .text-field-v2 .error-message {
  color: var(--color--alert-error-inverse)
}

.dropdown-list>div,
.dropdown-list-v2>div {
  display: flex;
  flex-direction: column;
  position: relative
}

.dropdown-list>div label.control-label,
.dropdown-list-v2>div label.control-label {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-minor);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-5);
  color: var(--color--text-secondary);
  cursor: default;
  display: block;
  max-width: max-content;
  margin-bottom: .5rem;
  order: 1
}

.form-inverse .dropdown-list>div label.control-label,
.form-inverse .dropdown-list-v2>div label.control-label {
  color: var(--color--text-primary-inverse)
}

.dropdown-list>div select.form-control,
.dropdown-list-v2>div select.form-control {
  appearance: none;
  order: 2
}

.dropdown-list>div select.form-control:not([multiple]),
.dropdown-list-v2>div select.form-control:not([multiple]) {
  appearance: none;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  background-repeat: no-repeat;
  background-size: 1.125rem 1.125rem;
  background-attachment: scroll;
  background-position: calc(100% - 0.8125rem) calc(100% - 0.9375rem);
  padding-right: 2.5625rem
}

.dropdown-list>div select.form-control:not([multiple]).is-open:not([multiple]),
.dropdown-list-v2>div select.form-control:not([multiple]).is-open:not([multiple]) {
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-collapse-md")
}

.dropdown-list>div select.form-control:not([multiple])[disabled],
.dropdown-list-v2>div select.form-control:not([multiple])[disabled] {
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-disabled-md")
}

.form-inverse .dropdown-list>div select.form-control:not([multiple])[disabled],
.form-inverse .dropdown-list-v2>div select.form-control:not([multiple])[disabled] {
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-disabled-inverse-md")
}

@media(forced-colors:active) and (prefers-color-scheme:dark) {

  .dropdown-list>div select.form-control:not([multiple]),
  .dropdown-list-v2>div select.form-control:not([multiple]) {
    appearance: auto
  }
}

.dropdown-list>div select.form-control[multiple],
.dropdown-list-v2>div select.form-control[multiple] {
  padding: 0;
  height: max-content;
  max-height: calc(7.5em + 2px);
  overflow-y: auto
}

.dropdown-list>div select.form-control[multiple] option,
.dropdown-list-v2>div select.form-control[multiple] option {
  padding: .75rem
}

.dropdown-list>div .help-block,
.dropdown-list-v2>div .help-block {
  font-size: var(--typesize-minor);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-5);
  display: inline-flex;
  align-items: center;
  margin: .3125rem 0 0 0;
  color: var(--color--text-secondary);
  order: 3
}

.form-inverse .dropdown-list>div .help-block,
.form-inverse .dropdown-list-v2>div .help-block {
  color: var(--color--text-secondary-inverse)
}

.dropdown-list>div .error-message,
.dropdown-list-v2>div .error-message {
  font-size: var(--typesize-minor);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-5);
  display: inline-flex;
  align-items: center;
  margin: .3125rem 0 0 0;
  color: var(--color--alert-error);
  display: none;
  order: 4
}

.form-inverse .dropdown-list>div .error-message,
.form-inverse .dropdown-list-v2>div .error-message {
  color: var(--color--alert-error-inverse)
}

.dropdown-list>div.has-error.has-error select,
.dropdown-list-v2>div.has-error.has-error select {
  outline-width: .125em;
  outline-color: var(--color--alert-error)
}

.form-inverse .dropdown-list>div.has-error.has-error select,
.form-inverse .dropdown-list-v2>div.has-error.has-error select {
  outline-color: var(--color--alert-error-inverse)
}

.dropdown-list>div.has-error.has-error select:not([disabled]):focus,
.dropdown-list-v2>div.has-error.has-error select:not([disabled]):focus {
  outline-offset: unset
}

.dropdown-list>div.has-error.has-error .help-block,
.dropdown-list-v2>div.has-error.has-error .help-block {
  display: none
}

.dropdown-list>div.has-error.has-error .error-message,
.dropdown-list-v2>div.has-error.has-error .error-message {
  display: inline-flex
}

.check-box-list label,
.check-box-list-v2 label {
  display: flex;
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-5);
  color: var(--color--text-primary);
  align-items: baseline;
  max-width: fit-content;
  position: relative;
  z-index: 1;
  margin-bottom: 0
}

.check-box-list label:not([class*="-inline"])+label,
.check-box-list-v2 label:not([class*="-inline"])+label {
  margin-top: 1rem
}

.check-box-list label[class*="-inline"],
.check-box-list-v2 label[class*="-inline"] {
  display: inline-flex;
  margin: 0 .625rem .625rem 0
}

.check-box-list label[class*="-inline"]:only-of-type,
.check-box-list-v2 label[class*="-inline"]:only-of-type,
.check-box-list label[class*="-inline"]:last-of-type,
.check-box-list-v2 label[class*="-inline"]:last-of-type {
  margin-bottom: 0
}

.check-box-list label p,
.check-box-list-v2 label p {
  margin-block-start: unset
}

.small-controls.check-box-list label,
.small-controls.check-box-list-v2 label {
  font-size: var(--typesize-minor)
}

.form-inverse .check-box-list label,
.form-inverse .check-box-list-v2 label {
  color: var(--color--text-primary-inverse)
}

.check-box-list [type="checkbox"],
.check-box-list-v2 [type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 1em;
  height: 1em;
  aspect-ratio: 1;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color--ui-field, var(--color--field));
  outline-width: .125em;
  outline-style: solid;
  outline-color: var(--color--ui-border-03, var(--color--border-03));
  margin: 0 .8125rem 0 0;
  position: relative;
  transition: all .125s ease-in-out;
  border-radius: .125em
}

.check-box-list [type="checkbox"]::before,
.check-box-list-v2 [type="checkbox"]::before {
  content: "";
  display: block;
  width: 2.25em;
  height: 2.25em;
  border-radius: 50%;
  background-color: var(--color--ui-06);
  flex: none;
  margin: auto;
  position: absolute;
  z-index: -1;
  transform: scale(0);
  opacity: 0;
  transition: all .25s ease-in-out;
  outline-width: .1875em;
  outline-style: solid;
  outline-color: transparent;
  outline-offset: -0.1875em
}

.small-controls.check-box-list [type="checkbox"]::before,
.small-controls.check-box-list-v2 [type="checkbox"]::before {
  width: 2em;
  height: 2em
}

.form-inverse .check-box-list [type="checkbox"]::before,
.form-inverse .check-box-list-v2 [type="checkbox"]::before {
  background-color: var(--color--ui-04)
}

.check-box-list [type="checkbox"]:hover:not([disabled]),
.check-box-list-v2 [type="checkbox"]:hover:not([disabled]) {
  outline-color: var(--color--ui-border-02, var(--color--border-02))
}

.check-box-list [type="checkbox"]:hover:not([disabled])::before,
.check-box-list-v2 [type="checkbox"]:hover:not([disabled])::before {
  transform-origin: center;
  transform: scale(1);
  opacity: 1
}

.check-box-list [type="checkbox"]:focus:not([disabled]),
.check-box-list-v2 [type="checkbox"]:focus:not([disabled]) {
  outline-color: var(--color--ui-border-02, var(--color--border-02))
}

.check-box-list [type="checkbox"]:focus:not([disabled])::before,
.check-box-list-v2 [type="checkbox"]:focus:not([disabled])::before {
  background-color: transparent;
  transform-origin: center;
  transform: scale(1);
  opacity: 1;
  outline-color: var(--color--interactive-focus-field)
}

.small-controls.check-box-list [type="checkbox"],
.small-controls.check-box-list-v2 [type="checkbox"] {
  width: 1rem;
  height: 1rem;
  outline-width: .125rem;
  outline-offset: -0.125rem
}

.small-controls.check-box-list [type="checkbox"]:focus,
.small-controls.check-box-list-v2 [type="checkbox"]:focus,
.small-controls.check-box-list [type="checkbox"]:focus-visible,
.small-controls.check-box-list-v2 [type="checkbox"]:focus-visible {
  outline-offset: -0.125rem
}

.check-box-list [type="checkbox"]:checked,
.check-box-list-v2 [type="checkbox"]:checked {
  outline-color: var(--color--interactive-primary)
}

.check-box-list [type="checkbox"]:checked:not([disabled]):hover,
.check-box-list-v2 [type="checkbox"]:checked:not([disabled]):hover {
  outline-color: var(--color--interactive-primary)
}

.check-box-list [type="checkbox"]:focus,
.check-box-list-v2 [type="checkbox"]:focus,
.check-box-list [type="checkbox"]:focus-visible,
.check-box-list-v2 [type="checkbox"]:focus-visible {
  outline-color: var(--color--interactive-focus);
  outline-offset: -0.0525em
}

.check-box-list [type="checkbox"][required][aria-checked="false"][aria-invalid="false"],
.check-box-list-v2 [type="checkbox"][required][aria-checked="false"][aria-invalid="false"] {
  outline-color: var(--color--alert-error)
}

.check-box-list [type="checkbox"]:focus,
.check-box-list-v2 [type="checkbox"]:focus {
  outline-offset: unset
}

.check-box-list [type="checkbox"]:checked:not([disabled]),
.check-box-list-v2 [type="checkbox"]:checked:not([disabled]) {
  background: var(--color--interactive-primary)
}

.check-box-list [type="checkbox"]:checked:not([disabled]):focus,
.check-box-list-v2 [type="checkbox"]:checked:not([disabled]):focus {
  outline-color: var(--color--interactive-primary)
}

.check-box-list [type="checkbox"]:checked::after,
.check-box-list-v2 [type="checkbox"]:checked::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 14px;
  aspect-ratio: 1;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-check");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-check");
  width: .875em;
  background: var(--color--ui-field, var(--color--field));
  margin: auto;
  position: absolute
}

@media(forced-colors:active) and (prefers-color-scheme:dark) {

  .check-box-list [type="checkbox"]:checked::after,
  .check-box-list-v2 [type="checkbox"]:checked::after {
    filter: brightness(0) invert(1)
  }
}

@media(forced-colors:active) and (prefers-color-scheme:light) {

  .check-box-list [type="checkbox"]:checked::after,
  .check-box-list-v2 [type="checkbox"]:checked::after {
    filter: brightness(1) invert(1)
  }
}

.check-box-list [type="checkbox"][disabled],
.check-box-list-v2 [type="checkbox"][disabled] {
  color: var(--color--interactive-disabled-02);
  background-color: var(--color--ui-01);
  outline-color: var(--color--interactive-disabled-02);
  pointer-events: none
}

.check-box-list [type="checkbox"][disabled]::-webkit-input-placeholder,
.check-box-list-v2 [type="checkbox"][disabled]::-webkit-input-placeholder,
.check-box-list [type="checkbox"][disabled]::placeholder,
.check-box-list-v2 [type="checkbox"][disabled]::placeholder {
  color: var(--color--interactive-disabled-02)
}

.form-inverse .check-box-list [type="checkbox"][disabled],
.form-inverse .check-box-list-v2 [type="checkbox"][disabled] {
  color: var(--color--interactive-disabled-01);
  background-color: var(--color--interactive-disabled-02)
}

.form-inverse .check-box-list [type="checkbox"][disabled]::-webkit-input-placeholder,
.form-inverse .check-box-list-v2 [type="checkbox"][disabled]::-webkit-input-placeholder,
.form-inverse .check-box-list [type="checkbox"][disabled]::placeholder,
.form-inverse .check-box-list-v2 [type="checkbox"][disabled]::placeholder {
  color: var(--color--interactive-disabled-01)
}

.check-box-list [type="checkbox"][disabled]:checked,
.check-box-list-v2 [type="checkbox"][disabled]:checked {
  outline-color: var(--color--ui-01)
}

.check-box-list [type="checkbox"][disabled]:checked::after,
.check-box-list-v2 [type="checkbox"][disabled]:checked::after {
  background-color: var(--color--interactive-disabled-02)
}

.form-inverse .check-box-list [type="checkbox"][disabled],
.form-inverse .check-box-list-v2 [type="checkbox"][disabled] {
  outline-color: var(--color--interactive-disabled-03)
}

.form-inverse .check-box-list [type="checkbox"][disabled]:checked,
.form-inverse .check-box-list-v2 [type="checkbox"][disabled]:checked {
  outline-color: var(--color--interactive-disabled-02)
}

.form-inverse .check-box-list [type="checkbox"][disabled]:checked::after,
.form-inverse .check-box-list-v2 [type="checkbox"][disabled]:checked::after {
  background: var(--color--interactive-disabled-03)
}

.small-controls.check-box-list [type="checkbox"]:checked::after,
.small-controls.check-box-list-v2 [type="checkbox"]:checked::after {
  width: .75rem;
  height: .75rem
}

.radio-button-list label {
  display: flex;
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-5);
  color: var(--color--text-primary);
  align-items: baseline;
  max-width: fit-content;
  position: relative;
  z-index: 1;
  margin-bottom: 0
}

.radio-button-list label:not([class*="-inline"])+label {
  margin-top: 1rem
}

.radio-button-list label[class*="-inline"] {
  display: inline-flex;
  margin: 0 .625rem .625rem 0
}

.radio-button-list label[class*="-inline"]:only-of-type,
.radio-button-list label[class*="-inline"]:last-of-type {
  margin-bottom: 0
}

.radio-button-list label p {
  margin-block-start: unset
}

.small-controls.radio-button-list label {
  font-size: var(--typesize-minor)
}

.form-inverse .radio-button-list label {
  color: var(--color--text-primary-inverse)
}

.radio-button-list [type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 1em;
  height: 1em;
  aspect-ratio: 1;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color--ui-field, var(--color--field));
  outline-width: .125em;
  outline-style: solid;
  outline-color: var(--color--ui-border-03, var(--color--border-03));
  margin: 0 .8125rem 0 0;
  transition: all .125s ease-in-out;
  position: relative;
  border-radius: 50%
}

.radio-button-list [type="radio"]::before {
  content: "";
  display: block;
  width: 2.25em;
  height: 2.25em;
  border-radius: 50%;
  background-color: var(--color--ui-06);
  flex: none;
  margin: auto;
  position: absolute;
  z-index: -1;
  transform: scale(0);
  opacity: 0;
  transition: all .25s ease-in-out;
  outline-width: .1875em;
  outline-style: solid;
  outline-color: transparent;
  outline-offset: -0.1875em
}

.small-controls.radio-button-list [type="radio"]::before {
  width: 2em;
  height: 2em
}

.form-inverse .radio-button-list [type="radio"]::before {
  background-color: var(--color--ui-04)
}

.radio-button-list [type="radio"]:hover:not([disabled]) {
  outline-color: var(--color--ui-border-02, var(--color--border-02))
}

.radio-button-list [type="radio"]:hover:not([disabled])::before {
  transform-origin: center;
  transform: scale(1);
  opacity: 1
}

.radio-button-list [type="radio"]:focus:not([disabled]) {
  outline-color: var(--color--ui-border-02, var(--color--border-02))
}

.radio-button-list [type="radio"]:focus:not([disabled])::before {
  background-color: transparent;
  transform-origin: center;
  transform: scale(1);
  opacity: 1;
  outline-color: var(--color--interactive-focus-field)
}

.small-controls.radio-button-list [type="radio"] {
  width: 1rem;
  height: 1rem;
  outline-width: .125rem;
  outline-offset: -0.125rem
}

.small-controls.radio-button-list [type="radio"]:focus,
.small-controls.radio-button-list [type="radio"]:focus-visible {
  outline-offset: -0.125rem
}

.radio-button-list [type="radio"]:checked {
  outline-color: var(--color--interactive-primary)
}

.radio-button-list [type="radio"]:checked:not([disabled]):hover {
  outline-color: var(--color--interactive-primary)
}

.radio-button-list [type="radio"]:focus,
.radio-button-list [type="radio"]:focus-visible {
  outline-color: var(--color--interactive-focus);
  outline-offset: -0.0525em
}

.radio-button-list [type="radio"][required][aria-checked="false"][aria-invalid="false"] {
  outline-color: var(--color--alert-error)
}

.radio-button-list [type="radio"]::after {
  display: inline-block;
  border-radius: 50%;
  background: var(--color--interactive-primary);
  content: "";
  width: calc(100% - .25rem);
  height: calc(100% - .25rem);
  aspect-ratio: 1;
  margin: auto;
  transform: scale(0);
  opacity: 0;
  transition: all .125s ease-in-out
}

.radio-button-list [type="radio"]:focus {
  outline-offset: unset
}

.radio-button-list [type="radio"]:checked {
  background-color: var(--color--ui-field, var(--color--field));
  background-image: none;
  outline-color: var(--color--interactive-primary)
}

.radio-button-list [type="radio"]:checked:focus {
  outline-color: var(--color--interactive-primary)
}

.radio-button-list [type="radio"]:checked::after {
  transform: scale(1);
  opacity: 1
}

@media(forced-colors:active) and (prefers-color-scheme:dark) {
  .radio-button-list [type="radio"]:checked::after {
    filter: brightness(0) invert(1)
  }
}

@media(forced-colors:active) and (prefers-color-scheme:light) {
  .radio-button-list [type="radio"]:checked::after {
    filter: brightness(1) invert(1)
  }
}

.form-inverse .radio-button-list [type="radio"]:checked {
  background-color: var(--color--ui-field, var(--color--field))
}

.radio-button-list [type="radio"][disabled] {
  color: var(--color--interactive-disabled-02);
  background-color: var(--color--ui-01);
  pointer-events: none;
  outline-color: var(--color--interactive-disabled-02)
}

.radio-button-list [type="radio"][disabled]::-webkit-input-placeholder,
.radio-button-list [type="radio"][disabled]::placeholder {
  color: var(--color--interactive-disabled-02)
}

.form-inverse .radio-button-list [type="radio"][disabled] {
  color: var(--color--interactive-disabled-01);
  background-color: var(--color--interactive-disabled-02)
}

.form-inverse .radio-button-list [type="radio"][disabled]::-webkit-input-placeholder,
.form-inverse .radio-button-list [type="radio"][disabled]::placeholder {
  color: var(--color--interactive-disabled-01)
}

.radio-button-list [type="radio"][disabled]:checked::after {
  background: var(--color--interactive-disabled-02)
}

.form-inverse .radio-button-list [type="radio"][disabled] {
  outline-color: var(--color--interactive-disabled-03)
}

.form-inverse .radio-button-list [type="radio"][disabled]:checked::after {
  background: var(--color--interactive-disabled-03)
}

.small-controls.radio-button-list [type="radio"]:checked::after {
  transform: scale(0.75)
}

.drilldown *+label,
.drilldown select+select {
  margin-top: 20px
}

.boxshadow-sm {
  box-shadow: var(--shadow)
}

.boxshadow-blur {
  box-shadow: var(--shadow-blur)
}

.cq-wcm-edit .tab-panel.tab-panel,
.cq-wcm-preview .tab-panel.tab-panel {
  opacity: 1;
  visibility: visible
}

.cq-wcm-edit .tab-content>.tab-pane,
.cq-wcm-preview .tab-content>.tab-pane {
  display: none
}

.cq-wcm-edit .tab-content>.tab-pane.active,
.cq-wcm-preview .tab-content>.tab-pane.active {
  display: block !important
}

.tab-panel {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out
}

.tab-panel nav {
  border-bottom: 1px solid var(--color--ui-border-01, var(--color--border-01))
}

.tab-panel .nav-tabs {
  display: flex;
  border: 0
}

.tab-panel .nav-tabs__item {
  display: block;
  flex: 1 auto;
  text-align: center;
  margin: 0
}

.tab-panel .nav-tabs__item.active .nav-tabs__link {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h6);
  line-height: var(--leading--1-2);
  margin-bottom: 0;
  font-weight: var(--typeweight--semibold)
}

.tab-panel .nav-tabs__item.active .nav-tabs__link::before {
  opacity: 1;
  width: 100%;
  transform: scaleX(1);
  transform-origin: center
}

.tab-panel .nav-tabs__item.active .nav-tabs__link::after {
  bottom: 0;
  background: var(--color--interactive-primary)
}

.tab-panel .nav-tabs__link {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-2);
  margin-bottom: 0;
  -webkit-transition: all .15s ease-in-out;
  transition: all .15s ease-in-out;
  padding: var(--spacing-15) var(--spacing-5);
  color: var(--color--text-primary);
  font-weight: var(--typeweight--default);
  position: relative;
  display: block;
  cursor: pointer;
  margin: 0;
  height: 100%
}

.tab-panel .nav-tabs__link::before {
  display: block;
  content: attr(data-text);
  font-weight: var(--typeweight--bold);
  height: 0;
  visibility: hidden;
  overflow: hidden;
  text-align: center
}

.tab-panel .nav-tabs__link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100%;
  background: transparent;
  border-radius: 2px 2px 0 0
}

.tab-panel .nav-tabs__link:hover,
.tab-panel .nav-tabs__link:active,
.tab-panel .nav-tabs__link:focus {
  text-decoration: none
}

.tab-panel .nav-tabs__link:hover {
  background-color: var(--color--ui-01)
}

.tab-panel .nav-tabs__link:focus-visible {
  outline: 2px solid var(--color--interactive-focus);
  outline-offset: -2px
}

.tab-panel .tab-pane {
  border: 0;
  padding-top: var(--spacing-20)
}

.tab-panel.is-visible {
  opacity: 1;
  visibility: visible
}

@media(max-width:768px) {
  .tab-panel nav {
    position: relative
  }

  .tab-panel nav::before {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    bottom: 0;
    width: 48px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff);
    z-index: 2;
    pointer-events: none
  }

  .tab-panel .nav-tabs {
    flex-wrap: nowrap;
    overflow-x: auto
  }

  .tab-panel .nav-tabs__item:last-child {
    margin-right: var(--spacing-45)
  }

  .tab-panel .nav-tabs__link {
    white-space: nowrap
  }
}

.tab-panel.tabs--large .nav-tabs {
  gap: var(--spacing-20)
}

.tab-panel.tabs--large .nav-tabs__item {
  flex-grow: 0
}

.tab-panel.tabs--large .nav-tabs__item::last-child {
  margin: 0
}

.tab-panel.tabs--large .nav-tabs__item.active .nav-tabs__link {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-h5);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0;
  font-weight: var(--typeweight--semibold)
}

.tab-panel.tabs--large .nav-tabs__item.active .nav-tabs__link::after {
  background: var(--color--interactive-secondary)
}

.tab-panel.tabs--large .nav-tabs__link {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-h5);
  line-height: var(--leading--1-2);
  margin-bottom: 0;
  font-weight: var(--typeweight--default);
  padding: var(--spacing-5)
}

.tab-panel.tabs--large .nav-tabs__link::after {
  height: 4px
}

.tab-panel.tabs--vertical .dft-toggle {
  background-color: var(--color--ui-02);
  border: 1px solid var(--color--ui-03);
  border-radius: 4px 4px 2px 2px;
  justify-content: space-between;
  font-family: var(--typefamily-alt);
  font-weight: var(--typeweight--bold);
  padding: var(--spacing-10);
  position: relative;
  text-align: left;
  width: 100%;
  display: none
}

.tab-panel.tabs--vertical .dft-toggle:focus,
.tab-panel.tabs--vertical .dft-toggle:active {
  outline: 2px solid var(--color--interactive-focus)
}

.tab-panel.tabs--vertical .dft-toggle:before {
  content: "";
  background-color: var(--color--interactive-secondary);
  border-radius: 0 0 2px 2px;
  bottom: -1px;
  height: 3px;
  margin-left: -1px;
  position: absolute;
  left: 0;
  right: 0;
  width: calc(100% + 2px)
}

.tab-panel.tabs--vertical .dft-expand {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md")
}

.tab-panel.tabs--vertical .dft-collapse {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  transform: rotate(180deg)
}

.tab-panel.tabs--vertical nav::before {
  display: none
}

.tab-panel.tabs--vertical .nav-tabs {
  background-color: transparent;
  border: 0;
  border-radius: inherit;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  max-width: 100%;
  width: inherit
}

.tab-panel.tabs--vertical .nav-tabs__item {
  border-bottom: 1px solid var(--color--ui-01)
}

.tab-panel.tabs--vertical .nav-tabs__item.active .nav-tabs__link {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-p);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default);
  font-weight: var(--typeweight--semibold)
}

.tab-panel.tabs--vertical .nav-tabs__item.active .nav-tabs__link::after {
  content: "";
  display: block;
  background: var(--color--interactive-secondary);
  border-radius: 0 2px 2px 0;
  height: 100%;
  left: 0;
  width: 3px
}

.tab-panel.tabs--vertical .nav-tabs__item:last-child {
  margin: 0
}

.tab-panel.tabs--vertical .nav-tabs__link {
  padding: var(--spacing-15) var(--spacing-15) var(--spacing-15) var(--spacing-20);
  text-align: left;
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-p);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default)
}

.tab-panel.tabs--vertical .nav-tabs.toggle-hidden {
  display: block
}

@media(max-width:768px) {
  .tab-panel.tabs--vertical .dft-toggle {
    display: flex
  }

  .tab-panel.tabs--vertical .nav-tabs {
    box-shadow: var(--shadow);
    background-color: var(--color--ui-02);
    border: 1px solid var(--color--ui-03);
    border-radius: var(--corners-semiround-sm);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: inherit;
    margin-top: var(--spacing-5);
    max-width: 100%
  }

  .tab-panel.tabs--vertical .nav-tabs.toggle-hidden {
    display: none
  }

  .tab-panel.tabs--vertical .nav-tabs__item {
    border: 0
  }

  .tab-panel.tabs--vertical .nav-tabs__link {
    padding: var(--spacing-10) var(--spacing-15)
  }

  .tab-panel.tabs--vertical .nav-tabs__link::after {
    display: none !important
  }
}

.tab-panel.tabs--alt nav {
  border-bottom: 1px solid var(--color--ui-04)
}

.tab-panel.tabs--alt .nav-tabs {
  gap: var(--spacing-5)
}

.tab-panel.tabs--alt .nav-tabs__item {
  flex-grow: 0
}

.tab-panel.tabs--alt .nav-tabs__item.active .nav-tabs__link {
  background: var(--color--ui-background-light, var(--color--background-light));
  font-weight: var(--typeweight--medium)
}

.tab-panel.tabs--alt .nav-tabs__item.active .nav-tabs__link::after {
  background: var(--color--ui-background-dark, var(--color--background-dark))
}

.tab-panel.tabs--alt .nav-tabs__link {
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-2);
  margin-bottom: 0;
  background: var(--color--ui-01);
  font-weight: var(--typeweight--medium);
  border-radius: 4px 4px 0 0;
  padding: var(--spacing-10) var(--spacing-30);
  color: var(--color--text-tertiary)
}

.tab-panel.tabs--alt .nav-tabs__link::before {
  display: none
}

.tab-panel.tabs--alt .nav-tabs__link:hover {
  color: var(--color--text-primary);
  background: var(--color--ui-background-light, var(--color--background-light))
}

.tab-panel.tabs--alt .nav-tabs__link:focus-visible {
  outline: 3px solid var(--color--interactive-focus);
  outline-offset: -3px
}

@media screen and (min-width:768px) {
  .tab-panel.tabs--alt .nav-tabs__item.active .nav-tabs__link {
    background: var(--color--ui-background-dark, var(--color--background-dark));
    color: var(--color--text-link-inverse);
    font-weight: var(--typeweight--medium)
  }

  .tab-panel.tabs--alt .nav-tabs__link::after {
    display: none
  }
}

.tab-panel.tabs--slider nav {
  position: relative
}

.tab-panel.tabs--slider .nav-tabs {
  margin: 0;
  flex-flow: row nowrap;
  width: max-content
}

.tab-panel.tabs--slider .nav-tabs__slider {
  overflow: hidden;
  scroll-behavior: smooth
}

.tab-panel.tabs--slider .nav-tabs__item {
  flex-grow: 0
}

.tab-panel.tabs--slider .nav-tabs__link {
  padding: var(--spacing-10) var(--spacing-30);
  white-space: nowrap
}

.tab-panel.tabs--slider .nav-tabs__nav-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: calc(100% + 1px);
  width: auto;
  background: 0;
  z-index: 1;
  border: 0
}

.tab-panel.tabs--slider .nav-tabs__nav-button::after {
  color: var(--color--icon-primary)
}

.tab-panel.tabs--slider .nav-tabs__nav-button-left {
  background: linear-gradient(to right, #fff 50%, rgba(255, 255, 255, 0) 115%);
  padding: 0 var(--spacing-10) 0 0;
  right: auto;
  left: 0
}

.tab-panel.tabs--slider .nav-tabs__nav-button-left::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-md")
}

.tab-panel.tabs--slider .nav-tabs__nav-button-right {
  background: linear-gradient(to left, #fff 50%, rgba(255, 255, 255, 0) 115%);
  padding: 0 0 0 var(--spacing-10);
  left: auto;
  right: 0
}

.tab-panel.tabs--slider .nav-tabs__nav-button-right::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-md");
  transform: rotate(180deg)
}

.tab-panel.tabs--slider .nav-tabs__nav-button-inactive {
  display: none
}

@media(max-width:768px) {
  .tab-panel.tabs--slider .nav-tabs {
    overflow: auto;
    width: 100%
  }
}

.dropdown-filter label {
  display: block
}

.dropdown-filter .dropdown-list-custom {
  display: inline-block
}

@media(max-width:375px) {
  .dropdown-filter .dropdown-list-custom {
    display: block
  }
}

.submit-filter {
  display: inline-block;
  margin-left: 30px;
  transform: translateY(1px);
  -webkit-transform: translateY(1px)
}

@media(max-width:375px) {
  .submit-filter {
    display: block;
    margin-left: 0
  }

  .submit-filter button {
    width: 100%
  }
}

.clear-content-filter {
  display: block
}

a.text-primary-link::after {
  vertical-align: middle;
  margin-top: -0.25em;
  margin-left: .275em;
  transform: none;
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  transform: rotate(180deg)
}

a.text-primary-link.new-window::after,
a.text-primary-link[target="_blank"]::after {
  vertical-align: middle;
  margin-top: -0.25em;
  margin-left: .275em;
  transform: none;
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-linkout-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-linkout-sm")
}

a.text-primary-link[href*=".pdf"]::after,
a.text-primary-link[href*=".PDF"]::after,
a.text-primary-link[href*=".txt"]::after {
  vertical-align: middle;
  margin-top: -0.25em;
  margin-left: .275em;
  transform: none;
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-file-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-file-sm")
}

a.text-primary-link[href*=".doc"]::after,
a.text-primary-link[href*=".DOC"]::after,
a.text-primary-link[href*=".docx"]::after,
a.text-primary-link[href*=".ppt"]::after,
a.text-primary-link[href*=".pptx"]::after,
a.text-primary-link[href*=".xsl"]::after,
a.text-primary-link[href*=".xslx"]::after,
a.text-primary-link[href*=".zip"]::after {
  vertical-align: middle;
  margin-top: -0.25em;
  margin-left: .275em;
  transform: none;
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-download-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-download-sm")
}

.text-primary-link:not([class*="button-"]) a[href]::after {
  vertical-align: middle;
  margin-top: -0.25em;
  margin-left: .275em;
  transform: none;
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  transform: rotate(180deg)
}

.text-primary-link:not([class*="button-"]) a[href].new-window::after,
.text-primary-link:not([class*="button-"]) a[href][target="_blank"]::after {
  vertical-align: middle;
  margin-top: -0.25em;
  margin-left: .275em;
  transform: none;
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-linkout-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-linkout-sm")
}

.text-primary-link:not([class*="button-"]) a[href*=".pdf"]::after,
.text-primary-link:not([class*="button-"]) a[href*=".PDF"]::after,
.text-primary-link:not([class*="button-"]) a[href*=".txt"]::after {
  vertical-align: middle;
  margin-top: -0.25em;
  margin-left: .275em;
  transform: none;
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-file-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-file-sm")
}

.text-primary-link:not([class*="button-"]) a[href*=".doc"]::after,
.text-primary-link:not([class*="button-"]) a[href*=".DOC"]::after,
.text-primary-link:not([class*="button-"]) a[href*=".docx"]::after,
.text-primary-link:not([class*="button-"]) a[href*=".ppt"]::after,
.text-primary-link:not([class*="button-"]) a[href*=".pptx"]::after,
.text-primary-link:not([class*="button-"]) a[href*=".xsl"]::after,
.text-primary-link:not([class*="button-"]) a[href*=".xslx"]::after,
.text-primary-link:not([class*="button-"]) a[href*=".zip"]::after {
  vertical-align: middle;
  margin-top: -0.25em;
  margin-left: .275em;
  transform: none;
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-download-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-download-sm")
}

.text-primary-link:not([class*="button-"]) h1 a[href]::after,
.text-primary-link:not([class*="button-"]) .h1 a[href]::after,
.text-primary-link:not([class*="button-"]) h2 a[href]::after,
.text-primary-link:not([class*="button-"]) .h2 a[href]::after {
  width: .625em;
  height: .625em;
  margin-top: 0;
  margin-left: .25em
}

[class*="button-"][href*=".pdf"],
[class*="button-"][href$=".txt"],
[class*="button-"] a[href$=".pdf"],
[class*="button-"] a[href$=".txt"] {
  padding-inline-start: 2.875em !important
}

[class*="button-"][href*=".pdf"]::after,
[class*="button-"][href$=".txt"]::after,
[class*="button-"] a[href$=".pdf"]::after,
[class*="button-"] a[href$=".txt"]::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 1.25em
}

[class*="button-"][href*=".pdf"]::after,
[class*="button-"][href$=".txt"]::after,
[class*="button-"] a[href$=".pdf"]::after,
[class*="button-"] a[href$=".txt"]::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-file-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-file-sm");
  transform: none;
  margin-right: .25em
}

[class*="button-"][href$=".doc"],
[class*="button-"][href$=".docx"],
[class*="button-"][href$=".ppt"],
[class*="button-"][href$=".pptx"],
[class*="button-"][href$=".docx"],
[class*="button-"][href$=".xls"],
[class*="button-"][href$=".xlsx"],
[class*="button-"][href$=".zip"],
[class*="button-"] a[href$=".doc"],
[class*="button-"] a[href$=".docx"],
[class*="button-"] a[href$=".ppt"],
[class*="button-"] a[href$=".pptx"],
[class*="button-"] a[href$=".docx"],
[class*="button-"] a[href$=".xls"],
[class*="button-"] a[href$=".xlsx"],
[class*="button-"] a[href$=".zip"] {
  padding-inline-start: 2.875em !important
}

[class*="button-"][href$=".doc"]::after,
[class*="button-"][href$=".docx"]::after,
[class*="button-"][href$=".ppt"]::after,
[class*="button-"][href$=".pptx"]::after,
[class*="button-"][href$=".docx"]::after,
[class*="button-"][href$=".xls"]::after,
[class*="button-"][href$=".xlsx"]::after,
[class*="button-"][href$=".zip"]::after,
[class*="button-"] a[href$=".doc"]::after,
[class*="button-"] a[href$=".docx"]::after,
[class*="button-"] a[href$=".ppt"]::after,
[class*="button-"] a[href$=".pptx"]::after,
[class*="button-"] a[href$=".docx"]::after,
[class*="button-"] a[href$=".xls"]::after,
[class*="button-"] a[href$=".xlsx"]::after,
[class*="button-"] a[href$=".zip"]::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 1.25em
}

[class*="button-"][href$=".doc"]::after,
[class*="button-"][href$=".docx"]::after,
[class*="button-"][href$=".ppt"]::after,
[class*="button-"][href$=".pptx"]::after,
[class*="button-"][href$=".docx"]::after,
[class*="button-"][href$=".xls"]::after,
[class*="button-"][href$=".xlsx"]::after,
[class*="button-"][href$=".zip"]::after,
[class*="button-"] a[href$=".doc"]::after,
[class*="button-"] a[href$=".docx"]::after,
[class*="button-"] a[href$=".ppt"]::after,
[class*="button-"] a[href$=".pptx"]::after,
[class*="button-"] a[href$=".docx"]::after,
[class*="button-"] a[href$=".xls"]::after,
[class*="button-"] a[href$=".xlsx"]::after,
[class*="button-"] a[href$=".zip"]::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-download-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-download-sm");
  transform: translateY(0.15em);
  margin-right: .25em
}

.button-primary {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1
}

@media(min-width:376px) {
  .button-primary {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-primary {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-primary::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-primary:hover,
.button-primary:focus,
.button-primary:focus-visible,
.button-primary:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-primary:hover::before,
.button-primary:focus::before,
.button-primary:focus-visible::before,
.button-primary:active::before {
  transform: scale(1.05)
}

.button-primary:focus,
.button-primary:focus-visible {
  outline: 0
}

.button-primary:focus::before,
.button-primary:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-primary:active::before {
  outline-color: transparent
}

.button-primary:only-of-type {
  margin: 0
}

.button-primary.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-primary::before {
  background: var(--color--interactive-primary) border-box
}

.button-primary:hover::before {
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-primary-hover) border-box
}

.button-primary:focus,
.button-primary:focus-visible {
  color: var(--color--text-primary-inverse)
}

.button-primary:focus::before,
.button-primary:focus-visible::before {
  background: var(--color--interactive-primary) border-box
}

.button-primary:active {
  color: var(--color--text-primary-inverse)
}

.button-primary:active::before {
  background: var(--color--interactive-primary-active) border-box
}

.button-primary[disabled],
.button-primary.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.button-primary[disabled]::before,
.button-primary.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.button-primary[disabled]:hover::before,
.button-primary.button-disabled:hover::before,
.button-primary[disabled]:focus::before,
.button-primary.button-disabled:focus::before,
.button-primary[disabled]:focus-visible::before,
.button-primary.button-disabled:focus-visible::before,
.button-primary[disabled]:active::before,
.button-primary.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.button-primary-inverse {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary-inverse)
}

@media(min-width:376px) {
  .button-primary-inverse {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-primary-inverse {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-primary-inverse::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-primary-inverse:hover,
.button-primary-inverse:focus,
.button-primary-inverse:focus-visible,
.button-primary-inverse:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-primary-inverse:hover::before,
.button-primary-inverse:focus::before,
.button-primary-inverse:focus-visible::before,
.button-primary-inverse:active::before {
  transform: scale(1.05)
}

.button-primary-inverse:focus,
.button-primary-inverse:focus-visible {
  outline: 0
}

.button-primary-inverse:focus::before,
.button-primary-inverse:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-primary-inverse:active::before {
  outline-color: transparent
}

.button-primary-inverse:only-of-type {
  margin: 0
}

.button-primary-inverse.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-primary-inverse::before {
  background: var(--color--interactive-primary-inverse)
}

.button-primary-inverse:hover {
  color: var(--color--text-primary-inverse)
}

.button-primary-inverse:hover::before {
  background: var(--color--interactive-primary-inverse-hover, var(--color--interactive-primary-hover-inverse))
}

.button-primary-inverse:focus,
.button-primary-inverse:focus-visible {
  color: var(--color--text-primary-inverse)
}

.button-primary-inverse:focus::before,
.button-primary-inverse:focus-visible::before {
  background: var(--color--interactive-primary-inverse)
}

.button-primary-inverse:active {
  color: var(--color--text-primary-inverse)
}

.button-primary-inverse:active::before {
  background: var(--color--interactive-primary-inverse-active, var(--color--interactive-primary-active-inverse))
}

.button-primary-inverse[disabled],
.button-primary-inverse.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02);
  color: var(--color--interactive-disabled-03)
}

.button-primary-inverse[disabled]::before,
.button-primary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.button-primary-inverse[disabled]:hover::before,
.button-primary-inverse.button-disabled:hover::before,
.button-primary-inverse[disabled]:focus::before,
.button-primary-inverse.button-disabled:focus::before,
.button-primary-inverse[disabled]:focus-visible::before,
.button-primary-inverse.button-disabled:focus-visible::before,
.button-primary-inverse[disabled]:active::before,
.button-primary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.button-primary-inverse[disabled]::before,
.button-primary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.button-primary-inverse[disabled]:hover::before,
.button-primary-inverse.button-disabled:hover::before,
.button-primary-inverse[disabled]:focus::before,
.button-primary-inverse.button-disabled:focus::before,
.button-primary-inverse[disabled]:focus-visible::before,
.button-primary-inverse.button-disabled:focus-visible::before,
.button-primary-inverse[disabled]:active::before,
.button-primary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01)
}

.button-secondary {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1
}

@media(min-width:376px) {
  .button-secondary {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-secondary {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-secondary::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-secondary:hover,
.button-secondary:focus,
.button-secondary:focus-visible,
.button-secondary:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-secondary:hover::before,
.button-secondary:focus::before,
.button-secondary:focus-visible::before,
.button-secondary:active::before {
  transform: scale(1.05)
}

.button-secondary:focus,
.button-secondary:focus-visible {
  outline: 0
}

.button-secondary:focus::before,
.button-secondary:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-secondary:active::before {
  outline-color: transparent
}

.button-secondary:only-of-type {
  margin: 0
}

.button-secondary.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-secondary::before {
  background: var(--color--interactive-secondary) border-box
}

.button-secondary:hover {
  color: var(--color--text-primary-inverse)
}

.button-secondary:hover::before {
  background: var(--color--interactive-secondary-hover) border-box
}

.button-secondary:focus,
.button-secondary:focus-visible {
  color: var(--color--text-primary-inverse)
}

.button-secondary:focus::before,
.button-secondary:focus-visible::before {
  background: var(--color--interactive-secondary) border-box
}

.button-secondary:active {
  color: var(--color--text-primary-inverse)
}

.button-secondary:active::before {
  background: var(--color--interactive-secondary-active) border-box
}

.button-secondary[disabled],
.button-secondary.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.button-secondary[disabled]::before,
.button-secondary.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.button-secondary[disabled]:hover::before,
.button-secondary.button-disabled:hover::before,
.button-secondary[disabled]:focus::before,
.button-secondary.button-disabled:focus::before,
.button-secondary[disabled]:focus-visible::before,
.button-secondary.button-disabled:focus-visible::before,
.button-secondary[disabled]:active::before,
.button-secondary.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.button-secondary-inverse {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary)
}

@media(min-width:376px) {
  .button-secondary-inverse {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-secondary-inverse {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-secondary-inverse::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-secondary-inverse:hover,
.button-secondary-inverse:focus,
.button-secondary-inverse:focus-visible,
.button-secondary-inverse:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-secondary-inverse:hover::before,
.button-secondary-inverse:focus::before,
.button-secondary-inverse:focus-visible::before,
.button-secondary-inverse:active::before {
  transform: scale(1.05)
}

.button-secondary-inverse:focus,
.button-secondary-inverse:focus-visible {
  outline: 0
}

.button-secondary-inverse:focus::before,
.button-secondary-inverse:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-secondary-inverse:active::before {
  outline-color: transparent
}

.button-secondary-inverse:only-of-type {
  margin: 0
}

.button-secondary-inverse.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-secondary-inverse::before {
  background: var(--color--interactive-secondary-inverse)
}

.button-secondary-inverse:hover {
  color: var(--color--text-primary)
}

.button-secondary-inverse:hover::before {
  background: var(--color--interactive-secondary-inverse-hover, var(--color--interactive-secondary-hover-inverse))
}

.button-secondary-inverse:focus,
.button-secondary-inverse:focus-visible {
  color: var(--color--text-primary)
}

.button-secondary-inverse:focus::before,
.button-secondary-inverse:focus-visible::before {
  background: var(--color--interactive-secondary-inverse)
}

.button-secondary-inverse:active {
  color: var(--color--text-primary)
}

.button-secondary-inverse:active::before {
  background: var(--color--interactive-secondary-inverse-active, var(--color--interactive-secondary-active-inverse))
}

.button-secondary-inverse[disabled],
.button-secondary-inverse.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02);
  color: var(--color--interactive-disabled-03)
}

.button-secondary-inverse[disabled]::before,
.button-secondary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.button-secondary-inverse[disabled]:hover::before,
.button-secondary-inverse.button-disabled:hover::before,
.button-secondary-inverse[disabled]:focus::before,
.button-secondary-inverse.button-disabled:focus::before,
.button-secondary-inverse[disabled]:focus-visible::before,
.button-secondary-inverse.button-disabled:focus-visible::before,
.button-secondary-inverse[disabled]:active::before,
.button-secondary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.button-secondary-inverse[disabled]::before,
.button-secondary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.button-secondary-inverse[disabled]:hover::before,
.button-secondary-inverse.button-disabled:hover::before,
.button-secondary-inverse[disabled]:focus::before,
.button-secondary-inverse.button-disabled:focus::before,
.button-secondary-inverse[disabled]:focus-visible::before,
.button-secondary-inverse.button-disabled:focus-visible::before,
.button-secondary-inverse[disabled]:active::before,
.button-secondary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01)
}

.button-tertiary {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary);
  transition: color .125s ease-in-out
}

@media(min-width:376px) {
  .button-tertiary {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-tertiary {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-tertiary::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-tertiary:hover,
.button-tertiary:focus,
.button-tertiary:focus-visible,
.button-tertiary:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-tertiary:hover::before,
.button-tertiary:focus::before,
.button-tertiary:focus-visible::before,
.button-tertiary:active::before {
  transform: scale(1.05)
}

.button-tertiary:focus,
.button-tertiary:focus-visible {
  outline: 0
}

.button-tertiary:focus::before,
.button-tertiary:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-tertiary:active::before {
  outline-color: transparent
}

.button-tertiary:only-of-type {
  margin: 0
}

.button-tertiary.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-tertiary::before {
  outline-color: var(--color--interactive-tertiary);
  outline-offset: -2px;
  background-color: transparent
}

.button-tertiary:hover {
  color: var(--color--text-primary-inverse)
}

.button-tertiary:hover::before {
  outline-color: var(--color--interactive-tertiary-hover);
  background: var(--color--interactive-tertiary-hover)
}

.button-tertiary:focus,
.button-tertiary:focus-visible {
  color: var(--color--text-tertiary)
}

.button-tertiary:focus::before,
.button-tertiary:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

.button-tertiary:active {
  color: var(--color--text-primary-inverse)
}

.button-tertiary:active::before {
  background: var(--color--interactive-tertiary-active)
}

.button-tertiary[disabled],
.button-tertiary.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.button-tertiary[disabled]::before,
.button-tertiary.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.button-tertiary[disabled]:hover::before,
.button-tertiary.button-disabled:hover::before,
.button-tertiary[disabled]:focus::before,
.button-tertiary.button-disabled:focus::before,
.button-tertiary[disabled]:focus-visible::before,
.button-tertiary.button-disabled:focus-visible::before,
.button-tertiary[disabled]:active::before,
.button-tertiary.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.button-tertiary[disabled]::before,
.button-tertiary.button-disabled::before {
  outline-color: var(--color--interactive-disabled-01);
  outline-offset: -2px;
  background: transparent
}

.button-tertiary[disabled]:hover::before,
.button-tertiary.button-disabled:hover::before,
.button-tertiary[disabled]:focus::before,
.button-tertiary.button-disabled:focus::before,
.button-tertiary[disabled]:focus-visible::before,
.button-tertiary.button-disabled:focus-visible::before,
.button-tertiary[disabled]:active::before,
.button-tertiary.button-disabled:active::before {
  background: transparent
}

.button-tertiary-inverse {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary-inverse)
}

@media(min-width:376px) {
  .button-tertiary-inverse {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-tertiary-inverse {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-tertiary-inverse::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-tertiary-inverse:hover,
.button-tertiary-inverse:focus,
.button-tertiary-inverse:focus-visible,
.button-tertiary-inverse:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-tertiary-inverse:hover::before,
.button-tertiary-inverse:focus::before,
.button-tertiary-inverse:focus-visible::before,
.button-tertiary-inverse:active::before {
  transform: scale(1.05)
}

.button-tertiary-inverse:focus,
.button-tertiary-inverse:focus-visible {
  outline: 0
}

.button-tertiary-inverse:focus::before,
.button-tertiary-inverse:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-tertiary-inverse:active::before {
  outline-color: transparent
}

.button-tertiary-inverse:only-of-type {
  margin: 0
}

.button-tertiary-inverse.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-tertiary-inverse::before {
  outline-color: var(--color--interactive-tertiary-inverse);
  outline-offset: -2px;
  background: transparent
}

.button-tertiary-inverse:hover {
  color: var(--color--text-primary)
}

.button-tertiary-inverse:hover::before {
  outline-color: transparent;
  background: var(--color--interactive-tertiary-inverse-hover, var(--color--interactive-tertiary-hover-inverse))
}

.button-tertiary-inverse:focus,
.button-tertiary-inverse:focus-visible {
  color: var(--color--text-primary-inverse)
}

.button-tertiary-inverse:focus::before,
.button-tertiary-inverse:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

.button-tertiary-inverse:active {
  color: var(--color--text-primary)
}

.button-tertiary-inverse:active::before {
  background: var(--color--interactive-tertiary-inverse-active, var(--color--interactive-tertiary-active-inverse))
}

.button-tertiary-inverse[disabled],
.button-tertiary-inverse.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.button-tertiary-inverse[disabled]::before,
.button-tertiary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.button-tertiary-inverse[disabled]:hover::before,
.button-tertiary-inverse.button-disabled:hover::before,
.button-tertiary-inverse[disabled]:focus::before,
.button-tertiary-inverse.button-disabled:focus::before,
.button-tertiary-inverse[disabled]:focus-visible::before,
.button-tertiary-inverse.button-disabled:focus-visible::before,
.button-tertiary-inverse[disabled]:active::before,
.button-tertiary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.button-tertiary-inverse[disabled]::before,
.button-tertiary-inverse.button-disabled::before {
  outline-color: var(--color--interactive-disabled-02);
  background: transparent
}

.button-tertiary-inverse[disabled]:hover::before,
.button-tertiary-inverse.button-disabled:hover::before,
.button-tertiary-inverse[disabled]:focus::before,
.button-tertiary-inverse.button-disabled:focus::before,
.button-tertiary-inverse[disabled]:focus-visible::before,
.button-tertiary-inverse.button-disabled:focus-visible::before,
.button-tertiary-inverse[disabled]:active::before,
.button-tertiary-inverse.button-disabled:active::before {
  background: transparent
}

.button-action {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary)
}

@media(min-width:376px) {
  .button-action {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-action {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-action::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-action:hover,
.button-action:focus,
.button-action:focus-visible,
.button-action:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-action:hover::before,
.button-action:focus::before,
.button-action:focus-visible::before,
.button-action:active::before {
  transform: scale(1.05)
}

.button-action:focus,
.button-action:focus-visible {
  outline: 0
}

.button-action:focus::before,
.button-action:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-action:active::before {
  outline-color: transparent
}

.button-action:only-of-type {
  margin: 0
}

.button-action.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-action::before {
  background: var(--color--interactive-action) border-box
}

.button-action:hover::before {
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-action-hover) border-box
}

.button-action:focus,
.button-action:focus-visible {
  color: var(--color--text-primary)
}

.button-action:focus::before,
.button-action:focus-visible::before {
  background: var(--color--interactive-action-focus) border-box
}

.button-action:active {
  color: var(--color--text-primary-inverse)
}

.button-action:active::before {
  background: var(--color--interactive-action-active) border-box
}

.button-action[disabled],
.button-action.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.button-action[disabled]::before,
.button-action.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.button-action[disabled]:hover::before,
.button-action.button-disabled:hover::before,
.button-action[disabled]:focus::before,
.button-action.button-disabled:focus::before,
.button-action[disabled]:focus-visible::before,
.button-action.button-disabled:focus-visible::before,
.button-action[disabled]:active::before,
.button-action.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.button-action[disabled]::before,
.button-action.button-disabled::before {
  outline-offset: -2px;
  outline-color: var(--color--interactive-disabled-01);
  background: transparent
}

.button-action[disabled]:hover::before,
.button-action.button-disabled:hover::before,
.button-action[disabled]:focus::before,
.button-action.button-disabled:focus::before,
.button-action[disabled]:focus-visible::before,
.button-action.button-disabled:focus-visible::before,
.button-action[disabled]:active::before,
.button-action.button-disabled:active::before {
  background: transparent
}

.button-action-inverse {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary)
}

@media(min-width:376px) {
  .button-action-inverse {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-action-inverse {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-action-inverse::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-action-inverse:hover,
.button-action-inverse:focus,
.button-action-inverse:focus-visible,
.button-action-inverse:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-action-inverse:hover::before,
.button-action-inverse:focus::before,
.button-action-inverse:focus-visible::before,
.button-action-inverse:active::before {
  transform: scale(1.05)
}

.button-action-inverse:focus,
.button-action-inverse:focus-visible {
  outline: 0
}

.button-action-inverse:focus::before,
.button-action-inverse:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-action-inverse:active::before {
  outline-color: transparent
}

.button-action-inverse:only-of-type {
  margin: 0
}

.button-action-inverse.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-action-inverse::before {
  background: var(--color--interactive-action-inverse) border-box
}

.button-action-inverse:hover {
  color: var(--color--text-primary)
}

.button-action-inverse:hover::before {
  background: var(--color--interactive-action-inverse-hover, var(--color--interactive-action-hover-inverse)) border-box
}

.button-action-inverse:focus,
.button-action-inverse:focus-visible {
  color: var(--color--text-primary)
}

.button-action-inverse:focus::before,
.button-action-inverse:focus-visible::before {
  background: var(--color--interactive-action-focus) border-box
}

.button-action-inverse:active {
  color: var(--color--text-primary)
}

.button-action-inverse:active::before {
  background: var(--color--interactive-action-inverse-active, var(--color--interactive-action-active-inverse)) border-box
}

.button-action-inverse[disabled],
.button-action-inverse.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.button-action-inverse[disabled]::before,
.button-action-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.button-action-inverse[disabled]:hover::before,
.button-action-inverse.button-disabled:hover::before,
.button-action-inverse[disabled]:focus::before,
.button-action-inverse.button-disabled:focus::before,
.button-action-inverse[disabled]:focus-visible::before,
.button-action-inverse.button-disabled:focus-visible::before,
.button-action-inverse[disabled]:active::before,
.button-action-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.button-action-inverse[disabled]::before,
.button-action-inverse.button-disabled::before {
  outline-offset: -2px;
  outline-color: var(--color--interactive-disabled-02);
  background: transparent
}

.button-action-inverse[disabled]:hover::before,
.button-action-inverse.button-disabled:hover::before,
.button-action-inverse[disabled]:focus::before,
.button-action-inverse.button-disabled:focus::before,
.button-action-inverse[disabled]:focus-visible::before,
.button-action-inverse.button-disabled:focus-visible::before,
.button-action-inverse[disabled]:active::before,
.button-action-inverse.button-disabled:active::before {
  background: transparent
}

.button-small {
  font-size: var(--typesize-minor);
  padding: .25em 1.25em;
  min-height: 2rem
}

.button-small.icon-only {
  aspect-ratio: 1;
  padding: .5em;
  max-width: 2rem
}

.button-large {
  padding: .75em 1.25em;
  min-height: 3rem
}

.button-large.icon-only {
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

.button-block {
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-right: 0
}

.button-unstyled {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0
}

.button-unstyled::before {
  display: none;
  background: transparent;
  border: 0;
  box-shadow: none
}

[class*="button-circular-"] {
  line-height: var(--leading--1);
  padding: 0;
  aspect: 1
}

[class*="button-circular-"]::before {
  border-radius: 50%;
  aspect-ratio: 1
}

.button-circular-large {
  width: 2.5em;
  height: 2.5em
}

.button-circular-medium {
  width: 2.25em;
  height: 2.25em
}

.button-circular-small {
  width: 2em;
  height: 2em
}

.button-interactive a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-p);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  background: var(--color--label-interactive);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-radius: var(--button-corners);
  padding: var(--spacing-10) var(--spacing-15);
  display: inline-block;
  min-height: 1.5rem;
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  .button-interactive a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-interactive a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-interactive a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-interactive a:hover,
.button-interactive a:focus,
.button-interactive a:focus-visible,
.button-interactive a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-interactive a:hover::before,
.button-interactive a:focus::before,
.button-interactive a:focus-visible::before,
.button-interactive a:active::before {
  transform: scale(1.05)
}

.button-interactive a:focus,
.button-interactive a:focus-visible {
  outline: 0
}

.button-interactive a:focus::before,
.button-interactive a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-interactive a:active::before {
  outline-color: transparent
}

.button-interactive a:only-of-type {
  margin: 0
}

.button-interactive a.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-interactive a:hover {
  color: var(--color--text-link-hover)
}

.button-interactive a:focus {
  color: var(--color--text-link)
}

.button-interactive a:focus::before {
  transform: scale(1)
}

.button-interactive a:active {
  color: var(--color--text-link-hover);
  text-decoration: none
}

.button-non-interactive a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-p);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-radius: var(--button-corners);
  padding: var(--spacing-10) var(--spacing-15);
  display: inline-block;
  min-height: 1.5rem;
  padding: 0 var(--spacing-10);
  pointer-events: none;
  color: var(--color--text-tertiary);
  background: var(--color--label-interactive);
  border: 2px solid var(--color--ui-border-03, var(--color--border-03));
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  .button-non-interactive a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-non-interactive a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-non-interactive a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-non-interactive a:hover,
.button-non-interactive a:focus,
.button-non-interactive a:focus-visible,
.button-non-interactive a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-non-interactive a:hover::before,
.button-non-interactive a:focus::before,
.button-non-interactive a:focus-visible::before,
.button-non-interactive a:active::before {
  transform: scale(1.05)
}

.button-non-interactive a:focus,
.button-non-interactive a:focus-visible {
  outline: 0
}

.button-non-interactive a:focus::before,
.button-non-interactive a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-non-interactive a:active::before {
  outline-color: transparent
}

.button-non-interactive a:only-of-type {
  margin: 0
}

.button-non-interactive a.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-non-interactive a:hover {
  color: var(--color--text-link-hover)
}

.button-non-interactive a:focus {
  color: var(--color--text-link)
}

.button-non-interactive a:focus::before {
  transform: scale(1)
}

.button-non-interactive a:active {
  color: var(--color--text-link-hover);
  text-decoration: none
}

.button-non-interactive a a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-p);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  background: var(--color--label-interactive);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-radius: var(--button-corners);
  padding: var(--spacing-10) var(--spacing-15);
  display: inline-block;
  min-height: 1.5rem;
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  .button-non-interactive a a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-non-interactive a a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-non-interactive a a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-non-interactive a a:hover,
.button-non-interactive a a:focus,
.button-non-interactive a a:focus-visible,
.button-non-interactive a a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-non-interactive a a:hover::before,
.button-non-interactive a a:focus::before,
.button-non-interactive a a:focus-visible::before,
.button-non-interactive a a:active::before {
  transform: scale(1.05)
}

.button-non-interactive a a:focus,
.button-non-interactive a a:focus-visible {
  outline: 0
}

.button-non-interactive a a:focus::before,
.button-non-interactive a a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-non-interactive a a:active::before {
  outline-color: transparent
}

.button-non-interactive a a:only-of-type {
  margin: 0
}

.button-non-interactive a a.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-non-interactive a a:hover {
  color: var(--color--text-link-hover)
}

.button-non-interactive a a:focus {
  color: var(--color--text-link)
}

.button-non-interactive a a:focus::before {
  transform: scale(1)
}

.button-non-interactive a a:active {
  color: var(--color--text-link-hover);
  text-decoration: none
}

.button-interactive-tertiary a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-secondary);
  padding: var(--spacing-10) 25px;
  border-radius: var(--button-corners);
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  .button-interactive-tertiary a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-interactive-tertiary a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-interactive-tertiary a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-interactive-tertiary a:hover,
.button-interactive-tertiary a:focus,
.button-interactive-tertiary a:focus-visible,
.button-interactive-tertiary a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-interactive-tertiary a:hover::before,
.button-interactive-tertiary a:focus::before,
.button-interactive-tertiary a:focus-visible::before,
.button-interactive-tertiary a:active::before {
  transform: scale(1.05)
}

.button-interactive-tertiary a:focus,
.button-interactive-tertiary a:focus-visible {
  outline: 0
}

.button-interactive-tertiary a:focus::before,
.button-interactive-tertiary a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-interactive-tertiary a:active::before {
  outline-color: transparent
}

.button-interactive-tertiary a:only-of-type {
  margin: 0
}

.button-interactive-tertiary a.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-interactive-tertiary a::before {
  background: var(--color--label-interactive);
  border: 1px solid rgba(39, 49, 49, 0.15)
}

.button-interactive-tertiary a:hover {
  color: var(--color--text-secondary)
}

.button-interactive-tertiary a:hover::before {
  background: #e6ecef
}

.button-interactive-tertiary a:focus,
.button-interactive-tertiary a:active {
  color: var(--color--text-secondary);
  background: var(--color--label-interactive)
}

[class*="flex-buttons--"][class*="flex-buttons--"] ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 -15px -30px -15px
}

[class*="flex-buttons--"][class*="flex-buttons--"] li {
  list-style: none;
  position: relative;
  min-height: 1px;
  width: 100%;
  padding: 0 15px;
  margin: 0 0 30px 0
}

[class*="flex-buttons--"][class*="flex-buttons--"] li::before {
  display: none
}

[class*="flex-buttons--"][class*="flex-buttons--"] li a[href] {
  text-align: center;
  width: 100%;
  margin: 0 !important
}

@media(min-width:376px) {
  [class*="flex-buttons--"][class*="flex-buttons--"] li a[href] {
    height: 100%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around
  }
}

@media(min-width:376px) and (max-width:1023px) {
  .flex-buttons--5 li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }
}

@media(min-width:1024px) {
  .flex-buttons--5 li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%
  }
}

.c-breadcrumb__nav {
  width: 100%;
  display: block;
  min-height: calc(7.2 * var(--space-unit));
  background-color: var(--color--ui-background-dark, var(--color--background-dark));
  border-radius: 0;
  padding: 3px 0 var(--space-xxsmall) 0;
  margin-bottom: 0
}

.aem-AuthorLayer-Edit .c-breadcrumb__nav {
  position: static
}

.c-breadcrumb__list {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  padding: 0 var(--space-small);
  margin: auto
}

.c-breadcrumb__list.show-children--all li {
  display: inline-flex
}

.c-breadcrumb__list.show-children--first-last li:not(:first-child, :last-child, .truncation) {
  display: none
}

.c-breadcrumb__item {
  display: inline-flex;
  align-items: center
}

.c-breadcrumb__item * {
  font-size: var(--typesize-milli);
  color: var(--color--ui-05)
}

.c-breadcrumb__item p,
.c-breadcrumb__item span.active {
  font-weight: var(--typeweight--semibold);
  color: var(--color--text-tertiary-inverse);
  display: inline;
  margin: 0
}

.c-breadcrumb__item a {
  font-weight: var(--typeweight--default);
  color: var(--color--ui-05)
}

.c-breadcrumb__item span:not(.active, .trail-text) {
  display: none
}

.c-breadcrumb__item:not(:last-of-type)::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  transform: rotate(180deg);
  width: var(--typesize-milli);
  height: var(--typesize-milli);
  background: var(--color--ui-05);
  margin: 1px var(--space-xxsmall) 0 var(--space-xxsmall)
}

.c-breadcrumb__toggle {
  font-size: inherit;
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1);
  color: var(--color--text-link);
  border: 0;
  background-color: transparent;
  transition: outline .2s ease-in-out;
  padding: 0 0 .05em 0;
  margin: 0;
  color: var(--color--ui-05)
}

.c-breadcrumb__toggle:focus,
.c-breadcrumb__toggle:focus-visible {
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--color--interactive-focus-field)
}

@media(max-width:375px) {
  .c-breadcrumb__toggle {
    text-align: left
  }
}

@media(max-width:768px) {
  .c-breadcrumb__wrapper {
    display: none
  }
}

[data-breadcrumb="false"] .c-breadcrumb__wrapper {
  display: none
}

.headerIsTransparent .c-breadcrumb__wrapper {
  position: relative;
  transform: translateY(var(--he-header-min-h))
}

.headerIsTransparent .c-breadcrumb__nav {
  position: absolute;
  background-color: transparent
}

.isSticky .c-breadcrumb__wrapper {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  height: 1px;
  width: 1px;
  min-height: unset;
  overflow: hidden;
  position: absolute;
  padding: 0;
  margin: 0
}

@media all and (min-width:768px) {
  .text:first-child {
    margin-top: 0;
    margin-bottom: 0
  }
}

.text * {
  margin-top: 0;
  margin-bottom: 0
}

.text *+* {
  margin-top: 20px;
  margin-bottom: 0
}

.text *+br,
.text br+* {
  margin-top: 0
}

.text li+li {
  margin-top: .5em
}

.text li:first-child {
  padding-top: 0
}

.text li:last-child {
  padding-bottom: 0
}

.text:last-child p:last-child {
  margin-bottom: 0
}

.text+.text:not(.tags-filter),
.text+.content-tile:not(.content-tile-panel-video),
.text+div.title,
.text+.column-control,
*+.text {
  margin-top: 20px
}

header .text,
footer .text {
  padding: 0;
  margin: 0
}

header .text+*,
footer .text+* {
  margin: 0
}

.text.text-list-checkmarks ul {
  --marker--size: 16px;
  --marker-offset: 6px;
  --list-offset: 26px;
  list-style-type: none;
  padding-inline-start: 0;
  text-indent: calc(var(--list-offset) * -1);
  margin-inline-start: var(--list-offset)
}

.text.text-list-checkmarks ul li::before {
  width: var(--marker-size);
  height: var(--marker-size);
  aspect-ratio: 1;
  transform: translateY(calc(50% - var(--marker-offset)));
  color: var(--color--accent-01-medium)
}

.text.text-list-checkmarks ul li::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  aspect-ratio: 1;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-check-thin");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-check-thin");
  margin-inline-end: var(--space-xxsmall)
}

main .text li :is(ul, ol) {
  margin-block-start: .5em
}

.title:first-of-type h1,
.title:first-of-type h2 {
  margin: 0
}

.title+* {
  margin-top: 30px
}

.text~.title h4,
.text~.title h3 {
  margin-top: 40px
}

.title[class*="has-padding-"]>* {
  padding: 0
}

.title[class*="use-margin-top-"]>* {
  margin-top: 0
}

.column-control,
.column-control.cq-Editable-dom--container {
  position: relative;
  padding-right: 0;
  padding-left: 0
}

@media(min-width:769px) {

  .column-control,
  .column-control.cq-Editable-dom--container {
    padding-top: var(--space-xlarge);
    padding-bottom: var(--space-xlarge)
  }
}

@media(max-width:768px) {

  .column-control,
  .column-control.cq-Editable-dom--container {
    padding-top: var(--space-large);
    padding-bottom: var(--space-large)
  }
}

@media(hover:none) and (pointer:coarse) {
  .par-main>.column-control:not(.c-hero-image, .overflow-auto, .overflow-unset) {
    overflow-x: hidden
  }
}

@media(prefers-reduced-motion:no-preference) {

  .column-control.animated>[class*="container"]>.row>.col-12>*,
  .column-control.animated>.row>.col-12>*,
  .column-control.animated>.background-video__container>[class*="container"]>.row>.col-12>* {
    opacity: 0;
    transform: translate(0, 20px)
  }

  .column-control.animated.in-view>[class*="container"]>.row>.col-12>*,
  .column-control.animated.in-view>.row>.col-12>*,
  .column-control.animated.in-view>.background-video__container>[class*="container"]>.row>.col-12>* {
    animation-duration: .5s;
    animation-name: slidein;
    animation-fill-mode: forwards
  }
}

.headerIsTransparent main :nth-child(1 of .column-control):not(.column-control .column-control)::before {
  content: "";
  display: block;
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.55) 0, rgba(0, 0, 0, 0.521) 10%, rgba(0, 0, 0, 0.487) 19.2%, rgba(0, 0, 0, 0.448) 27.7%, rgba(0, 0, 0, 0.405) 35.5%, rgba(0, 0, 0, 0.36) 42.8%, rgba(0, 0, 0, 0.313) 49.6%, rgba(0, 0, 0, 0.265) 56%, rgba(0, 0, 0, 0.219) 62%, rgba(0, 0, 0, 0.174) 67.7%, rgba(0, 0, 0, 0.132) 73.2%, rgba(0, 0, 0, 0.093) 78.5%, rgba(0, 0, 0, 0.06) 83.8%, rgba(0, 0, 0, 0.032) 89.1%, rgba(0, 0, 0, 0.012) 94.5%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
  height: 250px;
  top: 0;
  z-index: 1;
  pointer-events: none
}

.headerIsTransparent main :nth-child(1 of .column-control):not([class*="background-video__control--"], .column-control .column-control) {
  overflow: hidden
}

[data-breadcrumb="true"].headerIsTransparent main :nth-child(1 of .column-control):not([class*="background-video__control--"], .column-control .column-control) {
  padding-top: calc(32 * var(--space-unit))
}

[data-breadcrumb="false"].headerIsTransparent main :nth-child(1 of .column-control):not([class*="background-video__control--"], .column-control .column-control) {
  padding-top: calc(24.8 * var(--space-unit))
}

.headerIsTransparent main :nth-child(1 of .column-control):not(.column-control .column-control)>[class*="container"]>.row>.col-12>*:not(.column-control) {
  position: relative;
  z-index: 2
}

@media(min-width:768px) {

  .flex-layout>.row>.col-12,
  .flex-layout--pin-cta>.row>.col-12,
  .flex-layout>[class*="container"]>.row>.col-12,
  .flex-layout--pin-cta>[class*="container"]>.row>.col-12 {
    justify-content: space-around
  }

  .cq-wcm-edit .flex-layout>.row>.col-12,
  .cq-wcm-edit .flex-layout--pin-cta>.row>.col-12,
  .cq-wcm-edit .flex-layout>[class*="container"]>.row>.col-12,
  .cq-wcm-edit .flex-layout--pin-cta>[class*="container"]>.row>.col-12,
  .edit .flex-layout>.row>.col-12,
  .edit .flex-layout--pin-cta>.row>.col-12,
  .edit .flex-layout>[class*="container"]>.row>.col-12,
  .edit .flex-layout--pin-cta>[class*="container"]>.row>.col-12 {
    display: block
  }

  .flex-layout .content-tile,
  .flex-layout--pin-cta .content-tile {
    height: 100%;
    width: 100%
  }

  .cq-wcm-edit .flex-layout .content-tile,
  .cq-wcm-edit .flex-layout--pin-cta .content-tile,
  .edit .flex-layout .content-tile,
  .edit .flex-layout--pin-cta .content-tile {
    height: auto
  }

  .flex-layout .content-tile .content-tile-container,
  .flex-layout--pin-cta .content-tile .content-tile-container,
  .flex-layout .content-tile figure,
  .flex-layout--pin-cta .content-tile figure,
  .flex-layout .content-tile .content-tile__figure,
  .flex-layout--pin-cta .content-tile .content-tile__figure {
    height: 100%;
    width: 100%
  }

  .cq-wcm-edit .flex-layout .content-tile .content-tile-container,
  .cq-wcm-edit .flex-layout--pin-cta .content-tile .content-tile-container,
  .cq-wcm-edit .flex-layout .content-tile figure,
  .cq-wcm-edit .flex-layout--pin-cta .content-tile figure,
  .cq-wcm-edit .flex-layout .content-tile .content-tile__figure,
  .cq-wcm-edit .flex-layout--pin-cta .content-tile .content-tile__figure,
  .edit .flex-layout .content-tile .content-tile-container,
  .edit .flex-layout--pin-cta .content-tile .content-tile-container,
  .edit .flex-layout .content-tile figure,
  .edit .flex-layout--pin-cta .content-tile figure,
  .edit .flex-layout .content-tile .content-tile__figure,
  .edit .flex-layout--pin-cta .content-tile .content-tile__figure {
    height: auto
  }

  .flex-layout .content-tile__figure,
  .flex-layout--pin-cta .content-tile__figure,
  .flex-layout .content-tile__figcaption,
  .flex-layout--pin-cta .content-tile__figcaption,
  .flex-layout figure,
  .flex-layout--pin-cta figure,
  .flex-layout figcaption,
  .flex-layout--pin-cta figcaption,
  .flex-layout .content-tile-text,
  .flex-layout--pin-cta .content-tile-text {
    display: flex;
    flex-direction: column;
    flex: 1
  }

  .flex-layout .content-tile__figcaption,
  .flex-layout--pin-cta .content-tile__figcaption,
  .flex-layout figcaption,
  .flex-layout--pin-cta figcaption {
    height: 100%
  }

  .flex-layout .text,
  .flex-layout--pin-cta .text {
    height: 100%;
    width: 100%
  }

  .flex-layout .column-control:nth-child(1),
  .flex-layout--pin-cta .column-control:nth-child(1) {
    height: 100%
  }

  .flex-layout .column-control .content-tile,
  .flex-layout--pin-cta .column-control .content-tile,
  .flex-layout .column-control .text,
  .flex-layout--pin-cta .column-control .text {
    height: auto
  }

  .flex-layout *,
  .flex-layout--pin-cta * {
    aspect-ratio: unset
  }
}

@media(min-width:768px) {
  .flex-layout--pin-cta .content-tile-text p:last-of-type {
    padding-top: var(--space-xxsmall);
    margin-top: auto !important
  }
}

.flex-layout--vertically-centered {
  display: flex;
  flex-direction: column;
  justify-content: center
}

.flex-layout--vertically-centered>[class*="container"]>.row>[class*="col-"],
.flex-layout--vertically-centered>.row>[class*="col-"] {
  margin: auto
}

[class*="horizontal-scroll__container-dark-controls"] {
  --color-scrollbar-track: #15151510;
  --color-scrollbar-thumb: #15151530
}

[class*="horizontal-scroll__container-light-controls"] {
  --color-scrollbar-track: #e8eefa15;
  --color-scrollbar-thumb: #e8eefa35
}

[class*="horizontal-scroll__container"] {
  --color-scrollbar-track: #15151510;
  --color-scrollbar-thumb: #15151530
}

[class*="horizontal-scroll__container"]>[class*="container"]>.row,
[class*="horizontal-scroll__container"]>.row {
  scrollbar-color: var(--color-scrollbar-thumb) var(--color-scrollbar-track)
}

[class*="horizontal-scroll__container"]>[class*="container"]>.row::-webkit-scrollbar-track,
[class*="horizontal-scroll__container"]>.row::-webkit-scrollbar-track {
  background: var(--color-scrollbar-track)
}

[class*="horizontal-scroll__container"]>[class*="container"]>.row::-webkit-scrollbar-thumb,
[class*="horizontal-scroll__container"]>.row::-webkit-scrollbar-thumb {
  background: var(--color-scrollbar-thumb)
}

body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"]) {
  padding-right: 0;
  padding-left: 0
}

body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row {
  flex-wrap: nowrap;
  align-items: stretch;
  overflow-x: auto;
  overflow-y: hidden;
  transform: translateZ(0);
  scrollbar-width: thin;
  padding-bottom: var(--space-medium)
}

body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row::-webkit-scrollbar,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row::-webkit-scrollbar,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row::-webkit-scrollbar-track,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row::-webkit-scrollbar-track,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row::-webkit-scrollbar-thumb,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row::-webkit-scrollbar-thumb {
  height: 4px;
  border-radius: 40px
}

@media(hover:none) and (pointer:coarse) {

  body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row,
  body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row {
    scrollbar-width: none
  }

  body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row::-webkit-scrollbar,
  body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row::-webkit-scrollbar,
  body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row::-webkit-scrollbar-track,
  body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row::-webkit-scrollbar-track,
  body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row::-webkit-scrollbar-thumb,
  body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row::-webkit-scrollbar-thumb {
    display: none
  }
}

body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row>[class*="col-"],
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row>[class*="col-"] {
  justify-content: space-around
}

@media(min-width:376px) {

  body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row>[class*="col-"],
  body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row>[class*="col-"] {
    min-width: 270px;
    max-width: 66vw
  }
}

@media(max-width:375px) {

  body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row>[class*="col-"],
  body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row>[class*="col-"] {
    max-width: 64vw
  }
}

body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row .content-tile,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row .content-tile {
  height: 100%;
  width: 100%
}

.cq-wcm-edit body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row .content-tile,
.cq-wcm-edit body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row .content-tile,
.edit body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row .content-tile,
.edit body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row .content-tile {
  height: auto
}

body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row .content-tile .content-tile-container,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row .content-tile .content-tile-container,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row .content-tile figure,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row .content-tile figure,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row .content-tile .content-tile__figure,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row .content-tile .content-tile__figure {
  height: 100%;
  width: 100%
}

.cq-wcm-edit body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row .content-tile .content-tile-container,
.cq-wcm-edit body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row .content-tile .content-tile-container,
.cq-wcm-edit body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row .content-tile figure,
.cq-wcm-edit body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row .content-tile figure,
.cq-wcm-edit body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row .content-tile .content-tile__figure,
.cq-wcm-edit body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row .content-tile .content-tile__figure,
.edit body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row .content-tile .content-tile-container,
.edit body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row .content-tile .content-tile-container,
.edit body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row .content-tile figure,
.edit body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row .content-tile figure,
.edit body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row .content-tile .content-tile__figure,
.edit body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row .content-tile .content-tile__figure {
  height: auto
}

body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row .content-tile__figure,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row .content-tile__figure,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row .content-tile__figcaption,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row .content-tile__figcaption,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row figure,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row figure,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row figcaption,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row figcaption,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row .content-tile-text,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row .content-tile-text {
  display: flex;
  flex-direction: column;
  flex: 1
}

body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row .content-tile__figcaption,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row .content-tile__figcaption,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row figcaption,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row figcaption {
  height: 100%
}

body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>.row .content-tile-text p:last-of-type,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])>[class*="container"]>.row .content-tile-text p:last-of-type {
  padding-top: var(--space-xxsmall);
  margin-top: auto !important
}

body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])[class*="-controls"]>[class*="container"]>.row,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])[class*="-controls"]>.row {
  scrollbar-color: var(--color-scrollbar-thumb) var(--color-scrollbar-track)
}

body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])[class*="-controls"]>[class*="container"]>.row::-webkit-scrollbar-track,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])[class*="-controls"]>.row::-webkit-scrollbar-track {
  background: var(--color-scrollbar-track)
}

body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])[class*="-controls"]>[class*="container"]>.row::-webkit-scrollbar-thumb,
body:not(.cq-wcm-edit) [class*="horizontal-scroll__container"]:not([class*="cq-Editable-dom"])[class*="-controls"]>.row::-webkit-scrollbar-thumb {
  background: var(--color-scrollbar-thumb)
}

.gutterless-grid.gutterless-grid>.container-fluid {
  padding-right: 0;
  padding-left: 0
}

.gutterless-grid.gutterless-grid .row {
  margin: 0
}

.gutterless-grid.gutterless-grid .row [class^="col-"] {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0
}

.gutterless-grid.gutterless-grid .row [class^="col-"]:first-of-type {
  padding-left: 0
}

.gutterless-grid.gutterless-grid .row [class^="col-"]:last-of-type {
  padding-right: 0
}

.gutterless-grid.gutterless-grid .row .col-12>.content-tile:last-child {
  margin-bottom: 0
}

.gutterless-grid.gutterless-grid .content-tile,
.gutterless-grid.gutterless-grid .content-tile-container,
.gutterless-grid.gutterless-grid figure,
.gutterless-grid.gutterless-grid .content-tile__figure {
  display: flex;
  overflow: hidden;
  flex: 1 1 100%
}

.gutterless-grid.gutterless-grid .content-tile img,
.gutterless-grid.gutterless-grid .content-tile-container img,
.gutterless-grid.gutterless-grid figure img,
.gutterless-grid.gutterless-grid .content-tile__figure img {
  height: 100%;
  max-width: none;
  width: 100%;
  object-fit: cover
}

// [class*="dots-leftDown"]>.background-video__container::after {
//   --color--dots: transparent;
//   display: block;
//   content: "";
//   position: absolute;
//   top: 0;
//   height: 100%;
//   opacity: .1;
//   -webkit-mask-size: 100%;
//   mask-size: 100%;
//   -webkit-mask-repeat: repeat-y;
//   mask-repeat: repeat-y;
//   -webkit-mask-position: center;
//   mask-position: center;
//   background-color: var(--color--dots);
//   background-repeat: repeat-y;
//   clip-path: polygon(100% 0, 100% 77%, 83% 100%, 0 100%, 0 17%, 15% 0);
//   left: 0;
//   transform: scaleY(-1);
//   z-index: 2
// }

@media(min-width:769px) {
  [class*="dots-leftDown"]>.background-video__container::after {
    width: 400px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg")
  }
}

@media(max-width:768px) {
  [class*="dots-leftDown"]>.background-video__container::after {
    width: 220px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg")
  }
}

@media(max-width:420px) {
  [class*="dots-leftDown"]>.background-video__container::after {
    width: 120px
  }
}

// [class*="leftUp"]>.background-video__container::after {
//   --color--dots: transparent;
//   display: block;
//   content: "";
//   position: absolute;
//   top: 0;
//   height: 100%;
//   opacity: .1;
//   -webkit-mask-size: 100%;
//   mask-size: 100%;
//   -webkit-mask-repeat: repeat-y;
//   mask-repeat: repeat-y;
//   -webkit-mask-position: center;
//   mask-position: center;
//   background-color: var(--color--dots);
//   background-repeat: repeat-y;
//   clip-path: polygon(100% 0, 100% 77%, 83% 100%, 0 100%, 0 17%, 15% 0);
//   left: 0;
//   z-index: 2
// }

@media(min-width:769px) {
  [class*="leftUp"]>.background-video__container::after {
    width: 400px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg")
  }
}

@media(max-width:768px) {
  [class*="leftUp"]>.background-video__container::after {
    width: 220px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg")
  }
}

@media(max-width:420px) {
  [class*="leftUp"]>.background-video__container::after {
    width: 120px
  }
}

// [class*="dots-lrUp"]>.background-video__container::after {
//   --color--dots: transparent;
//   display: block;
//   content: "";
//   position: absolute;
//   top: 0;
//   height: 100%;
//   opacity: .1;
//   -webkit-mask-size: 100%;
//   mask-size: 100%;
//   -webkit-mask-repeat: repeat-y;
//   mask-repeat: repeat-y;
//   -webkit-mask-position: center;
//   mask-position: center;
//   background-color: var(--color--dots);
//   background-repeat: repeat-y;
//   clip-path: polygon(100% 0, 100% 77%, 83% 100%, 0 100%, 0 17%, 15% 0);
//   left: 0;
//   z-index: 2
// }

@media(min-width:769px) {
  [class*="dots-lrUp"]>.background-video__container::after {
    width: 400px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg")
  }
}

@media(max-width:768px) {
  [class*="dots-lrUp"]>.background-video__container::after {
    width: 220px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg")
  }
}

@media(max-width:420px) {
  [class*="dots-lrUp"]>.background-video__container::after {
    width: 120px
  }
}

// [class*="dots-lrDown"]>.background-video__container::after {
//   --color--dots: transparent;
//   content: "";
//   position: absolute;
//   top: 0;
//   height: 100%;
//   opacity: .1;
//   -webkit-mask-size: 100%;
//   mask-size: 100%;
//   -webkit-mask-repeat: repeat-y;
//   mask-repeat: repeat-y;
//   -webkit-mask-position: center;
//   mask-position: center;
//   background-color: var(--color--dots);
//   background-repeat: repeat-y;
//   clip-path: polygon(100% 0, 100% 77%, 83% 100%, 0 100%, 0 17%, 15% 0);
//   left: 0;
//   transform: scaleY(-1);
//   z-index: 2
// }

@media(min-width:769px) {
  [class*="dots-lrDown"]>.background-video__container::after {
    width: 400px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg")
  }
}

@media(max-width:768px) {
  [class*="dots-lrDown"]>.background-video__container::after {
    width: 220px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg")
  }
}

@media(max-width:420px) {
  [class*="dots-lrDown"]>.background-video__container::after {
    width: 120px
  }
}

[class*="textureDark"]>.background-video__container::after {
  --color--dots: var(--color--accent-03-primary)
}

[class*="textureLight"]>.background-video__container::after {
  --color--dots: var(--color--ui-04)
}

.background-video__container>.row {
  display: flex;
  position: relative;
  z-index: 3;
  margin: auto;
  width: 100%
}

.curve-bottom-right .background-video__control {
  right: unset;
  left: 30px
}

.column-control[class*="min-height--"]>.background-video__container {
  min-height: inherit !important
}

@media(min-width:376px) and (max-width:767px) {
  .background-video__container.background-video__container {
    padding-bottom: 0
  }
}

.headerIsTransparent main>div>.aem-Grid>.column-control:first-child>.background-video__container::before,
.headerIsTransparent main>div>.aem-Grid>.embed>.aem-Grid>.column-control:first-child>.background-video__container::before {
  content: "";
  display: block;
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.55) 0, rgba(0, 0, 0, 0.521) 10%, rgba(0, 0, 0, 0.487) 19.2%, rgba(0, 0, 0, 0.448) 27.7%, rgba(0, 0, 0, 0.405) 35.5%, rgba(0, 0, 0, 0.36) 42.8%, rgba(0, 0, 0, 0.313) 49.6%, rgba(0, 0, 0, 0.265) 56%, rgba(0, 0, 0, 0.219) 62%, rgba(0, 0, 0, 0.174) 67.7%, rgba(0, 0, 0, 0.132) 73.2%, rgba(0, 0, 0, 0.093) 78.5%, rgba(0, 0, 0, 0.06) 83.8%, rgba(0, 0, 0, 0.032) 89.1%, rgba(0, 0, 0, 0.012) 94.5%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
  height: 250px;
  top: 0;
  z-index: 1;
  pointer-events: none;
  z-index: 3
}

.background-video__control {
  border-radius: 50%
}

.background-video__control:focus,
.background-video__control:focus-visible {
  outline: unset;
  outline-width: 2px;
  outline-style: solid;
  outline-offset: 2px;
  outline-color: var(--color--interactive-focus)
}

.column-control--bg {
  border-radius: inherit
}

// .column-control[class*="dots-leftDown"]>.column-control--bg::after {
//   --color--dots: transparent;
//   display: block;
//   content: "";
//   position: absolute;
//   top: 0;
//   height: 100%;
//   opacity: .1;
//   -webkit-mask-size: 100%;
//   mask-size: 100%;
//   -webkit-mask-repeat: repeat-y;
//   mask-repeat: repeat-y;
//   -webkit-mask-position: center;
//   mask-position: center;
//   background-color: var(--color--dots);
//   background-repeat: repeat-y;
//   clip-path: polygon(100% 0, 100% 77%, 83% 100%, 0 100%, 0 17%, 15% 0);
//   left: 0;
//   transform: scaleY(-1)
// }

@media(min-width:769px) {
  .column-control[class*="dots-leftDown"]>.column-control--bg::after {
    width: 400px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg")
  }
}

@media(max-width:768px) {
  .column-control[class*="dots-leftDown"]>.column-control--bg::after {
    width: 220px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg")
  }
}

@media(max-width:420px) {
  .column-control[class*="dots-leftDown"]>.column-control--bg::after {
    width: 120px
  }
}

// .column-control[class*="leftUp"]>.column-control--bg::after {
//   --color--dots: transparent;
//   display: block;
//   content: "";
//   position: absolute;
//   top: 0;
//   height: 100%;
//   opacity: .1;
//   -webkit-mask-size: 100%;
//   mask-size: 100%;
//   -webkit-mask-repeat: repeat-y;
//   mask-repeat: repeat-y;
//   -webkit-mask-position: center;
//   mask-position: center;
//   background-color: var(--color--dots);
//   background-repeat: repeat-y;
//   clip-path: polygon(100% 0, 100% 77%, 83% 100%, 0 100%, 0 17%, 15% 0);
//   left: 0
// }

@media(min-width:769px) {
  .column-control[class*="leftUp"]>.column-control--bg::after {
    width: 400px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg")
  }
}

@media(max-width:768px) {
  .column-control[class*="leftUp"]>.column-control--bg::after {
    width: 220px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg")
  }
}

@media(max-width:420px) {
  .column-control[class*="leftUp"]>.column-control--bg::after {
    width: 120px
  }
}

// .column-control[class*="dots-lrUp"]>.column-control--bg::after {
//   --color--dots: transparent;
//   display: block;
//   content: "";
//   position: absolute;
//   top: 0;
//   height: 100%;
//   opacity: .1;
//   -webkit-mask-size: 100%;
//   mask-size: 100%;
//   -webkit-mask-repeat: repeat-y;
//   mask-repeat: repeat-y;
//   -webkit-mask-position: center;
//   mask-position: center;
//   background-color: var(--color--dots);
//   background-repeat: repeat-y;
//   clip-path: polygon(100% 0, 100% 77%, 83% 100%, 0 100%, 0 17%, 15% 0);
//   left: 0
// }

@media(min-width:769px) {
  .column-control[class*="dots-lrUp"]>.column-control--bg::after {
    width: 400px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg")
  }
}

@media(max-width:768px) {
  .column-control[class*="dots-lrUp"]>.column-control--bg::after {
    width: 220px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg")
  }
}

@media(max-width:420px) {
  .column-control[class*="dots-lrUp"]>.column-control--bg::after {
    width: 120px
  }
}

// .column-control[class*="dots-lrDown"]>.column-control--bg::after {
//   --color--dots: transparent;
//   display: block;
//   content: "";
//   position: absolute;
//   top: 0;
//   height: 100%;
//   opacity: .1;
//   -webkit-mask-size: 100%;
//   mask-size: 100%;
//   -webkit-mask-repeat: repeat-y;
//   mask-repeat: repeat-y;
//   -webkit-mask-position: center;
//   mask-position: center;
//   background-color: var(--color--dots);
//   background-repeat: repeat-y;
//   clip-path: polygon(100% 0, 100% 77%, 83% 100%, 0 100%, 0 17%, 15% 0);
//   left: 0;
//   transform: scaleY(-1)
// }

@media(min-width:769px) {
  .column-control[class*="dots-lrDown"]>.column-control--bg::after {
    width: 400px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg")
  }
}

@media(max-width:768px) {
  .column-control[class*="dots-lrDown"]>.column-control--bg::after {
    width: 220px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg")
  }
}

@media(max-width:420px) {
  .column-control[class*="dots-lrDown"]>.column-control--bg::after {
    width: 120px
  }
}

.column-control[class*="textureDark"]>.column-control--bg::after {
  --color--dots: var(--color--accent-03-primary)
}

.column-control[class*="textureLight"]>.column-control--bg::after {
  --color--dots: var(--color--ui-04)
}

.bg-attachment--fixed>.column-control--bg {
  background-attachment: fixed
}

.column-control[class*="min-height--"]>.column-control--bg {
  min-height: inherit !important
}

[class*="clip-target-bg-media"] {
  clip-path: unset !important;
  overflow: unset !important
}

[class*="clip-target-bg-media"].curve-bottom-right .column-control--bg,
[class*="clip-target-bg-media"].curve-bottom-right .background-video__wrap-outer {
  overflow: hidden
}

@media(min-width:1920px) {

  [class*="clip-target-bg-media"].curve-bottom-right .column-control--bg,
  [class*="clip-target-bg-media"].curve-bottom-right .background-video__wrap-outer {
    clip-path: ellipse(110% 100% at left top)
  }
}

@media(min-width:376px) and (max-width:1919px) {

  [class*="clip-target-bg-media"].curve-bottom-right .column-control--bg,
  [class*="clip-target-bg-media"].curve-bottom-right .background-video__wrap-outer {
    clip-path: ellipse(160% 100% at left top)
  }
}

@media(max-width:375px) {

  [class*="clip-target-bg-media"].curve-bottom-right .column-control--bg,
  [class*="clip-target-bg-media"].curve-bottom-right .background-video__wrap-outer {
    clip-path: ellipse(225% 100% at left top)
  }
}

[class*="clip-target-bg-media"].curve-bottom-left .column-control--bg,
[class*="clip-target-bg-media"].curve-bottom-left .background-video__wrap-outer {
  overflow: hidden
}

@media(min-width:1920px) {

  [class*="clip-target-bg-media"].curve-bottom-left .column-control--bg,
  [class*="clip-target-bg-media"].curve-bottom-left .background-video__wrap-outer {
    clip-path: ellipse(110% 100% at right top)
  }
}

@media(min-width:376px) and (max-width:1919px) {

  [class*="clip-target-bg-media"].curve-bottom-left .column-control--bg,
  [class*="clip-target-bg-media"].curve-bottom-left .background-video__wrap-outer {
    clip-path: ellipse(160% 100% at right top)
  }
}

@media(max-width:375px) {

  [class*="clip-target-bg-media"].curve-bottom-left .column-control--bg,
  [class*="clip-target-bg-media"].curve-bottom-left .background-video__wrap-outer {
    clip-path: ellipse(225% 100% at right top)
  }
}

[class*="clip-target-bg-media"].curve-top-right .column-control--bg,
[class*="clip-target-bg-media"].curve-top-right .background-video__wrap-outer {
  overflow: hidden
}

@media(min-width:1920px) {

  [class*="clip-target-bg-media"].curve-top-right .column-control--bg,
  [class*="clip-target-bg-media"].curve-top-right .background-video__wrap-outer {
    clip-path: ellipse(110% 100% at left bottom)
  }
}

@media(min-width:376px) and (max-width:1919px) {

  [class*="clip-target-bg-media"].curve-top-right .column-control--bg,
  [class*="clip-target-bg-media"].curve-top-right .background-video__wrap-outer {
    clip-path: ellipse(160% 100% at left bottom)
  }
}

@media(max-width:375px) {

  [class*="clip-target-bg-media"].curve-top-right .column-control--bg,
  [class*="clip-target-bg-media"].curve-top-right .background-video__wrap-outer {
    clip-path: ellipse(225% 100% at left bottom)
  }
}

[class*="clip-target-bg-media"].curve-top-left .column-control--bg,
[class*="clip-target-bg-media"].curve-top-left .background-video__wrap-outer {
  overflow: hidden
}

@media(min-width:1920px) {

  [class*="clip-target-bg-media"].curve-top-left .column-control--bg,
  [class*="clip-target-bg-media"].curve-top-left .background-video__wrap-outer {
    clip-path: ellipse(110% 100% at right bottom)
  }
}

@media(min-width:376px) and (max-width:1919px) {

  [class*="clip-target-bg-media"].curve-top-left .column-control--bg,
  [class*="clip-target-bg-media"].curve-top-left .background-video__wrap-outer {
    clip-path: ellipse(160% 100% at right bottom)
  }
}

@media(max-width:375px) {

  [class*="clip-target-bg-media"].curve-top-left .column-control--bg,
  [class*="clip-target-bg-media"].curve-top-left .background-video__wrap-outer {
    clip-path: ellipse(225% 100% at right bottom)
  }
}

.content-tile {
  width: 100%
}

.content-tile * {
  margin-top: 0;
  margin-bottom: 0
}

.content-tile figure,
.content-tile .content-tile__figure {
  margin: 0
}

.content-tile figure>a,
.content-tile .content-tile__figure>a {
  display: block
}

.content-tile figure>a:hover,
.content-tile .content-tile__figure>a:hover,
.content-tile figure>a:active,
.content-tile .content-tile__figure>a:active,
.content-tile figure>a:focus,
.content-tile .content-tile__figure>a:focus {
  text-decoration: none
}

.content-tile picture {
  display: inline-block;
  position: relative;
  overflow: hidden;
  aspect-ratio: var(--original-image-ratio, 2)
}

.content-tile picture:has([src*=".svg"]) {
  display: block;
  aspect-ratio: unset
}

.content-tile__title {
  color: var(--color--text-primary)
}

.content-tile-subtitle {
  color: var(--color--text-secondary)
}

.content-tile-text *:not(a[href], a[href] *) {
  color: var(--color--text-secondary)
}

.content-tile-subtitle+.content-tile-text *:not(a[href], a[href] *) {
  color: var(--color--text-tertiary)
}

*+.content-tile-subtitle,
*+.content-tile-text {
  margin-top: 20px;
  margin-bottom: 0
}

.content-tile-text>*+* {
  margin-block-start: 20px;
  margin-block-end: 0
}

.content-tile-text *+br,
.content-tile-text br+* {
  margin-block-start: 0
}

.content-tile-text li+li {
  margin-block-start: .5em
}

.content-tile:not([class*="content-tile-hero-"])+* {
  margin-top: 20px
}

.content-tile+.content-tile {
  margin-top: 20px
}

@media(min-width:374px) {

  .content-tile[class*="button-"].default-btn-width figcaption a+a,
  .content-tile[class*="button-"].default-btn-width .content-tile__figcaption a+a {
    margin-top: 0
  }
}

.content-tile[class*="corners-"] {
  overflow: hidden
}

.content-tile[class*="min-height-"] .content-tile-container,
.content-tile[class*="min-height-"] .content-tile__figure,
.content-tile[class*="min-height-"] figure {
  min-height: inherit
}

*+.content-tile {
  margin-block-start: 20px
}

.content-tile.content-tile-list-checkmarks .content-tile-text ul {
  --marker--size: 16px;
  --marker-offset: 6px;
  --list-offset: 26px;
  list-style-type: none;
  padding-inline-start: 0;
  text-indent: calc(var(--list-offset) * -1);
  margin-inline-start: var(--list-offset)
}

.content-tile.content-tile-list-checkmarks .content-tile-text ul li::before {
  width: var(--marker-size);
  height: var(--marker-size);
  aspect-ratio: 1;
  transform: translateY(calc(50% - var(--marker-offset)));
  color: var(--color--accent-01-medium)
}

.content-tile.content-tile-list-checkmarks .content-tile-text ul li::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  aspect-ratio: 1;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-check-thin");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-check-thin");
  margin-inline-end: var(--space-xxsmall)
}

.content-tile.content-tile-button-opens-in-new-tab .content-tile-text a::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-linkout-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-linkout-md");
  margin-inline-start: var(--spacing-10)
}

main .content-tile-text li :is(ul, ol) {
  margin-block-start: .5em
}

.content-tile figure>a[href] .content-tile-text *,
.content-tile__figure>a[href] .content-tile-text * {
  color: var(--color--text-secondary)
}

.content-tile.color--text-primary-inverse figure>a[href],
.content-tile.color--text-primary-inverse .content-tile__figure>a[href] {
  text-decoration: unset
}

.content-tile.color--text-primary-inverse figure>a[href] figcaption *,
.content-tile.color--text-primary-inverse .content-tile__figure>a[href] .content-tile__figcaption * {
  color: var(--color--text-primary-inverse)
}

.content-tile-color-block--full-img {
  overflow: hidden
}

.content-tile-color-block--full-img .content-tile-container {
  position: relative;
  height: 100%
}

.content-tile-color-block--full-img figure,
.content-tile-color-block--full-img .content-tile__figure {
  display: flex;
  height: 100%
}

.content-tile-color-block--full-img figcaption,
.content-tile-color-block--full-img .content-tile__figcaption {
  height: 100%;
  position: relative;
  z-index: 2
}

@media(min-width:376px) {

  .content-tile-color-block--full-img figcaption,
  .content-tile-color-block--full-img .content-tile__figcaption {
    padding: var(--spacing-45)
  }
}

@media(max-width:375px) {

  .content-tile-color-block--full-img figcaption,
  .content-tile-color-block--full-img .content-tile__figcaption {
    padding: var(--spacing-30)
  }
}

.content-tile-color-block--full-img figcaption::after,
.content-tile-color-block--full-img .content-tile__figcaption::after {
  content: "";
  display: block;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 2.5rem;
  right: -0.5rem;
  transform: rotate(-45deg)
}

.content-tile-color-block--full-img figcaption .content-tile__title,
.content-tile-color-block--full-img .content-tile__figcaption .content-tile__title {
  font-family: var(--typefamily-alt)
}

.content-tile-color-block--full-img img {
  height: auto;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center center;
  max-width: 100%
}

@media(min-width:768px) {

  .content-tile-color-block--full-img figcaption,
  .content-tile-color-block--full-img .content-tile__figcaption,
  .content-tile-color-block--full-img picture {
    min-height: inherit
  }

  .content-tile-color-block--full-img[class*="text-right"] figcaption::after,
  .content-tile-color-block--full-img[class*="text-right"] .content-tile__figcaption::after {
    right: auto;
    left: -0.5rem
  }

  .content-tile-color-block--full-img img {
    max-height: 100%
  }
}

@media(max-width:767px) {

  .content-tile-color-block--full-img figure,
  .content-tile-color-block--full-img .content-tile__figure {
    flex-direction: column
  }

  .content-tile-color-block--full-img picture {
    position: relative;
    max-height: 345px
  }

  .content-tile-color-block--full-img img {
    max-width: 100%;
    height: auto;
    max-height: inherit
  }

  .content-tile-color-block--full-img figcaption,
  .content-tile-color-block--full-img .content-tile__figcaption {
    margin-left: 0;
    max-width: 100%
  }

  .content-tile-color-block--full-img figcaption::after,
  .content-tile-color-block--full-img .content-tile__figcaption::after {
    top: -0.825rem;
    right: auto;
    left: 50%;
    transform: rotate(-45deg) translateX(-50%)
  }

  .content-tile-color-block--full-img .content-tile__title {
    margin-top: 0
  }
}

.content-tile-color-block--full-img picture {
  z-index: 1;
  height: 100%;
  right: 0
}

@media(max-width:767px) {

  .content-tile-color-block--full-img figcaption,
  .content-tile-color-block--full-img .content-tile__figcaption {
    margin-left: 0;
    width: 100%;
    min-width: 100%
  }

  .content-tile-color-block--full-img picture {
    position: static;
    width: 100%
  }
}

@media(width:768px) {

  .content-tile-color-block--full-img figcaption,
  .content-tile-color-block--full-img .content-tile__figcaption,
  .content-tile-color-block--full-img picture {
    width: 50%
  }
}

@media(min-width:768px) {
  .content-tile-color-block--full-img picture {
    position: absolute
  }
}

@media(min-width:769px) {

  .content-tile-color-block--full-img figcaption,
  .content-tile-color-block--full-img .content-tile__figcaption {
    width: 31.62%;
    min-width: 370px;
    max-width: 455px
  }

  .content-tile-color-block--full-img picture {
    width: 68.38%;
    max-width: calc(100% - 370px)
  }

  .container-fluid .content-tile-color-block--full-img picture {
    width: 100%
  }
}

.content-tile-color-block--full-img--left-margin {
  overflow: hidden
}

.content-tile-color-block--full-img--left-margin .content-tile-container {
  position: relative;
  height: 100%
}

.content-tile-color-block--full-img--left-margin figure,
.content-tile-color-block--full-img--left-margin .content-tile__figure {
  display: flex;
  height: 100%
}

.content-tile-color-block--full-img--left-margin figcaption,
.content-tile-color-block--full-img--left-margin .content-tile__figcaption {
  height: 100%;
  position: relative;
  z-index: 2
}

@media(min-width:376px) {

  .content-tile-color-block--full-img--left-margin figcaption,
  .content-tile-color-block--full-img--left-margin .content-tile__figcaption {
    padding: var(--spacing-45)
  }
}

@media(max-width:375px) {

  .content-tile-color-block--full-img--left-margin figcaption,
  .content-tile-color-block--full-img--left-margin .content-tile__figcaption {
    padding: var(--spacing-30)
  }
}

.content-tile-color-block--full-img--left-margin figcaption::after,
.content-tile-color-block--full-img--left-margin .content-tile__figcaption::after {
  content: "";
  display: block;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 2.5rem;
  right: -0.5rem;
  transform: rotate(-45deg)
}

.content-tile-color-block--full-img--left-margin figcaption .content-tile__title,
.content-tile-color-block--full-img--left-margin .content-tile__figcaption .content-tile__title {
  font-family: var(--typefamily-alt)
}

.content-tile-color-block--full-img--left-margin img {
  height: auto;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center center;
  max-width: 100%
}

@media(min-width:768px) {

  .content-tile-color-block--full-img--left-margin figcaption,
  .content-tile-color-block--full-img--left-margin .content-tile__figcaption,
  .content-tile-color-block--full-img--left-margin picture {
    min-height: inherit
  }

  .content-tile-color-block--full-img--left-margin[class*="text-right"] figcaption::after,
  .content-tile-color-block--full-img--left-margin[class*="text-right"] .content-tile__figcaption::after {
    right: auto;
    left: -0.5rem
  }

  .content-tile-color-block--full-img--left-margin img {
    max-height: 100%
  }
}

@media(max-width:767px) {

  .content-tile-color-block--full-img--left-margin figure,
  .content-tile-color-block--full-img--left-margin .content-tile__figure {
    flex-direction: column
  }

  .content-tile-color-block--full-img--left-margin picture {
    position: relative;
    max-height: 345px
  }

  .content-tile-color-block--full-img--left-margin img {
    max-width: 100%;
    height: auto;
    max-height: inherit
  }

  .content-tile-color-block--full-img--left-margin figcaption,
  .content-tile-color-block--full-img--left-margin .content-tile__figcaption {
    margin-left: 0;
    max-width: 100%
  }

  .content-tile-color-block--full-img--left-margin figcaption::after,
  .content-tile-color-block--full-img--left-margin .content-tile__figcaption::after {
    top: -0.825rem;
    right: auto;
    left: 50%;
    transform: rotate(-45deg) translateX(-50%)
  }

  .content-tile-color-block--full-img--left-margin .content-tile__title {
    margin-top: 0
  }
}

.content-tile-color-block--full-img--left-margin picture {
  z-index: 1;
  height: 100%;
  right: 0
}

@media(max-width:767px) {

  .content-tile-color-block--full-img--left-margin figcaption,
  .content-tile-color-block--full-img--left-margin .content-tile__figcaption {
    margin-left: 0;
    width: 100%;
    min-width: 100%
  }

  .content-tile-color-block--full-img--left-margin picture {
    position: static;
    width: 100%
  }
}

@media(width:768px) {

  .content-tile-color-block--full-img--left-margin figcaption,
  .content-tile-color-block--full-img--left-margin .content-tile__figcaption,
  .content-tile-color-block--full-img--left-margin picture {
    width: 50%
  }
}

@media(min-width:768px) {
  .content-tile-color-block--full-img--left-margin picture {
    position: absolute
  }
}

@media(min-width:769px) {

  .content-tile-color-block--full-img--left-margin figcaption,
  .content-tile-color-block--full-img--left-margin .content-tile__figcaption {
    width: 31.62%;
    min-width: 370px;
    max-width: 455px
  }

  .content-tile-color-block--full-img--left-margin picture {
    width: 68.38%;
    max-width: calc(100% - 370px)
  }

  .container-fluid .content-tile-color-block--full-img--left-margin picture {
    width: 100%
  }
}

@media(min-width:768px) {

  .content-tile-color-block--full-img--left-margin figcaption,
  .content-tile-color-block--full-img--left-margin .content-tile__figcaption {
    margin-left: 4.27%
  }

  .content-tile-color-block--full-img--left-margin picture {
    width: 100%;
    max-width: 100%
  }
}

.content-tile-color-block--full-img--text-right {
  overflow: hidden
}

.content-tile-color-block--full-img--text-right .content-tile-container {
  position: relative;
  height: 100%
}

.content-tile-color-block--full-img--text-right figure,
.content-tile-color-block--full-img--text-right .content-tile__figure {
  display: flex;
  height: 100%
}

.content-tile-color-block--full-img--text-right figcaption,
.content-tile-color-block--full-img--text-right .content-tile__figcaption {
  height: 100%;
  position: relative;
  z-index: 2
}

@media(min-width:376px) {

  .content-tile-color-block--full-img--text-right figcaption,
  .content-tile-color-block--full-img--text-right .content-tile__figcaption {
    padding: var(--spacing-45)
  }
}

@media(max-width:375px) {

  .content-tile-color-block--full-img--text-right figcaption,
  .content-tile-color-block--full-img--text-right .content-tile__figcaption {
    padding: var(--spacing-30)
  }
}

.content-tile-color-block--full-img--text-right figcaption::after,
.content-tile-color-block--full-img--text-right .content-tile__figcaption::after {
  content: "";
  display: block;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 2.5rem;
  right: -0.5rem;
  transform: rotate(-45deg)
}

.content-tile-color-block--full-img--text-right figcaption .content-tile__title,
.content-tile-color-block--full-img--text-right .content-tile__figcaption .content-tile__title {
  font-family: var(--typefamily-alt)
}

.content-tile-color-block--full-img--text-right img {
  height: auto;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center center;
  max-width: 100%
}

@media(min-width:768px) {

  .content-tile-color-block--full-img--text-right figcaption,
  .content-tile-color-block--full-img--text-right .content-tile__figcaption,
  .content-tile-color-block--full-img--text-right picture {
    min-height: inherit
  }

  .content-tile-color-block--full-img--text-right[class*="text-right"] figcaption::after,
  .content-tile-color-block--full-img--text-right[class*="text-right"] .content-tile__figcaption::after {
    right: auto;
    left: -0.5rem
  }

  .content-tile-color-block--full-img--text-right img {
    max-height: 100%
  }
}

@media(max-width:767px) {

  .content-tile-color-block--full-img--text-right figure,
  .content-tile-color-block--full-img--text-right .content-tile__figure {
    flex-direction: column
  }

  .content-tile-color-block--full-img--text-right picture {
    position: relative;
    max-height: 345px
  }

  .content-tile-color-block--full-img--text-right img {
    max-width: 100%;
    height: auto;
    max-height: inherit
  }

  .content-tile-color-block--full-img--text-right figcaption,
  .content-tile-color-block--full-img--text-right .content-tile__figcaption {
    margin-left: 0;
    max-width: 100%
  }

  .content-tile-color-block--full-img--text-right figcaption::after,
  .content-tile-color-block--full-img--text-right .content-tile__figcaption::after {
    top: -0.825rem;
    right: auto;
    left: 50%;
    transform: rotate(-45deg) translateX(-50%)
  }

  .content-tile-color-block--full-img--text-right .content-tile__title {
    margin-top: 0
  }
}

.content-tile-color-block--full-img--text-right picture {
  z-index: 1;
  height: 100%;
  right: 0
}

@media(max-width:767px) {

  .content-tile-color-block--full-img--text-right figcaption,
  .content-tile-color-block--full-img--text-right .content-tile__figcaption {
    margin-left: 0;
    width: 100%;
    min-width: 100%
  }

  .content-tile-color-block--full-img--text-right picture {
    position: static;
    width: 100%
  }
}

@media(width:768px) {

  .content-tile-color-block--full-img--text-right figcaption,
  .content-tile-color-block--full-img--text-right .content-tile__figcaption,
  .content-tile-color-block--full-img--text-right picture {
    width: 50%
  }
}

@media(min-width:768px) {
  .content-tile-color-block--full-img--text-right picture {
    position: absolute
  }
}

@media(min-width:769px) {

  .content-tile-color-block--full-img--text-right figcaption,
  .content-tile-color-block--full-img--text-right .content-tile__figcaption {
    width: 31.62%;
    min-width: 370px;
    max-width: 455px
  }

  .content-tile-color-block--full-img--text-right picture {
    width: 68.38%;
    max-width: calc(100% - 370px)
  }

  .container-fluid .content-tile-color-block--full-img--text-right picture {
    width: 100%
  }
}

@media(min-width:769px) {

  .content-tile-color-block--full-img--text-right figure,
  .content-tile-color-block--full-img--text-right .content-tile__figure {
    grid-template-columns: 1fr clamp(370px, 31.62%, 455px)
  }

  .content-tile-color-block--full-img--text-right figcaption,
  .content-tile-color-block--full-img--text-right .content-tile__figcaption {
    width: 100%;
    min-width: auto
  }

  .content-tile-color-block--full-img--text-right picture {
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    height: 100%;
    width: 100%
  }
}

@media(min-width:768px) {

  .content-tile-color-block--full-img--text-right figure,
  .content-tile-color-block--full-img--text-right .content-tile__figure {
    display: grid
  }

  .content-tile-color-block--full-img--text-right figcaption,
  .content-tile-color-block--full-img--text-right .content-tile__figcaption {
    grid-column-start: 2
  }

  .content-tile-color-block--full-img--text-right picture {
    grid-column-start: 1;
    right: auto;
    left: 0
  }
}

@media(width:768px) {

  .content-tile-color-block--full-img--text-right figure,
  .content-tile-color-block--full-img--text-right .content-tile__figure {
    grid-template-columns: 1fr 1fr
  }

  .content-tile-color-block--full-img--text-right figcaption,
  .content-tile-color-block--full-img--text-right .content-tile__figcaption {
    width: 100%
  }
}

.content-tile-color-block--half-img {
  overflow: hidden
}

.content-tile-color-block--half-img .content-tile-container {
  position: relative;
  height: 100%
}

.content-tile-color-block--half-img figure,
.content-tile-color-block--half-img .content-tile__figure {
  display: flex;
  height: 100%
}

.content-tile-color-block--half-img figcaption,
.content-tile-color-block--half-img .content-tile__figcaption {
  height: 100%;
  position: relative;
  z-index: 2
}

@media(min-width:376px) {

  .content-tile-color-block--half-img figcaption,
  .content-tile-color-block--half-img .content-tile__figcaption {
    padding: var(--spacing-45)
  }
}

@media(max-width:375px) {

  .content-tile-color-block--half-img figcaption,
  .content-tile-color-block--half-img .content-tile__figcaption {
    padding: var(--spacing-30)
  }
}

.content-tile-color-block--half-img figcaption::after,
.content-tile-color-block--half-img .content-tile__figcaption::after {
  content: "";
  display: block;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 2.5rem;
  right: -0.5rem;
  transform: rotate(-45deg)
}

.content-tile-color-block--half-img figcaption .content-tile__title,
.content-tile-color-block--half-img .content-tile__figcaption .content-tile__title {
  font-family: var(--typefamily-alt)
}

.content-tile-color-block--half-img img {
  height: auto;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center center;
  max-width: 100%
}

@media(min-width:768px) {

  .content-tile-color-block--half-img figcaption,
  .content-tile-color-block--half-img .content-tile__figcaption,
  .content-tile-color-block--half-img picture {
    min-height: inherit
  }

  .content-tile-color-block--half-img[class*="text-right"] figcaption::after,
  .content-tile-color-block--half-img[class*="text-right"] .content-tile__figcaption::after {
    right: auto;
    left: -0.5rem
  }

  .content-tile-color-block--half-img img {
    max-height: 100%
  }
}

@media(max-width:767px) {

  .content-tile-color-block--half-img figure,
  .content-tile-color-block--half-img .content-tile__figure {
    flex-direction: column
  }

  .content-tile-color-block--half-img picture {
    position: relative;
    max-height: 345px
  }

  .content-tile-color-block--half-img img {
    max-width: 100%;
    height: auto;
    max-height: inherit
  }

  .content-tile-color-block--half-img figcaption,
  .content-tile-color-block--half-img .content-tile__figcaption {
    margin-left: 0;
    max-width: 100%
  }

  .content-tile-color-block--half-img figcaption::after,
  .content-tile-color-block--half-img .content-tile__figcaption::after {
    top: -0.825rem;
    right: auto;
    left: 50%;
    transform: rotate(-45deg) translateX(-50%)
  }

  .content-tile-color-block--half-img .content-tile__title {
    margin-top: 0
  }
}

@media(min-width:768px) {

  .content-tile-color-block--half-img figcaption,
  .content-tile-color-block--half-img .content-tile__figcaption {
    width: 50%
  }

  .content-tile-color-block--half-img picture {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    height: 100%;
    width: 50%
  }

  .content-tile-color-block--half-img img {
    max-width: 100%;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center center
  }
}

@media(max-width:767px) {

  .content-tile-color-block--half-img figcaption,
  .content-tile-color-block--half-img .content-tile__figcaption {
    width: 100%
  }

  .content-tile-color-block--half-img picture {
    width: 100%;
    height: auto;
    top: inherit;
    left: inherit
  }
}

.content-tile-color-block--half-img--text-right {
  overflow: hidden
}

.content-tile-color-block--half-img--text-right .content-tile-container {
  position: relative;
  height: 100%
}

.content-tile-color-block--half-img--text-right figure,
.content-tile-color-block--half-img--text-right .content-tile__figure {
  display: flex;
  height: 100%
}

.content-tile-color-block--half-img--text-right figcaption,
.content-tile-color-block--half-img--text-right .content-tile__figcaption {
  height: 100%;
  position: relative;
  z-index: 2
}

@media(min-width:376px) {

  .content-tile-color-block--half-img--text-right figcaption,
  .content-tile-color-block--half-img--text-right .content-tile__figcaption {
    padding: var(--spacing-45)
  }
}

@media(max-width:375px) {

  .content-tile-color-block--half-img--text-right figcaption,
  .content-tile-color-block--half-img--text-right .content-tile__figcaption {
    padding: var(--spacing-30)
  }
}

.content-tile-color-block--half-img--text-right figcaption::after,
.content-tile-color-block--half-img--text-right .content-tile__figcaption::after {
  content: "";
  display: block;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 2.5rem;
  right: -0.5rem;
  transform: rotate(-45deg)
}

.content-tile-color-block--half-img--text-right figcaption .content-tile__title,
.content-tile-color-block--half-img--text-right .content-tile__figcaption .content-tile__title {
  font-family: var(--typefamily-alt)
}

.content-tile-color-block--half-img--text-right img {
  height: auto;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center center;
  max-width: 100%
}

@media(min-width:768px) {

  .content-tile-color-block--half-img--text-right figcaption,
  .content-tile-color-block--half-img--text-right .content-tile__figcaption,
  .content-tile-color-block--half-img--text-right picture {
    min-height: inherit
  }

  .content-tile-color-block--half-img--text-right[class*="text-right"] figcaption::after,
  .content-tile-color-block--half-img--text-right[class*="text-right"] .content-tile__figcaption::after {
    right: auto;
    left: -0.5rem
  }

  .content-tile-color-block--half-img--text-right img {
    max-height: 100%
  }
}

@media(max-width:767px) {

  .content-tile-color-block--half-img--text-right figure,
  .content-tile-color-block--half-img--text-right .content-tile__figure {
    flex-direction: column
  }

  .content-tile-color-block--half-img--text-right picture {
    position: relative;
    max-height: 345px
  }

  .content-tile-color-block--half-img--text-right img {
    max-width: 100%;
    height: auto;
    max-height: inherit
  }

  .content-tile-color-block--half-img--text-right figcaption,
  .content-tile-color-block--half-img--text-right .content-tile__figcaption {
    margin-left: 0;
    max-width: 100%
  }

  .content-tile-color-block--half-img--text-right figcaption::after,
  .content-tile-color-block--half-img--text-right .content-tile__figcaption::after {
    top: -0.825rem;
    right: auto;
    left: 50%;
    transform: rotate(-45deg) translateX(-50%)
  }

  .content-tile-color-block--half-img--text-right .content-tile__title {
    margin-top: 0
  }
}

@media(min-width:768px) {

  .content-tile-color-block--half-img--text-right figcaption,
  .content-tile-color-block--half-img--text-right .content-tile__figcaption {
    width: 50%
  }

  .content-tile-color-block--half-img--text-right picture {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    height: 100%;
    width: 50%
  }

  .content-tile-color-block--half-img--text-right img {
    max-width: 100%;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center center
  }
}

@media(max-width:767px) {

  .content-tile-color-block--half-img--text-right figcaption,
  .content-tile-color-block--half-img--text-right .content-tile__figcaption {
    width: 100%
  }

  .content-tile-color-block--half-img--text-right picture {
    width: 100%;
    height: auto;
    top: inherit;
    left: inherit
  }
}

@media(min-width:768px) {

  .content-tile-color-block--half-img--text-right figure,
  .content-tile-color-block--half-img--text-right .content-tile__figure {
    display: grid;
    grid-template-columns: 1fr 1fr
  }

  .content-tile-color-block--half-img--text-right figcaption,
  .content-tile-color-block--half-img--text-right .content-tile__figcaption {
    grid-column-start: 2;
    width: auto
  }

  .content-tile-color-block--half-img--text-right picture {
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    height: 100%;
    width: 50%
  }

  .content-tile-color-block--half-img--text-right img {
    max-width: 100%;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center center
  }
}

[class*="content-tile-color-block--"] {
  background: transparent !important
}

[class*="content-tile-color-block--"].bgcolor--background-medium figcaption,
[class*="content-tile-color-block--"].bgcolor--background-medium .content-tile__figcaption,
[class*="content-tile-color-block--"].bgcolor--background-medium figcaption::after,
[class*="content-tile-color-block--"].bgcolor--background-medium .content-tile__figcaption::after {
  background: var(--color--ui-background-med, var(--color--background-medium))
}

[class*="content-tile-color-block--"].bgcolor--background-medium .content-tile__title {
  color: var(--color--text-primary)
}

[class*="content-tile-color-block--"].bgcolor--background-medium .content-tile-subtitle {
  color: var(--color--text-secondary)
}

[class*="content-tile-color-block--"].bgcolor--background-medium .content-tile-text *:not(a[href]:last-of-type) {
  color: var(--color--text-secondary)
}

[class*="content-tile-color-block--"].bgcolor--background-dark figcaption,
[class*="content-tile-color-block--"].bgcolor--background-dark .content-tile__figcaption,
[class*="content-tile-color-block--"].bgcolor--background-dark figcaption::after,
[class*="content-tile-color-block--"].bgcolor--background-dark .content-tile__figcaption::after {
  background: var(--color--ui-background-dark, var(--color--background-dark))
}

[class*="content-tile-color-block--"].bgcolor--background-dark .content-tile__title {
  color: var(--color--text-primary-inverse)
}

[class*="content-tile-color-block--"].bgcolor--background-dark .content-tile-subtitle {
  color: var(--color--text-secondary-inverse)
}

[class*="content-tile-color-block--"].bgcolor--background-dark .content-tile-text *:not(a[href]:last-of-type) {
  color: var(--color--text-secondary-inverse)
}

[class*="content-tile-color-block--"].bgcolor--background-light figcaption,
[class*="content-tile-color-block--"].bgcolor--background-light .content-tile__figcaption,
[class*="content-tile-color-block--"].bgcolor--background-light figcaption::after,
[class*="content-tile-color-block--"].bgcolor--background-light .content-tile__figcaption::after {
  background: var(--color--ui-background-light, var(--color--background-light))
}

[class*="content-tile-color-block--"].bgcolor--ui-01 figcaption,
[class*="content-tile-color-block--"].bgcolor--ui-01 .content-tile__figcaption,
[class*="content-tile-color-block--"].bgcolor--ui-01 figcaption::after,
[class*="content-tile-color-block--"].bgcolor--ui-01 .content-tile__figcaption::after {
  background: var(--color--ui-01)
}

[class*="content-tile-color-block--"].bgcolor--ui-04 figcaption,
[class*="content-tile-color-block--"].bgcolor--ui-04 .content-tile__figcaption,
[class*="content-tile-color-block--"].bgcolor--ui-04 figcaption::after,
[class*="content-tile-color-block--"].bgcolor--ui-04 .content-tile__figcaption::after {
  background: var(--color--ui-04)
}

[class*="content-tile-color-block--"].bgcolor--ui-05 figcaption,
[class*="content-tile-color-block--"].bgcolor--ui-05 .content-tile__figcaption,
[class*="content-tile-color-block--"].bgcolor--ui-05 figcaption::after,
[class*="content-tile-color-block--"].bgcolor--ui-05 .content-tile__figcaption::after {
  background: var(--color--ui-05)
}

.content-tile-info-small-img {
  background-color: var(--color--ui-02)
}

.content-tile-info-small-img figure,
.content-tile-info-small-img .content-tile__figure {
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 20px;
  padding: 30px
}

@media(min-width:992px) {

  .content-tile-info-small-img figure,
  .content-tile-info-small-img .content-tile__figure {
    grid-template-columns: 60px 1fr
  }
}

@media(max-width:991px) {

  .content-tile-info-small-img figure,
  .content-tile-info-small-img .content-tile__figure {
    grid-template-columns: 48px 1fr
  }
}

.content-tile-info-small-img figcaption,
.content-tile-info-small-img .content-tile__figcaption {
  display: contents
}

@media(min-width:992px) {
  .content-tile-info-small-img picture {
    width: 60px;
    height: 60px
  }
}

@media(max-width:991px) {
  .content-tile-info-small-img picture {
    width: 48px;
    height: 48px
  }
}

.content-tile-info-small-img .content-tile__title {
  font-size: var(--typesize-h3);
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto
}

@media(min-width:992px) {
  .content-tile-info-small-img .content-tile__title {
    transform: translateY(15px)
  }
}

@media(max-width:991px) {
  .content-tile-info-small-img .content-tile__title {
    transform: translateY(12px)
  }
}

.content-tile-info-small-img .content-tile-subtitle {
  font-weight: var(--typeweight--bold)
}

.content-tile-info-small-img .content-tile-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: 0
}

.content-tile-info-small-img .content-tile-text p:last-of-type {
  margin-top: auto
}

.content-tile-info-small-img .content-tile-text p:last-of-type::before {
  display: block;
  content: "";
  height: 1px;
  margin: 20px auto;
  width: 100%;
  background-color: var(--color--ui-border-01, var(--color--border-01))
}

.flex-layout .content-tile-info-small-img .content-tile-text p:last-of-type::before {
  margin-top: unset
}

.behavior--show-more .content-tile-text {
  opacity: 0;
  visibility: hidden;
  max-height: 300px;
  transition: all .125s ease-in-out
}

.behavior--show-more .content-tile-text.is-visible {
  opacity: 1;
  visibility: visible;
  max-height: none
}

body.edit .behavior--show-more .content-tile-text,
body.cq-wcm-edit .behavior--show-more .content-tile-text {
  opacity: 1;
  visibility: visible;
  max-height: none
}

.text-toggle__trigger-container {
  margin-top: 20px
}

.text-toggle__trigger.text-toggle__trigger {
  font-size: inherit;
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1);
  color: var(--color--text-link);
  border: 0;
  background-color: transparent;
  transition: outline .2s ease-in-out;
  padding: 0 0 .05em 0;
  margin: 0;
  border-bottom: 0
}

.text-toggle__trigger.text-toggle__trigger:focus,
.text-toggle__trigger.text-toggle__trigger:focus-visible {
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--color--interactive-focus-field)
}

@media(max-width:375px) {
  .text-toggle__trigger.text-toggle__trigger {
    text-align: left
  }
}

.text-toggle__trigger.text-toggle__trigger span {
  font-weight: var(--typeweight--bold);
  color: var(--color--text-link);
  transition: all .2s ease-in-out;
  display: inline-block;
  border-bottom: 1px solid transparent;
  margin-top: .15em
}

.text-toggle__trigger.text-toggle__trigger::after {
  display: inline-block;
  content: "";
  margin-left: 5px;
  color: var(--color--text-link);
  border-bottom: 1px solid transparent
}

.text-toggle__trigger.text-toggle__trigger:hover,
.text-toggle__trigger.text-toggle__trigger:active,
.text-toggle__trigger.text-toggle__trigger:focus {
  border-bottom: 0
}

.text-toggle__trigger.text-toggle__trigger:hover span,
.text-toggle__trigger.text-toggle__trigger:active span,
.text-toggle__trigger.text-toggle__trigger:focus span {
  border-bottom-color: var(--color--text-link)
}

.text-toggle__trigger.text-toggle__trigger[aria-expanded="false"]::after {
  content: "+"
}

.text-toggle__trigger.text-toggle__trigger[aria-expanded="true"]::after {
  content: "-"
}

.color--text-primary-inverse.color--text-primary-inverse .text-toggle__trigger.text-toggle__trigger span,
.color--text-primary-inverse.color--text-primary-inverse .text-toggle__trigger.text-toggle__trigger::after {
  color: var(--color--text-link-inverse)
}

.color--text-primary-inverse.color--text-primary-inverse .text-toggle__trigger.text-toggle__trigger:hover span,
.color--text-primary-inverse.color--text-primary-inverse .text-toggle__trigger.text-toggle__trigger:active span,
.color--text-primary-inverse.color--text-primary-inverse .text-toggle__trigger.text-toggle__trigger:focus:not(:focus-visible) span {
  border-bottom-color: var(--color--text-link-inverse)
}

@supports selector(:focus-visible) {
  .text-toggle__trigger:focus-visible {
    border-bottom: 0;
    outline: 1px solid var(--color--interactive-focus);
    outline-offset: 6px
  }

  .text-toggle__trigger:focus-visible span {
    border-bottom: 0
  }

  .color--text-primary-inverse .text-toggle__trigger:focus-visible {
    outline: 1px solid var(--color--text-link-inverse)
  }
}

.content-tile.content-tile-panel-item,
.content-tile.content-tile-panel-item-main {
  background-color: var(--color--ui-02);
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out
}

.content-tile.content-tile-panel-item .content-tile-container,
.content-tile.content-tile-panel-item-main .content-tile-container {
  height: 100%
}

.content-tile.content-tile-panel-item figure,
.content-tile.content-tile-panel-item-main figure,
.content-tile.content-tile-panel-item .content-tile__figure,
.content-tile.content-tile-panel-item-main .content-tile__figure {
  clear: both;
  padding: 0;
  overflow: visible
}

.content-tile.content-tile-panel-item picture,
.content-tile.content-tile-panel-item-main picture {
  display: block;
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  margin: 0
}

.content-tile.content-tile-panel-item img,
.content-tile.content-tile-panel-item-main img {
  display: block;
  width: auto
}

.content-tile.content-tile-panel-item figcaption,
.content-tile.content-tile-panel-item-main figcaption,
.content-tile.content-tile-panel-item .content-tile__figcaption,
.content-tile.content-tile-panel-item-main .content-tile__figcaption {
  width: 100%
}

@media(min-width:769px) {

  .content-tile.content-tile-panel-item figcaption,
  .content-tile.content-tile-panel-item-main figcaption,
  .content-tile.content-tile-panel-item .content-tile__figcaption,
  .content-tile.content-tile-panel-item-main .content-tile__figcaption {
    padding: var(--spacing-30)
  }
}

@media(max-width:768px) {

  .content-tile.content-tile-panel-item figcaption,
  .content-tile.content-tile-panel-item-main figcaption,
  .content-tile.content-tile-panel-item .content-tile__figcaption,
  .content-tile.content-tile-panel-item-main .content-tile__figcaption {
    padding: var(--spacing-20)
  }
}

.content-tile.content-tile-panel-item .content-tile__title,
.content-tile.content-tile-panel-item-main .content-tile__title {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  padding-bottom: .125em;
  font-size: var(--typesize-h3);
  margin: 0
}

.content-tile.content-tile-panel-item .content-tile-subtitle,
.content-tile.content-tile-panel-item-main .content-tile-subtitle {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  line-height: var(--leading--1-4);
  padding-top: 15px;
  margin-top: 0
}

.content-tile.content-tile-panel-item .content-tile-text,
.content-tile.content-tile-panel-item-main .content-tile-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  height: 100%
}

*+.content-tile.content-tile-panel-item .content-tile-text,
*+.content-tile.content-tile-panel-item-main .content-tile-text {
  margin-top: 20px
}

.content-tile.content-tile-panel-item a[id^="icon"],
.content-tile.content-tile-panel-item-main a[id^="icon"] {
  position: relative;
  line-height: var(--leading--1-5)
}

.content-tile.content-tile-panel-item a[id^="icon"] br,
.content-tile.content-tile-panel-item-main a[id^="icon"] br {
  display: none
}

.content-tile.content-tile-panel-item a[id^="icon"]::after,
.content-tile.content-tile-panel-item-main a[id^="icon"]::after {
  color: inherit
}

.content-tile.content-tile-panel-item .content-tile-subtitle,
.content-tile.content-tile-panel-item-main .content-tile-subtitle {
  font-weight: var(--typeweight--bold)
}

.content-tile.content-tile-panel-item picture,
.content-tile.content-tile-panel-item-main picture {
  position: relative;
  width: calc(100% + 2px);
  left: -1px
}

.content-tile.content-tile-panel-item img,
.content-tile.content-tile-panel-item-main img {
  width: 100%;
  object-fit: cover;
  object-position: top center;
  transform: translateZ(0) scale(1);
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out
}

.content-tile.content-tile-panel-item:hover,
.content-tile.content-tile-panel-item-main:hover {
  -webkit-box-shadow: 4px 4px 15px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 4px 4px 15px 0 rgba(0, 0, 0, 0.25)
}

.content-tile.content-tile-panel-item:hover img,
.content-tile.content-tile-panel-item-main:hover img {
  transform: scale(1.1)
}

.content-tile.content-tile-panel-item .content-tile-text p:last-of-type::before,
.content-tile.content-tile-panel-item-main .content-tile-text p:last-of-type::before {
  display: block;
  content: "";
  height: 1px;
  margin: 20px auto;
  width: 100%;
  background-color: var(--color--ui-border-01, var(--color--border-01))
}

.flex-layout .content-tile.content-tile-panel-item .content-tile-text p:last-of-type::before,
.flex-layout .content-tile.content-tile-panel-item-main .content-tile-text p:last-of-type::before {
  margin-top: unset
}

.content-tile.content-tile-panel-item .content-tile__title {
  font-size: var(--typesize-h6)
}

.content-tile-panel-item-simple {
  background-color: var(--color--ui-02)
}

.content-tile-panel-item-simple .content-tile-container {
  height: 100%
}

.content-tile-panel-item-simple figure,
.content-tile-panel-item-simple .content-tile__figure {
  clear: both;
  padding: 0;
  overflow: visible
}

.content-tile-panel-item-simple picture {
  display: block;
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  margin: 0
}

.content-tile-panel-item-simple img {
  display: block;
  width: auto
}

.content-tile-panel-item-simple figcaption,
.content-tile-panel-item-simple .content-tile__figcaption {
  width: 100%
}

@media(min-width:769px) {

  .content-tile-panel-item-simple figcaption,
  .content-tile-panel-item-simple .content-tile__figcaption {
    padding: var(--spacing-30)
  }
}

@media(max-width:768px) {

  .content-tile-panel-item-simple figcaption,
  .content-tile-panel-item-simple .content-tile__figcaption {
    padding: var(--spacing-20)
  }
}

.content-tile-panel-item-simple .content-tile__title {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  padding-bottom: .125em;
  font-size: var(--typesize-h3);
  margin: 0
}

.content-tile-panel-item-simple .content-tile-subtitle {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  line-height: var(--leading--1-4);
  padding-top: 15px;
  margin-top: 0
}

.content-tile-panel-item-simple .content-tile-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  height: 100%
}

*+.content-tile-panel-item-simple .content-tile-text {
  margin-top: 20px
}

.content-tile-panel-item-simple a[id^="icon"] {
  position: relative;
  line-height: var(--leading--1-5)
}

.content-tile-panel-item-simple a[id^="icon"] br {
  display: none
}

.content-tile-panel-item-simple a[id^="icon"]::after {
  color: inherit
}

.content-tile-panel-item-simple .content-tile__title {
  font-size: var(--typesize-h5);
  font-weight: var(--typeweight--default);
  border-bottom: 0;
  padding: 0
}

.content-tile-panel-item-simple .content-tile-subtitle {
  font-weight: var(--typeweight--bold)
}

.content-tile-product-card {
  background-color: var(--color--ui-02)
}

.content-tile-product-card .content-tile-container {
  height: 100%
}

.content-tile-product-card figure,
.content-tile-product-card .content-tile__figure {
  clear: both;
  padding: 0;
  overflow: visible
}

.content-tile-product-card picture {
  display: block;
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  margin: 0
}

.content-tile-product-card img {
  display: block;
  width: auto
}

.content-tile-product-card figcaption,
.content-tile-product-card .content-tile__figcaption {
  width: 100%
}

@media(min-width:769px) {

  .content-tile-product-card figcaption,
  .content-tile-product-card .content-tile__figcaption {
    padding: var(--spacing-30)
  }
}

@media(max-width:768px) {

  .content-tile-product-card figcaption,
  .content-tile-product-card .content-tile__figcaption {
    padding: var(--spacing-20)
  }
}

.content-tile-product-card .content-tile__title {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  padding-bottom: .125em;
  font-size: var(--typesize-h3);
  margin: 0
}

.content-tile-product-card .content-tile-subtitle {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  line-height: var(--leading--1-4);
  padding-top: 15px;
  margin-top: 0
}

.content-tile-product-card .content-tile-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  height: 100%
}

*+.content-tile-product-card .content-tile-text {
  margin-top: 20px
}

.content-tile-product-card a[id^="icon"] {
  position: relative;
  line-height: var(--leading--1-5)
}

.content-tile-product-card a[id^="icon"] br {
  display: none
}

.content-tile-product-card a[id^="icon"]::after {
  color: inherit
}

.content-tile-product-card p,
.content-tile-product-card .p,
.content-tile-product-card .p *,
.content-tile-product-card blockquote,
.content-tile-product-card figcaption,
.content-tile-product-card cite,
.content-tile-product-card dl,
.content-tile-product-card ol,
.content-tile-product-card ul {
  font-size: var(--typesize-minor)
}

.content-tile-product-card .content-tile__title {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-h4);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0;
  border-block-end: none;
  padding: 0
}

.content-tile-product-card .content-tile-subtitle {
  font-weight: var(--typeweight--bold)
}

.content-tile-product-card .content-tile-text p:last-of-type {
  padding-block-start: var(--spacing-15)
}

@media(min-width:768px) {
  .section-feature.section-feature {
    overflow: visible
  }
}

.section-feature.section-feature::before {
  filter: none
}

.section-feature.section-feature::after {
  display: none
}

.section-feature__item .content-tile__figure,
.section-feature__item figure {
  position: relative;
  word-break: unset;
  word-wrap: normal;
  hyphens: none;
  display: flex
}

.section-feature__item picture {
  flex: 0 0 32px
}

.section-feature__item img {
  margin: auto;
  width: 100%
}

.section-feature__item .content-tile__figcaption,
.section-feature__item figcaption {
  margin-left: 15px
}

.section-feature__item .content-tile-text {
  margin-top: -0.5em
}

@media(min-width:376px) {
  .section-feature__item p {
    font-size: var(--typesize-h6);
    line-height: var(--leading--1-8)
  }
}

@media(max-width:375px) {
  .section-feature__item p {
    line-height: var(--leading--1-5)
  }
}

@media(min-width:376px) {
  .section-feature__item+.section-feature__item {
    margin-top: 40px !important
  }
}

@media(max-width:375px) {
  .section-feature__item+.section-feature__item {
    margin-top: 30px !important
  }
}

@media(min-width:769px) {
  body:not(.edit):not(.cq-wcm-edit) .section-feature__media img {
    margin-right: 30px;
    margin-left: auto
  }
}

body:not(.edit):not(.cq-wcm-edit) .section-feature__media.display-mobile {
  margin-top: -7.5vh
}

body:not(.edit):not(.cq-wcm-edit) .section-feature__media.display-mobile img {
  margin: auto
}

@media(prefers-reduced-motion:no-preference) {
  .section-feature.animated .section-feature__item {
    opacity: 0;
    transform: translate(0, 20px)
  }

  .section-feature.animated.in-view .section-feature__item {
    animation-duration: .5s;
    animation-name: slidein;
    animation-fill-mode: forwards
  }
}

.content-tile.content-tile-panel-item-homepage {
  background-color: var(--color--ui-02)
}

.content-tile.content-tile-panel-item-homepage .content-tile-container {
  height: 100%
}

.content-tile.content-tile-panel-item-homepage figure,
.content-tile.content-tile-panel-item-homepage .content-tile__figure {
  clear: both;
  padding: 0;
  overflow: visible
}

.content-tile.content-tile-panel-item-homepage picture {
  display: block;
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  margin: 0
}

.content-tile.content-tile-panel-item-homepage img {
  display: block;
  width: auto
}

.content-tile.content-tile-panel-item-homepage figcaption,
.content-tile.content-tile-panel-item-homepage .content-tile__figcaption {
  width: 100%
}

@media(min-width:769px) {

  .content-tile.content-tile-panel-item-homepage figcaption,
  .content-tile.content-tile-panel-item-homepage .content-tile__figcaption {
    padding: var(--spacing-30)
  }
}

@media(max-width:768px) {

  .content-tile.content-tile-panel-item-homepage figcaption,
  .content-tile.content-tile-panel-item-homepage .content-tile__figcaption {
    padding: var(--spacing-20)
  }
}

.content-tile.content-tile-panel-item-homepage .content-tile__title {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  padding-bottom: .125em;
  font-size: var(--typesize-h3);
  margin: 0
}

.content-tile.content-tile-panel-item-homepage .content-tile-subtitle {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  line-height: var(--leading--1-4);
  padding-top: 15px;
  margin-top: 0
}

.content-tile.content-tile-panel-item-homepage .content-tile-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  height: 100%
}

*+.content-tile.content-tile-panel-item-homepage .content-tile-text {
  margin-top: 20px
}

.content-tile.content-tile-panel-item-homepage a[id^="icon"] {
  position: relative;
  line-height: var(--leading--1-5)
}

.content-tile.content-tile-panel-item-homepage a[id^="icon"] br {
  display: none
}

.content-tile.content-tile-panel-item-homepage a[id^="icon"]::after {
  color: inherit
}

@media(min-width:769px) {

  .content-tile.content-tile-panel-item-homepage figcaption,
  .content-tile.content-tile-panel-item-homepage .content-tile__figcaption {
    padding: var(--space-medium)
  }
}

@media(max-width:768px) {

  .content-tile.content-tile-panel-item-homepage figcaption,
  .content-tile.content-tile-panel-item-homepage .content-tile__figcaption {
    padding: var(--space-small)
  }
}

.content-tile.content-tile-panel-item-homepage .content-tile__title {
  font-size: var(--typesize-h1-catalog);
  line-height: var(--leading--1-2)
}

.content-tile.content-tile-panel-item-homepage .content-tile-subtitle {
  font-weight: var(--typeweight--bold)
}

.content-tile.content-tile-panel-item-homepage picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center
}

.content-tile.content-tile-panel-item-homepage-v2 {
  background-color: var(--color--ui-02)
}

.content-tile.content-tile-panel-item-homepage-v2 .content-tile-container {
  height: 100%
}

.content-tile.content-tile-panel-item-homepage-v2 figure,
.content-tile.content-tile-panel-item-homepage-v2 .content-tile__figure {
  clear: both;
  padding: 0;
  overflow: visible
}

.content-tile.content-tile-panel-item-homepage-v2 picture {
  display: block;
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  margin: 0
}

.content-tile.content-tile-panel-item-homepage-v2 img {
  display: block;
  width: auto
}

.content-tile.content-tile-panel-item-homepage-v2 figcaption,
.content-tile.content-tile-panel-item-homepage-v2 .content-tile__figcaption {
  width: 100%
}

@media(min-width:769px) {

  .content-tile.content-tile-panel-item-homepage-v2 figcaption,
  .content-tile.content-tile-panel-item-homepage-v2 .content-tile__figcaption {
    padding: var(--spacing-30)
  }
}

@media(max-width:768px) {

  .content-tile.content-tile-panel-item-homepage-v2 figcaption,
  .content-tile.content-tile-panel-item-homepage-v2 .content-tile__figcaption {
    padding: var(--spacing-20)
  }
}

.content-tile.content-tile-panel-item-homepage-v2 .content-tile__title {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  padding-bottom: .125em;
  font-size: var(--typesize-h3);
  margin: 0
}

.content-tile.content-tile-panel-item-homepage-v2 .content-tile-subtitle {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  line-height: var(--leading--1-4);
  padding-top: 15px;
  margin-top: 0
}

.content-tile.content-tile-panel-item-homepage-v2 .content-tile-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  height: 100%
}

*+.content-tile.content-tile-panel-item-homepage-v2 .content-tile-text {
  margin-top: 20px
}

.content-tile.content-tile-panel-item-homepage-v2 a[id^="icon"] {
  position: relative;
  line-height: var(--leading--1-5)
}

.content-tile.content-tile-panel-item-homepage-v2 a[id^="icon"] br {
  display: none
}

.content-tile.content-tile-panel-item-homepage-v2 a[id^="icon"]::after {
  color: inherit
}

@media(min-width:769px) {

  .content-tile.content-tile-panel-item-homepage-v2 figcaption,
  .content-tile.content-tile-panel-item-homepage-v2 .content-tile__figcaption {
    padding: var(--space-medium)
  }
}

@media(max-width:768px) {

  .content-tile.content-tile-panel-item-homepage-v2 figcaption,
  .content-tile.content-tile-panel-item-homepage-v2 .content-tile__figcaption {
    padding: var(--space-small)
  }
}

.content-tile.content-tile-panel-item-homepage-v2 .content-tile__title {
  font-size: var(--typesize-h1-catalog);
  line-height: var(--leading--1-2)
}

.content-tile.content-tile-panel-item-homepage-v2 .content-tile-subtitle {
  font-weight: var(--typeweight--bold)
}

.content-tile.content-tile-panel-item-homepage-v2 picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center
}

.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary);
  transition: color .125s ease-in-out
}

@media(min-width:376px) {
  .content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:hover,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:focus,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:focus-visible,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:hover::before,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:focus::before,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:focus-visible::before,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:active::before {
  transform: scale(1.05)
}

.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:focus,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:focus-visible {
  outline: 0
}

.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:focus::before,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:active::before {
  outline-color: transparent
}

.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:only-of-type {
  margin: 0
}

.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type::before {
  outline-color: var(--color--interactive-tertiary);
  outline-offset: -2px;
  background-color: transparent
}

.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:hover {
  color: var(--color--text-primary-inverse)
}

.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:hover::before {
  outline-color: var(--color--interactive-tertiary-hover);
  background: var(--color--interactive-tertiary-hover)
}

.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:focus,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:focus-visible {
  color: var(--color--text-tertiary)
}

.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:focus::before,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:active {
  color: var(--color--text-primary-inverse)
}

.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type:active::before {
  background: var(--color--interactive-tertiary-active)
}

.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type[disabled],
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type[disabled]::before,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type[disabled]:hover::before,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type.button-disabled:hover::before,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type[disabled]:focus::before,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type.button-disabled:focus::before,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type[disabled]:focus-visible::before,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type.button-disabled:focus-visible::before,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type[disabled]:active::before,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type[disabled]::before,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type.button-disabled::before {
  outline-color: var(--color--interactive-disabled-01);
  outline-offset: -2px;
  background: transparent
}

.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type[disabled]:hover::before,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type.button-disabled:hover::before,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type[disabled]:focus::before,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type.button-disabled:focus::before,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type[disabled]:focus-visible::before,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type.button-disabled:focus-visible::before,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type[disabled]:active::before,
.content-tile.content-tile-panel-item-homepage-v2 p:last-of-type a:last-of-type.button-disabled:active::before {
  background: transparent
}

[class*="c-promo"] {
  position: relative
}

[class*="c-promo"] .content-tile-container {
  position: relative
}

@media(min-width:769px) {
  [class*="c-promo"] .content-tile-container {
    aspect-ratio: 2
  }
}

[class*="c-promo"] figure,
[class*="c-promo"] .content-tile__figure {
  overflow: hidden;
  min-height: 100%
}

@media(min-width:769px) {

  [class*="c-promo"] figure,
  [class*="c-promo"] .content-tile__figure {
    display: flex
  }
}

@media(max-width:768px) {

  [class*="c-promo"] figure,
  [class*="c-promo"] .content-tile__figure {
    flex-direction: column
  }
}

[class*="img-background"][class*="c-promo"] figure,
[class*="img-background"][class*="c-promo"] .content-tile__figure {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  overflow: visible
}

@media(width:768px) {

  [class*="img-background"][class*="c-promo"] figure,
  [class*="img-background"][class*="c-promo"] .content-tile__figure {
    aspect-ratio: 1
  }
}

[class*="c-promo"] picture {
  aspect-ratio: unset
}

@media(min-width:769px) {

  [class*="-img-right"][class*="c-promo"] picture,
  [class*="-img-left"][class*="c-promo"] picture {
    flex: 0 0 50%;
    max-width: 50%;
    aspect-ratio: 1
  }

  [class*="-img-right"][class*="c-promo"] picture {
    order: 2;
    margin-left: auto
  }

  [class*="-img-left"][class*="c-promo"] picture {
    order: 1;
    margin-right: auto
  }
}

[class*="img-background"][class*="c-promo"] picture {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  z-index: 1
}

[class*="img-background"][class*="c-promo"] picture::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  opacity: .5;
  position: absolute;
  top: 0;
  left: 0
}

[class*="c-promo"] img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center
}

[class*="-img-right"][class*="c-promo"] img,
[class*="-img-left"][class*="c-promo"] img {
  aspect-ratio: 1
}

@media(min-width:769px) {
  [class*="-img-background"][class*="c-promo"] img {
    aspect-ratio: 2
  }
}

@media(min-width:769px) {

  [class*="c-promo"] figcaption,
  [class*="c-promo"] .content-tile__figcaption {
    padding: clamp(var(--space-medium), 5.2%, var(--space-xlarge));
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center
  }

  [class*="-img-right"][class*="c-promo"] figcaption,
  [class*="-img-right"][class*="c-promo"] .content-tile__figcaption {
    order: 1
  }

  [class*="-img-left"][class*="c-promo"] figcaption,
  [class*="-img-left"][class*="c-promo"] .content-tile__figcaption {
    order: 2
  }
}

@media(max-width:768px) {

  [class*="c-promo"] figcaption,
  [class*="c-promo"] .content-tile__figcaption {
    padding: clamp(var(--space-small), 5.2%, var(--space-large))
  }
}

[class*="img-background"][class*="c-promo"] figcaption,
[class*="img-background"][class*="c-promo"] .content-tile__figcaption {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  z-index: 2
}

@media(min-width:1024px) {

  [class*="img-background"][class*="c-promo"] figcaption,
  [class*="img-background"][class*="c-promo"] .content-tile__figcaption {
    max-width: 50%
  }
}

@media(min-width:769px) {

  [class*="text-right"][class*="img-background"][class*="c-promo"] figcaption,
  [class*="text-right"][class*="img-background"][class*="c-promo"] .content-tile__figcaption {
    flex: 1 1 50%;
    margin-left: auto
  }
}

[class*="c-promo"] .content-tile__title {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h1);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-15);
  margin-bottom: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto
}

.typefamily-primary [class*="c-promo"] .content-tile__title {
  font-weight: var(--typeweight--light)
}

*+[class*="c-promo"] .content-tile-text {
  margin-top: var(--space-medium)
}

[class*="c-promo"] .content-tile-text p {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-h4);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

@media(min-width:769px) {
  [class*="c-promo"] .content-tile-text p:last-of-type:not(:only-of-type) {
    margin-top: var(--space-xlarge)
  }
}

@media(max-width:768px) {
  [class*="c-promo"] .content-tile-text p:last-of-type:not(:only-of-type) {
    margin-top: var(--space-large)
  }
}

@media(min-width:1920px) {

  [class*="c-promo"][class*="curve-top"] figcaption,
  [class*="c-promo"][class*="curve-top"] .content-tile__figcaption {
    padding-top: 25%
  }
}

@media(min-width:376px) and (max-width:1919px) {

  [class*="c-promo"][class*="curve-top"] figcaption,
  [class*="c-promo"][class*="curve-top"] .content-tile__figcaption {
    padding-top: 15%
  }
}

@media(max-width:375px) {

  [class*="c-promo"][class*="curve-top"] figcaption,
  [class*="c-promo"][class*="curve-top"] .content-tile__figcaption {
    padding-top: 50%
  }
}

@media(min-width:769px) {
  [class*="c-promo"]:not([class*="img-background"]) .content-tile-text>* {
    max-width: 40ch
  }
}

[class*="c-promo"][class*="img-background"].c-gradient-overlay::after,
[class*="c-promo"][class*="img-background"][class*="use-gradient-overlay"]::after {
  z-index: 1
}

[class*="c-promo-dark"] {
  background-color: var(--color--ui-background-dark, var(--color--background-dark))
}

[class*="c-promo-dark"][class*="img-background"] picture::after {
  background-color: var(--color--ui-background-dark, var(--color--background-dark))
}

[class*="c-promo-dark"] .content-tile__title {
  color: var(--color--text-primary-inverse)
}

[class*="c-promo-dark"] .content-tile-subtitle {
  color: var(--color--text-secondary-inverse)
}

[class*="c-promo-dark"] .content-tile-text *:not(a[href]) {
  color: var(--color--text-tertiary-inverse)
}

[class*="c-promo-dark"] .content-tile-text a[href] {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary)
}

@media(min-width:376px) {
  [class*="c-promo-dark"] .content-tile-text a[href] {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  [class*="c-promo-dark"] .content-tile-text a[href] {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

[class*="c-promo-dark"] .content-tile-text a[href]::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

[class*="c-promo-dark"] .content-tile-text a[href]:hover,
[class*="c-promo-dark"] .content-tile-text a[href]:focus,
[class*="c-promo-dark"] .content-tile-text a[href]:focus-visible,
[class*="c-promo-dark"] .content-tile-text a[href]:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

[class*="c-promo-dark"] .content-tile-text a[href]:hover::before,
[class*="c-promo-dark"] .content-tile-text a[href]:focus::before,
[class*="c-promo-dark"] .content-tile-text a[href]:focus-visible::before,
[class*="c-promo-dark"] .content-tile-text a[href]:active::before {
  transform: scale(1.05)
}

[class*="c-promo-dark"] .content-tile-text a[href]:focus,
[class*="c-promo-dark"] .content-tile-text a[href]:focus-visible {
  outline: 0
}

[class*="c-promo-dark"] .content-tile-text a[href]:focus::before,
[class*="c-promo-dark"] .content-tile-text a[href]:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

[class*="c-promo-dark"] .content-tile-text a[href]:active::before {
  outline-color: transparent
}

[class*="c-promo-dark"] .content-tile-text a[href]:only-of-type {
  margin: 0
}

[class*="c-promo-dark"] .content-tile-text a[href].icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

[class*="c-promo-dark"] .content-tile-text a[href]::before {
  background: var(--color--interactive-secondary-inverse)
}

[class*="c-promo-dark"] .content-tile-text a[href]:hover {
  color: var(--color--text-primary)
}

[class*="c-promo-dark"] .content-tile-text a[href]:hover::before {
  background: var(--color--interactive-secondary-inverse-hover, var(--color--interactive-secondary-hover-inverse))
}

[class*="c-promo-dark"] .content-tile-text a[href]:focus,
[class*="c-promo-dark"] .content-tile-text a[href]:focus-visible {
  color: var(--color--text-primary)
}

[class*="c-promo-dark"] .content-tile-text a[href]:focus::before,
[class*="c-promo-dark"] .content-tile-text a[href]:focus-visible::before {
  background: var(--color--interactive-secondary-inverse)
}

[class*="c-promo-dark"] .content-tile-text a[href]:active {
  color: var(--color--text-primary)
}

[class*="c-promo-dark"] .content-tile-text a[href]:active::before {
  background: var(--color--interactive-secondary-inverse-active, var(--color--interactive-secondary-active-inverse))
}

[class*="c-promo-dark"] .content-tile-text a[href][disabled],
[class*="c-promo-dark"] .content-tile-text a[href].button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02);
  color: var(--color--interactive-disabled-03)
}

[class*="c-promo-dark"] .content-tile-text a[href][disabled]::before,
[class*="c-promo-dark"] .content-tile-text a[href].button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

[class*="c-promo-dark"] .content-tile-text a[href][disabled]:hover::before,
[class*="c-promo-dark"] .content-tile-text a[href].button-disabled:hover::before,
[class*="c-promo-dark"] .content-tile-text a[href][disabled]:focus::before,
[class*="c-promo-dark"] .content-tile-text a[href].button-disabled:focus::before,
[class*="c-promo-dark"] .content-tile-text a[href][disabled]:focus-visible::before,
[class*="c-promo-dark"] .content-tile-text a[href].button-disabled:focus-visible::before,
[class*="c-promo-dark"] .content-tile-text a[href][disabled]:active::before,
[class*="c-promo-dark"] .content-tile-text a[href].button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

[class*="c-promo-dark"] .content-tile-text a[href][disabled]::before,
[class*="c-promo-dark"] .content-tile-text a[href].button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

[class*="c-promo-dark"] .content-tile-text a[href][disabled]:hover::before,
[class*="c-promo-dark"] .content-tile-text a[href].button-disabled:hover::before,
[class*="c-promo-dark"] .content-tile-text a[href][disabled]:focus::before,
[class*="c-promo-dark"] .content-tile-text a[href].button-disabled:focus::before,
[class*="c-promo-dark"] .content-tile-text a[href][disabled]:focus-visible::before,
[class*="c-promo-dark"] .content-tile-text a[href].button-disabled:focus-visible::before,
[class*="c-promo-dark"] .content-tile-text a[href][disabled]:active::before,
[class*="c-promo-dark"] .content-tile-text a[href].button-disabled:active::before {
  background: var(--color--interactive-disabled-01)
}

[class*="c-promo-light"] {
  background-color: var(--color--ui-background-med, var(--color--background-medium))
}

[class*="c-promo-light"][class*="img-background"] picture::after {
  background-color: var(--color--ui-background-med, var(--color--background-medium))
}

[class*="c-promo-light"] .content-tile__title {
  color: var(--color--text-primary)
}

[class*="c-promo-light"] .content-tile-subtitle {
  color: var(--color--text-secondary)
}

[class*="c-promo-light"] .content-tile-text *:not(a[href]) {
  color: var(--color--text-tertiary)
}

[class*="c-promo-light"] .content-tile-text a[href] {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1
}

@media(min-width:376px) {
  [class*="c-promo-light"] .content-tile-text a[href] {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  [class*="c-promo-light"] .content-tile-text a[href] {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

[class*="c-promo-light"] .content-tile-text a[href]::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

[class*="c-promo-light"] .content-tile-text a[href]:hover,
[class*="c-promo-light"] .content-tile-text a[href]:focus,
[class*="c-promo-light"] .content-tile-text a[href]:focus-visible,
[class*="c-promo-light"] .content-tile-text a[href]:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

[class*="c-promo-light"] .content-tile-text a[href]:hover::before,
[class*="c-promo-light"] .content-tile-text a[href]:focus::before,
[class*="c-promo-light"] .content-tile-text a[href]:focus-visible::before,
[class*="c-promo-light"] .content-tile-text a[href]:active::before {
  transform: scale(1.05)
}

[class*="c-promo-light"] .content-tile-text a[href]:focus,
[class*="c-promo-light"] .content-tile-text a[href]:focus-visible {
  outline: 0
}

[class*="c-promo-light"] .content-tile-text a[href]:focus::before,
[class*="c-promo-light"] .content-tile-text a[href]:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

[class*="c-promo-light"] .content-tile-text a[href]:active::before {
  outline-color: transparent
}

[class*="c-promo-light"] .content-tile-text a[href]:only-of-type {
  margin: 0
}

[class*="c-promo-light"] .content-tile-text a[href].icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

[class*="c-promo-light"] .content-tile-text a[href]::before {
  background: var(--color--interactive-secondary) border-box
}

[class*="c-promo-light"] .content-tile-text a[href]:hover {
  color: var(--color--text-primary-inverse)
}

[class*="c-promo-light"] .content-tile-text a[href]:hover::before {
  background: var(--color--interactive-secondary-hover) border-box
}

[class*="c-promo-light"] .content-tile-text a[href]:focus,
[class*="c-promo-light"] .content-tile-text a[href]:focus-visible {
  color: var(--color--text-primary-inverse)
}

[class*="c-promo-light"] .content-tile-text a[href]:focus::before,
[class*="c-promo-light"] .content-tile-text a[href]:focus-visible::before {
  background: var(--color--interactive-secondary) border-box
}

[class*="c-promo-light"] .content-tile-text a[href]:active {
  color: var(--color--text-primary-inverse)
}

[class*="c-promo-light"] .content-tile-text a[href]:active::before {
  background: var(--color--interactive-secondary-active) border-box
}

[class*="c-promo-light"] .content-tile-text a[href][disabled],
[class*="c-promo-light"] .content-tile-text a[href].button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

[class*="c-promo-light"] .content-tile-text a[href][disabled]::before,
[class*="c-promo-light"] .content-tile-text a[href].button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

[class*="c-promo-light"] .content-tile-text a[href][disabled]:hover::before,
[class*="c-promo-light"] .content-tile-text a[href].button-disabled:hover::before,
[class*="c-promo-light"] .content-tile-text a[href][disabled]:focus::before,
[class*="c-promo-light"] .content-tile-text a[href].button-disabled:focus::before,
[class*="c-promo-light"] .content-tile-text a[href][disabled]:focus-visible::before,
[class*="c-promo-light"] .content-tile-text a[href].button-disabled:focus-visible::before,
[class*="c-promo-light"] .content-tile-text a[href][disabled]:active::before,
[class*="c-promo-light"] .content-tile-text a[href].button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.content-tile-panel-item-file-download-v2 figure,
.content-tile-panel-item-file-download-v2 .content-tile__figure {
  display: flex
}

@media(max-width:767px) {

  .content-tile-panel-item-file-download-v2 figure,
  .content-tile-panel-item-file-download-v2 .content-tile__figure {
    display: flex;
    flex-direction: column
  }
}

.content-tile-panel-item-file-download-v2 picture {
  aspect-ratio: 1
}

@media(min-width:768px) {
  .content-tile-panel-item-file-download-v2 picture {
    width: 108px;
    height: 108px
  }
}

@media(max-width:767px) {
  .content-tile-panel-item-file-download-v2 picture {
    width: 95px;
    height: 95px
  }
}

.content-tile-panel-item-file-download-v2 figcaption,
.content-tile-panel-item-file-download-v2 .content-tile__figcaption {
  width: 75%
}

.content-tile-panel-item-file-download-v2 .content-tile__title {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--bold);
  font-size: var(--typesize-h6);
  line-height: var(--leading-default)
}

@media(min-width:769px) {

  .content-tile-panel-item-file-download-v2 picture+figcaption,
  .content-tile-panel-item-file-download-v2 picture+.content-tile__figcaption {
    padding-right: var(--space-medium);
    padding-left: var(--space-medium)
  }
}

@media(max-width:768px) {

  .content-tile-panel-item-file-download-v2 picture+figcaption,
  .content-tile-panel-item-file-download-v2 picture+.content-tile__figcaption {
    padding-right: var(--space-small);
    padding-left: var(--space-small)
  }
}

.content-tile-icon picture {
  max-height: 60px;
  overflow: clip;
  margin-block-end: unset;
  margin-inline: auto
}

@media(min-width:768px) {
  .content-tile-icon picture+* {
    margin-block-start: var(--space-medium)
  }
}

@media(max-width:767px) {
  .content-tile-icon picture+* {
    margin-block-start: var(--space-small)
  }
}

.content-tile-icon picture img {
  min-width: 0;
  max-height: 60px;
  margin: 0 auto
}

.content-tile-icon figure,
.content-tile-icon .content-tile__figure {
  text-align: center
}

.content-tile-icon .content-tile__title {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-2);
  margin-bottom: 0;
  text-align: center
}

[class*="c-frameless"] {
  border-radius: 0 0 50px 0;
  background-color: transparent;
  transition: all .2s ease-in-out
}

[class*="c-frameless"] .content-tile__figcaption,
[class*="c-frameless"] figcaption {
  padding: 40px 20px
}

[class*="c-frameless"] .content-tile__title {
  animation-duration: .125s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  font-weight: var(--typeweight--default)
}

[class*="c-frameless"]:hover {
  background-color: var(--color--ui-background-light, var(--color--background-light));
  box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.05)
}

[class*="c-frameless"]:hover .content-tile__title {
  animation-name: setBoldType;
  font-weight: var(--typeweight--semibold)
}

@media(prefers-reduced-motion:no-preference) {
  @supports(font-variation-settings:normal) {
    @keyframes setBoldType {
      from {
        font-weight: var(--typeweight--default)
      }

      to {
        font-weight: var(--typeweight--semibold)
      }
    }

    @keyframes removeBoldType {
      from {
        font-weight: var(--typeweight--semibold)
      }

      to {
        font-weight: var(--typeweight--default)
      }
    }
  }
}

.link-list>div>ul {
  list-style-type: none
}

main .link-list>div h1,
main .link-list>div h2,
main .link-list>div h3,
main .link-list>div h4,
main .link-list>div h5,
main .link-list>div h6 {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

@media(max-width:767px) {
  main .link-list>div *+ul {
    margin-top: var(--space-small)
  }
}

@media(min-width:768px) {
  main .link-list>div *+ul {
    margin-top: var(--space-medium)
  }
}

main .link-list>div ul {
  list-style-type: none;
  padding: var(--space-zero)
}

main .link-list>div ul>li:not(:last-child) {
  border-bottom: 1px solid var(--color--ui-border-01, var(--color--border-01))
}

@media(pointer:fine) {
  main .link-list>div ul>li {
    padding: var(--space-small) var(--space-zero)
  }
}

@media(pointer:coarse) {
  main .link-list>div ul>li {
    padding: var(--space-zero)
  }
}

main .link-list>div ul>li a {
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default)
}

@media(pointer:fine) {
  main .link-list>div ul>li a {
    padding: var(--space-zero);
    display: inline-block
  }
}

@media(pointer:coarse) {
  main .link-list>div ul>li a {
    padding: var(--space-xxsmall) var(--space-zero);
    display: block
  }
}

main .link-list>div ul>li br {
  display: none
}

main .link-list *+.link-list.link-list:not(.nested-tabs, .c-footer__link-list) {
  margin-top: var(--space-small)
}

.link-list.subjects__list.subjects__list {
  --outlineSize-small: .0714285714286em;
  --outlineSize-medium: .142857142857em;
  --tagListTypeSize: var(--typesize-minor);
  --tagListMinLinkHeight: calc(var(--tagListTypeSize) * 2.14285714286);
  --tagListLinkPad-x: 1.14285714286em;
  --tagListLinkPad-yTop: .357142857143em;
  --tagListLinkPad-yBottom: .428571428571em;
  --tagListColGapSize: 1.14285714286em;
  --tagListRowGapSize: .857142857143em
}

.link-list.subjects__list.subjects__list ul {
  font-size: var(--tagListTypeSize);
  display: inline-flex;
  flex-wrap: wrap;
  column-gap: var(--tagListColGapSize);
  row-gap: var(--tagListRowGapSize);
  list-style: none;
  padding: 0;
  margin-top: unset
}

@media(max-width:375px) {
  .link-list.subjects__list.subjects__list ul {
    flex-direction: column
  }
}

.link-list.subjects__list.subjects__list ul li {
  border-bottom: unset;
  padding: unset
}

.link-list.subjects__list.subjects__list ul li+li {
  margin-top: unset
}

@media(max-width:375px) {
  .link-list.subjects__list.subjects__list ul li {
    flex: 1 1 100%
  }
}

.link-list.subjects__list.subjects__list ul a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary);
  transition: color .125s ease-in-out
}

@media(min-width:376px) {
  .link-list.subjects__list.subjects__list ul a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .link-list.subjects__list.subjects__list ul a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.link-list.subjects__list.subjects__list ul a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.link-list.subjects__list.subjects__list ul a:hover,
.link-list.subjects__list.subjects__list ul a:focus,
.link-list.subjects__list.subjects__list ul a:focus-visible,
.link-list.subjects__list.subjects__list ul a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.link-list.subjects__list.subjects__list ul a:hover::before,
.link-list.subjects__list.subjects__list ul a:focus::before,
.link-list.subjects__list.subjects__list ul a:focus-visible::before,
.link-list.subjects__list.subjects__list ul a:active::before {
  transform: scale(1.05)
}

.link-list.subjects__list.subjects__list ul a:focus,
.link-list.subjects__list.subjects__list ul a:focus-visible {
  outline: 0
}

.link-list.subjects__list.subjects__list ul a:focus::before,
.link-list.subjects__list.subjects__list ul a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.link-list.subjects__list.subjects__list ul a:active::before {
  outline-color: transparent
}

.link-list.subjects__list.subjects__list ul a:only-of-type {
  margin: 0
}

.link-list.subjects__list.subjects__list ul a.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.link-list.subjects__list.subjects__list ul a::before {
  outline-color: var(--color--interactive-tertiary);
  outline-offset: -2px;
  background-color: transparent
}

.link-list.subjects__list.subjects__list ul a:hover {
  color: var(--color--text-primary-inverse)
}

.link-list.subjects__list.subjects__list ul a:hover::before {
  outline-color: var(--color--interactive-tertiary-hover);
  background: var(--color--interactive-tertiary-hover)
}

.link-list.subjects__list.subjects__list ul a:focus,
.link-list.subjects__list.subjects__list ul a:focus-visible {
  color: var(--color--text-tertiary)
}

.link-list.subjects__list.subjects__list ul a:focus::before,
.link-list.subjects__list.subjects__list ul a:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

.link-list.subjects__list.subjects__list ul a:active {
  color: var(--color--text-primary-inverse)
}

.link-list.subjects__list.subjects__list ul a:active::before {
  background: var(--color--interactive-tertiary-active)
}

.link-list.subjects__list.subjects__list ul a[disabled],
.link-list.subjects__list.subjects__list ul a.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.link-list.subjects__list.subjects__list ul a[disabled]::before,
.link-list.subjects__list.subjects__list ul a.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.link-list.subjects__list.subjects__list ul a[disabled]:hover::before,
.link-list.subjects__list.subjects__list ul a.button-disabled:hover::before,
.link-list.subjects__list.subjects__list ul a[disabled]:focus::before,
.link-list.subjects__list.subjects__list ul a.button-disabled:focus::before,
.link-list.subjects__list.subjects__list ul a[disabled]:focus-visible::before,
.link-list.subjects__list.subjects__list ul a.button-disabled:focus-visible::before,
.link-list.subjects__list.subjects__list ul a[disabled]:active::before,
.link-list.subjects__list.subjects__list ul a.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.link-list.subjects__list.subjects__list ul a[disabled]::before,
.link-list.subjects__list.subjects__list ul a.button-disabled::before {
  outline-color: var(--color--interactive-disabled-01);
  outline-offset: -2px;
  background: transparent
}

.link-list.subjects__list.subjects__list ul a[disabled]:hover::before,
.link-list.subjects__list.subjects__list ul a.button-disabled:hover::before,
.link-list.subjects__list.subjects__list ul a[disabled]:focus::before,
.link-list.subjects__list.subjects__list ul a.button-disabled:focus::before,
.link-list.subjects__list.subjects__list ul a[disabled]:focus-visible::before,
.link-list.subjects__list.subjects__list ul a.button-disabled:focus-visible::before,
.link-list.subjects__list.subjects__list ul a[disabled]:active::before,
.link-list.subjects__list.subjects__list ul a.button-disabled:active::before {
  background: transparent
}

@media(max-width:375px) {
  .link-list.subjects__list.subjects__list ul a {
    display: flex
  }
}

.accordion-panel-hide {
  display: none
}

.accordion-panel {
  background-color: var(--color--ui-02);
  box-shadow: var(--shadow);
  border-radius: var(--corners-semiround-sm);
  transition: box-shadow .125s ease-in-out
}

.accordion-panel+* {
  margin-top: 20px
}

.accordion-panel-content {
  color: var(--color--text-secondary);
  margin-top: 0
}

@media all and (max-width:768px) {
  .accordion-panel-content {
    padding: 0 32px 15px 20px
  }
}

@media all and (min-width:769px) {
  .accordion-panel-content {
    padding: 0 50px 24px 24px
  }
}

@media print {
  .accordion-panel-hide {
    display: block
  }
}

.accordion-panel:hover {
  box-shadow: var(--shadow-blur)
}

.accordion-panel-header {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-p);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default);
  padding: 0;
  margin: 0;
  color: var(--color--text-primary)
}

@media all and (min-width:769px) {
  .accordion-panel-header {
    font-size: var(--typesize-h5)
  }
}

.accordion-panel-header button {
  all: unset
}

.accordion-panel-header a,
.accordion-panel-header button {
  font-weight: var(--typeweight--default);
  color: var(--color--interactive-secondary);
  text-decoration: none;
  cursor: pointer;
  border: 0;
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center
}

@media all and (max-width:768px) {

  .accordion-panel-header a,
  .accordion-panel-header button {
    padding: 15px 9px 10px 20px
  }
}

@media all and (min-width:769px) {

  .accordion-panel-header a,
  .accordion-panel-header button {
    padding: 24px
  }
}

.accordion-panel-header a:hover,
.accordion-panel-header button:hover,
.accordion-panel-header a:active,
.accordion-panel-header button:active,
.accordion-panel-header a:focus,
.accordion-panel-header button:focus {
  text-decoration: none
}

.accordion-panel-header a:focus,
.accordion-panel-header button:focus {
  color: var(--color--text-primary)
}

.accordion-panel-header a:focus-visible,
.accordion-panel-header button:focus-visible {
  outline-width: 3px;
  outline-style: solid;
  outline-color: var(--color--interactive-focus);
  outline-offset: -3px
}

.accordion-panel-header a span:first-of-type,
.accordion-panel-header button span:first-of-type {
  display: inline-block;
  animation-duration: .125s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards
}

@media(min-width:769px) {

  .accordion-panel-header a span:first-of-type,
  .accordion-panel-header button span:first-of-type {
    width: calc(100% - 20px)
  }
}

@media(max-width:768px) {

  .accordion-panel-header a span:first-of-type,
  .accordion-panel-header button span:first-of-type {
    width: auto;
    flex: 1 1 calc(100% - 20px)
  }
}

.accordion-panel-header a [class*="fa-"],
.accordion-panel-header button [class*="fa-"] {
  font-size: 0;
  margin-left: auto;
  margin-top: 0
}

.accordion-panel-header a [class*="fa-"]::before,
.accordion-panel-header button [class*="fa-"]::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  background-color: var(--color--icon-primary);
  transition: transform 300ms ease-in-out
}

.accordion-panel-header a .fa-minus::before,
.accordion-panel-header button .fa-minus::before {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg)
}

.accordion-panel-header[aria-expanded="true"] a span:first-of-type,
.accordion-panel-header[aria-expanded="true"] button span:first-of-type {
  animation-name: setBoldType;
  font-weight: var(--typeweight--semibold)
}

.accordion-panel-header[aria-expanded="false"] a span:first-of-type,
.accordion-panel-header[aria-expanded="false"] button span:first-of-type {
  animation-name: removeBoldType;
  font-weight: var(--typeweight--default)
}

@supports selector(:focus-visible) {

  .accordion-panel-header a:focus-visible,
  .accordion-panel-header button:focus-visible {
    outline-width: 3px;
    outline-style: solid;
    outline-color: var(--color--interactive-focus)
  }
}

@media all and (max-width:768px) {
  .accordion+* {
    margin-top: 30px
  }
}

@media all and (min-width:769px) {
  .accordion+* {
    margin-top: 40px
  }
}

*+.accordion {
  margin-top: 20px
}

.accordion-secondary .accordion-panel {
  background-color: var(--color--ui-04)
}

.accordion-secondary .accordion-panel-content {
  color: var(--color--text-secondary-inverse)
}

.accordion-secondary .accordion-panel-header a span:first-of-type,
.accordion-secondary .accordion-panel-header button span:first-of-type {
  color: var(--color--interactive-secondary-inverse)
}

.accordion-secondary .accordion-panel-header a [class*="fa-"]::before,
.accordion-secondary .accordion-panel-header button [class*="fa-"]::before {
  background-color: var(--color--interactive-secondary-inverse)
}

@media(prefers-reduced-motion:no-preference) {
  .accordion.animated .accordion-panel {
    opacity: 0;
    transform: translate(0, 20px)
  }

  .accordion.animated.in-view .accordion-panel {
    animation-duration: .5s;
    animation-name: slidein;
    animation-fill-mode: forwards
  }

  @supports(font-variation-settings:normal) {
    @keyframes setBoldType {
      from {
        font-weight: var(--typeweight--default)
      }

      to {
        font-weight: var(--typeweight--semibold)
      }
    }

    @keyframes removeBoldType {
      from {
        font-weight: var(--typeweight--semibold)
      }

      to {
        font-weight: var(--typeweight--default)
      }
    }
  }
}

.child-page-list-column-navigation {
  --space-indent: var(--space-xsmall)
}

@media(max-width:767px) {
  .child-page-list-column-navigation {
    margin-bottom: var(--space-xlarge)
  }
}

.child-page-list-column-navigation .child-page-list__header {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-2);
  margin-bottom: 0;
  background-color: var(--color--ui-01);
  border-top-right-radius: var(--corners-semiround-sm);
  border-top-left-radius: var(--corners-semiround-sm);
  border-bottom: 1px solid var(--color--ui-border-03, var(--color--border-03));
  position: relative;
  overflow: hidden;
  padding-top: var(--space-indent);
  padding-right: calc(10 * var(--space-unit));
  padding-bottom: var(--space-indent);
  padding-left: var(--space-indent);
  margin: unset
}

@media(max-width:480px) {
  .child-page-list-column-navigation .child-page-list__header[data-expanded="true"] {
    color: var(--color--text-secondary-inverse);
    background-color: var(--color--ui-background-dark, var(--color--background-dark))
  }
}

.child-page-list-column-navigation .child-page-list__header:empty {
  display: none
}

.child-page-list-column-navigation .child-page-list__headerLink {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  background-color: inherit;
  position: inherit;
  display: block;
  padding: inherit;
  margin-top: calc(var(--space-xsmall) * -1);
  margin-right: calc(-10 * var(--space-unit));
  margin-bottom: calc(var(--space-xsmall) * -1);
  margin-left: calc(var(--space-xsmall) * -1)
}

.child-page-list-column-navigation .child-page-list__headerLink:focus-visible {
  outline-offset: -0.125em
}

@media(max-width:480px) {
  .child-page-list-column-navigation .c-child-page-list__mobileToggle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0;
    appearance: unset;
    border-radius: var(--corners-semiround-sm);
    outline-width: .0625em;
    outline-style: solid;
    outline-color: transparent;
    background-color: transparent;
    cursor: pointer;
    padding: var(--space-unit);
    transition-property: all;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    position: absolute;
    top: var(--space-indent);
    right: var(--space-xxsmall);
    color: inherit;
    top: calc(var(--space-indent) - 3px)
  }

  .child-page-list-column-navigation .c-child-page-list__mobileToggle:hover,
  .child-page-list-column-navigation .c-child-page-list__mobileToggle:active,
  .child-page-list-column-navigation .c-child-page-list__mobileToggle:focus {
    background-color: var(--color--ui-background-light, var(--color--background-light));
    outline-color: var(--color--ui-border-02, var(--color--border-02))
  }

  .child-page-list-column-navigation .c-child-page-list__mobileToggle:hover::after,
  .child-page-list-column-navigation .c-child-page-list__mobileToggle:active::after,
  .child-page-list-column-navigation .c-child-page-list__mobileToggle:focus::after {
    color: var(--color--ui-03)
  }

  .child-page-list-column-navigation .c-child-page-list__mobileToggle:focus-visible {
    outline-color: var(--color--interactive-focus-field);
    outline-width: .125em
  }

  .child-page-list-column-navigation .c-child-page-list__mobileToggle::after {
    display: inline-block;
    line-height: inherit;
    content: "";
    flex: none;
    -webkit-mask-size: 100%;
    mask-size: 100%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
    background: currentColor;
    width: 18px;
    height: 18px;
    -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
    mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
    color: var(--color--icon-tertiary);
    cursor: pointer;
    transition-property: transform;
    transition-duration: .2s;
    transition-timing-function: ease-in-out
  }

  .child-page-list-column-navigation .c-child-page-list__mobileToggle::after:hover,
  .child-page-list-column-navigation .c-child-page-list__mobileToggle::after:active {
    color: var(--color--ui-03)
  }

  .child-page-list-column-navigation .c-child-page-list__mobileToggle:hover,
  .child-page-list-column-navigation .c-child-page-list__mobileToggle:active {
    color: inherit;
    background-color: unset;
    outline-color: transparent
  }

  .child-page-list-column-navigation .c-child-page-list__mobileToggle:hover:after,
  .child-page-list-column-navigation .c-child-page-list__mobileToggle:active:after {
    color: inherit
  }

  .child-page-list-column-navigation .c-child-page-list__mobileToggle[aria-expanded="true"]::after {
    transform: rotate(180deg)
  }
}

@media(min-width:481px) {
  .child-page-list-column-navigation .c-child-page-list__mobileToggle {
    display: none
  }
}

.child-page-list-column-navigation .c-child-page-list__mobileToggle::after {
  color: inherit
}

.child-page-list-column-navigation ul {
  list-style-type: none;
  background-color: var(--color--ui-background-light, var(--color--background-light));
  padding: unset;
  margin: unset
}

@media(max-width:480px) {
  .child-page-list-column-navigation ul.cpl--is-hidden {
    display: none
  }
}

@media(min-width:481px) {
  .child-page-list-column-navigation ul.cpl--is-hidden {
    display: block
  }
}

.child-page-list-column-navigation li {
  list-style-type: none;
  display: block;
  position: relative;
  padding: unset;
  margin: unset
}

.child-page-list-column-navigation li a:not([href="javascript:void(0)"]) {
  font-weight: inherit;
  color: var(--color--text-secondary);
  display: block;
  position: relative;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--color--ui-border-01, var(--color--border-01));
  padding-top: var(--space-indent);
  padding-right: calc(10 * var(--space-unit));
  padding-bottom: var(--space-indent);
  padding-left: var(--space-indent);
  transition-property: background-color;
  transition-duration: .1s;
  transition-timing-function: ease-in-out
}

.child-page-list-column-navigation li a:not([href="javascript:void(0)"]):hover,
.child-page-list-column-navigation li a:not([href="javascript:void(0)"]):focus,
.child-page-list-column-navigation li a:not([href="javascript:void(0)"]):focus-visible,
.child-page-list-column-navigation li a:not([href="javascript:void(0)"]):active {
  background-color: var(--color--ui-01)
}

.child-page-list-column-navigation li a:not([href="javascript:void(0)"]):hover+a,
.child-page-list-column-navigation li a:not([href="javascript:void(0)"]):focus+a,
.child-page-list-column-navigation li a:not([href="javascript:void(0)"]):focus-visible+a,
.child-page-list-column-navigation li a:not([href="javascript:void(0)"]):active+a,
.child-page-list-column-navigation li a:not([href="javascript:void(0)"]):hover+button,
.child-page-list-column-navigation li a:not([href="javascript:void(0)"]):focus+button,
.child-page-list-column-navigation li a:not([href="javascript:void(0)"]):focus-visible+button,
.child-page-list-column-navigation li a:not([href="javascript:void(0)"]):active+button {
  background-color: var(--color--ui-background-light, var(--color--background-light));
  outline-color: var(--color--ui-border-01, var(--color--border-01))
}

.child-page-list-column-navigation li a:not([href="javascript:void(0)"]):focus-visible {
  outline-offset: -0.0625em
}

.child-page-list-column-navigation li:not(:has(.child-page-list-expand-collapse-button)) a {
  padding-right: var(--space-xxsmall)
}

.child-page-list-column-navigation .fa,
.child-page-list-column-navigation .child-page-list-expand-collapse-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0;
  appearance: unset;
  border-radius: var(--corners-semiround-sm);
  outline-width: .0625em;
  outline-style: solid;
  outline-color: transparent;
  background-color: transparent;
  cursor: pointer;
  padding: var(--space-unit);
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  position: absolute;
  top: var(--space-indent);
  right: var(--space-xxsmall)
}

.child-page-list-column-navigation .fa:hover,
.child-page-list-column-navigation .child-page-list-expand-collapse-button:hover,
.child-page-list-column-navigation .fa:active,
.child-page-list-column-navigation .child-page-list-expand-collapse-button:active,
.child-page-list-column-navigation .fa:focus,
.child-page-list-column-navigation .child-page-list-expand-collapse-button:focus {
  background-color: var(--color--ui-background-light, var(--color--background-light));
  outline-color: var(--color--ui-border-02, var(--color--border-02))
}

.child-page-list-column-navigation .fa:hover::after,
.child-page-list-column-navigation .child-page-list-expand-collapse-button:hover::after,
.child-page-list-column-navigation .fa:active::after,
.child-page-list-column-navigation .child-page-list-expand-collapse-button:active::after,
.child-page-list-column-navigation .fa:focus::after,
.child-page-list-column-navigation .child-page-list-expand-collapse-button:focus::after {
  color: var(--color--ui-03)
}

.child-page-list-column-navigation .fa:focus-visible,
.child-page-list-column-navigation .child-page-list-expand-collapse-button:focus-visible {
  outline-color: var(--color--interactive-focus-field);
  outline-width: .125em
}

.child-page-list-column-navigation .fa::after,
.child-page-list-column-navigation .child-page-list-expand-collapse-button::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  color: var(--color--icon-tertiary);
  cursor: pointer;
  transition-property: transform;
  transition-duration: .2s;
  transition-timing-function: ease-in-out
}

.child-page-list-column-navigation .fa::after:hover,
.child-page-list-column-navigation .child-page-list-expand-collapse-button::after:hover,
.child-page-list-column-navigation .fa::after:active,
.child-page-list-column-navigation .child-page-list-expand-collapse-button::after:active {
  color: var(--color--ui-03)
}

.child-page-list-column-navigation .fa::before,
.child-page-list-column-navigation .child-page-list-expand-collapse-button::before {
  display: none
}

.child-page-list-column-navigation .fa.fa-minus::after,
.child-page-list-column-navigation .child-page-list-expand-collapse-button.fa-minus::after,
.child-page-list-column-navigation .fa.child-list-minus::after,
.child-page-list-column-navigation .child-page-list-expand-collapse-button.child-list-minus::after {
  transform: rotate(180deg)
}

.child-page-list-column-navigation .child-page-list-current-page.child-page-list-current-page {
  font-weight: var(--typeweight--semibold);
  color: var(--color--text-primary)
}

.child-page-list-column-navigation .child-page-list-current-page.child-page-list-current-page::before {
  display: block;
  content: "";
  width: 3px;
  height: 100%;
  background-color: var(--color--icon-primary);
  border-top-right-radius: var(--corners-semiround-md);
  border-bottom-right-radius: var(--corners-semiround-md);
  position: absolute;
  top: 0;
  left: 0
}

.child-page-list-column-navigation .child-page-list-level-2>li>a:not([href="javascript:void(0)"]) {
  padding-left: calc(var(--space-indent) * 2)
}

.child-page-list-column-navigation .child-page-list-level-3>li>a:not([href="javascript:void(0)"]) {
  padding-left: calc(var(--space-indent) * 3)
}

.container .embed .container {
  padding: 0
}

@media(min-width:768px) {
  .content-tile-expander-link-v2.content-tile-expander-link-v2 {
    padding-bottom: 30px
  }
}

.content-tile-expander-link-v2 [class*="col-"].col-12 {
  padding-bottom: 30px
}

.content-tile-expander-link-v2 .content-tile-container {
  height: 100%;
  position: inherit
}

.content-tile-expander-link-v2 figcaption,
.content-tile-expander-link-v2 .content-tile__figcaption {
  text-align: center;
  margin: 30px 0 0 0
}

.content-tile-expander-link-v2 .content-tile__title {
  font-size: var(--typesize-h3);
  font-weight: var(--typeweight--default);
  color: #151515
}

.content-tile-expander-link-v2 .content-tile-expander-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 0;
  border-radius: 100%;
  background: #007fa3;
  height: 30px;
  width: 30px;
  cursor: pointer;
  margin: 30px auto 0;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -webkit-transform: scale(1) translateZ(0);
  transform: scale(1) translateZ(0);
  position: relative;
  padding: 0;
  margin: 20px auto 0 auto;
  z-index: 1
}

// .content-tile-expander-link-v2 .content-tile-expander-button i{font-style:normal;display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;height:16px;width:10px;background-image:url("../assets/icons/icon-chevron-down--white.svg");background-repeat:no-repeat;background-size:10px 16px;background-attachment:scroll;-webkit-transform:scale(1) translateZ(0);transform:scale(1) translateZ(0);-webkit-transition:all .15s ease-in-out;transition:all .15s ease-in-out}
.content-tile-expander-link-v2 .content-tile-expander-button:hover,
.content-tile-expander-link-v2 .content-tile-expander-button:focus {
  -webkit-transform: scale(1.2);
  transform: scale(1.2)
}

.content-tile-expander-link-v2 .content-tile-expander-button.active {
  -webkit-transform: scale(1.5);
  transform: scale(1.5)
}

@media(min-width:768px) {
  .content-tile-expander-link-v2 .content-tile-expander-button.active {
    -webkit-transform: scale(2);
    transform: scale(2)
  }
}

.content-tile-expander-link-v2 .content-tile-expander-button.active i {
  -webkit-transform: rotate(180deg) scale(1.2);
  transform: rotate(180deg) scale(1.2)
}

@media(min-width:768px) {
  .content-tile-expander-link-v2 .content-tile-expander-button {
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    margin: 0 auto
  }
}

@media(min-width:768px) and (max-width:992px) {
  .content-tile-expander-link-v2 .content-tile-expander-button {
    margin: 0 auto 0
  }
}

@media(max-width:767px) {
  .content-tile-expander-link-v2 {
    padding-top: 30px;
    padding-bottom: 0;
    margin-bottom: 30px
  }
}

.column-control-expander-content-v2 {
  border-top: 6px #007fa3 solid;
  clear: both;
  width: 100%;
  padding: 45px 20px;
  margin-top: 12px
}

@media(max-width:767px) {
  .column-control-expander-content-v2 {
    padding-bottom: 15px;
    margin-top: -48px
  }
}

.column-control-expander-links-v2>.row,
.column-control-expander-links-v2>.container>.row {
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap
}

@media(max-width:767px) {

  .column-control-expander-links-v2>.row .col-12:first-of-type,
  .column-control-expander-links-v2>.container>.row .col-12:first-of-type {
    order: 1
  }

  .column-control-expander-links-v2>.row .col-12:nth-of-type(2),
  .column-control-expander-links-v2>.container>.row .col-12:nth-of-type(2) {
    order: 3
  }

  .column-control-expander-links-v2>.row .col-12:nth-of-type(3),
  .column-control-expander-links-v2>.container>.row .col-12:nth-of-type(3) {
    order: 5
  }

  .column-control-expander-links-v2>.row .col-12:nth-of-type(4),
  .column-control-expander-links-v2>.container>.row .col-12:nth-of-type(4) {
    order: 7
  }

  .column-control-expander-links-v2>.row .col-12:nth-of-type(5),
  .column-control-expander-links-v2>.container>.row .col-12:nth-of-type(5) {
    order: 9
  }

  .column-control-expander-links-v2>.row .col-12:nth-of-type(6),
  .column-control-expander-links-v2>.container>.row .col-12:nth-of-type(6) {
    order: 11
  }

  .column-control-expander-links-v2>.row section:first-of-type,
  .column-control-expander-links-v2>.container>.row section:first-of-type {
    order: 2
  }

  .column-control-expander-links-v2>.row section:nth-of-type(2),
  .column-control-expander-links-v2>.container>.row section:nth-of-type(2) {
    order: 4
  }

  .column-control-expander-links-v2>.row section:nth-of-type(3),
  .column-control-expander-links-v2>.container>.row section:nth-of-type(3) {
    order: 6
  }

  .column-control-expander-links-v2>.row section:nth-of-type(4),
  .column-control-expander-links-v2>.container>.row section:nth-of-type(4) {
    order: 8
  }

  .column-control-expander-links-v2>.row section:nth-of-type(5),
  .column-control-expander-links-v2>.container>.row section:nth-of-type(5) {
    order: 10
  }

  .column-control-expander-links-v2>.row section:nth-of-type(6),
  .column-control-expander-links-v2>.container>.row section:nth-of-type(6) {
    order: 12
  }
}

.c-card-list {
  list-style-type: none;
  padding: 0;
  margin: 0 auto -30px auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center
}

@media(max-width:767px) {
  .c-card-list {
    flex-flow: column wrap;
    align-items: center
  }
}

.c-card-list__item {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  width: clamp(210px, 100%, 328px);
  padding: 0
}

@media(max-width:767px) {
  .c-card-list__item {
    margin: 0 0 30px 0
  }
}

@media(min-width:768px) {
  .c-card-list__item {
    margin: 0 15px 30px 15px
  }
}

@media(min-width:740px) {
  .programHero__info .card-container {
    margin: 60px 0 0 0
  }
}

@media(max-width:739px) {
  .programHero__info .card-container {
    margin: 20px 0 0 0
  }
}

.card-container .c-card__content {
  box-shadow: var(--shadow);
  transition: box-shadow .125s ease-in-out
}

.card-container .c-card__content:hover {
  box-shadow: var(--shadow-blur)
}

@media(prefers-reduced-motion:no-preference) {
  .card-container.animated .c-card-list__item {
    opacity: 0;
    transform: translate(0, 20px)
  }

  .card-container.animated.in-view .c-card-list__item {
    animation-duration: .5s;
    animation-name: slidein;
    animation-fill-mode: forwards
  }

  .card-container.animated.in-view .c-card-list__item:nth-child(1) {
    animation-delay: .4s
  }

  .card-container.animated.in-view .c-card-list__item:nth-child(2) {
    animation-delay: .6s
  }

  .card-container.animated.in-view .c-card-list__item:nth-child(3) {
    animation-delay: .8s
  }

  .card-container.animated.in-view .c-card-list__item:nth-child(4) {
    animation-delay: .10s
  }

  .card-container.animated.in-view .c-card-list__item:nth-child(5) {
    animation-delay: .12s
  }
}

.c-card {
  height: 100%
}

.c-card__holder:focus,
.c-card__holder:hover,
.c-card__holder:active {
  text-decoration: none
}

@media(min-width:768px) {
  .c-card__holder {
    height: 100%
  }
}

.c-card__value-flag {
  background-color: var(--color--accent-03-primary);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: var(--shadow);
  display: flex;
  justify-content: center;
  padding: 10px;
  max-height: 40px
}

@media(min-width:768px) {
  body:not(.edit, .cq-wcm-edit) .c-card__value-flag {
    margin-top: -40px
  }
}

.c-card__value-flag-text {
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-1);
  color: var(--color--text-primary);
  white-space: nowrap;
  max-width: 30ch;
  text-overflow: ellipsis;
  overflow: hidden
}

.c-card__value-flag+.c-card__content {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.c-card__content {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: rgba(79, 85, 97, 0.5);
  margin: 0
}

.c-card__content *:not(a[href]) {
  color: var(--color--text-primary-inverse)
}

@media(min-width:768px) {
  .c-card__content {
    height: 100%
  }
}

.c-card__copy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  height: 100%;
  padding: 30px 50px
}

.c-card__header {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h3);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-1);
  text-align: center;
  color: var(--color--text-primary-inverse);
  border-bottom: 1px solid var(--color--ui-border-01, var(--color--border-01));
  padding-bottom: 10px;
  margin-bottom: 20px
}

.c-card__feature-level {
  font-size: var(--typesize-minor);
  text-align: center;
  color: var(--color--text-primary-inverse);
  max-width: 30ch;
  margin: 10px auto 0 auto
}

.c-card__price {
  margin-top: 10px
}

.c-card__description {
  margin-top: 10px
}

.c-card__cost {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h3);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-1);
  text-align: center;
  color: var(--color--text-primary-inverse);
  display: block;
  margin-top: 0
}

.c-card__billing {
  text-align: center;
  max-width: 25ch
}

.c-card__billing p {
  font-size: var(--typesize-minor);
  line-height: var(--leading--1-5)
}

.c-card__feature-list {
  list-style-type: none;
  padding: 0
}

.c-card__feature-list li {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--bold);
  display: flex;
  align-items: flex-start;
  padding: 0
}

.c-card__feature-list li::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-success-banner-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-success-banner-md");
  background: var(--color--accent-03-primary);
  margin-right: 10px;
  transform: translateY(4px)
}

.c-card__feature-list li+li {
  margin-top: 15px
}

*+.c-card__feature-list {
  margin-top: 20px
}

.c-card__cta-container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  margin-top: auto
}

.c-card__cta {
  position: inherit;
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  min-width: calc(50% + 30px)
}

@media(min-width:376px) {
  .c-card__cta {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .c-card__cta {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.c-card__cta::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.c-card__cta:hover,
.c-card__cta:focus,
.c-card__cta:focus-visible,
.c-card__cta:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.c-card__cta:hover::before,
.c-card__cta:focus::before,
.c-card__cta:focus-visible::before,
.c-card__cta:active::before {
  transform: scale(1.05)
}

.c-card__cta:focus,
.c-card__cta:focus-visible {
  outline: 0
}

.c-card__cta:focus::before,
.c-card__cta:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.c-card__cta:active::before {
  outline-color: transparent
}

.c-card__cta:only-of-type {
  margin: 0
}

.c-card__cta.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.c-card__cta::before {
  background: var(--color--interactive-primary) border-box
}

.c-card__cta:hover::before {
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-primary-hover) border-box
}

.c-card__cta:focus,
.c-card__cta:focus-visible {
  color: var(--color--text-primary-inverse)
}

.c-card__cta:focus::before,
.c-card__cta:focus-visible::before {
  background: var(--color--interactive-primary) border-box
}

.c-card__cta:active {
  color: var(--color--text-primary-inverse)
}

.c-card__cta:active::before {
  background: var(--color--interactive-primary-active) border-box
}

.c-card__cta[disabled],
.c-card__cta.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.c-card__cta[disabled]::before,
.c-card__cta.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.c-card__cta[disabled]:hover::before,
.c-card__cta.button-disabled:hover::before,
.c-card__cta[disabled]:focus::before,
.c-card__cta.button-disabled:focus::before,
.c-card__cta[disabled]:focus-visible::before,
.c-card__cta.button-disabled:focus-visible::before,
.c-card__cta[disabled]:active::before,
.c-card__cta.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.c-card.isProxyLink {
  cursor: pointer
}

.c-card:hover .pricing-card__cta::before,
.c-card:active .pricing-card__cta::before,
.c-card:focus .pricing-card__cta::before {
  transform: scale(1.05)
}

.card-container--secondary-theme .c-card__content {
  background-color: var(--color--ui-background-med, var(--color--background-medium))
}

.card-container--secondary-theme *:not(a[href]) {
  color: var(--color--text-secondary)
}

.card-container--secondary-theme .c-card__header {
  border-bottom: 1px solid var(--color--ui-05)
}

.c-loading__container {
  height: 100%;
  width: 100%
}

.c-loading__background {
  transform-origin: 50% 50%;
  fill: transparent;
  stroke: var(--color--ui-01);
  stroke-width: 12
}

.c-loading__foreground {
  -webkit-animation: 1s linear infinite both c-loading-spinner;
  animation: 1s linear infinite both c-loading-spinner;
  fill: transparent;
  stroke: var(--color--accent-01-medium);
  stroke-dasharray: 135;
  stroke-width: 12px;
  stroke-linecap: round;
  transform-origin: 50% 50%
}

@-webkit-keyframes c-loading-spinner {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

@keyframes c-loading-spinner {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

.c-collection .container {
  display: flex;
  flex-direction: column
}

@media(min-width:768px) {
  .c-collection .container {
    gap: var(---space-small)
  }
}

@media(max-width:767px) {
  .c-collection .container {
    gap: var(--space-xxsmall)
  }
}

@media(max-width:375px) {
  .c-collection .c-collection__header {
    display: contents
  }

  .c-collection .c-collection__title {
    order: 1
  }

  .c-collection .c-collection__sub-title {
    order: 2
  }

  .c-collection .text-primary-link {
    order: 3
  }

  .c-collection .c-collection__row {
    order: 4
  }
}

.c-collection .button--primary.button--primary {
  background-color: unset;
  color: unset;
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1
}

.c-collection .button--primary.button--primary:hover,
.c-collection .button--primary.button--primary:focus,
.c-collection .button--primary.button--primary:active {
  color: unset;
  background-color: unset
}

@media(min-width:376px) {
  .c-collection .button--primary.button--primary {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .c-collection .button--primary.button--primary {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.c-collection .button--primary.button--primary::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.c-collection .button--primary.button--primary:hover,
.c-collection .button--primary.button--primary:focus,
.c-collection .button--primary.button--primary:focus-visible,
.c-collection .button--primary.button--primary:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.c-collection .button--primary.button--primary:hover::before,
.c-collection .button--primary.button--primary:focus::before,
.c-collection .button--primary.button--primary:focus-visible::before,
.c-collection .button--primary.button--primary:active::before {
  transform: scale(1.05)
}

.c-collection .button--primary.button--primary:focus,
.c-collection .button--primary.button--primary:focus-visible {
  outline: 0
}

.c-collection .button--primary.button--primary:focus::before,
.c-collection .button--primary.button--primary:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.c-collection .button--primary.button--primary:active::before {
  outline-color: transparent
}

.c-collection .button--primary.button--primary:only-of-type {
  margin: 0
}

.c-collection .button--primary.button--primary.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.c-collection .button--primary.button--primary::before {
  background: var(--color--interactive-primary) border-box
}

.c-collection .button--primary.button--primary:hover::before {
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-primary-hover) border-box
}

.c-collection .button--primary.button--primary:focus,
.c-collection .button--primary.button--primary:focus-visible {
  color: var(--color--text-primary-inverse)
}

.c-collection .button--primary.button--primary:focus::before,
.c-collection .button--primary.button--primary:focus-visible::before {
  background: var(--color--interactive-primary) border-box
}

.c-collection .button--primary.button--primary:active {
  color: var(--color--text-primary-inverse)
}

.c-collection .button--primary.button--primary:active::before {
  background: var(--color--interactive-primary-active) border-box
}

.c-collection .button--primary.button--primary[disabled],
.c-collection .button--primary.button--primary.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.c-collection .button--primary.button--primary[disabled]::before,
.c-collection .button--primary.button--primary.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.c-collection .button--primary.button--primary[disabled]:hover::before,
.c-collection .button--primary.button--primary.button-disabled:hover::before,
.c-collection .button--primary.button--primary[disabled]:focus::before,
.c-collection .button--primary.button--primary.button-disabled:focus::before,
.c-collection .button--primary.button--primary[disabled]:focus-visible::before,
.c-collection .button--primary.button--primary.button-disabled:focus-visible::before,
.c-collection .button--primary.button--primary[disabled]:active::before,
.c-collection .button--primary.button--primary.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.c-collection .button--small {
  font-size: var(--typesize-minor);
  padding: .25em 1.25em;
  min-height: 2rem
}

.c-collection .button--small.icon-only {
  aspect-ratio: 1;
  padding: .5em;
  max-width: 2rem
}

.c-collection__header {
  margin-block-end: 0
}

@media(min-width:768px) {
  .c-collection__header {
    gap: var(--space-small)
  }
}

@media(max-width:767px) {
  .c-collection__header {
    gap: var(--space-xxsmall)
  }
}

.c-collection__header .text-primary-link {
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-5);
  max-width: max-content;
  margin: auto 0
}

.c-collection--dark .c-collection__header .text-primary-link {
  color: var(--color--text-link-inverse)
}

.c-collection__title {
  font-size: var(--typesize-h2);
  font-weight: var(--typeweight--light);
  line-height: var(--leading--1-2);
  color: var(--color--text-primary);
  width: max-content;
  margin-inline-end: auto
}

@media(min-width:768px) {
  .c-collection__title {
    max-width: 75%
  }
}

@media(max-width:768px) {
  .c-collection__header .c-collection__title {
    padding-block-end: 0
  }
}

.c-collection--dark .c-collection__title {
  color: var(--color--text-primary-inverse)
}

.c-collection__sub-title {
  font-size: var(--typesize--default);
  line-height: var(--leading--1-5);
  color: var(--color--text-secondary);
  margin-block-start: 0
}

@media(min-width:768px) {
  .c-collection__sub-title {
    max-width: 75%
  }
}

.c-collection--dark .c-collection__sub-title {
  color: var(--color--text-secondary-inverse)
}

*+.c-collection__sub-title {
  margin-block-start: var(--space-small)
}

.c-collection__row {
  scrollbar-width: thin;
  scrollbar-color: var(--color-scrollbar-thumb) var(--color-scrollbar-track)
}

.c-collection__row::-webkit-scrollbar,
.c-collection__row::-webkit-scrollbar-track,
.c-collection__row::-webkit-scrollbar-thumb {
  height: 4px;
  border-radius: 40px
}

@media(hover:none) and (pointer:coarse) {
  .c-collection__row {
    scrollbar-width: none
  }

  .c-collection__row::-webkit-scrollbar,
  .c-collection__row::-webkit-scrollbar-track,
  .c-collection__row::-webkit-scrollbar-thumb {
    display: none
  }
}

.c-collection__row::-webkit-scrollbar-track {
  background: var(--color-scrollbar-track)
}

.c-collection__row::-webkit-scrollbar-thumb {
  background: var(--color-scrollbar-thumb)
}

[class*="c-collection__col"] {
  padding-block-start: var(--space-medium)
}

.static-collection-card[class*="use-button-"] .text-primary-link {
  margin: 0 !important
}

.static-collection-card[class*="use-button-"] .text-primary-link::after {
  margin-block-start: unset
}

@media(min-width:376px) {
  .static-collection-card[class*="use-button-"] .c-collection__footer-mobile-link.c-collection__footer-mobile-link {
    display: none !important
  }
}

@media(max-width:375px) {
  .c-collection--featured .static-collection-card[class*="use-button-"] .text-primary-link {
    display: none !important
  }
}

@media(min-width:376px) {
  .c-collection--featured .static-collection-card[class*="use-button-"] .c-collection__footer-mobile-link.c-collection__footer-mobile-link {
    display: none !important
  }
}

@media(max-width:375px) {
  .c-collection--featured .static-collection-card[class*="use-button-"] .c-collection__footer-mobile-link.c-collection__footer-mobile-link {
    all: unset
  }

  .c-collection--featured .static-collection-card[class*="use-button-"] .c-collection__footer-mobile-link.c-collection__footer-mobile-link::before {
    display: none
  }
}

@media(max-width:375px) and (min-width:376px) {
  .c-collection--featured .static-collection-card[class*="use-button-"] .c-collection__footer-mobile-link.c-collection__footer-mobile-link {
    display: none
  }
}

@media(max-width:375px) and (max-width:375px) {
  .c-collection--featured .static-collection-card[class*="use-button-"] .c-collection__footer-mobile-link.c-collection__footer-mobile-link {
    display: block;
    font-family: var(--typefamily-primary);
    font-size: var(--typesize-h5);
    font-weight: var(--typeweight--default);
    line-height: var(--leading--1-2);
    color: var(--color--text-primary)
  }
}

.c-collection__item {
  border: 0
}

.c-collection__item:hover .c-collection__img img {
  transform: none
}

.c-collection__banner img {
  transition: none;
  border-radius: var(--corners-semiround-sm)
}

.c-collection__banner:hover,
.c-collection__banner:active,
.c-collection__banner:focus {
  box-shadow: var(--shadow-blur)
}

.c-collection__banner:hover img,
.c-collection__banner:active img,
.c-collection__banner:focus img {
  transform: none;
  transform-origin: none
}

.c-collection__banner-title {
  padding-block-start: var(--space-small)
}

.c-collection__footer {
  display: flex;
  flex-direction: column
}

.c-collection__footer>*:not(.c-collection__footer-bottom) {
  margin: 0
}

@media(min-width:769px) {
  .c-collection__footer {
    padding: var(--spacing-30);
    gap: var(--spacing-20)
  }
}

@media(max-width:768px) {
  .c-collection__footer {
    padding: var(--spacing-20);
    gap: var(--spacing-15)
  }
}

@media(min-width:376px) and (max-width:767px) {
  .c-collection__footer-textBasic {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    padding-bottom: .125em;
    text-overflow: ellipsis
  }
}

@media(max-width:375px) {
  .c-collection__footer-textBasic {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    padding-bottom: .125em;
    text-overflow: ellipsis
  }
}

.c-collection__footer-bottom {
  text-align: start
}

.c-collection__footer-btnBasic {
  justify-content: flex-start
}

.c-collection .slick-dots.slick-dots {
  position: unset;
  bottom: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block-start: var(--space-xsmall)
}

.c-collection .slick-dots.slick-dots li {
  z-index: 1;
  display: inline-flex
}

.c-collection .slick-dots.slick-dots li button {
  width: .5rem;
  height: .5rem;
  aspect-ratio: 1;
  display: flex;
  align-content: center;
  justify-content: center;
  transition: all .125s ease-in-out;
  outline-width: .125rem;
  outline-style: solid;
  outline-color: transparent;
  outline-offset: 3px;
  opacity: 1;
  appearance: unset;
  border: unset;
  padding: unset;
  margin: auto;
  box-shadow: unset
}

.c-collection .slick-dots.slick-dots li button::before {
  display: block;
  content: "";
  width: 0;
  height: 0;
  aspect-ratio: 1;
  border-radius: 50%;
  opacity: 0;
  outline-width: .1875rem;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out;
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  background: unset;
  border: unset;
  left: unset;
  margin: unset
}

.c-collection .slick-dots.slick-dots li button:not(:active, [aria-current="true"]):hover::before {
  width: 1rem;
  height: 1rem;
  opacity: 1
}

.c-collection .slick-dots.slick-dots li button:focus,
.c-collection .slick-dots.slick-dots li button:focus-visible {
  outline-color: var(--color--interactive-focus-field)
}

.c-collection .slick-dots.slick-dots li button[aria-current="true"],
.c-collection .slick-dots.slick-dots li button:active {
  width: .625rem;
  height: .625rem
}

.c-collection .slick-dots.slick-dots li button[aria-current="true"]:focus-visible::before,
.c-collection .slick-dots.slick-dots li button:active:focus-visible::before {
  background-color: unset;
  outline-color: var(--color--interactive-focus-field);
  width: 1.5rem;
  height: 1.5rem;
  opacity: 1;
  border: unset
}

.c-collection--light.c-collection .slick-dots.slick-dots li button {
  --color--icon-secondary: 21, 21, 21;
  background-color: rgba(var(--color--icon-secondary), 0.5)
}

.c-collection--light.c-collection .slick-dots.slick-dots li button:not(:active, [aria-current="true"]):hover::before {
  background-color: var(--color--ui-06)
}

.c-collection--light.c-collection .slick-dots.slick-dots li button[aria-current="true"],
.c-collection--light.c-collection .slick-dots.slick-dots li button:active {
  background-color: rgba(var(--color--icon-secondary), 1.0);
  outline-color: rgba(var(--color--icon-secondary), 1.0)
}

.c-collection--dark.c-collection .slick-dots.slick-dots li button {
  --color--icon-primary-inverse: 254, 254, 254;
  background-color: rgba(var(--color--icon-primary-inverse), 0.5)
}

.c-collection--dark.c-collection .slick-dots.slick-dots li button:not(:active, [aria-current="true"]):hover::before {
  background-color: var(--color--ui-04)
}

.c-collection--dark.c-collection .slick-dots.slick-dots li button[aria-current="true"],
.c-collection--dark.c-collection .slick-dots.slick-dots li button:active {
  background-color: rgba(var(--color--icon-primary-inverse), 1.0);
  outline-color: rgba(var(--color--icon-primary-inverse), 1.0)
}

.c-collection--light .c-collection__row {
  --color-scrollbar-track: #05112a10;
  --color-scrollbar-thumb: #05122a30
}

.c-collection--dark .c-collection__row {
  --color-scrollbar-track: #e8eefa15;
  --color-scrollbar-thumb: #e8eefa35
}

.c-collection--grid .c-collection__row {
  --cols: 0;
  margin: 0
}

@media(min-width:769px) {
  .c-collection--grid .c-collection__row {
    --gap: var(--spacing-30);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(max(200px, (100% - 1*(var(--cols) - 1)*var(--gap))/var(--cols)), 1fr));
    gap: var(--gap)
  }

  .c-collection--grid .c-collection__row[data-slick*="6"] {
    --cols: 6
  }

  .c-collection--grid .c-collection__row[data-slick*="5"] {
    --cols: 5
  }

  .c-collection--grid .c-collection__row[data-slick*="4"] {
    --cols: 4
  }

  .c-collection--grid .c-collection__row[data-slick*="3"] {
    --cols: 3
  }

  .c-collection--grid .c-collection__row[data-slick*="2"] {
    --cols: 2
  }
}

@media(max-width:768px) {
  .c-collection--grid .c-collection__row {
    flex-wrap: nowrap;
    gap: var(--space-small);
    padding: var(--space-small) var(--space-xxsmall) var(--space-small) var(--space-xxsmall);
    margin-inline: calc(var(--space-xxsmall) * -1);
    transform: translateY(calc(var(--space-xxsmall) * -1))
  }
}

.c-collection--grid .c-collection__row [class*="cq-placeholder"] {
  display: none
}

.c-collection--grid *+.c-collection__row {
  margin-block-start: var(--space-medium)
}

.c-collection--grid [class*="c-collection__col"] {
  flex: 0 1 auto;
  padding: 0 !important
}

@media(min-width:769px) {
  .c-collection--grid [class*="c-collection__col"] {
    width: unset
  }
}

@media(min-width:376px) and (max-width:767px) {
  .c-collection--grid [class*="c-collection__col"] {
    width: calc(75% - 22.5px);
    flex: 0 0 calc(75% - 22.5px)
  }
}

@media(width:768px) {
  .c-collection--grid [class*="c-collection__col"] {
    width: calc(40% - 22.5px);
    flex: 0 0 calc(40% - 22.5px)
  }
}

@media(max-width:375px) {
  .c-collection--grid [class*="c-collection__col"] {
    width: calc(80% - 22.5px);
    flex: 0 0 calc(80% - 22.5px)
  }
}

@media(min-width:769px) {
  .c-collection--basic .c-collection__img {
    height: 185px
  }
}

@media(min-width:376px) and (max-width:768px) {
  .c-collection--basic .c-collection__img {
    height: 115px
  }
}

@media(max-width:375px) {
  .c-collection--basic .c-collection__img {
    height: 85px
  }
}

.c-collection--basic .c-collection__footer {
  padding: var(--space-medium)
}

.c-collection--basic .c-collection__footer-title {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-5)
}

.c-collection--basic .c-collection__footer-desc {
  color: var(--color--text-primary);
  font-size: var(--typesize-milli);
  line-height: var(--leading--1-2);
  font-weight: var(--typeweight--default)
}

.c-collection--basic .c-collection__footer-textBasic {
  color: var(--color--text-primary);
  line-height: var(--leading--1-3)
}

@media(max-width:768px) {
  .c-collection--featured .c-collection__img {
    height: 50px
  }
}

@media(min-width:769px) and (max-width:991px) {
  .c-collection--featured .c-collection__img {
    height: 90px
  }
}

@media(min-width:992px) {
  .c-collection--featured .c-collection__img {
    height: 160px
  }
}

.c-collection--featured .c-collection__footer-title {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-h5);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  color: var(--color--text-primary)
}

@media(min-width:376px) {
  .c-collection--featured .c-collection__footer-title {
    display: block
  }
}

@media(max-width:375px) {
  .c-collection--featured .c-collection__footer-title {
    display: none
  }
}

@media(min-width:376px) and (max-width:768px) {
  .c-collection--featured .c-collection__footer-textBasic {
    margin-block-end: 0
  }
}

@media(max-width:768px) {
  .c-collection--featured .c-collection__footer-textBasic {
    display: block
  }
}

@media(max-width:375px) {
  .c-collection--featured .c-collection__footer-textBasic {
    display: none
  }
}

.c-collection--featured .text-primary-link.text-primary-link {
  text-align: start
}

@media(min-width:376px) {
  .c-collection--featured .text-primary-link.text-primary-link {
    display: block
  }
}

@media(min-width:376px) {
  .c-collection--featured .c-collection__footer-mobile-link {
    display: none
  }
}

@media(max-width:375px) {
  .c-collection--featured .c-collection__footer-mobile-link {
    display: block;
    font-family: var(--typefamily-primary);
    font-size: var(--typesize-h5);
    font-weight: var(--typeweight--default);
    line-height: var(--leading--1-2);
    color: var(--color--text-primary)
  }
}

.c-collection-mobile-stack .c-collection--featured [class*="c-collection__col"] {
  flex: 0 0 calc(50% - 22.5px)
}

.c-collection--product-highlight .static-collection-card[class*="stroke-bottom--"] {
  border-block-end: 0
}

.c-collection--product-highlight .static-collection-card[class*="stroke-bottom--accent-01-primary"] .c-collection__item {
  border-block-end-color: var(--color--accent-01-primary)
}

.c-collection--product-highlight .static-collection-card[class*="stroke-bottom--accent-01-medium"] .c-collection__item {
  border-block-end-color: var(--color--accent-01-medium)
}

.c-collection--product-highlight .static-collection-card[class*="stroke-bottom--accent-02-primary"] .c-collection__item {
  border-block-end-color: var(--color--accent-02-primary)
}

.c-collection--product-highlight .static-collection-card[class*="stroke-bottom--accent-02-medium"] .c-collection__item {
  border-block-end-color: var(--color--accent-02-medium)
}

.c-collection--product-highlight .static-collection-card[class*="stroke-bottom--accent-03-light"] .c-collection__item {
  border-block-end-color: var(--color--accent-03-light)
}

.c-collection--product-highlight .static-collection-card[class*="stroke-bottom--accent-03-primary"] .c-collection__item {
  border-block-end-color: var(--color--accent-03-primary)
}

.c-collection--product-highlight .c-collection__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-block-end: 10px solid transparent
}

@media(min-width:769px) {
  .c-collection--product-highlight .c-collection__item {
    padding: var(--space-medium) 0
  }
}

@media(min-width:375px) and (max-width:768px) {
  .c-collection--product-highlight .c-collection__item {
    padding: var(--space-small) 0
  }
}

.c-collection--product-highlight .c-collection__label {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-minor);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-5);
  color: var(--color--text-primary);
  background-color: var(--color--accent-01-light);
  padding: .6em 1.25em;
  border-radius: 2.5rem;
  position: absolute;
  inset-block-start: 35px;
  inset-inline-start: var(--spacing-15);
  z-index: 1
}

.c-collection--product-highlight .c-collection__img {
  width: 55%;
  height: auto;
  aspect-ratio: .78125;
  position: relative;
  border-radius: var(--corners-semiround-md);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01))
}

.c-collection--product-highlight .c-collection__img img {
  object-fit: cover;
  object-position: top
}

.c-collection--product-highlight .c-collection__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: unset;
  width: 100%;
  margin-block-start: var(--space-small)
}

@media(min-width:769px) {
  .c-collection--product-highlight .c-collection__footer {
    padding: 0 var(--space-medium)
  }
}

@media(max-width:768px) {
  .c-collection--product-highlight .c-collection__footer {
    padding: 0 var(--space-small)
  }
}

.c-collection--product-highlight .c-collection__footer-title {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-2);
  text-align: center
}

.c-collection--product-highlight .c-collection__footer-desc {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-milli);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-5);
  color: var(--color--text-tertiary);
  text-align: center;
  text-transform: unset
}

*+.c-collection--product-highlight .c-collection__footer-desc {
  margin-block-start: var(--space-xxsmall)
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary);
  transition: color .125s ease-in-out;
  margin-block-start: var(--space-small) !important
}

@media(min-width:376px) {
  *+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  *+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:hover,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:focus,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:focus-visible,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:hover::before,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:focus::before,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:focus-visible::before,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:active::before {
  transform: scale(1.05)
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:focus,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:focus-visible {
  outline: 0
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:focus::before,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:active::before {
  outline-color: transparent
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:only-of-type {
  margin: 0
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link::before {
  outline-color: var(--color--interactive-tertiary);
  outline-offset: -2px;
  background-color: transparent
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:hover {
  color: var(--color--text-primary-inverse)
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:hover::before {
  outline-color: var(--color--interactive-tertiary-hover);
  background: var(--color--interactive-tertiary-hover)
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:focus,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:focus-visible {
  color: var(--color--text-tertiary)
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:focus::before,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:active {
  color: var(--color--text-primary-inverse)
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link:active::before {
  background: var(--color--interactive-tertiary-active)
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link[disabled],
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link[disabled]::before,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link[disabled]:hover::before,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link.button-disabled:hover::before,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link[disabled]:focus::before,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link.button-disabled:focus::before,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link[disabled]:focus-visible::before,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link.button-disabled:focus-visible::before,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link[disabled]:active::before,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link[disabled]::before,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link.button-disabled::before {
  outline-color: var(--color--interactive-disabled-01);
  outline-offset: -2px;
  background: transparent
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link[disabled]:hover::before,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link.button-disabled:hover::before,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link[disabled]:focus::before,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link.button-disabled:focus::before,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link[disabled]:focus-visible::before,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link.button-disabled:focus-visible::before,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link[disabled]:active::before,
*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link.button-disabled:active::before {
  background: transparent
}

*+.c-collection--product-highlight .c-collection__footer-bottom .text-primary-link::after {
  display: none
}

@media(prefers-reduced-motion:no-preference) {
  .static-collection.animated .static-collection-card {
    opacity: 0;
    transform: translate(0, 20px)
  }

  .static-collection.animated.in-view .static-collection-card {
    animation-duration: .5s;
    animation-name: slidein;
    animation-fill-mode: forwards
  }
}

@media all and (max-width:768px) {
  .pagination {
    justify-content: center
  }
}

.ais-Pagination-list {
  display: flex;
  align-items: center;
  padding: 0
}

@media all and (max-width:768px) {
  .ais-Pagination-list {
    justify-content: center
  }
}

.ais-Pagination-list span {
  display: none
}

.ais-Pagination-item {
  list-style-type: none
}

@media(min-width:769px) {
  .ais-Pagination-item+.ais-Pagination-item {
    margin-left: .375rem
  }
}

.ais-Pagination-item.ais-Pagination-item--of {
  order: 2;
  margin: .34rem .625rem 0;
  display: none
}

@media all and (max-width:768px) {
  .ais-Pagination-item.ais-Pagination-item--of {
    display: block
  }
}

.ais-Pagination-item.ais-Pagination-item--previousPage,
.ais-Pagination-item.ais-Pagination-item--nextPage {
  display: block;
  width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
  border-radius: 50%
}

.ais-Pagination-item.ais-Pagination-item--previousPage {
  margin-top: 5px
}

@media all and (max-width:768px) {
  .ais-Pagination-item.ais-Pagination-item--previousPage {
    order: 1
  }
}

@media all and (max-width:768px) {
  .ais-Pagination-item.ais-Pagination-item--nextPage {
    order: 3
  }
}

@media all and (max-width:768px) {
  .ais-Pagination-item {
    display: none
  }
}

a.ais-Pagination-link {
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  line-height: 2.25rem;
  color: var(--color--interactive-secondary);
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  aspect-ratio: 1;
  position: relative;
  z-index: 1;
  padding: 0
}

a.ais-Pagination-link::before {
  border-radius: 50%;
  width: 2.25rem;
  height: 2.25rem;
  background-color: var(--color--ui-06);
  border: .125em solid transparent;
  display: block;
  content: "";
  flex: 0 0 auto;
  position: absolute;
  z-index: -1;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  transform: scale(0);
  transition: all .125s ease-in-out
}

a.ais-Pagination-link:hover,
a.ais-Pagination-link:active {
  color: currentColor;
  text-decoration: none
}

a.ais-Pagination-link:hover::before,
a.ais-Pagination-link:active::before {
  transform: scale(1)
}

a.ais-Pagination-link:not(.ais-Pagination-link--disabled):focus {
  outline: 0;
  text-decoration: none
}

a.ais-Pagination-link:not(.ais-Pagination-link--disabled):focus::before {
  transform: scale(1);
  border-color: var(--color--interactive-focus-field)
}

.ais-Pagination-item--selected a.ais-Pagination-link {
  font-weight: var(--typeweight--bold);
  color: var(--color--text-primary-inverse);
  pointer-events: none
}

.ais-Pagination-item--selected a.ais-Pagination-link::before {
  background-color: var(--color--interactive-secondary);
  transform: none;
  transition: none
}

a.ais-Pagination-link.ais-Pagination-link--nextPage::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  transform: rotate(180deg);
  margin: auto
}

a.ais-Pagination-link.ais-Pagination-link--previousPage::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  margin: auto
}

a.ais-Pagination-link.ais-Pagination-link--disabled {
  color: var(--color--interactive-disabled-04);
  pointer-events: none
}

a.ais-Pagination-link.ais-Pagination-link--disabled:focus {
  outline: 0
}

.ais-Pagination-item--selected a.ais-Pagination-link.ais-Pagination-link--disabled {
  color: var(--color--text-primary-inverse)
}

@media all and (max-width:768px) {
  a.ais-Pagination-link:not(.ais-Pagination-link--nextPage, .ais-Pagination-link--previousPage) {
    display: none
  }
}

@supports selector(:focus-visible) {
  a.ais-Pagination-link:not(.ais-Pagination-link--disabled):focus-visible {
    text-decoration: none;
    outline: 0
  }

  a.ais-Pagination-link:not(.ais-Pagination-link--disabled):focus-visible::before {
    transform: scale(1);
    border-color: var(--color--interactive-focus)
  }

  a.ais-Pagination-link--disabled:focus-visible {
    outline: 0
  }

  .ais-Pagination-item--selected a.ais-Pagination-link:focus-visible {
    text-decoration: none;
    outline: 0
  }

  .ais-Pagination-item--selected a.ais-Pagination-link:focus-visible::before {
    border-color: var(--color--interactive-focus)
  }
}

.pagination--on-dark a.ais-Pagination-link {
  color: var(--color--text-primary-inverse)
}

.pagination--on-dark a.ais-Pagination-link.ais-Pagination-link--previousPage.ais-Pagination-link--disabled,
.pagination--on-dark a.ais-Pagination-link.ais-Pagination-link--nextPage.ais-Pagination-link--disabled {
  color: var(--color--interactive-disabled-03)
}

.pagination--on-dark a.ais-Pagination-link::before {
  background-color: var(--color--ui-04)
}

.pagination--on-dark .ais-Pagination-item--selected a.ais-Pagination-link {
  color: var(--color--text-primary)
}

.pagination--on-dark .ais-Pagination-item--selected a.ais-Pagination-link::before {
  background-color: var(--color--interactive-secondary-inverse)
}

.pagination--on-dark .ais-Pagination-list::after {
  color: var(--color--text-primary-inverse)
}

body>footer {
  position: relative;
  background-color: var(--color--ui-background-dark, var(--color--background-dark))
}

@media(min-width:768px) {
  .c-footer__main>.container>.row>.col-12 {
    display: grid;
    grid-column-gap: 30px
  }
}

@media(min-width:1025px) {
  .c-footer__main>.container>.row>.col-12 {
    grid-template-columns: 1.5fr .5fr .5fr 140px
  }
}

@media(max-width:1024px) {
  .c-footer__main>.container>.row>.col-12 {
    grid-template-columns: 1.5fr 1fr 1fr 140px
  }
}

@media(width:768px) {
  .c-footer__main>.container>.row>.col-12 {
    grid-template-rows: auto auto;
    grid-template-columns: 1.5fr 1fr 1.25fr
  }
}

.c-footer__logo {
  display: inline-flex;
  height: 34px
}

.c-footer__tagline.c-footer__tagline {
  margin-top: 24px
}

.c-footer__tagline.c-footer__tagline * {
  color: var(--color--text-primary-inverse)
}

.c-footer__social-links.c-footer__social-links {
  margin-top: 20px
}

@media(max-width:767px) {
  .c-footer__social-links.c-footer__social-links {
    margin-bottom: 45px
  }
}

.c-footer__social-links .social-links__list {
  flex-wrap: wrap
}

@media(min-width:376px) {
  .c-footer__social-links .social-links__list {
    justify-content: flex-start
  }
}

.c-footer__social-links .social-links__item {
  flex: 0 0 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: 16px;
  margin-bottom: 16px
}

.c-footer__social-links .social-links__item+.social-links__item {
  margin-left: 0
}

.c-footer__social-links [class*="social-links--"] {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  min-width: 24px;
  min-height: 24px;
  background-color: transparent;
  background-size: auto 12px;
  background-image: none
}

.c-footer__social-links [class*="social-links--"]::after {
  transition: transform .125s ease-in-out
}

.c-footer__social-links [class*="social-links--"][class*="social-links--"]::after {
  background-color: var(--color--icon-primary-inverse)
}

.c-footer__social-links [class*="social-links--"]:hover,
.c-footer__social-links [class*="social-links--"]:active,
.c-footer__social-links [class*="social-links--"]:focus {
  outline-offset: .5rem
}

.c-footer__social-links [class*="social-links--"]:hover::after,
.c-footer__social-links [class*="social-links--"]:active::after,
.c-footer__social-links [class*="social-links--"]:focus::after {
  transform: scale(1.5)
}

.c-footer__social-links .social-links--facebook::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-facebook-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-facebook-lg")
}

.c-footer__social-links .social-links--twitter::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-twitter-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-twitter-lg")
}

.c-footer__social-links .social-links--youtube::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-youtube-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-youtube-lg")
}

.c-footer__social-links .social-links--instagram::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-instagram-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-instagram-lg")
}

.c-footer__social-links .social-links--linkedin::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-linkedin-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-linkedin-lg")
}

.c-footer__link-list.c-footer__link-list {
  margin-top: 0
}

@media(max-width:767px) {
  .c-footer__link-list.c-footer__link-list {
    margin-top: 45px
  }

  .c-footer__link-list.c-footer__link-list:first-of-type:not(.text) {
    margin-top: 0
  }
}

.c-footer__link-list.c-footer__link-list h1,
.c-footer__link-list.c-footer__link-list h2,
.c-footer__link-list.c-footer__link-list h3,
.c-footer__link-list.c-footer__link-list h4,
.c-footer__link-list.c-footer__link-list h5,
.c-footer__link-list.c-footer__link-list h6 {
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-1);
  color: var(--color--text-primary-inverse)
}

.c-footer__link-list.c-footer__link-list h1+ul,
.c-footer__link-list.c-footer__link-list h2+ul,
.c-footer__link-list.c-footer__link-list h3+ul,
.c-footer__link-list.c-footer__link-list h4+ul,
.c-footer__link-list.c-footer__link-list h5+ul,
.c-footer__link-list.c-footer__link-list h6+ul {
  margin-top: 8px
}

@media(max-width:767px) {

  .c-footer__link-list.c-footer__link-list h1+ul,
  .c-footer__link-list.c-footer__link-list h2+ul,
  .c-footer__link-list.c-footer__link-list h3+ul,
  .c-footer__link-list.c-footer__link-list h4+ul,
  .c-footer__link-list.c-footer__link-list h5+ul,
  .c-footer__link-list.c-footer__link-list h6+ul {
    margin-top: 15px
  }
}

.c-footer__link-list.c-footer__link-list li {
  border-bottom: 0
}

.c-footer__link-list.c-footer__link-list li a {
  font-weight: var(--typeweight--default);
  color: var(--color--text-primary-inverse)
}

.c-footer__link-list.c-footer__link-list li a:hover,
.c-footer__link-list.c-footer__link-list li a:active,
.c-footer__link-list.c-footer__link-list li a:focus {
  color: var(--color--text-primary-inverse)
}

@media(min-width:768px) {
  .c-footer__link-list.c-footer__link-list li {
    padding: 8px 0
  }
}

@media(max-width:768px) {
  .c-footer__link-list.c-footer__link-list li:last-child {
    border-bottom: 0
  }
}

@media(max-width:767px) {
  .c-footer__link-list.c-footer__link-list li {
    margin-top: 15px
  }

  .c-footer__link-list.c-footer__link-list li:first-child {
    margin-top: 0
  }

  .c-footer__link-list.c-footer__link-list li a {
    padding: 0
  }
}

@media(width:768px) {
  .c-footer__column-get-the-app {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 4
  }
}

@media(max-width:768px) {
  .c-footer__column-get-the-app>.row>.col-12 {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 140px 140px;
    grid-template-areas: "badge-header badge-header""badge-one badge-two";
    padding-bottom: 15px
  }

  .c-footer__column-get-the-app .c-footer__link-list {
    grid-area: badge-header
  }

  .c-footer__column-get-the-app .c-footer__badge-1 {
    grid-area: badge-one
  }

  .c-footer__column-get-the-app .c-footer__badge-2 {
    grid-area: badge-two
  }
}

*+.c-footer__app-badge.c-footer__app-badge {
  margin-top: 16px
}

.c-footer__app-badge.c-footer__app-badge a {
  display: inline-block
}

.c-footer__app-badge.c-footer__app-badge img {
  height: 40px
}

.c-footer__legal-notification {
  border-top: 1px solid var(--color--ui-border-02, var(--color--border-02))
}

.c-footer__legal-notification .legal-notification>.container {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0
}

@media(max-width:767px) {
  .c-footer__legal-notification .legal-notification>.container {
    flex-direction: column
  }
}

.c-footer__legal-notification .legal-notification-copy {
  font-size: var(--typesize-default);
  color: var(--color--text-primary-inverse);
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  white-space: nowrap;
  -webkit-box-ordinal-group: 3;
  order: 2
}

@media(max-width:767px) {
  .c-footer__legal-notification .legal-notification-copy {
    margin-top: 60px
  }
}

.c-footer__legal-notification .legal-notification-links-container {
  flex: 1 1 auto;
  order: 1;
  padding-right: 30px
}

.c-footer__legal-notification .legal-notification-links {
  display: flex;
  flex-wrap: wrap
}

@media(min-width:768px) {
  .c-footer__legal-notification .legal-notification-links {
    flex: 1 1 auto
  }
}

@media(max-width:767px) {
  .c-footer__legal-notification .legal-notification-links {
    flex-direction: column
  }
}

.c-footer__legal-notification .legal-notification-links li {
  list-style-type: none
}

@media(min-width:1025px) {
  .c-footer__legal-notification .legal-notification-links li {
    margin-right: 45px;
    margin-bottom: 15px
  }
}

@media(max-width:767px) {
  .c-footer__legal-notification .legal-notification-links li {
    margin-bottom: 15px
  }
}

.c-footer__legal-notification .legal-notification-links a {
  font-weight: var(--typeweight--default);
  color: var(--color--text-primary-inverse)
}

.c-footer__legal-notification .legal-notification-links a:hover,
.c-footer__legal-notification .legal-notification-links a:active,
.c-footer__legal-notification .legal-notification-links a:focus {
  color: var(--color--text-primary-inverse)
}

@media(min-width:768px) and (max-width:1024px) {
  .c-footer__legal-notification .legal-notification>.container {
    display: flex;
    flex-flow: row;
    justify-content: flex-end
  }

  .c-footer__legal-notification .legal-notification-copy {
    font-size: var(--typesize-default);
    text-align: right;
    white-space: normal;
    display: block;
    flex-shrink: 1;
    width: auto
  }

  .c-footer__legal-notification .legal-notification-links-container {
    min-width: 78%;
    flex-grow: 1;
    padding-right: 26px
  }

  .c-footer__legal-notification .legal-notification-links {
    display: grid;
    gap: 15px;
    grid-template-columns: .8fr 1.2fr
  }
}

@media all and (max-width:768px) {
  body.preventScroll {
    overflow: hidden
  }
}

body #pmc_header header,
body>header {
  background-color: transparent;
  position: relative;
  top: 0;
  z-index: 1000
}

body #pmc_header header.isSticky,
body>header.isSticky {
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100%
}

body #pmc_header header.isVisible,
body>header.isVisible {
  opacity: 1;
  transition: all .325s ease-in-out
}

body.edit>header,
body.cq-Editable-dom>header {
  position: static
}

.js-preloader {
  display: block;
  position: relative;
  min-height: 100vh;
  border-color: transparent;
  background-color: orange
}

.js-preloader:before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 216px;
  width: 100%;
  background-color: orange
}

// .js-preloader:after{display:block;content:url('assets/icons/loading-spinner.svg');position:absolute;top:0;bottom:0;margin:auto;left:0;right:0;height:50px;width:50px;animation:spin 1s infinite linear}
.skip-nav {
  position: absolute;
  display: inline-block;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 10px 15px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0)
}

.skip-nav:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 1px;
  clip: auto;
  flex: none
}

.c-modal.c-modal.active [role="dialog"] {
  width: 90vw
}

@media(forced-colors:active) and (prefers-color-scheme:dark) {
  .c-modal.c-modal.active [role="dialog"] {
    outline: 2px solid currentColor;
    outline-offset: -2px
  }
}

@media(forced-colors:active) and (prefers-color-scheme:light) {
  .c-modal.c-modal.active [role="dialog"] {
    outline: 2px solid currentColor;
    outline-offset: -2px
  }
}

.c-modal__col-12.c-modal__col-12 [role="dialog"] {
  max-width: 1390px
}

@media(min-width:481px) {
  .c-modal__wrap.c-modal__wrap {
    padding: var(--space-large)
  }
}

@media(max-width:480px) {
  .c-modal__wrap.c-modal__wrap {
    padding: var(--space-large) var(--space-medium) var(--space-medium) var(--space-medium)
  }
}

.c-modal__title.c-modal__title {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h1);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-15);
  margin-bottom: 0;
  color: var(--color--text-primary);
  text-align: center;
  margin-bottom: 30px
}

.typefamily-primary .c-modal__title.c-modal__title {
  font-weight: var(--typeweight--light)
}

.c-modal__title-sm .c-modal__title.c-modal__title {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h3);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0;
  margin-bottom: 30px
}

.c-modal__title-left .c-modal__title.c-modal__title {
  text-align: left
}

.c-modal__content.c-modal__content {
  overflow: unset;
  transition: outline .125s ease-in-out;
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent
}

.c-modal__content.c-modal__content .text[class*="use-button-"]:first-of-type p:last-of-type {
  margin-top: 30px
}

.c-modal__content.c-modal__content .youtube-video {
  margin-bottom: 0
}

.c-modal__close.c-modal__close {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: unset;
  background-image: unset;
  background-repeat: unset;
  background-size: unset;
  margin-top: 0;
  margin-right: 0;
  transition: none;
  height: 36px;
  width: 36px;
  top: 8px;
  right: 8px
}

.c-modal__close.c-modal__close:hover,
.c-modal__close.c-modal__close:focus {
  outline: 0;
  background-color: transparent
}

.c-modal__close.c-modal__close::before {
  display: block;
  content: "";
  flex: 0 0 auto;
  border-radius: 50%;
  width: inherit;
  height: inherit;
  background-color: var(--color--ui-01);
  position: absolute;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  transform: scale(0);
  transition: transform .125s ease-in-out;
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent
}

.c-modal__close.c-modal__close::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg");
  transform: none;
  top: unset;
  left: unset;
  border-radius: 0
}

.c-modal__close.c-modal__close:hover::before,
.c-modal__close.c-modal__close:focus::before,
.c-modal__close.c-modal__close:focus-visible::before,
.c-modal__close.c-modal__close:active::before {
  transform: scale(1)
}

.c-modal__close.c-modal__close:focus,
.c-modal__close.c-modal__close:focus-visible {
  outline: 0
}

.c-modal__close.c-modal__close:focus::before,
.c-modal__close.c-modal__close:focus-visible::before {
  outline-color: var(--color--interactive-focus-field)
}

@media(forced-colors:active) and (prefers-color-scheme:dark) {

  .c-modal__close.c-modal__close:focus,
  .c-modal__close.c-modal__close:focus-visible {
    outline-width: 2px;
    outline-style: solid;
    outline-offset: 1px
  }

  .c-modal__close.c-modal__close::after {
    filter: brightness(0) invert(1)
  }

  .c-modal__close.c-modal__close:hover::after,
  .c-modal__close.c-modal__close:focus::after,
  .c-modal__close.c-modal__close:focus-visible::after,
  .c-modal__close.c-modal__close:active::after {
    filter: unset
  }

  .c-modal__close.c-modal__close::before {
    outline-color: currentColor;
    outline-offset: -2px;
    transform: unset;
    opacity: unset;
    background-color: unset
  }

  .c-modal__close.c-modal__close:hover::before,
  .c-modal__close.c-modal__close:focus::before,
  .c-modal__close.c-modal__close:focus-visible::before,
  .c-modal__close.c-modal__close:active::before {
    background-color: currentColor;
    filter: brightness(0) invert(1)
  }
}

@media(forced-colors:active) and (prefers-color-scheme:light) {
  .c-modal__close.c-modal__close {
    border-radius: 50%;
    outline-width: 2px;
    outline-style: solid;
    outline-color: currentColor
  }

  .c-modal__close.c-modal__close:hover,
  .c-modal__close.c-modal__close:focus,
  .c-modal__close.c-modal__close:focus-visible,
  .c-modal__close.c-modal__close:active {
    outline-width: 2px;
    outline-style: solid;
    outline-color: currentColor
  }

  .c-modal__close.c-modal__close::after {
    filter: brightness(1) invert(1)
  }

  .c-modal__close.c-modal__close:hover::after,
  .c-modal__close.c-modal__close:focus::after,
  .c-modal__close.c-modal__close:focus-visible::after,
  .c-modal__close.c-modal__close:active::after {
    filter: unset
  }

  .c-modal__close.c-modal__close:hover::before,
  .c-modal__close.c-modal__close:focus::before,
  .c-modal__close.c-modal__close:focus-visible::before,
  .c-modal__close.c-modal__close:active::before {
    filter: brightness(1) invert(1);
    outline: 0
  }
}

.c-modal__close.c-modal__close::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg")
}

.modal-component--no-close .c-modal__close.c-modal__close {
  display: none
}

@supports selector(:focus-visible) {
  .c-modal__close:focus-visible {
    outline: 0;
    background-color: transparent
  }

  .c-modal__close:focus-visible::before {
    transform: scale(1);
    outline-color: var(--color--interactive-focus)
  }

  .c-modal__content.c-modal__content[tabindex]:focus-visible {
    outline-color: var(--color--interactive-focus)
  }
}

body:not(.edit):not(.cqm-edit) .nav__wrapper {
  position: absolute
}

.en-page.edit .nav__wrapper .c-nav__search,
.en-page.cq-wcm-edit .nav__wrapper .c-nav__search {
  display: block !important
}

.nav__wrapper {
  align-items: center;
  box-shadow: var(--shadow);
  display: flex;
  height: 64px;
  padding: 0;
  width: 100%
}

.nav__wrapper>.container {
  max-width: 100%;
  padding: 0 20px
}

@media(max-width:768px) {
  .nav__wrapper>.container {
    padding: 0 10px
  }

  .nav__wrapper>.container>.row {
    margin-right: -10px;
    margin-left: -10px
  }
}

.nav__wrapper .col-12 {
  align-items: center;
  display: grid;
  grid-template-columns: 134px 1fr auto auto;
  min-height: 40px;
  padding: 0 15px
}

.nav__wrapper .header__logo {
  width: 134px
}

.nav__wrapper .header__logo a {
  display: block
}

.nav__wrapper .c-nav__account {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative
}

*+.nav__wrapper .c-nav__account,
.nav__wrapper .c-nav__account+div {
  margin-top: 0 !important
}

.nav__wrapper .c-nav__account ul {
  align-items: center;
  display: flex
}

.nav__wrapper .c-nav__account ul li {
  border: 0;
  padding: 0
}

.nav__wrapper .c-nav__account ul li:first-child+* {
  margin-left: 20px
}

.nav__wrapper .c-nav__account a {
  color: yellow;
  font-size: var(--typesize-minor);
  white-space: nowrap
}

.nav__wrapper .c-nav__account a::after {
  border: 1px solid navy;
  border-radius: 2px 2px 0 0;
  bottom: -10px;
  content: "";
  display: none;
  left: -5px;
  position: absolute;
  right: -5px
}

.nav__wrapper .c-nav__account a:hover,
.nav__wrapper .c-nav__account a:active,
.nav__wrapper .c-nav__account a:focus {
  text-decoration: none
}

.nav__wrapper .c-nav__account a:hover {
  font-weight: var(--typeweight--bold)
}

.nav__wrapper .c-nav__account a:hover::after {
  display: block
}

@media(max-width:767px) {
  .nav__wrapper .c-nav__account a {
    padding: 0
  }
}

.nav__wrapper .c-nav__account ul li #free-trial {
  background-color: teal;
  border-radius: 4.8em;
  color: salmon;
  padding: .6em 1.2em
}

@media(max-width:768px) {
  .nav__wrapper .c-nav__account ul li #free-trial {
    display: none
  }
}

.nav__wrapper #my-library {
  display: none
}

#inMomentInterceptModal .imContent p {
  outline: none !important
}

.page-login.isRegister .nav__wrapper,
.page-checkout-login.isRegister .nav__wrapper,
.page-checkout .nav__wrapper,
.page-updateSubscriptionsPassword .nav__wrapper,
.page-forgottenPassword .nav__wrapper,
.page-updatePassword .nav__wrapper,
.page-orderConfirmationPage .nav__wrapper,
.page-resetPasswordConfirmation .nav__wrapper,
.page-login-welcome .nav__wrapper {
  background-color: royalblue
}

.page-login.isRegister .isVisible .nav__wrapper,
.page-checkout-login.isRegister .isVisible .nav__wrapper,
.page-checkout .isVisible .nav__wrapper,
.page-updateSubscriptionsPassword .isVisible .nav__wrapper,
.page-forgottenPassword .isVisible .nav__wrapper,
.page-updatePassword .isVisible .nav__wrapper,
.page-orderConfirmationPage .isVisible .nav__wrapper,
.page-resetPasswordConfirmation .isVisible .nav__wrapper,
.page-login-welcome .isVisible .nav__wrapper {
  background-color: lightcoral
}

main .c-nav__search form {
  position: relative
}

@media(max-width:768px) {
  main .c-nav__search .ui-autocomplete {
    height: auto
  }
}

.c-nav__search {
  height: 40px;
  position: relative
}

.c-nav__search form {
  display: inline-block;
  margin: 0;
  position: relative;
  width: 100%
}

.c-nav__search form .search-box-icon,
.c-nav__search form .search-box-close-icon {
  -webkit-transform: translateY(-50%);
  background-color: transparent;
  border: 0;
  font-size: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%)
}

.c-nav__search form .search-box-icon:focus,
.c-nav__search form .search-box-close-icon:focus {
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--color--interactive-focus);
  outline-offset: 5px
}

.c-nav__search form .search-box-icon {
  border-radius: 50%;
  height: 18px;
  right: 14px;
  width: 18px
}

.c-nav__search form .search-box-icon svg {
  display: none
}

.c-nav__search form .search-box-close-icon {
  right: 20px
}

.c-nav__search form .search-box-close-icon::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md")
}

@media(max-width:768px) {
  .c-nav__search form .search-box-close-icon {
    right: 19px
  }
}

.c-nav__search form .search-box-input {
  border-radius: 40px;
  display: block;
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-default);
  font-weight: var(--typeweight-default);
  height: 40px;
  padding: 0 42px 0 24px;
  width: 100%
}

.c-nav__search form .search-box-input::-webkit-input-placeholder,
.c-nav__search form .search-box-input::placeholder {
  color: var(--color--text-secondary);
  font-size: var(--typesize-default)
}

.c-nav__search form .search-box-input::-webkit-search-decoration,
.c-nav__search form .search-box-input::-webkit-search-cancel-button,
.c-nav__search form .search-box-input::-webkit-search-results-button,
.c-nav__search form .search-box-input::-webkit-search-results-decoration {
  display: none
}

.c-nav__search form .search-box-input:hover::-webkit-input-placeholder,
.c-nav__search form .search-box-input:focus::-webkit-input-placeholder,
.c-nav__search form .search-box-input:hover::placeholder,
.c-nav__search form .search-box-input:focus::placeholder {
  color: var(--color--text-primary)
}

.c-nav__search form .search-box-input:focus {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus);
  outline-offset: -2px;
  box-shadow: unset
}

.c-nav__search form .search-box-input.ui-autocomplete-open {
  color: grey
}

@media(min-width:769px) {
  .c-nav__search form .search-box-input {
    transition: background-color .175s ease-in-out
  }
}

@media(max-width:768px) {
  .c-nav__search form .search-box-input {
    font-size: var(--typesize-default)
  }
}

.c-nav__search form.isOpen {
  background-color: var(--color--ui-02);
  border-bottom: 1px solid var(--color--ui-01);
  bottom: 0;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10
}

@media all and (max-width:768px) {
  .c-nav__search form.isOpen::after {
    background: var(--color--ui-02);
    content: '';
    height: calc(var(--vh, 1vh) * 100 - 64px);
    left: 0;
    position: absolute;
    right: 0;
    top: 64px;
    z-index: 10
  }
}

.c-nav__search form.isOpen button.ais-SearchBox-submit.search-box-icon {
  display: block
}

.c-nav__search form.isOpen .ais-SearchBox-loadingIndicator {
  bottom: 23px
}

.c-nav__search form.isOpen .search-box-input,
.c-nav__search form.isOpen .search-box-close-icon {
  display: block;
  transition: none
}

.c-nav__search form.isOpen .search-box-icon {
  display: none
}

.c-nav__search form.isOpen .search-box-icon::before {
  background: var(--color--interactive-primary)
}

@media(max-width:768px) {
  .c-nav__search form.isOpen .search-box-icon {
    display: block;
    left: 18px;
    right: auto
  }
}

.nav__wrapper .c-nav__search {
  height: 40px;
  margin: auto;
  position: static;
  width: 100%
}

@media(min-width:768px) {
  .nav__wrapper .c-nav__search {
    position: relative;
    width: 62.3%
  }
}

.nav__wrapper .c-nav__search form {
  height: 40px
}

@media(max-width:768px) {
  .nav__wrapper .c-nav__search form .search-box-input {
    display: none;
    opacity: 0
  }
}

@media(max-width:768px) {
  .nav__wrapper .c-nav__search form.isOpen {
    height: calc(100% + 24px);
    top: 50%;
    transform: translateY(-50%);
    width: 100%
  }

  .nav__wrapper .c-nav__search form.isOpen .search-box-input {
    background-color: var(--color--ui-02);
    border: 0;
    border-bottom: 1px solid var(--color--ui-border-01, var(--color--border-01));
    border-radius: 0;
    box-shadow: none;
    display: block;
    height: 100%;
    opacity: 1;
    padding-left: 44px
  }

  .nav__wrapper .c-nav__search form.isOpen .search-box-input::-webkit-input-placeholder,
  .nav__wrapper .c-nav__search form.isOpen .search-box-input::placeholder {
    color: var(--color--text-tertiary)
  }
}

.page-login .c-nav__search,
.page-checkout .c-nav__search,
.page-checkout-login .c-nav__search {
  display: none !important
}

.ais-SearchBox-form.headerSearch:focus-within .ais-SearchBox-input:not(:focus) {
  background-color: var(--color--ui-02);
  color: var(--color--text-primary)
}

.ais-SearchBox-form.headerSearch:focus-within .ais-SearchBox-input:not(:focus)::-webkit-input-placeholder,
.ais-SearchBox-form.headerSearch:focus-within .ais-SearchBox-input:not(:focus)::placeholder {
  color: var(--color--text-primary)
}

.ais-SearchBox-form.headerSearch:focus-within .search-box-icon::before {
  color: var(--color--interactive-primary-hover)
}

@media(min-width:769px) {
  .par-main>.c-hero.c-hero {
    padding-top: 154px
  }
}

@media(max-width:768px) {
  .par-main>.c-hero.c-hero {
    padding-top: 104px
  }
}

.c-hero .background-video__contrast-mask {
  background-color: transparent !important;
  background: linear-gradient(146.75deg, rgba(28, 34, 46, 0.85) 39.55%, rgba(28, 34, 46, 0) 85.56%);
  opacity: 1 !important
}

.c-hero .background-video__control {
  left: 30px;
  width: 24px;
  height: 24px;
  position: absolute;
  z-index: 4
}

.c-hero .background-video__media,
.c-hero .background-video__fallback-image {
  width: 100vw
}

.c-hero.curve-bottom-right {
  clip-path: none;
  overflow: visible
}

@media(min-width:768px) {
  .c-hero.curve-bottom-right .background-video__wrap-outer {
    clip-path: ellipse(117.5% 80% at 0 20%)
  }
}

@media(max-width:767px) {
  .c-hero.curve-bottom-right .background-video__wrap-outer {
    clip-path: ellipse(170% 70% at 0 30%)
  }
}

@media(prefers-reduced-motion:no-preference) {

  .c-hero.animated .c-hero__headline,
  .c-hero.animated .c-hero__body-copy {
    opacity: 0;
    transform: translate(0, 30px)
  }

  .c-hero.animated .c-hero__body-copy-2,
  .c-hero.animated .c-hero__search {
    opacity: 0
  }

  .c-hero.animated.in-view .c-hero__headline,
  .c-hero.animated.in-view .c-hero__body-copy {
    animation-delay: .2s;
    animation-duration: .5s;
    animation-name: slidein;
    animation-fill-mode: forwards
  }

  .c-hero.animated.in-view .c-hero__body-copy-2,
  .c-hero.animated.in-view .c-hero__search {
    animation-delay: .8s;
    animation-duration: .5s;
    animation-name: fadeIn;
    animation-fill-mode: forwards
  }
}

.c-hero-image {
  min-height: 768px
}

.c-hero-image .column-control--bg::before {
  background-color: var(--color--ui-03);
  opacity: .5
}

.c-hero-image>[class*="container"],
.c-hero-image>[class*="container"]>.row {
  min-height: inherit
}

.c-hero-image>[class*="container"]>[class*="col-"],
.c-hero-image>[class*="container"]>.row>[class*="col-"] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 7.6%
}

.c-hero-image__copy {
  color: var(--color--text-primary-inverse)
}

.c-hero-image__copy .content-tile__title {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h1);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-15);
  margin-bottom: 0;
  max-width: 20ch
}

.typefamily-primary .c-hero-image__copy .content-tile__title {
  font-weight: var(--typeweight--light)
}

.c-hero-image__copy .content-tile-text {
  margin-top: 30px
}

.c-hero-image__copy .content-tile-text>* {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-h4);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0;
  max-width: 55ch
}

.c-hero__search.c-hero__search {
  margin-top: var(--spacing-60)
}

@media(min-width:768px) {
  .c-hero__search.c-hero__search {
    max-width: 630px
  }
}

.c-hero__search.c-hero__search label {
  color: var(--color--text-primary-inverse);
  margin-bottom: var(--spacing-15)
}

.c-hero__search.c-hero__search .search-box-input {
  border: unset;
  box-shadow: unset;
  outline-width: 1px;
  outline-style: solid;
  outline-color: var(--color--ui-border-02, var(--color--border-02));
  outline-offset: unset
}

.c-hero__search.c-hero__search .search-box-input:hover+.search-box-icon {
  background: var(--color--interactive-primary-hover)
}

.c-hero__search.c-hero__search .search-box-input:not(.ui-autocomplete-open):focus {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field);
  outline-offset: -2px;
  box-shadow: unset
}

.c-hero__search.c-hero__search .search-box-icon {
  background: var(--color--interactive-primary);
  top: unset;
  bottom: 4px;
  height: 32px;
  width: 32px;
  right: 4px;
  transform: none;
  transition: background .2s ease-in-out
}

.c-hero__search.c-hero__search .search-box-icon:hover,
.c-hero__search.c-hero__search .search-box-icon:focus,
.c-hero__search.c-hero__search .search-box-icon:active {
  outline: unset;
  background: var(--color--interactive-primary-hover)
}

.c-hero__search.c-hero__search .search-box-icon::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-sm");
  color: var(--color--icon-primary-inverse)
}

.hero-buttons-wrapper .button--primary {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1
}

@media(min-width:376px) {
  .hero-buttons-wrapper .button--primary {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .hero-buttons-wrapper .button--primary {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.hero-buttons-wrapper .button--primary::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.hero-buttons-wrapper .button--primary:hover,
.hero-buttons-wrapper .button--primary:focus,
.hero-buttons-wrapper .button--primary:focus-visible,
.hero-buttons-wrapper .button--primary:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.hero-buttons-wrapper .button--primary:hover::before,
.hero-buttons-wrapper .button--primary:focus::before,
.hero-buttons-wrapper .button--primary:focus-visible::before,
.hero-buttons-wrapper .button--primary:active::before {
  transform: scale(1.05)
}

.hero-buttons-wrapper .button--primary:focus,
.hero-buttons-wrapper .button--primary:focus-visible {
  outline: 0
}

.hero-buttons-wrapper .button--primary:focus::before,
.hero-buttons-wrapper .button--primary:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.hero-buttons-wrapper .button--primary:active::before {
  outline-color: transparent
}

.hero-buttons-wrapper .button--primary:only-of-type {
  margin: 0
}

.hero-buttons-wrapper .button--primary.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.hero-buttons-wrapper .button--primary::before {
  background: var(--color--interactive-primary) border-box
}

.hero-buttons-wrapper .button--primary:hover::before {
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-primary-hover) border-box
}

.hero-buttons-wrapper .button--primary:focus,
.hero-buttons-wrapper .button--primary:focus-visible {
  color: var(--color--text-primary-inverse)
}

.hero-buttons-wrapper .button--primary:focus::before,
.hero-buttons-wrapper .button--primary:focus-visible::before {
  background: var(--color--interactive-primary) border-box
}

.hero-buttons-wrapper .button--primary:active {
  color: var(--color--text-primary-inverse)
}

.hero-buttons-wrapper .button--primary:active::before {
  background: var(--color--interactive-primary-active) border-box
}

.hero-buttons-wrapper .button--primary[disabled],
.hero-buttons-wrapper .button--primary.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.hero-buttons-wrapper .button--primary[disabled]::before,
.hero-buttons-wrapper .button--primary.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.hero-buttons-wrapper .button--primary[disabled]:hover::before,
.hero-buttons-wrapper .button--primary.button-disabled:hover::before,
.hero-buttons-wrapper .button--primary[disabled]:focus::before,
.hero-buttons-wrapper .button--primary.button-disabled:focus::before,
.hero-buttons-wrapper .button--primary[disabled]:focus-visible::before,
.hero-buttons-wrapper .button--primary.button-disabled:focus-visible::before,
.hero-buttons-wrapper .button--primary[disabled]:active::before,
.hero-buttons-wrapper .button--primary.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.hero-buttons-wrapper .button--secondary {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1
}

@media(min-width:376px) {
  .hero-buttons-wrapper .button--secondary {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .hero-buttons-wrapper .button--secondary {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.hero-buttons-wrapper .button--secondary::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.hero-buttons-wrapper .button--secondary:hover,
.hero-buttons-wrapper .button--secondary:focus,
.hero-buttons-wrapper .button--secondary:focus-visible,
.hero-buttons-wrapper .button--secondary:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.hero-buttons-wrapper .button--secondary:hover::before,
.hero-buttons-wrapper .button--secondary:focus::before,
.hero-buttons-wrapper .button--secondary:focus-visible::before,
.hero-buttons-wrapper .button--secondary:active::before {
  transform: scale(1.05)
}

.hero-buttons-wrapper .button--secondary:focus,
.hero-buttons-wrapper .button--secondary:focus-visible {
  outline: 0
}

.hero-buttons-wrapper .button--secondary:focus::before,
.hero-buttons-wrapper .button--secondary:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.hero-buttons-wrapper .button--secondary:active::before {
  outline-color: transparent
}

.hero-buttons-wrapper .button--secondary:only-of-type {
  margin: 0
}

.hero-buttons-wrapper .button--secondary.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.hero-buttons-wrapper .button--secondary::before {
  background: var(--color--interactive-secondary) border-box
}

.hero-buttons-wrapper .button--secondary:hover {
  color: var(--color--text-primary-inverse)
}

.hero-buttons-wrapper .button--secondary:hover::before {
  background: var(--color--interactive-secondary-hover) border-box
}

.hero-buttons-wrapper .button--secondary:focus,
.hero-buttons-wrapper .button--secondary:focus-visible {
  color: var(--color--text-primary-inverse)
}

.hero-buttons-wrapper .button--secondary:focus::before,
.hero-buttons-wrapper .button--secondary:focus-visible::before {
  background: var(--color--interactive-secondary) border-box
}

.hero-buttons-wrapper .button--secondary:active {
  color: var(--color--text-primary-inverse)
}

.hero-buttons-wrapper .button--secondary:active::before {
  background: var(--color--interactive-secondary-active) border-box
}

.hero-buttons-wrapper .button--secondary[disabled],
.hero-buttons-wrapper .button--secondary.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.hero-buttons-wrapper .button--secondary[disabled]::before,
.hero-buttons-wrapper .button--secondary.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.hero-buttons-wrapper .button--secondary[disabled]:hover::before,
.hero-buttons-wrapper .button--secondary.button-disabled:hover::before,
.hero-buttons-wrapper .button--secondary[disabled]:focus::before,
.hero-buttons-wrapper .button--secondary.button-disabled:focus::before,
.hero-buttons-wrapper .button--secondary[disabled]:focus-visible::before,
.hero-buttons-wrapper .button--secondary.button-disabled:focus-visible::before,
.hero-buttons-wrapper .button--secondary[disabled]:active::before,
.hero-buttons-wrapper .button--secondary.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.hero-buttons-wrapper .button--home {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary)
}

@media(min-width:376px) {
  .hero-buttons-wrapper .button--home {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .hero-buttons-wrapper .button--home {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.hero-buttons-wrapper .button--home::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.hero-buttons-wrapper .button--home:hover,
.hero-buttons-wrapper .button--home:focus,
.hero-buttons-wrapper .button--home:focus-visible,
.hero-buttons-wrapper .button--home:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.hero-buttons-wrapper .button--home:hover::before,
.hero-buttons-wrapper .button--home:focus::before,
.hero-buttons-wrapper .button--home:focus-visible::before,
.hero-buttons-wrapper .button--home:active::before {
  transform: scale(1.05)
}

.hero-buttons-wrapper .button--home:focus,
.hero-buttons-wrapper .button--home:focus-visible {
  outline: 0
}

.hero-buttons-wrapper .button--home:focus::before,
.hero-buttons-wrapper .button--home:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.hero-buttons-wrapper .button--home:active::before {
  outline-color: transparent
}

.hero-buttons-wrapper .button--home:only-of-type {
  margin: 0
}

.hero-buttons-wrapper .button--home.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.hero-buttons-wrapper .button--home::before {
  background: var(--color--interactive-secondary-inverse)
}

.hero-buttons-wrapper .button--home:hover {
  color: var(--color--text-primary)
}

.hero-buttons-wrapper .button--home:hover::before {
  background: var(--color--interactive-secondary-inverse-hover, var(--color--interactive-secondary-hover-inverse))
}

.hero-buttons-wrapper .button--home:focus,
.hero-buttons-wrapper .button--home:focus-visible {
  color: var(--color--text-primary)
}

.hero-buttons-wrapper .button--home:focus::before,
.hero-buttons-wrapper .button--home:focus-visible::before {
  background: var(--color--interactive-secondary-inverse)
}

.hero-buttons-wrapper .button--home:active {
  color: var(--color--text-primary)
}

.hero-buttons-wrapper .button--home:active::before {
  background: var(--color--interactive-secondary-inverse-active, var(--color--interactive-secondary-active-inverse))
}

.hero-buttons-wrapper .button--home[disabled],
.hero-buttons-wrapper .button--home.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02);
  color: var(--color--interactive-disabled-03)
}

.hero-buttons-wrapper .button--home[disabled]::before,
.hero-buttons-wrapper .button--home.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.hero-buttons-wrapper .button--home[disabled]:hover::before,
.hero-buttons-wrapper .button--home.button-disabled:hover::before,
.hero-buttons-wrapper .button--home[disabled]:focus::before,
.hero-buttons-wrapper .button--home.button-disabled:focus::before,
.hero-buttons-wrapper .button--home[disabled]:focus-visible::before,
.hero-buttons-wrapper .button--home.button-disabled:focus-visible::before,
.hero-buttons-wrapper .button--home[disabled]:active::before,
.hero-buttons-wrapper .button--home.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.hero-buttons-wrapper .button--home[disabled]::before,
.hero-buttons-wrapper .button--home.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.hero-buttons-wrapper .button--home[disabled]:hover::before,
.hero-buttons-wrapper .button--home.button-disabled:hover::before,
.hero-buttons-wrapper .button--home[disabled]:focus::before,
.hero-buttons-wrapper .button--home.button-disabled:focus::before,
.hero-buttons-wrapper .button--home[disabled]:focus-visible::before,
.hero-buttons-wrapper .button--home.button-disabled:focus-visible::before,
.hero-buttons-wrapper .button--home[disabled]:active::before,
.hero-buttons-wrapper .button--home.button-disabled:active::before {
  background: var(--color--interactive-disabled-01)
}

.hero-buttons-wrapper .button--ctap {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary)
}

@media(min-width:376px) {
  .hero-buttons-wrapper .button--ctap {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .hero-buttons-wrapper .button--ctap {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.hero-buttons-wrapper .button--ctap::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.hero-buttons-wrapper .button--ctap:hover,
.hero-buttons-wrapper .button--ctap:focus,
.hero-buttons-wrapper .button--ctap:focus-visible,
.hero-buttons-wrapper .button--ctap:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.hero-buttons-wrapper .button--ctap:hover::before,
.hero-buttons-wrapper .button--ctap:focus::before,
.hero-buttons-wrapper .button--ctap:focus-visible::before,
.hero-buttons-wrapper .button--ctap:active::before {
  transform: scale(1.05)
}

.hero-buttons-wrapper .button--ctap:focus,
.hero-buttons-wrapper .button--ctap:focus-visible {
  outline: 0
}

.hero-buttons-wrapper .button--ctap:focus::before,
.hero-buttons-wrapper .button--ctap:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.hero-buttons-wrapper .button--ctap:active::before {
  outline-color: transparent
}

.hero-buttons-wrapper .button--ctap:only-of-type {
  margin: 0
}

.hero-buttons-wrapper .button--ctap.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.hero-buttons-wrapper .button--ctap::before {
  background: var(--color--interactive-action) border-box
}

.hero-buttons-wrapper .button--ctap:hover::before {
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-action-hover) border-box
}

.hero-buttons-wrapper .button--ctap:focus,
.hero-buttons-wrapper .button--ctap:focus-visible {
  color: var(--color--text-primary)
}

.hero-buttons-wrapper .button--ctap:focus::before,
.hero-buttons-wrapper .button--ctap:focus-visible::before {
  background: var(--color--interactive-action-focus) border-box
}

.hero-buttons-wrapper .button--ctap:active {
  color: var(--color--text-primary-inverse)
}

.hero-buttons-wrapper .button--ctap:active::before {
  background: var(--color--interactive-action-active) border-box
}

.hero-buttons-wrapper .button--ctap[disabled],
.hero-buttons-wrapper .button--ctap.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.hero-buttons-wrapper .button--ctap[disabled]::before,
.hero-buttons-wrapper .button--ctap.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.hero-buttons-wrapper .button--ctap[disabled]:hover::before,
.hero-buttons-wrapper .button--ctap.button-disabled:hover::before,
.hero-buttons-wrapper .button--ctap[disabled]:focus::before,
.hero-buttons-wrapper .button--ctap.button-disabled:focus::before,
.hero-buttons-wrapper .button--ctap[disabled]:focus-visible::before,
.hero-buttons-wrapper .button--ctap.button-disabled:focus-visible::before,
.hero-buttons-wrapper .button--ctap[disabled]:active::before,
.hero-buttons-wrapper .button--ctap.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.hero-buttons-wrapper .button--ctap[disabled]::before,
.hero-buttons-wrapper .button--ctap.button-disabled::before {
  outline-offset: -2px;
  outline-color: var(--color--interactive-disabled-01);
  background: transparent
}

.hero-buttons-wrapper .button--ctap[disabled]:hover::before,
.hero-buttons-wrapper .button--ctap.button-disabled:hover::before,
.hero-buttons-wrapper .button--ctap[disabled]:focus::before,
.hero-buttons-wrapper .button--ctap.button-disabled:focus::before,
.hero-buttons-wrapper .button--ctap[disabled]:focus-visible::before,
.hero-buttons-wrapper .button--ctap.button-disabled:focus-visible::before,
.hero-buttons-wrapper .button--ctap[disabled]:active::before,
.hero-buttons-wrapper .button--ctap.button-disabled:active::before {
  background: transparent
}

.hero-buttons-wrapper .button-ghost--on-light {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary);
  transition: color .125s ease-in-out
}

@media(min-width:376px) {
  .hero-buttons-wrapper .button-ghost--on-light {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .hero-buttons-wrapper .button-ghost--on-light {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.hero-buttons-wrapper .button-ghost--on-light::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.hero-buttons-wrapper .button-ghost--on-light:hover,
.hero-buttons-wrapper .button-ghost--on-light:focus,
.hero-buttons-wrapper .button-ghost--on-light:focus-visible,
.hero-buttons-wrapper .button-ghost--on-light:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.hero-buttons-wrapper .button-ghost--on-light:hover::before,
.hero-buttons-wrapper .button-ghost--on-light:focus::before,
.hero-buttons-wrapper .button-ghost--on-light:focus-visible::before,
.hero-buttons-wrapper .button-ghost--on-light:active::before {
  transform: scale(1.05)
}

.hero-buttons-wrapper .button-ghost--on-light:focus,
.hero-buttons-wrapper .button-ghost--on-light:focus-visible {
  outline: 0
}

.hero-buttons-wrapper .button-ghost--on-light:focus::before,
.hero-buttons-wrapper .button-ghost--on-light:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.hero-buttons-wrapper .button-ghost--on-light:active::before {
  outline-color: transparent
}

.hero-buttons-wrapper .button-ghost--on-light:only-of-type {
  margin: 0
}

.hero-buttons-wrapper .button-ghost--on-light.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.hero-buttons-wrapper .button-ghost--on-light::before {
  outline-color: var(--color--interactive-tertiary);
  outline-offset: -2px;
  background-color: transparent
}

.hero-buttons-wrapper .button-ghost--on-light:hover {
  color: var(--color--text-primary-inverse)
}

.hero-buttons-wrapper .button-ghost--on-light:hover::before {
  outline-color: var(--color--interactive-tertiary-hover);
  background: var(--color--interactive-tertiary-hover)
}

.hero-buttons-wrapper .button-ghost--on-light:focus,
.hero-buttons-wrapper .button-ghost--on-light:focus-visible {
  color: var(--color--text-tertiary)
}

.hero-buttons-wrapper .button-ghost--on-light:focus::before,
.hero-buttons-wrapper .button-ghost--on-light:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

.hero-buttons-wrapper .button-ghost--on-light:active {
  color: var(--color--text-primary-inverse)
}

.hero-buttons-wrapper .button-ghost--on-light:active::before {
  background: var(--color--interactive-tertiary-active)
}

.hero-buttons-wrapper .button-ghost--on-light[disabled],
.hero-buttons-wrapper .button-ghost--on-light.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.hero-buttons-wrapper .button-ghost--on-light[disabled]::before,
.hero-buttons-wrapper .button-ghost--on-light.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.hero-buttons-wrapper .button-ghost--on-light[disabled]:hover::before,
.hero-buttons-wrapper .button-ghost--on-light.button-disabled:hover::before,
.hero-buttons-wrapper .button-ghost--on-light[disabled]:focus::before,
.hero-buttons-wrapper .button-ghost--on-light.button-disabled:focus::before,
.hero-buttons-wrapper .button-ghost--on-light[disabled]:focus-visible::before,
.hero-buttons-wrapper .button-ghost--on-light.button-disabled:focus-visible::before,
.hero-buttons-wrapper .button-ghost--on-light[disabled]:active::before,
.hero-buttons-wrapper .button-ghost--on-light.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.hero-buttons-wrapper .button-ghost--on-light[disabled]::before,
.hero-buttons-wrapper .button-ghost--on-light.button-disabled::before {
  outline-color: var(--color--interactive-disabled-01);
  outline-offset: -2px;
  background: transparent
}

.hero-buttons-wrapper .button-ghost--on-light[disabled]:hover::before,
.hero-buttons-wrapper .button-ghost--on-light.button-disabled:hover::before,
.hero-buttons-wrapper .button-ghost--on-light[disabled]:focus::before,
.hero-buttons-wrapper .button-ghost--on-light.button-disabled:focus::before,
.hero-buttons-wrapper .button-ghost--on-light[disabled]:focus-visible::before,
.hero-buttons-wrapper .button-ghost--on-light.button-disabled:focus-visible::before,
.hero-buttons-wrapper .button-ghost--on-light[disabled]:active::before,
.hero-buttons-wrapper .button-ghost--on-light.button-disabled:active::before {
  background: transparent
}

.hero-buttons-wrapper .button-ghost--on-dark {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary-inverse)
}

@media(min-width:376px) {
  .hero-buttons-wrapper .button-ghost--on-dark {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .hero-buttons-wrapper .button-ghost--on-dark {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.hero-buttons-wrapper .button-ghost--on-dark::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.hero-buttons-wrapper .button-ghost--on-dark:hover,
.hero-buttons-wrapper .button-ghost--on-dark:focus,
.hero-buttons-wrapper .button-ghost--on-dark:focus-visible,
.hero-buttons-wrapper .button-ghost--on-dark:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.hero-buttons-wrapper .button-ghost--on-dark:hover::before,
.hero-buttons-wrapper .button-ghost--on-dark:focus::before,
.hero-buttons-wrapper .button-ghost--on-dark:focus-visible::before,
.hero-buttons-wrapper .button-ghost--on-dark:active::before {
  transform: scale(1.05)
}

.hero-buttons-wrapper .button-ghost--on-dark:focus,
.hero-buttons-wrapper .button-ghost--on-dark:focus-visible {
  outline: 0
}

.hero-buttons-wrapper .button-ghost--on-dark:focus::before,
.hero-buttons-wrapper .button-ghost--on-dark:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.hero-buttons-wrapper .button-ghost--on-dark:active::before {
  outline-color: transparent
}

.hero-buttons-wrapper .button-ghost--on-dark:only-of-type {
  margin: 0
}

.hero-buttons-wrapper .button-ghost--on-dark.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.hero-buttons-wrapper .button-ghost--on-dark::before {
  outline-color: var(--color--interactive-tertiary-inverse);
  outline-offset: -2px;
  background: transparent
}

.hero-buttons-wrapper .button-ghost--on-dark:hover {
  color: var(--color--text-primary)
}

.hero-buttons-wrapper .button-ghost--on-dark:hover::before {
  outline-color: transparent;
  background: var(--color--interactive-tertiary-inverse-hover, var(--color--interactive-tertiary-hover-inverse))
}

.hero-buttons-wrapper .button-ghost--on-dark:focus,
.hero-buttons-wrapper .button-ghost--on-dark:focus-visible {
  color: var(--color--text-primary-inverse)
}

.hero-buttons-wrapper .button-ghost--on-dark:focus::before,
.hero-buttons-wrapper .button-ghost--on-dark:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

.hero-buttons-wrapper .button-ghost--on-dark:active {
  color: var(--color--text-primary)
}

.hero-buttons-wrapper .button-ghost--on-dark:active::before {
  background: var(--color--interactive-tertiary-inverse-active, var(--color--interactive-tertiary-active-inverse))
}

.hero-buttons-wrapper .button-ghost--on-dark[disabled],
.hero-buttons-wrapper .button-ghost--on-dark.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.hero-buttons-wrapper .button-ghost--on-dark[disabled]::before,
.hero-buttons-wrapper .button-ghost--on-dark.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.hero-buttons-wrapper .button-ghost--on-dark[disabled]:hover::before,
.hero-buttons-wrapper .button-ghost--on-dark.button-disabled:hover::before,
.hero-buttons-wrapper .button-ghost--on-dark[disabled]:focus::before,
.hero-buttons-wrapper .button-ghost--on-dark.button-disabled:focus::before,
.hero-buttons-wrapper .button-ghost--on-dark[disabled]:focus-visible::before,
.hero-buttons-wrapper .button-ghost--on-dark.button-disabled:focus-visible::before,
.hero-buttons-wrapper .button-ghost--on-dark[disabled]:active::before,
.hero-buttons-wrapper .button-ghost--on-dark.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.hero-buttons-wrapper .button-ghost--on-dark[disabled]::before,
.hero-buttons-wrapper .button-ghost--on-dark.button-disabled::before {
  outline-color: var(--color--interactive-disabled-02);
  background: transparent
}

.hero-buttons-wrapper .button-ghost--on-dark[disabled]:hover::before,
.hero-buttons-wrapper .button-ghost--on-dark.button-disabled:hover::before,
.hero-buttons-wrapper .button-ghost--on-dark[disabled]:focus::before,
.hero-buttons-wrapper .button-ghost--on-dark.button-disabled:focus::before,
.hero-buttons-wrapper .button-ghost--on-dark[disabled]:focus-visible::before,
.hero-buttons-wrapper .button-ghost--on-dark.button-disabled:focus-visible::before,
.hero-buttons-wrapper .button-ghost--on-dark[disabled]:active::before,
.hero-buttons-wrapper .button-ghost--on-dark.button-disabled:active::before {
  background: transparent
}

.hero-buttons-wrapper .button--block {
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-right: 0
}

.hero-buttons-wrapper [class*="button-"] {
  padding: .75em 1.25em;
  min-height: 3rem
}

.hero-buttons-wrapper [class*="button-"].icon-only {
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

.hero-carousel-slide.hero-carousel-slide h1,
.hero-carousel-slide.hero-carousel-slide h2,
.hero-carousel-slide.hero-carousel-slide .slider-slide-content__title {
  font-family: var(--typefamily-primary)
}

.slider-slide-content.slider-slide-content h1,
.slider-slide-content.slider-slide-content h2,
.slider-slide-content.slider-slide-content__title {
  font-size: var(--typesize-h2);
  line-height: var(--leading--1-2)
}

@media(min-width:1280px) {

  .slider-slide-content.slider-slide-content h1,
  .slider-slide-content.slider-slide-content h2,
  .slider-slide-content.slider-slide-content__title {
    font-size: var(--typesize-h1)
  }
}

.slider-slide-content.slider-slide-content h3,
.slider-slide-content.slider-slide-content h4,
.slider-slide-content.slider-slide-content h5,
.slider-slide-content.slider-slide-content h6,
.slider-slide-content.slider-slide-content p,
.slider-slide-content.slider-slide-content__subtitle {
  font-size: var(--typesize-h4);
  line-height: var(--leading--1-3)
}

@media(min-width:1280px) {

  .slider-slide-content.slider-slide-content h3,
  .slider-slide-content.slider-slide-content h4,
  .slider-slide-content.slider-slide-content h5,
  .slider-slide-content.slider-slide-content h6,
  .slider-slide-content.slider-slide-content p,
  .slider-slide-content.slider-slide-content__subtitle {
    font-size: var(--typesize-h3)
  }
}

.headerIsTransparent>main .hero-carousel:first-child .slider-slide__container {
  padding-top: var(--space-xlarge)
}

.headerIsTransparent>main .hero-carousel:first-child .slider-slide__container::before {
  content: "";
  display: block;
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.55) 0, rgba(0, 0, 0, 0.521) 10%, rgba(0, 0, 0, 0.487) 19.2%, rgba(0, 0, 0, 0.448) 27.7%, rgba(0, 0, 0, 0.405) 35.5%, rgba(0, 0, 0, 0.36) 42.8%, rgba(0, 0, 0, 0.313) 49.6%, rgba(0, 0, 0, 0.265) 56%, rgba(0, 0, 0, 0.219) 62%, rgba(0, 0, 0, 0.174) 67.7%, rgba(0, 0, 0, 0.132) 73.2%, rgba(0, 0, 0, 0.093) 78.5%, rgba(0, 0, 0, 0.06) 83.8%, rgba(0, 0, 0, 0.032) 89.1%, rgba(0, 0, 0, 0.012) 94.5%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
  height: 250px;
  top: 0;
  z-index: 1;
  pointer-events: none
}

.headerIsTransparent>main .hero-carousel:first-child .slider-slide__container .col-12>* {
  position: relative;
  z-index: 2
}

.c-nav__search.c-hero__search .search-box-input.ui-autocomplete-open {
  border-radius: 8px 8px 0 0;
  outline-color: unset
}

.c-nav__search form .search-box-input.ui-autocomplete-open {
  background: var(--color--ui-02);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25) !important
}

@media all and (min-width:769px) {
  .c-nav__search form .search-box-input.ui-autocomplete-open {
    border-radius: 8px 8px 0 0;
    padding-left: 16px
  }
}

.c-nav__search form .search-close-btn {
  align-items: center;
  background: transparent;
  border: 0;
  border-radius: 50%;
  bottom: 8px;
  height: 24px;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: 8px;
  width: 24px;
  z-index: 2
}

.c-nav__search form .search-close-btn::before {
  background-color: var(--color--ui-06);
  border-radius: 50%;
  bottom: auto;
  content: "";
  display: block;
  flex: 0 0 auto;
  height: 24px;
  left: auto;
  position: absolute;
  right: auto;
  top: auto;
  transform: scale(0);
  transition: transform .125s ease-in-out;
  width: 24px;
  z-index: -1
}

.c-nav__search form .search-close-btn::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md")
}

.c-nav__search form .search-close-btn:hover::before,
.c-nav__search form .search-close-btn:focus::before,
.c-nav__search form .search-close-btn:active::before {
  transform: scale(1)
}

.c-nav__search form .search-close-btn:focus {
  outline: 1px solid var(--color--interactive-focus)
}

.c-nav__search form .search-close-btn.show {
  display: flex
}

.c-nav__search form .search-close-btn>svg {
  display: none
}

.search-close-btn {
  display: none
}

.isOpen>.search-close-btn {
  display: flex
}

.ui-autocomplete-open~.search-close-btn {
  display: flex
}

.ui-autocomplete-open~.search-box-icon {
  display: none
}

.ui-autocomplete {
  background: var(--color--ui-02);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: var(--color--text-primary)
}

.ui-autocomplete ul {
  list-style-type: none;
  padding: 0;
  margin: 0
}

@media all and (max-width:768px) {
  .ui-autocomplete {
    height: calc(var(--vh, 1vh) * 100 - 64px);
    left: 0;
    position: absolute;
    right: 0;
    top: 52px;
    z-index: 11
  }
}

@media all and (min-width:769px) {
  .ui-autocomplete {
    border-radius: 0 0 8px 8px;
    position: absolute;
    width: 100%;
    z-index: 10
  }
}

.ui-autocomplete mark {
  background: 0;
  font-weight: var(--typeweight--semibold);
  padding: 0
}

.ui-autocomplete .ui-menu-item__result-count {
  padding: .625rem 1rem;
  display: inline-block;
  width: fit-content
}

.ui-autocomplete .ui-menu-item__result-text {
  display: none
}

.ui-autocomplete .search-subject_group__wrapper {
  border-top: 1px solid var(--color--ui-border-01, var(--color--border-01))
}

.ui-autocomplete .search-subject_group__wrapper .search-subject_group__heading {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-minor);
  font-weight: var(--typeweight--semibold);
  color: var(--color--text-tertiary);
  padding: 16px 16px 4px 16px
}

.ui-autocomplete .search-subject_group__wrapper:first-child {
  border-top: 0
}

.ui-autocomplete .search-subject_group__wrapper:first-child .search-subject_group__heading {
  padding-top: 12px
}

.c-hero__search .ui-autocomplete {
  border-radius: 0 0 8px 8px;
  top: auto
}

@media all and (max-width:768px) {
  li.ui-menu-item:first-child {
    padding-top: 10px
  }
}

a.ui-menu-item.ui-menu-item {
  font-weight: var(--typestyle-normal);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: var(--leading--1-5);
  padding: 12px 16px
}

@media all and (min-width:769px) {
  a.ui-menu-item.ui-menu-item {
    margin: 0
  }
}

a.ui-menu-item.ui-menu-item:hover,
a.ui-menu-item.ui-menu-item:focus {
  background-color: var(--color--ui-01);
  text-decoration: none
}

a.ui-menu-item.ui-menu-item .search-subject__title {
  display: flex;
  align-items: center
}

a.ui-menu-item.ui-menu-item .search-subject__pagename {
  display: inline-flex;
  align-items: center;
  color: var(--color--accent-01-medium);
  margin-left: 5px
}

a.ui-menu-item.ui-menu-item .search-subject__pagename::before {
  content: '';
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  transform: rotate(180deg);
  color: var(--color--text-primary);
  opacity: .59;
  margin-right: 5px;
  width: 14px;
  height: 14px;
  mask-size: 10px
}

.search-product__image {
  border-radius: 8px;
  flex-shrink: 0;
  margin-right: 16px;
  overflow: hidden
}

.search-product__image>img {
  height: auto;
  width: 50px
}

.search-product__content {
  flex-grow: 1;
  flex-shrink: 1
}

.search-product__title {
  color: var(--color--text-primary);
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-default);
  font-weight: var(--typestyle-normal);
  line-height: var(--leading--1-5);
  margin-bottom: 4px
}

@media all and (max-width:991px) {
  .search-product__title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    padding-bottom: .125em;
    text-overflow: ellipsis
  }
}

.search-product__author {
  color: var(--color--text-tertiary);
  font-family: var(--typefamily-primary);
  font-size: 12px;
  font-weight: var(--typestyle-normal);
  line-height: var(--leading--1-5);
  margin-top: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  padding-bottom: .125em;
  text-overflow: ellipsis
}

.search-product__author:empty {
  display: none
}

.ui-menu-item__result-wrap {
  display: flex;
  font-size: var(--typesize-minor);
  justify-content: space-between;
  line-height: var(--leading--1-5);
  padding: 7px 16px
}

.search-box-icon.hide {
  display: none
}

.search-box-close-icon.hide {
  display: none !important
}

.ais-SearchBox-loadingIndicator {
  -webkit-animation: 1s linear infinite both spin;
  animation: 1s linear infinite both spin;
  background: var(--color--ui-01);
  border-radius: 80%;
  bottom: 11px;
  display: block;
  height: 18px;
  position: absolute;
  right: 15px;
  width: 18px
}

.ais-SearchBox-loadingIndicator::before,
.ais-SearchBox-loadingIndicator::after {
  content: " ";
  display: block;
  position: absolute
}

.ais-SearchBox-loadingIndicator::before {
  background: var(--gradient--interactive-accent-01, var(--color--accent-01-gradient));
  border-radius: 0 90px 90px 0;
  height: 18px;
  right: 0;
  top: 0;
  width: 50%;
  z-index: 1
}

.ais-SearchBox-loadingIndicator::after {
  background: var(--color--ui-field, var(--color--field));
  border-radius: 80%;
  height: 14px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 14px;
  z-index: 1
}

.ais-SearchBox-loadingIndicator .ais-SearchBox-loadingIcon {
  height: 100%;
  width: 100%
}

.ais-SearchBox-loadingIndicator g {
  display: none
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

@keyframes spin {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

.nav__wrapper .custom-header__container {
  padding: 0
}

.nav__wrapper .custom-header__container .row {
  margin: 0
}

.nav__wrapper .custom-header {
  margin-left: auto
}

.page-login .custom-header,
.page-checkout .custom-header,
.page-checkout-login .custom-header {
  display: none !important
}

.page-checkout .c-nav__secure-text,
.page-checkout-login .c-nav__secure-text {
  display: flex !important
}

.page-checkout .c-nav__secure-text {
  justify-self: end !important
}

.nav__wrapper .c-nav__secure-text {
  align-items: center;
  display: flex
}

.nav__wrapper .c-nav__secure-text::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-locked-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-locked-sm");
  margin-left: 10px
}

.nav__wrapper .c-nav__secure-text.hide {
  display: none
}

.nav__wrapper .c-nav__secure-text p {
  font-size: var(--typesize-minor);
  line-height: var(--leading--1)
}

@media(max-width:768px) {
  .userNav {
    margin-left: 0
  }
}

.userNav.userSignedIn {
  margin-right: 20px;
  position: relative
}

@media(max-width:768px) {
  .userNav.userSignedIn {
    margin-right: 18px
  }
}

.userNav ul {
  list-style-type: none
}

.userNav__button {
  background-color: transparent;
  border: 0;
  color: var(--color--text-primary-inverse);
  cursor: pointer;
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-5);
  padding: 6px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  white-space: normal;
  z-index: 1
}

.userNav__button::before {
  background: var(--color--interactive-primary) border-box;
  border-color: transparent;
  border-radius: var(--corners-semiround-sm);
  border-style: solid;
  border-width: 2px;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: all .125s ease-in-out;
  width: 100%;
  z-index: -2
}

.userNav__button:hover,
.userNav__button:active,
.userNav__button:focus {
  text-decoration: none;
  transform: none
}

.userNav__button:hover::before {
  background-color: var(--color--interactive-primary-hover)
}

.userNav__button:active::before {
  background-color: var(--color--interactive-primary-active)
}

.userNav__button:focus::before {
  border: 2px solid var(--color--interactive-focus)
}

.userNav__button:focus:not(:focus-visible) {
  outline: 0
}

.userNav:not(.userSignedIn) .userNav__button {
  padding: .535em 1.25em
}

.userNav.userSignedIn .userNav__button {
  background: transparent;
  border: 2px solid currentColor;
  border-radius: 50%;
  font-weight: var(--typeweight--bold);
  height: 36px;
  line-height: 1;
  padding: 0;
  position: relative;
  transition: background-color .2s ease-in-out;
  width: 36px
}

.userNav.userSignedIn .userNav__button::before {
  display: none
}

.userNav.userSignedIn .userNav__button::after {
  line-height: inherit;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  content: "";
  display: inline-block;
  flex: none;
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translate(0, -50%);
  transition: transform .125s ease-in-out
}

.userNav.userSignedIn .userNav__button[aria-expanded='true']::after {
  transform: translate(0, -50%) rotate(180deg)
}

.userNav__menu {
  box-shadow: var(--shadow);
  background: var(--color--ui-02);
  border-radius: 4px;
  display: none;
  min-width: 221px;
  padding: 14px 0 10px;
  position: absolute;
  right: -12px;
  top: 29px
}

@media(max-width:768px) {
  .userNav__menu {
    right: -14px;
    top: 29px
  }
}

.userNav__menu::before {
  background-color: var(--color--ui-02);
  content: '';
  height: 10px;
  position: absolute;
  top: -5px;
  transform: rotate(45deg);
  width: 10px
}

@media(min-width:769px) {
  .userNav__menu::before {
    right: 40px
  }
}

@media(max-width:768px) {
  .userNav__menu::before {
    right: 40px
  }
}

.userNav__button[aria-expanded='true']+.userNav__menu {
  display: block
}

.userNav__menu li {
  padding: 0;
  margin: 0
}

.userNav__menu a {
  color: var(--color--text-primary);
  display: block;
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  letter-spacing: -1px;
  line-height: 150%;
  padding: .3125em .6875em .3825em 1.15em;
  text-decoration: none;
  transition: background-color .2s ease-in-out
}

.userNav__menu a:hover,
.userNav__menu a:active,
.userNav__menu a:focus {
  background-color: var(--color--ui-01);
  color: var(--color--text-primary);
  font-weight: 700;
  text-decoration: none !important
}

.isVisible .nav__wrapper {
  background-color: orange;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all .325s ease-in-out
}

.isVisible .c-nav__search form .search-box-input {
  border: 0;
  box-shadow: inset 0 0 0 1px indigo
}

.isVisible .c-nav__search form .search-box-input::-webkit-input-placeholder,
.isVisible .c-nav__search form .search-box-input::placeholder {
  color: aquamarine
}

.isVisible .c-nav__search form .search-box-input:hover {
  background-color: rgba(254, 254, 254, 0.8);
  box-shadow: inset 0 0 0 1px var(--color--ui-border-01, var(--color--border-01))
}

.isVisible .c-nav__search form .search-box-input:hover::-webkit-input-placeholder,
.isVisible .c-nav__search form .search-box-input:hover::placeholder {
  color: forestgreen
}

.isVisible .c-nav__search form .search-box-input:focus {
  background-color: var(--color--interactive-primary);
  box-shadow: inset 0 0 0 1px var(--color--ui-border-01, var(--color--border-01))
}

.isVisible .c-nav__search form .search-box-input:focus::-webkit-input-placeholder,
.isVisible .c-nav__search form .search-box-input:focus::placeholder {
  color: chartreuse
}

.isVisible .c-nav__search form .search-box-icon::before {
  background: chocolate
}

.isVisible .c-nav__search form.isOpen .search-box-input:hover,
.isVisible .c-nav__search form.isOpen .search-box-input:focus {
  box-shadow: inset 0 0 0 1px transparent
}

.isSticky .nav__wrapper .c-nav__search {
  display: block !important
}

.isSticky .nav__wrapper .c-nav__secure-text p {
  color: violet
}

.isSticky .nav__wrapper .c-nav__secure-text::after {
  background-color: firebrick
}

.isSticky .nav__wrapper .c-nav__acount a {
  color: goldenrod
}

.isSticky .c-nav__search form .search-box-input {
  color: tan
}

.isSticky .c-nav__search form .search-box-icon,
.isSticky .c-nav__search form .search-box-icon::before {
  height: 16px;
  width: 16px
}

.isSticky .userNav.userSignedIn .userNav__button {
  color: rebeccapurple
}

.isSticky .userNav.userSignedIn .userNav__button:hover {
  background-color: rgba(155, 161, 173, 0.35)
}

.isSticky .userNav.userSignedIn .userNav__button:active,
.isSticky .userNav.userSignedIn .userNav__button[aria-expanded='true'] {
  background: crimson;
  color: steelblue
}

.isSticky .userNav.userSignedIn .userNav__button::after {
  background-color: blue
}

.nav__wrapper.c-navigation-light {
  background-color: var(--color--ui-01)
}

.nav__wrapper.c-navigation-light .search-box-icon::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-sm");
  color: var(--color--icon-primary)
}

.nav__wrapper.c-navigation-light .c-nav__search input:hover~.search-box-icon::before,
.nav__wrapper.c-navigation-light .c-nav__search input:active~.search-box-icon::before,
.nav__wrapper.c-navigation-light .c-nav__search input:focus~.search-box-icon::before {
  color: var(--color--interactive-primary)
}

.nav__wrapper.c-navigation-light .c-nav__search input {
  background-color: transparent;
  box-shadow: 0 0 0 1px var(--color--ui-border-02, var(--color--border-02)) inset, 0 0 1px 0 var(--color--ui-border-02, var(--color--border-02));
  color: var(--color--text-primary)
}

.nav__wrapper.c-navigation-light .c-nav__search input::-webkit-input-placeholder,
.nav__wrapper.c-navigation-light .c-nav__search input::placeholder {
  color: var(--color--text-secondary)
}

.nav__wrapper.c-navigation-light .c-nav__search input:active,
.nav__wrapper.c-navigation-light .c-nav__search input:focus {
  background-color: var(--color--ui-02);
  color: var(--color--text-primary)
}

.nav__wrapper.c-navigation-light .c-nav__search input:active::-webkit-input-placeholder,
.nav__wrapper.c-navigation-light .c-nav__search input:focus::-webkit-input-placeholder,
.nav__wrapper.c-navigation-light .c-nav__search input:active::placeholder,
.nav__wrapper.c-navigation-light .c-nav__search input:focus::placeholder {
  color: var(--color--text-tertiary)
}

.nav__wrapper.c-navigation-light .c-nav__search:hover button.search-box-icon::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-sm");
  color: var(--color--interactive-primary)
}

.nav__wrapper.c-navigation-light .c-nav__search:hover input {
  background-color: var(--color--ui-02);
  color: var(--color--text-primary)
}

.nav__wrapper.c-navigation-light .c-nav__search:hover input:active,
.nav__wrapper.c-navigation-light .c-nav__search:hover input:focus {
  background-color: var(--color--ui-02)
}

.nav__wrapper.c-navigation-light .c-nav__search:hover input::-webkit-input-placeholder,
.nav__wrapper.c-navigation-light .c-nav__search:hover input::placeholder {
  color: var(--color--text-primary)
}

.c-nav__secure-text p {
  color: var(--color--text-primary)
}

.c-nav__secure-text::after {
  background-color: var(--color--text-primary)
}

.c-navigation-light .userNav.userSignedIn .userNav__button {
  color: var(--color--text-primary)
}

.c-navigation-light .userNav.userSignedIn .userNav__button:hover,
.c-navigation-light .userNav.userSignedIn .userNav__button:active,
.c-navigation-light .userNav.userSignedIn .userNav__button:focus,
.c-navigation-light .userNav.userSignedIn .userNav__button[aria-expanded='true'] {
  color: var(--color--ui-02);
  background-color: var(--color--ui-03);
  border-color: var(--color--ui-03)
}

.c-navigation-light .userNav.userSignedIn .userNav__button::after {
  background: var(--color--ui-03)
}

.nav__wrapper.c-navigation-dark {
  background-color: var(--color--ui-03)
}

.nav__wrapper.c-navigation-dark .search-box-icon::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-sm");
  color: var(--color--icon-primary-inverse)
}

.nav__wrapper.c-navigation-dark .c-nav__search button.search-close-btn {
  color: var(--color--icon-primary-inverse)
}

.nav__wrapper.c-navigation-dark .c-nav__search input:hover~.search-close-btn,
.nav__wrapper.c-navigation-dark .c-nav__search input:active~.search-close-btn,
.nav__wrapper.c-navigation-dark .c-nav__search input:focus~.search-close-btn {
  color: var(--color--icon-primary)
}

.nav__wrapper.c-navigation-dark .c-nav__search input:hover~.search-box-icon::before,
.nav__wrapper.c-navigation-dark .c-nav__search input:active~.search-box-icon::before,
.nav__wrapper.c-navigation-dark .c-nav__search input:focus~.search-box-icon::before {
  color: var(--color--interactive-primary)
}

.nav__wrapper.c-navigation-dark .c-nav__search button.search-box-icon::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-sm");
  color: var(--color--icon-primary-inverse)
}

.nav__wrapper.c-navigation-dark .c-nav__search input {
  background-color: rgba(247, 249, 253, 0.35);
  box-shadow: 0 0 0 1px var(--color--ui-border-01, var(--color--border-01)) inset, 0 0 1px 0 var(--color--ui-border-01, var(--color--border-01));
  color: var(--color--text-secondary-inverse)
}

.nav__wrapper.c-navigation-dark .c-nav__search input::-webkit-input-placeholder,
.nav__wrapper.c-navigation-dark .c-nav__search input::placeholder {
  color: var(--color--text-secondary-inverse)
}

.nav__wrapper.c-navigation-dark .c-nav__search input:active,
.nav__wrapper.c-navigation-dark .c-nav__search input:focus {
  background-color: var(--color--ui-02);
  color: var(--color--text-primary)
}

.nav__wrapper.c-navigation-dark .c-nav__search input:active::-webkit-input-placeholder,
.nav__wrapper.c-navigation-dark .c-nav__search input:focus::-webkit-input-placeholder,
.nav__wrapper.c-navigation-dark .c-nav__search input:active::placeholder,
.nav__wrapper.c-navigation-dark .c-nav__search input:focus::placeholder {
  color: var(--color--text-tertiary)
}

.nav__wrapper.c-navigation-dark .c-nav__search:hover button.search-close-btn {
  color: var(--color--icon-primary)
}

.nav__wrapper.c-navigation-dark .c-nav__search:hover button.search-box-icon::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-sm");
  color: var(--color--interactive-primary)
}

.nav__wrapper.c-navigation-dark .c-nav__search:hover input {
  background-color: rgba(254, 254, 254, 0.8);
  color: var(--color--text-primary)
}

.nav__wrapper.c-navigation-dark .c-nav__search:hover input:active,
.nav__wrapper.c-navigation-dark .c-nav__search:hover input:focus {
  background-color: var(--color--ui-02)
}

.nav__wrapper.c-navigation-dark .c-nav__search:hover input::-webkit-input-placeholder,
.nav__wrapper.c-navigation-dark .c-nav__search:hover input::placeholder {
  color: var(--color--text-primary)
}

.c-nav__secure-text p {
  color: var(--color--text-primary-inverse)
}

.c-nav__secure-text::after {
  background-color: var(--color--text-primary-inverse)
}

.c-navigation-dark .userNav.userSignedIn .userNav__button {
  color: var(--color--text-primary-inverse)
}

.c-navigation-dark .userNav.userSignedIn .userNav__button:hover,
.c-navigation-dark .userNav.userSignedIn .userNav__button:focus,
.c-navigation-dark .userNav.userSignedIn .userNav__button:active,
.c-navigation-dark .userNav.userSignedIn .userNav__button[aria-expanded='true'] {
  color: var(--color--ui-03);
  background-color: var(--color--ui-02);
  border-color: var(--color--ui-02)
}

.c-navigation-dark .userNav.userSignedIn .userNav__button::after {
  background: var(--color--ui-02)
}

.youtube-video.youtube-video {
  aspect-ratio: 1.77777778;
  width: unset;
  height: unset;
  padding-bottom: unset;
  max-width: 100%;
  margin-bottom: 0
}

*+.youtube-video.youtube-video {
  margin-top: var(--space-small)
}

.ui-widget-overlay.ui-widget-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 1
}

.youtube-video-container.youtube-video-container {
  aspect-ratio: 1.77777778;
  width: unset;
  height: unset;
  padding-bottom: unset;
  max-width: 100%
}

.youtube-video-modal-container.youtube-video-modal-container {
  max-width: 1390px;
  padding: 0;
  background: 0
}

.youtube-video-modal-container.youtube-video-modal-container .youtube-video-container {
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: outline .125s ease-in-out
}

.youtube-video-modal-container.youtube-video-modal-container .youtube-video-container[tabindex]:focus-visible {
  outline-color: var(--color--interactive-focus)
}

// .youtube-video-modal-container.youtube-video-modal-container .youtube-video-container .youtube-video-iframe{width:100%;height:100%;min-height:inherit;position:absolute;display:block;top:0;left:0;background-color:var(--color--ui-background-dark,var(--color--background-dark));background-image:url("../../../themes/platform/vanillaCake/assets/icons/non-sprites/loading-indicator--bubble-color-ui-06.svg");background-repeat:no-repeat;background-size:clamp(64px,17vw,128px);background-position:center center;border:0}
@media screen and (min-width:768px) {
  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-container .youtube-video-iframe {
    width: 100%;
    height: 100%
  }
}

@media screen and (min-width:992px) {
  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-container .youtube-video-iframe {
    width: 100%;
    height: 100%
  }
}

.youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: unset;
  background-image: unset;
  background-repeat: unset;
  background-size: unset;
  margin-top: 0;
  margin-right: 0;
  transition: none;
  height: 36px;
  width: 36px;
  top: 8px;
  right: 8px
}

.youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:hover,
.youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:focus {
  outline: 0;
  background-color: transparent
}

.youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon::before {
  display: block;
  content: "";
  flex: 0 0 auto;
  border-radius: 50%;
  width: inherit;
  height: inherit;
  background-color: var(--color--ui-01);
  position: absolute;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  transform: scale(0);
  transition: transform .125s ease-in-out;
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent
}

.youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg");
  transform: none;
  top: unset;
  left: unset;
  border-radius: 0
}

.youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:hover::before,
.youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:focus::before,
.youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:focus-visible::before,
.youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:active::before {
  transform: scale(1)
}

.youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:focus,
.youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:focus-visible {
  outline: 0
}

.youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:focus::before,
.youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:focus-visible::before {
  outline-color: var(--color--interactive-focus-field)
}

@media(forced-colors:active) and (prefers-color-scheme:dark) {

  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:focus,
  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:focus-visible {
    outline-width: 2px;
    outline-style: solid;
    outline-offset: 1px
  }

  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon::after {
    filter: brightness(0) invert(1)
  }

  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:hover::after,
  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:focus::after,
  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:focus-visible::after,
  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:active::after {
    filter: unset
  }

  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon::before {
    outline-color: currentColor;
    outline-offset: -2px;
    transform: unset;
    opacity: unset;
    background-color: unset
  }

  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:hover::before,
  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:focus::before,
  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:focus-visible::before,
  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:active::before {
    background-color: currentColor;
    filter: brightness(0) invert(1)
  }
}

@media(forced-colors:active) and (prefers-color-scheme:light) {
  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon {
    border-radius: 50%;
    outline-width: 2px;
    outline-style: solid;
    outline-color: currentColor
  }

  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:hover,
  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:focus,
  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:focus-visible,
  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:active {
    outline-width: 2px;
    outline-style: solid;
    outline-color: currentColor
  }

  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon::after {
    filter: brightness(1) invert(1)
  }

  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:hover::after,
  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:focus::after,
  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:focus-visible::after,
  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:active::after {
    filter: unset
  }

  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:hover::before,
  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:focus::before,
  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:focus-visible::before,
  .youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon:active::before {
    filter: brightness(1) invert(1);
    outline: 0
  }
}

.youtube-video-modal-container.youtube-video-modal-container .youtube-video-cross-icon::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg")
}

.youtube-video-modal {
  border-radius: var(--corners-semiround-md);
  background-color: var(--color--ui-background-light, var(--color--background-light))
}

@media(min-width:481px) {
  .youtube-video-modal {
    padding: var(--space-large) !important
  }
}

@media(max-width:480px) {
  .youtube-video-modal {
    padding: var(--space-large) var(--space-medium) var(--space-medium) var(--space-medium) !important
  }
}

.youtube-video-modal .youtube-video-container {
  margin-top: 0
}

.youtube-video-modal .youtube-video-cross-icon [class*="fa"] {
  display: none
}

@media(forced-colors:active) and (prefers-color-scheme:dark) {
  .youtube-video-modal {
    outline: 2px solid currentColor;
    outline-offset: -2px
  }
}

@media(forced-colors:active) and (prefers-color-scheme:light) {
  .youtube-video-modal {
    outline: 2px solid currentColor;
    outline-offset: -2px
  }
}

.youtube-video-iframe.visible-xs-block {
  display: none
}

.youtube-video-wrapper {
  padding: 0;
  -webkit-appearance: none
}

.youtube-video-play-img {
  display: inline-flex;
  align-self: center;
  width: clamp(48px, 16%, 68px);
  height: auto;
  aspect-ratio: 1.41666667;
  background-size: contain;
  background-repeat: no-repeat;
  top: auto;
  left: auto;
  transition: all .2s ease-in-out;
  margin: auto
}

button[data-open="youtube-video"] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: outline .125s ease-in-out;
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent
}

button[data-open="youtube-video"]:hover .youtube-video-play-img,
button[data-open="youtube-video"]:focus .youtube-video-play-img,
button[data-open="youtube-video"]:focus-visible .youtube-video-play-img,
button[data-open="youtube-video"]:active .youtube-video-play-img {
  opacity: 1;
  filter: none
}

button[data-open="youtube-video"]:focus-visible {
  outline-color: var(--color--interactive-focus)
}

.youtube-video-img {
  object-fit: cover;
  object-position: center center
}

.youtube-video {
  border-top-left-radius: var(--corners-semiround-sm);
  border-top-right-radius: var(--corners-semiround-sm);
  outline-color: var(--color--ui-border-03, var(--color--border-03))
}

.youtube-video .yt-overlay .overlay-header {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-p);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default);
  font-size: clamp(1rem, calc(0.825rem + 0.5cqw), 1.125rem);
  font-weight: var(--typeweight--bold);
  margin: unset
}

.youtube-video .yt-overlay .overlay-text {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-p);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default);
  font-size: clamp(0.6875rem, calc(0.6875rem + 0.5cqw), 0.875rem);
  margin: unset
}

.youtube-video .yt-overlay .overlayButton.overlayButton {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-minor);
  padding: .25em 1.25em;
  min-height: 2rem
}

@media(min-width:376px) {
  .youtube-video .yt-overlay .overlayButton.overlayButton {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .youtube-video .yt-overlay .overlayButton.overlayButton {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.youtube-video .yt-overlay .overlayButton.overlayButton::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.youtube-video .yt-overlay .overlayButton.overlayButton:hover,
.youtube-video .yt-overlay .overlayButton.overlayButton:focus,
.youtube-video .yt-overlay .overlayButton.overlayButton:focus-visible,
.youtube-video .yt-overlay .overlayButton.overlayButton:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.youtube-video .yt-overlay .overlayButton.overlayButton:hover::before,
.youtube-video .yt-overlay .overlayButton.overlayButton:focus::before,
.youtube-video .yt-overlay .overlayButton.overlayButton:focus-visible::before,
.youtube-video .yt-overlay .overlayButton.overlayButton:active::before {
  transform: scale(1.05)
}

.youtube-video .yt-overlay .overlayButton.overlayButton:focus,
.youtube-video .yt-overlay .overlayButton.overlayButton:focus-visible {
  outline: 0
}

.youtube-video .yt-overlay .overlayButton.overlayButton:focus::before,
.youtube-video .yt-overlay .overlayButton.overlayButton:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.youtube-video .yt-overlay .overlayButton.overlayButton:active::before {
  outline-color: transparent
}

.youtube-video .yt-overlay .overlayButton.overlayButton:only-of-type {
  margin: 0
}

.youtube-video .yt-overlay .overlayButton.overlayButton.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.youtube-video .yt-overlay .overlayButton.overlayButton::before {
  background: var(--color--interactive-primary) border-box
}

.youtube-video .yt-overlay .overlayButton.overlayButton:hover::before {
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-primary-hover) border-box
}

.youtube-video .yt-overlay .overlayButton.overlayButton:focus,
.youtube-video .yt-overlay .overlayButton.overlayButton:focus-visible {
  color: var(--color--text-primary-inverse)
}

.youtube-video .yt-overlay .overlayButton.overlayButton:focus::before,
.youtube-video .yt-overlay .overlayButton.overlayButton:focus-visible::before {
  background: var(--color--interactive-primary) border-box
}

.youtube-video .yt-overlay .overlayButton.overlayButton:active {
  color: var(--color--text-primary-inverse)
}

.youtube-video .yt-overlay .overlayButton.overlayButton:active::before {
  background: var(--color--interactive-primary-active) border-box
}

.youtube-video .yt-overlay .overlayButton.overlayButton[disabled],
.youtube-video .yt-overlay .overlayButton.overlayButton.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.youtube-video .yt-overlay .overlayButton.overlayButton[disabled]::before,
.youtube-video .yt-overlay .overlayButton.overlayButton.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.youtube-video .yt-overlay .overlayButton.overlayButton[disabled]:hover::before,
.youtube-video .yt-overlay .overlayButton.overlayButton.button-disabled:hover::before,
.youtube-video .yt-overlay .overlayButton.overlayButton[disabled]:focus::before,
.youtube-video .yt-overlay .overlayButton.overlayButton.button-disabled:focus::before,
.youtube-video .yt-overlay .overlayButton.overlayButton[disabled]:focus-visible::before,
.youtube-video .yt-overlay .overlayButton.overlayButton.button-disabled:focus-visible::before,
.youtube-video .yt-overlay .overlayButton.overlayButton[disabled]:active::before,
.youtube-video .yt-overlay .overlayButton.overlayButton.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.youtube-video .yt-overlay .overlayButton.overlayButton.icon-only {
  aspect-ratio: 1;
  padding: .5em;
  max-width: 2rem
}

.isSticky [class*="global-message--alert-banner"] {
  display: none
}

[class*="global-message--alert-banner"] {
  --gm-bgcolor: var(--color--ui-background-med, var(--color--background-medium));
  --gm-text-color: var(--color--text-primary);
  --gm-link-color: var(--color--text-link);
  --gm-icon-alert-color: var(--color--alert-error);
  --gm-icon-warning-color: var(--color--alert-warning);
  --gm-icon-success-color: var(--color--alert-success);
  --gm-icon-info-color: var(--color--alert-information);
  --gm-dismiss-bgcolor: transparent;
  --gm-dismiss-bgcolor-hover: var(--color--ui-background-light, var(--color--background-light));
  --gm-dismiss-bgcolor-focus: var(--color--ui-background-light, var(--color--background-light));
  --gm-dismiss-icon-color: var(--color--ui-background-dark, var(--color--background-dark));
  --gm-dismiss-icon-color-hover: currentColor
}

[class*="global-message--alert-banner"][class*="-alt-white"] {
  --gm-bgcolor: var(--color--ui-background-light, var(--color--background-light));
  --gm-text-color: var(--color--text-secondary);
  --gm-dismiss-bgcolor-hover: var(--color--ui-01);
  --gm-dismiss-bgcolor-focus: var(--color--ui-01)
}

[class*="global-message--alert-banner"][class*="-alt-dark"] {
  --gm-bgcolor: var(--color--ui-03);
  --gm-text-color: var(--color--text-primary-inverse);
  --gm-link-color: var(--color--text-link-inverse);
  --gm-dismiss-icon-color: var(--color--ui-background-light, var(--color--background-light));
  --gm-dismiss-icon-color-hover: var(--color--ui-background-dark, var(--color--background-dark));
  --gm-icon-alert-color: var(--color--alert-error-inverse);
  --gm-icon-warning-color: var(--color--alert-warning-inverse);
  --gm-icon-success-color: var(--color--alert-success-inverse);
  --gm-icon-info-color: var(--color--alert-information-inverse)
}

[class*="global-message--alert-banner"][class*="-alt-grey-light"] {
  --gm-bgcolor: var(--color--ui-01);
  --gm-text-color: var(--color--text-secondary)
}

[class*="global-message--alert-banner"][class*="-alt-grey-dark"] {
  --gm-bgcolor: var(--color--ui-04);
  --gm-text-color: var(--color--text-primary-inverse);
  --gm-link-color: var(--color--text-link-inverse);
  --gm-dismiss-icon-color: var(--color--ui-background-light, var(--color--background-light));
  --gm-dismiss-icon-color-hover: var(--color--ui-background-dark, var(--color--background-dark));
  --gm-icon-alert-color: var(--color--alert-error-inverse);
  --gm-icon-warning-color: var(--color--alert-warning-inverse);
  --gm-icon-success-color: var(--color--alert-success-inverse);
  --gm-icon-info-color: var(--color--alert-information-inverse)
}

[class*="global-message--alert-banner"][class*="-alt-accent"] {
  --gm-bgcolor: var(--color--accent-01-light);
  --gm-text-color: var(--color--text-secondary)
}

[class*="global-message--alert-banner"] [class*="alert--banner"] {
  background-color: var(--gm-bgcolor)
}

[class*="global-message--alert-banner"] [class*="alert--banner"] p {
  font-size: var(--typesize-default);
  line-height: var(--leading-1);
  color: var(--gm-text-color)
}

[class*="global-message--alert-banner"] [class*="alert--banner"] a {
  color: var(--gm-link-color)
}

[class*="global-message--alert-banner"] [class*="alert--banner"] a:link,
[class*="global-message--alert-banner"] [class*="alert--banner"] a:hover,
[class*="global-message--alert-banner"] [class*="alert--banner"] a:active,
[class*="global-message--alert-banner"] [class*="alert--banner"] a:focus {
  color: var(--gm-link-color)
}

[class*="global-message--alert-banner"] [class*="alert--banner"] .pmc-alert__inner {
  padding: var(--space-xsmall) calc(5 * var(--space-unit));
  align-items: center;
  max-width: 100%
}

[class*="global-message--alert-banner"] [class*="alert--banner"] .pmc-alert__icon {
  margin-top: 0
}

[class*="global-message--alert-banner"] [class*="alert--banner"] .pmc-alert__dismiss {
  margin: 0
}

@media(min-width:768px) {
  [class*="global-message--alert-banner"] [class*="alert--banner"] .pmc-alert__inner {
    padding-right: calc(11 * var(--space-unit));
    padding-left: calc(11 * var(--space-unit))
  }
}

@media(min-width:1590px) {
  [class*="global-message--alert-banner"] [class*="alert--banner"] .pmc-alert__inner {
    padding-right: calc(15 * var(--space-unit));
    padding-left: calc(15 * var(--space-unit));
    max-width: 1590px
  }
}

[class*="global-message--alert-banner"] [class*="alert__dismiss"],
[class*="global-message--alert-banner"] [class*="alert__icon"] {
  background-image: none
}

[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: unset;
  background-image: unset;
  background-repeat: unset;
  background-size: unset;
  margin-top: 0;
  margin-right: 0;
  transition: none;
  height: 24px;
  width: 24px;
  background-color: transparent;
  color: var(--gm-dismiss-icon-color)
}

[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:hover,
[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus {
  outline: 0;
  background-color: transparent
}

[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]::before {
  display: block;
  content: "";
  flex: 0 0 auto;
  border-radius: 50%;
  width: inherit;
  height: inherit;
  background-color: var(--color--ui-01);
  position: absolute;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  transform: scale(0);
  transition: transform .125s ease-in-out;
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent
}

[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg");
  transform: none;
  top: unset;
  left: unset;
  border-radius: 0
}

[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:hover::before,
[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus::before,
[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible::before,
[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:active::before {
  transform: scale(1)
}

[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus,
[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible {
  outline: 0
}

[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus::before,
[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible::before {
  outline-color: var(--color--interactive-focus-field)
}

@media(forced-colors:active) and (prefers-color-scheme:dark) {

  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus,
  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible {
    outline-width: 2px;
    outline-style: solid;
    outline-offset: 1px
  }

  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]::after {
    filter: brightness(0) invert(1)
  }

  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:hover::after,
  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus::after,
  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible::after,
  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:active::after {
    filter: unset
  }

  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]::before {
    outline-color: currentColor;
    outline-offset: -2px;
    transform: unset;
    opacity: unset;
    background-color: unset
  }

  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:hover::before,
  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus::before,
  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible::before,
  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:active::before {
    background-color: currentColor;
    filter: brightness(0) invert(1)
  }
}

@media(forced-colors:active) and (prefers-color-scheme:light) {
  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"] {
    border-radius: 50%;
    outline-width: 2px;
    outline-style: solid;
    outline-color: currentColor
  }

  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:hover,
  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus,
  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible,
  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:active {
    outline-width: 2px;
    outline-style: solid;
    outline-color: currentColor
  }

  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]::after {
    filter: brightness(1) invert(1)
  }

  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:hover::after,
  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus::after,
  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible::after,
  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:active::after {
    filter: unset
  }

  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:hover::before,
  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus::before,
  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible::before,
  [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:active::before {
    filter: brightness(1) invert(1);
    outline: 0
  }
}

[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md")
}

[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:hover,
[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus,
[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible,
[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:active {
  color: var(--gm-dismiss-icon-color-hover)
}

[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]::before {
  background-color: var(--gm-dismiss-bgcolor)
}

[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:hover::before {
  background-color: var(--gm-dismiss-bgcolor-hover)
}

[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus::before,
[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible::before,
[class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:active::before {
  background-color: var(--gm-dismiss-bgcolor-focus)
}

[class*="global-message--alert-banner"] [class*="alert__icon"][class*="--alert"] {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-alert-banner-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-information-banner-sm");
  background-color: var(--gm-icon-alert-color)
}

[class*="global-message--alert-banner"] [class*="alert__icon"][class*="--error"] {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-warning-banner-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-warning-banner-sm");
  background-color: var(--gm-icon-warning-color)
}

[class*="global-message--alert-banner"] [class*="alert__icon"][class*="--success"] {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-success-banner-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-success-banner-sm");
  background-color: var(--gm-icon-success-color)
}

[class*="global-message--alert-banner"] [class*="alert__icon"][class*="--info"] {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-information-banner-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-information-banner-sm");
  background-color: var(--gm-icon-info-color)
}

@media(forced-colors:active) and (prefers-color-scheme:dark) {
  [class*="global-message--alert-banner"] [class*="alert__icon"] {
    filter: brightness(0) invert(1)
  }
}

@media(forced-colors:active) and (prefers-color-scheme:light) {
  [class*="global-message--alert-banner"] [class*="alert__icon"] {
    filter: brightness(1) invert(1)
  }
}

.table-responsive table,
.table-responsive thead,
.table-responsive tbody,
.table-responsive tfoot,
.table-responsive tr,
.table-responsive th,
.table-responsive td {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  color: inherit;
  text-align: left;
  border: 0;
  border-spacing: 0;
  vertical-align: baseline;
  padding: var(--space-zero);
  margin: 0
}

.table-responsive table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse
}

.table-responsive table tr:nth-child(even) {
  background-color: var(--color--ui-background-light, var(--color--background-light))
}

.table-responsive table tr:nth-child(odd) {
  background-color: var(--color--ui-01)
}

.table-responsive table th,
.table-responsive table td {
  font-weight: var(--typeweight--default);
  color: var(--color--text-secondary);
  border-top: 0;
  vertical-align: top
}

@media(min-width:769px) {

  .table-responsive table th,
  .table-responsive table td {
    padding: var(--space-small)
  }
}

@media(max-width:768px) {

  .table-responsive table th,
  .table-responsive table td {
    padding: var(--space-xsmall)
  }
}

.table-responsive table th {
  font-size: var(--typesize-h5);
  line-height: var(--leading--1-1);
  background-color: var(--color--ui-background-med, var(--color--background-medium));
  border-bottom: 1px solid var(--color--ui-border-02, var(--color--border-02))
}

.table-responsive table td {
  font-size: var(--typesize-default);
  line-height: var(--leading--default)
}

.table-responsive table td p:only-of-type {
  margin: var(--space-zero)
}

*+.table-responsive {
  margin-top: var(--space-small)
}

.table-responsive--inverse table th {
  color: var(--color--text-secondary-inverse);
  background-color: var(--color--ui-background-dark, var(--color--background-dark))
}

.c-eventListing__content {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-xsmall);
  padding: var(--space-medium);
  background: var(--color--ui-background-light, var(--color--background-light))
}

.c-eventListing__content+.c-eventListing__content {
  margin-top: var(--space-medium)
}

.c-eventListing__image,
.c-eventListing__presenterMobile,
.c-eventListing-recorded-presenter,
.c-eventListing-recorded-presenterMobile {
  display: none
}

.c-eventListing__labels {
  --labelListTypeSize: var(--typesize-micro);
  --labelListGapSize: .8em;
  list-style: none;
  font-size: var(--labelListTypeSize);
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--labelListGapSize);
  row-gap: var(--labelListGapSize);
  padding: unset;
  margin: unset
}

.c-eventListing__labels-item {
  --corners-semiround-sm: var(--space-unit);
  --space-unit: .4em;
  --space-xxxsmall: calc(var(--space-unit) - 1px);
  --space-xxsmall: calc(2 * var(--space-unit));
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-micro);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-tertiary);
  background-color: var(--color--ui-background-med, var(--color--background-medium));
  cursor: default;
  border-radius: var(--corners-semiround-sm);
  border-width: 1px;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: .1em;
  border-color: var(--color--ui-border-01, var(--color--border-01));
  min-height: max-content;
  padding: var(--space-xxxsmall) var(--space-xxsmall);
  margin: unset
}

.c-eventListing__title a {
  color: var(--color--text-secondary)
}

.c-eventListing__title a:hover {
  color: var(--color--text-secondary)
}

.c-eventListing__duration {
  font-synthesis: style;
  font-style: italic
}

.c-eventListing__button::after {
  vertical-align: middle;
  margin-top: -0.25em;
  margin-left: .275em;
  transform: none;
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  transform: rotate(180deg)
}

.c-eventListing__informations {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap
}

.c-eventListing__informations:only-child {
  flex-flow: column wrap;
  gap: var(--space-xxsmall)
}

.c-eventListing__informations-list {
  display: flex;
  flex-flow: column wrap;
  gap: var(--space-xsmall);
  padding-top: var(--space-xsmall)
}

.c-eventListing__informations-list:not(:last-child) {
  padding-bottom: var(--space-xsmall)
}

.c-eventListing__informations-list:empty {
  display: none
}

@media(max-width:1389px) {
  .c-eventListing__informations {
    flex-flow: column wrap;
    row-gap: var(--space-xxsmall)
  }

  .c-eventListing__informations-list {
    row-gap: var(--space-medium)
  }
}

.c-eventListing__format-event {
  display: flex;
  flex-flow: column;
  gap: var(--space-xxsmall);
  flex: 1
}

@media(min-width:1390px) {
  .c-eventListing__format-event {
    gap: var(--spacing-5)
  }
}

.c-eventListing__date,
.c-eventListing__address,
.c-eventListing__time,
.c-eventListing__in-person,
.c-eventListing__live-online {
  display: flex;
  flex: 1
}

@media(min-width:1390px) {

  .c-eventListing__date,
  .c-eventListing__address,
  .c-eventListing__time,
  .c-eventListing__in-person,
  .c-eventListing__live-online {
    padding-right: var(--space-medium)
  }
}

.c-eventListing__date::before,
.c-eventListing__address::before,
.c-eventListing__time::before,
.c-eventListing__in-person::before,
.c-eventListing__live-online::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-date-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-date-md");
  margin: 2px var(--space-xsmall) 0 0
}

.c-eventListing__time::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-time-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-time-md")
}

.c-eventListing__address {
  padding-left: var(--spacing-30);
  position: relative;
  display: block
}

.c-eventListing__address::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-location-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-location-md")
}

.c-eventListing__live-online::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-broadcast-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-broadcast-md")
}

.c-eventListing__in-person::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-group-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-group-md")
}

.c-eventListing__informations--recorded .c-eventListing__informations {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  position: relative;
  padding-top: var(--space-small)
}

.c-eventListing__informations--recorded .c-eventListing__informations::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
  height: 1px;
  background: var(--color--ui-background-med-gray, var(--color--background-medium-gray))
}

@media(max-width:1389px) {
  .c-eventListing__informations--recorded .c-eventListing__informations {
    flex-flow: column wrap;
    gap: var(--space-xxsmall)
  }
}

.c-eventListing__informations--recorded p {
  margin: 0
}

.c-eventListing__navigation {
  display: flex;
  align-items: center;
  justify-content: space-between
}

@media(max-width:375px) {
  *+.c-eventListing__navigation {
    margin-top: var(--space-small)
  }
}

@media(min-width:376px) and (max-width:768px) {
  *+.c-eventListing__navigation {
    margin-top: var(--space-medium)
  }
}

@media(min-width:769px) {
  *+.c-eventListing__navigation {
    margin-top: var(--space-large)
  }
}

.c-eventListing__navigation-next {
  margin-left: auto
}

.c-eventListing__navigation-next::after {
  vertical-align: middle;
  margin-top: -0.25em;
  margin-left: .275em;
  transform: none;
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  transform: rotate(180deg)
}

.c-eventListing__navigation-prev {
  margin-right: auto
}

.c-eventListing__navigation-prev::before {
  vertical-align: middle;
  margin-top: -0.25em;
  margin-right: .5em;
  transform: none;
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm")
}

.c-eventList--grid .c-eventListing__content {
  overflow: hidden;
  display: flex;
  flex-direction: column
}

.c-eventList--grid .c-eventListing__image {
  --use-negative-margin: calc(var(--space-medium) * -1);
  display: block;
  overflow: hidden;
  margin-top: var(--use-negative-margin);
  margin-right: var(--use-negative-margin);
  margin-left: var(--use-negative-margin)
}

.c-eventList--grid .c-eventListing__image img {
  width: 100%;
  transition: transform .3s;
  will-change: transform;
  aspect-ratio: 1.57;
  object-fit: cover
}

.c-eventList--grid .c-eventListing__image+* {
  margin-top: var(--space-xsmall)
}

.c-eventList--grid .c-eventListing__desc {
  flex-grow: 1
}

.c-eventList--grid .c-eventListing__desc p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  overflow: hidden;
  padding-bottom: .125em;
  text-overflow: ellipsis
}

.c-eventList--grid .c-eventListing__desc p:not(:first-child) {
  display: none
}

.c-eventList--grid .c-eventListing__informations-list {
  margin: 0
}

.c-eventList--grid .c-eventListing__informations-list:empty {
  display: none
}

.c-eventList--grid .c-eventListing__informations {
  margin: 0;
  padding: 0
}

.c-eventList--grid .c-eventListing__informations:not(:first-child),
.c-eventList--grid .c-eventListing__informations::before {
  display: none
}

.c-eventList--grid .c-eventListing__informations--recorded {
  padding: 0;
  margin: 0
}

.c-eventList--grid .c-eventListing__date {
  padding: 0
}

.c-eventList--grid .c-eventListing__date::before {
  display: none
}

.c-eventList--grid .c-eventListing__in-person {
  padding: 0
}

.c-eventList--grid .c-eventListing__in-person::before {
  display: none
}

.c-eventList--grid .c-eventListing__button,
.c-eventList--grid .c-eventListing__duration,
.c-eventList--grid .c-eventListing__address,
.c-eventList--grid .c-eventListing__time,
.c-eventList--grid .c-eventListing__live-online,
.c-eventList--grid .c-eventListing__presenter,
.c-eventList--grid .c-eventListing__date.endDate {
  display: none
}

[class*="c-eventList--grid-"]:not(.c-eventList--grid-1) .c-eventListing__contentAll {
  --gap: var(--space-medium);
  --cols: 3;
  --cardWidth: 300px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(var(--cardWidth), (100% - 1*(var(--cols) - 1)*var(--gap))/var(--cols)), 1fr));
  gap: var(--gap)
}

[class*="c-eventList--grid-"]:not(.c-eventList--grid-1) .c-eventListing__content+.c-eventListing__content {
  margin-top: unset
}

[class*="c-eventList--grid-"]:not(.c-eventList--grid-1) .c-eventListing__informations {
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: unset;
  row-gap: var(--space-xxsmall)
}

[class*="c-eventList--grid-"]:not(.c-eventList--grid-1) .c-eventListing__informations>* {
  padding-right: unset
}

[class*="c-eventList--grid-"]:not(.c-eventList--grid-1) .c-eventListing__informations>*>p {
  line-height: var(--leading--1-3)
}

[class*="c-eventList--grid-"]:not(.c-eventList--grid-1) .c-eventListing__informations-list {
  row-gap: calc(8 * var(--space-unit))
}

[class*="c-eventList--grid-2"][class*="c-eventList--grid-2"] .c-eventListing__contentAll {
  --cols: 2
}

[class*="c-eventList--grid-3"][class*="c-eventList--grid-3"] .c-eventListing__contentAll {
  --cols: 3
}

[class*="c-eventList--grid-4"][class*="c-eventList--grid-4"] .c-eventListing__contentAll {
  --cols: 4;
  --cardWidth: 260px
}

.c-eventList--showImages .c-eventListing__image {
  --use-negative-margin: calc(var(--space-medium) * -1);
  display: block;
  overflow: hidden;
  margin-top: var(--use-negative-margin);
  margin-right: var(--use-negative-margin);
  margin-left: var(--use-negative-margin)
}

.c-eventList--showImages .c-eventListing__image img {
  width: 100%;
  transition: transform .3s;
  will-change: transform;
  aspect-ratio: 1.57;
  object-fit: cover
}

.c-eventList--showImages .c-eventListing__image+* {
  margin-top: var(--space-xsmall)
}

.c-post__utilities-container {
  display: flex;
  align-items: center;
  column-gap: .5em
}

.c-post__utilities-container+* {
  margin: unset
}

.c-viewAllTags__link-container {
  display: inline-flex;
  align-items: center
}

.c-viewAllTags__link-container::before {
  display: inline-block;
  content: "";
  background-color: var(--color--text-tertiary);
  width: 1px;
  height: 1em;
  margin-right: .5em
}

.c-eventDetails__content {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-small)
}

.c-eventDetails__content .event-address-legacy {
  display: none
}

.c-eventDetails__summary,
.c-eventDetails__upcomingPresenter,
.c-eventDetails__upcomingPresenterMobile,
.c-eventDetails-recorded-presenter,
.c-eventDetails__tags {
  display: none
}

.c-eventDetails__title {
  font-size: var(--typesize-h1-catalog);
  font-weight: var(--typeweight--light)
}

.c-eventDetails__presenter {
  margin: unset
}

.c-eventDetails__address,
.c-eventDetails__date,
.c-eventDetails__live-online,
.c-eventDetails__in-person,
.c-eventDetails__time {
  display: flex;
  flex: 1;
  margin-right: var(--space-xsmall)
}

.c-eventDetails__address::before,
.c-eventDetails__date::before,
.c-eventDetails__live-online::before,
.c-eventDetails__in-person::before,
.c-eventDetails__time::before {
  margin: 2px var(--space-xsmall) 0 0;
  display: flex;
  justify-content: center;
  align-items: center
}

.c-eventDetails__format-event {
  display: flex;
  flex-flow: column;
  gap: var(--space-xxsmall);
  flex: 1
}

@media(min-width:1390px) {
  .c-eventDetails__format-event {
    gap: var(--spacing-5)
  }
}

.c-eventDetails__upcomingInformations-single {
  margin-block-start: var(--space-xxsmall)
}

.c-eventDetails__register {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  margin-block-start: var(--space-small)
}

@media(min-width:376px) {
  .c-eventDetails__register {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .c-eventDetails__register {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.c-eventDetails__register::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.c-eventDetails__register:hover,
.c-eventDetails__register:focus,
.c-eventDetails__register:focus-visible,
.c-eventDetails__register:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.c-eventDetails__register:hover::before,
.c-eventDetails__register:focus::before,
.c-eventDetails__register:focus-visible::before,
.c-eventDetails__register:active::before {
  transform: scale(1.05)
}

.c-eventDetails__register:focus,
.c-eventDetails__register:focus-visible {
  outline: 0
}

.c-eventDetails__register:focus::before,
.c-eventDetails__register:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.c-eventDetails__register:active::before {
  outline-color: transparent
}

.c-eventDetails__register:only-of-type {
  margin: 0
}

.c-eventDetails__register.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.c-eventDetails__register::before {
  background: var(--color--interactive-primary) border-box
}

.c-eventDetails__register:hover::before {
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-primary-hover) border-box
}

.c-eventDetails__register:focus,
.c-eventDetails__register:focus-visible {
  color: var(--color--text-primary-inverse)
}

.c-eventDetails__register:focus::before,
.c-eventDetails__register:focus-visible::before {
  background: var(--color--interactive-primary) border-box
}

.c-eventDetails__register:active {
  color: var(--color--text-primary-inverse)
}

.c-eventDetails__register:active::before {
  background: var(--color--interactive-primary-active) border-box
}

.c-eventDetails__register[disabled],
.c-eventDetails__register.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.c-eventDetails__register[disabled]::before,
.c-eventDetails__register.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.c-eventDetails__register[disabled]:hover::before,
.c-eventDetails__register.button-disabled:hover::before,
.c-eventDetails__register[disabled]:focus::before,
.c-eventDetails__register.button-disabled:focus::before,
.c-eventDetails__register[disabled]:focus-visible::before,
.c-eventDetails__register.button-disabled:focus-visible::before,
.c-eventDetails__register[disabled]:active::before,
.c-eventDetails__register.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.c-eventDetails__register[target="_blank"]::after {
  vertical-align: middle;
  margin-top: -0.25em;
  margin-left: .275em;
  transform: none;
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-linkout-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-linkout-sm");
  margin-top: unset;
  margin-left: .5em
}

.c-eventDetails__date::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-date-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-date-md")
}

.c-eventDetails__time::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-time-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-time-md")
}

.c-eventDetails__address {
  padding-left: var(--space-medium);
  position: relative;
  display: block
}

.c-eventDetails__address::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-location-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-location-md");
  position: absolute;
  top: 0;
  left: 0
}

.c-eventDetails__live-online::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-broadcast-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-broadcast-md")
}

.c-eventDetails__in-person::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-group-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-group-md")
}

.c-eventDetails__duration {
  font-synthesis: style;
  font-style: italic
}

.c-eventDetails__upcoming:only-child .c-eventDetails__upcomingInformations {
  display: flex;
  flex-flow: column wrap;
  gap: var(--space-xxsmall);
  align-items: initial
}

.c-eventDetails__upcoming:first-child {
  border-top-width: 4px;
  border-top-style: solid;
  border-top-color: var(--color--ui-01);
  padding-top: var(--space-small);
  margin-top: var(--space-medium)
}

.c-eventDetails__upcoming:last-child {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: var(--color--ui-01);
  padding-bottom: var(--space-xxsmall);
  margin-bottom: var(--space-medium)
}

.c-eventDetails__upcoming:only-child {
  border: unset;
  padding: unset;
  margin: unset
}

.c-eventDetails__upcoming-list {
  display: flex;
  flex-flow: column wrap;
  gap: var(--space-xsmall)
}

@media(max-width:1389px) {
  .c-eventDetails__upcomingInformations {
    display: flex;
    flex-flow: column wrap;
    gap: var(--space-xxsmall);
    align-items: initial
  }
}

@media(min-width:1390px) {
  .c-eventDetails__upcomingInformations {
    display: flex;
    flex-flow: row wrap
  }
}

.c-eventDetails__upcomingInformations p {
  margin-top: 0
}

@media(max-width:1389px) {
  .c-eventDetails__upcoming:last-child:not(:only-child) {
    padding-bottom: var(--space-small)
  }

  .c-eventDetails__upcoming:only-child {
    margin-bottom: unset
  }

  .c-eventDetails__upcoming-list {
    gap: var(--space-medium)
  }
}

.c-eventDetails .event-external-link-v2 {
  margin-block-start: var(--space-small)
}

.c-eventDetails .event-external-link-v2 .button {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1
}

@media(min-width:376px) {
  .c-eventDetails .event-external-link-v2 .button {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .c-eventDetails .event-external-link-v2 .button {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.c-eventDetails .event-external-link-v2 .button::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.c-eventDetails .event-external-link-v2 .button:hover,
.c-eventDetails .event-external-link-v2 .button:focus,
.c-eventDetails .event-external-link-v2 .button:focus-visible,
.c-eventDetails .event-external-link-v2 .button:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.c-eventDetails .event-external-link-v2 .button:hover::before,
.c-eventDetails .event-external-link-v2 .button:focus::before,
.c-eventDetails .event-external-link-v2 .button:focus-visible::before,
.c-eventDetails .event-external-link-v2 .button:active::before {
  transform: scale(1.05)
}

.c-eventDetails .event-external-link-v2 .button:focus,
.c-eventDetails .event-external-link-v2 .button:focus-visible {
  outline: 0
}

.c-eventDetails .event-external-link-v2 .button:focus::before,
.c-eventDetails .event-external-link-v2 .button:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.c-eventDetails .event-external-link-v2 .button:active::before {
  outline-color: transparent
}

.c-eventDetails .event-external-link-v2 .button:only-of-type {
  margin: 0
}

.c-eventDetails .event-external-link-v2 .button.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.c-eventDetails .event-external-link-v2 .button::before {
  background: var(--color--interactive-primary) border-box
}

.c-eventDetails .event-external-link-v2 .button:hover::before {
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-primary-hover) border-box
}

.c-eventDetails .event-external-link-v2 .button:focus,
.c-eventDetails .event-external-link-v2 .button:focus-visible {
  color: var(--color--text-primary-inverse)
}

.c-eventDetails .event-external-link-v2 .button:focus::before,
.c-eventDetails .event-external-link-v2 .button:focus-visible::before {
  background: var(--color--interactive-primary) border-box
}

.c-eventDetails .event-external-link-v2 .button:active {
  color: var(--color--text-primary-inverse)
}

.c-eventDetails .event-external-link-v2 .button:active::before {
  background: var(--color--interactive-primary-active) border-box
}

.c-eventDetails .event-external-link-v2 .button[disabled],
.c-eventDetails .event-external-link-v2 .button.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.c-eventDetails .event-external-link-v2 .button[disabled]::before,
.c-eventDetails .event-external-link-v2 .button.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.c-eventDetails .event-external-link-v2 .button[disabled]:hover::before,
.c-eventDetails .event-external-link-v2 .button.button-disabled:hover::before,
.c-eventDetails .event-external-link-v2 .button[disabled]:focus::before,
.c-eventDetails .event-external-link-v2 .button.button-disabled:focus::before,
.c-eventDetails .event-external-link-v2 .button[disabled]:focus-visible::before,
.c-eventDetails .event-external-link-v2 .button.button-disabled:focus-visible::before,
.c-eventDetails .event-external-link-v2 .button[disabled]:active::before,
.c-eventDetails .event-external-link-v2 .button.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.c-eventDetails .event-external-link-v2 .button::after {
  margin-top: unset
}

*+.blog-author {
  margin-top: var(--space-xlarge)
}

.blog-author:not(.section) {
  display: grid;
  gap: var(--space-medium);
  background-color: var(--color--ui-background-light, var(--color--background-light))
}

@media(min-width:640px) {
  .blog-author:not(.section) {
    grid-template-columns: 10rem 1fr
  }
}

.blog-author__user {
  display: contents
}

@media(min-width:640px) {
  .blog-author-avatar {
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 3
  }
}

.blog-author-avatar__image {
  display: block;
  width: 10rem;
  height: 10rem;
  aspect-ratio: 1;
  object-fit: cover
}

@media(min-width:640px) {
  .blog-author-user-info {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 2
  }
}

.blog-author-user-info__name {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-h4);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0;
  color: var(--color--text-primary)
}

.blog-author-user-info__signature {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-2);
  margin-bottom: 0;
  color: var(--color--text-secondary);
  margin-top: var(--space-xsmall)
}

.blog-author__description {
  color: var(--color--text-secondary)
}

@media(min-width:640px) {
  .blog-author__description {
    grid-column-start: 2;
    grid-row-start: 2
  }
}

@media(max-width:639px) {
  .blog-author__soc-net {
    order: 3
  }
}

@media(min-width:640px) {
  .blog-author__soc-net {
    grid-column-start: 2;
    grid-row-start: 3
  }
}

.blog-author__soc-net-list {
  list-style: none;
  display: flex;
  gap: var(--spacing-15);
  padding: 0;
  margin: 0
}

.blog-author__soc-net-item {
  margin: unset
}

.blog-author__soc-net-link {
  color: var(--color--icon-tertiary);
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: transform .125s ease-in-out;
  will-change: transform;
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  outline-offset: 2px
}

.blog-author__soc-net-link::after {
  width: inherit;
  height: inherit
}

.blog-author__soc-net-link:hover,
.blog-author__soc-net-link:focus,
.blog-author__soc-net-link:focus-visible,
.blog-author__soc-net-link:active {
  color: var(--color--icon-tertiary);
  transform: scale(1.25)
}

.blog-author__soc-net-link:focus,
.blog-author__soc-net-link:focus-visible,
.blog-author__soc-net-link:active {
  outline-color: var(--color--interactive-focus-field)
}

:root {
  --paginationLinkSize: 2.25rem
}

.c-blogList__list {
  list-style: none;
  padding: 0;
  margin: 0;
  --cols: 3;
  --gap: 0;
  --cardWidth: 300px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(var(--cardWidth), (100% - 1*(var(--cols) - 1)*var(--gap))/var(--cols)), 1fr));
  gap: var(--gap)
}

@media(max-width:768px) {
  .c-blogList__list {
    --gap: var(--space-small)
  }
}

@media(min-width:769px) {
  .c-blogList__list {
    --gap: var(--space-medium)
  }
}

.c-blogList__list-item {
  margin: unset
}

.c-blogList__card {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  border-top: 6px solid var(--color--icon-primary);
  background-color: var(--color--ui-background-light, var(--color--background-light))
}

.c-blogList__card-content {
  padding: var(--spacing-30);
  flex: 1;
  display: flex;
  flex-direction: column
}

.c-blogList__card-img {
  display: block;
  overflow: hidden
}

.c-blogList__card-img picture {
  display: block;
  transition: transform .3s;
  will-change: transform
}

.c-blogList__card-img img {
  width: 100%;
  aspect-ratio: 2;
  object-fit: cover
}

.c-blogList__card-description {
  flex: 1 1 auto;
  margin-top: var(--space-small)
}

.c-blogList__card-description * {
  line-height: var(--leading--1-5)
}

.c-blogList__card-title {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-1)
}

.c-blogList__card-title-link {
  font-weight: inherit
}

.c-blogList__card-author {
  margin-top: var(--space-small);
  display: block;
  font-weight: var(--typeweight--semibold);
  color: var(--color--text-secondary)
}

.c-blogList__card-author a {
  color: inherit
}

.c-blogList__card-date {
  display: block;
  margin-top: var(--space-small);
  border-top: 1px solid var(--color--ui-border-01, var(--color--border-01));
  padding: var(--space-small) 0 0;
  color: var(--color--ui-04);
  font-weight: var(--typeweight--semibold-plus)
}

.c-blogList__reading-time {
  margin-top: var(--space-small);
  font-weight: var(--typeweight--semibold);
  color: var(--color--text-secondary)
}

.c-blogList__tags {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap
}

.c-blogList__tags-item {
  margin: 0 var(--spacing-10) var(--spacing-10) 0
}

.c-blogList__tags-item.selected {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-p);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  background: var(--color--label-interactive);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-radius: var(--button-corners);
  padding: var(--spacing-10) var(--spacing-15);
  display: inline-block;
  min-height: 1.5rem;
  font-size: var(--typesize-micro);
  font-weight: var(--typeweight--semibold);
  padding: var(--spacing-5) var(--spacing-10);
  cursor: default
}

@media(min-width:376px) {
  .c-blogList__tags-item.selected {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .c-blogList__tags-item.selected {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.c-blogList__tags-item.selected::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.c-blogList__tags-item.selected:hover,
.c-blogList__tags-item.selected:focus,
.c-blogList__tags-item.selected:focus-visible,
.c-blogList__tags-item.selected:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.c-blogList__tags-item.selected:hover::before,
.c-blogList__tags-item.selected:focus::before,
.c-blogList__tags-item.selected:focus-visible::before,
.c-blogList__tags-item.selected:active::before {
  transform: scale(1.05)
}

.c-blogList__tags-item.selected:focus,
.c-blogList__tags-item.selected:focus-visible {
  outline: 0
}

.c-blogList__tags-item.selected:focus::before,
.c-blogList__tags-item.selected:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.c-blogList__tags-item.selected:active::before {
  outline-color: transparent
}

.c-blogList__tags-item.selected:only-of-type {
  margin: 0
}

.c-blogList__tags-item.selected.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.c-blogList__tags-item.selected:hover {
  color: var(--color--text-link-hover)
}

.c-blogList__tags-item.selected:focus {
  color: var(--color--text-link)
}

.c-blogList__tags-item.selected:focus::before {
  transform: scale(1)
}

.c-blogList__tags-item.selected:active {
  color: var(--color--text-link-hover);
  text-decoration: none
}

.c-blogList__tags-item.selected a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-p);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  background: var(--color--label-interactive);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-radius: var(--button-corners);
  padding: var(--spacing-10) var(--spacing-15);
  display: inline-block;
  min-height: 1.5rem;
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  .c-blogList__tags-item.selected a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .c-blogList__tags-item.selected a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.c-blogList__tags-item.selected a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.c-blogList__tags-item.selected a:hover,
.c-blogList__tags-item.selected a:focus,
.c-blogList__tags-item.selected a:focus-visible,
.c-blogList__tags-item.selected a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.c-blogList__tags-item.selected a:hover::before,
.c-blogList__tags-item.selected a:focus::before,
.c-blogList__tags-item.selected a:focus-visible::before,
.c-blogList__tags-item.selected a:active::before {
  transform: scale(1.05)
}

.c-blogList__tags-item.selected a:focus,
.c-blogList__tags-item.selected a:focus-visible {
  outline: 0
}

.c-blogList__tags-item.selected a:focus::before,
.c-blogList__tags-item.selected a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.c-blogList__tags-item.selected a:active::before {
  outline-color: transparent
}

.c-blogList__tags-item.selected a:only-of-type {
  margin: 0
}

.c-blogList__tags-item.selected a.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.c-blogList__tags-item.selected a:hover {
  color: var(--color--text-link-hover)
}

.c-blogList__tags-item.selected a:focus {
  color: var(--color--text-link)
}

.c-blogList__tags-item.selected a:focus::before {
  transform: scale(1)
}

.c-blogList__tags-item.selected a:active {
  color: var(--color--text-link-hover);
  text-decoration: none
}

.c-blogList__tags-item-link {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-p);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  background: var(--color--label-interactive);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-radius: var(--button-corners);
  padding: var(--spacing-10) var(--spacing-15);
  display: inline-block;
  min-height: 1.5rem;
  font-size: var(--typesize-micro);
  font-weight: var(--typeweight--semibold);
  padding: var(--spacing-5) var(--spacing-10)
}

@media(min-width:376px) {
  .c-blogList__tags-item-link {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .c-blogList__tags-item-link {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.c-blogList__tags-item-link::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.c-blogList__tags-item-link:hover,
.c-blogList__tags-item-link:focus,
.c-blogList__tags-item-link:focus-visible,
.c-blogList__tags-item-link:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.c-blogList__tags-item-link:hover::before,
.c-blogList__tags-item-link:focus::before,
.c-blogList__tags-item-link:focus-visible::before,
.c-blogList__tags-item-link:active::before {
  transform: scale(1.05)
}

.c-blogList__tags-item-link:focus,
.c-blogList__tags-item-link:focus-visible {
  outline: 0
}

.c-blogList__tags-item-link:focus::before,
.c-blogList__tags-item-link:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.c-blogList__tags-item-link:active::before {
  outline-color: transparent
}

.c-blogList__tags-item-link:only-of-type {
  margin: 0
}

.c-blogList__tags-item-link.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.c-blogList__tags-item-link:hover {
  color: var(--color--text-link-hover)
}

.c-blogList__tags-item-link:focus {
  color: var(--color--text-link)
}

.c-blogList__tags-item-link:focus::before {
  transform: scale(1)
}

.c-blogList__tags-item-link:active {
  color: var(--color--text-link-hover);
  text-decoration: none
}

.c-blogList__tags-item-link a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-p);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  background: var(--color--label-interactive);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-radius: var(--button-corners);
  padding: var(--spacing-10) var(--spacing-15);
  display: inline-block;
  min-height: 1.5rem;
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  .c-blogList__tags-item-link a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .c-blogList__tags-item-link a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.c-blogList__tags-item-link a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.c-blogList__tags-item-link a:hover,
.c-blogList__tags-item-link a:focus,
.c-blogList__tags-item-link a:focus-visible,
.c-blogList__tags-item-link a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.c-blogList__tags-item-link a:hover::before,
.c-blogList__tags-item-link a:focus::before,
.c-blogList__tags-item-link a:focus-visible::before,
.c-blogList__tags-item-link a:active::before {
  transform: scale(1.05)
}

.c-blogList__tags-item-link a:focus,
.c-blogList__tags-item-link a:focus-visible {
  outline: 0
}

.c-blogList__tags-item-link a:focus::before,
.c-blogList__tags-item-link a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.c-blogList__tags-item-link a:active::before {
  outline-color: transparent
}

.c-blogList__tags-item-link a:only-of-type {
  margin: 0
}

.c-blogList__tags-item-link a.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.c-blogList__tags-item-link a:hover {
  color: var(--color--text-link-hover)
}

.c-blogList__tags-item-link a:focus {
  color: var(--color--text-link)
}

.c-blogList__tags-item-link a:focus::before {
  transform: scale(1)
}

.c-blogList__tags-item-link a:active {
  color: var(--color--text-link-hover);
  text-decoration: none
}

.c-blogList__tags-item-link:hover {
  text-decoration: none
}

.c-blogList__labels {
  --labelListTypeSize: var(--typesize-micro);
  --labelListGapSize: .8em;
  list-style: none;
  font-size: var(--labelListTypeSize);
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--labelListGapSize);
  row-gap: var(--labelListGapSize);
  padding: unset;
  margin: unset
}

.c-blogList__labels-item {
  --corners-semiround-sm: var(--space-unit);
  --space-unit: .4em;
  --space-xxxsmall: calc(var(--space-unit) - 1px);
  --space-xxsmall: calc(2 * var(--space-unit));
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-micro);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-tertiary);
  background-color: var(--color--ui-background-med, var(--color--background-medium));
  cursor: default;
  border-radius: var(--corners-semiround-sm);
  border-width: 1px;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: .1em;
  border-color: var(--color--ui-border-01, var(--color--border-01));
  min-height: max-content;
  padding: var(--space-xxxsmall) var(--space-xxsmall);
  margin: unset
}

.c-blogList__nav {
  display: flex;
  justify-content: center;
  align-items: center
}

@media(max-width:375px) {
  *+.c-blogList__nav {
    margin-top: var(--space-small)
  }
}

@media(min-width:376px) and (max-width:768px) {
  *+.c-blogList__nav {
    margin-top: var(--space-medium)
  }
}

@media(min-width:769px) {
  *+.c-blogList__nav {
    margin-top: var(--space-large)
  }
}

.c-blogList__nav-prev {
  display: inline-flex;
  align-items: center;
  margin-right: auto
}

.c-blogList__nav-prev::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  margin-right: .5em
}

.c-blogList__nav-next {
  display: inline-flex;
  align-items: center;
  text-align: right;
  margin-left: auto
}

.c-blogList__nav-next::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  transform: rotate(180deg);
  margin-left: .5em
}

.c-blogList__pagination {
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: 0
}

.c-blogList__pagination::before {
  content: attr(data-current-page);
  font-weight: var(--typeweight--light);
  letter-spacing: .25ch;
  margin: 0 var(--spacing-10)
}

@media(max-width:767px) {
  .c-blogList__pagination::before {
    display: inline-block
  }
}

@media(min-width:768px) {
  .c-blogList__pagination::before {
    display: none
  }
}

.c-blogList__pagination-item {
  list-style-type: none
}

.c-blogList__pagination-item+li {
  margin-top: 0;
  margin-left: .375rem
}

@media(max-width:767px) {
  .c-blogList__pagination-item {
    display: none
  }
}

.c-blogList__pagination-item.item-next,
.c-blogList__pagination-item.item-prev {
  display: block
}

@media(max-width:767px) {
  .c-blogList__pagination-item.item-next {
    margin-left: unset
  }
}

@media(max-width:767px) {
  .c-blogList__pagination-item.item-prev {
    order: -1
  }
}

@media(min-width:768px) {
  .c-blogList__pagination-item.item-prev {
    order: 0
  }
}

.c-blogList__pagination-prev,
.c-blogList__pagination-next,
.c-blogList__pagination-number {
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  line-height: var(--paginationLinkSize);
  color: var(--color--interactive-secondary);
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--paginationLinkSize);
  height: var(--paginationLinkSize);
  aspect-ratio: 1;
  position: relative;
  z-index: 1;
  padding: 0
}

.c-blogList__pagination-prev::before,
.c-blogList__pagination-next::before,
.c-blogList__pagination-number::before {
  border-radius: 50%;
  width: var(--paginationLinkSize);
  height: var(--paginationLinkSize);
  background-color: var(--color--ui-06);
  border: .125em solid transparent;
  display: block;
  content: "";
  flex: 0 0 auto;
  position: absolute;
  z-index: -1;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  transform: scale(0);
  transition: all .125s ease-in-out
}

.c-blogList__pagination-prev:hover,
.c-blogList__pagination-next:hover,
.c-blogList__pagination-number:hover,
.c-blogList__pagination-prev:active,
.c-blogList__pagination-next:active,
.c-blogList__pagination-number:active {
  color: currentColor;
  text-decoration: none
}

.c-blogList__pagination-prev:hover::before,
.c-blogList__pagination-next:hover::before,
.c-blogList__pagination-number:hover::before,
.c-blogList__pagination-prev:active::before,
.c-blogList__pagination-next:active::before,
.c-blogList__pagination-number:active::before {
  transform: scale(1)
}

.c-blogList__pagination-prev:focus,
.c-blogList__pagination-next:focus,
.c-blogList__pagination-number:focus,
.c-blogList__pagination-prev:focus-visible,
.c-blogList__pagination-next:focus-visible,
.c-blogList__pagination-number:focus-visible {
  color: currentColor;
  outline: 0;
  text-decoration: none
}

.c-blogList__pagination-prev:focus::before,
.c-blogList__pagination-next:focus::before,
.c-blogList__pagination-number:focus::before,
.c-blogList__pagination-prev:focus-visible::before,
.c-blogList__pagination-next:focus-visible::before,
.c-blogList__pagination-number:focus-visible::before {
  transform: scale(1);
  border-color: var(--color--interactive-focus-field)
}

.c-blogList__pagination-number[aria-current="page"]:not([href], [tabindex]) {
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  line-height: var(--paginationLinkSize);
  color: var(--color--interactive-secondary);
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--paginationLinkSize);
  height: var(--paginationLinkSize);
  aspect-ratio: 1;
  position: relative;
  z-index: 1;
  padding: 0;
  font-weight: var(--typeweight--bold);
  color: var(--color--text-primary-inverse);
  pointer-events: none
}

.c-blogList__pagination-number[aria-current="page"]:not([href], [tabindex])::before {
  border-radius: 50%;
  width: var(--paginationLinkSize);
  height: var(--paginationLinkSize);
  background-color: var(--color--ui-06);
  border: .125em solid transparent;
  display: block;
  content: "";
  flex: 0 0 auto;
  position: absolute;
  z-index: -1;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  transform: scale(0);
  transition: all .125s ease-in-out
}

.c-blogList__pagination-number[aria-current="page"]:not([href], [tabindex]):hover,
.c-blogList__pagination-number[aria-current="page"]:not([href], [tabindex]):active {
  color: currentColor;
  text-decoration: none
}

.c-blogList__pagination-number[aria-current="page"]:not([href], [tabindex]):hover::before,
.c-blogList__pagination-number[aria-current="page"]:not([href], [tabindex]):active::before {
  transform: scale(1)
}

.c-blogList__pagination-number[aria-current="page"]:not([href], [tabindex]):focus,
.c-blogList__pagination-number[aria-current="page"]:not([href], [tabindex]):focus-visible {
  color: currentColor;
  outline: 0;
  text-decoration: none
}

.c-blogList__pagination-number[aria-current="page"]:not([href], [tabindex]):focus::before,
.c-blogList__pagination-number[aria-current="page"]:not([href], [tabindex]):focus-visible::before {
  transform: scale(1);
  border-color: var(--color--interactive-focus-field)
}

.c-blogList__pagination-number[aria-current="page"]:not([href], [tabindex])::before {
  background-color: var(--color--interactive-secondary);
  transform: none;
  transition: none
}

.c-blogList__pagination-next {
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  line-height: var(--paginationLinkSize);
  color: var(--color--interactive-secondary);
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--paginationLinkSize);
  height: var(--paginationLinkSize);
  aspect-ratio: 1;
  position: relative;
  z-index: 1;
  padding: 0
}

.c-blogList__pagination-next::before {
  border-radius: 50%;
  width: var(--paginationLinkSize);
  height: var(--paginationLinkSize);
  background-color: var(--color--ui-06);
  border: .125em solid transparent;
  display: block;
  content: "";
  flex: 0 0 auto;
  position: absolute;
  z-index: -1;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  transform: scale(0);
  transition: all .125s ease-in-out
}

.c-blogList__pagination-next:hover,
.c-blogList__pagination-next:active {
  color: currentColor;
  text-decoration: none
}

.c-blogList__pagination-next:hover::before,
.c-blogList__pagination-next:active::before {
  transform: scale(1)
}

.c-blogList__pagination-next:focus,
.c-blogList__pagination-next:focus-visible {
  color: currentColor;
  outline: 0;
  text-decoration: none
}

.c-blogList__pagination-next:focus::before,
.c-blogList__pagination-next:focus-visible::before {
  transform: scale(1);
  border-color: var(--color--interactive-focus-field)
}

.c-blogList__pagination-next::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  transform: rotate(180deg);
  margin: auto
}

.c-blogList__pagination-next.disabled:not([href], [tabindex]) {
  color: var(--color--interactive-disabled-04);
  pointer-events: none
}

.c-blogList__pagination-next.disabled:not([href], [tabindex]):focus,
.c-blogList__pagination-next.disabled:not([href], [tabindex]):focus-visible {
  outline: 0
}

.c-blogList__pagination-next>span {
  display: none
}

.c-blogList__pagination-prev {
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  line-height: var(--paginationLinkSize);
  color: var(--color--interactive-secondary);
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--paginationLinkSize);
  height: var(--paginationLinkSize);
  aspect-ratio: 1;
  position: relative;
  z-index: 1;
  padding: 0
}

.c-blogList__pagination-prev::before {
  border-radius: 50%;
  width: var(--paginationLinkSize);
  height: var(--paginationLinkSize);
  background-color: var(--color--ui-06);
  border: .125em solid transparent;
  display: block;
  content: "";
  flex: 0 0 auto;
  position: absolute;
  z-index: -1;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  transform: scale(0);
  transition: all .125s ease-in-out
}

.c-blogList__pagination-prev:hover,
.c-blogList__pagination-prev:active {
  color: currentColor;
  text-decoration: none
}

.c-blogList__pagination-prev:hover::before,
.c-blogList__pagination-prev:active::before {
  transform: scale(1)
}

.c-blogList__pagination-prev:focus,
.c-blogList__pagination-prev:focus-visible {
  color: currentColor;
  outline: 0;
  text-decoration: none
}

.c-blogList__pagination-prev:focus::before,
.c-blogList__pagination-prev:focus-visible::before {
  transform: scale(1);
  border-color: var(--color--interactive-focus-field)
}

.c-blogList__pagination-prev::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  margin: auto
}

.c-blogList__pagination-prev.disabled:not([href], [tabindex]) {
  color: var(--color--interactive-disabled-04);
  pointer-events: none
}

.c-blogList__pagination-prev.disabled:not([href], [tabindex]):focus,
.c-blogList__pagination-prev.disabled:not([href], [tabindex]):focus-visible {
  outline: 0
}

.c-blogList__pagination-prev>span {
  display: none
}

.c-blogList__pagination-more {
  color: var(--color--interactive-secondary);
  pointer-events: none
}

.c-blogPost {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-small)
}

.c-blogPost__back-blog-list {
  margin: 0 0 var(--space-small)
}

.c-blogPost__back-blog-list-link {
  position: relative;
  display: flex;
  align-items: center
}

.c-blogPost__back-blog-list-link::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  margin: 0 var(--space-xxxsmall) 0 0
}

.c-blogPost__back-blog-list-link:hover {
  text-decoration: none
}

.c-blogPost__title {
  font-size: var(--typesize-h1-catalog);
  font-weight: var(--typeweight--light)
}

.c-blogPost__info {
  display: flex;
  align-items: center;
  --author-imgSize: 90px
}

.c-blogPost__author-img {
  width: var(--author-imgSize);
  height: var(--author-imgSize);
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 100%;
  margin: 0 var(--space-small) 0 0
}

.c-blogPost__published-info {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-xxxsmall)
}

.c-blogPost__author-name {
  font-weight: var(--typeweight--semibold-plus);
  color: var(--color--text-secondary);
  display: block
}

.c-blogPost__reading-time {
  font-weight: var(--typeweight--semibold-plus)
}

.c-blogPost__author-link {
  color: inherit
}

.c-blogPost__author-link:hover {
  text-decoration: none
}

.c-blogPost__tags {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0 0 var(--space-medium)
}

.c-blogPost__tags-item {
  margin: 0 var(--space-xxsmall) var(--space-xxsmall) 0
}

.c-blogPost__tags-item-link {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-p);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  background: var(--color--label-interactive);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-radius: var(--button-corners);
  padding: var(--spacing-10) var(--spacing-15);
  display: inline-block;
  min-height: 1.5rem
}

@media(min-width:376px) {
  .c-blogPost__tags-item-link {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .c-blogPost__tags-item-link {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.c-blogPost__tags-item-link::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.c-blogPost__tags-item-link:hover,
.c-blogPost__tags-item-link:focus,
.c-blogPost__tags-item-link:focus-visible,
.c-blogPost__tags-item-link:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.c-blogPost__tags-item-link:hover::before,
.c-blogPost__tags-item-link:focus::before,
.c-blogPost__tags-item-link:focus-visible::before,
.c-blogPost__tags-item-link:active::before {
  transform: scale(1.05)
}

.c-blogPost__tags-item-link:focus,
.c-blogPost__tags-item-link:focus-visible {
  outline: 0
}

.c-blogPost__tags-item-link:focus::before,
.c-blogPost__tags-item-link:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.c-blogPost__tags-item-link:active::before {
  outline-color: transparent
}

.c-blogPost__tags-item-link:only-of-type {
  margin: 0
}

.c-blogPost__tags-item-link.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.c-blogPost__tags-item-link:hover {
  color: var(--color--text-link-hover)
}

.c-blogPost__tags-item-link:focus {
  color: var(--color--text-link)
}

.c-blogPost__tags-item-link:focus::before {
  transform: scale(1)
}

.c-blogPost__tags-item-link:active {
  color: var(--color--text-link-hover);
  text-decoration: none
}

.c-blogPost__tags-item-link a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-p);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  background: var(--color--label-interactive);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-radius: var(--button-corners);
  padding: var(--spacing-10) var(--spacing-15);
  display: inline-block;
  min-height: 1.5rem;
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  .c-blogPost__tags-item-link a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .c-blogPost__tags-item-link a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.c-blogPost__tags-item-link a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.c-blogPost__tags-item-link a:hover,
.c-blogPost__tags-item-link a:focus,
.c-blogPost__tags-item-link a:focus-visible,
.c-blogPost__tags-item-link a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.c-blogPost__tags-item-link a:hover::before,
.c-blogPost__tags-item-link a:focus::before,
.c-blogPost__tags-item-link a:focus-visible::before,
.c-blogPost__tags-item-link a:active::before {
  transform: scale(1.05)
}

.c-blogPost__tags-item-link a:focus,
.c-blogPost__tags-item-link a:focus-visible {
  outline: 0
}

.c-blogPost__tags-item-link a:focus::before,
.c-blogPost__tags-item-link a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.c-blogPost__tags-item-link a:active::before {
  outline-color: transparent
}

.c-blogPost__tags-item-link a:only-of-type {
  margin: 0
}

.c-blogPost__tags-item-link a.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.c-blogPost__tags-item-link a:hover {
  color: var(--color--text-link-hover)
}

.c-blogPost__tags-item-link a:focus {
  color: var(--color--text-link)
}

.c-blogPost__tags-item-link a:focus::before {
  transform: scale(1)
}

.c-blogPost__tags-item-link a:active {
  color: var(--color--text-link-hover);
  text-decoration: none
}

.c-blogPost__img picture {
  display: block
}

.c-blogPost__img img {
  width: 100%
}

.tag-list {
  --outlineSize-small: .0714285714286em;
  --outlineSize-medium: .142857142857em;
  --tagListTypeSize: var(--typesize-minor);
  --tagListMinLinkHeight: calc(var(--tagListTypeSize) * 2.14285714286);
  --tagListLinkPad-x: 1.14285714286em;
  --tagListLinkPad-yTop: .357142857143em;
  --tagListLinkPad-yBottom: .428571428571em;
  --tagListColGapSize: 1.14285714286em;
  --tagListRowGapSize: .857142857143em
}

.tag-list h3,
.tag-list .c-tagList__heading {
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-5);
  color: var(--color--text-tertiary);
  display: block;
  margin-bottom: var(--tagListRowGapSize)
}

.tag-list ul {
  font-size: var(--tagListTypeSize);
  display: inline-flex;
  flex-wrap: wrap;
  column-gap: var(--tagListColGapSize);
  row-gap: var(--tagListRowGapSize);
  list-style: none;
  padding: 0;
  margin-top: unset
}

.tag-list ul li {
  border-bottom: unset;
  padding: unset
}

.tag-list ul li+li {
  margin-top: unset
}

.tag-list ul li a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: inherit;
  font-weight: var(--typeweight--medium);
  min-height: var(--tagListMinLinkHeight);
  padding: var(--tagListLinkPad-yTop) var(--tagListLinkPad-x) var(--tagListLinkPad-yBottom) var(--tagListLinkPad-x);
  margin: 0;
  color: var(--color--text-link)
}

@media(min-width:376px) {
  .tag-list ul li a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .tag-list ul li a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.tag-list ul li a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.tag-list ul li a:hover,
.tag-list ul li a:focus,
.tag-list ul li a:focus-visible,
.tag-list ul li a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.tag-list ul li a:hover::before,
.tag-list ul li a:focus::before,
.tag-list ul li a:focus-visible::before,
.tag-list ul li a:active::before {
  transform: scale(1.05)
}

.tag-list ul li a:focus,
.tag-list ul li a:focus-visible {
  outline: 0
}

.tag-list ul li a:focus::before,
.tag-list ul li a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.tag-list ul li a:active::before {
  outline-color: transparent
}

.tag-list ul li a:only-of-type {
  margin: 0
}

.tag-list ul li a.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.tag-list ul li a::before {
  background-color: var(--color--ui-background-light, var(--color--background-light));
  outline-width: var(--outlineSize-small);
  outline-offset: calc(var(--outlineSize-small) * -1);
  outline-color: var(--color--ui-border-01, var(--color--border-01))
}

.tag-list ul li a:hover,
.tag-list ul li a:focus,
.tag-list ul li a:focus-visible,
.tag-list ul li a:active {
  color: unset
}

.tag-list ul li a:hover,
.tag-list ul li a:focus,
.tag-list ul li a:focus-visible {
  color: var(--color--text-link)
}

.tag-list ul li a:hover::before {
  background-color: var(--color--ui-background-med, var(--color--background-medium))
}

.tag-list ul li a:focus::before,
.tag-list ul li a:focus-visible::before {
  outline-width: var(--outlineSize-medium);
  outline-offset: calc(var(--outlineSize-small) * -1)
}

.tag-list ul li a:active {
  color: var(--color--text-secondary)
}

.tag-list ul li a:active::before {
  background-color: var(--color--ui-background-light, var(--color--background-light));
  outline-color: var(--color--ui-border-01, var(--color--border-01))
}

@media(min-width:376px) {
  .tag-list ul li a {
    margin: 0
  }
}

.tag-list ul li.selected {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: inherit;
  font-weight: var(--typeweight--medium);
  min-height: var(--tagListMinLinkHeight);
  padding: var(--tagListLinkPad-yTop) var(--tagListLinkPad-x) var(--tagListLinkPad-yBottom) var(--tagListLinkPad-x);
  margin: 0;
  color: var(--color--text-secondary);
  cursor: default;
  pointer-events: none
}

@media(min-width:376px) {
  .tag-list ul li.selected {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .tag-list ul li.selected {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.tag-list ul li.selected::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.tag-list ul li.selected:hover,
.tag-list ul li.selected:focus,
.tag-list ul li.selected:focus-visible,
.tag-list ul li.selected:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.tag-list ul li.selected:hover::before,
.tag-list ul li.selected:focus::before,
.tag-list ul li.selected:focus-visible::before,
.tag-list ul li.selected:active::before {
  transform: scale(1.05)
}

.tag-list ul li.selected:focus,
.tag-list ul li.selected:focus-visible {
  outline: 0
}

.tag-list ul li.selected:focus::before,
.tag-list ul li.selected:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.tag-list ul li.selected:active::before {
  outline-color: transparent
}

.tag-list ul li.selected:only-of-type {
  margin: 0
}

.tag-list ul li.selected.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.tag-list ul li.selected::before {
  background-color: var(--color--ui-background-light, var(--color--background-light));
  outline-width: var(--outlineSize-small);
  outline-offset: calc(var(--outlineSize-small) * -1);
  outline-color: var(--color--ui-border-01, var(--color--border-01))
}

.tag-list ul li.selected:hover,
.tag-list ul li.selected:focus,
.tag-list ul li.selected:focus-visible,
.tag-list ul li.selected:active {
  color: unset
}

@media(min-width:376px) {
  .tag-list ul li.selected {
    margin: 0
  }
}

.tag-list.collapsable ul {
  box-sizing: content-box;
  position: relative;
  overflow: hidden;
  height: 32px;
  width: 100%;
  padding: var(--space-xxxsmall);
  margin: calc(var(--space-xxxsmall) * -1)
}

.tag-list.collapsable li.sm-invisible-child {
  display: none
}

.tag-list.collapsable.show-more-expanded ul {
  height: auto
}

.tag-list.collapsable .btn-expand-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  width: 100%;
  margin-top: var(--space-xxsmall)
}

.tag-list.collapsable .btn-expand-wrapper::before,
.tag-list.collapsable .btn-expand-wrapper::after {
  content: "";
  display: block;
  flex-grow: 1;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--color--ui-border-01, var(--color--border-01))
}

.tag-list.collapsable .btn-expand-wrapper .btn-expand {
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  text-decoration: none;
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: center;
  transition: all .2s ease-in-out;
  padding: 0 var(--space-medium)
}

.tag-list.collapsable .btn-expand-wrapper .btn-expand::after {
  transition: transform .3s ease-in-out
}

.tag-list.collapsable.sm-contains-all .btn-expand-wrapper {
  display: none
}

.c-tagList {
  --outlineSize-small: .0714285714286em;
  --outlineSize-medium: .142857142857em;
  --tagListTypeSize: var(--typesize-minor);
  --tagListMinLinkHeight: calc(var(--tagListTypeSize) * 2.14285714286);
  --tagListLinkPad-x: 1.14285714286em;
  --tagListLinkPad-yTop: .357142857143em;
  --tagListLinkPad-yBottom: .428571428571em;
  --tagListColGapSize: 1.14285714286em;
  --tagListRowGapSize: .857142857143em
}

.c-tagList__title {
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-5);
  color: var(--color--text-tertiary);
  display: block;
  margin-bottom: var(--tagListRowGapSize)
}

.c-tagList__list {
  font-size: var(--tagListTypeSize);
  display: inline-flex;
  flex-wrap: wrap;
  column-gap: var(--tagListColGapSize);
  row-gap: var(--tagListRowGapSize);
  list-style: none;
  padding: 0;
  margin-top: unset
}

.c-tagList__item {
  border-bottom: unset;
  padding: unset
}

.c-tagList__item+li {
  margin-top: unset
}

.c-tagList__link {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: inherit;
  font-weight: var(--typeweight--medium);
  min-height: var(--tagListMinLinkHeight);
  padding: var(--tagListLinkPad-yTop) var(--tagListLinkPad-x) var(--tagListLinkPad-yBottom) var(--tagListLinkPad-x);
  margin: 0;
  color: var(--color--text-link)
}

@media(min-width:376px) {
  .c-tagList__link {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .c-tagList__link {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.c-tagList__link::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.c-tagList__link:hover,
.c-tagList__link:focus,
.c-tagList__link:focus-visible,
.c-tagList__link:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.c-tagList__link:hover::before,
.c-tagList__link:focus::before,
.c-tagList__link:focus-visible::before,
.c-tagList__link:active::before {
  transform: scale(1.05)
}

.c-tagList__link:focus,
.c-tagList__link:focus-visible {
  outline: 0
}

.c-tagList__link:focus::before,
.c-tagList__link:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.c-tagList__link:active::before {
  outline-color: transparent
}

.c-tagList__link:only-of-type {
  margin: 0
}

.c-tagList__link.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.c-tagList__link::before {
  background-color: var(--color--ui-background-light, var(--color--background-light));
  outline-width: var(--outlineSize-small);
  outline-offset: calc(var(--outlineSize-small) * -1);
  outline-color: var(--color--ui-border-01, var(--color--border-01))
}

.c-tagList__link:hover,
.c-tagList__link:focus,
.c-tagList__link:focus-visible,
.c-tagList__link:active {
  color: unset
}

.c-tagList__link:hover,
.c-tagList__link:focus,
.c-tagList__link:focus-visible {
  color: var(--color--text-link)
}

.c-tagList__link:hover::before {
  background-color: var(--color--ui-background-med, var(--color--background-medium))
}

.c-tagList__link:focus::before,
.c-tagList__link:focus-visible::before {
  outline-width: var(--outlineSize-medium);
  outline-offset: calc(var(--outlineSize-small) * -1)
}

.c-tagList__link:active {
  color: var(--color--text-secondary)
}

.c-tagList__link:active::before {
  background-color: var(--color--ui-background-light, var(--color--background-light));
  outline-color: var(--color--ui-border-01, var(--color--border-01))
}

@media(min-width:376px) {
  .c-tagList__link {
    margin: 0
  }
}

.c-tagList__item--selected .c-tagList__link {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: inherit;
  font-weight: var(--typeweight--medium);
  min-height: var(--tagListMinLinkHeight);
  padding: var(--tagListLinkPad-yTop) var(--tagListLinkPad-x) var(--tagListLinkPad-yBottom) var(--tagListLinkPad-x);
  margin: 0;
  color: var(--color--text-secondary);
  cursor: default;
  pointer-events: none
}

@media(min-width:376px) {
  .c-tagList__item--selected .c-tagList__link {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .c-tagList__item--selected .c-tagList__link {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.c-tagList__item--selected .c-tagList__link::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.c-tagList__item--selected .c-tagList__link:hover,
.c-tagList__item--selected .c-tagList__link:focus,
.c-tagList__item--selected .c-tagList__link:focus-visible,
.c-tagList__item--selected .c-tagList__link:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.c-tagList__item--selected .c-tagList__link:hover::before,
.c-tagList__item--selected .c-tagList__link:focus::before,
.c-tagList__item--selected .c-tagList__link:focus-visible::before,
.c-tagList__item--selected .c-tagList__link:active::before {
  transform: scale(1.05)
}

.c-tagList__item--selected .c-tagList__link:focus,
.c-tagList__item--selected .c-tagList__link:focus-visible {
  outline: 0
}

.c-tagList__item--selected .c-tagList__link:focus::before,
.c-tagList__item--selected .c-tagList__link:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.c-tagList__item--selected .c-tagList__link:active::before {
  outline-color: transparent
}

.c-tagList__item--selected .c-tagList__link:only-of-type {
  margin: 0
}

.c-tagList__item--selected .c-tagList__link.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.c-tagList__item--selected .c-tagList__link::before {
  background-color: var(--color--ui-background-light, var(--color--background-light));
  outline-width: var(--outlineSize-small);
  outline-offset: calc(var(--outlineSize-small) * -1);
  outline-color: var(--color--ui-border-01, var(--color--border-01))
}

.c-tagList__item--selected .c-tagList__link:hover,
.c-tagList__item--selected .c-tagList__link:focus,
.c-tagList__item--selected .c-tagList__link:focus-visible,
.c-tagList__item--selected .c-tagList__link:active {
  color: unset
}

@media(min-width:376px) {
  .c-tagList__item--selected .c-tagList__link {
    margin: 0
  }
}

.c-tagList.collapsable ul {
  box-sizing: content-box;
  position: relative;
  overflow: hidden;
  height: 32px;
  width: 100%;
  padding: var(--space-xxxsmall);
  margin: calc(var(--space-xxxsmall) * -1)
}

.c-tagList.collapsable li.sm-invisible-child {
  display: none
}

.c-tagList.collapsable.show-more-expanded ul {
  height: auto
}

.c-tagList.collapsable .btn-expand-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  width: 100%;
  margin-top: var(--space-xxsmall)
}

.c-tagList.collapsable .btn-expand-wrapper::before,
.c-tagList.collapsable .btn-expand-wrapper::after {
  content: "";
  display: block;
  flex-grow: 1;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--color--ui-border-01, var(--color--border-01))
}

.c-tagList.collapsable .btn-expand-wrapper .btn-expand {
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  text-decoration: none;
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: center;
  transition: all .2s ease-in-out;
  padding: 0 var(--space-medium)
}

.c-tagList.collapsable .btn-expand-wrapper .btn-expand::after {
  transition: transform .3s ease-in-out
}

.c-tagList.collapsable.sm-contains-all .btn-expand-wrapper {
  display: none
}

.share-this-page .share-page .icon-mail::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-mailSocial-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-mailSocial-lg")
}

.share-this-page .share-page .icon-facebook::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-facebook-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-facebook-lg")
}

.share-this-page .share-page .icon-twitter::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-twitter-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-twitter-lg")
}

.share-this-page .share-page .icon-linkedin::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-linkedin-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-linkedin-lg")
}

.share-this-page .share-page__action-button {
  font-size: inherit;
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1);
  color: var(--color--text-link);
  border: 0;
  background-color: transparent;
  transition: outline .2s ease-in-out;
  padding: 0 0 .05em 0;
  margin: 0;
  display: flex;
  align-items: center;
  font-weight: var(--typeweight--semibold)
}

.share-this-page .share-page__action-button:focus,
.share-this-page .share-page__action-button:focus-visible {
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--color--interactive-focus-field)
}

@media(max-width:375px) {
  .share-this-page .share-page__action-button {
    text-align: left
  }
}

.share-this-page .share-page__action-button::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-share-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-share-md");
  margin-inline-end: var(--space-xxsmall)
}

.share-this-page .share-page__action-button:focus,
.share-this-page .share-page__action-button:focus-visible {
  text-decoration: none
}

.share-this-page .share-page__title-txt {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h3);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

.share-this-page .share-page__close {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: unset;
  background-image: unset;
  background-repeat: unset;
  background-size: unset;
  margin-top: 0;
  margin-right: 0;
  transition: none;
  height: 36px;
  width: 36px
}

.share-this-page .share-page__close:hover,
.share-this-page .share-page__close:focus {
  outline: 0;
  background-color: transparent
}

.share-this-page .share-page__close::before {
  display: block;
  content: "";
  flex: 0 0 auto;
  border-radius: 50%;
  width: inherit;
  height: inherit;
  background-color: var(--color--ui-01);
  position: absolute;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  transform: scale(0);
  transition: transform .125s ease-in-out;
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent
}

.share-this-page .share-page__close::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg");
  transform: none;
  top: unset;
  left: unset;
  border-radius: 0
}

.share-this-page .share-page__close:hover::before,
.share-this-page .share-page__close:focus::before,
.share-this-page .share-page__close:focus-visible::before,
.share-this-page .share-page__close:active::before {
  transform: scale(1)
}

.share-this-page .share-page__close:focus,
.share-this-page .share-page__close:focus-visible {
  outline: 0
}

.share-this-page .share-page__close:focus::before,
.share-this-page .share-page__close:focus-visible::before {
  outline-color: var(--color--interactive-focus-field)
}

@media(forced-colors:active) and (prefers-color-scheme:dark) {

  .share-this-page .share-page__close:focus,
  .share-this-page .share-page__close:focus-visible {
    outline-width: 2px;
    outline-style: solid;
    outline-offset: 1px
  }

  .share-this-page .share-page__close::after {
    filter: brightness(0) invert(1)
  }

  .share-this-page .share-page__close:hover::after,
  .share-this-page .share-page__close:focus::after,
  .share-this-page .share-page__close:focus-visible::after,
  .share-this-page .share-page__close:active::after {
    filter: unset
  }

  .share-this-page .share-page__close::before {
    outline-color: currentColor;
    outline-offset: -2px;
    transform: unset;
    opacity: unset;
    background-color: unset
  }

  .share-this-page .share-page__close:hover::before,
  .share-this-page .share-page__close:focus::before,
  .share-this-page .share-page__close:focus-visible::before,
  .share-this-page .share-page__close:active::before {
    background-color: currentColor;
    filter: brightness(0) invert(1)
  }
}

@media(forced-colors:active) and (prefers-color-scheme:light) {
  .share-this-page .share-page__close {
    border-radius: 50%;
    outline-width: 2px;
    outline-style: solid;
    outline-color: currentColor
  }

  .share-this-page .share-page__close:hover,
  .share-this-page .share-page__close:focus,
  .share-this-page .share-page__close:focus-visible,
  .share-this-page .share-page__close:active {
    outline-width: 2px;
    outline-style: solid;
    outline-color: currentColor
  }

  .share-this-page .share-page__close::after {
    filter: brightness(1) invert(1)
  }

  .share-this-page .share-page__close:hover::after,
  .share-this-page .share-page__close:focus::after,
  .share-this-page .share-page__close:focus-visible::after,
  .share-this-page .share-page__close:active::after {
    filter: unset
  }

  .share-this-page .share-page__close:hover::before,
  .share-this-page .share-page__close:focus::before,
  .share-this-page .share-page__close:focus-visible::before,
  .share-this-page .share-page__close:active::before {
    filter: brightness(1) invert(1);
    outline: 0
  }
}

.share-this-page .share-page__close::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg")
}

.share-this-page .share-page__mail-form {
  gap: var(--spacing-30)
}

.share-this-page .share-page__mail-copy-button {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary);
  transition: color .125s ease-in-out;
  padding: .75em 1.25em;
  min-height: 3rem;
  flex: 1 0 auto
}

@media(min-width:376px) {
  .share-this-page .share-page__mail-copy-button {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .share-this-page .share-page__mail-copy-button {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.share-this-page .share-page__mail-copy-button::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.share-this-page .share-page__mail-copy-button:hover,
.share-this-page .share-page__mail-copy-button:focus,
.share-this-page .share-page__mail-copy-button:focus-visible,
.share-this-page .share-page__mail-copy-button:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.share-this-page .share-page__mail-copy-button:hover::before,
.share-this-page .share-page__mail-copy-button:focus::before,
.share-this-page .share-page__mail-copy-button:focus-visible::before,
.share-this-page .share-page__mail-copy-button:active::before {
  transform: scale(1.05)
}

.share-this-page .share-page__mail-copy-button:focus,
.share-this-page .share-page__mail-copy-button:focus-visible {
  outline: 0
}

.share-this-page .share-page__mail-copy-button:focus::before,
.share-this-page .share-page__mail-copy-button:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.share-this-page .share-page__mail-copy-button:active::before {
  outline-color: transparent
}

.share-this-page .share-page__mail-copy-button:only-of-type {
  margin: 0
}

.share-this-page .share-page__mail-copy-button.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.share-this-page .share-page__mail-copy-button::before {
  outline-color: var(--color--interactive-tertiary);
  outline-offset: -2px;
  background-color: transparent
}

.share-this-page .share-page__mail-copy-button:hover {
  color: var(--color--text-primary-inverse)
}

.share-this-page .share-page__mail-copy-button:hover::before {
  outline-color: var(--color--interactive-tertiary-hover);
  background: var(--color--interactive-tertiary-hover)
}

.share-this-page .share-page__mail-copy-button:focus,
.share-this-page .share-page__mail-copy-button:focus-visible {
  color: var(--color--text-tertiary)
}

.share-this-page .share-page__mail-copy-button:focus::before,
.share-this-page .share-page__mail-copy-button:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

.share-this-page .share-page__mail-copy-button:active {
  color: var(--color--text-primary-inverse)
}

.share-this-page .share-page__mail-copy-button:active::before {
  background: var(--color--interactive-tertiary-active)
}

.share-this-page .share-page__mail-copy-button[disabled],
.share-this-page .share-page__mail-copy-button.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.share-this-page .share-page__mail-copy-button[disabled]::before,
.share-this-page .share-page__mail-copy-button.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.share-this-page .share-page__mail-copy-button[disabled]:hover::before,
.share-this-page .share-page__mail-copy-button.button-disabled:hover::before,
.share-this-page .share-page__mail-copy-button[disabled]:focus::before,
.share-this-page .share-page__mail-copy-button.button-disabled:focus::before,
.share-this-page .share-page__mail-copy-button[disabled]:focus-visible::before,
.share-this-page .share-page__mail-copy-button.button-disabled:focus-visible::before,
.share-this-page .share-page__mail-copy-button[disabled]:active::before,
.share-this-page .share-page__mail-copy-button.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.share-this-page .share-page__mail-copy-button[disabled]::before,
.share-this-page .share-page__mail-copy-button.button-disabled::before {
  outline-color: var(--color--interactive-disabled-01);
  outline-offset: -2px;
  background: transparent
}

.share-this-page .share-page__mail-copy-button[disabled]:hover::before,
.share-this-page .share-page__mail-copy-button.button-disabled:hover::before,
.share-this-page .share-page__mail-copy-button[disabled]:focus::before,
.share-this-page .share-page__mail-copy-button.button-disabled:focus::before,
.share-this-page .share-page__mail-copy-button[disabled]:focus-visible::before,
.share-this-page .share-page__mail-copy-button.button-disabled:focus-visible::before,
.share-this-page .share-page__mail-copy-button[disabled]:active::before,
.share-this-page .share-page__mail-copy-button.button-disabled:active::before {
  background: transparent
}

.share-this-page .share-page__mail-copy-button.icon-only {
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

.share-this-page .share-page__social-item {
  margin: 0
}

.share-this-page .share-page__social-link {
  background: 0;
  -webkit-transition: all .125s ease-in-out;
  transition: all .125s ease-in-out
}

.share-this-page .share-page__social-link[class*="icon-"]::after {
  background-color: var(--color--icon-primary);
  width: inherit;
  height: inherit
}

.share-this-page .share-page__social-link:hover,
.share-this-page .share-page__social-link:focus,
.share-this-page .share-page__social-link:focus-visible,
.share-this-page .share-page__social-link:active {
  transform: scale(1.25)
}

*+.search-results-list {
  margin-top: var(--space-small)
}

.search-results-list-results {
  list-style-type: none;
  padding: 0;
  margin: 0
}

.search-results-list-results li {
  border-bottom: 1px solid var(--color--ui-border-01, var(--color--border-01))
}

@media(min-width:769px) {
  .search-results-list-results li {
    padding: var(--space-medium) 0
  }
}

@media(max-width:768px) {
  .search-results-list-results li {
    padding: var(--space-small) 0
  }
}

.search-results-list-results li cite,
.search-results-list-results li time {
  display: none
}

.search-results-list-results li h2 {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-2);
  margin-bottom: 0;
  margin-top: unset
}

.search-results-list-results li *+* {
  margin-top: var(--space-xxsmall)
}

.search-results-list-results li+li {
  margin-top: 0
}

.search-results-list-pages-container {
  margin-top: var(--space-medium)
}

.search-results-list-pages-elements {
  display: flex;
  justify-content: center;
  align-items: center
}

@media(max-width:768px) {
  .search-results-list-pages-elements ol {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    list-style: none;
    padding: unset;
    margin: unset
  }

  .search-results-list-pages-elements ol li:not(:first-child):not(:nth-last-child(2)):not(:last-child) {
    display: none
  }

  .search-results-list-pages-elements ol li+li {
    margin: unset
  }
}

.search-results-list-pages-elements ol li+li {
  margin: unset
}

.search-results-list-pages-elements ol a[aria-disabled="true"] {
  color: var(--color--interactive-disabled-04);
  pointer-events: none;
  color: var(--color--interactive-disabled-01)
}

.search-results-list-pages-elements ol a[aria-disabled="true"]:focus,
.search-results-list-pages-elements ol a[aria-disabled="true"]:focus-visible {
  outline: 0
}

@media(min-width:769px) {
  .search-results-list-pages-elements ol {
    display: flex;
    align-items: center;
    padding: 0;
    margin-top: 0
  }

  .search-results-list-pages-elements ol li {
    list-style-type: none
  }

  .search-results-list-pages-elements ol li+li {
    margin-top: 0;
    margin-left: .375rem
  }

  .search-results-list-pages-elements ol li a {
    font-size: var(--typesize-default);
    font-weight: var(--typeweight--default);
    line-height: var(--paginationLinkSize);
    color: var(--color--interactive-secondary);
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--paginationLinkSize);
    height: var(--paginationLinkSize);
    aspect-ratio: 1;
    position: relative;
    z-index: 1;
    padding: 0
  }

  .search-results-list-pages-elements ol li a::before {
    border-radius: 50%;
    width: var(--paginationLinkSize);
    height: var(--paginationLinkSize);
    background-color: var(--color--ui-06);
    border: .125em solid transparent;
    display: block;
    content: "";
    flex: 0 0 auto;
    position: absolute;
    z-index: -1;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    transform: scale(0);
    transition: all .125s ease-in-out
  }

  .search-results-list-pages-elements ol li a:hover,
  .search-results-list-pages-elements ol li a:active {
    color: currentColor;
    text-decoration: none
  }

  .search-results-list-pages-elements ol li a:hover::before,
  .search-results-list-pages-elements ol li a:active::before {
    transform: scale(1)
  }

  .search-results-list-pages-elements ol li a:focus,
  .search-results-list-pages-elements ol li a:focus-visible {
    color: currentColor;
    outline: 0;
    text-decoration: none
  }

  .search-results-list-pages-elements ol li a:focus::before,
  .search-results-list-pages-elements ol li a:focus-visible::before {
    transform: scale(1);
    border-color: var(--color--interactive-focus-field)
  }

  .search-results-list-pages-elements ol li.search-results-list-pages-selected strong {
    font-size: var(--typesize-default);
    font-weight: var(--typeweight--default);
    line-height: var(--paginationLinkSize);
    color: var(--color--interactive-secondary);
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--paginationLinkSize);
    height: var(--paginationLinkSize);
    aspect-ratio: 1;
    position: relative;
    z-index: 1;
    padding: 0;
    font-weight: var(--typeweight--bold);
    color: var(--color--text-primary-inverse);
    pointer-events: none
  }

  .search-results-list-pages-elements ol li.search-results-list-pages-selected strong::before {
    border-radius: 50%;
    width: var(--paginationLinkSize);
    height: var(--paginationLinkSize);
    background-color: var(--color--ui-06);
    border: .125em solid transparent;
    display: block;
    content: "";
    flex: 0 0 auto;
    position: absolute;
    z-index: -1;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    transform: scale(0);
    transition: all .125s ease-in-out
  }

  .search-results-list-pages-elements ol li.search-results-list-pages-selected strong:hover,
  .search-results-list-pages-elements ol li.search-results-list-pages-selected strong:active {
    color: currentColor;
    text-decoration: none
  }

  .search-results-list-pages-elements ol li.search-results-list-pages-selected strong:hover::before,
  .search-results-list-pages-elements ol li.search-results-list-pages-selected strong:active::before {
    transform: scale(1)
  }

  .search-results-list-pages-elements ol li.search-results-list-pages-selected strong:focus,
  .search-results-list-pages-elements ol li.search-results-list-pages-selected strong:focus-visible {
    color: currentColor;
    outline: 0;
    text-decoration: none
  }

  .search-results-list-pages-elements ol li.search-results-list-pages-selected strong:focus::before,
  .search-results-list-pages-elements ol li.search-results-list-pages-selected strong:focus-visible::before {
    transform: scale(1);
    border-color: var(--color--interactive-focus-field)
  }

  .search-results-list-pages-elements ol li.search-results-list-pages-selected strong::before {
    background-color: var(--color--interactive-secondary);
    transform: none;
    transition: none
  }
}

.search-results-list-pages-elements .search-results-list-pages-prev,
.search-results-list-pages-elements .search-results-list-pages-next {
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  line-height: var(--paginationLinkSize);
  color: var(--color--interactive-secondary);
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--paginationLinkSize);
  height: var(--paginationLinkSize);
  aspect-ratio: 1;
  position: relative;
  z-index: 1;
  padding: 0
}

.search-results-list-pages-elements .search-results-list-pages-prev::before,
.search-results-list-pages-elements .search-results-list-pages-next::before {
  border-radius: 50%;
  width: var(--paginationLinkSize);
  height: var(--paginationLinkSize);
  background-color: var(--color--ui-06);
  border: .125em solid transparent;
  display: block;
  content: "";
  flex: 0 0 auto;
  position: absolute;
  z-index: -1;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  transform: scale(0);
  transition: all .125s ease-in-out
}

.search-results-list-pages-elements .search-results-list-pages-prev:hover,
.search-results-list-pages-elements .search-results-list-pages-next:hover,
.search-results-list-pages-elements .search-results-list-pages-prev:active,
.search-results-list-pages-elements .search-results-list-pages-next:active {
  color: currentColor;
  text-decoration: none
}

.search-results-list-pages-elements .search-results-list-pages-prev:hover::before,
.search-results-list-pages-elements .search-results-list-pages-next:hover::before,
.search-results-list-pages-elements .search-results-list-pages-prev:active::before,
.search-results-list-pages-elements .search-results-list-pages-next:active::before {
  transform: scale(1)
}

.search-results-list-pages-elements .search-results-list-pages-prev:focus,
.search-results-list-pages-elements .search-results-list-pages-next:focus,
.search-results-list-pages-elements .search-results-list-pages-prev:focus-visible,
.search-results-list-pages-elements .search-results-list-pages-next:focus-visible {
  color: currentColor;
  outline: 0;
  text-decoration: none
}

.search-results-list-pages-elements .search-results-list-pages-prev:focus::before,
.search-results-list-pages-elements .search-results-list-pages-next:focus::before,
.search-results-list-pages-elements .search-results-list-pages-prev:focus-visible::before,
.search-results-list-pages-elements .search-results-list-pages-next:focus-visible::before {
  transform: scale(1);
  border-color: var(--color--interactive-focus-field)
}

.search-results-list-pages-elements .search-results-list-pages-prev strong,
.search-results-list-pages-elements .search-results-list-pages-next strong {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  height: 1px;
  width: 1px;
  min-height: unset;
  overflow: hidden;
  position: absolute;
  padding: 0;
  margin: 0
}

.search-results-list-pages-elements .search-results-list-pages-prev {
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  line-height: var(--paginationLinkSize);
  color: var(--color--interactive-secondary);
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--paginationLinkSize);
  height: var(--paginationLinkSize);
  aspect-ratio: 1;
  position: relative;
  z-index: 1;
  padding: 0
}

.search-results-list-pages-elements .search-results-list-pages-prev::before {
  border-radius: 50%;
  width: var(--paginationLinkSize);
  height: var(--paginationLinkSize);
  background-color: var(--color--ui-06);
  border: .125em solid transparent;
  display: block;
  content: "";
  flex: 0 0 auto;
  position: absolute;
  z-index: -1;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  transform: scale(0);
  transition: all .125s ease-in-out
}

.search-results-list-pages-elements .search-results-list-pages-prev:hover,
.search-results-list-pages-elements .search-results-list-pages-prev:active {
  color: currentColor;
  text-decoration: none
}

.search-results-list-pages-elements .search-results-list-pages-prev:hover::before,
.search-results-list-pages-elements .search-results-list-pages-prev:active::before {
  transform: scale(1)
}

.search-results-list-pages-elements .search-results-list-pages-prev:focus,
.search-results-list-pages-elements .search-results-list-pages-prev:focus-visible {
  color: currentColor;
  outline: 0;
  text-decoration: none
}

.search-results-list-pages-elements .search-results-list-pages-prev:focus::before,
.search-results-list-pages-elements .search-results-list-pages-prev:focus-visible::before {
  transform: scale(1);
  border-color: var(--color--interactive-focus-field)
}

.search-results-list-pages-elements .search-results-list-pages-prev::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  margin: auto
}

.search-results-list-pages-elements .search-results-list-pages-next {
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  line-height: var(--paginationLinkSize);
  color: var(--color--interactive-secondary);
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--paginationLinkSize);
  height: var(--paginationLinkSize);
  aspect-ratio: 1;
  position: relative;
  z-index: 1;
  padding: 0
}

.search-results-list-pages-elements .search-results-list-pages-next::before {
  border-radius: 50%;
  width: var(--paginationLinkSize);
  height: var(--paginationLinkSize);
  background-color: var(--color--ui-06);
  border: .125em solid transparent;
  display: block;
  content: "";
  flex: 0 0 auto;
  position: absolute;
  z-index: -1;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  transform: scale(0);
  transition: all .125s ease-in-out
}

.search-results-list-pages-elements .search-results-list-pages-next:hover,
.search-results-list-pages-elements .search-results-list-pages-next:active {
  color: currentColor;
  text-decoration: none
}

.search-results-list-pages-elements .search-results-list-pages-next:hover::before,
.search-results-list-pages-elements .search-results-list-pages-next:active::before {
  transform: scale(1)
}

.search-results-list-pages-elements .search-results-list-pages-next:focus,
.search-results-list-pages-elements .search-results-list-pages-next:focus-visible {
  color: currentColor;
  outline: 0;
  text-decoration: none
}

.search-results-list-pages-elements .search-results-list-pages-next:focus::before,
.search-results-list-pages-elements .search-results-list-pages-next:focus-visible::before {
  transform: scale(1);
  border-color: var(--color--interactive-focus-field)
}

.search-results-list-pages-elements .search-results-list-pages-next::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  transform: rotate(180deg);
  margin: auto
}

@media(max-width:768px) {
  .search-results-list-pages-mobile {
    color: var(--color--interactive-secondary);
    display: inline-flex;
    line-height: 1;
    padding: 0 var(--space-xxsmall);
    margin-top: 0
  }
}

@media(min-width:769px) {
  .search-results-list-pages-mobile {
    display: none
  }
}

main .search-box form[role="search"] {
  display: inline-flex;
  position: relative;
  width: 100%
}

main .search-box-input[type="search"] {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  color: var(--color--text-secondary);
  appearance: unset;
  border: unset;
  outline-offset: unset;
  outline-width: 1px;
  outline-style: solid;
  outline-color: var(--color--ui-border-03, var(--color--border-03));
  display: block;
  border-radius: var(--corners-semiround-pill);
  transition: all .1125s ease-in-out;
  height: 40px;
  width: 100%;
  padding: 0 42px 0 24px
}

main .search-box-input[type="search"]::placeholder {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  color: var(--color--text-secondary)
}

main .search-box-input[type="search"]::-webkit-search-cancel-button {
  display: none
}

main .search-box-input[type="search"]:hover+[type="submit"] {
  background-color: var(--color--interactive-primary-hover)
}

main .search-box-input[type="search"]:active+[type="submit"] {
  background-color: var(--color--interactive-primary-active)
}

main .search-box-input[type="search"]:focus,
main .search-box-input[type="search"]:focus-visible {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field);
  outline-offset: -2px
}

main .search-box-input[type="search"]:focus+[type="submit"],
main .search-box-input[type="search"]:focus-visible+[type="submit"] {
  background-color: var(--color--interactive-primary-hover)
}

main .search-box-icon[type="submit"] {
  appearance: unset;
  border: unset;
  padding: unset;
  color: var(--color--text-primary-inverse);
  height: 2rem;
  width: 2rem;
  aspect-ratio: 1;
  border-radius: 50%;
  transition: all .2s ease-in-out;
  background-color: var(--color--interactive-primary);
  display: inline-flex;
  position: absolute;
  right: 4px;
  bottom: 4px
}

main .search-box-icon[type="submit"]:focus,
main .search-box-icon[type="submit"]:focus-within,
main .search-box-icon[type="submit"]:active {
  background-color: var(--color--interactive-primary-active);
  outline: unset
}

main .search-box-icon[type="submit"]::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-sm");
  color: var(--color--icon-primary-inverse);
  margin: auto
}

main .c-search-box__reset {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: unset;
  background-image: unset;
  background-repeat: unset;
  background-size: unset;
  margin-top: 0;
  margin-right: 0;
  transition: none;
  height: 24px;
  width: 24px;
  background: unset;
  position: absolute;
  right: 8px;
  bottom: 8px
}

main .c-search-box__reset:hover,
main .c-search-box__reset:focus {
  outline: 0;
  background-color: transparent
}

main .c-search-box__reset::before {
  display: block;
  content: "";
  flex: 0 0 auto;
  border-radius: 50%;
  width: inherit;
  height: inherit;
  background-color: var(--color--ui-01);
  position: absolute;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  transform: scale(0);
  transition: transform .125s ease-in-out;
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent
}

main .c-search-box__reset::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg");
  transform: none;
  top: unset;
  left: unset;
  border-radius: 0
}

main .c-search-box__reset:hover::before,
main .c-search-box__reset:focus::before,
main .c-search-box__reset:focus-visible::before,
main .c-search-box__reset:active::before {
  transform: scale(1)
}

main .c-search-box__reset:focus,
main .c-search-box__reset:focus-visible {
  outline: 0
}

main .c-search-box__reset:focus::before,
main .c-search-box__reset:focus-visible::before {
  outline-color: var(--color--interactive-focus-field)
}

@media(forced-colors:active) and (prefers-color-scheme:dark) {

  main .c-search-box__reset:focus,
  main .c-search-box__reset:focus-visible {
    outline-width: 2px;
    outline-style: solid;
    outline-offset: 1px
  }

  main .c-search-box__reset::after {
    filter: brightness(0) invert(1)
  }

  main .c-search-box__reset:hover::after,
  main .c-search-box__reset:focus::after,
  main .c-search-box__reset:focus-visible::after,
  main .c-search-box__reset:active::after {
    filter: unset
  }

  main .c-search-box__reset::before {
    outline-color: currentColor;
    outline-offset: -2px;
    transform: unset;
    opacity: unset;
    background-color: unset
  }

  main .c-search-box__reset:hover::before,
  main .c-search-box__reset:focus::before,
  main .c-search-box__reset:focus-visible::before,
  main .c-search-box__reset:active::before {
    background-color: currentColor;
    filter: brightness(0) invert(1)
  }
}

@media(forced-colors:active) and (prefers-color-scheme:light) {
  main .c-search-box__reset {
    border-radius: 50%;
    outline-width: 2px;
    outline-style: solid;
    outline-color: currentColor
  }

  main .c-search-box__reset:hover,
  main .c-search-box__reset:focus,
  main .c-search-box__reset:focus-visible,
  main .c-search-box__reset:active {
    outline-width: 2px;
    outline-style: solid;
    outline-color: currentColor
  }

  main .c-search-box__reset::after {
    filter: brightness(1) invert(1)
  }

  main .c-search-box__reset:hover::after,
  main .c-search-box__reset:focus::after,
  main .c-search-box__reset:focus-visible::after,
  main .c-search-box__reset:active::after {
    filter: unset
  }

  main .c-search-box__reset:hover::before,
  main .c-search-box__reset:focus::before,
  main .c-search-box__reset:focus-visible::before,
  main .c-search-box__reset:active::before {
    filter: brightness(1) invert(1);
    outline: 0
  }
}

main .c-search-box__reset::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md")
}

main .c-search-box__reset--text {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  height: 1px;
  width: 1px;
  min-height: unset;
  overflow: hidden;
  position: absolute;
  padding: 0;
  margin: 0
}

:root {
  --inpn-transition: .125s ease-in-out;
  --inpn-bg-color: var(--color--ui-background-light, var(--color--background-light));
  --inpn-top: calc(var(--he-header-min-h, 0) - 1px);
  --inpn-min-h: 55px;
  --inpn-gap: 5px;
  --inpn-active-line-color: var(--color--interactive-primary);
  --inpn-dropdown-display: flex;
  --inpn-dropdown-btn-display: none;
  --inpn-dropdown-flow: row;
  --inpn-link-h: 100%;
  --inpn-link-justify: center;
  --inpn-link-hover-bg: none;
  --inpn-li-w: auto;
  --inpn-li-min-w: 16.666%;
  --inpn-li-m: 0;
  --inpn-ul-position: relative;
  --inpn-ul-t: 0;
  --inpn-ul-w: calc(100% - 110px);
  --inpn-ul-m: 0 calc(var(--inpn-gap) / 2 * -1);
  --inpn-ul-mh: 400px;
  --inpn-ul-shadow: none;
  --inpn-ul-bdradius: 0;
  --inpn-mobile-arrow-rotation: 0;
  --inpn-scroll-btn-w: 35px;
  --inpn-scroll-btn-radius: 8px
}

body.inpn-page-state__crossed {
  --inpn-top: 0
}

body.inpn-page-state__crossed.inpn-page-state__top {
  --inpn-top: calc(var(--he-header-min-h, 0) - 1px)
}

@media screen and (max-width:992px) {
  .inpage-sticky-navigation {
    --inpn-li-min-w: 33.333%;
    --inpn-ul-w: 100%
  }
}

@media screen and (max-width:767px) {
  .inpage-sticky-navigation {
    --inpn-dropdown-display: none;
    --inpn-dropdown-btn-display: flex;
    --inpn-dropdown-flow: column;
    --inpn-link-h: 50px;
    --inpn-link-justify: flex-start;
    --inpn-link-hover-bg: var(--color--ui-01);
    --inpn-li-w: 100%;
    --inpn-li-m: 0;
    --inpn-ul-position: absolute;
    --inpn-ul-m: 0 auto;
    --inpn-ul-w: calc(100% - 18px);
    --inpn-ul-t: 100%;
    --inpn-ul-shadow: var(--shadow);
    --inpn-ul-bdradius: 4px
  }
}

body {
  overflow-x: unset !important;
  overflow-y: unset !important
}

.inpage-sticky-navigation {
  display: flex;
  justify-content: center;
  position: sticky;
  width: 100%;
  top: var(--inpn-top);
  background-color: var(--inpn-bg-color);
  box-shadow: var(--shadow);
  z-index: 3;
  transition: top var(--inpn-transition)
}

.inpage-sticky-navigation .inpn-button {
  --inpn-btn-bg-opacity: 0;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: var(--inpn-scroll-btn-w);
  padding: 0;
  background: 0;
  border: 0
}

.inpage-sticky-navigation .inpn-button::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: var(--color--icon-tertiary-inverse);
  border-color: var(--color--ui-border-01, var(--color--border-01));
  border-style: solid;
  opacity: var(--inpn-btn-bg-opacity);
  transition: opacity var(--inpn-transition)
}

.inpage-sticky-navigation .inpn-button::after {
  color: var(--color--icon-tertiary)
}

.inpage-sticky-navigation .inpn-button-left {
  margin-right: calc(-1 * var(--inpn-scroll-btn-w))
}

.inpage-sticky-navigation .inpn-button-left::before {
  border-width: 1px 1px 1px 0;
  border-radius: 0 var(--inpn-scroll-btn-radius) var(--inpn-scroll-btn-radius) 0
}

.inpage-sticky-navigation .inpn-button-left::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-md")
}

.inpage-sticky-navigation .inpn-button-right {
  margin-left: calc(-1 * var(--inpn-scroll-btn-w))
}

.inpage-sticky-navigation .inpn-button-right::before {
  border-width: 1px 0 1px 1px;
  border-radius: var(--inpn-scroll-btn-radius) 0 0 var(--inpn-scroll-btn-radius)
}

.inpage-sticky-navigation .inpn-button-right::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-md");
  transform: rotate(180deg)
}

.inpage-sticky-navigation .inpn-button-inactive {
  display: none
}

.inpage-sticky-navigation .inpn-button:hover {
  --inpn-btn-bg-opacity: 1
}

.inpage-sticky-navigation .in-page-nav--dropdown {
  display: var(--inpn-dropdown-btn-display);
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--spacing-20);
  width: 100%;
  height: var(--inpn-min-h);
  background-color: transparent;
  border: 0;
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-default);
  color: var(--color--text-secondary);
  text-decoration: none;
  line-height: var(--leading--1-6);
  font-weight: var(--typeweight--default);
  font-weight: var(--typeweight--semibold)
}

.inpage-sticky-navigation .in-page-nav--dropdown::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  transform: rotate(var(--inpn-mobile-arrow-rotation));
  transition: transform var(--inpn-transition)
}

.inpage-sticky-navigation .in-page-nav--dropdown::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: 0;
  left: 0;
  border-radius: 2px 2px 0 0;
  background-color: var(--inpn-active-line-color);
  opacity: 1
}

.inpage-sticky-navigation>ul {
  --grid-margin-sm: 30px;
  --grid-margin-md: 55px;
  --grid-margin-lg: 75px;
  max-width: 100%;
  position: var(--inpn-ul-position);
  display: var(--inpn-dropdown-display);
  flex-flow: var(--inpn-dropdown-flow);
  margin: var(--inpn-ul-m);
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-height: var(--inpn-min-h);
  width: var(--inpn-ul-w);
  list-style: none;
  background-color: var(--inpn-bg-color);
  max-height: var(--inpn-ul-mh);
  overflow-y: auto;
  box-shadow: var(--inpn-ul-shadow);
  top: var(--inpn-ul-t);
  left: 0;
  right: 0;
  border-radius: 0 0 var(--inpn-ul-bdradius) var(--inpn-ul-bdradius);
  overflow: hidden;
  scroll-behavior: smooth
}

@media(max-width:768px) {
  .inpage-sticky-navigation>ul {
    padding-inline-end: var(--grid-margin-sm);
    padding-inline-start: var(--grid-margin-sm)
  }
}

@media(min-width:769px) and (max-width:1589px) {
  .inpage-sticky-navigation>ul {
    padding-inline-end: var(--grid-margin-md);
    padding-inline-start: var(--grid-margin-md)
  }
}

@media(min-width:1590px) {
  .inpage-sticky-navigation>ul {
    padding-inline-end: var(--grid-margin-lg);
    padding-inline-start: var(--grid-margin-lg);
    max-width: 1590px
  }
}

.inpage-sticky-navigation>ul>li {
  padding: 0;
  align-items: center;
  flex-grow: 1;
  margin: var(--inpn-li-m);
  width: var(--inpn-li-w);
  min-width: var(--inpn-li-min-w)
}

.inpage-sticky-navigation>ul>li>a {
  display: flex;
  position: relative;
  width: 100%;
  height: var(--inpn-link-h);
  align-items: center;
  justify-content: var(--inpn-link-justify);
  text-align: center;
  padding: 0 var(--spacing-20);
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-default);
  color: var(--color--text-secondary);
  text-decoration: none;
  line-height: var(--leading--1-6);
  font-weight: var(--typeweight--default)
}

.inpage-sticky-navigation>ul>li>a::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: 0;
  left: 0;
  border-radius: 2px 2px 0 0;
  background-color: var(--inpn-active-line-color);
  opacity: 0;
  transition: opacity var(--inpn-transition)
}

.inpage-sticky-navigation>ul>li>a.active {
  font-weight: var(--typeweight--semibold)
}

.inpage-sticky-navigation>ul>li>a.active::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: 0;
  left: 0;
  border-radius: 2px 2px 0 0;
  background-color: var(--inpn-active-line-color);
  opacity: 1
}

.inpage-sticky-navigation>ul>li>a:active,
.inpage-sticky-navigation>ul>li>a:hover,
.inpage-sticky-navigation>ul>li>a:focus {
  background-color: var(--inpn-link-hover-bg)
}

.inpage-sticky-navigation>ul>li>a:focus-visible {
  outline-offset: -2px
}

.inpage-sticky-navigation.is-open {
  --inpn-dropdown-display: flex;
  --inpn-mobile-arrow-rotation: 180deg
}

@media screen and (max-width:767px) {
  .inpage-sticky-navigation>ul {
    padding: 0
  }

  .inpage-sticky-navigation>ul>li>a.active {
    font-weight: var(--typeweight--default)
  }

  .inpage-sticky-navigation>ul>li>a.active::after {
    content: unset
  }
}

*+.responsive-iframe {
  margin-top: var(--space-small)
}

.responsive-iframe-form {
  border: unset
}

.responsive-iframe-form[src*="go.pardot.com"] {
  min-height: 750px
}

.dynamic-content-list {
  --cols: 4;
  --gap: var(--space-medium);
  --cardWidth: 190px
}

.dynamic-content-list h2 {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h3);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

.dynamic-content-list ol {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(var(--cardWidth), (100% - 1*(var(--cols) - 1)*var(--gap))/var(--cols)), 1fr));
  gap: var(--gap);
  padding: var(--space-zero);
  margin: 0
}

.dynamic-content-list ol>li {
  display: inline-flex;
  flex-direction: column
}

.dynamic-content-list ol>li+li {
  margin: unset
}

.dynamic-content-list ol article,
.dynamic-content-list ol div {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  row-gap: 15px
}

.dynamic-content-list ol div:not(.no-image) {
  padding: 0 var(--space-small)
}

@media(max-width:375px) {
  .dynamic-content-list ol div:not(.no-image) {
    padding: unset
  }
}

.dynamic-content-list ol h4 {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-p);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default);
  font-weight: var(--typeweight--semibold);
  color: inherit
}

.dynamic-content-list ol img {
  border-radius: var(--corners-semiround-md);
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 2;
  object-fit: cover
}

.dynamic-content-list ol time {
  font-size: var(--typesize-minor);
  font-weight: var(--typeweight--semibold);
  border-top: 1px solid var(--color--ui-border-01, var(--color--border-01));
  order: 3;
  padding-top: var(--space-small);
  margin-top: auto
}

@media(max-width:767px) {
  .dynamic-content-list *+ol {
    margin-top: var(--space-small)
  }
}

@media(min-width:768px) {
  .dynamic-content-list *+ol {
    margin-top: var(--space-medium)
  }
}

@media(max-width:767px) {
  *+.dynamic-content-list {
    margin-top: var(--space-small)
  }
}

@media(min-width:768px) {
  *+.dynamic-content-list {
    margin-top: var(--space-medium)
  }
}

@supports selector(:not(:has(img))) {
  .dynamic-content-list>ol:not(:has(img)) {
    gap: unset;
    column-gap: var(--space-medium);
    row-gap: var(--space-xsmall)
  }

  .dynamic-content-list>ol>li:not(:has(time)):not(:has(img))::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background-color: var(--color--ui-border-01, var(--color--border-01));
    margin-top: var(--space-xsmall)
  }
}

@keyframes slidein {
  from {
    opacity: 0;
    transform: translate(0, 20px)
  }

  to {
    opacity: 1;
    transform: translate(0, 0)
  }
}

@keyframes fadeIn {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

.content-tile[class*="content-tile-landscape--"] figure,
.content-tile[class*="content-tile-landscape--"] .content-tile__figure,
.content-tile[class*="content-tile-landscape--"] figure>a,
.content-tile[class*="content-tile-landscape--"] .content-tile__figure>a {
  display: flex
}

@media(min-width:768px) {

  .content-tile[class*="content-tile-landscape--"] figure,
  .content-tile[class*="content-tile-landscape--"] .content-tile__figure,
  .content-tile[class*="content-tile-landscape--"] figure>a,
  .content-tile[class*="content-tile-landscape--"] .content-tile__figure>a {
    flex: 0 0 100%;
    max-width: 100%
  }
}

@media(max-width:767px) {

  .content-tile[class*="content-tile-landscape--"] figure,
  .content-tile[class*="content-tile-landscape--"] .content-tile__figure,
  .content-tile[class*="content-tile-landscape--"] figure>a,
  .content-tile[class*="content-tile-landscape--"] .content-tile__figure>a {
    display: flex;
    flex-direction: column
  }

  .content-tile[class*="content-tile-landscape--"] picture {
    order: 1;
    padding-right: 0;
    padding-left: 0;
    flex: 1 1 auto;
    max-width: 100%
  }

  .content-tile[class*="content-tile-landscape--"] picture>img {
    width: 100%;
    height: auto
  }

  .content-tile[class*="content-tile-landscape--"] figcaption,
  .content-tile[class*="content-tile-landscape--"] .content-tile__figcaption {
    order: 2;
    padding-top: 15px;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    flex: 1 1 auto;
    max-width: 100%
  }
}

@media(min-width:768px) {

  .content-tile[class*="content-tile-landscape--"].vertically-centered picture,
  .content-tile[class*="content-tile-landscape--"].vertically-centered figcaption,
  .content-tile[class*="content-tile-landscape--"].vertically-centered .content-tile__figcaption {
    display: flex;
    flex-direction: column;
    justify-content: center
  }

  .content-tile[class*="content-tile-landscape--"].vertically-centered picture img {
    flex: none
  }
}

@media(min-width:768px) {

  .content-tile[class*="content-tile-landscape--"].vertical-bottom picture,
  .content-tile[class*="content-tile-landscape--"].vertical-bottom figcaption,
  .content-tile[class*="content-tile-landscape--"].vertical-bottom .content-tile__figcaption {
    display: flex;
    flex-direction: column;
    justify-content: end
  }

  .content-tile[class*="content-tile-landscape--"].vertical-bottom picture img {
    flex: none
  }
}

@media(min-width:768px) {
  .content-tile[class*="content-tile-landscape--"].full-height-image picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center
  }

  .content-tile[class*="content-tile-landscape--"].full-height-image figcaption,
  .content-tile[class*="content-tile-landscape--"].full-height-image .content-tile__figcaption {
    display: flex;
    flex-direction: column;
    justify-content: center
  }
}

@media(max-width:768px) {
  .content-tile.image-offset-right picture {
    transform: translateX(calc(var(--grid-margin-sm) + 10vw))
  }
}

@media(min-width:769px) and (max-width:1589px) {
  .content-tile.image-offset-right picture {
    transform: translateX(calc(var(--grid-margin-md) + 5vw))
  }
}

@media(min-width:1590px) {
  .content-tile.image-offset-right picture {
    transform: translateX(calc(var(--grid-margin-lg) + 5vw))
  }
}

@media(max-width:768px) {
  .content-tile.image-offset-left picture {
    transform: translateX(calc(-1 * (var(--grid-margin-sm) + 10vw)))
  }
}

@media(min-width:769px) and (max-width:1589px) {
  .content-tile.image-offset-left picture {
    transform: translateX(calc(-1 * (var(--grid-margin-md) + 5vw)))
  }
}

@media(min-width:1590px) {
  .content-tile.image-offset-left picture {
    transform: translateX(calc(-1 * (var(--grid-margin-lg) + 5vw)))
  }
}

@media(max-width:768px) {

  .content-tile[class*="image-offset-"] figure,
  .content-tile[class*="image-offset-"] figure>a,
  .content-tile[class*="image-offset-"] .content-tile__figure,
  .content-tile[class*="image-offset-"] .content-tile__figure>a {
    display: flex;
    flex-direction: column
  }

  .content-tile[class*="image-offset-"] picture {
    order: 1;
    padding-right: 0;
    padding-left: 0;
    flex: 1 1 auto;
    max-width: 100%;
    order: 2
  }

  .content-tile[class*="image-offset-"] picture>img {
    width: 100%;
    height: auto
  }

  .content-tile[class*="image-offset-"] figcaption,
  .content-tile[class*="image-offset-"] .content-tile__figcaption {
    order: 2;
    padding-top: 15px;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    flex: 1 1 auto;
    max-width: 100%;
    order: 1
  }
}

@media(min-width:376px) and (max-width:768px) {

  .content-tile[class*="image-offset-"] figcaption,
  .content-tile[class*="image-offset-"] .content-tile__figcaption {
    padding-top: 0;
    margin-bottom: var(--spacing-60)
  }
}

@media(max-width:375px) {

  .content-tile[class*="image-offset-"] figcaption,
  .content-tile[class*="image-offset-"] .content-tile__figcaption {
    padding-top: 0;
    margin-bottom: var(--spacing-30)
  }
}

.content-tile-landscape--50-50 picture {
  flex: 0 0 50%;
  max-width: 50%
}

@media(min-width:769px) {
  .content-tile-landscape--50-50 picture {
    padding-right: var(--space-xsmall)
  }
}

@media(max-width:768px) {
  .content-tile-landscape--50-50 picture {
    padding-right: var(--space-xxsmall)
  }
}

.content-tile-landscape--50-50 figcaption,
.content-tile-landscape--50-50 .content-tile__figcaption {
  flex: 0 0 50%;
  max-width: 50%
}

@media(min-width:769px) {

  .content-tile-landscape--50-50 figcaption,
  .content-tile-landscape--50-50 .content-tile__figcaption {
    padding-left: var(--space-xsmall)
  }
}

@media(max-width:768px) {

  .content-tile-landscape--50-50 figcaption,
  .content-tile-landscape--50-50 .content-tile__figcaption {
    padding-left: var(--space-xxsmall)
  }
}

.content-tile-landscape--50-50-reverse picture {
  order: 2;
  flex: 0 0 50%;
  max-width: 50%
}

@media(min-width:769px) {
  .content-tile-landscape--50-50-reverse picture {
    padding-left: var(--space-xsmall)
  }
}

@media(max-width:768px) {
  .content-tile-landscape--50-50-reverse picture {
    padding-left: var(--space-xxsmall)
  }
}

.content-tile-landscape--50-50-reverse figcaption,
.content-tile-landscape--50-50-reverse .content-tile__figcaption {
  order: 1;
  flex: 0 0 50%;
  max-width: 50%
}

@media(min-width:769px) {

  .content-tile-landscape--50-50-reverse figcaption,
  .content-tile-landscape--50-50-reverse .content-tile__figcaption {
    padding-right: var(--space-xsmall)
  }
}

@media(max-width:768px) {

  .content-tile-landscape--50-50-reverse figcaption,
  .content-tile-landscape--50-50-reverse .content-tile__figcaption {
    padding-right: var(--space-xxsmall)
  }
}

.content-tile-landscape--40-60 picture {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%
}

@media(min-width:769px) {
  .content-tile-landscape--40-60 picture {
    padding-right: var(--space-xsmall)
  }
}

@media(max-width:768px) {
  .content-tile-landscape--40-60 picture {
    padding-right: var(--space-xxsmall)
  }
}

.content-tile-landscape--40-60 figcaption,
.content-tile-landscape--40-60 .content-tile__figcaption {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%
}

@media(min-width:769px) {

  .content-tile-landscape--40-60 figcaption,
  .content-tile-landscape--40-60 .content-tile__figcaption {
    padding-left: var(--space-xsmall)
  }
}

@media(max-width:768px) {

  .content-tile-landscape--40-60 figcaption,
  .content-tile-landscape--40-60 .content-tile__figcaption {
    padding-left: var(--space-xxsmall)
  }
}

.content-tile-landscape--33-66 picture {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%
}

@media(min-width:769px) {
  .content-tile-landscape--33-66 picture {
    padding-right: var(--space-xsmall)
  }
}

@media(max-width:768px) {
  .content-tile-landscape--33-66 picture {
    padding-right: var(--space-xxsmall)
  }
}

.content-tile-landscape--33-66 figcaption,
.content-tile-landscape--33-66 .content-tile__figcaption {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%
}

@media(min-width:769px) {

  .content-tile-landscape--33-66 figcaption,
  .content-tile-landscape--33-66 .content-tile__figcaption {
    padding-left: var(--space-xsmall)
  }
}

@media(max-width:768px) {

  .content-tile-landscape--33-66 figcaption,
  .content-tile-landscape--33-66 .content-tile__figcaption {
    padding-left: var(--space-xxsmall)
  }
}

.content-tile-landscape--25-75 picture {
  flex: 0 0 25%;
  max-width: 25%
}

@media(min-width:769px) {
  .content-tile-landscape--25-75 picture {
    padding-right: var(--space-xsmall)
  }
}

@media(max-width:768px) {
  .content-tile-landscape--25-75 picture {
    padding-right: var(--space-xxsmall)
  }
}

.content-tile-landscape--25-75 figcaption,
.content-tile-landscape--25-75 .content-tile__figcaption {
  flex: 0 0 75%;
  max-width: 75%
}

@media(min-width:769px) {

  .content-tile-landscape--25-75 figcaption,
  .content-tile-landscape--25-75 .content-tile__figcaption {
    padding-left: var(--space-xsmall)
  }
}

@media(max-width:768px) {

  .content-tile-landscape--25-75 figcaption,
  .content-tile-landscape--25-75 .content-tile__figcaption {
    padding-left: var(--space-xxsmall)
  }
}

.content-tile-landscape--17-83 picture {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%
}

@media(min-width:769px) {
  .content-tile-landscape--17-83 picture {
    padding-right: var(--space-xsmall)
  }
}

@media(max-width:768px) {
  .content-tile-landscape--17-83 picture {
    padding-right: var(--space-xxsmall)
  }
}

.content-tile-landscape--17-83 figcaption,
.content-tile-landscape--17-83 .content-tile__figcaption {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%
}

@media(min-width:769px) {

  .content-tile-landscape--17-83 figcaption,
  .content-tile-landscape--17-83 .content-tile__figcaption {
    padding-left: var(--space-xsmall)
  }
}

@media(max-width:768px) {

  .content-tile-landscape--17-83 figcaption,
  .content-tile-landscape--17-83 .content-tile__figcaption {
    padding-left: var(--space-xxsmall)
  }
}

.content-tile-landscape--60-40 picture {
  order: 2;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%
}

@media(min-width:769px) {
  .content-tile-landscape--60-40 picture {
    padding-left: var(--space-xsmall)
  }
}

@media(max-width:768px) {
  .content-tile-landscape--60-40 picture {
    padding-left: var(--space-xxsmall)
  }
}

.content-tile-landscape--60-40 figcaption,
.content-tile-landscape--60-40 .content-tile__figcaption {
  order: 1;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%
}

@media(min-width:769px) {

  .content-tile-landscape--60-40 figcaption,
  .content-tile-landscape--60-40 .content-tile__figcaption {
    padding-right: var(--space-xsmall)
  }
}

@media(max-width:768px) {

  .content-tile-landscape--60-40 figcaption,
  .content-tile-landscape--60-40 .content-tile__figcaption {
    padding-right: var(--space-xxsmall)
  }
}

.content-tile-landscape--66-33 picture {
  order: 2;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%
}

@media(min-width:769px) {
  .content-tile-landscape--66-33 picture {
    padding-left: var(--space-xsmall)
  }
}

@media(max-width:768px) {
  .content-tile-landscape--66-33 picture {
    padding-left: var(--space-xxsmall)
  }
}

.content-tile-landscape--66-33 figcaption,
.content-tile-landscape--66-33 .content-tile__figcaption {
  order: 1;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%
}

@media(min-width:769px) {

  .content-tile-landscape--66-33 figcaption,
  .content-tile-landscape--66-33 .content-tile__figcaption {
    padding-right: var(--space-xsmall)
  }
}

@media(max-width:768px) {

  .content-tile-landscape--66-33 figcaption,
  .content-tile-landscape--66-33 .content-tile__figcaption {
    padding-right: var(--space-xxsmall)
  }
}

.content-tile-landscape--75-25 picture {
  order: 2;
  flex: 0 0 25%;
  max-width: 25%
}

@media(min-width:769px) {
  .content-tile-landscape--75-25 picture {
    padding-left: var(--space-xsmall)
  }
}

@media(max-width:768px) {
  .content-tile-landscape--75-25 picture {
    padding-left: var(--space-xxsmall)
  }
}

.content-tile-landscape--75-25 figcaption,
.content-tile-landscape--75-25 .content-tile__figcaption {
  order: 1;
  flex: 0 0 75%;
  max-width: 75%
}

@media(min-width:769px) {

  .content-tile-landscape--75-25 figcaption,
  .content-tile-landscape--75-25 .content-tile__figcaption {
    padding-right: var(--space-xsmall)
  }
}

@media(max-width:768px) {

  .content-tile-landscape--75-25 figcaption,
  .content-tile-landscape--75-25 .content-tile__figcaption {
    padding-right: var(--space-xxsmall)
  }
}

.content-tile-landscape--83-17 picture {
  order: 2;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%
}

@media(min-width:769px) {
  .content-tile-landscape--83-17 picture {
    padding-left: var(--space-xsmall)
  }
}

@media(max-width:768px) {
  .content-tile-landscape--83-17 picture {
    padding-left: var(--space-xxsmall)
  }
}

.content-tile-landscape--83-17 figcaption,
.content-tile-landscape--83-17 .content-tile__figcaption {
  order: 1;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%
}

@media(min-width:769px) {

  .content-tile-landscape--83-17 figcaption,
  .content-tile-landscape--83-17 .content-tile__figcaption {
    padding-right: var(--space-xsmall)
  }
}

@media(max-width:768px) {

  .content-tile-landscape--83-17 figcaption,
  .content-tile-landscape--83-17 .content-tile__figcaption {
    padding-right: var(--space-xxsmall)
  }
}

.content-tile.title-typesize--exa .content-tile__title {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-exa);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-1)
}

.content-tile.title-typesize--peta .content-tile__title {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-peta);
  font-weight: var(--typeweight--light);
  line-height: var(--leading--1-2)
}

.content-tile.title-typesize--tera .content-tile__title {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-tera);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2)
}

.content-tile.title-typesize--giga .content-tile__title {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-giga);
  font-weight: var(--typeweight--light);
  line-height: var(--leading--1-2)
}

.content-tile.title-typesize--mega .content-tile__title {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-mega);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-2)
}

.content-tile.title-typesize--kilo .content-tile__title {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-kilo);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2)
}

.content-tile.title-typesize--h1 .content-tile__title {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h1);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-15);
  margin-bottom: 0
}

.typefamily-primary .content-tile.title-typesize--h1 .content-tile__title {
  font-weight: var(--typeweight--light)
}

.content-tile.title-typesize--h2 .content-tile__title {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h2);
  font-weight: var(--typeweight--light);
  line-height: var(--leading--1-3);
  margin-bottom: 0
}

.content-tile.title-typesize--h3 .content-tile__title {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h3);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

.content-tile.title-typesize--h4 .content-tile__title {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-h4);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

.content-tile.title-typesize--h5 .content-tile__title {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-h5);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

.content-tile.title-typesize--h6 .content-tile__title {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

.content-tile.title-typesize--p .content-tile__title {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-p);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default)
}

.content-tile.title-typefamily--primary .content-tile__title {
  font-family: var(--typefamily-primary)
}

.content-tile.title-typefamily--alt .content-tile__title {
  font-family: var(--typefamily-alt)
}

.content-tile.title-typeweight--light .content-tile__title {
  font-weight: var(--typeweight--light)
}

.content-tile.title-typeweight--default .content-tile__title {
  font-weight: var(--typeweight--default)
}

.content-tile.title-typeweight--medium .content-tile__title {
  font-weight: var(--typeweight--medium)
}

.content-tile.title-typeweight--semibold .content-tile__title {
  font-weight: var(--typeweight--semibold-plus)
}

.content-tile.title-typeweight--bold .content-tile__title {
  font-weight: var(--typeweight--bold)
}

.content-tile.title-color--text-primary.title-color--text-primary .content-tile__title {
  color: var(--color--text-primary)
}

.content-tile.title-color--text-secondary.title-color--text-secondary .content-tile__title {
  color: var(--color--text-secondary)
}

.content-tile.title-color--text-tertiary.title-color--text-tertiary .content-tile__title {
  color: var(--color--text-tertiary)
}

.content-tile.title-color--text-primary-inverse.title-color--text-primary-inverse .content-tile__title {
  color: var(--color--text-primary-inverse)
}

.content-tile.title-color--text-secondary-inverse.title-color--text-secondary-inverse .content-tile__title {
  color: var(--color--text-secondary-inverse)
}

.content-tile.title-color--text-tertiary-inverse.title-color--text-tertiary-inverse .content-tile__title {
  color: var(--color--text-tertiary-inverse)
}

.content-tile.subtitle-typesize--h2 .content-tile-subtitle {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h2);
  font-weight: var(--typeweight--light);
  line-height: var(--leading--1-3);
  margin-bottom: 0
}

.content-tile.subtitle-typesize--h3 .content-tile-subtitle {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h3);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

.content-tile.subtitle-typesize--h4 .content-tile-subtitle {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-h4);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

.content-tile.subtitle-typesize--h5 .content-tile-subtitle {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-h5);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

.content-tile.subtitle-typesize--h6 .content-tile-subtitle {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

.content-tile.subtitle-typesize--p .content-tile-subtitle {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-p);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default)
}

.content-tile.subtitle-typefamily--primary .content-tile-subtitle {
  font-family: var(--typefamily-primary)
}

.content-tile.subtitle-typefamily--alt .content-tile-subtitle {
  font-family: var(--typefamily-alt)
}

.content-tile.subtitle-typeweight--light .content-tile-subtitle {
  font-weight: var(--typeweight--light)
}

.content-tile.subtitle-typeweight--default .content-tile-subtitle {
  font-weight: var(--typeweight--default)
}

.content-tile.subtitle-typeweight--semibold .content-tile-subtitle {
  font-weight: var(--typeweight--semibold-plus)
}

.content-tile.subtitle-typeweight--bold .content-tile-subtitle {
  font-weight: var(--typeweight--bold)
}

.content-tile.subtitle-color--text-primary.subtitle-color-color--text-primary .content-tile-subtitle {
  color: var(--color--text-primary)
}

.content-tile.subtitle-color--text-secondary.subtitle-color--text-secondary .content-tile-subtitle {
  color: var(--color--text-secondary)
}

.content-tile.subtitle-color--text-tertiary.subtitle-color--text-tertiary .content-tile-subtitle {
  color: var(--color--text-tertiary)
}

.content-tile.subtitle-color--text-primary-inverse.subtitle-color--text-primary-inverse .content-tile-subtitle {
  color: var(--color--text-primary-inverse)
}

.content-tile.subtitle-color--text-secondary-inverse.subtitle-color--text-secondary-inverse .content-tile-subtitle {
  color: var(--color--text-secondary-inverse)
}

.content-tile.subtitle-color--text-tertiary-inverse.subtitle-color--text-tertiary-inverse .content-tile-subtitle {
  color: var(--color--text-tertiary-inverse)
}

.content-tile.pad--0__figure figure,
.content-tile.pad--0__figure .content-tile__figure {
  padding: 0
}

@media(min-width:769px) {

  .content-tile.pad--15__figure figure,
  .content-tile.pad--15__figure .content-tile__figure {
    padding: var(--space-xsmall)
  }
}

@media(max-width:768px) {

  .content-tile.pad--15__figure figure,
  .content-tile.pad--15__figure .content-tile__figure {
    padding: var(--space-xxsmall)
  }
}

@media(min-width:769px) {

  .content-tile.pad--30__figure figure,
  .content-tile.pad--30__figure .content-tile__figure {
    padding: var(--space-medium)
  }
}

@media(max-width:768px) {

  .content-tile.pad--30__figure figure,
  .content-tile.pad--30__figure .content-tile__figure {
    padding: var(--space-small)
  }
}

@media(min-width:769px) {

  .content-tile.pad--45__figure figure,
  .content-tile.pad--45__figure .content-tile__figure {
    padding: var(--space-large)
  }
}

@media(max-width:768px) {

  .content-tile.pad--45__figure figure,
  .content-tile.pad--45__figure .content-tile__figure {
    padding: var(--space-medium)
  }
}

@media(min-width:769px) {

  .content-tile.pad--60__figure figure,
  .content-tile.pad--60__figure .content-tile__figure {
    padding: var(--space-xlarge)
  }
}

@media(max-width:768px) {

  .content-tile.pad--60__figure figure,
  .content-tile.pad--60__figure .content-tile__figure {
    padding: var(--space-large)
  }
}

.content-tile.pad-eq-vertical--0__caption figcaption,
.content-tile.pad-eq-vertical--0__caption .content-tile__figcaption {
  padding-top: 0;
  padding-bottom: 0
}

@media(min-width:769px) {

  .content-tile.pad-eq-vertical--15__caption figcaption,
  .content-tile.pad-eq-vertical--15__caption .content-tile__figcaption {
    padding-top: var(--space-xsmall);
    padding-bottom: var(--space-xsmall)
  }
}

@media(max-width:768px) {

  .content-tile.pad-eq-vertical--15__caption figcaption,
  .content-tile.pad-eq-vertical--15__caption .content-tile__figcaption {
    padding-top: var(--space-xxsmall);
    padding-bottom: var(--space-xxsmall)
  }
}

@media(min-width:769px) {

  .content-tile.pad-eq-vertical--30__caption figcaption,
  .content-tile.pad-eq-vertical--30__caption .content-tile__figcaption {
    padding-top: var(--space-medium);
    padding-bottom: var(--space-medium)
  }
}

@media(max-width:768px) {

  .content-tile.pad-eq-vertical--30__caption figcaption,
  .content-tile.pad-eq-vertical--30__caption .content-tile__figcaption {
    padding-top: var(--space-small);
    padding-bottom: var(--space-small)
  }
}

@media(min-width:769px) {

  .content-tile.pad-eq-vertical--45__caption figcaption,
  .content-tile.pad-eq-vertical--45__caption .content-tile__figcaption {
    padding-top: var(--space-large);
    padding-bottom: var(--space-large)
  }
}

@media(max-width:768px) {

  .content-tile.pad-eq-vertical--45__caption figcaption,
  .content-tile.pad-eq-vertical--45__caption .content-tile__figcaption {
    padding-top: var(--space-medium);
    padding-bottom: var(--space-medium)
  }
}

@media(min-width:769px) {

  .content-tile.pad-eq-vertical--60__caption figcaption,
  .content-tile.pad-eq-vertical--60__caption .content-tile__figcaption {
    padding-top: var(--space-xlarge);
    padding-bottom: var(--space-xlarge)
  }
}

@media(max-width:768px) {

  .content-tile.pad-eq-vertical--60__caption figcaption,
  .content-tile.pad-eq-vertical--60__caption .content-tile__figcaption {
    padding-top: var(--space-large);
    padding-bottom: var(--space-large)
  }
}

.content-tile.pad-top--0__pad-bottom--15__caption figcaption,
.content-tile.pad-top--0__pad-bottom--15__caption .content-tile__figcaption {
  padding-top: 0
}

@media(min-width:769px) {

  .content-tile.pad-top--0__pad-bottom--15__caption figcaption,
  .content-tile.pad-top--0__pad-bottom--15__caption .content-tile__figcaption {
    padding-bottom: var(--space-xsmall)
  }
}

@media(max-width:768px) {

  .content-tile.pad-top--0__pad-bottom--15__caption figcaption,
  .content-tile.pad-top--0__pad-bottom--15__caption .content-tile__figcaption {
    padding-bottom: var(--space-xxsmall)
  }
}

.content-tile.pad-top--0__pad-bottom--30__caption figcaption,
.content-tile.pad-top--0__pad-bottom--30__caption .content-tile__figcaption {
  padding-top: 0
}

@media(min-width:769px) {

  .content-tile.pad-top--0__pad-bottom--30__caption figcaption,
  .content-tile.pad-top--0__pad-bottom--30__caption .content-tile__figcaption {
    padding-bottom: var(--space-medium)
  }
}

@media(max-width:768px) {

  .content-tile.pad-top--0__pad-bottom--30__caption figcaption,
  .content-tile.pad-top--0__pad-bottom--30__caption .content-tile__figcaption {
    padding-bottom: var(--space-small)
  }
}

.content-tile.pad-top--0__pad-bottom--45__caption figcaption,
.content-tile.pad-top--0__pad-bottom--45__caption .content-tile__figcaption {
  padding-top: 0
}

@media(min-width:769px) {

  .content-tile.pad-top--0__pad-bottom--45__caption figcaption,
  .content-tile.pad-top--0__pad-bottom--45__caption .content-tile__figcaption {
    padding-bottom: var(--space-large)
  }
}

@media(max-width:768px) {

  .content-tile.pad-top--0__pad-bottom--45__caption figcaption,
  .content-tile.pad-top--0__pad-bottom--45__caption .content-tile__figcaption {
    padding-bottom: var(--space-medium)
  }
}

.content-tile.pad-top--0__pad-bottom--60__caption figcaption,
.content-tile.pad-top--0__pad-bottom--60__caption .content-tile__figcaption {
  padding-top: 0
}

@media(min-width:769px) {

  .content-tile.pad-top--0__pad-bottom--60__caption figcaption,
  .content-tile.pad-top--0__pad-bottom--60__caption .content-tile__figcaption {
    padding-bottom: var(--space-xlarge)
  }
}

@media(max-width:768px) {

  .content-tile.pad-top--0__pad-bottom--60__caption figcaption,
  .content-tile.pad-top--0__pad-bottom--60__caption .content-tile__figcaption {
    padding-bottom: var(--space-large)
  }
}

.content-tile.pad-bottom--0__pad-top--15__caption figcaption,
.content-tile.pad-bottom--0__pad-top--15__caption .content-tile__figcaption {
  padding-bottom: 0
}

@media(min-width:769px) {

  .content-tile.pad-bottom--0__pad-top--15__caption figcaption,
  .content-tile.pad-bottom--0__pad-top--15__caption .content-tile__figcaption {
    padding-top: var(--space-xsmall)
  }
}

@media(max-width:768px) {

  .content-tile.pad-bottom--0__pad-top--15__caption figcaption,
  .content-tile.pad-bottom--0__pad-top--15__caption .content-tile__figcaption {
    padding-top: var(--space-xxsmall)
  }
}

.content-tile.pad-bottom--0__pad-top--30__caption figcaption,
.content-tile.pad-bottom--0__pad-top--30__caption .content-tile__figcaption {
  padding-bottom: 0
}

@media(min-width:769px) {

  .content-tile.pad-bottom--0__pad-top--30__caption figcaption,
  .content-tile.pad-bottom--0__pad-top--30__caption .content-tile__figcaption {
    padding-top: var(--space-medium)
  }
}

@media(max-width:768px) {

  .content-tile.pad-bottom--0__pad-top--30__caption figcaption,
  .content-tile.pad-bottom--0__pad-top--30__caption .content-tile__figcaption {
    padding-top: var(--space-small)
  }
}

.content-tile.pad-bottom--0__pad-top--45__caption figcaption,
.content-tile.pad-bottom--0__pad-top--45__caption .content-tile__figcaption {
  padding-bottom: 0
}

@media(min-width:769px) {

  .content-tile.pad-bottom--0__pad-top--45__caption figcaption,
  .content-tile.pad-bottom--0__pad-top--45__caption .content-tile__figcaption {
    padding-top: var(--space-large)
  }
}

@media(max-width:768px) {

  .content-tile.pad-bottom--0__pad-top--45__caption figcaption,
  .content-tile.pad-bottom--0__pad-top--45__caption .content-tile__figcaption {
    padding-top: var(--space-medium)
  }
}

.content-tile.pad-bottom--0__pad-top--60__caption figcaption,
.content-tile.pad-bottom--0__pad-top--60__caption .content-tile__figcaption {
  padding-bottom: 0
}

@media(min-width:769px) {

  .content-tile.pad-bottom--0__pad-top--60__caption figcaption,
  .content-tile.pad-bottom--0__pad-top--60__caption .content-tile__figcaption {
    padding-top: var(--space-xlarge)
  }
}

@media(max-width:768px) {

  .content-tile.pad-bottom--0__pad-top--60__caption figcaption,
  .content-tile.pad-bottom--0__pad-top--60__caption .content-tile__figcaption {
    padding-top: var(--space-large)
  }
}

.content-tile.pad-eq-horizontal--0__caption figcaption,
.content-tile.pad-eq-horizontal--0__caption .content-tile__figcaption {
  padding-right: 0;
  padding-left: 0
}

@media(min-width:769px) {

  .content-tile.pad-eq-horizontal--15__caption figcaption,
  .content-tile.pad-eq-horizontal--15__caption .content-tile__figcaption {
    padding-right: var(--space-xsmall);
    padding-left: var(--space-xsmall)
  }
}

@media(max-width:768px) {

  .content-tile.pad-eq-horizontal--15__caption figcaption,
  .content-tile.pad-eq-horizontal--15__caption .content-tile__figcaption {
    padding-right: var(--space-xxsmall);
    padding-left: var(--space-xxsmall)
  }
}

@media(min-width:769px) {

  .content-tile.pad-eq-horizontal--30__caption figcaption,
  .content-tile.pad-eq-horizontal--30__caption .content-tile__figcaption {
    padding-right: var(--space-medium);
    padding-left: var(--space-medium)
  }
}

@media(max-width:768px) {

  .content-tile.pad-eq-horizontal--30__caption figcaption,
  .content-tile.pad-eq-horizontal--30__caption .content-tile__figcaption {
    padding-right: var(--space-small);
    padding-left: var(--space-small)
  }
}

@media(min-width:769px) {

  .content-tile.pad-eq-horizontal--45__caption figcaption,
  .content-tile.pad-eq-horizontal--45__caption .content-tile__figcaption {
    padding-right: var(--space-large);
    padding-left: var(--space-large)
  }
}

@media(max-width:768px) {

  .content-tile.pad-eq-horizontal--45__caption figcaption,
  .content-tile.pad-eq-horizontal--45__caption .content-tile__figcaption {
    padding-right: var(--space-medium);
    padding-left: var(--space-medium)
  }
}

@media(min-width:769px) {

  .content-tile.pad-eq-horizontal--60__caption figcaption,
  .content-tile.pad-eq-horizontal--60__caption .content-tile__figcaption {
    padding-right: var(--space-xlarge);
    padding-left: var(--space-xlarge)
  }
}

@media(max-width:768px) {

  .content-tile.pad-eq-horizontal--60__caption figcaption,
  .content-tile.pad-eq-horizontal--60__caption .content-tile__figcaption {
    padding-right: var(--space-large);
    padding-left: var(--space-large)
  }
}

.content-tile.pad-left--0__pad-right--15__caption figcaption,
.content-tile.pad-left--0__pad-right--15__caption .content-tile__figcaption {
  padding-left: 0
}

@media(min-width:769px) {

  .content-tile.pad-left--0__pad-right--15__caption figcaption,
  .content-tile.pad-left--0__pad-right--15__caption .content-tile__figcaption {
    padding-right: var(--space-xsmall)
  }
}

@media(max-width:768px) {

  .content-tile.pad-left--0__pad-right--15__caption figcaption,
  .content-tile.pad-left--0__pad-right--15__caption .content-tile__figcaption {
    padding-right: var(--space-xxsmall)
  }
}

.content-tile.pad-left--0__pad-right--30__caption figcaption,
.content-tile.pad-left--0__pad-right--30__caption .content-tile__figcaption {
  padding-left: 0
}

@media(min-width:769px) {

  .content-tile.pad-left--0__pad-right--30__caption figcaption,
  .content-tile.pad-left--0__pad-right--30__caption .content-tile__figcaption {
    padding-right: var(--space-medium)
  }
}

@media(max-width:768px) {

  .content-tile.pad-left--0__pad-right--30__caption figcaption,
  .content-tile.pad-left--0__pad-right--30__caption .content-tile__figcaption {
    padding-right: var(--space-small)
  }
}

.content-tile.pad-left--0__pad-right--45__caption figcaption,
.content-tile.pad-left--0__pad-right--45__caption .content-tile__figcaption {
  padding-left: 0
}

@media(min-width:769px) {

  .content-tile.pad-left--0__pad-right--45__caption figcaption,
  .content-tile.pad-left--0__pad-right--45__caption .content-tile__figcaption {
    padding-right: var(--space-large)
  }
}

@media(max-width:768px) {

  .content-tile.pad-left--0__pad-right--45__caption figcaption,
  .content-tile.pad-left--0__pad-right--45__caption .content-tile__figcaption {
    padding-right: var(--space-medium)
  }
}

.content-tile.pad-left--0__pad-right--60__caption figcaption,
.content-tile.pad-left--0__pad-right--60__caption .content-tile__figcaption {
  padding-left: 0
}

@media(min-width:769px) {

  .content-tile.pad-left--0__pad-right--60__caption figcaption,
  .content-tile.pad-left--0__pad-right--60__caption .content-tile__figcaption {
    padding-right: var(--space-xlarge)
  }
}

@media(max-width:768px) {

  .content-tile.pad-left--0__pad-right--60__caption figcaption,
  .content-tile.pad-left--0__pad-right--60__caption .content-tile__figcaption {
    padding-right: var(--space-large)
  }
}

.content-tile.pad-right--0__pad-left--15__caption figcaption,
.content-tile.pad-right--0__pad-left--15__caption .content-tile__figcaption {
  padding-right: 0
}

@media(min-width:769px) {

  .content-tile.pad-right--0__pad-left--15__caption figcaption,
  .content-tile.pad-right--0__pad-left--15__caption .content-tile__figcaption {
    padding-left: var(--space-xsmall)
  }
}

@media(max-width:768px) {

  .content-tile.pad-right--0__pad-left--15__caption figcaption,
  .content-tile.pad-right--0__pad-left--15__caption .content-tile__figcaption {
    padding-left: var(--space-xxsmall)
  }
}

.content-tile.pad-right--0__pad-left--30__caption figcaption,
.content-tile.pad-right--0__pad-left--30__caption .content-tile__figcaption {
  padding-right: 0
}

@media(min-width:769px) {

  .content-tile.pad-right--0__pad-left--30__caption figcaption,
  .content-tile.pad-right--0__pad-left--30__caption .content-tile__figcaption {
    padding-left: var(--space-medium)
  }
}

@media(max-width:768px) {

  .content-tile.pad-right--0__pad-left--30__caption figcaption,
  .content-tile.pad-right--0__pad-left--30__caption .content-tile__figcaption {
    padding-left: var(--space-small)
  }
}

.content-tile.pad-right--0__pad-left--45__caption figcaption,
.content-tile.pad-right--0__pad-left--45__caption .content-tile__figcaption {
  padding-right: 0
}

@media(min-width:769px) {

  .content-tile.pad-right--0__pad-left--45__caption figcaption,
  .content-tile.pad-right--0__pad-left--45__caption .content-tile__figcaption {
    padding-left: var(--space-large)
  }
}

@media(max-width:768px) {

  .content-tile.pad-right--0__pad-left--45__caption figcaption,
  .content-tile.pad-right--0__pad-left--45__caption .content-tile__figcaption {
    padding-left: var(--space-medium)
  }
}

.content-tile.pad-right--0__pad-left--60__caption figcaption,
.content-tile.pad-right--0__pad-left--60__caption .content-tile__figcaption {
  padding-right: 0
}

@media(min-width:769px) {

  .content-tile.pad-right--0__pad-left--60__caption figcaption,
  .content-tile.pad-right--0__pad-left--60__caption .content-tile__figcaption {
    padding-left: var(--space-xlarge)
  }
}

@media(max-width:768px) {

  .content-tile.pad-right--0__pad-left--60__caption figcaption,
  .content-tile.pad-right--0__pad-left--60__caption .content-tile__figcaption {
    padding-left: var(--space-large)
  }
}

@media(min-width:769px) {
  .content-tile-text--col-count-2 .content-tile-text {
    column-count: 2;
    column-gap: 45px
  }

  .content-tile-text--col-count-2.use-button-block .content-tile-text p:last-of-type {
    column-span: all
  }
}

.color--text-primary.color--text-primary {
  color: var(--color--text-primary)
}

.color--text-primary.color--text-primary *:not(a[href]) {
  color: var(--color--text-primary)
}

.color--text-secondary.color--text-secondary {
  color: var(--color--text-secondary)
}

.color--text-secondary.color--text-secondary *:not(a[href]) {
  color: var(--color--text-secondary)
}

.color--text-tertiary.color--text-tertiary {
  color: var(--color--text-tertiary)
}

.color--text-tertiary.color--text-tertiary *:not(a[href]) {
  color: var(--color--text-tertiary)
}

.color--text-primary-inverse.color--text-primary-inverse {
  color: var(--color--text-primary-inverse)
}

.color--text-primary-inverse.color--text-primary-inverse *:not(a[href]) {
  color: var(--color--text-primary-inverse)
}

.color--text-secondary-inverse.color--text-secondary-inverse {
  color: var(--color--text-secondary-inverse)
}

.color--text-secondary-inverse.color--text-secondary-inverse *:not(a[href]) {
  color: var(--color--text-secondary-inverse)
}

.color--text-tertiary-inverse.color--text-tertiary-inverse {
  color: var(--color--text-tertiary-inverse)
}

.color--text-tertiary-inverse.color--text-tertiary-inverse *:not(a[href]) {
  color: var(--color--text-tertiary-inverse)
}

.color--text-quaternary-inverse.color--text-quaternary-inverse {
  color: var(--color--text-quaternary-inverse)
}

.color--text-quaternary-inverse.color--text-quaternary-inverse *:not(a[href]) {
  color: var(--color--text-quaternary-inverse)
}

.color--text-link.color--text-link {
  color: var(--color--text-link)
}

.color--text-link.color--text-link *:not([class*="button-"]) {
  color: var(--color--text-link)
}

.color--text-link-inverse.color--text-link-inverse {
  color: var(--color--text-link-inverse)
}

.color--text-link-inverse.color--text-link-inverse *:not([class*="button-"]) {
  color: var(--color--text-link-inverse)
}

.color--accent-01-light.color--accent-01-light {
  color: var(--color--accent-01-light)
}

.color--accent-01-light.color--accent-01-light *:not(a[href]) {
  color: var(--color--accent-01-light)
}

.color--accent-01-primary.color--accent-01-primary {
  color: var(--color--accent-01-primary)
}

.color--accent-01-primary.color--accent-01-primary *:not(a[href]) {
  color: var(--color--accent-01-primary)
}

.color--accent-01-medium.color--accent-01-medium {
  color: var(--color--accent-01-medium)
}

.color--accent-01-medium.color--accent-01-medium *:not(a[href]) {
  color: var(--color--accent-01-medium)
}

.color--accent-01-dark.color--accent-01-dark {
  color: var(--color--accent-01-dark)
}

.color--accent-01-dark.color--accent-01-dark *:not(a[href]) {
  color: var(--color--accent-01-dark)
}

.color--accent-02-light.color--accent-02-light {
  color: var(--color--accent-02-light)
}

.color--accent-02-light.color--accent-02-light *:not(a[href]) {
  color: var(--color--accent-02-light)
}

.color--accent-02-primary.color--accent-02-primary {
  color: var(--color--accent-02-primary)
}

.color--accent-02-primary.color--accent-02-primary *:not(a[href]) {
  color: var(--color--accent-02-primary)
}

.color--accent-02-medium.color--accent-02-medium {
  color: var(--color--accent-02-medium)
}

.color--accent-02-medium.color--accent-02-medium *:not(a[href]) {
  color: var(--color--accent-02-medium)
}

.color--accent-02-dark.color--accent-02-dark {
  color: var(--color--accent-02-dark)
}

.color--accent-02-dark.color--accent-02-dark *:not(a[href]) {
  color: var(--color--accent-02-dark)
}

.color--accent-03-light.color--accent-03-light {
  color: var(--color--accent-03-light)
}

.color--accent-03-light.color--accent-03-light *:not(a[href]) {
  color: var(--color--accent-03-light)
}

.color--accent-03-primary.color--accent-03-primary {
  color: var(--color--accent-03-primary)
}

.color--accent-03-primary.color--accent-03-primary *:not(a[href]) {
  color: var(--color--accent-03-primary)
}

.color--accent-03-medium.color--accent-03-medium {
  color: var(--color--accent-03-medium)
}

.color--accent-03-medium.color--accent-03-medium *:not(a[href]) {
  color: var(--color--accent-03-medium)
}

.color--accent-03-dark.color--accent-03-dark {
  color: var(--color--accent-03-dark)
}

.color--accent-03-dark.color--accent-03-dark *:not(a[href]) {
  color: var(--color--accent-03-dark)
}

.color--accent-01-gradient.color--accent-01-gradient {
  color: var(--gradient--interactive-accent-01, var(--color--accent-01-gradient))
}

.color--accent-01-gradient.color--accent-01-gradient *:not(a[href]) {
  color: var(--gradient--interactive-accent-01, var(--color--accent-01-gradient))
}

.bgcolor--transparent.bgcolor--transparent {
  background: transparent
}

.bgcolor--background-light.bgcolor--background-light {
  background: var(--color--ui-background-light, var(--color--background-light))
}

.bgcolor--background-gray.bgcolor--background-gray {
  background: var(--color--ui-background-gray, var(--color--background-gray))
}

.bgcolor--background-medium.bgcolor--background-medium {
  background: var(--color--ui-background-med, var(--color--background-medium))
}

.bgcolor--background-medium-gray.bgcolor--background-medium-gray {
  background: var(--color--ui-background-med-gray, var(--color--background-medium-gray))
}

.bgcolor--background-dark-gray.bgcolor--background-dark-gray {
  background: var(--color--ui-background-dark-gray, var(--color--background-dark-gray))
}

.bgcolor--background-dark.bgcolor--background-dark {
  background: var(--color--ui-background-dark, var(--color--background-dark))
}

.bgcolor--ui-01.bgcolor--ui-01 {
  background: var(--color--ui-01)
}

.bgcolor--ui-02.bgcolor--ui-02 {
  background: var(--color--ui-02)
}

.bgcolor--ui-03.bgcolor--ui-03 {
  background: var(--color--ui-03)
}

.bgcolor--ui-04.bgcolor--ui-04 {
  background: var(--color--ui-04)
}

.bgcolor--ui-05.bgcolor--ui-05 {
  background: var(--color--ui-05)
}

.bgcolor--ui-06.bgcolor--ui-06 {
  background: var(--color--ui-06)
}

.bgcolor--overlay-dark.bgcolor--overlay-dark {
  background: var(--color--ui-overlay-dark, var(--color--overlay-dark))
}

.bgcolor--overlay-light.bgcolor--overlay-light {
  background: var(--color--ui-overlay-light, var(--color--overlay-light))
}

.cq-wcm-edit .column-control .row [class*="cq-placeholder"] {
  width: auto !important
}

[style="margin: 0.0px;"]+[style="margin: 0.0px;"] {
  margin-top: 20px !important
}

[style*="font-family: tahoma"] {
  font-family: var(--typefamily-primary) !important;
  font-size: var(--typesize-default) !important
}

main [style*="font-size"] {
  font-size: inherit !important
}

body>header br,
body>footer br {
  font-size: 0;
  line-height: 0;
  display: none
}

.cq-Editable-dom,
.cq-Editable-dom * {
  transition: unset !important;
  opacity: 1 !important
}

.weight--light.weight--light,
.weight--light.weight--light * {
  font-weight: var(--typeweight--light)
}

.weight--default.weight--default,
.weight--default.weight--default * {
  font-weight: var(--typeweight--default)
}

.weight--medium.weight--medium,
.weight--medium.weight--medium * {
  font-weight: var(--typeweight--medium)
}

.weight--semibold.weight--semibold,
.weight--semibold.weight--semibold * {
  font-weight: var(--typeweight--semibold)
}

.weight--semibold-plus.weight--semibold-plus,
.weight--semibold-plus.weight--semibold-plus * {
  font-weight: var(--typeweight--semibold-plus)
}

.weight--bold.weight--bold,
.weight--bold.weight--bold * {
  font-weight: var(--typeweight--bold)
}

.typefamily-primary,
.typefamily-primary *:not(em, i) {
  font-family: var(--typefamily-primary) !important
}

.typefamily-alt,
.typefamily-alt *:not(em, i) {
  font-family: var(--typefamily-alt) !important
}

.typecase-upper,
.typecase-upper * {
  text-transform: var(--typecase-upper)
}

.typecase-lower,
.typecase-lower * {
  text-transform: var(--typecase-lower)
}

.text-align--center,
.text-align--center * {
  text-align: center
}

.text-align--left,
.text-align--left * {
  text-align: left
}

.text-align--right,
.text-align--right * {
  text-align: right
}

.text-wrap--balance.text:is(.h1, .h2, .h3, .h4, .h5, .h6)>p,
.text-wrap--balance.text :where(h1, h2, h3, h4, h5, h6),
.text-wrap--balance.title>*,
.text-wrap--balance .content-tile__title,
.text-wrap--balance .content-tile-subtitle,
.text-wrap--balance .content-tile-text :where(h1, h2, h3, h4, h5, h6) {
  text-wrap: balance
}

.text-wrap--pretty.text:is(.h1, .h2, .h3, .h4, .h5, .h6)>p,
.text-wrap--pretty.text :where(h1, h2, h3, h4, h5, h6),
.text-wrap--pretty.title>*,
.text-wrap--pretty .content-tile__title,
.text-wrap--pretty .content-tile-subtitle,
.text-wrap--pretty .content-tile-text :where(h1, h2, h3, h4, h5, h6) {
  text-wrap: pretty
}

.leading--tightest.text :where(p, li),
.leading--tightest .content-tile-text :where(p, li) {
  line-height: var(--leading--tightest)
}

.leading--1.text :where(p, li),
.leading--1 .content-tile-text :where(p, li) {
  line-height: var(--leading--1)
}

.leading--1-1.text :where(p, li),
.leading--1-1 .content-tile-text :where(p, li) {
  line-height: var(--leading--1-1)
}

.leading--1-2.text :where(p, li),
.leading--1-2 .content-tile-text :where(p, li) {
  line-height: var(--leading--1-15)
}

.leading--1-3.text :where(p, li),
.leading--1-3 .content-tile-text :where(p, li) {
  line-height: var(--leading--1-2)
}

.leading--1-4.text :where(p, li),
.leading--1-4 .content-tile-text :where(p, li) {
  line-height: var(--leading--1-4)
}

.leading--1-5.text :where(p, li),
.leading--1-5 .content-tile-text :where(p, li) {
  line-height: var(--leading--1-5)
}

.leading--1-6.text :where(p, li),
.leading--1-6 .content-tile-text :where(p, li) {
  line-height: var(--leading--1-6)
}

.leading--default.text :where(p, li),
.leading--default .content-tile-text :where(p, li) {
  line-height: var(--leading--1-5)
}

.leading--1-7.text :where(p, li),
.leading--1-7 .content-tile-text :where(p, li) {
  line-height: var(--leading--1-7)
}

.leading--1-8.text :where(p, li),
.leading--1-8 .content-tile-text :where(p, li) {
  line-height: var(--leading--1-8)
}

.leading--2.text :where(p, li),
.leading--2 .content-tile-text :where(p, li) {
  line-height: var(--leading--2)
}

.line-length--ideal.text>*,
.line-length--ideal.title>*,
.line-length--ideal .content-tile__title,
.line-length--ideal .content-tile-subtitle,
.line-length--ideal .content-tile-text>* {
  max-width: 66ch;
  word-break: break-word
}

.line-length--15.text>*,
.line-length--15.title>*,
.line-length--15 .content-tile__title,
.line-length--15 .content-tile-subtitle,
.line-length--15 .content-tile-text>* {
  max-width: 15ch;
  word-break: break-word
}

.line-length--20.text>*,
.line-length--20.title>*,
.line-length--20 .content-tile__title,
.line-length--20 .content-tile-subtitle,
.line-length--20 .content-tile-text>* {
  max-width: 20ch;
  word-break: break-word
}

.line-length--25.text>*,
.line-length--25.title>*,
.line-length--25 .content-tile__title,
.line-length--25 .content-tile-subtitle,
.line-length--25 .content-tile-text>* {
  max-width: 25ch;
  word-break: break-word
}

.line-length--30.text>*,
.line-length--30.title>*,
.line-length--30 .content-tile__title,
.line-length--30 .content-tile-subtitle,
.line-length--30 .content-tile-text>* {
  max-width: 30ch;
  word-break: break-word
}

.line-length--35.text>*,
.line-length--35.title>*,
.line-length--35 .content-tile__title,
.line-length--35 .content-tile-subtitle,
.line-length--35 .content-tile-text>* {
  max-width: 35ch;
  word-break: break-word
}

.line-length--40.text>*,
.line-length--40.title>*,
.line-length--40 .content-tile__title,
.line-length--40 .content-tile-subtitle,
.line-length--40 .content-tile-text>* {
  max-width: 40ch;
  word-break: break-word
}

.line-length--45.text>*,
.line-length--45.title>*,
.line-length--45 .content-tile__title,
.line-length--45 .content-tile-subtitle,
.line-length--45 .content-tile-text>* {
  max-width: 45ch;
  word-break: break-word
}

.line-length--50.text>*,
.line-length--50.title>*,
.line-length--50 .content-tile__title,
.line-length--50 .content-tile-subtitle,
.line-length--50 .content-tile-text>* {
  max-width: 50ch;
  word-break: break-word
}

.line-length--55.text>*,
.line-length--55.title>*,
.line-length--55 .content-tile__title,
.line-length--55 .content-tile-subtitle,
.line-length--55 .content-tile-text>* {
  max-width: 55ch;
  word-break: break-word
}

.line-length--60.text>*,
.line-length--60.title>*,
.line-length--60 .content-tile__title,
.line-length--60 .content-tile-subtitle,
.line-length--60 .content-tile-text>* {
  max-width: 60ch;
  word-break: break-word
}

.line-length--70.text>*,
.line-length--70.title>*,
.line-length--70 .content-tile__title,
.line-length--70 .content-tile-subtitle,
.line-length--70 .content-tile-text>* {
  max-width: 70ch;
  word-break: break-word
}

.break-word {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto
}

.has-padding--none.has-padding--none,
.has-padding--0.has-padding--0 {
  padding: 0
}

@media(min-width:769px) {
  .has-padding--15.has-padding--15 {
    padding: var(--space-xsmall)
  }
}

@media(max-width:768px) {
  .has-padding--15.has-padding--15 {
    padding: var(--space-xxsmall)
  }
}

@media(min-width:769px) {
  .has-padding--30.has-padding--30 {
    padding: var(--space-medium)
  }
}

@media(max-width:768px) {
  .has-padding--30.has-padding--30 {
    padding: var(--space-small)
  }
}

@media(min-width:769px) {
  .has-padding--45.has-padding--45 {
    padding: var(--space-large)
  }
}

@media(max-width:768px) {
  .has-padding--45.has-padding--45 {
    padding: var(--space-medium)
  }
}

@media(min-width:769px) {
  .has-padding--60.has-padding--60 {
    padding: var(--space-xlarge)
  }
}

@media(max-width:768px) {
  .has-padding--60.has-padding--60 {
    padding: var(--space-large)
  }
}

@media(min-width:769px) {
  .has-padding--75.has-padding--75 {
    padding: var(--space-xxlarge)
  }
}

@media(max-width:768px) {
  .has-padding--75.has-padding--75 {
    padding: var(--space-xlarge)
  }
}

.has-padding-vertical--none.has-padding-vertical--none,
.has-padding-vertical--0.has-padding-vertical--0 {
  padding-top: 0;
  padding-bottom: 0
}

@media(min-width:769px) {
  .has-padding-vertical--15.has-padding-vertical--15 {
    padding-top: var(--space-xsmall);
    padding-bottom: var(--space-xsmall)
  }
}

@media(max-width:768px) {
  .has-padding-vertical--15.has-padding-vertical--15 {
    padding-top: var(--space-xxsmall);
    padding-bottom: var(--space-xxsmall)
  }
}

@media(min-width:769px) {
  .has-padding-vertical--30.has-padding-vertical--30 {
    padding-top: var(--space-medium);
    padding-bottom: var(--space-medium)
  }
}

@media(max-width:768px) {
  .has-padding-vertical--30.has-padding-vertical--30 {
    padding-top: var(--space-small);
    padding-bottom: var(--space-small)
  }
}

@media(min-width:769px) {
  .has-padding-vertical--45.has-padding-vertical--45 {
    padding-top: var(--space-large);
    padding-bottom: var(--space-large)
  }
}

@media(max-width:768px) {
  .has-padding-vertical--45.has-padding-vertical--45 {
    padding-top: var(--space-medium);
    padding-bottom: var(--space-medium)
  }
}

@media(min-width:769px) {
  .has-padding-vertical--60.has-padding-vertical--60 {
    padding-top: var(--space-xlarge);
    padding-bottom: var(--space-xlarge)
  }
}

@media(max-width:768px) {
  .has-padding-vertical--60.has-padding-vertical--60 {
    padding-top: var(--space-large);
    padding-bottom: var(--space-large)
  }
}

@media(min-width:769px) {
  .has-padding-vertical--75.has-padding-vertical--75 {
    padding-top: var(--space-xxlarge);
    padding-bottom: var(--space-xxlarge)
  }
}

@media(max-width:768px) {
  .has-padding-vertical--75.has-padding-vertical--75 {
    padding-top: var(--space-xlarge);
    padding-bottom: var(--space-xlarge)
  }
}

@media(min-width:769px) {
  .has-padding-vertical--90.has-padding-vertical--90 {
    padding-top: var(--space-xxxlarge);
    padding-bottom: var(--space-xxxlarge)
  }
}

@media(max-width:768px) {
  .has-padding-vertical--90.has-padding-vertical--90 {
    padding-top: var(--space-xxlarge);
    padding-bottom: var(--space-xxlarge)
  }
}

.has-padding-bottom--none.has-padding-bottom--none,
.has-padding-bottom--0.has-padding-bottom--0 {
  padding-bottom: 0
}

@media(min-width:769px) {
  .has-padding-bottom--15.has-padding-bottom--15 {
    padding-bottom: var(--space-xsmall)
  }
}

@media(max-width:768px) {
  .has-padding-bottom--15.has-padding-bottom--15 {
    padding-bottom: var(--space-xxsmall)
  }
}

@media(min-width:769px) {
  .has-padding-bottom--30.has-padding-bottom--30 {
    padding-bottom: var(--space-medium)
  }
}

@media(max-width:768px) {
  .has-padding-bottom--30.has-padding-bottom--30 {
    padding-bottom: var(--space-small)
  }
}

@media(min-width:769px) {
  .has-padding-bottom--45.has-padding-bottom--45 {
    padding-bottom: var(--space-large)
  }
}

@media(max-width:768px) {
  .has-padding-bottom--45.has-padding-bottom--45 {
    padding-bottom: var(--space-medium)
  }
}

@media(min-width:769px) {
  .has-padding-bottom--60.has-padding-bottom--60 {
    padding-bottom: var(--space-xlarge)
  }
}

@media(max-width:768px) {
  .has-padding-bottom--60.has-padding-bottom--60 {
    padding-bottom: var(--space-large)
  }
}

@media(min-width:769px) {
  .has-padding-bottom--75.has-padding-bottom--75 {
    padding-bottom: var(--space-xxlarge)
  }
}

@media(max-width:768px) {
  .has-padding-bottom--75.has-padding-bottom--75 {
    padding-bottom: var(--space-xlarge)
  }
}

@media(min-width:769px) {
  .has-padding-bottom--90.has-padding-bottom--90 {
    padding-bottom: var(--space-xxxlarge)
  }
}

@media(max-width:768px) {
  .has-padding-bottom--90.has-padding-bottom--90 {
    padding-bottom: var(--space-xxlarge)
  }
}

.has-padding-top--none.has-padding-top--none,
.has-padding-top--0.has-padding-top--0 {
  padding-top: 0
}

@media(min-width:769px) {
  .has-padding-top--15.has-padding-top--15 {
    padding-top: var(--space-xsmall)
  }
}

@media(max-width:768px) {
  .has-padding-top--15.has-padding-top--15 {
    padding-top: var(--space-xxsmall)
  }
}

@media(min-width:769px) {
  .has-padding-top--30.has-padding-top--30 {
    padding-top: var(--space-medium)
  }
}

@media(max-width:768px) {
  .has-padding-top--30.has-padding-top--30 {
    padding-top: var(--space-small)
  }
}

@media(min-width:769px) {
  .has-padding-top--45.has-padding-top--45 {
    padding-top: var(--space-large)
  }
}

@media(max-width:768px) {
  .has-padding-top--45.has-padding-top--45 {
    padding-top: var(--space-medium)
  }
}

@media(min-width:769px) {
  .has-padding-top--60.has-padding-top--60 {
    padding-top: var(--space-xlarge)
  }
}

@media(max-width:768px) {
  .has-padding-top--60.has-padding-top--60 {
    padding-top: var(--space-large)
  }
}

@media(min-width:769px) {
  .has-padding-top--75.has-padding-top--75 {
    padding-top: var(--space-xxlarge)
  }
}

@media(max-width:768px) {
  .has-padding-top--75.has-padding-top--75 {
    padding-top: var(--space-xlarge)
  }
}

@media(min-width:769px) {
  .has-padding-top--90.has-padding-top--90 {
    padding-top: var(--space-xxxlarge)
  }
}

@media(max-width:768px) {
  .has-padding-top--90.has-padding-top--90 {
    padding-top: var(--space-xxlarge)
  }
}

.has-padding-horizontal--none,
.has-padding-horizontal--0 {
  padding-right: 0;
  padding-left: 0
}

@media(min-width:769px) {
  .has-padding-horizontal--15 {
    padding-right: var(--space-xsmall);
    padding-left: var(--space-xsmall)
  }
}

@media(max-width:768px) {
  .has-padding-horizontal--15 {
    padding-right: var(--space-xxsmall);
    padding-left: var(--space-xxsmall)
  }
}

@media(min-width:769px) {
  .has-padding-horizontal--20 {
    padding-right: var(--space-small);
    padding-left: var(--space-small)
  }
}

@media(max-width:768px) {
  .has-padding-horizontal--20 {
    padding-right: var(--space-xsmall);
    padding-left: var(--space-xsmall)
  }
}

@media(min-width:769px) {
  .has-padding-horizontal--30 {
    padding-right: var(--space-medium);
    padding-left: var(--space-medium)
  }
}

@media(max-width:768px) {
  .has-padding-horizontal--30 {
    padding-right: var(--space-small);
    padding-left: var(--space-small)
  }
}

@media(min-width:769px) {
  .has-padding-horizontal--45 {
    padding-right: var(--space-large);
    padding-left: var(--space-large)
  }
}

@media(max-width:768px) {
  .has-padding-horizontal--45 {
    padding-right: var(--space-medium);
    padding-left: var(--space-medium)
  }
}

@media(min-width:769px) {
  .has-padding-horizontal--60 {
    padding-right: var(--space-xlarge);
    padding-left: var(--space-xlarge)
  }
}

@media(max-width:768px) {
  .has-padding-horizontal--60 {
    padding-right: var(--space-large);
    padding-left: var(--space-large)
  }
}

@media(min-width:769px) {
  .has-padding-horizontal--75 {
    padding-right: var(--space-xxlarge);
    padding-left: var(--space-xxlarge)
  }
}

@media(max-width:768px) {
  .has-padding-horizontal--75 {
    padding-right: var(--space-xlarge);
    padding-left: var(--space-xlarge)
  }
}

@media(min-width:769px) {
  .has-padding-horizontal--90 {
    padding-right: var(--space-xxxlarge);
    padding-left: var(--space-xxxlarge)
  }
}

@media(max-width:768px) {
  .has-padding-horizontal--90 {
    padding-right: var(--space-xxlarge);
    padding-left: var(--space-xxlarge)
  }
}

@media(min-width:769px) {
  .has-padding-horizontal--105 {
    padding-right: calc(24 * var(--space-unit));
    padding-left: calc(24 * var(--space-unit))
  }
}

@media(max-width:768px) {
  .has-padding-horizontal--105 {
    padding-right: var(--space-xxxlarge);
    padding-left: var(--space-xxxlarge)
  }
}

@media(min-width:769px) {
  .has-padding-horizontal--120 {
    padding-right: var(--space-xxxxlarge);
    padding-left: var(--space-xxxxlarge)
  }
}

@media(max-width:768px) {
  .has-padding-horizontal--120 {
    padding-right: calc(24 * var(--space-unit));
    padding-left: calc(24 * var(--space-unit))
  }
}

[class*="has-padding-bottom--0"] {
  padding-bottom: 0
}

[class*="has-padding-top--0"] {
  padding-top: 0
}

[class*="has-padding-right--0"] {
  padding-right: 0
}

[class*="has-padding-left--0"] {
  padding-left: 0
}

@media(min-width:769px) {
  .has-padding-bottom--0__has-padding-top--15 {
    padding-top: var(--space-xsmall)
  }

  .has-padding-bottom--0__has-padding-top--30 {
    padding-top: var(--space-medium)
  }

  .has-padding-bottom--0__has-padding-top--45 {
    padding-top: var(--space-large)
  }

  .has-padding-bottom--0__has-padding-top--60 {
    padding-top: var(--space-xlarge)
  }

  .has-padding-top--0__has-padding-bottom--15 {
    padding-bottom: var(--space-xsmall)
  }

  .has-padding-top--0__has-padding-bottom--30 {
    padding-bottom: var(--space-medium)
  }

  .has-padding-top--0__has-padding-bottom--45 {
    padding-bottom: var(--space-large)
  }

  .has-padding-top--0__has-padding-bottom--60 {
    padding-bottom: var(--space-xlarge)
  }

  .has-padding-left--15__has-padding-right--0 {
    padding-left: var(--space-xsmall)
  }

  .has-padding-left--30__has-padding-right--0 {
    padding-left: var(--space-medium)
  }

  .has-padding-left--45__has-padding-right--0 {
    padding-left: var(--space-large)
  }

  .has-padding-left--60__has-padding-right--0 {
    padding-left: var(--space-xlarge)
  }

  .has-padding-left--0__has-padding-right--15 {
    padding-right: var(--space-xsmall)
  }

  .has-padding-left--0__has-padding-right--30 {
    padding-right: var(--space-medium)
  }

  .has-padding-left--0__has-padding-right--45 {
    padding-right: var(--space-large)
  }

  .has-padding-left--0__has-padding-right--60 {
    padding-right: var(--space-xlarge)
  }
}

@media(max-width:768px) {
  .has-padding-bottom--0__has-padding-top--15 {
    padding-top: var(--space-xxsmall)
  }

  .has-padding-bottom--0__has-padding-top--30 {
    padding-top: var(--space-small)
  }

  .has-padding-bottom--0__has-padding-top--45 {
    padding-top: var(--space-medium)
  }

  .has-padding-bottom--0__has-padding-top--60 {
    padding-top: var(--space-large)
  }

  .has-padding-top--0__has-padding-bottom--15 {
    padding-bottom: var(--space-xxsmall)
  }

  .has-padding-top--0__has-padding-bottom--30 {
    padding-bottom: var(--space-small)
  }

  .has-padding-top--0__has-padding-bottom--45 {
    padding-bottom: var(--space-medium)
  }

  .has-padding-top--0__has-padding-bottom--60 {
    padding-bottom: var(--space-large)
  }

  .has-padding-left--15__has-padding-right--0 {
    padding-left: var(--space-xxsmall)
  }

  .has-padding-left--30__has-padding-right--0 {
    padding-left: var(--space-small)
  }

  .has-padding-left--45__has-padding-right--0 {
    padding-left: var(--space-medium)
  }

  .has-padding-left--60__has-padding-right--0 {
    padding-left: var(--space-large)
  }

  .has-padding-left--0__has-padding-right--15 {
    padding-right: var(--space-xxsmall)
  }

  .has-padding-left--0__has-padding-right--30 {
    padding-right: var(--space-small)
  }

  .has-padding-left--0__has-padding-right--45 {
    padding-right: var(--space-medium)
  }

  .has-padding-left--0__has-padding-right--60 {
    padding-right: var(--space-large)
  }
}

.corners-semiround-sm {
  border-radius: var(--corners-semiround-sm)
}

.corners-semiround-md {
  border-radius: var(--corners-semiround-md)
}

.corners-semiround-pill {
  border-radius: var(--corners-semiround-pill)
}

.curve-bottom-right {
  overflow: hidden
}

@media(min-width:1920px) {
  .curve-bottom-right {
    clip-path: ellipse(110% 100% at left top)
  }
}

@media(min-width:376px) and (max-width:1919px) {
  .curve-bottom-right {
    clip-path: ellipse(160% 100% at left top)
  }
}

@media(max-width:375px) {
  .curve-bottom-right {
    clip-path: ellipse(225% 100% at left top)
  }
}

.curve-bottom-left {
  overflow: hidden
}

@media(min-width:1920px) {
  .curve-bottom-left {
    clip-path: ellipse(110% 100% at right top)
  }
}

@media(min-width:376px) and (max-width:1919px) {
  .curve-bottom-left {
    clip-path: ellipse(160% 100% at right top)
  }
}

@media(max-width:375px) {
  .curve-bottom-left {
    clip-path: ellipse(225% 100% at right top)
  }
}

.curve-top-right {
  overflow: hidden
}

@media(min-width:1920px) {
  .curve-top-right {
    clip-path: ellipse(110% 100% at left bottom)
  }
}

@media(min-width:376px) and (max-width:1919px) {
  .curve-top-right {
    clip-path: ellipse(160% 100% at left bottom)
  }
}

@media(max-width:375px) {
  .curve-top-right {
    clip-path: ellipse(225% 100% at left bottom)
  }
}

.curve-top-left {
  overflow: hidden
}

@media(min-width:1920px) {
  .curve-top-left {
    clip-path: ellipse(110% 100% at right bottom)
  }
}

@media(min-width:376px) and (max-width:1919px) {
  .curve-top-left {
    clip-path: ellipse(160% 100% at right bottom)
  }
}

@media(max-width:375px) {
  .curve-top-left {
    clip-path: ellipse(225% 100% at right bottom)
  }
}

.min-height--400 {
  min-height: 400px
}

.min-height--600 {
  min-height: 600px
}

.min-height--45vh {
  min-height: 45vh
}

.min-height--45vh.cq-Editable-dom {
  min-height: 45%
}

.min-height--65vh {
  min-height: 65vh
}

.min-height--65vh.cq-Editable-dom {
  min-height: 65%
}

.min-height--ideal-hero {
  min-height: clamp(484px, 53.33vw, 780px)
}

.min-height--ideal-hero.cq-Editable-dom {
  min-height: clamp(484px, 53.33%, 780px)
}

body>img[width] {
  display: none
}

body:not(.edit):not(.cq-wcm-edit) .hide {
  display: none !important
}

body:not(.edit):not(.cq-wcm-edit) .display-block {
  display: block !important
}

body:not(.edit):not(.cq-wcm-edit) .display-inlineblock {
  display: inline-block !important
}

@media(min-width:768px) {
  body:not(.edit):not(.cq-wcm-edit) .display-mobile {
    display: none !important
  }
}

@media(max-width:767px) {
  body:not(.edit):not(.cq-wcm-edit) .display-tablet {
    display: none !important
  }
}

@media(max-width:1024px) {
  body:not(.edit):not(.cq-wcm-edit) .display-desktop {
    display: none !important
  }
}

.underline-links a {
  text-decoration: underline
}

svg {
  vertical-align: inherit
}

.column-border-middle>.container>.row>.col-12 {
  height: max-content
}

.column-border-middle>.container>.row>.col-12::before {
  content: "";
  display: block;
  height: calc(100% - 50px);
  width: 1px;
  background: #dbdbdb;
  position: absolute;
  top: 40px;
  left: -10px
}

.column-border-middle>.container>.row>.col-12:first-child::before {
  display: none
}

@media(max-width:767px) {
  .column-border-middle>.container>.row>.col-12::before {
    height: 10px;
    width: 100%;
    background-color: transparent;
    position: static;
    margin: 30px 0
  }
}

.column-border-between-rows>.container,
.column-border-between-rows>.row {
  position: relative
}

.column-border-between-rows>.container::after,
.column-border-between-rows>.row::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #dbdbdb;
  position: absolute;
  bottom: -13px;
  max-width: 1180px;
  left: 10px
}

.column-border-between-rows>.row {
  padding-bottom: 20px
}

.column-border-between-rows>.row::after {
  bottom: 12px
}

.left {
  float: left
}

.right {
  float: right
}

.overflow-visible {
  overflow: visible !important
}

.overflow-hidden {
  overflow: hidden !important
}

.overflow-auto {
  overflow: auto !important
}

.overflow-unset {
  overflow: unset !important
}

.content-load--lazy {
  content-visibility: auto;
  contain-intrinsic-size: 1px 5000px
}

.filter-grayscale--1 {
  -webkit-filter: grayscale(1);
  filter: grayscale(1)
}

[class*="dots-"] {
  position: relative
}

// [class*="dots-"][class*="leftUp"]::before {
//   --color--dots: transparent;
//   display: block;
//   content: "";
//   position: absolute;
//   top: 0;
//   height: 100%;
//   opacity: .1;
//   -webkit-mask-size: 100%;
//   mask-size: 100%;
//   -webkit-mask-repeat: repeat-y;
//   mask-repeat: repeat-y;
//   -webkit-mask-position: center;
//   mask-position: center;
//   background-color: var(--color--dots);
//   background-repeat: repeat-y;
//   clip-path: polygon(100% 0, 100% 77%, 83% 100%, 0 100%, 0 17%, 15% 0);
//   left: 0
// }

@media(min-width:769px) {
  [class*="dots-"][class*="leftUp"]::before {
    width: 400px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg")
  }
}

@media(max-width:768px) {
  [class*="dots-"][class*="leftUp"]::before {
    width: 220px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg")
  }
}

@media(max-width:420px) {
  [class*="dots-"][class*="leftUp"]::before {
    width: 120px
  }
}

// [class*="dots-"][class*="leftDown"]::before {
//   --color--dots: transparent;
//   display: block;
//   content: "";
//   position: absolute;
//   top: 0;
//   height: 100%;
//   opacity: .1;
//   -webkit-mask-size: 100%;
//   mask-size: 100%;
//   -webkit-mask-repeat: repeat-y;
//   mask-repeat: repeat-y;
//   -webkit-mask-position: center;
//   mask-position: center;
//   background-color: var(--color--dots);
//   background-repeat: repeat-y;
//   clip-path: polygon(100% 0, 100% 77%, 83% 100%, 0 100%, 0 17%, 15% 0);
//   left: 0;
//   transform: scaleY(-1)
// }

@media(min-width:769px) {
  [class*="dots-"][class*="leftDown"]::before {
    width: 400px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg")
  }
}

@media(max-width:768px) {
  [class*="dots-"][class*="leftDown"]::before {
    width: 220px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg")
  }
}

@media(max-width:420px) {
  [class*="dots-"][class*="leftDown"]::before {
    width: 120px
  }
}

// [class*="dots-"][class*="rightUp"]::after {
//   --color--dots: transparent;
//   display: block;
//   content: "";
//   position: absolute;
//   top: 0;
//   height: 100%;
//   opacity: .1;
//   -webkit-mask-size: 100%;
//   mask-size: 100%;
//   -webkit-mask-repeat: repeat-y;
//   mask-repeat: repeat-y;
//   -webkit-mask-position: center;
//   mask-position: center;
//   background-color: var(--color--dots);
//   background-repeat: repeat-y;
//   clip-path: polygon(100% 0, 100% 77%, 83% 100%, 0 100%, 0 17%, 15% 0);
//   right: 0;
//   transform: scaleX(-1) scaleY(-1)
// }

@media(min-width:769px) {
  [class*="dots-"][class*="rightUp"]::after {
    width: 400px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg")
  }
}

@media(max-width:768px) {
  [class*="dots-"][class*="rightUp"]::after {
    width: 220px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg")
  }
}

@media(max-width:420px) {
  [class*="dots-"][class*="rightUp"]::after {
    width: 120px
  }
}

// [class*="dots-"][class*="rightDown"]::after {
//   --color--dots: transparent;
//   display: block;
//   content: "";
//   position: absolute;
//   top: 0;
//   height: 100%;
//   opacity: .1;
//   -webkit-mask-size: 100%;
//   mask-size: 100%;
//   -webkit-mask-repeat: repeat-y;
//   mask-repeat: repeat-y;
//   -webkit-mask-position: center;
//   mask-position: center;
//   background-color: var(--color--dots);
//   background-repeat: repeat-y;
//   clip-path: polygon(100% 0, 100% 77%, 83% 100%, 0 100%, 0 17%, 15% 0);
//   right: 0;
//   transform: scaleX(-1)
// }

@media(min-width:769px) {
  [class*="dots-"][class*="rightDown"]::after {
    width: 400px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg")
  }
}

@media(max-width:768px) {
  [class*="dots-"][class*="rightDown"]::after {
    width: 220px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg")
  }
}

@media(max-width:420px) {
  [class*="dots-"][class*="rightDown"]::after {
    width: 120px
  }
}

// [class*="dots-"][class*="lrUp"]::before {
//   --color--dots: transparent;
//   display: block;
//   content: "";
//   position: absolute;
//   top: 0;
//   height: 100%;
//   opacity: .1;
//   -webkit-mask-size: 100%;
//   mask-size: 100%;
//   -webkit-mask-repeat: repeat-y;
//   mask-repeat: repeat-y;
//   -webkit-mask-position: center;
//   mask-position: center;
//   background-color: var(--color--dots);
//   background-repeat: repeat-y;
//   clip-path: polygon(100% 0, 100% 77%, 83% 100%, 0 100%, 0 17%, 15% 0);
//   left: 0
// }

@media(min-width:769px) {
  [class*="dots-"][class*="lrUp"]::before {
    width: 400px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg")
  }
}

@media(max-width:768px) {
  [class*="dots-"][class*="lrUp"]::before {
    width: 220px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg")
  }
}

@media(max-width:420px) {
  [class*="dots-"][class*="lrUp"]::before {
    width: 120px
  }
}

// [class*="dots-"][class*="lrUp"]::after {
//   --color--dots: transparent;
//   display: block;
//   content: "";
//   position: absolute;
//   top: 0;
//   height: 100%;
//   opacity: .1;
//   -webkit-mask-size: 100%;
//   mask-size: 100%;
//   -webkit-mask-repeat: repeat-y;
//   mask-repeat: repeat-y;
//   -webkit-mask-position: center;
//   mask-position: center;
//   background-color: var(--color--dots);
//   background-repeat: repeat-y;
//   clip-path: polygon(100% 0, 100% 77%, 83% 100%, 0 100%, 0 17%, 15% 0);
//   right: 0;
//   transform: scaleX(-1) scaleY(-1)
// }

@media(min-width:769px) {
  [class*="dots-"][class*="lrUp"]::after {
    width: 400px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg")
  }
}

@media(max-width:768px) {
  [class*="dots-"][class*="lrUp"]::after {
    width: 220px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg")
  }
}

@media(max-width:420px) {
  [class*="dots-"][class*="lrUp"]::after {
    width: 120px
  }
}

// [class*="dots-"][class*="lrDown"]::before {
//   --color--dots: transparent;
//   display: block;
//   content: "";
//   position: absolute;
//   top: 0;
//   height: 100%;
//   opacity: .1;
//   -webkit-mask-size: 100%;
//   mask-size: 100%;
//   -webkit-mask-repeat: repeat-y;
//   mask-repeat: repeat-y;
//   -webkit-mask-position: center;
//   mask-position: center;
//   background-color: var(--color--dots);
//   background-repeat: repeat-y;
//   clip-path: polygon(100% 0, 100% 77%, 83% 100%, 0 100%, 0 17%, 15% 0);
//   left: 0;
//   transform: scaleY(-1)
// }

@media(min-width:769px) {
  [class*="dots-"][class*="lrDown"]::before {
    width: 400px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg")
  }
}

@media(max-width:768px) {
  [class*="dots-"][class*="lrDown"]::before {
    width: 220px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg")
  }
}

@media(max-width:420px) {
  [class*="dots-"][class*="lrDown"]::before {
    width: 120px
  }
}

// [class*="dots-"][class*="lrDown"]::after {
//   --color--dots: transparent;
//   // display: block;
//   display: none;
//   content: "";
//   position: absolute;
//   top: 0;
//   height: 100%;
//   opacity: .1;
//   -webkit-mask-size: 100%;
//   mask-size: 100%;
//   -webkit-mask-repeat: repeat-y;
//   mask-repeat: repeat-y;
//   -webkit-mask-position: center;
//   mask-position: center;
//   background-color: var(--color--dots);
//   background-repeat: repeat-y;
//   clip-path: polygon(100% 0, 100% 77%, 83% 100%, 0 100%, 0 17%, 15% 0);
//   right: 0;
//   transform: scaleX(-1)
// }

@media(min-width:769px) {
  [class*="dots-"][class*="lrDown"]::after {
    width: 400px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-desktop.svg")
  }
}

@media(max-width:768px) {
  [class*="dots-"][class*="lrDown"]::after {
    width: 220px;
    // -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg");
    // mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/textures/texture-halftone-tablet.svg")
  }
}

@media(max-width:420px) {
  [class*="dots-"][class*="lrDown"]::after {
    width: 120px
  }
}

[class*="dots-"][class*="textureDark"]::before,
[class*="dots-"][class*="textureDark"]::after {
  --color--dots: var(--color--accent-03-primary)
}

[class*="dots-"][class*="textureLight"]::before,
[class*="dots-"][class*="textureLight"]::after {
  --color--dots: var(--color--ui-05)
}

[class*="dots-"]>[class*="container"]:not([class*="background-video__container"]),
[class*="dots-"]>.row {
  position: relative;
  z-index: 1
}

[class*="dots-"]::after {
  // z-index: -1
}

[class*="dots-"]::after,
[class*="dots-"]::before {
  clip-path: none !important
}

.icon-next-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  transform: rotate(180deg)
}

.icon-next-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-md");
  transform: rotate(180deg)
}

.icon-next-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-lg");
  transform: rotate(180deg)
}

.icon-previous-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm")
}

.icon-previous-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-md")
}

.icon-previous-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-lg")
}

.icon-back-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-back-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-back-sm")
}

.icon-back-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-back-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-back-md")
}

.icon-back-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-back-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-back-lg")
}

.icon-close-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-sm")
}

.icon-close-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md")
}

.icon-close-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg")
}

.icon-add-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-add-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-add-sm")
}

.icon-add-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-add-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-add-md")
}

.icon-add-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-add-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-add-lg")
}

.icon-linkout-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-linkout-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-linkout-sm")
}

.icon-linkout-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-linkout-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-linkout-md")
}

.icon-linkout-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-linkout-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-linkout-lg")
}

.icon-menu-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-menu-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-menu-md")
}

.icon-menu-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-menu-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-menu-lg")
}

.icon-home-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-home-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-home-md")
}

.icon-home-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-home-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-home-lg")
}

.icon-settings-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-settings-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-settings-md")
}

.icon-settings-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-settings-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-settings-lg")
}

.icon-search-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-sm")
}

.icon-search-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-md")
}

.icon-search-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-lg")
}

.icon-notifications-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-notifications-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-notifications-md")
}

.icon-notifications-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-notifications-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-notifications-lg")
}

.icon-print-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-print-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-print-md")
}

.icon-print-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-print-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-print-lg")
}

.icon-delete-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-delete-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-delete-md")
}

.icon-delete-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-delete-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-delete-lg")
}

.icon-edit-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-edit-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-edit-md")
}

.icon-edit-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-edit-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-edit-lg")
}

.icon-date-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-date-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-date-md")
}

.icon-date-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-date-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-date-lg")
}

.icon-time-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-time-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-time-md")
}

.icon-time-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-time-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-time-lg")
}

.icon-zoom-in-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-zoom-in-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-zoom-in-md")
}

.icon-zoom-in-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-zoom-in-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-zoom-in-lg")
}

.icon-zoom-out-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-zoom-out-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-zoom-out-md")
}

.icon-zoom-out-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-zoom-out-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-zoom-out-lg")
}

.icon-refresh-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-refresh-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-refresh-md")
}

.icon-refresh-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-refresh-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-refresh-lg")
}

.icon-more-menu-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-more-menu-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-more-menu-sm")
}

.icon-more-menu-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-more-menu-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-more-menu-md")
}

.icon-more-menu-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-more-menu-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-more-menu-lg")
}

.icon-view-grid-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-grid-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-grid-md")
}

.icon-view-grid-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-grid-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-grid-lg")
}

.icon-view-grid-solid-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-grid-solid-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-grid-solid-md")
}

.icon-view-grid-solid-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-grid-solid-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-grid-solid-lg")
}

.icon-view-list-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-list-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-list-md")
}

.icon-view-list-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-list-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-list-lg")
}

.icon-view-list-solid-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-list-solid-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-list-solid-md")
}

.icon-view-list-solid-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-list-solid-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-list-solid-lg")
}

.icon-drawer-left-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-drawer-left-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-drawer-left-md")
}

.icon-drawer-left-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-drawer-left-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-drawer-left-lg")
}

.icon-drawer-right-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-drawer-left-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-drawer-left-md");
  transform: rotate(180deg)
}

.icon-drawer-right-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-drawer-left-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-drawer-left-lg");
  transform: rotate(180deg)
}

.icon-maximize-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-maximize-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-maximize-md")
}

.icon-maximize-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-maximize-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-maximize-lg")
}

.icon-minimize-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-minimize-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-minimize-md")
}

.icon-minimize-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-minimize-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-minimize-lg")
}

.icon-drag-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-drag-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-drag-md")
}

.icon-drag-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-drag-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-drag-lg")
}

.icon-filter-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-filter-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-filter-md")
}

.icon-filter-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-filter-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-filter-lg")
}

.icon-sort-ascending-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-sort-ascending-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-sort-ascending-md")
}

.icon-sort-ascending-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-sort-ascending-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-sort-ascending-lg")
}

.icon-sort-descending-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-sort-ascending-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-sort-ascending-md");
  transform: rotate(180deg)
}

.icon-sort-descending-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-sort-ascending-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-sort-ascending-lg");
  transform: rotate(180deg)
}

.icon-expand-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-sm")
}

.icon-expand-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md")
}

.icon-expand-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-lg")
}

.icon-collapse-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-sm");
  transform: rotate(180deg)
}

.icon-collapse-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  transform: rotate(180deg)
}

.icon-collapse-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-lg");
  transform: rotate(180deg)
}

.icon-scale-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-scale-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-scale-md")
}

.icon-scale-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-scale-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-scale-lg")
}

.icon-drop-down-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-drop-down-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-drop-down-md")
}

.icon-drop-down-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-drop-down-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-drop-down-lg")
}

.icon-drop-up-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-drop-down-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-drop-down-md");
  transform: rotate(180deg)
}

.icon-drop-up-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-drop-down-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-drop-down-lg");
  transform: rotate(180deg)
}

.icon-sortable-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-sortable-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-sortable-sm")
}

.icon-sortable-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-sortable-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-sortable-md")
}

.icon-sortable-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-sortable-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-sortable-lg")
}

.icon-show-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-show-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-show-md")
}

.icon-show-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-show-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-show-lg")
}

.icon-hide-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-hide-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-hide-md")
}

.icon-hide-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-hide-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-hide-lg")
}

.icon-unlock-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-unlock-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-unlock-sm")
}

.icon-unlock-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-unlock-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-unlock-md")
}

.icon-unlock-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-unlock-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-unlock-lg")
}

.icon-locked-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-locked-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-locked-sm")
}

.icon-locked-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-locked-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-locked-md")
}

.icon-locked-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-locked-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-locked-lg")
}

.icon-item-add-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-item-add-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-item-add-sm")
}

.icon-item-add-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-item-add-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-locked-md")
}

.icon-item-add-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-item-add-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-locked-lg")
}

.icon-item-remove-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-item-remove-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-item-remove-sm")
}

.icon-item-remove-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-item-remove-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-item-remove-md")
}

.icon-item-remove-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-item-remove-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-item-remove-lg")
}

.icon-card-flip-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-card-flip-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-card-flip-md")
}

.icon-card-flip-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-card-flip-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-card-flip-lg")
}

.icon-play-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-play-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-play-md")
}

.icon-play-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-play-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-play-lg")
}

.icon-pause-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-pause-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-pause-md")
}

.icon-pause-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-pause-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-pause-lg")
}

.icon-fast-forward-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-fast-forward-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-fast-forward-md")
}

.icon-fast-forward-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-fast-forward-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-fast-forward-lg")
}

.icon-rewind-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-fast-forward-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-fast-forward-md");
  transform: rotate(180deg)
}

.icon-rewind-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-fast-forward-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-fast-forward-lg");
  transform: rotate(180deg)
}

.icon-forward-10-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-forward-10-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-forward-10-md")
}

.icon-forward-10-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-forward-10-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-forward-10-lg")
}

.icon-backward-10-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-backward-10-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-backward-10-md")
}

.icon-backward-10-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-backward-10-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-backward-10-lg")
}

.icon-fullscreen-on-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-fullscreen-on-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-fullscreen-on-md")
}

.icon-fullscreen-on-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-fullscreen-on-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-fullscreen-on-lg")
}

.icon-video-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-video-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-video-md")
}

.icon-video-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-video-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-video-lg")
}

.icon-fullscreen-off-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-fullscreen-off-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-fullscreen-off-md")
}

.icon-fullscreen-off-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-fullscreen-off-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-fullscreen-off-lg")
}

.icon-enlarge-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-enlarge-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-enlarge-md")
}

.icon-enlarge-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-enlarge-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-enlarge-lg")
}

.icon-mic-on-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-mic-on-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-mic-on-md")
}

.icon-mic-on-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-mic-on-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-mic-on-lg")
}

.icon-mic-off-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-mic-off-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-mic-off-md")
}

.icon-mic-off-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-mic-off-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-mic-off-lg")
}

.icon-volume-up-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-volume-up-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-volume-up-md")
}

.icon-volume-up-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-volume-up-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-volume-up-lg")
}

.icon-volume-down-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-volume-down-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-volume-down-md")
}

.icon-volume-down-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-volume-down-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-volume-down-lg")
}

.icon-mute-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-mute-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-mute-md")
}

.icon-mute-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-mute-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-mute-lg")
}

.icon-active-section-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-active-section-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-active-section-sm")
}

.icon-active-section-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-active-section-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-active-section-md")
}

.icon-active-section-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-active-section-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-active-section-lg")
}

.icon-view-picture-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-picture-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-picture-md")
}

.icon-view-picture-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-picture-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-view-picture-lg")
}

.icon-listen-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-listen-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-listen-md")
}

.icon-listen-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-listen-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-listen-lg")
}

.icon-virtual-reality-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-virtual-reality-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-virtual-reality-md")
}

.icon-virtual-reality-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-virtual-reality-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-virtual-reality-lg")
}

.icon-share-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-share-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-share-sm")
}

.icon-share-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-share-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-share-md")
}

.icon-share-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-share-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-share-lg")
}

.icon-copy-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-copy-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-copy-sm")
}

.icon-copy-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-copy-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-copy-md")
}

.icon-copy-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-copy-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-copy-lg")
}

.icon-file-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-file-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-file-sm")
}

.icon-file-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-file-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-file-md")
}

.icon-file-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-file-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-file-lg")
}

.icon-download-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-download-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-download-sm")
}

.icon-download-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-download-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-download-md")
}

.icon-download-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-download-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-download-lg")
}

.icon-broadcast-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-broadcast-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-broadcast-md")
}

.icon-warning-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-warning-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-warning-sm")
}

.icon-warning-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-warning-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-warning-md")
}

.icon-warning-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-warning-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-warning-lg")
}

.icon-chat-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-chat-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-chat-md")
}

.icon-chat-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-chat-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-chat-lg")
}

.icon-information-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-information-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-information-sm")
}

.icon-information-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-information-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-information-md")
}

.icon-information-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-information-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-information-lg")
}

.icon-help-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-help-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-help-md")
}

.icon-help-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-help-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-help-lg")
}

.icon-email-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-email-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-email-md")
}

.icon-email-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-email-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-email-lg")
}

.icon-error-inline-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-error-inline-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-error-inline-sm")
}

.icon-error-inline-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-error-inline-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-error-inline-md")
}

.icon-error-inline-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-error-inline-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-error-inline-lg")
}

.icon-warning-inline-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-warning-inline-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-warning-inline-sm")
}

.icon-warning-inline-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-warning-inline-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-warning-inline-md")
}

.icon-warning-inline-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-warning-inline-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-warning-inline-lg")
}

.icon-success-inline-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-success-inline-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-success-inline-sm")
}

.icon-success-inline-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-success-inline-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-success-inline-md")
}

.icon-success-inline-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-success-inline-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-success-inline-lg")
}

.icon-information-inline-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-information-inline-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-information-inline-sm")
}

.icon-information-inline-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-information-inline-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-information-inline-md")
}

.icon-information-inline-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-information-inline-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-information-inline-lg")
}

.icon-alert-banner-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-alert-banner-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-information-banner-sm")
}

.icon-alert-banner-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-alert-banner-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-information-banner-md")
}

.icon-alert-banner-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-alert-banner-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-information-banner-lg")
}

.icon-warning-banner-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-warning-banner-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-warning-banner-sm")
}

.icon-warning-banner-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-warning-banner-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-warning-banner-md")
}

.icon-warning-banner-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-warning-banner-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-warning-banner-lg")
}

.icon-success-banner-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-success-banner-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-success-banner-sm")
}

.icon-success-banner-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-success-banner-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-success-banner-md")
}

.icon-success-banner-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-success-banner-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-success-banner-lg")
}

.icon-information-banner-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-information-banner-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-information-banner-sm")
}

.icon-information-banner-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-information-banner-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-information-banner-md")
}

.icon-information-banner-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-information-banner-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-information-banner-lg")
}

.icon-profile-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-profile-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-profile-md")
}

.icon-profile-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-profile-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-profile-lg")
}

.icon-group-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-group-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-group-md")
}

.icon-group-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-group-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-group-lg")
}

.icon-language-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-language-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-language-md")
}

.icon-language-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-language-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-language-lg")
}

.icon-cart-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-cart-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-cart-md")
}

.icon-cart-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-cart-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-cart-lg")
}

.icon-delivery-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-delivery-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-delivery-sm")
}

.icon-delivery-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-delivery-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-delivery-md")
}

.icon-delivery-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-delivery-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-delivery-lg")
}

.icon-envelope-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-envelope-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-envelope-sm")
}

.icon-envelope-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-envelope-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-envelope-md")
}

.icon-envelope-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-envelope-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-envelope-lg")
}

.icon-location-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-location-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-location-sm")
}

.icon-location-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-location-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-location-md")
}

.icon-location-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-location-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-location-lg")
}

.icon-order-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-order-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-order-sm")
}

.icon-order-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-order-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-order-md")
}

.icon-order-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-order-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-order-lg")
}

.icon-payment-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-payment-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-payment-sm")
}

.icon-payment-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-payment-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-payment-md")
}

.icon-payment-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-payment-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-payment-lg")
}

.icon-preorder-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-preorder-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-preorder-sm")
}

.icon-preorder-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-preorder-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-preorder-md")
}

.icon-preorder-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-preorder-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-preorder-lg")
}

.icon-facebook-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-facebook-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-facebook-md")
}

.icon-facebook-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-facebook-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-facebook-lg")
}

.icon-twitter-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-twitter-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-twitter-md")
}

.icon-twitter-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-twitter-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-twitter-lg")
}

.icon-instagram-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-instagram-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-instagram-md")
}

.icon-instagram-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-instagram-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-instagram-lg")
}

.icon-linkedin-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-linkedin-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-linkedin-md")
}

.icon-linkedin-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-linkedin-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-linkedin-lg")
}

.icon-youtube-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-youtube-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-youtube-md")
}

.icon-youtube-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-youtube-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-youtube-lg")
}

.icon-mailSocial-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-mailSocial-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-mailSocial-md")
}

.icon-mailSocial-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-mailSocial-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-mailSocial-lg")
}

.icon-facebookColor-md::after {
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-facebookColor-md")
}

.icon-facebookColor-lg::after {
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-facebookColor-lg")
}

.icon-linkedinColor-md::after {
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-linkedinColor-md")
}

.icon-linkedinColor-lg::after {
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-linkedinColor-lg")
}

.icon-chromeColor-md::after {
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-chromeColor-md")
}

.icon-chromeColor-lg::after {
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-chromeColor-lg")
}

.icon-mastercard-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 27px;
  height: 18px;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-creditcards.svg#icon-mastercard-md")
}

.icon-mastercard-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 35px;
  height: 24px;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-creditcards.svg#icon-mastercard-lg")
}

.icon-visa-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 27px;
  height: 18px;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-creditcards.svg#icon-visa-md")
}

.icon-visa-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 35px;
  height: 24px;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-creditcards.svg#icon-visa-lg")
}

.icon-discover-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 27px;
  height: 18px;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-creditcards.svg#icon-discover-md")
}

.icon-discover-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 35px;
  height: 24px;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-creditcards.svg#icon-discover-lg")
}

.icon-amex-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 27px;
  height: 18px;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-creditcards.svg#icon-amex-md")
}

.icon-amex-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 35px;
  height: 24px;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-creditcards.svg#icon-amex-lg")
}

.icon-paypal-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 27px;
  height: 18px;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-creditcards.svg#icon-paypal-md")
}

.icon-paypal-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 35px;
  height: 24px;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-creditcards.svg#icon-paypal-lg")
}

.icon-applepay-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 27px;
  height: 18px;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-creditcards.svg#icon-applepay-md")
}

.icon-applepay-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 35px;
  height: 24px;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-creditcards.svg#icon-applepay-lg")
}

.icon-amazonpay-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 27px;
  height: 18px;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-creditcards.svg#icon-amazonpay-md")
}

.icon-amazonpay-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 35px;
  height: 24px;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-creditcards.svg#icon-amazonpay-lg")
}

.icon-googlepay-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 27px;
  height: 18px;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-creditcards.svg#icon-googlepay-md")
}

.icon-googlepay-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 35px;
  height: 24px;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-creditcards.svg#icon-googlepay-lg")
}

.icon-flag-ca::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 21px;
  height: 15px;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-flags.svg#icon-flag-ca")
}

.icon-flag-ca-rd::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 24px;
  height: 24px;
  background-size: 40px 24px;
  background-position: center;
  border-radius: 50%;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-flags.svg#icon-flag-ca")
}

.icon-flag-gb::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 21px;
  height: 15px;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-flags.svg#icon-flag-gb")
}

.icon-flag-gb-rd::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 24px;
  height: 24px;
  background-size: 40px 24px;
  background-position: center;
  border-radius: 50%;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-flags.svg#icon-flag-gb")
}

.icon-flag-us::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 21px;
  height: 15px;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-flags.svg#icon-flag-us")
}

.icon-flag-us-rd::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 24px;
  height: 24px;
  background-size: 40px 24px;
  background-position: center;
  border-radius: 50%;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-flags.svg#icon-flag-us")
}

.icon-laptop-sm::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-platform.svg#icon-laptop-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-platform.svg#icon-laptop-sm")
}

.icon-laptop-md::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-platform.svg#icon-laptop-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-platform.svg#icon-laptop-md")
}

.icon-laptop-lg::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-platform.svg#icon-laptop-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-platform.svg#icon-laptop-lg")
}

.icon-right::after {
  margin-inline-start: var(--space-xxsmall)
}

.icon-left {
  padding-inline-start: 2.875em !important
}

.icon-left::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 1.25em
}

.icon-left::after {
  margin-inline-end: var(--space-xxsmall)
}

[class*="rule-"] {
  position: relative
}

[class*="-hrTop"]::before {
  display: block;
  content: "";
  width: 100%;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0
}

[class*="-hrTop"][class*="-hrPrimary"]::before {
  background-color: var(--color--ui-border-01, var(--color--border-01))
}

[class*="-hrTop"][class*="-hrSecondary"]::before {
  background-color: var(--color--ui-border-02, var(--color--border-02))
}

[class*="-hrTop"][class*="-hrSm"]::before {
  height: 1px
}

[class*="-hrTop"][class*="-hrMd"]::before {
  height: 2px
}

[class*="-hrTop"][class*="-hrLg"]::before {
  height: 4px
}

[class*="-hrBottom"]::after {
  display: block;
  content: "";
  width: 100%;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0
}

[class*="-hrBottom"][class*="-hrPrimary"]::after {
  background-color: var(--color--ui-border-01, var(--color--border-01))
}

[class*="-hrBottom"][class*="-hrSecondary"]::after {
  background-color: var(--color--ui-border-02, var(--color--border-02))
}

[class*="-hrBottom"][class*="-hrSm"]::after {
  height: 1px
}

[class*="-hrBottom"][class*="-hrMd"]::after {
  height: 2px
}

[class*="-hrBottom"][class*="-hrLg"]::after {
  height: 4px
}

.use-button-primary.use-button-primary:not(div),
.use-button-primary.use-button-primary a:not([name]):not([id]),
.use-button-primary.use-button-primary button {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1
}

@media(min-width:376px) {

  .use-button-primary.use-button-primary:not(div),
  .use-button-primary.use-button-primary a:not([name]):not([id]),
  .use-button-primary.use-button-primary button {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {

  .use-button-primary.use-button-primary:not(div),
  .use-button-primary.use-button-primary a:not([name]):not([id]),
  .use-button-primary.use-button-primary button {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.use-button-primary.use-button-primary:not(div)::before,
.use-button-primary.use-button-primary a:not([name]):not([id])::before,
.use-button-primary.use-button-primary button::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.use-button-primary.use-button-primary:not(div):hover,
.use-button-primary.use-button-primary a:not([name]):not([id]):hover,
.use-button-primary.use-button-primary button:hover,
.use-button-primary.use-button-primary:not(div):focus,
.use-button-primary.use-button-primary a:not([name]):not([id]):focus,
.use-button-primary.use-button-primary button:focus,
.use-button-primary.use-button-primary:not(div):focus-visible,
.use-button-primary.use-button-primary a:not([name]):not([id]):focus-visible,
.use-button-primary.use-button-primary button:focus-visible,
.use-button-primary.use-button-primary:not(div):active,
.use-button-primary.use-button-primary a:not([name]):not([id]):active,
.use-button-primary.use-button-primary button:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.use-button-primary.use-button-primary:not(div):hover::before,
.use-button-primary.use-button-primary a:not([name]):not([id]):hover::before,
.use-button-primary.use-button-primary button:hover::before,
.use-button-primary.use-button-primary:not(div):focus::before,
.use-button-primary.use-button-primary a:not([name]):not([id]):focus::before,
.use-button-primary.use-button-primary button:focus::before,
.use-button-primary.use-button-primary:not(div):focus-visible::before,
.use-button-primary.use-button-primary a:not([name]):not([id]):focus-visible::before,
.use-button-primary.use-button-primary button:focus-visible::before,
.use-button-primary.use-button-primary:not(div):active::before,
.use-button-primary.use-button-primary a:not([name]):not([id]):active::before,
.use-button-primary.use-button-primary button:active::before {
  transform: scale(1.05)
}

.use-button-primary.use-button-primary:not(div):focus,
.use-button-primary.use-button-primary a:not([name]):not([id]):focus,
.use-button-primary.use-button-primary button:focus,
.use-button-primary.use-button-primary:not(div):focus-visible,
.use-button-primary.use-button-primary a:not([name]):not([id]):focus-visible,
.use-button-primary.use-button-primary button:focus-visible {
  outline: 0
}

.use-button-primary.use-button-primary:not(div):focus::before,
.use-button-primary.use-button-primary a:not([name]):not([id]):focus::before,
.use-button-primary.use-button-primary button:focus::before,
.use-button-primary.use-button-primary:not(div):focus-visible::before,
.use-button-primary.use-button-primary a:not([name]):not([id]):focus-visible::before,
.use-button-primary.use-button-primary button:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.use-button-primary.use-button-primary:not(div):active::before,
.use-button-primary.use-button-primary a:not([name]):not([id]):active::before,
.use-button-primary.use-button-primary button:active::before {
  outline-color: transparent
}

.use-button-primary.use-button-primary:not(div):only-of-type,
.use-button-primary.use-button-primary a:not([name]):not([id]):only-of-type,
.use-button-primary.use-button-primary button:only-of-type {
  margin: 0
}

.use-button-primary.use-button-primary:not(div).icon-only,
.use-button-primary.use-button-primary a:not([name]):not([id]).icon-only,
.use-button-primary.use-button-primary button.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.use-button-primary.use-button-primary:not(div)::before,
.use-button-primary.use-button-primary a:not([name]):not([id])::before,
.use-button-primary.use-button-primary button::before {
  background: var(--color--interactive-primary) border-box
}

.use-button-primary.use-button-primary:not(div):hover::before,
.use-button-primary.use-button-primary a:not([name]):not([id]):hover::before,
.use-button-primary.use-button-primary button:hover::before {
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-primary-hover) border-box
}

.use-button-primary.use-button-primary:not(div):focus,
.use-button-primary.use-button-primary a:not([name]):not([id]):focus,
.use-button-primary.use-button-primary button:focus,
.use-button-primary.use-button-primary:not(div):focus-visible,
.use-button-primary.use-button-primary a:not([name]):not([id]):focus-visible,
.use-button-primary.use-button-primary button:focus-visible {
  color: var(--color--text-primary-inverse)
}

.use-button-primary.use-button-primary:not(div):focus::before,
.use-button-primary.use-button-primary a:not([name]):not([id]):focus::before,
.use-button-primary.use-button-primary button:focus::before,
.use-button-primary.use-button-primary:not(div):focus-visible::before,
.use-button-primary.use-button-primary a:not([name]):not([id]):focus-visible::before,
.use-button-primary.use-button-primary button:focus-visible::before {
  background: var(--color--interactive-primary) border-box
}

.use-button-primary.use-button-primary:not(div):active,
.use-button-primary.use-button-primary a:not([name]):not([id]):active,
.use-button-primary.use-button-primary button:active {
  color: var(--color--text-primary-inverse)
}

.use-button-primary.use-button-primary:not(div):active::before,
.use-button-primary.use-button-primary a:not([name]):not([id]):active::before,
.use-button-primary.use-button-primary button:active::before {
  background: var(--color--interactive-primary-active) border-box
}

.use-button-primary.use-button-primary:not(div)[disabled],
.use-button-primary.use-button-primary a:not([name]):not([id])[disabled],
.use-button-primary.use-button-primary button[disabled],
.use-button-primary.use-button-primary:not(div).button-disabled,
.use-button-primary.use-button-primary a:not([name]):not([id]).button-disabled,
.use-button-primary.use-button-primary button.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.use-button-primary.use-button-primary:not(div)[disabled]::before,
.use-button-primary.use-button-primary a:not([name]):not([id])[disabled]::before,
.use-button-primary.use-button-primary button[disabled]::before,
.use-button-primary.use-button-primary:not(div).button-disabled::before,
.use-button-primary.use-button-primary a:not([name]):not([id]).button-disabled::before,
.use-button-primary.use-button-primary button.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.use-button-primary.use-button-primary:not(div)[disabled]:hover::before,
.use-button-primary.use-button-primary a:not([name]):not([id])[disabled]:hover::before,
.use-button-primary.use-button-primary button[disabled]:hover::before,
.use-button-primary.use-button-primary:not(div).button-disabled:hover::before,
.use-button-primary.use-button-primary a:not([name]):not([id]).button-disabled:hover::before,
.use-button-primary.use-button-primary button.button-disabled:hover::before,
.use-button-primary.use-button-primary:not(div)[disabled]:focus::before,
.use-button-primary.use-button-primary a:not([name]):not([id])[disabled]:focus::before,
.use-button-primary.use-button-primary button[disabled]:focus::before,
.use-button-primary.use-button-primary:not(div).button-disabled:focus::before,
.use-button-primary.use-button-primary a:not([name]):not([id]).button-disabled:focus::before,
.use-button-primary.use-button-primary button.button-disabled:focus::before,
.use-button-primary.use-button-primary:not(div)[disabled]:focus-visible::before,
.use-button-primary.use-button-primary a:not([name]):not([id])[disabled]:focus-visible::before,
.use-button-primary.use-button-primary button[disabled]:focus-visible::before,
.use-button-primary.use-button-primary:not(div).button-disabled:focus-visible::before,
.use-button-primary.use-button-primary a:not([name]):not([id]).button-disabled:focus-visible::before,
.use-button-primary.use-button-primary button.button-disabled:focus-visible::before,
.use-button-primary.use-button-primary:not(div)[disabled]:active::before,
.use-button-primary.use-button-primary a:not([name]):not([id])[disabled]:active::before,
.use-button-primary.use-button-primary button[disabled]:active::before,
.use-button-primary.use-button-primary:not(div).button-disabled:active::before,
.use-button-primary.use-button-primary a:not([name]):not([id]).button-disabled:active::before,
.use-button-primary.use-button-primary button.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.use-button-primary-inverse.use-button-primary-inverse:not(div),
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]),
.use-button-primary-inverse.use-button-primary-inverse button {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary-inverse)
}

@media(min-width:376px) {

  .use-button-primary-inverse.use-button-primary-inverse:not(div),
  .use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]),
  .use-button-primary-inverse.use-button-primary-inverse button {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {

  .use-button-primary-inverse.use-button-primary-inverse:not(div),
  .use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]),
  .use-button-primary-inverse.use-button-primary-inverse button {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.use-button-primary-inverse.use-button-primary-inverse:not(div)::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id])::before,
.use-button-primary-inverse.use-button-primary-inverse button::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.use-button-primary-inverse.use-button-primary-inverse:not(div):hover,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):hover,
.use-button-primary-inverse.use-button-primary-inverse button:hover,
.use-button-primary-inverse.use-button-primary-inverse:not(div):focus,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):focus,
.use-button-primary-inverse.use-button-primary-inverse button:focus,
.use-button-primary-inverse.use-button-primary-inverse:not(div):focus-visible,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):focus-visible,
.use-button-primary-inverse.use-button-primary-inverse button:focus-visible,
.use-button-primary-inverse.use-button-primary-inverse:not(div):active,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):active,
.use-button-primary-inverse.use-button-primary-inverse button:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.use-button-primary-inverse.use-button-primary-inverse:not(div):hover::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):hover::before,
.use-button-primary-inverse.use-button-primary-inverse button:hover::before,
.use-button-primary-inverse.use-button-primary-inverse:not(div):focus::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):focus::before,
.use-button-primary-inverse.use-button-primary-inverse button:focus::before,
.use-button-primary-inverse.use-button-primary-inverse:not(div):focus-visible::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):focus-visible::before,
.use-button-primary-inverse.use-button-primary-inverse button:focus-visible::before,
.use-button-primary-inverse.use-button-primary-inverse:not(div):active::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):active::before,
.use-button-primary-inverse.use-button-primary-inverse button:active::before {
  transform: scale(1.05)
}

.use-button-primary-inverse.use-button-primary-inverse:not(div):focus,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):focus,
.use-button-primary-inverse.use-button-primary-inverse button:focus,
.use-button-primary-inverse.use-button-primary-inverse:not(div):focus-visible,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):focus-visible,
.use-button-primary-inverse.use-button-primary-inverse button:focus-visible {
  outline: 0
}

.use-button-primary-inverse.use-button-primary-inverse:not(div):focus::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):focus::before,
.use-button-primary-inverse.use-button-primary-inverse button:focus::before,
.use-button-primary-inverse.use-button-primary-inverse:not(div):focus-visible::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):focus-visible::before,
.use-button-primary-inverse.use-button-primary-inverse button:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.use-button-primary-inverse.use-button-primary-inverse:not(div):active::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):active::before,
.use-button-primary-inverse.use-button-primary-inverse button:active::before {
  outline-color: transparent
}

.use-button-primary-inverse.use-button-primary-inverse:not(div):only-of-type,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):only-of-type,
.use-button-primary-inverse.use-button-primary-inverse button:only-of-type {
  margin: 0
}

.use-button-primary-inverse.use-button-primary-inverse:not(div).icon-only,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]).icon-only,
.use-button-primary-inverse.use-button-primary-inverse button.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.use-button-primary-inverse.use-button-primary-inverse:not(div)::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id])::before,
.use-button-primary-inverse.use-button-primary-inverse button::before {
  background: var(--color--interactive-primary-inverse)
}

.use-button-primary-inverse.use-button-primary-inverse:not(div):hover,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):hover,
.use-button-primary-inverse.use-button-primary-inverse button:hover {
  color: var(--color--text-primary-inverse)
}

.use-button-primary-inverse.use-button-primary-inverse:not(div):hover::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):hover::before,
.use-button-primary-inverse.use-button-primary-inverse button:hover::before {
  background: var(--color--interactive-primary-inverse-hover, var(--color--interactive-primary-hover-inverse))
}

.use-button-primary-inverse.use-button-primary-inverse:not(div):focus,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):focus,
.use-button-primary-inverse.use-button-primary-inverse button:focus,
.use-button-primary-inverse.use-button-primary-inverse:not(div):focus-visible,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):focus-visible,
.use-button-primary-inverse.use-button-primary-inverse button:focus-visible {
  color: var(--color--text-primary-inverse)
}

.use-button-primary-inverse.use-button-primary-inverse:not(div):focus::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):focus::before,
.use-button-primary-inverse.use-button-primary-inverse button:focus::before,
.use-button-primary-inverse.use-button-primary-inverse:not(div):focus-visible::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):focus-visible::before,
.use-button-primary-inverse.use-button-primary-inverse button:focus-visible::before {
  background: var(--color--interactive-primary-inverse)
}

.use-button-primary-inverse.use-button-primary-inverse:not(div):active,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):active,
.use-button-primary-inverse.use-button-primary-inverse button:active {
  color: var(--color--text-primary-inverse)
}

.use-button-primary-inverse.use-button-primary-inverse:not(div):active::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):active::before,
.use-button-primary-inverse.use-button-primary-inverse button:active::before {
  background: var(--color--interactive-primary-inverse-active, var(--color--interactive-primary-active-inverse))
}

.use-button-primary-inverse.use-button-primary-inverse:not(div)[disabled],
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id])[disabled],
.use-button-primary-inverse.use-button-primary-inverse button[disabled],
.use-button-primary-inverse.use-button-primary-inverse:not(div).button-disabled,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]).button-disabled,
.use-button-primary-inverse.use-button-primary-inverse button.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02);
  color: var(--color--interactive-disabled-03)
}

.use-button-primary-inverse.use-button-primary-inverse:not(div)[disabled]::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id])[disabled]::before,
.use-button-primary-inverse.use-button-primary-inverse button[disabled]::before,
.use-button-primary-inverse.use-button-primary-inverse:not(div).button-disabled::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]).button-disabled::before,
.use-button-primary-inverse.use-button-primary-inverse button.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.use-button-primary-inverse.use-button-primary-inverse:not(div)[disabled]:hover::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id])[disabled]:hover::before,
.use-button-primary-inverse.use-button-primary-inverse button[disabled]:hover::before,
.use-button-primary-inverse.use-button-primary-inverse:not(div).button-disabled:hover::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]).button-disabled:hover::before,
.use-button-primary-inverse.use-button-primary-inverse button.button-disabled:hover::before,
.use-button-primary-inverse.use-button-primary-inverse:not(div)[disabled]:focus::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id])[disabled]:focus::before,
.use-button-primary-inverse.use-button-primary-inverse button[disabled]:focus::before,
.use-button-primary-inverse.use-button-primary-inverse:not(div).button-disabled:focus::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]).button-disabled:focus::before,
.use-button-primary-inverse.use-button-primary-inverse button.button-disabled:focus::before,
.use-button-primary-inverse.use-button-primary-inverse:not(div)[disabled]:focus-visible::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id])[disabled]:focus-visible::before,
.use-button-primary-inverse.use-button-primary-inverse button[disabled]:focus-visible::before,
.use-button-primary-inverse.use-button-primary-inverse:not(div).button-disabled:focus-visible::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]).button-disabled:focus-visible::before,
.use-button-primary-inverse.use-button-primary-inverse button.button-disabled:focus-visible::before,
.use-button-primary-inverse.use-button-primary-inverse:not(div)[disabled]:active::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id])[disabled]:active::before,
.use-button-primary-inverse.use-button-primary-inverse button[disabled]:active::before,
.use-button-primary-inverse.use-button-primary-inverse:not(div).button-disabled:active::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]).button-disabled:active::before,
.use-button-primary-inverse.use-button-primary-inverse button.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.use-button-primary-inverse.use-button-primary-inverse:not(div)[disabled]::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id])[disabled]::before,
.use-button-primary-inverse.use-button-primary-inverse button[disabled]::before,
.use-button-primary-inverse.use-button-primary-inverse:not(div).button-disabled::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]).button-disabled::before,
.use-button-primary-inverse.use-button-primary-inverse button.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.use-button-primary-inverse.use-button-primary-inverse:not(div)[disabled]:hover::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id])[disabled]:hover::before,
.use-button-primary-inverse.use-button-primary-inverse button[disabled]:hover::before,
.use-button-primary-inverse.use-button-primary-inverse:not(div).button-disabled:hover::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]).button-disabled:hover::before,
.use-button-primary-inverse.use-button-primary-inverse button.button-disabled:hover::before,
.use-button-primary-inverse.use-button-primary-inverse:not(div)[disabled]:focus::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id])[disabled]:focus::before,
.use-button-primary-inverse.use-button-primary-inverse button[disabled]:focus::before,
.use-button-primary-inverse.use-button-primary-inverse:not(div).button-disabled:focus::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]).button-disabled:focus::before,
.use-button-primary-inverse.use-button-primary-inverse button.button-disabled:focus::before,
.use-button-primary-inverse.use-button-primary-inverse:not(div)[disabled]:focus-visible::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id])[disabled]:focus-visible::before,
.use-button-primary-inverse.use-button-primary-inverse button[disabled]:focus-visible::before,
.use-button-primary-inverse.use-button-primary-inverse:not(div).button-disabled:focus-visible::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]).button-disabled:focus-visible::before,
.use-button-primary-inverse.use-button-primary-inverse button.button-disabled:focus-visible::before,
.use-button-primary-inverse.use-button-primary-inverse:not(div)[disabled]:active::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id])[disabled]:active::before,
.use-button-primary-inverse.use-button-primary-inverse button[disabled]:active::before,
.use-button-primary-inverse.use-button-primary-inverse:not(div).button-disabled:active::before,
.use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]).button-disabled:active::before,
.use-button-primary-inverse.use-button-primary-inverse button.button-disabled:active::before {
  background: var(--color--interactive-disabled-01)
}

.use-button-secondary.use-button-secondary:not(div),
.use-button-secondary.use-button-secondary a:not([name]):not([id]),
.use-button-secondary.use-button-secondary button {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1
}

@media(min-width:376px) {

  .use-button-secondary.use-button-secondary:not(div),
  .use-button-secondary.use-button-secondary a:not([name]):not([id]),
  .use-button-secondary.use-button-secondary button {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {

  .use-button-secondary.use-button-secondary:not(div),
  .use-button-secondary.use-button-secondary a:not([name]):not([id]),
  .use-button-secondary.use-button-secondary button {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.use-button-secondary.use-button-secondary:not(div)::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id])::before,
.use-button-secondary.use-button-secondary button::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.use-button-secondary.use-button-secondary:not(div):hover,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):hover,
.use-button-secondary.use-button-secondary button:hover,
.use-button-secondary.use-button-secondary:not(div):focus,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):focus,
.use-button-secondary.use-button-secondary button:focus,
.use-button-secondary.use-button-secondary:not(div):focus-visible,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):focus-visible,
.use-button-secondary.use-button-secondary button:focus-visible,
.use-button-secondary.use-button-secondary:not(div):active,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):active,
.use-button-secondary.use-button-secondary button:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.use-button-secondary.use-button-secondary:not(div):hover::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):hover::before,
.use-button-secondary.use-button-secondary button:hover::before,
.use-button-secondary.use-button-secondary:not(div):focus::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):focus::before,
.use-button-secondary.use-button-secondary button:focus::before,
.use-button-secondary.use-button-secondary:not(div):focus-visible::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):focus-visible::before,
.use-button-secondary.use-button-secondary button:focus-visible::before,
.use-button-secondary.use-button-secondary:not(div):active::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):active::before,
.use-button-secondary.use-button-secondary button:active::before {
  transform: scale(1.05)
}

.use-button-secondary.use-button-secondary:not(div):focus,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):focus,
.use-button-secondary.use-button-secondary button:focus,
.use-button-secondary.use-button-secondary:not(div):focus-visible,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):focus-visible,
.use-button-secondary.use-button-secondary button:focus-visible {
  outline: 0
}

.use-button-secondary.use-button-secondary:not(div):focus::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):focus::before,
.use-button-secondary.use-button-secondary button:focus::before,
.use-button-secondary.use-button-secondary:not(div):focus-visible::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):focus-visible::before,
.use-button-secondary.use-button-secondary button:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.use-button-secondary.use-button-secondary:not(div):active::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):active::before,
.use-button-secondary.use-button-secondary button:active::before {
  outline-color: transparent
}

.use-button-secondary.use-button-secondary:not(div):only-of-type,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):only-of-type,
.use-button-secondary.use-button-secondary button:only-of-type {
  margin: 0
}

.use-button-secondary.use-button-secondary:not(div).icon-only,
.use-button-secondary.use-button-secondary a:not([name]):not([id]).icon-only,
.use-button-secondary.use-button-secondary button.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.use-button-secondary.use-button-secondary:not(div)::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id])::before,
.use-button-secondary.use-button-secondary button::before {
  background: var(--color--interactive-secondary) border-box
}

.use-button-secondary.use-button-secondary:not(div):hover,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):hover,
.use-button-secondary.use-button-secondary button:hover {
  color: var(--color--text-primary-inverse)
}

.use-button-secondary.use-button-secondary:not(div):hover::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):hover::before,
.use-button-secondary.use-button-secondary button:hover::before {
  background: var(--color--interactive-secondary-hover) border-box
}

.use-button-secondary.use-button-secondary:not(div):focus,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):focus,
.use-button-secondary.use-button-secondary button:focus,
.use-button-secondary.use-button-secondary:not(div):focus-visible,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):focus-visible,
.use-button-secondary.use-button-secondary button:focus-visible {
  color: var(--color--text-primary-inverse)
}

.use-button-secondary.use-button-secondary:not(div):focus::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):focus::before,
.use-button-secondary.use-button-secondary button:focus::before,
.use-button-secondary.use-button-secondary:not(div):focus-visible::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):focus-visible::before,
.use-button-secondary.use-button-secondary button:focus-visible::before {
  background: var(--color--interactive-secondary) border-box
}

.use-button-secondary.use-button-secondary:not(div):active,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):active,
.use-button-secondary.use-button-secondary button:active {
  color: var(--color--text-primary-inverse)
}

.use-button-secondary.use-button-secondary:not(div):active::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id]):active::before,
.use-button-secondary.use-button-secondary button:active::before {
  background: var(--color--interactive-secondary-active) border-box
}

.use-button-secondary.use-button-secondary:not(div)[disabled],
.use-button-secondary.use-button-secondary a:not([name]):not([id])[disabled],
.use-button-secondary.use-button-secondary button[disabled],
.use-button-secondary.use-button-secondary:not(div).button-disabled,
.use-button-secondary.use-button-secondary a:not([name]):not([id]).button-disabled,
.use-button-secondary.use-button-secondary button.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.use-button-secondary.use-button-secondary:not(div)[disabled]::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id])[disabled]::before,
.use-button-secondary.use-button-secondary button[disabled]::before,
.use-button-secondary.use-button-secondary:not(div).button-disabled::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id]).button-disabled::before,
.use-button-secondary.use-button-secondary button.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.use-button-secondary.use-button-secondary:not(div)[disabled]:hover::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id])[disabled]:hover::before,
.use-button-secondary.use-button-secondary button[disabled]:hover::before,
.use-button-secondary.use-button-secondary:not(div).button-disabled:hover::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id]).button-disabled:hover::before,
.use-button-secondary.use-button-secondary button.button-disabled:hover::before,
.use-button-secondary.use-button-secondary:not(div)[disabled]:focus::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id])[disabled]:focus::before,
.use-button-secondary.use-button-secondary button[disabled]:focus::before,
.use-button-secondary.use-button-secondary:not(div).button-disabled:focus::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id]).button-disabled:focus::before,
.use-button-secondary.use-button-secondary button.button-disabled:focus::before,
.use-button-secondary.use-button-secondary:not(div)[disabled]:focus-visible::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id])[disabled]:focus-visible::before,
.use-button-secondary.use-button-secondary button[disabled]:focus-visible::before,
.use-button-secondary.use-button-secondary:not(div).button-disabled:focus-visible::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id]).button-disabled:focus-visible::before,
.use-button-secondary.use-button-secondary button.button-disabled:focus-visible::before,
.use-button-secondary.use-button-secondary:not(div)[disabled]:active::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id])[disabled]:active::before,
.use-button-secondary.use-button-secondary button[disabled]:active::before,
.use-button-secondary.use-button-secondary:not(div).button-disabled:active::before,
.use-button-secondary.use-button-secondary a:not([name]):not([id]).button-disabled:active::before,
.use-button-secondary.use-button-secondary button.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.use-button-secondary-inverse.use-button-secondary-inverse:not(div),
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]),
.use-button-secondary-inverse.use-button-secondary-inverse button {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary)
}

@media(min-width:376px) {

  .use-button-secondary-inverse.use-button-secondary-inverse:not(div),
  .use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]),
  .use-button-secondary-inverse.use-button-secondary-inverse button {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {

  .use-button-secondary-inverse.use-button-secondary-inverse:not(div),
  .use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]),
  .use-button-secondary-inverse.use-button-secondary-inverse button {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.use-button-secondary-inverse.use-button-secondary-inverse:not(div)::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id])::before,
.use-button-secondary-inverse.use-button-secondary-inverse button::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.use-button-secondary-inverse.use-button-secondary-inverse:not(div):hover,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):hover,
.use-button-secondary-inverse.use-button-secondary-inverse button:hover,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div):focus,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):focus,
.use-button-secondary-inverse.use-button-secondary-inverse button:focus,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div):focus-visible,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):focus-visible,
.use-button-secondary-inverse.use-button-secondary-inverse button:focus-visible,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div):active,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):active,
.use-button-secondary-inverse.use-button-secondary-inverse button:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.use-button-secondary-inverse.use-button-secondary-inverse:not(div):hover::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):hover::before,
.use-button-secondary-inverse.use-button-secondary-inverse button:hover::before,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div):focus::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):focus::before,
.use-button-secondary-inverse.use-button-secondary-inverse button:focus::before,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div):focus-visible::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):focus-visible::before,
.use-button-secondary-inverse.use-button-secondary-inverse button:focus-visible::before,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div):active::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):active::before,
.use-button-secondary-inverse.use-button-secondary-inverse button:active::before {
  transform: scale(1.05)
}

.use-button-secondary-inverse.use-button-secondary-inverse:not(div):focus,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):focus,
.use-button-secondary-inverse.use-button-secondary-inverse button:focus,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div):focus-visible,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):focus-visible,
.use-button-secondary-inverse.use-button-secondary-inverse button:focus-visible {
  outline: 0
}

.use-button-secondary-inverse.use-button-secondary-inverse:not(div):focus::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):focus::before,
.use-button-secondary-inverse.use-button-secondary-inverse button:focus::before,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div):focus-visible::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):focus-visible::before,
.use-button-secondary-inverse.use-button-secondary-inverse button:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.use-button-secondary-inverse.use-button-secondary-inverse:not(div):active::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):active::before,
.use-button-secondary-inverse.use-button-secondary-inverse button:active::before {
  outline-color: transparent
}

.use-button-secondary-inverse.use-button-secondary-inverse:not(div):only-of-type,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):only-of-type,
.use-button-secondary-inverse.use-button-secondary-inverse button:only-of-type {
  margin: 0
}

.use-button-secondary-inverse.use-button-secondary-inverse:not(div).icon-only,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]).icon-only,
.use-button-secondary-inverse.use-button-secondary-inverse button.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.use-button-secondary-inverse.use-button-secondary-inverse:not(div)::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id])::before,
.use-button-secondary-inverse.use-button-secondary-inverse button::before {
  background: var(--color--interactive-secondary-inverse)
}

.use-button-secondary-inverse.use-button-secondary-inverse:not(div):hover,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):hover,
.use-button-secondary-inverse.use-button-secondary-inverse button:hover {
  color: var(--color--text-primary)
}

.use-button-secondary-inverse.use-button-secondary-inverse:not(div):hover::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):hover::before,
.use-button-secondary-inverse.use-button-secondary-inverse button:hover::before {
  background: var(--color--interactive-secondary-inverse-hover, var(--color--interactive-secondary-hover-inverse))
}

.use-button-secondary-inverse.use-button-secondary-inverse:not(div):focus,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):focus,
.use-button-secondary-inverse.use-button-secondary-inverse button:focus,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div):focus-visible,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):focus-visible,
.use-button-secondary-inverse.use-button-secondary-inverse button:focus-visible {
  color: var(--color--text-primary)
}

.use-button-secondary-inverse.use-button-secondary-inverse:not(div):focus::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):focus::before,
.use-button-secondary-inverse.use-button-secondary-inverse button:focus::before,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div):focus-visible::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):focus-visible::before,
.use-button-secondary-inverse.use-button-secondary-inverse button:focus-visible::before {
  background: var(--color--interactive-secondary-inverse)
}

.use-button-secondary-inverse.use-button-secondary-inverse:not(div):active,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):active,
.use-button-secondary-inverse.use-button-secondary-inverse button:active {
  color: var(--color--text-primary)
}

.use-button-secondary-inverse.use-button-secondary-inverse:not(div):active::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]):active::before,
.use-button-secondary-inverse.use-button-secondary-inverse button:active::before {
  background: var(--color--interactive-secondary-inverse-active, var(--color--interactive-secondary-active-inverse))
}

.use-button-secondary-inverse.use-button-secondary-inverse:not(div)[disabled],
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id])[disabled],
.use-button-secondary-inverse.use-button-secondary-inverse button[disabled],
.use-button-secondary-inverse.use-button-secondary-inverse:not(div).button-disabled,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]).button-disabled,
.use-button-secondary-inverse.use-button-secondary-inverse button.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02);
  color: var(--color--interactive-disabled-03)
}

.use-button-secondary-inverse.use-button-secondary-inverse:not(div)[disabled]::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id])[disabled]::before,
.use-button-secondary-inverse.use-button-secondary-inverse button[disabled]::before,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div).button-disabled::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]).button-disabled::before,
.use-button-secondary-inverse.use-button-secondary-inverse button.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.use-button-secondary-inverse.use-button-secondary-inverse:not(div)[disabled]:hover::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id])[disabled]:hover::before,
.use-button-secondary-inverse.use-button-secondary-inverse button[disabled]:hover::before,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div).button-disabled:hover::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]).button-disabled:hover::before,
.use-button-secondary-inverse.use-button-secondary-inverse button.button-disabled:hover::before,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div)[disabled]:focus::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id])[disabled]:focus::before,
.use-button-secondary-inverse.use-button-secondary-inverse button[disabled]:focus::before,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div).button-disabled:focus::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]).button-disabled:focus::before,
.use-button-secondary-inverse.use-button-secondary-inverse button.button-disabled:focus::before,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div)[disabled]:focus-visible::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id])[disabled]:focus-visible::before,
.use-button-secondary-inverse.use-button-secondary-inverse button[disabled]:focus-visible::before,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div).button-disabled:focus-visible::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]).button-disabled:focus-visible::before,
.use-button-secondary-inverse.use-button-secondary-inverse button.button-disabled:focus-visible::before,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div)[disabled]:active::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id])[disabled]:active::before,
.use-button-secondary-inverse.use-button-secondary-inverse button[disabled]:active::before,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div).button-disabled:active::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]).button-disabled:active::before,
.use-button-secondary-inverse.use-button-secondary-inverse button.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.use-button-secondary-inverse.use-button-secondary-inverse:not(div)[disabled]::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id])[disabled]::before,
.use-button-secondary-inverse.use-button-secondary-inverse button[disabled]::before,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div).button-disabled::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]).button-disabled::before,
.use-button-secondary-inverse.use-button-secondary-inverse button.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.use-button-secondary-inverse.use-button-secondary-inverse:not(div)[disabled]:hover::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id])[disabled]:hover::before,
.use-button-secondary-inverse.use-button-secondary-inverse button[disabled]:hover::before,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div).button-disabled:hover::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]).button-disabled:hover::before,
.use-button-secondary-inverse.use-button-secondary-inverse button.button-disabled:hover::before,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div)[disabled]:focus::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id])[disabled]:focus::before,
.use-button-secondary-inverse.use-button-secondary-inverse button[disabled]:focus::before,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div).button-disabled:focus::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]).button-disabled:focus::before,
.use-button-secondary-inverse.use-button-secondary-inverse button.button-disabled:focus::before,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div)[disabled]:focus-visible::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id])[disabled]:focus-visible::before,
.use-button-secondary-inverse.use-button-secondary-inverse button[disabled]:focus-visible::before,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div).button-disabled:focus-visible::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]).button-disabled:focus-visible::before,
.use-button-secondary-inverse.use-button-secondary-inverse button.button-disabled:focus-visible::before,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div)[disabled]:active::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id])[disabled]:active::before,
.use-button-secondary-inverse.use-button-secondary-inverse button[disabled]:active::before,
.use-button-secondary-inverse.use-button-secondary-inverse:not(div).button-disabled:active::before,
.use-button-secondary-inverse.use-button-secondary-inverse a:not([name]):not([id]).button-disabled:active::before,
.use-button-secondary-inverse.use-button-secondary-inverse button.button-disabled:active::before {
  background: var(--color--interactive-disabled-01)
}

.use-button-tertiary.use-button-tertiary:not(div),
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]),
.use-button-tertiary.use-button-tertiary button {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary);
  transition: color .125s ease-in-out
}

@media(min-width:376px) {

  .use-button-tertiary.use-button-tertiary:not(div),
  .use-button-tertiary.use-button-tertiary a:not([name]):not([id]),
  .use-button-tertiary.use-button-tertiary button {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {

  .use-button-tertiary.use-button-tertiary:not(div),
  .use-button-tertiary.use-button-tertiary a:not([name]):not([id]),
  .use-button-tertiary.use-button-tertiary button {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.use-button-tertiary.use-button-tertiary:not(div)::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id])::before,
.use-button-tertiary.use-button-tertiary button::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.use-button-tertiary.use-button-tertiary:not(div):hover,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):hover,
.use-button-tertiary.use-button-tertiary button:hover,
.use-button-tertiary.use-button-tertiary:not(div):focus,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):focus,
.use-button-tertiary.use-button-tertiary button:focus,
.use-button-tertiary.use-button-tertiary:not(div):focus-visible,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):focus-visible,
.use-button-tertiary.use-button-tertiary button:focus-visible,
.use-button-tertiary.use-button-tertiary:not(div):active,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):active,
.use-button-tertiary.use-button-tertiary button:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.use-button-tertiary.use-button-tertiary:not(div):hover::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):hover::before,
.use-button-tertiary.use-button-tertiary button:hover::before,
.use-button-tertiary.use-button-tertiary:not(div):focus::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):focus::before,
.use-button-tertiary.use-button-tertiary button:focus::before,
.use-button-tertiary.use-button-tertiary:not(div):focus-visible::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):focus-visible::before,
.use-button-tertiary.use-button-tertiary button:focus-visible::before,
.use-button-tertiary.use-button-tertiary:not(div):active::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):active::before,
.use-button-tertiary.use-button-tertiary button:active::before {
  transform: scale(1.05)
}

.use-button-tertiary.use-button-tertiary:not(div):focus,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):focus,
.use-button-tertiary.use-button-tertiary button:focus,
.use-button-tertiary.use-button-tertiary:not(div):focus-visible,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):focus-visible,
.use-button-tertiary.use-button-tertiary button:focus-visible {
  outline: 0
}

.use-button-tertiary.use-button-tertiary:not(div):focus::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):focus::before,
.use-button-tertiary.use-button-tertiary button:focus::before,
.use-button-tertiary.use-button-tertiary:not(div):focus-visible::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):focus-visible::before,
.use-button-tertiary.use-button-tertiary button:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.use-button-tertiary.use-button-tertiary:not(div):active::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):active::before,
.use-button-tertiary.use-button-tertiary button:active::before {
  outline-color: transparent
}

.use-button-tertiary.use-button-tertiary:not(div):only-of-type,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):only-of-type,
.use-button-tertiary.use-button-tertiary button:only-of-type {
  margin: 0
}

.use-button-tertiary.use-button-tertiary:not(div).icon-only,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]).icon-only,
.use-button-tertiary.use-button-tertiary button.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.use-button-tertiary.use-button-tertiary:not(div)::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id])::before,
.use-button-tertiary.use-button-tertiary button::before {
  outline-color: var(--color--interactive-tertiary);
  outline-offset: -2px;
  background-color: transparent
}

.use-button-tertiary.use-button-tertiary:not(div):hover,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):hover,
.use-button-tertiary.use-button-tertiary button:hover {
  color: var(--color--text-primary-inverse)
}

.use-button-tertiary.use-button-tertiary:not(div):hover::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):hover::before,
.use-button-tertiary.use-button-tertiary button:hover::before {
  outline-color: var(--color--interactive-tertiary-hover);
  background: var(--color--interactive-tertiary-hover)
}

.use-button-tertiary.use-button-tertiary:not(div):focus,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):focus,
.use-button-tertiary.use-button-tertiary button:focus,
.use-button-tertiary.use-button-tertiary:not(div):focus-visible,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):focus-visible,
.use-button-tertiary.use-button-tertiary button:focus-visible {
  color: var(--color--text-tertiary)
}

.use-button-tertiary.use-button-tertiary:not(div):focus::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):focus::before,
.use-button-tertiary.use-button-tertiary button:focus::before,
.use-button-tertiary.use-button-tertiary:not(div):focus-visible::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):focus-visible::before,
.use-button-tertiary.use-button-tertiary button:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

.use-button-tertiary.use-button-tertiary:not(div):active,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):active,
.use-button-tertiary.use-button-tertiary button:active {
  color: var(--color--text-primary-inverse)
}

.use-button-tertiary.use-button-tertiary:not(div):active::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]):active::before,
.use-button-tertiary.use-button-tertiary button:active::before {
  background: var(--color--interactive-tertiary-active)
}

.use-button-tertiary.use-button-tertiary:not(div)[disabled],
.use-button-tertiary.use-button-tertiary a:not([name]):not([id])[disabled],
.use-button-tertiary.use-button-tertiary button[disabled],
.use-button-tertiary.use-button-tertiary:not(div).button-disabled,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]).button-disabled,
.use-button-tertiary.use-button-tertiary button.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.use-button-tertiary.use-button-tertiary:not(div)[disabled]::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id])[disabled]::before,
.use-button-tertiary.use-button-tertiary button[disabled]::before,
.use-button-tertiary.use-button-tertiary:not(div).button-disabled::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]).button-disabled::before,
.use-button-tertiary.use-button-tertiary button.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.use-button-tertiary.use-button-tertiary:not(div)[disabled]:hover::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id])[disabled]:hover::before,
.use-button-tertiary.use-button-tertiary button[disabled]:hover::before,
.use-button-tertiary.use-button-tertiary:not(div).button-disabled:hover::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]).button-disabled:hover::before,
.use-button-tertiary.use-button-tertiary button.button-disabled:hover::before,
.use-button-tertiary.use-button-tertiary:not(div)[disabled]:focus::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id])[disabled]:focus::before,
.use-button-tertiary.use-button-tertiary button[disabled]:focus::before,
.use-button-tertiary.use-button-tertiary:not(div).button-disabled:focus::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]).button-disabled:focus::before,
.use-button-tertiary.use-button-tertiary button.button-disabled:focus::before,
.use-button-tertiary.use-button-tertiary:not(div)[disabled]:focus-visible::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id])[disabled]:focus-visible::before,
.use-button-tertiary.use-button-tertiary button[disabled]:focus-visible::before,
.use-button-tertiary.use-button-tertiary:not(div).button-disabled:focus-visible::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]).button-disabled:focus-visible::before,
.use-button-tertiary.use-button-tertiary button.button-disabled:focus-visible::before,
.use-button-tertiary.use-button-tertiary:not(div)[disabled]:active::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id])[disabled]:active::before,
.use-button-tertiary.use-button-tertiary button[disabled]:active::before,
.use-button-tertiary.use-button-tertiary:not(div).button-disabled:active::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]).button-disabled:active::before,
.use-button-tertiary.use-button-tertiary button.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.use-button-tertiary.use-button-tertiary:not(div)[disabled]::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id])[disabled]::before,
.use-button-tertiary.use-button-tertiary button[disabled]::before,
.use-button-tertiary.use-button-tertiary:not(div).button-disabled::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]).button-disabled::before,
.use-button-tertiary.use-button-tertiary button.button-disabled::before {
  outline-color: var(--color--interactive-disabled-01);
  outline-offset: -2px;
  background: transparent
}

.use-button-tertiary.use-button-tertiary:not(div)[disabled]:hover::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id])[disabled]:hover::before,
.use-button-tertiary.use-button-tertiary button[disabled]:hover::before,
.use-button-tertiary.use-button-tertiary:not(div).button-disabled:hover::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]).button-disabled:hover::before,
.use-button-tertiary.use-button-tertiary button.button-disabled:hover::before,
.use-button-tertiary.use-button-tertiary:not(div)[disabled]:focus::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id])[disabled]:focus::before,
.use-button-tertiary.use-button-tertiary button[disabled]:focus::before,
.use-button-tertiary.use-button-tertiary:not(div).button-disabled:focus::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]).button-disabled:focus::before,
.use-button-tertiary.use-button-tertiary button.button-disabled:focus::before,
.use-button-tertiary.use-button-tertiary:not(div)[disabled]:focus-visible::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id])[disabled]:focus-visible::before,
.use-button-tertiary.use-button-tertiary button[disabled]:focus-visible::before,
.use-button-tertiary.use-button-tertiary:not(div).button-disabled:focus-visible::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]).button-disabled:focus-visible::before,
.use-button-tertiary.use-button-tertiary button.button-disabled:focus-visible::before,
.use-button-tertiary.use-button-tertiary:not(div)[disabled]:active::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id])[disabled]:active::before,
.use-button-tertiary.use-button-tertiary button[disabled]:active::before,
.use-button-tertiary.use-button-tertiary:not(div).button-disabled:active::before,
.use-button-tertiary.use-button-tertiary a:not([name]):not([id]).button-disabled:active::before,
.use-button-tertiary.use-button-tertiary button.button-disabled:active::before {
  background: transparent
}

.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div),
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]),
.use-button-tertiary-inverse.use-button-tertiary-inverse button {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary-inverse)
}

@media(min-width:376px) {

  .use-button-tertiary-inverse.use-button-tertiary-inverse:not(div),
  .use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]),
  .use-button-tertiary-inverse.use-button-tertiary-inverse button {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {

  .use-button-tertiary-inverse.use-button-tertiary-inverse:not(div),
  .use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]),
  .use-button-tertiary-inverse.use-button-tertiary-inverse button {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div)::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id])::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):hover,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):hover,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:hover,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):focus,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):focus,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:focus,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):focus-visible,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):focus-visible,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:focus-visible,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):active,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):active,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):hover::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):hover::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:hover::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):focus::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):focus::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:focus::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):focus-visible::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):focus-visible::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:focus-visible::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):active::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):active::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:active::before {
  transform: scale(1.05)
}

.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):focus,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):focus,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:focus,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):focus-visible,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):focus-visible,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:focus-visible {
  outline: 0
}

.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):focus::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):focus::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:focus::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):focus-visible::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):focus-visible::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):active::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):active::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:active::before {
  outline-color: transparent
}

.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):only-of-type,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):only-of-type,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:only-of-type {
  margin: 0
}

.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div).icon-only,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]).icon-only,
.use-button-tertiary-inverse.use-button-tertiary-inverse button.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div)::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id])::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button::before {
  outline-color: var(--color--interactive-tertiary-inverse);
  outline-offset: -2px;
  background: transparent
}

.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):hover,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):hover,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:hover {
  color: var(--color--text-primary)
}

.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):hover::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):hover::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:hover::before {
  outline-color: transparent;
  background: var(--color--interactive-tertiary-inverse-hover, var(--color--interactive-tertiary-hover-inverse))
}

.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):focus,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):focus,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:focus,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):focus-visible,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):focus-visible,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:focus-visible {
  color: var(--color--text-primary-inverse)
}

.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):focus::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):focus::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:focus::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):focus-visible::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):focus-visible::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):active,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):active,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:active {
  color: var(--color--text-primary)
}

.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div):active::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]):active::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button:active::before {
  background: var(--color--interactive-tertiary-inverse-active, var(--color--interactive-tertiary-active-inverse))
}

.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div)[disabled],
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id])[disabled],
.use-button-tertiary-inverse.use-button-tertiary-inverse button[disabled],
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div).button-disabled,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]).button-disabled,
.use-button-tertiary-inverse.use-button-tertiary-inverse button.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div)[disabled]::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id])[disabled]::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button[disabled]::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div).button-disabled::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]).button-disabled::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div)[disabled]:hover::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id])[disabled]:hover::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button[disabled]:hover::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div).button-disabled:hover::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]).button-disabled:hover::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button.button-disabled:hover::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div)[disabled]:focus::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id])[disabled]:focus::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button[disabled]:focus::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div).button-disabled:focus::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]).button-disabled:focus::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button.button-disabled:focus::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div)[disabled]:focus-visible::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id])[disabled]:focus-visible::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button[disabled]:focus-visible::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div).button-disabled:focus-visible::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]).button-disabled:focus-visible::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button.button-disabled:focus-visible::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div)[disabled]:active::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id])[disabled]:active::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button[disabled]:active::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div).button-disabled:active::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]).button-disabled:active::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div)[disabled]::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id])[disabled]::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button[disabled]::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div).button-disabled::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]).button-disabled::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button.button-disabled::before {
  outline-color: var(--color--interactive-disabled-02);
  background: transparent
}

.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div)[disabled]:hover::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id])[disabled]:hover::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button[disabled]:hover::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div).button-disabled:hover::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]).button-disabled:hover::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button.button-disabled:hover::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div)[disabled]:focus::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id])[disabled]:focus::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button[disabled]:focus::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div).button-disabled:focus::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]).button-disabled:focus::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button.button-disabled:focus::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div)[disabled]:focus-visible::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id])[disabled]:focus-visible::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button[disabled]:focus-visible::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div).button-disabled:focus-visible::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]).button-disabled:focus-visible::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button.button-disabled:focus-visible::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div)[disabled]:active::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id])[disabled]:active::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button[disabled]:active::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse:not(div).button-disabled:active::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse a:not([name]):not([id]).button-disabled:active::before,
.use-button-tertiary-inverse.use-button-tertiary-inverse button.button-disabled:active::before {
  background: transparent
}

.use-button-action.use-button-action:not(div),
.use-button-action.use-button-action a:not([name]):not([id]),
.use-button-action.use-button-action button {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary)
}

@media(min-width:376px) {

  .use-button-action.use-button-action:not(div),
  .use-button-action.use-button-action a:not([name]):not([id]),
  .use-button-action.use-button-action button {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {

  .use-button-action.use-button-action:not(div),
  .use-button-action.use-button-action a:not([name]):not([id]),
  .use-button-action.use-button-action button {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.use-button-action.use-button-action:not(div)::before,
.use-button-action.use-button-action a:not([name]):not([id])::before,
.use-button-action.use-button-action button::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.use-button-action.use-button-action:not(div):hover,
.use-button-action.use-button-action a:not([name]):not([id]):hover,
.use-button-action.use-button-action button:hover,
.use-button-action.use-button-action:not(div):focus,
.use-button-action.use-button-action a:not([name]):not([id]):focus,
.use-button-action.use-button-action button:focus,
.use-button-action.use-button-action:not(div):focus-visible,
.use-button-action.use-button-action a:not([name]):not([id]):focus-visible,
.use-button-action.use-button-action button:focus-visible,
.use-button-action.use-button-action:not(div):active,
.use-button-action.use-button-action a:not([name]):not([id]):active,
.use-button-action.use-button-action button:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.use-button-action.use-button-action:not(div):hover::before,
.use-button-action.use-button-action a:not([name]):not([id]):hover::before,
.use-button-action.use-button-action button:hover::before,
.use-button-action.use-button-action:not(div):focus::before,
.use-button-action.use-button-action a:not([name]):not([id]):focus::before,
.use-button-action.use-button-action button:focus::before,
.use-button-action.use-button-action:not(div):focus-visible::before,
.use-button-action.use-button-action a:not([name]):not([id]):focus-visible::before,
.use-button-action.use-button-action button:focus-visible::before,
.use-button-action.use-button-action:not(div):active::before,
.use-button-action.use-button-action a:not([name]):not([id]):active::before,
.use-button-action.use-button-action button:active::before {
  transform: scale(1.05)
}

.use-button-action.use-button-action:not(div):focus,
.use-button-action.use-button-action a:not([name]):not([id]):focus,
.use-button-action.use-button-action button:focus,
.use-button-action.use-button-action:not(div):focus-visible,
.use-button-action.use-button-action a:not([name]):not([id]):focus-visible,
.use-button-action.use-button-action button:focus-visible {
  outline: 0
}

.use-button-action.use-button-action:not(div):focus::before,
.use-button-action.use-button-action a:not([name]):not([id]):focus::before,
.use-button-action.use-button-action button:focus::before,
.use-button-action.use-button-action:not(div):focus-visible::before,
.use-button-action.use-button-action a:not([name]):not([id]):focus-visible::before,
.use-button-action.use-button-action button:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.use-button-action.use-button-action:not(div):active::before,
.use-button-action.use-button-action a:not([name]):not([id]):active::before,
.use-button-action.use-button-action button:active::before {
  outline-color: transparent
}

.use-button-action.use-button-action:not(div):only-of-type,
.use-button-action.use-button-action a:not([name]):not([id]):only-of-type,
.use-button-action.use-button-action button:only-of-type {
  margin: 0
}

.use-button-action.use-button-action:not(div).icon-only,
.use-button-action.use-button-action a:not([name]):not([id]).icon-only,
.use-button-action.use-button-action button.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.use-button-action.use-button-action:not(div)::before,
.use-button-action.use-button-action a:not([name]):not([id])::before,
.use-button-action.use-button-action button::before {
  background: var(--color--interactive-action) border-box
}

.use-button-action.use-button-action:not(div):hover::before,
.use-button-action.use-button-action a:not([name]):not([id]):hover::before,
.use-button-action.use-button-action button:hover::before {
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-action-hover) border-box
}

.use-button-action.use-button-action:not(div):focus,
.use-button-action.use-button-action a:not([name]):not([id]):focus,
.use-button-action.use-button-action button:focus,
.use-button-action.use-button-action:not(div):focus-visible,
.use-button-action.use-button-action a:not([name]):not([id]):focus-visible,
.use-button-action.use-button-action button:focus-visible {
  color: var(--color--text-primary)
}

.use-button-action.use-button-action:not(div):focus::before,
.use-button-action.use-button-action a:not([name]):not([id]):focus::before,
.use-button-action.use-button-action button:focus::before,
.use-button-action.use-button-action:not(div):focus-visible::before,
.use-button-action.use-button-action a:not([name]):not([id]):focus-visible::before,
.use-button-action.use-button-action button:focus-visible::before {
  background: var(--color--interactive-action-focus) border-box
}

.use-button-action.use-button-action:not(div):active,
.use-button-action.use-button-action a:not([name]):not([id]):active,
.use-button-action.use-button-action button:active {
  color: var(--color--text-primary-inverse)
}

.use-button-action.use-button-action:not(div):active::before,
.use-button-action.use-button-action a:not([name]):not([id]):active::before,
.use-button-action.use-button-action button:active::before {
  background: var(--color--interactive-action-active) border-box
}

.use-button-action.use-button-action:not(div)[disabled],
.use-button-action.use-button-action a:not([name]):not([id])[disabled],
.use-button-action.use-button-action button[disabled],
.use-button-action.use-button-action:not(div).button-disabled,
.use-button-action.use-button-action a:not([name]):not([id]).button-disabled,
.use-button-action.use-button-action button.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.use-button-action.use-button-action:not(div)[disabled]::before,
.use-button-action.use-button-action a:not([name]):not([id])[disabled]::before,
.use-button-action.use-button-action button[disabled]::before,
.use-button-action.use-button-action:not(div).button-disabled::before,
.use-button-action.use-button-action a:not([name]):not([id]).button-disabled::before,
.use-button-action.use-button-action button.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.use-button-action.use-button-action:not(div)[disabled]:hover::before,
.use-button-action.use-button-action a:not([name]):not([id])[disabled]:hover::before,
.use-button-action.use-button-action button[disabled]:hover::before,
.use-button-action.use-button-action:not(div).button-disabled:hover::before,
.use-button-action.use-button-action a:not([name]):not([id]).button-disabled:hover::before,
.use-button-action.use-button-action button.button-disabled:hover::before,
.use-button-action.use-button-action:not(div)[disabled]:focus::before,
.use-button-action.use-button-action a:not([name]):not([id])[disabled]:focus::before,
.use-button-action.use-button-action button[disabled]:focus::before,
.use-button-action.use-button-action:not(div).button-disabled:focus::before,
.use-button-action.use-button-action a:not([name]):not([id]).button-disabled:focus::before,
.use-button-action.use-button-action button.button-disabled:focus::before,
.use-button-action.use-button-action:not(div)[disabled]:focus-visible::before,
.use-button-action.use-button-action a:not([name]):not([id])[disabled]:focus-visible::before,
.use-button-action.use-button-action button[disabled]:focus-visible::before,
.use-button-action.use-button-action:not(div).button-disabled:focus-visible::before,
.use-button-action.use-button-action a:not([name]):not([id]).button-disabled:focus-visible::before,
.use-button-action.use-button-action button.button-disabled:focus-visible::before,
.use-button-action.use-button-action:not(div)[disabled]:active::before,
.use-button-action.use-button-action a:not([name]):not([id])[disabled]:active::before,
.use-button-action.use-button-action button[disabled]:active::before,
.use-button-action.use-button-action:not(div).button-disabled:active::before,
.use-button-action.use-button-action a:not([name]):not([id]).button-disabled:active::before,
.use-button-action.use-button-action button.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.use-button-action.use-button-action:not(div)[disabled]::before,
.use-button-action.use-button-action a:not([name]):not([id])[disabled]::before,
.use-button-action.use-button-action button[disabled]::before,
.use-button-action.use-button-action:not(div).button-disabled::before,
.use-button-action.use-button-action a:not([name]):not([id]).button-disabled::before,
.use-button-action.use-button-action button.button-disabled::before {
  outline-offset: -2px;
  outline-color: var(--color--interactive-disabled-01);
  background: transparent
}

.use-button-action.use-button-action:not(div)[disabled]:hover::before,
.use-button-action.use-button-action a:not([name]):not([id])[disabled]:hover::before,
.use-button-action.use-button-action button[disabled]:hover::before,
.use-button-action.use-button-action:not(div).button-disabled:hover::before,
.use-button-action.use-button-action a:not([name]):not([id]).button-disabled:hover::before,
.use-button-action.use-button-action button.button-disabled:hover::before,
.use-button-action.use-button-action:not(div)[disabled]:focus::before,
.use-button-action.use-button-action a:not([name]):not([id])[disabled]:focus::before,
.use-button-action.use-button-action button[disabled]:focus::before,
.use-button-action.use-button-action:not(div).button-disabled:focus::before,
.use-button-action.use-button-action a:not([name]):not([id]).button-disabled:focus::before,
.use-button-action.use-button-action button.button-disabled:focus::before,
.use-button-action.use-button-action:not(div)[disabled]:focus-visible::before,
.use-button-action.use-button-action a:not([name]):not([id])[disabled]:focus-visible::before,
.use-button-action.use-button-action button[disabled]:focus-visible::before,
.use-button-action.use-button-action:not(div).button-disabled:focus-visible::before,
.use-button-action.use-button-action a:not([name]):not([id]).button-disabled:focus-visible::before,
.use-button-action.use-button-action button.button-disabled:focus-visible::before,
.use-button-action.use-button-action:not(div)[disabled]:active::before,
.use-button-action.use-button-action a:not([name]):not([id])[disabled]:active::before,
.use-button-action.use-button-action button[disabled]:active::before,
.use-button-action.use-button-action:not(div).button-disabled:active::before,
.use-button-action.use-button-action a:not([name]):not([id]).button-disabled:active::before,
.use-button-action.use-button-action button.button-disabled:active::before {
  background: transparent
}

.use-button-action-inverse.use-button-action-inverse:not(div),
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]),
.use-button-action-inverse.use-button-action-inverse button {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary)
}

@media(min-width:376px) {

  .use-button-action-inverse.use-button-action-inverse:not(div),
  .use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]),
  .use-button-action-inverse.use-button-action-inverse button {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {

  .use-button-action-inverse.use-button-action-inverse:not(div),
  .use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]),
  .use-button-action-inverse.use-button-action-inverse button {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.use-button-action-inverse.use-button-action-inverse:not(div)::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id])::before,
.use-button-action-inverse.use-button-action-inverse button::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.use-button-action-inverse.use-button-action-inverse:not(div):hover,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):hover,
.use-button-action-inverse.use-button-action-inverse button:hover,
.use-button-action-inverse.use-button-action-inverse:not(div):focus,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):focus,
.use-button-action-inverse.use-button-action-inverse button:focus,
.use-button-action-inverse.use-button-action-inverse:not(div):focus-visible,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):focus-visible,
.use-button-action-inverse.use-button-action-inverse button:focus-visible,
.use-button-action-inverse.use-button-action-inverse:not(div):active,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):active,
.use-button-action-inverse.use-button-action-inverse button:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.use-button-action-inverse.use-button-action-inverse:not(div):hover::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):hover::before,
.use-button-action-inverse.use-button-action-inverse button:hover::before,
.use-button-action-inverse.use-button-action-inverse:not(div):focus::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):focus::before,
.use-button-action-inverse.use-button-action-inverse button:focus::before,
.use-button-action-inverse.use-button-action-inverse:not(div):focus-visible::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):focus-visible::before,
.use-button-action-inverse.use-button-action-inverse button:focus-visible::before,
.use-button-action-inverse.use-button-action-inverse:not(div):active::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):active::before,
.use-button-action-inverse.use-button-action-inverse button:active::before {
  transform: scale(1.05)
}

.use-button-action-inverse.use-button-action-inverse:not(div):focus,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):focus,
.use-button-action-inverse.use-button-action-inverse button:focus,
.use-button-action-inverse.use-button-action-inverse:not(div):focus-visible,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):focus-visible,
.use-button-action-inverse.use-button-action-inverse button:focus-visible {
  outline: 0
}

.use-button-action-inverse.use-button-action-inverse:not(div):focus::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):focus::before,
.use-button-action-inverse.use-button-action-inverse button:focus::before,
.use-button-action-inverse.use-button-action-inverse:not(div):focus-visible::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):focus-visible::before,
.use-button-action-inverse.use-button-action-inverse button:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.use-button-action-inverse.use-button-action-inverse:not(div):active::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):active::before,
.use-button-action-inverse.use-button-action-inverse button:active::before {
  outline-color: transparent
}

.use-button-action-inverse.use-button-action-inverse:not(div):only-of-type,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):only-of-type,
.use-button-action-inverse.use-button-action-inverse button:only-of-type {
  margin: 0
}

.use-button-action-inverse.use-button-action-inverse:not(div).icon-only,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]).icon-only,
.use-button-action-inverse.use-button-action-inverse button.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.use-button-action-inverse.use-button-action-inverse:not(div)::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id])::before,
.use-button-action-inverse.use-button-action-inverse button::before {
  background: var(--color--interactive-action-inverse) border-box
}

.use-button-action-inverse.use-button-action-inverse:not(div):hover,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):hover,
.use-button-action-inverse.use-button-action-inverse button:hover {
  color: var(--color--text-primary)
}

.use-button-action-inverse.use-button-action-inverse:not(div):hover::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):hover::before,
.use-button-action-inverse.use-button-action-inverse button:hover::before {
  background: var(--color--interactive-action-inverse-hover, var(--color--interactive-action-hover-inverse)) border-box
}

.use-button-action-inverse.use-button-action-inverse:not(div):focus,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):focus,
.use-button-action-inverse.use-button-action-inverse button:focus,
.use-button-action-inverse.use-button-action-inverse:not(div):focus-visible,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):focus-visible,
.use-button-action-inverse.use-button-action-inverse button:focus-visible {
  color: var(--color--text-primary)
}

.use-button-action-inverse.use-button-action-inverse:not(div):focus::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):focus::before,
.use-button-action-inverse.use-button-action-inverse button:focus::before,
.use-button-action-inverse.use-button-action-inverse:not(div):focus-visible::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):focus-visible::before,
.use-button-action-inverse.use-button-action-inverse button:focus-visible::before {
  background: var(--color--interactive-action-focus) border-box
}

.use-button-action-inverse.use-button-action-inverse:not(div):active,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):active,
.use-button-action-inverse.use-button-action-inverse button:active {
  color: var(--color--text-primary)
}

.use-button-action-inverse.use-button-action-inverse:not(div):active::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]):active::before,
.use-button-action-inverse.use-button-action-inverse button:active::before {
  background: var(--color--interactive-action-inverse-active, var(--color--interactive-action-active-inverse)) border-box
}

.use-button-action-inverse.use-button-action-inverse:not(div)[disabled],
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id])[disabled],
.use-button-action-inverse.use-button-action-inverse button[disabled],
.use-button-action-inverse.use-button-action-inverse:not(div).button-disabled,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]).button-disabled,
.use-button-action-inverse.use-button-action-inverse button.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.use-button-action-inverse.use-button-action-inverse:not(div)[disabled]::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id])[disabled]::before,
.use-button-action-inverse.use-button-action-inverse button[disabled]::before,
.use-button-action-inverse.use-button-action-inverse:not(div).button-disabled::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]).button-disabled::before,
.use-button-action-inverse.use-button-action-inverse button.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.use-button-action-inverse.use-button-action-inverse:not(div)[disabled]:hover::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id])[disabled]:hover::before,
.use-button-action-inverse.use-button-action-inverse button[disabled]:hover::before,
.use-button-action-inverse.use-button-action-inverse:not(div).button-disabled:hover::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]).button-disabled:hover::before,
.use-button-action-inverse.use-button-action-inverse button.button-disabled:hover::before,
.use-button-action-inverse.use-button-action-inverse:not(div)[disabled]:focus::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id])[disabled]:focus::before,
.use-button-action-inverse.use-button-action-inverse button[disabled]:focus::before,
.use-button-action-inverse.use-button-action-inverse:not(div).button-disabled:focus::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]).button-disabled:focus::before,
.use-button-action-inverse.use-button-action-inverse button.button-disabled:focus::before,
.use-button-action-inverse.use-button-action-inverse:not(div)[disabled]:focus-visible::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id])[disabled]:focus-visible::before,
.use-button-action-inverse.use-button-action-inverse button[disabled]:focus-visible::before,
.use-button-action-inverse.use-button-action-inverse:not(div).button-disabled:focus-visible::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]).button-disabled:focus-visible::before,
.use-button-action-inverse.use-button-action-inverse button.button-disabled:focus-visible::before,
.use-button-action-inverse.use-button-action-inverse:not(div)[disabled]:active::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id])[disabled]:active::before,
.use-button-action-inverse.use-button-action-inverse button[disabled]:active::before,
.use-button-action-inverse.use-button-action-inverse:not(div).button-disabled:active::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]).button-disabled:active::before,
.use-button-action-inverse.use-button-action-inverse button.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.use-button-action-inverse.use-button-action-inverse:not(div)[disabled]::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id])[disabled]::before,
.use-button-action-inverse.use-button-action-inverse button[disabled]::before,
.use-button-action-inverse.use-button-action-inverse:not(div).button-disabled::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]).button-disabled::before,
.use-button-action-inverse.use-button-action-inverse button.button-disabled::before {
  outline-offset: -2px;
  outline-color: var(--color--interactive-disabled-02);
  background: transparent
}

.use-button-action-inverse.use-button-action-inverse:not(div)[disabled]:hover::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id])[disabled]:hover::before,
.use-button-action-inverse.use-button-action-inverse button[disabled]:hover::before,
.use-button-action-inverse.use-button-action-inverse:not(div).button-disabled:hover::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]).button-disabled:hover::before,
.use-button-action-inverse.use-button-action-inverse button.button-disabled:hover::before,
.use-button-action-inverse.use-button-action-inverse:not(div)[disabled]:focus::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id])[disabled]:focus::before,
.use-button-action-inverse.use-button-action-inverse button[disabled]:focus::before,
.use-button-action-inverse.use-button-action-inverse:not(div).button-disabled:focus::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]).button-disabled:focus::before,
.use-button-action-inverse.use-button-action-inverse button.button-disabled:focus::before,
.use-button-action-inverse.use-button-action-inverse:not(div)[disabled]:focus-visible::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id])[disabled]:focus-visible::before,
.use-button-action-inverse.use-button-action-inverse button[disabled]:focus-visible::before,
.use-button-action-inverse.use-button-action-inverse:not(div).button-disabled:focus-visible::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]).button-disabled:focus-visible::before,
.use-button-action-inverse.use-button-action-inverse button.button-disabled:focus-visible::before,
.use-button-action-inverse.use-button-action-inverse:not(div)[disabled]:active::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id])[disabled]:active::before,
.use-button-action-inverse.use-button-action-inverse button[disabled]:active::before,
.use-button-action-inverse.use-button-action-inverse:not(div).button-disabled:active::before,
.use-button-action-inverse.use-button-action-inverse a:not([name]):not([id]).button-disabled:active::before,
.use-button-action-inverse.use-button-action-inverse button.button-disabled:active::before {
  background: transparent
}

.use-button-small.use-button-small:not(div),
.use-button-small.use-button-small a:not([name]):not([id]),
.use-button-small.use-button-small button {
  font-size: var(--typesize-minor);
  padding: .25em 1.25em;
  min-height: 2rem
}

.use-button-small.use-button-small:not(div).icon-only,
.use-button-small.use-button-small a:not([name]):not([id]).icon-only,
.use-button-small.use-button-small button.icon-only {
  aspect-ratio: 1;
  padding: .5em;
  max-width: 2rem
}

.use-button-large.use-button-large:not(div),
.use-button-large.use-button-large a:not([name]):not([id]),
.use-button-large.use-button-large button {
  padding: .75em 1.25em;
  min-height: 3rem
}

.use-button-large.use-button-large:not(div).icon-only,
.use-button-large.use-button-large a:not([name]):not([id]).icon-only,
.use-button-large.use-button-large button.icon-only {
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

.use-button-block.use-button-block:not(div),
.use-button-block.use-button-block a:not([name]):not([id]),
.use-button-block.use-button-block button {
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-right: 0
}

.use-button-unstyled.use-button-unstyled:not(div),
.use-button-unstyled.use-button-unstyled a:not([name]):not([id]),
.use-button-unstyled.use-button-unstyled button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0
}

.use-button-unstyled.use-button-unstyled:not(div)::before,
.use-button-unstyled.use-button-unstyled a:not([name]):not([id])::before,
.use-button-unstyled.use-button-unstyled button::before {
  display: none;
  background: transparent;
  border: 0;
  box-shadow: none
}

[class*="use-button-circular-"] :not(div),
[class*="use-button-circular-"] a:not([name]):not([id]) {
  line-height: var(--leading--1);
  padding: 0;
  aspect: 1
}

[class*="use-button-circular-"] :not(div)::before,
[class*="use-button-circular-"] a:not([name]):not([id])::before {
  border-radius: 50%;
  aspect-ratio: 1
}

.use-button-circular-large:not(div),
.use-button-circular-large a:not([name]):not([id]) {
  width: 2.5em;
  height: 2.5em
}

.use-button-circular-medium:not(div),
.use-button-circular-medium a:not([name]):not([id]) {
  width: 2.25em;
  height: 2.25em
}

.use-button-circular-small:not(div),
.use-button-circular-small a:not([name]):not([id]) {
  width: 2em;
  height: 2em
}

// [class*="use-button-"]([href$=".doc"])>span,
// [class*="use-button-"]([href$=".docx"])>span,
// [class*="use-button-"]([href$=".pdf"])>span,
// [class*="use-button-"]([href$=".txt"])>span,
// [class*="use-button-"]([href$=".doc"])>br,
// [class*="use-button-"]([href$=".docx"])>br,
// [class*="use-button-"]([href$=".pdf"])>br,
// [class*="use-button-"]([href$=".txt"])>br { display:none; }

.use-shape-circle.use-shape-circle {
  min-height: 36px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  clip-path: circle(50% at 50% 50%)
}

.use-shape-circle.use-shape-circle>* {
  max-height: 36px;
  display: inline-block;
  overflow: hidden;
  padding: 0;
  margin: 0
}

.use-shape-pill.use-shape-pill {
  min-width: 72px;
  min-height: 36px;
  border-radius: 999px;
  overflow: hidden
}

.use-shape-pill.use-shape-pill>* {
  max-height: 36px;
  display: inline-block;
  overflow: hidden;
  padding: 0;
  margin: 0
}

.use-shape-tag.use-shape-tag {
  min-height: 36px;
  min-width: 48px;
  min-height: 24px;
  border-radius: 999px;
  overflow: hidden
}

.use-shape-tag.use-shape-tag>* {
  max-height: 24px;
  display: inline-block;
  overflow: hidden;
  padding: 0;
  margin: 0
}

.stroke-top--accent-01-light {
  border-top: .5rem solid var(--color--accent-01-light)
}

.stroke-top--accent-01-primary {
  border-top: .5rem solid var(--color--accent-01-primary)
}

.stroke-top--accent-01-medium {
  border-top: .5rem solid var(--color--accent-01-medium)
}

.stroke-top--accent-01-dark {
  border-top: .5rem solid var(--color--accent-01-dark)
}

.stroke-top--accent-02-light {
  border-top: .5rem solid var(--color--accent-02-light)
}

.stroke-top--accent-02-primary {
  border-top: .5rem solid var(--color--accent-02-primary)
}

.stroke-top--accent-02-medium {
  border-top: .5rem solid var(--color--accent-02-medium)
}

.stroke-top--accent-02-dark {
  border-top: .5rem solid var(--color--accent-02-dark)
}

.stroke-top--accent-03-light {
  border-top: .5rem solid var(--color--accent-03-light)
}

.stroke-top--accent-03-primary {
  border-top: .5rem solid var(--color--accent-03-primary)
}

.stroke-top--accent-03-medium {
  border-top: .5rem solid var(--color--accent-03-medium)
}

.stroke-top--accent-03-dark {
  border-top: .5rem solid var(--color--accent-03-dark)
}

.stroke-top--accent-04-light {
  border-top: .5rem solid var(--color--accent-04-light)
}

.stroke-top--accent-04-primary {
  border-top: .5rem solid var(--color--accent-04-primary)
}

.stroke-top--accent-04-medium {
  border-top: .5rem solid var(--color--accent-04-medium)
}

.stroke-top--accent-04-dark {
  border-top: .5rem solid var(--color--accent-04-dark)
}

.stroke-top--accent-05-light {
  border-top: .5rem solid var(--color--accent-05-light)
}

.stroke-top--accent-05-primary {
  border-top: .5rem solid var(--color--accent-05-primary)
}

.stroke-top--accent-05-medium {
  border-top: .5rem solid var(--color--accent-05-medium)
}

.stroke-top--accent-05-dark {
  border-top: .5rem solid var(--color--accent-05-dark)
}

.stroke-top--accent-06-light {
  border-top: .5rem solid var(--color--accent-06-light)
}

.stroke-top--accent-06-primary {
  border-top: .5rem solid var(--color--accent-06-primary)
}

.stroke-top--accent-06-medium {
  border-top: .5rem solid var(--color--accent-06-medium)
}

.stroke-top--accent-06-dark {
  border-top: .5rem solid var(--color--accent-06-dark)
}

.stroke-bottom--accent-01-light {
  border-bottom: .5rem solid var(--color--accent-01-light)
}

.stroke-bottom--accent-01-primary {
  border-bottom: .5rem solid var(--color--accent-01-primary)
}

.stroke-bottom--accent-01-medium {
  border-bottom: .5rem solid var(--color--accent-01-medium)
}

.stroke-bottom--accent-01-dark {
  border-bottom: .5rem solid var(--color--accent-01-dark)
}

.stroke-bottom--accent-02-light {
  border-bottom: .5rem solid var(--color--accent-02-light)
}

.stroke-bottom--accent-02-primary {
  border-bottom: .5rem solid var(--color--accent-02-primary)
}

.stroke-bottom--accent-02-medium {
  border-bottom: .5rem solid var(--color--accent-02-medium)
}

.stroke-bottom--accent-02-dark {
  border-bottom: .5rem solid var(--color--accent-02-dark)
}

.stroke-bottom--accent-03-light {
  border-bottom: .5rem solid var(--color--accent-03-light)
}

.stroke-bottom--accent-03-primary {
  border-bottom: .5rem solid var(--color--accent-03-primary)
}

.stroke-bottom--accent-03-medium {
  border-bottom: .5rem solid var(--color--accent-03-medium)
}

.stroke-bottom--accent-03-dark {
  border-bottom: .5rem solid var(--color--accent-03-dark)
}

.stroke-bottom--accent-04-light {
  border-bottom: .5rem solid var(--color--accent-04-light)
}

.stroke-bottom--accent-04-primary {
  border-bottom: .5rem solid var(--color--accent-04-primary)
}

.stroke-bottom--accent-04-medium {
  border-bottom: .5rem solid var(--color--accent-04-medium)
}

.stroke-bottom--accent-04-dark {
  border-bottom: .5rem solid var(--color--accent-04-dark)
}

.stroke-bottom--accent-05-light {
  border-bottom: .5rem solid var(--color--accent-05-light)
}

.stroke-bottom--accent-05-primary {
  border-bottom: .5rem solid var(--color--accent-05-primary)
}

.stroke-bottom--accent-05-medium {
  border-bottom: .5rem solid var(--color--accent-05-medium)
}

.stroke-bottom--accent-05-dark {
  border-bottom: .5rem solid var(--color--accent-05-dark)
}

.stroke-bottom--accent-06-light {
  border-bottom: .5rem solid var(--color--accent-06-light)
}

.stroke-bottom--accent-06-primary {
  border-bottom: .5rem solid var(--color--accent-06-primary)
}

.stroke-bottom--accent-06-medium {
  border-bottom: .5rem solid var(--color--accent-06-medium)
}

.stroke-bottom--accent-06-dark {
  border-bottom: .5rem solid var(--color--accent-06-dark)
}

.use-margin-top-none {
  margin-top: var(--space-zero) !important
}

.use-margin-top-xxxsmall {
  margin-top: var(--space-xxxsmall) !important
}

.use-margin-top-xxsmall {
  margin-top: var(--space-xxsmall) !important
}

.use-margin-top-xsmall {
  margin-top: var(--space-xsmall) !important
}

@media(min-width:769px) {
  .use-margin-top-small {
    margin-top: var(--space-small) !important
  }
}

@media(max-width:768px) {
  .use-margin-top-small {
    margin-top: var(--space-xsmall) !important
  }
}

.use-margin-top-medium {
  margin-top: var(--space-medium) !important
}

.use-margin-top-large {
  margin-top: var(--space-large) !important
}

.use-margin-top-xlarge {
  margin-top: var(--space-xlarge) !important
}

.use-margin-top-xxlarge {
  margin-top: var(--space-xxlarge) !important
}

.use-margin-top-xxxlarge {
  margin-top: var(--space-xxxlarge) !important
}

.use-margin-top-xxxxlarge {
  margin-top: var(--space-xxxxlarge) !important
}

.use-margin-auto.title * {
  margin-right: auto;
  margin-left: auto
}

.use-margin-auto.text * {
  margin-right: auto;
  margin-left: auto
}

.use-margin-auto.content-tile picture,
.use-margin-auto.content-tile .content-tile__title,
.use-margin-auto.content-tile .content-tile-subtitle,
.use-margin-auto.content-tile .content-tile-text * {
  margin-right: auto;
  margin-left: auto
}

.use-margin-auto.content-tile picture {
  display: inline-block
}

.has-margin--none {
  margin: 0 !important
}

.has-margin-bottom--none {
  margin-bottom: 0 !important
}

.push-top-25 {
  margin-top: 25px
}

.pull-up-20 {
  margin-top: -20px
}

.pull-up-5-percent {
  margin-top: -5%
}

.pull-up-10-percent {
  margin-top: -10%
}

.edit [class*="pull-up-"] {
  margin-top: 0
}

.remove-bottom-margin-40 {
  margin-bottom: -40px
}

.has-margin-top--10 {
  margin-top: 10px !important
}

.has-margin-top--15 {
  margin-top: 15px !important
}

.has-margin-top--20 {
  margin-top: 20px !important
}

.has-margin-top--25 {
  margin-top: 25px !important
}

.has-margin-top--30 {
  margin-top: 30px !important
}

.has-margin-top--40 {
  margin-top: 40px !important
}

.has-margin-top--60 {
  margin-top: 60px !important
}

.has-margin-top--75 {
  margin-top: 75px !important
}

.has-margin-top--90 {
  margin-top: 90px !important
}

.margin-20 {
  margin: 20px
}

.screenreader {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  height: 1px;
  width: 1px;
  min-height: unset;
  overflow: hidden;
  position: absolute;
  padding: 0;
  margin: 0
}

.c-gradient-overlay::after,
.use-gradient-overlay--topDown::after {
  content: "";
  height: clamp(100px, 90%, 480px);
  max-height: 100%;
  pointer-events: none;
  position: absolute;
  right: 0;
  left: 0;
  opacity: .825;
  background: linear-gradient(to bottom, #000 0, rgba(0, 0, 0, 0.738) 19%, rgba(0, 0, 0, 0.541) 34%, rgba(0, 0, 0, 0.382) 47%, rgba(0, 0, 0, 0.278) 56.5%, rgba(0, 0, 0, 0.194) 65%, rgba(0, 0, 0, 0.126) 73%, rgba(0, 0, 0, 0.075) 80.2%, rgba(0, 0, 0, 0.042) 86.1%, rgba(0, 0, 0, 0.021) 91%, rgba(0, 0, 0, 0.008) 95.2%, rgba(0, 0, 0, 0.002) 98.2%, transparent 100%);
  top: 0
}

.use-gradient-overlay--bottomUp::after {
  content: "";
  height: clamp(100px, 90%, 480px);
  max-height: 100%;
  pointer-events: none;
  position: absolute;
  right: 0;
  left: 0;
  opacity: .825;
  background: linear-gradient(to bottom, #000 0, rgba(0, 0, 0, 0.738) 19%, rgba(0, 0, 0, 0.541) 34%, rgba(0, 0, 0, 0.382) 47%, rgba(0, 0, 0, 0.278) 56.5%, rgba(0, 0, 0, 0.194) 65%, rgba(0, 0, 0, 0.126) 73%, rgba(0, 0, 0, 0.075) 80.2%, rgba(0, 0, 0, 0.042) 86.1%, rgba(0, 0, 0, 0.021) 91%, rgba(0, 0, 0, 0.008) 95.2%, rgba(0, 0, 0, 0.002) 98.2%, transparent 100%);
  bottom: 0;
  transform: rotate(180deg)
}

.c-gradient-overlay>.container,
[class*="use-gradient-overlay"]>.container,
.c-gradient-overlay>.row,
[class*="use-gradient-overlay"]>.row {
  position: relative;
  z-index: 1
}

.use-list-as-tag-cloud.text ul,
.use-list-as-tag-cloud .content-tile-text ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center
}

.use-list-as-tag-cloud.text ul li,
.use-list-as-tag-cloud .content-tile-text ul li {
  margin: 0 var(--space-xsmall) var(--space-small) 0
}

.link-list.link-list--inverse {
  background-color: var(--color--ui-background-dark, var(--color--background-dark))
}

.link-list.link-list--inverse>div h1,
.link-list.link-list--inverse>div h2,
.link-list.link-list--inverse>div h3,
.link-list.link-list--inverse>div h4,
.link-list.link-list--inverse>div h5,
.link-list.link-list--inverse>div h6 {
  color: var(--color--text-primary-inverse)
}

.link-list.link-list--inverse ul>li:not(:last-child) {
  border-bottom: 1px solid var(--color--ui-border-03, var(--color--border-03))
}

.link-list.link-list--inverse ul>li a {
  color: var(--color--text-link-inverse)
}

@media(min-width:1390px) {
  .link-list--columns-6 ul {
    column-count: 6
  }
}

@media(min-width:1024px) and (max-width:1389px) {
  .link-list--columns-6 ul {
    column-count: 5
  }
}

@media(min-width:992px) and (max-width:1023px) {
  .link-list--columns-6 ul {
    column-count: 4
  }
}

@media(min-width:768px) and (max-width:991px) {
  .link-list--columns-6 ul {
    column-count: 3
  }
}

@media(min-width:640px) and (max-width:767px) {
  .link-list--columns-6 ul {
    column-count: 2
  }
}

@media(max-width:639px) {
  .link-list--columns-6 ul {
    column-count: unset
  }
}

@media(min-width:1024px) {
  .link-list--columns-5 ul {
    column-count: 5
  }
}

@media(min-width:992px) and (max-width:1023px) {
  .link-list--columns-5 ul {
    column-count: 4
  }
}

@media(min-width:768px) and (max-width:991px) {
  .link-list--columns-5 ul {
    column-count: 3
  }
}

@media(min-width:640px) and (max-width:767px) {
  .link-list--columns-5 ul {
    column-count: 2
  }
}

@media(max-width:639px) {
  .link-list--columns-5 ul {
    column-count: unset
  }
}

@media(min-width:992px) {
  .link-list--columns-4 ul {
    column-count: 4
  }
}

@media(min-width:768px) and (max-width:991px) {
  .link-list--columns-4 ul {
    column-count: 3
  }
}

@media(min-width:640px) and (max-width:767px) {
  .link-list--columns-4 ul {
    column-count: 2
  }
}

@media(max-width:639px) {
  .link-list--columns-4 ul {
    column-count: unset
  }
}

@media(min-width:768px) {
  .link-list--columns-3 ul {
    column-count: 3
  }
}

@media(min-width:640px) and (max-width:767px) {
  .link-list--columns-3 ul {
    column-count: 2
  }
}

@media(max-width:639px) {
  .link-list--columns-3 ul {
    column-count: unset
  }
}

@media(min-width:640px) {
  .link-list--columns-2 ul {
    column-count: 2
  }
}

@media(max-width:639px) {
  .link-list--columns-2 ul {
    column-count: unset
  }
}

[class*="link-list--columns-"] ul {
  column-gap: calc(8 * var(--space-unit))
}

html[class*="aem-AuthorLayer-Edit"] header.cq-Editable-dom--container {
  --he-author-space-unit: 4px;
  --he-author-space-unit-15: calc(15 * var(--he-author-space-unit));
  margin-block-end: calc(1em + var(--he-author-space-unit-15))
}

html[class*="aem-AuthorLayer-Edit"] header.cq-Editable-dom--container::before {
  outline-width: calc(2 * var(--he-author-space-unit));
  outline-style: dashed;
  outline-color: var(--color--alert-error-inverse);
  outline-offset: calc(2 * var(--he-author-space-unit) * -1);
  background: repeating-linear-gradient(65deg, var(--color--text-secondary), var(--color--text-secondary) var(--he-author-space-unit), var(--color--ui-background-dark, var(--color--background-dark)) calc(2 * var(--he-author-space-unit)), var(--color--ui-background-dark, var(--color--background-dark)) calc(2 * var(--he-author-space-unit)));
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 100%;
  height: calc(100% + 1em + var(--he-author-space-unit-15));
  content: "";
  display: block
}

html[class*="aem-AuthorLayer-Edit"] header.cq-Editable-dom--container::after {
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--default);
  color: var(--color--text-primary-inverse);
  background-color: var(--color--alert-error-inverse);
  content: "\26A0\00A0 END HEADER CONTENT / Do not edit unless authorized";
  display: inline-block;
  max-width: fit-content;
  padding: 1em 1.5em;
  margin: 0 auto;
  position: absolute;
  inset-inline-end: 0;
  inset-block-end: calc(-1em + var(--he-author-space-unit-15) * -1);
  inset-inline-start: 0;
  border-start-end-radius: var(--corners-semiround-md);
  border-start-start-radius: var(--corners-semiround-md)
}

html[class*="aem-AuthorLayer-Preview"] header.cq-Editable-dom--container .new.section {
  display: none !important
}

html[class*="aem-AuthorLayer-Edit"] #onetrust-consent-sdk {
  display: none
}

html[class*="aem-AuthorLayer-Design"] #onetrust-consent-sdk .onetrust-pc-dark-filter {
  background: 0
}

.c-hero-image .column-control--bg {
  overflow: hidden
}

@media(min-width:1920px) {
  .c-hero-image .column-control--bg {
    clip-path: ellipse(110% 100% at left top)
  }
}

@media(min-width:376px) and (max-width:1919px) {
  .c-hero-image .column-control--bg {
    clip-path: ellipse(160% 100% at left top)
  }
}

@media(max-width:375px) {
  .c-hero-image .column-control--bg {
    clip-path: ellipse(225% 100% at left top)
  }
}

.content-tile.content-tile-panel-item,
.content-tile.content-tile-panel-item-main,
.content-tile.content-tile-info-small-img {
  border-radius: var(--corners-semiround-md);
  box-shadow: var(--shadow);
  overflow: hidden
}

.content-tile.content-tile-panel-item:hover,
.content-tile.content-tile-panel-item-main:hover {
  box-shadow: var(--shadow-blur)
}

.marketing_banner {
  margin: 0 -55px
}

@media all and (max-width:767px) {
  .marketing_banner {
    margin: 0 -30px
  }
}

@media all and (min-width:768px) {
  .marketing_banner div.content-tile-container {
    aspect-ratio: 2
  }
}

@media all and (min-width:768px) {
  .marketing_banner div.content-tile-container figure picture {
    width: 50%
  }
}

@media all and (max-width:767px) {
  .marketing_banner div.content-tile-container figure picture {
    width: 100%
  }
}

@media all and (max-width:767px) {
  .marketing_banner div.content-tile-container figure picture img {
    aspect-ratio: 1;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center
  }
}

.marketing_banner div.content-tile-container figure figcaption {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 auto;
  padding: 5.2%;
  min-width: 50%;
  max-width: 50%
}

@media all and (max-width:767px) {
  .marketing_banner div.content-tile-container figure figcaption {
    max-width: 100%
  }
}

.marketing_banner div.content-tile-container figure figcaption .content-tile__title {
  font-size: var(--typesize-h1)
}

.marketing_banner div.content-tile-container figure figcaption .content-tile-text {
  margin-top: 30px
}

.marketing_banner div.content-tile-container figure figcaption .content-tile-text p:first-child {
  color: var(--color--text-tertiary);
  font-size: var(--typesize-h4);
  line-height: var(--leading--1-2)
}

.marketing_banner div.content-tile-container figure figcaption .content-tile-text p:nth-child(2) {
  margin-top: 60px
}

.marketing_banner div.content-tile-container figure figcaption .content-tile-text p:nth-child(2) a::before {
  background: var(--color--interactive-secondary) border-box
}

@media all and (max-width:767px) {
  .marketing_banner div.content-tile-container figure figcaption .content-tile-text p:nth-child(2) {
    margin-top: 45px
  }
}

.pagination--on-dark a.ais-Pagination-link.ais-Pagination-link--previousPage.ais-Pagination-link--disabled,
.pagination--on-dark a.ais-Pagination-link.ais-Pagination-link--nextPage.ais-Pagination-link--disabled {
  color: var(--color--interactive-disabled-01)
}

.pagination {
  justify-content: center
}

.pagination .ais-Pagination {
  margin-top: 20px
}

.ais-Hits-list {
  padding: 0
}

.ais-Hits-list .ais-Hits-item {
  margin-top: 0;
  list-style: none
}

.ais-Hits-list .ais-Hits-item.list-banner {
  padding: 20px 0
}

.ais-Hits-list .programItem {
  padding: 20px 30px;
  border-bottom: 1px solid #e8eefa;
  display: flex
}

.ais-Hits-list .programItem:focus-within {
  outline: 3px solid var(--color--interactive-focus-field)
}

.ais-Hits-list .programItem:focus-visible {
  outline: 0
}

.ais-Hits-list .programItem:hover .programItem__title a {
  text-underline-offset: unset
}

@media all and (max-width:768px) {
  .ais-Hits-list .programItem {
    padding: 20px 0
  }
}

@media all and (max-width:375px) {
  .ais-Hits-list .programItem {
    padding: 20px 0 10px 0
  }
}

.ais-Hits-list .programItem .programItem__image {
  height: auto
}

.ais-Hits-list .programItem .programItem__image figure {
  margin: 0
}

@media all and (max-width:768px) {
  .ais-Hits-list .programItem .programItem__image figure {
    width: 90px;
    height: auto
  }
}

@media all and (min-width:769px) {
  .ais-Hits-list .programItem .programItem__image figure {
    width: 120px;
    height: auto
  }
}

.ais-Hits-list .programItem .programItem__image>a {
  display: block
}

.ais-Hits-list .programItem .programItem__image img {
  height: auto;
  width: 100%;
  margin: 0 auto;
  border-radius: 10px;
  filter: drop-shadow(0 2px 10px rgba(28, 34, 46, 0.2))
}

.ais-Hits-list .programItem .programItem__details {
  margin-left: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between
}

.ais-Hits-list .programItem .programItem__details p {
  margin-top: 0
}

.ais-Hits-list .programItem .programItem__details .programItem__title {
  margin-top: 5px;
  font-weight: bold;
  font-size: var(--typesize-h6);
  line-height: 123%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  padding-bottom: .125em
}

@media all and (max-width:768px) {
  .ais-Hits-list .programItem .programItem__details .programItem__title {
    margin-top: 0
  }
}

.ais-Hits-list .programItem .programItem__details .programItem__title>a:focus-visible {
  outline: 0
}

.ais-Hits-list .programItem .programItem__details .programItem__author {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  padding-bottom: .125em
}

.ais-Hits-list .programItem .programItem__details .programItem__edition {
  margin-top: 10px
}

.ais-Hits-list .programItem .programItem__details .programItem__options {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-top: 10px
}

@media all and (max-width:768px) {
  .ais-Hits-list .programItem .programItem__details .programItem__options {
    flex-direction: column;
    align-items: unset
  }

  .ais-Hits-list .programItem .programItem__details .programItem__options .programItem__price {
    margin-top: 10px
  }
}

.ais-Hits-list .programItem .programItem__details .programItem__options .programItem__platforms {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  padding-bottom: .125em
}

// .ais-Hits-list .programItem .programItem__details .programItem__options .programItem__platforms::before{display:inline-block;line-height:inherit;content:"";flex:none;-webkit-mask-size:100%;mask-size:100%;-webkit-mask-repeat:no-repeat;mask-repeat:no-repeat;-webkit-mask-position:center;mask-position:center;background:currentColor;width:18px;height:18px;-webkit-mask-image:url("highered-reimagined/assets/icons/icon-platform.svg");mask-image:url("highered-reimagined/assets/icons/icon-platform.svg");position:relative;top:3px;margin-right:5px}
.ais-Hits-list .programItem .programItem__details .programItem__options .programItem__price {
  text-align: right
}

@media all and (max-width:375px) {
  .ais-Hits-list .programItem .programItem__details .programItem__options .programItem__price {
    width: 100%
  }
}

.ais-Hits-list .programItem .programItem__details .programItem__options .programItem__price *:first-child {
  display: block;
  color: var(--color--text-secondary)
}

.ais-Hits-list .programItem .programItem__details .programItem__options .programItem__price span {
  color: var(--color--ui-boulder-gray);
  font-weight: var(--typeweight--default)
}

.ais-Hits-list .programItem .programItem__details .programItem__options .programItem__price .programItem__clipped {
  display: flex;
  font-family: var(--typeweight--semibold-plus)
}

.ais-Hits-list .programItem .programItem__details .programItem__options .programItem__price .programItem__clipped del span {
  margin-right: 10px
}

.view-switcher__control.view-switcher__control:before {
  background-color: var(--color--ui-04)
}

.view-switcher__control.view-switcher__control:checked::before {
  background-color: var(--color--accent-01-medium)
}

@media all and (max-width:1024px) {
  .view-switcher__control {
    display: none
  }
}

.hidden {
  display: none
}

@media all and (min-width:1025px) {
  .grid-view .ais-Hits-list {
    display: flex;
    flex-wrap: wrap;
    margin: 5px -15px
  }

  .grid-view .ais-Hits-list .ais-Hits-item {
    width: 25%
  }

  .grid-view .ais-Hits-list .ais-Hits-item.grid-banner {
    width: 100%;
    padding: 25px 0
  }

  .grid-view .ais-Hits-list .ais-Hits-item .programItem {
    display: block;
    padding: 0;
    border: 0;
    padding: 25px 20px
  }

  .grid-view .ais-Hits-list .ais-Hits-item .programItem:focus-within {
    outline: 3px solid var(--color--interactive-focus-field)
  }

  .grid-view .ais-Hits-list .ais-Hits-item .programItem:focus-visible {
    outline: 0
  }

  .grid-view .ais-Hits-list .ais-Hits-item .programItem .programItem__image {
    margin: auto;
    margin-bottom: 20px
  }

  .grid-view .ais-Hits-list .ais-Hits-item .programItem .programItem__image figure {
    height: 196px;
    width: auto
  }

  .grid-view .ais-Hits-list .ais-Hits-item .programItem .programItem__image figure img {
    height: 100%;
    width: auto
  }

  .grid-view .ais-Hits-list .ais-Hits-item .programItem .programItem__details {
    margin-left: 0
  }

  .grid-view .ais-Hits-list .ais-Hits-item .programItem .programItem__details p {
    color: var(--color--text-secondary)
  }

  .grid-view .ais-Hits-list .ais-Hits-item .programItem .programItem__details .programItem__title {
    margin-top: 20px
  }

  .grid-view .ais-Hits-list .ais-Hits-item .programItem .programItem__details .programItem__title>a:focus-visible {
    outline: 0
  }

  .grid-view .ais-Hits-list .ais-Hits-item .programItem .programItem__details .programItem__options {
    display: block
  }

  .grid-view .ais-Hits-list .ais-Hits-item .programItem .programItem__details .programItem__options .programItem__price {
    text-align: left;
    margin-top: 10px
  }

  .grid-view .ais-Hits-list .ais-Hits-item .programItem .programItem__details .programItem__options .programItem__isbn {
    display: none
  }

  .grid-view .ais-Hits-list .ais-Hits-item .programItem .programItem__details .programItem__options .programItem__platforms {
    text-transform: capitalize
  }
}

@media all and (max-width:1024px) {
  .educator-nav__search .search-box {
    display: none
  }
}

.educator-nav__search .search-box.isOpen {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1001;
  background: var(--color--ui-02);
  padding: 20px
}

.educator-nav__search .ui-autocomplete .ui-menu-item__result-count {
  padding: 0
}

.educator-nav__search .ui-autocomplete .ui-menu-item__found-and-view {
  display: flex;
  width: 100%;
  justify-content: space-between
}

@media all and (max-width:1024px) {
  .educator-nav__search .ui-autocomplete {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    border-top: 0;
    margin-top: 0
  }

  .educator-nav__search .ui-autocomplete li {
    position: relative;
    z-index: 11;
    padding-top: 0
  }

  .educator-nav__search .ui-autocomplete::after {
    content: "";
    position: fixed;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: var(--color--ui-02)
  }
}

.educator-nav__search label {
  color: var(--color--text-primary-inverse);
  margin-bottom: var(--spacing-15)
}

.educator-nav__search .search-box-input:hover {
  color: var(--color--text-primary)
}

.educator-nav__search form .ais-SearchBox-input {
  background-color: rgba(247, 249, 253, 0.35);
  color: var(--color--text-secondary-inverse);
  box-shadow: unset;
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  outline-width: 0;
  outline-style: solid;
  outline-offset: -1px
}

@media all and (max-width:1024px) {
  .educator-nav__search form .ais-SearchBox-input {
    outline-color: var(--color--ui-border-02, var(--color--border-02))
  }
}

@media all and (max-width:1024px) {
  .educator-nav__search form .ais-SearchBox-input.ui-autocomplete-open {
    border-radius: 40px
  }
}

.educator-nav__search form .ais-SearchBox-input:hover {
  background-color: var(--color--ui-02)
}

.educator-nav__search form .ais-SearchBox-input:active,
.educator-nav__search form .ais-SearchBox-input:focus {
  background-color: var(--color--ui-02);
  color: var(--color--text-primary);
  outline-width: 3px;
  outline-color: var(--color--interactive-focus);
  outline-offset: -2px
}

.educator-nav__search form .ais-SearchBox-input::-webkit-input-placeholder,
.educator-nav__search form .ais-SearchBox-input::placeholder {
  color: var(--color--text-secondary-inverse)
}

@media all and (max-width:1024px) {

  .educator-nav__search form .ais-SearchBox-input::-webkit-input-placeholder,
  .educator-nav__search form .ais-SearchBox-input::placeholder {
    color: var(--color--text-secondary)
  }
}

.educator-nav__search form .ais-SearchBox-input:hover::-webkit-input-placeholder,
.educator-nav__search form .ais-SearchBox-input:focus::-webkit-input-placeholder,
.educator-nav__search form .ais-SearchBox-input:hover::placeholder,
.educator-nav__search form .ais-SearchBox-input:focus::placeholder {
  color: var(--color--text-primary)
}

.educator-nav__search form .ais-SearchBox-input:hover~.search-box-icon::before {
  color: var(--color--interactive-primary)
}

.educator-nav__search form .ais-SearchBox-input:focus~.search-box-icon::before {
  color: var(--color--text-primary)
}

.educator-nav__search form .search-box-icon {
  right: 16px;
  cursor: pointer
}

.educator-nav__search form .search-box-icon::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-sm");
  color: var(--color--icon-primary-inverse)
}

@media all and (max-width:1024px) {
  .educator-nav__search form .search-box-icon::before {
    color: var(--color--text-secondary)
  }
}

.educator-nav__search .closeBtn {
  display: none;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  margin: 0 var(--spacing-10) 0 calc(-1 * var(--spacing-5));
  padding: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent
}

@media all and (max-width:1024px) {
  .educator-nav__search .closeBtn {
    display: flex
  }
}

.educator-nav__search .closeBtn::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-lg")
}

.educator-nav__search .userNav__search {
  display: none;
  border: 0;
  border-radius: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  background: transparent;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer
}

.educator-nav__search .userNav__search::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-lg");
  background: var(--color--ui-background-light, var(--color--background-light))
}

@media all and (max-width:1024px) {
  .educator-nav__search .userNav__search {
    display: inline-block
  }
}

.educator-nav__search .search-subject__title,
.educator-nav__search .search-product__title,
.educator-nav__search .search-product__author,
.educator-nav__search .search-product__footer,
.educator-nav__search .search-product__price,
.educator-nav__search .search-product__price {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-minor);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-5);
  color: var(--color--text-secondary)
}

.educator-nav__search .search-product__author,
.educator-nav__search .search-product__footer {
  font-size: var(--typesize-milli);
  color: var(--color--text-tertiary);
  margin-bottom: 3px
}

.educator-nav__search .search-product__author:last-child,
.educator-nav__search .search-product__footer:last-child {
  margin-bottom: 0
}

.educator-nav__search .search-product__price {
  line-height: var(--leading--1-2)
}

.educator-nav__search .search-product__title {
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  padding-bottom: .125em;
  text-overflow: ellipsis
}

.educator-nav__search .search-product__author {
  -webkit-line-clamp: 2
}

.educator-nav__search .search-product__footer {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.educator-nav__search .search-product__footer div {
  display: flex
}

.educator-nav__search .search-product__footer div .search-product__old-price {
  font-size: var(--typesize-milli);
  color: var(--color--ui-boulder-gray);
  text-decoration: line-through;
  margin-right: 5px;
  padding: 4px 0
}

.educator-nav__search .search-product__footer div .search-product__price {
  padding: 2px 0
}

.educator-nav__search a.search-product.ui-menu-item {
  padding: 12px 16px
}

.educator-nav__search a.search-product.ui-menu-item:hover {
  background: var(--color--ui-background-med, var(--color--background-medium))
}

@media all and (max-width:768px) {
  .educator-nav__search a.search-product.ui-menu-item {
    padding: 12px 20px
  }
}

.educator-nav__search ul li+li {
  margin-top: 0
}

.educator-nav__search a.ui-menu-item__result-text {
  display: block
}

.recent-searches {
  padding-bottom: 15px
}

.recent-searches [tabindex]:focus,
.recent-searches [tabindex]:focus-visible,
.recent-searches [tabindex]:focus-within {
  outline: 2px solid var(--color--interactive-focus) !important
}

.recent-searches li:first-child {
  display: block;
  z-index: 12
}

.recent-searches .ui-menu-item__recently-viewed {
  padding-left: 0 !important
}

.recent-searches li {
  padding-left: 15px
}

@media all and (max-width:1024px) {
  .recent-searches li {
    padding-left: 20px
  }
}

.recent-searches li:first-child {
  padding-left: 0
}

@media all and (max-width:1024px) {
  .recent-searches li:last-child {
    padding: 5px 20px 15px 20px
  }
}

.recent-searches .ui-menu-item__recent-search-text {
  height: 40px
}

.recent-searches .ui-menu-item__recent-search-text h6 {
  line-height: 40px
}

.recent-searches .ui-menu-item__recent-searches:hover {
  background-color: var(--color--interactive-secondary-inverse-hover, var(--color--interactive-secondary-hover-inverse));
  border-radius: var(--corners-semiround-sm);
  text-decoration: none
}

.recent-searches .ui-menu-item__clear-history {
  position: absolute;
  right: 20px;
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--bold);
  font-size: var(--typesize-p);
  line-height: 40px;
  height: 40px;
  border: 0;
  background: 0;
  color: var(--color--text-link)
}

.recent-searches .recent-search__title {
  color: var(--color--text-primary);
  height: 32px;
  line-height: 32px;
  margin-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1
}

.recent-searches .search-product__title {
  margin-top: 0
}

.close-recents-btn {
  width: 18px;
  height: 18px;
  top: 11px;
  right: 19px;
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  border: 0;
  background: transparent
}

.close-recents-btn.hide {
  display: none
}

@media all and (max-width:1024px) {
  .close-recents-btn {
    right: 33px;
    top: 31px
  }
}

.close-recents-btn::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md")
}

.close-recents-btn:hover {
  border-radius: 50%;
  background-color: var(--color--interactive-secondary-inverse-hover, var(--color--interactive-secondary-hover-inverse))
}

.parent-result__section .col-md-3 {
  display: flex;
  justify-content: flex-start;
  align-items: center
}

@media all and (max-width:1024px) {
  .parent-result__section .col-md-3 {
    flex: 0 0 100%;
    max-width: 100%;
    order: 2;
    display: block
  }
}

.parent-result__section .col-md-3 .a-search__count {
  margin-bottom: 0
}

@media all and (max-width:1024px) {
  .parent-result__section .col-md-3 .a-search__count {
    text-align: center;
    margin-top: 20px
  }
}

.parent-result__section .right-children__section {
  padding: 0
}

.parent-result__section .right-children__section .col-12 {
  display: flex;
  justify-content: flex-end;
  align-items: center
}

label[for="countsPerPage"] {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute
}

.a-search__filters {
  display: none
}

.dropdown-list>div select.input.form-control {
  margin-left: 20px;
  width: auto
}

@media all and (max-width:1024px) {
  .dropdown-list>div select.input.form-control {
    margin-left: 0;
    padding: 6px 0;
    text-align: center;
    background: var(--color--ui-background-dark, var(--color--background-dark));
    color: var(--color--text-primary-inverse);
    font-weight: var(--typeweight--bold);
    border-radius: var(--button-corners)
  }
}

@media all and (max-width:1024px) {

  .dropdown-list>div select.input.form-control:active,
  .dropdown-list>div select.input.form-control:focus {
    background: var(--color--ui-background-dark, var(--color--background-dark));
    box-shadow: none
  }
}

.show__count select.input {
  margin-left: 20px;
  width: auto;
  -webkit-appearance: none
}

.ais-Panel-header span {
  font-size: var(--typesize-h6);
  color: var(--color--text-primary)
}

.ais-Panel-body .ais-RefinementList-item {
  padding: 0;
  margin-top: 10px;
  margin-left: -5px
}

.ais-Panel-body .ais-RefinementList-item .ais-RefinementList-label {
  margin-bottom: 10px
}

.ais-Panel-body .ais-RefinementList-item .ais-RefinementList-label input {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  position: relative;
  bottom: 2px;
  left: 4px;
  -webkit-appearance: none;
  background: var(--color--ui-background-light, var(--color--background-light));
  border: 2px solid var(--color--ui-border-02, var(--color--border-02));
  border-radius: 2px;
  outline: 0
}

.ais-Panel-body .ais-RefinementList-item .ais-RefinementList-label input:hover::before {
  opacity: 1
}

.ais-Panel-body .ais-RefinementList-item .ais-RefinementList-label input:focus::before {
  top: -11px;
  left: -11px;
  opacity: 1;
  outline: 3px solid var(--color--interactive-focus-field);
  outline-offset: -3px;
  background-color: transparent
}

.ais-Panel-body .ais-RefinementList-item .ais-RefinementList-label input::before {
  content: "";
  display: block;
  width: 36px;
  height: 36px;
  background-color: var(--color--ui-01);
  position: absolute;
  top: -11px;
  left: -11px;
  z-index: -1;
  opacity: 0;
  border-radius: 50%
}

.ais-Panel-body .ais-RefinementList-item .ais-RefinementList-label input:checked {
  background: var(--color--interactive-primary);
  border: 2px solid var(--color--interactive-primary)
}

.ais-Panel-body .ais-RefinementList-item .ais-RefinementList-label input:checked::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 14px;
  aspect-ratio: 1;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-check");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-check");
  background: var(--color--ui-field, var(--color--field));
  position: absolute
}

.ais-Panel-body .ais-RefinementList-item .ais-RefinementList-label input:focus {
  border: 2px solid var(--color--interactive-primary)
}

.ais-Panel-body .ais-RefinementList-item .ais-RefinementList-label:focus {
  outline: transparent;
  border: 2px solid var(--color--ui-border-02, var(--color--border-02))
}

.ais-Panel-body .ais-RefinementList-item .ais-RefinementList-label:focus::after {
  top: 0;
  left: 0
}

.ais-Panel-body .ais-RefinementList-item .ais-RefinementList-labelText {
  margin-left: 12px
}

.ais-Panel-body .ais-RefinementList-item span {
  color: var(--color--text-secondary)
}

.ais-Panel-body .ais-RefinementList-item span.ais-RefinementList-count {
  font-weight: var(--typeweight--bold)
}

.ais-Panel-body .ais-HierarchicalMenu-showMore {
  color: var(--color--text-link);
  font-weight: var(--typeweight--bold)
}

.ais-Panel-body .ais-HierarchicalMenu-showMore--disabled {
  display: none
}

.ais-Panel-body a {
  color: var(--color--text-secondary)
}

.ais-Panel-body a strong {
  display: inline-block
}

.ais-Panel-body .ais-HierarchicalMenu-item a {
  color: var(--color--text-secondary);
  font-weight: var(--typeweight--default)
}

@media all and (max-width:1024px) {
  .facet__category {
    margin-top: 20px
  }
}

.facet__category .ais-Panel-header {
  padding-bottom: 5px
}

@media all and (max-width:1024px) {
  .facet__category .ais-Panel-header {
    padding-bottom: 0
  }
}

@media all and (max-width:1024px) {
  .facet__price {
    margin-top: 0
  }
}

.facet__price .ais-Panel-header {
  padding-bottom: 10px
}

@media all and (max-width:1024px) {
  .facet__price .ais-Panel-header {
    padding-bottom: 5px
  }
}

.facet__formats ul li:last-child label {
  margin-bottom: 5px
}

@media all and (max-width:1024px) {
  .facet__formats {
    margin-top: 0
  }
}

@media all and (min-width:1024px) {
  .facet__editions {
    margin-top: 20px
  }
}

@media all and (max-width:1024px) {
  .facet__editions {
    margin-top: 0
  }
}

@media all and (min-width:1024px) {

  .facet__category,
  .facet__price,
  .facet__formats {
    margin-top: 25px
  }
}

.facet__editions .ais-Panel-header,
.facet__formats .ais-Panel-header {
  padding-bottom: 10px
}

@media all and (max-width:1024px) {

  .facet__editions .ais-Panel-header,
  .facet__formats .ais-Panel-header {
    padding-bottom: 5px
  }
}

.facet__editions .ais-RefinementList-showMore,
.facet__formats .ais-RefinementList-showMore,
.facet__price .ais-RefinementList-showMore {
  background: 0;
  border: 0;
  color: var(--color--text-link);
  font-weight: var(--typeweight--bold);
  margin-top: 10px;
  margin-left: -5px
}

.facet__editions .ais-RefinementList-showMore--disabled,
.facet__formats .ais-RefinementList-showMore--disabled,
.facet__price .ais-RefinementList-showMore--disabled {
  display: none
}

.facet-title {
  margin-bottom: 5px;
  font-size: var(--typesize-h4);
  line-height: var(--leading--1-2);
  font-weight: var(--typeweight--default);
  color: var(--color--text-secondary)
}

@media all and (max-width:1024px) {
  .facet-title {
    margin-bottom: 0;
    position: absolute;
    top: 54px
  }
}

.close-filters-button {
  display: none
}

@media all and (max-width:1024px) {
  .close-filters-button {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    background: transparent;
    border: 0;
    padding: 0
  }

  .close-filters-button::after {
    display: inline-block;
    line-height: inherit;
    content: "";
    flex: none;
    -webkit-mask-size: 100%;
    mask-size: 100%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
    background: currentColor;
    width: 24px;
    height: 24px;
    -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg");
    mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg")
  }
}

.ais-CustomRefinements-title {
  font-size: var(--typesize-h6);
  color: var(--color--text-primary);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-1)
}

@media all and (max-width:1024px) {
  .ais-CustomRefinements-title {
    padding-bottom: 0
  }
}

ul.ais-CustomRefinements-list .ais-CustomRefinements-category {
  color: var(--color--text-secondary);
  background-color: var(--color--ui-01);
  border: 2px solid var(--color--ui-border-01, var(--color--border-01));
  margin-top: 20px
}

@media all and (max-width:1024px) {
  ul.ais-CustomRefinements-list .ais-CustomRefinements-category {
    width: 100%
  }

  ul.ais-CustomRefinements-list .ais-CustomRefinements-category:nth-child(even) {
    margin-left: 0
  }
}

ul.ais-CustomRefinements-list .ais-CustomRefinements-category button::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md");
  top: 4px
}

.current_refinements {
  margin-bottom: 30px
}

@media all and (max-width:1024px) {
  .current_refinements {
    margin-bottom: 0;
    margin-top: 20px
  }
}

@media all and (max-width:1024px) {

  section.searchResults .order-1,
  section.searchResults .order-2 {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%
  }
}

section.searchResults .order-1 .left-children__section {
  width: 100%
}

@media all and (max-width:1024px) {
  .algolia-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center
  }
}

@media all and (min-width:376px) and (max-width:1024px) {
  .algolia-buttons {
    margin-top: 30px !important;
    margin-bottom: 16px
  }
}

@media all and (max-width:1024px) {
  .algolia-buttons .a-search__filters {
    display: inline-block;
    order: 1;
    width: 48%;
    height: 36px;
    padding: 6px 20px;
    background: var(--color--ui-background-dark, var(--color--background-dark));
    color: var(--color--text-primary-inverse);
    font-weight: var(--typeweight--bold);
    border-radius: var(--button-corners);
    border: 0
  }
}

.algolia-buttons .programList__sortBy,
.algolia-buttons .sort-by--container {
  -webkit-transition-property: none;
  -moz-transition-property: none;
  -o-transition-property: none;
  transition-property: none
}

@media all and (max-width:1024px) {

  .algolia-buttons .programList__sortBy,
  .algolia-buttons .sort-by--container {
    width: calc(45%);
    justify-content: center;
    height: 36px;
    border-radius: 54px;
    background: var(--color--ui-background-dark, var(--color--background-dark))
  }
}

@media all and (max-width:1024px) {

  .algolia-buttons .programList__sortBy::before,
  .algolia-buttons .sort-by--container::before {
    content: attr(data-select-value);
    font-size: 1.6rem;
    line-height: 1.5;
    margin-right: 0;
    position: absolute;
    font-weight: var(--typeweight--bold);
    font-size: var(--typesize-default);
    color: var(--color--text-primary-inverse)
  }
}

@media all and (max-width:1024px) {
  .algolia-buttons div.sort-by--container {
    order: 2;
    width: 48%
  }

  .algolia-buttons div.sort-by--container>select[name="sort-by"] {
    width: 100%;
    opacity: 0;
    margin-top: 1px !important
  }

  .algolia-buttons div.sort-by--container:focus-within {
    outline: -webkit-focus-ring-color solid 2px
  }
}

@media all and (max-width:1024px) {
  .algolia-buttons div.sort-by--container select {
    margin-left: 0;
    padding: 0;
    height: 36px
  }
}

@media all and (max-width:1024px) {
  .show__count.c-dropdown {
    display: none
  }
}

@media all and (max-width:1024px) {
  .parent-result__section div>div {
    order: 1
  }
}

@media all and (max-width:1024px) {
  section.searchResults .searchResults-section div.row div.col-md-3.col-12 {
    display: none
  }
}

@media all and (max-width:1024px) {
  section.searchResults.filters--open .container-fluid>div.row div.col-md-3.col-12 {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    max-width: 320px;
    max-height: 100%;
    background: var(--color--ui-background-light, var(--color--background-light));
    box-shadow: var(--shadow);
    border-radius: 0 8px 8px 0;
    overflow-y: scroll
  }

  section.searchResults.filters--open .container-fluid>div.row div.col-md-3.col-12 .function-panel+.function-panel {
    margin-top: 15px
  }

  section.searchResults.filters--open .container-fluid>div.row div.col-md-3.col-12 div:nth-child(1).function-panel {
    margin-top: 103px
  }

  section.searchResults.filters--open .container-fluid>div.row div.col-md-3.col-12 div:nth-child(5).function-panel {
    margin-top: 10px
  }
}

@media all and (max-width:1024px) {
  section.searchResults.filters--open .container-fluid>div.row::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    height: 110vh;
    width: 100%;
    background: #000;
    opacity: .6
  }
}

@media all and (max-width:1024px) {
  section.algolia-layout div.row div.col-md-9.col-12 {
    flex: 0 0 100%;
    max-width: 100%
  }
}

.syncedFilterSection {
  display: none
}

@media all and (max-width:1024px) {
  .syncedFilterSection {
    display: block;
    margin-top: 30px
  }

  .syncedFilterSection label {
    display: block;
    margin-bottom: 0;
    font-size: var(--typesize-h6);
    color: var(--color--text-primary);
    font-weight: var(--typeweight--bold);
    font-family: var(--typefamily-alt);
    font-style: var(--typestyle-normal);
    line-height: var(--leading--1-1)
  }

  .syncedFilterSection ul.ais-CustomRefinements-list {
    display: flex;
    justify-content: space-between;
    margin: 0
  }

  .syncedFilterSection ul.ais-CustomRefinements-list li.ais-CustomRefinements-category {
    width: 48%
  }
}

@media all and (max-width:576px) {
  .syncedFilterSection ul.ais-CustomRefinements-list li.ais-CustomRefinements-category {
    width: 100%
  }
}

.preventScroll {
  overflow-y: hidden
}

.visuallyHidden {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute
}

.btn__subject {
  display: block;
  width: 100%;
  border-radius: 54px;
  padding: 9px 16px;
  font-style: var(--typestyle-normal);
  font-weight: var(--typeweight--bold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  color: var(--color--text-primary);
  background: transparent;
  border: 2px solid var(--color--interactive-tertiary);
  box-sizing: border-box;
  transition: background .3s ease;
  text-align: center
}

.btn__subject:hover {
  text-decoration: none;
  transform: scale(1.05);
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-tertiary-hover)
}

div.title.subjects__title,
.subjects__title,
.subjects__title>h2 {
  font-size: var(--typesize-h3);
  line-height: var(--leading--1-1);
  color: var(--color--text-secondary);
  margin-top: 30px;
  font-weight: var(--typeweight--bold)
}

@media all and (max-width:991px) {

  div.title.subjects__title,
  .subjects__title,
  .subjects__title>h2 {
    margin-top: 0;
    font-size: 2.8rem
  }
}

.noSearchResults {
  padding-top: 0
}

.noSearchResults .container {
  padding-right: 0;
  padding-left: 0;
  max-width: 100%
}

.noSearchResults .col-12 {
  padding: 0
}

.noSearchResults .col-12>section.programList__header {
  background-color: var(--color--ui-background-dark, var(--color--background-dark));
  padding-top: 0 !important;
  padding-bottom: 0
}

.noSearchResults .col-12>section.programList__header::before {
  box-shadow: 0 100px 0 0 var(--color--text-primary-inverse);
  bottom: 0
}

.noSearchResults .row {
  margin: 0
}

.noSearchResults .noResultsHeader {
  color: var(--color--text-primary-inverse)
}

.noSearchResults .noResultsHeader .title {
  padding-top: var(--spacing-90);
  margin-bottom: var(--spacing-10)
}

.noSearchResults .noResultsHeader .title h2 {
  color: var(--color--text-primary-inverse);
  font-size: var(--typesize-h1-catalog);
  line-height: var(--leading--1-1);
  font-weight: var(--typeweight--semibold)
}

@media all and (max-width:991px) {
  .noSearchResults .noResultsHeader .title h2 {
    font-size: 38px
  }
}

.noSearchResults .noResultsHeader .text {
  padding-bottom: var(--space-xxlarge)
}

.noSearchResults .noResultsHeader .text p {
  color: var(--color--text-primary-inverse);
  font-size: var(--typesize-h4);
  line-height: var(--leading--1-2);
  font-weight: var(--typeweight--default)
}

.noSearchResults .noResultsContent,
.noSearchResults .noResultsHeader .title,
.noSearchResults .noResultsHeader .text {
  max-width: 100%;
  margin-inline-end: auto;
  margin-inline-start: auto
}

@media all and (min-width:1590px) {

  .noSearchResults .noResultsContent,
  .noSearchResults .noResultsHeader .title,
  .noSearchResults .noResultsHeader .text {
    padding-right: var(--grid-margin-lg);
    padding-left: var(--grid-margin-lg);
    max-width: 1590px
  }
}

@media all and (min-width:768px) and (max-width:1589px) {

  .noSearchResults .noResultsContent,
  .noSearchResults .noResultsHeader .title,
  .noSearchResults .noResultsHeader .text {
    padding-right: var(--grid-margin-md);
    padding-left: var(--grid-margin-md)
  }
}

@media all and (max-width:767px) {

  .noSearchResults .noResultsContent,
  .noSearchResults .noResultsHeader .title,
  .noSearchResults .noResultsHeader .text {
    padding-right: var(--grid-margin-sm);
    padding-left: var(--grid-margin-sm)
  }
}

.noSearchResults .noResultsContent {
  padding-top: var(--spacing-60)
}

@media all and (max-width:991px) {
  .noSearchResults .noResultsContent {
    padding-top: var(--spacing-45)
  }
}

@media all and (max-width:767px) {
  .noSearchResults .noResultsContent {
    padding-top: var(--spacing-30)
  }
}

.noSearchResults .noResultsContent .title h2 {
  font-size: var(--typesize-h3);
  line-height: var(--leading--1-1);
  margin-top: var(--spacing-30);
  font-weight: var(--typeweight--default)
}

.searchResults {
  --grid-margin-sm: 30px;
  --grid-margin-md: 55px;
  --grid-margin-lg: 75px
}

.searchResults .col-12 {
  padding: 0
}

.searchResults .col-12>section.programList__header {
  background-color: #151515;
  padding-top: 0 !important
}

.searchResults .col-12>section.programList__header::before {
  box-shadow: 0 100px 0 0 #fefefe;
  bottom: 0
}

.searchResults .col-12>section.programList__header .programList__title {
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0
}

.searchResults .col-12>section.programList__header .a-search__header.active-category,
.searchResults .col-12>section.programList__header .programList__title .noQueryHeader,
.searchResults .col-12>section.programList__header .programList__title .querySearchResults {
  padding-top: 100px;
  padding-bottom: 100px
}

.searchResults .col-12>section.programList__header .a-search__header.active-category h1,
.searchResults .col-12>section.programList__header .programList__title .noQueryHeader h1,
.searchResults .col-12>section.programList__header .programList__title .querySearchResults h1 {
  color: #fefefe;
  font-size: 42px;
  font-weight: 600
}

.searchResults .col-12>section.programList__header .a-search__header.active-category p,
.searchResults .col-12>section.programList__header .programList__title .noQueryHeader p,
.searchResults .col-12>section.programList__header .programList__title .querySearchResults p {
  color: #fefefe;
  font-size: 24px !important
}

.searchResults .col-12>section.programList__header .programList__title,
.searchResults .col-12>section.programList__header .programList__title>h1,
.searchResults .col-12>section.programList__header .programList__title>h2,
.searchResults .col-12>section.programList__header .programList__title>div>h1 {
  color: #e8eefa
}

.searchResults .col-12>section.programList__header>.row {
  width: 100%;
  max-width: 100%;
  margin-inline-end: auto;
  margin-inline-start: auto
}

@media all and (min-width:1590px) {
  .searchResults .col-12>section.programList__header>.row {
    padding-right: var(--grid-margin-lg);
    padding-left: var(--grid-margin-lg);
    max-width: 1590px
  }
}

@media all and (min-width:768px) and (max-width:1589px) {
  .searchResults .col-12>section.programList__header>.row {
    padding-right: var(--grid-margin-md);
    padding-left: var(--grid-margin-md)
  }
}

@media all and (max-width:767px) {
  .searchResults .col-12>section.programList__header>.row {
    padding-right: var(--grid-margin-sm);
    padding-left: var(--grid-margin-sm)
  }
}

.searchResults .col-12>section.programList__header .col-12 {
  padding-left: 8px;
  padding-right: 8px
}

.searchResults .searchResultsContent {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin-inline-end: auto;
  margin-inline-start: auto
}

@media all and (min-width:1590px) {
  .searchResults .searchResultsContent {
    padding-right: var(--grid-margin-lg);
    padding-left: var(--grid-margin-lg);
    max-width: 1590px
  }
}

@media all and (min-width:768px) and (max-width:1589px) {
  .searchResults .searchResultsContent {
    padding-right: var(--grid-margin-md);
    padding-left: var(--grid-margin-md)
  }
}

@media all and (max-width:767px) {
  .searchResults .searchResultsContent {
    padding-right: var(--grid-margin-sm);
    padding-left: var(--grid-margin-sm)
  }
}

.searchResults .searchResultsContent .col-12 {
  padding-left: 15px;
  padding-right: 15px
}

.c-blogList__card {
  border-top-color: var(--color--accent-03-primary);
  border-radius: var(--corners-semiround-sm);
  overflow: hidden
}

.c-blogList__card-description * {
  line-height: var(--leading--1-6)
}

.c-blogList__card-title {
  font-size: var(--typesize-h5);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-2)
}

.c-eventListing__content {
  border-radius: var(--corners-semiround-sm);
  overflow: hidden
}

.c-eventListing__title-heading {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

header.isSticky {
  padding-top: unset
}

header.isSticky .he-navigation-topnav,
body.inpn-page-state__crossed .he-navigation-topnav {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  height: 1px;
  width: 1px;
  min-height: unset;
  overflow: hidden;
  position: absolute;
  padding: 0;
  margin: 0
}
