.PCOM {
  .cart-wrapper {
    background: #fff;
    width: 100%;
    padding: 2.8125rem 2rem 1.25rem 2rem;
    // min-height: 100vh;

    h1.page-title {
      color: var(--color-text-primary, #151515);
      font-family: var(--typefamily--primary-stack);
      font-size: 2.625rem;
      font-weight: 600;
      text-align: left;
      padding-bottom: 30px;
    }
    main {
      max-width: 1440px;
      margin: 0 auto;

      .section-wrapper {
        display: flex;
        flex-direction: row;
        gap: 30px;

        section.cart-body {
          display: flex;
          flex-direction: column;
          width: 60%;
          max-width: 740px;
          padding: 30px;
          border-radius: 8px;
          background: var(--primary-bkgd-white, #fefefe);
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
          gap: 30px;

          .book-body-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }

          .book-info {
            text-align: left;
            display: flex;
            flex-direction: row;
            gap: 30px;
            img {
              width: 125px;
              aspect-ratio: 5/6;
              border-radius: 5px;
              filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
            }
            .text-info {
              h3 {
                color: var(--text-text-link, #007a9c);
                font-family: var(--typefamily--primary-stack);
                font-size: 1rem;
                font-weight: 600;
                padding-bottom: 15px;
              }
              p {
                color: var(--color-text-secondary, #333);
                font-family: var(--typefamily--primary-stack);
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
              }
            }
          }
          .price-info {
            display: flex;
            flex-direction: column;
            text-align: right;
            min-width: 190px;
            gap: 20px;

            h4 {
              color: var(--color-text-primary, #151515);
              font-family: var(--typefamily--primary-stack);
              font-size: 1.125rem;
              font-weight: 600;
            }
            p {
              color: var(--color-text-secondary, #333);
              font-size: 0.875rem;
              font-weight: 600;
              padding: 13px 0;
            }
            button {
              min-width: fit-content;
              color: var(--color-text-link, #007a9c);
              text-align: right;
              font-family: var(--typefamily--primary-stack);
              font-size: 14px;
              font-weight: 600;
              background: transparent;
              border: none;
            }
          }
        }
      }
    }
  }

  section.cart-price {
    width: 40%;
  }
}
