.use-button-effect-flood {
  a {
    // display: inline-block !important;
    overflow: hidden !important;
    transition: color .4s ease-in !important;
    border-radius: var(--button-corners);

    &::after {
      content: "";
      position: absolute;
      width: 10px;
      aspect-ratio: 1 / 1;
      top: 100%;
      left: 50%;
      transform: translateX(-50%) scale(1);
      border-radius: 50%;
      transition: .8s cubic-bezier(.12,.76,.67,1);
      z-index: -1;
    }

    &:hover {
      &::after {
        width: 110%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }

  &.use-button-primary {
    a {
      &::after {
        background-color: var(--color--interactive-primary-hover);
      }

      &:hover {
        &::before {
          background-color: var(--color--interactive-primary) !important;
        }
      }
    }
  }

  &.use-button-primary-inverse {
    a {
      &::after {
        background-color: var(--color--interactive-primary-inverse-hover);
      }

      &:hover {
        &::before {
          background-color: var(--color--interactive-primary-inverse) !important;
        }
      }
    }
  }

  &.use-button-secondary {
    a {
      &::after {
        background-color: var(--color--interactive-secondary-hover);
      }

      &:hover {
        &::before {
          background-color: var(--color--interactive-secondary) !important;
        }
      }
    }
  }

  &.use-button-secondary-inverse {
    a {
      &::after {
        background-color: var(--color--interactive-secondary-inverse-hover);
      }

      &:hover {
        &::before {
          background-color: var(--color--interactive-secondary-inverse) !important;
        }
      }
    }
  }

  &.use-button-tertiary {
    a {
      &::after {
        background-color: var(--color--interactive-tertiary-hover);
      }

      &:hover {
        &::before {
          background-color: transparent !important;
        }
      }
    }
  }

  &.use-button-tertiary-inverse {
    a {
      &::after {
        background-color: var(--color--interactive-tertiary-inverse-hover);
      }

      &:hover {
        &::before {
          outline-color: var(--color--interactive-tertiary-inverse) !important;
          background-color: transparent !important;
        }
      }
    }
  }

  &.use-button-action {
    a {
      &::after {
        background-color: var(--color--interactive-action-hover);
      }

      &:hover {
        &::before {
          background-color: var(--color--interactive-action) !important;
        }
      }
    }
  }

  &.use-button-action-inverse {
    a {
      &::after {
        background-color: var(--color--interactive-action-inverse-hover);
      }

      &:hover {
        &::before {
          background-color: var(--color--interactive-action-inverse) !important;
        }
      }
    }
  }
}
