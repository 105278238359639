/*  This file is loaded in /src/index.js              */
/*  It's not necessary to load it in any other place  */

:root {
  --color--reskin--pearson-purple: #0D004D;

  --color--reskin--amethyst: #512EAB;
/*  --color--reskin--dark-amethyst: #512EAB;*/
  --color--reskin--light-amethyst: #693CDE;

  --color--reskin--light-purple: #C1BFFF;
  --color--reskin--very-light-purple: #EBEBFF;

  --color--reskin--fuchsia: #DF41CF;
  --color--reskin--dark-fuchsia: #AB329F;
  --color--reskin--light-fuchsia: #FF4AED;

  --color--reskin--amber: #FFCE00;
/*  --color--reskin-deark-amber: #FFCE00;*/
  --color--reskin--light-amber: #FFDF66;


  --color--reskin--turquoise: #56E2E1;
  --color--reskin--light-turquoise: #61FFFE;
  --color--reskin--mid-turquoise: #1EAEAD;
/*  --color--reskin--dark-turquoise: #;*/

  --color--reskin--pearson-black: #05001C;
  --color--reskin--pearson-black-70-opacity: #05001CB2;
  --color--reskin--pearson-black-50-opacity: #05001C80;


  --color--reskin--hallmark-link: #095c9c;
  --color--reskin--hallmark-link-hover: #084D82;
  --color--reskin--hallmark-link-active:#063E69;

  --typefamily--primary: "Plus Jakarta Sans";
  --typefamily--primary-stack: var(--typefamily--primary), Helvetica, Verdana, sans-serif;
}


/* FONTS - Plus Jakarta Sans (Variable) */
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');


/* OVERRIDES for Master Token Fonts */
:root {
  --typefamily-alt: var(--typefamily--primary-stack);
  --typefamily-primary: var(--typefamily--primary-stack);
}


/* Fix Delta's semibold value - was 645 */
:root {
  --typeweight--semibold-plus: 600;
  --typeweight--semibold: var(--typeweight--semibold-plus);
  --typeweight--bold: var(--typeweight--semibold-plus);
}
