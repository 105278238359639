.PCOM-GANKED.home-page {

  .OVERRIDE-HOME  {
    &.primarybtn.radiant-a {
      display: inline-flex;
      font-family: var(--typefamily-primary);
      font-weight: var(--typeweight--semibold);
      font-size: var(--typesize-default);
      line-height: var(--leading--1-5);
      text-align: center;
      white-space: normal;
      text-decoration: none;
      background-color: transparent;
      vertical-align: middle;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      min-height: 2.25rem;
      padding: .375em 1.875em;
      position: relative;
      z-index: 1;
      color: var(--color--text-primary-inverse) !important;
      white-space: nowrap !important;

      border: 2px solid transparent !important;
      border-radius: var(--button-corners);

      --blueRipple: #25A4CE;
      --fuchsia: #DF21CF;
      --amber: #FFCB00;
      --deg: 45deg;

      &:before {
        transition: scale 0.125s ease-in-out !important;
      }

      &:after {
        transition: scale 0.125s ease-in-out !important;
        content: "";
        position: absolute;
        inset: -2px; /* Inset and padding must be equal but opposite signs */
        padding: 2px;
        border-radius: 50px;
        background-color: var(--color--interactive-primary);
        mask: exclude linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        opacity: 0;
        background: conic-gradient(from var(--spinAngle), var(--fuchsia), var(--blueRipple), var(--fuchsia));
      }

      &:hover {
        &:before {
          transform: scale(1.05) !important;
        }
        &:after {
          transform: scale(1.05) !important;
          animation: spinAndPulse 3s infinite linear;
        }
      }



      &:active {
        &:before {
          transform: scale(1) !important;
          background: var(--color--interactive-primary-active) !important;
        }

        &:after {
          transform: scale(1) !important;
          opacity: 1;
        }
      }



      @keyframes pulse {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 1;
        }
      }

      @keyframes spinAndPulse {
        0% {
          opacity: 0;
          --spinAngle: 0deg;
        }
        25% {
          opacity: 1;
          --spinAngle: 90deg;
        }
        50% {
          opacity: 0;
          --spinAngle: 180deg;
        }
        50% {
          opacity: 1;
          --spinAngle: 270deg;
        }
        100% {
          opacity: 0;
          --spinAngle: 360deg;
        }
      }
    }

    &.primarybtn.radiant-b {
      display: inline-flex;
      font-family: var(--typefamily-primary);
      font-weight: var(--typeweight--semibold);
      font-size: var(--typesize-default);
      line-height: var(--leading--1-5);
      text-align: center;
      white-space: normal;
      text-decoration: none;
      background-color: transparent;
      vertical-align: middle;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      min-height: 2.25rem;
      padding: .375em 1.875em;
      position: relative;
      z-index: 1;
      color: var(--color--text-primary-inverse) !important;
      white-space: nowrap !important;

      border: 2px solid transparent !important;
      border-radius: var(--button-corners);

      --blueRipple: #25A4CE;
      --fuchsia: #DF21CF;
      --amber: #FFCB00;
      --purple: #5E32A7;
      --deg: 45deg;

      &:before {
        transition: scale 0.125s ease-in-out !important;
      }

      &:after {
        transition: scale 0.125s ease-in-out !important;
        content: "";
        position: absolute;
        inset: -2px; /* Inset and padding must be equal but opposite signs */
        padding: 2px;
        border-radius: 50px;
        background-color: var(--color--interactive-primary);
        mask: exclude linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        opacity: 0;
        background: conic-gradient(from var(--spinAngle), var(--purple), var(--amber), var(--purple));
      }

      &:hover {
        &:before {
          transform: scale(1.05) !important;
        }
        &:after {
          transform: scale(1.05) !important;
          animation: spinAndPulse 3s infinite linear;
        }
      }



      &:active {
        &:before {
          transform: scale(1) !important;
          background: var(--color--interactive-primary-active) !important;
        }

        &:after {
          transform: scale(1) !important;
          opacity: 1;
        }
      }



      @keyframes pulse {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 1;
        }
      }

      @keyframes spinAndPulse {
        0% {
          opacity: 0;
          --spinAngle: 0deg;
        }
        25% {
          opacity: 1;
          --spinAngle: 90deg;
        }
        50% {
          opacity: 0;
          --spinAngle: 180deg;
        }
        50% {
          opacity: 1;
          --spinAngle: 270deg;
        }
        100% {
          opacity: 0;
          --spinAngle: 360deg;
        }
      }
    }


    &.primarybtn.radiant-c {
      display: inline-flex;
      font-family: var(--typefamily-primary);
      font-weight: var(--typeweight--semibold);
      font-size: var(--typesize-default);
      line-height: var(--leading--1-5);
      text-align: center;
      white-space: normal;
      text-decoration: none;
      background-color: transparent;
      vertical-align: middle;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      min-height: 2.25rem;
      padding: .375em 1.875em;
      position: relative;
      z-index: 1;
      color: var(--color--text-primary-inverse) !important;
      white-space: nowrap !important;

      border: 2px solid transparent !important;
      border-radius: var(--button-corners);

      --blueRipple: #25A4CE;
      --fuchsia: #DF21CF;
      --amber: #FFCB00;
      --purple: #5E32A7;
      --deg: 45deg;

      &:before {
        transition: scale 0.125s ease-in-out !important;
      }

      &:after {
        transition: scale 0.125s ease-in-out !important;
        content: "";
        position: absolute;
        inset: -2px; /* Inset and padding must be equal but opposite signs */
        padding: 2px;
        border-radius: 50px;
        background-color: var(--color--interactive-primary);
        mask: exclude linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        opacity: 0;
        background: conic-gradient(from var(--spinAngle), var(--purple), var(--blueRipple), var(--purple), var(--blueRipple), var(--purple));
      }

      &:hover {
        &:before {
          transform: scale(1.05) !important;
        }
        &:after {
          transform: scale(1.05) !important;
          animation: spinAndPulse 3s infinite linear;
        }
      }



      &:active {
        &:before {
          transform: scale(1) !important;
          background: var(--color--interactive-primary-active) !important;
        }

        &:after {
          transform: scale(1) !important;
          opacity: 1;
        }
      }



      @keyframes pulse {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 1;
        }
      }

      @keyframes spinAndPulse {
        0% {
          opacity: 0;
          --spinAngle: 0deg;
        }
        25% {
          opacity: 1;
          --spinAngle: 90deg;
        }
        50% {
          opacity: 0;
          --spinAngle: 180deg;
        }
        50% {
          opacity: 1;
          --spinAngle: 270deg;
        }
        100% {
          opacity: 0;
          --spinAngle: 360deg;
        }
      }
    }
  }


}

@property --spinAngle {
        /* An initial value for our custom property */
        initial-value: 0deg;
        /* Whether it inherits from parent set values or not */
        inherits: false;
        /* The type. Yes, the type. You thought TypeScript was cool */
        syntax: '<angle>';
      }

.PCOM-GANKED .use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):focus::before {
  // outline-width: 0 !important;
  // background-color: #F00 !important;
}


.PCOM-GANKED .use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id]):focus-visible::before {
    // background: #F00 !important;
    // outline-width: 3px !important;
    // outline-color: var(--color--interactive-focus-field) !important;
  }
