.column-control.collapse-sm>.container>.row,
.column-control.collapse-sm>.row {
  position: relative;
  padding-bottom: var(--spacing-60)
}

.column-control.collapse-sm>.container>.row .btn-expand-wrapper,
.column-control.collapse-sm>.row .btn-expand-wrapper {
  display: none
}

@media(max-width:481px) {

  .column-control.collapse-sm>.container>.row .btn-expand-wrapper,
  .column-control.collapse-sm>.row .btn-expand-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%
  }

  .column-control.collapse-sm>.container>.row .btn-expand-wrapper .btn-expand,
  .column-control.collapse-sm>.row .btn-expand-wrapper .btn-expand {
    display: flex;
    align-items: center;
    font-weight: var(--typeweight--bold);
    text-decoration: none;
    font-size: var(--typesize-default);
    line-height: var(--leading--1-5);
    color: var(--color--text-link);
    transition: all .2s ease-in-out;
    background-color: transparent;
    border: 0;
    padding: 0 var(--spacing-30)
  }

  .column-control.collapse-sm>.container>.row .btn-expand-wrapper .btn-expand::after,
  .column-control.collapse-sm>.row .btn-expand-wrapper .btn-expand::after {
    transition: transform .3s ease-in-out
  }

  .column-control.collapse-sm>.container>.row .btn-expand-wrapper::before,
  .column-control.collapse-sm>.row .btn-expand-wrapper::before,
  .column-control.collapse-sm>.container>.row .btn-expand-wrapper::after,
  .column-control.collapse-sm>.row .btn-expand-wrapper::after {
    content: '';
    display: block;
    flex-grow: 1;
    border-bottom: 1px solid var(--color--border-01)
  }

  .column-control.collapse-sm>.container>.row:not(.is-expanded)>[class*="col-"]:not(:first-child),
  .column-control.collapse-sm>.row:not(.is-expanded)>[class*="col-"]:not(:first-child) {
    display: none
  }

  .column-control.collapse-sm>.container>.row.is-expanded .btn-expand::after,
  .column-control.collapse-sm>.row.is-expanded .btn-expand::after {
    transform: rotate(-180deg)
  }
}
