.PCOM {
  .order-confirmation {
    background: #fff;
    width: 100%;
    padding: 5rem 2rem 1.25rem 2rem;
    min-height: 100vh;

    .page-top {
      padding-bottom: 35px;
      border-bottom: 1px solid #dfe1e1;
      h1.page-title {
        color: var(--color--text-primary, #151515);
        font-family: var(--typefamily--primary-stack);
        font-size: 2.625rem;
        font-weight: 600;
        text-align: left;
        padding-bottom: 30px;
      }
      h2.sub-title {
        color: var(--color-text--primary, #151515);
        font-size: 1.875rem;
        font-weight: 400;
        padding-bottom: 32px;
      }
      p.description {
        color: var(--color-text--secondary, #333);
        font-size: 1rem;
        font-weight: 400;
        span {
          font-weight: 600;
        }
      }
    }

    main {
      max-width: 1440px;
      margin: 0 auto;
      text-align: left;
      .order-body {
        padding: 35px 0;
      }
    }

    section.order-body {
      display: flex;
      flex-direction: column;
      gap: 35px;
      border-bottom: 1px solid #dfe1e1;

      h3 {
        color: var(--color--text-secondary, #333);
        font-size: 1.5rem;
        font-weight: 400;
      }
      p {
        color: var(--color--text-secondary, #333);
        font-size: 1rem;
        font-weight: 400;
        span, a {
          color: var(--color--text-link, #007a9c);
          font-weight: 600;
          cursor: pointer;
          &:hover {
            text-decoration: underline;

          }
        }
      }
      .order {
        display: flex;

        img.book-cover {
          width: 4.5rem;
          max-height: 6.5rem;
          aspect-ratio: 4.5/6;
          border-radius: 5px;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
        }
        img.channel {
          max-width: 4.5rem;
          height: 42.75px;
          border-radius: 5px;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
        }
        .text {
          width: 100%;
          padding-left: 20px;
          h3 {
            color: var(--color--text-secondary, #333);
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 12px;
            &.accent {
              color: var(--color--text-link, #007a9c);
              margin-bottom: 12px;
            }
          }
        }
      }
      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .price {
        font-weight: 600;
      }
      .order-summary {
        padding: 1.5rem;
        background: var(--color--ui-01);

        h3 {
          color: var(--color--text-primary, #151515);
          font-size: 1.5rem;
          font-weight: 400;
          padding-bottom: 24px;
        }
        .order-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        .order-detail {
          display: flex;
          flex-direction: column;
          color: var(--color--text-secondary, #333);
          font-size: 1rem;
          &:last-child {
            width: 50%;
            text-align: right;
            h4 {
              font-weight: 600;
            }
          }
          h4 {
            font-weight: 400;
          }
          p {
            font-weight: 600;
          }
        }
      }

      .course-detail-info {
        p {
          font-size: 0.875rem;
          line-height: 1.5;
        }
      }

      .billing {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .billing-content {
          display: flex;
          flex-direction: row;
          gap: 32px;
          width: 50%;
          p {
            color: var(--color--text-secondary, #333);
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.6;
          }
        }
      }

      .bundle-pack-wrapper {
        .bundle-pack {
          justify-content: flex-start;
          gap: 24px;

          img {
            max-width: 4.5rem;
            height: 42.75px;
            border-radius: 5px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
          }
          .text {
            width: 100%;

            h3 {
              color: var(--color--text-secondary, #333);
              font-size: 1rem;
              font-weight: 600;
              margin-bottom: 12px;
            }
          }
        }
        .subscription-content {
          max-width: 53.25rem;
          p {
            padding-bottom: 35px;
          }
          i {
            color: var(--color--text-secondary, #333);
            font-size: 1rem;
            font-weight: 400;
          }
        }
      }
    }
    section.price-confirmation {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 90px;
      .position-right {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        width: 40rem;
      }
    }
  }
}
