.PCOM {
  .checkout-wrapper {
    background: #fff;
    width: 100%;
    padding: 5rem 2rem;
    min-height: 100vh;
    h1.page-title {
      color: var(--color-text-primary, #151515);
      font-family: var(--typefamily--primary-stack);
      font-size: 2.625rem;
      font-weight: 600;
      text-align: left;
      padding-bottom: 30px;
    }
    main {
      max-width: 1440px;
      margin: 0 auto;

      .body-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 3.75rem;
      }
    }
  }

  section.payment {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    font-family: var(--typefamily--primary-stack);
    text-align: left;

    h2.payment-title {
      color: var(--text-inverse-text-primary, #fefefe);
      font-size: 18px;
      font-weight: 600;
      padding: 15px 20px;
      border-radius: 4px;
      background: var(--interactive-interactive-secondary, #151515);
    }
  }

  section.order-summary {
    width: 50%;
    display: flex;
    flex-direction: column;
    font-family: var(--typefamily--primary-stack);
    text-align: left;

    h1 {
      color: var(--color-text-primary, #151515);
      font-size: 1.375rem;
      font-weight: 400;
    }

    .order-content {
      border-top: 1px solid var(--ui-elements-border-01, #dfe1e1);
      border-bottom: 1px solid var(--ui-elements-border-01, #dfe1e1);
      padding: 30px 0;
      margin: 30px 0;

      .book-info {
        text-align: left;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .img-text-wrapper {
          display: flex;
          align-items: flex-start;
          gap: 20px;
          flex-grow: 1;
        }

        img {
          width: 85px;
          height: auto;
          border-radius: 5px;
        }

        .text-info {
          flex-grow: 1;

          h3 {
            color: var(--text-text-primary, #151515);
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 8px;
            line-height: 1.6;
          }
          p {
            color: var(--color-text-secondary, #333);
            font-size: 14px;
            font-weight: 400;
            line-height: 150%; /* 21px */

            &.price {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }
          }
        }
      }
      .course-detail-container {
        margin: 30px 0 30px 0;
        .header {
          margin-bottom: 16px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          vertical-align: middle;
          cursor: pointer;
        }
        .arrow {
          color: #151515;
          align-self: center;
        }
        h6 {
          color: var(--color-text-primary, #151515);
          font-size: 1rem;
          font-weight: 600;
        }
        p {
          color: var(--color-text-secondary, #333);
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.6;
          span {
            color: var(--color-text-link, #007a9c);
            font-weight: 600;
          }
        }
      }
    }
  }

  section .need-help-footer {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 25.6px */

    border-top: 1px solid #dfe1e1;
    border-bottom: 1px solid #dfe1e1;

    .fake-link {
      text-decoration: underline;
    }
  }
}
