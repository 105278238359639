.PCOM {

  &.nav-is-sticky {
    position: sticky;
    top: 0;
    z-index: 1000;
    will-change: scroll-position;
  }

  .nav-wrapper {
    margin: 0;
    padding: 0;
    position: relative;
    overflow: visible;
  }

  nav {
    padding: 0 2rem;
    // background-color: var(--color--reskin--pearson-purple);
    background-color: var(--color--background-dark);
    fill: white; // Logo color
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 67px;

    .menu-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      ul.menu-list {
        display: flex;
        flex-direction: row;
        margin-left: auto;

        li {
          text-align: center;
          margin-right: 30px;
          display: flex;
          align-items: center;
          width: max-content;
          min-height: 67px;
          border-bottom: 3px solid transparent;

          a {
            font-size: 1rem;
            color: var(--interactive-inverse-interactive-tertiary, #fff);
            font-family: var(--typefamily--primary-stack);
            font-weight: 400;
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            &:after {
              margin-left: 3px;
              display: inline-block;
              line-height: inherit;
              content: "";
              flex: none;
              mask-size: 100%;
              mask-repeat: no-repeat;
              mask-position: center;
              background: currentColor;
              width: 18px;
              height: 18px;
              mask-image: url('../pcom-reskin/_ganked-css/highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md');
            }
          }
          &:hover {
            border-bottom: 3px solid var(--color--reskin--amber);
          }
        }
      }
    }

    .function-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: end;

      .secure-checkout {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;

        color: #FEFEFE;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */

        img {
          height: 16px;
          width: auto;
        }
      }
    }

    // Must put .is-stuck in `.is-transparent` below as well
    // TODO: Simplify
    &.is-stuck {
      // background-color: var(--color--reskin--very-light-purple);
      // color: var(--color--reskin--pearson-purple);
      // fill: var(--color--reskin--pearson-purple);
      background-color: oklch(19.96% 0.12610858701528674 274.3496735870381 / 65%);
      backdrop-filter: blur(6px);
    }
  }

  .is-transparent {
    nav {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: transparent;
      z-index: 99;
      transition: background .4s;

      // Must put .is-stuck in `nav` above as well
      // TODO: Simplify
      &.is-stuck {
        // background-color: var(--color--reskin--very-light-purple);
        // color: var(--color--reskin--pearson-purple);
        // fill: var(--color--reskin--pearson-purple);
        background-color: oklch(19.96% 0.12610858701528674 274.3496735870381 / 65%);
        backdrop-filter: blur(6px);
      }
    }
  }

  .crumbtrail {
    margin: 0;
    padding: 5px 43px;;
    text-align: left;
    background-color: var(--color--reskin--pearson-purple);;

    .return {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;

      background-color: transparent;
      border: none;
      color: var(--text-inverse-text-primary, #FEFEFE);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */

      img {
        width: 14px;
        height: auto;
      }
    }
  }
}

// account dropdown style
.PCOM {
  .account-button-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    .down-arrow {
      margin-left: 10px;
      color: var(--text-inverse-text-primary, #fefefe);
      transform: rotate(0deg);
      transition: transform 0.2s ease;
      &.active {
        transform: rotate(-180deg);
      }
    }

    .account-button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid var(--text-inverse-text-primary, #fefefe);
      border-radius: 100%;
      width: 36px;
      height: 36px;
      text-align: center;
      font-size: 1rem;
      font-weight: 600;
      color: var(--text-inverse-text-primary, #fefefe);
      background: transparent;
      margin-left: 20px;
      &.active {
        background-color: #fefefe;
        color: #151515;
      }
    }
  }

  ul.dropdown-menu {
    position: absolute;
    background-color: #fefefe;
    border-radius: 4px;
    width: 14.5rem;
    top: 67px;
    right: 45px;
    padding: 1.25rem 0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    &.active {
      display: block;
    }
    .triangle {
      position: absolute;
      top: -7px;
      right: 10%;
      width: 0;
      height: 0;
      border: 0.5rem solid transparent;
      border-top: 0;
      border-bottom: 0.5rem solid #fefefe;
    }

    li {
      padding: 0 1.25rem;
      margin-bottom: 1rem;
      height: 26px;
      text-align: left;
      cursor: pointer;
      a {
        display: block;
        font-weight: 400;
        font-size: 1rem;
        color: #151515;
      }
      &.user {
        margin-bottom: 1.25rem;
        height: 46px;
        line-height: 46px;
        border-bottom: 1px solid #dfe1e1;
        a {
          font-weight: 600;
        }
      }

      &.sign-out {
        margin-top: 1.25rem;
        margin-bottom: 0;
        padding-top: 0.5rem;
        height: 46px;
        line-height: 46px;
        border-top: 1px solid #dfe1e1;
      }
    }
  }
}
