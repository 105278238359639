.PCOM {
  h2.column-title {
    margin-bottom: 5px;
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--color-text-secondary);
  }
  .filter {
    display: flex;
    flex-direction: column;
    gap: 10px;
    h3.filter-title {
      font-size: 1.125rem;
      color: var(--color-text-primary);
      font-weight: 600;
    }
    a.show-more {
      color: var(--color--text-link);
      font-size: 1rem;
      font-weight: 600;
      margin-top: 10px;
    }

    .subject-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      li {
        font-family: var(--typefamily--primary-stack);
        color: #333;
        font-size: 0.875rem;
        font-weight: 400;
        text-align: left;
        width: 100%;
        cursor: pointer;
      }
    }
  }
}
