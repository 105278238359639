// ======= Word Clipper Animation A
.use-text-clipper-a {
  ul{
    display: inline-flex;
    flex-direction: row;;
    position: relative;

    li {
      margin: 0;
      white-space: nowrap;
      color: transparent;
      padding-bottom: .1em; // To accomodate Plus Jakarta descenders falling outside line-height

      &:not(:last-child) {
        position: absolute;
        // width: 100%;
      }

      &:first-child {
        color: var(--color--text-brand-03);
      }

      // background: linear-gradient(90deg, #0FF 0%, #F00 100%) no-repeat;
      background-repeat: no-repeat;
      background-size: 200%;
      background-position-x: 200%;
      background-position-y: 50%; // We go from -50% to 150%

      // Clipped text color is controlled by the circle's fill color
      // Background image MUST be perfectly square and scaled equally in both directions for positioning to work properly
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http%3A//www.w3.org/2000/svg' version='1.1' viewBox='0 0 100 100'><defs><style>.st0 {fill: %23c1bfffff;}</style></defs><rect class='st0' width='100' height='100'/></svg>");

      + li {
        margin-block-start: 0;
      }
    }
  }
}

.animated.in-view {
  @media (prefers-reduced-motion: no-preference) {
    .use-text-clipper-a {
      ul {
        li {
          // Define --index for each child
          &:nth-child(1) { --index: 0;}
          &:nth-child(2) { --index: 1;}
          &:nth-child(3) { --index: 2;}
          &:nth-child(4) { --index: 3;}
          &:nth-child(5) { --index: 4;}
          &:nth-child(6) { --index: 5;}
          &:nth-child(7) { --index: 6;}
          &:nth-child(8) { --index: 7;}
          &:nth-child(9) { --index: 8;}
          &:nth-child(10) { --index: 9;}

          color: transparent;
          background-clip: text;

          --in: 2s;
          --hold: 3s;
          --out: 2s;
          --gap: 0s;

          --hold-delay: var(--in);
          --out-delay: calc(var(--in) + var(--hold));

          --offset: calc( (var(--in) + var(--hold) + var(--out) + var(--gap)) * var(--index));

          animation:
            var(--in) ease-out var(--offset) 1 forwards a-in,
            var(--hold) linear calc(var(--hold-delay) + var(--offset)) 1 forwards a-hold,
            var(--out) ease-in calc(var(--out-delay) + var(--offset)) 1 forwards a-out;

          &:first-child {
            background-position-x: 50%;
            animation:
              var(--hold) linear calc(var(--hold-delay) + var(--offset)) 1 forwards a-hold,
              var(--out) ease-in calc(var(--out-delay) + var(--offset)) 1 forwards a-out;
          }

          &:last-child {
            animation:
            var(--in) ease-out var(--offset) 1 forwards a-in,
            var(--hold) linear calc(var(--hold-delay) + var(--offset)) 1 forwards a-hold;
          }

          @keyframes a-in {
            0% {
              background-position-x: 200%;
            }
            100% {
              background-position-x: 50%;
            }
          }

          @keyframes a-hold {
            0%, 100% {
              background-position-x: 50%;
            }
          }

          @keyframes a-out {
            0% {
              background-position-x: 50%;
            }
            100% {
              background-position-x: -200%;
            }
          }
        }
      }
    }
  }
}
