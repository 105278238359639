// ======= Word Clipper Animation B
.use-text-clipper-b {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    display: inline-flex;
    flex-direction: row;;

    position: relative;

    li {
      margin: 0;
      white-space: nowrap;
      // color: var(--color--text-brand-03);
      color: transparent;
      padding-bottom: .1em; // To accomodate Plus Jakarta descenders falling outside line-height

      &::before {
        content: attr(data-text);
        position: absolute;
        top:0;
        left:0;
        width: 0;
        height: 100%;
        color: var(--color--text-brand-03);
        overflow: hidden;
        white-space: nowrap;
      }

      &:not(:last-child) {
        position: absolute;
      }

      &:first-child {
        &::before {
          width: 100%;
        }
      }

      + li {
        margin-block-start: 0;
      }
    }
  }
}

.animated.in-view {
  @media (prefers-reduced-motion: no-preference) {
    .use-text-clipper-b {
      ul {
        li {
          // Define --index for each child
          &:nth-child(1) { --index: 0;}
          &:nth-child(2) { --index: 1;}
          &:nth-child(3) { --index: 2;}
          &:nth-child(4) { --index: 3;}
          &:nth-child(5) { --index: 4;}
          &:nth-child(6) { --index: 5;}
          &:nth-child(7) { --index: 6;}
          &:nth-child(8) { --index: 7;}
          &:nth-child(9) { --index: 8;}
          &:nth-child(10) { --index: 9;}

          top: 0;
          left: 0;
          // width: 100%;

          color: transparent;

          --in: 2s;
          --hold: 3s;
          --out: 1s;
          --gap: 0s;

          --hold-delay: var(--in);
          --out-delay: calc(var(--in) + var(--hold));

          --offset: calc( (var(--in) + var(--hold) + var(--out) + var(--gap)) * var(--index));

          &::before {
            animation:
              var(--in) ease-out var(--offset) 1 forwards b-in,
              var(--hold) linear calc(var(--hold-delay) + var(--offset)) 1 forwards b-hold,
              var(--out) ease-in calc(var(--out-delay) + var(--offset)) 1 forwards b-out;
          }

          &:first-child::before {
            animation:
              var(--hold) linear calc(var(--hold-delay) + var(--offset)) 1 forwards b-hold,
              var(--out) ease-in calc(var(--out-delay) + var(--offset)) 1 forwards b-out;
          }

          &:last-child::before {
            animation:
              var(--in) ease-out var(--offset) 1 forwards b-in,
              var(--hold) linear calc(var(--hold-delay) + var(--offset)) 1 forwards b-hold;
          }

          @keyframes b-in {
            0% {
              width: 0%;
            }
            100% {
              width: 100%;
            }
          }

          @keyframes b-hold {
            0%, 100% {
              width: 100%;
            }
          }

          @keyframes b-out {
            0% {
              transform: translateX(0);
              width: 100%;
              opacity: 1;
            }
            100% {
              transform: translateX(1.5em);
              opacity: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
