.carousel-with-transitions {
  padding: 295px 155px;
  background: var(--color--text-brand-01);
  border-radius: 40px;


  h2 {
    margin: 0 0 5px 0;
    color: var(--color--text-secondary-inverse);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Plus Jakarta Sans";
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 115%; /* 57.5px */
  }

  h3 {
    margin: 0;
    color: var(--color--text-brand-03);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Plus Jakarta Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 115%; /* 46px */
  }

  ul {
    margin: 0 0 32px 0;
    padding: 0;
    list-style: none;

    li {
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: "Plus Jakarta Sans";
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 115%; /* 46px */
    }
  }

  p {
    margin: 0 0 32px;
    color: var(--color--text-primary-inverse);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Plus Jakarta Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
  }

  @media (max-width: 768px) {
    padding: 150px 75px;

    h2 {
      font-size: 40px;
    }

    ul li {
      font-size: 30px;
    }
  }

  @media (max-width: 500px) {
    padding: 40px 35px;

    h2 {
      font-size: 30px;
    }

    ul li {
      font-size: 20px;
    }
  }
}
