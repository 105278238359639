.PCOM-GANKED {
  // === Focus Outline Fix (show outline only on :focus-visible)
  .use-button-effect-focus-fix,
  .use-button-primary.use-button-effect-focus-fix:not(div),
  .use-button-primary.use-button-effect-focus-fix a:not([name]):not([id]),
  .use-button-primary.use-button-effect-focus-fix button,

  .use-button-primary-inverse.use-button-effect-focus-fix:not(div),
  .use-button-primary-inverse.use-button-effect-focus-fix a:not([name]):not([id]),
  .use-button-primary-inverse.use-button-effect-focus-fix button,

  .use-button-secondary.use-button-effect-focus-fix:not(div),
  .use-button-secondary.use-button-effect-focus-fix a:not([name]):not([id]),
  .use-button-secondary.use-button-effect-focus-fix button,

  .use-button-secondary-inverse.use-button-effect-focus-fix:not(div),
  .use-button-secondary-inverse.use-button-effect-focus-fix a:not([name]):not([id]),
  .use-button-secondary-inverse.use-button-effect-focus-fix button,

  .use-button-tertiary.use-button-effect-focus-fix:not(div),
  .use-button-tertiary.use-button-effect-focus-fix a:not([name]):not([id]),
  .use-button-tertiary.use-button-effect-focus-fix button,

  .use-button-tertiary-inverse.use-button-effect-focus-fix:not(div),
  .use-button-tertiary-inverse.use-button-effect-focus-fix a:not([name]):not([id]),
  .use-button-tertiary-inverse.use-button-effect-focus-fix button,

  .use-button-action.use-button-effect-focus-fix:not(div),
  .use-button-action.use-button-effect-focus-fix a:not([name]):not([id]),
  .use-button-action.use-button-effect-focus-fix button

  .use-button-action-inverse.use-button-effect-focus-fix:not(div),
  .use-button-action-inverse.use-button-effect-focus-fix a:not([name]):not([id]),
  .use-button-action-inverse.use-button-effect-focus-fix button
   {
    &:focus {
      &:before {
        outline-width: unset;
        outline-color: transparent;
        transform: scale(1);
      }
      &:hover {
        &:before {
          transform: scale(1.05);
        }
      }
    }
  }

  // Correct Tertiary focus state (match default state)
  .use-button-tertiary.use-button-effect-focus-fix:not(div),
  .use-button-tertiary.use-button-effect-focus-fix a:not([name]):not([id]),
  .use-button-tertiary.use-button-effect-focus-fix button {
    &:focus {
      &:before {
        outline-width: 2px;
        outline-color: var(--color--interactive-tertiary);
      }
      &:hover {
        color: var(--color--text-primary-inverse);
        &:before {
          outline-color: var(--color--interactive-tertiary-hover);
          background: var(--color--interactive-tertiary-hover);
        }
      }
    }
  }

  // Correct Tertiary Inverse focus state (match default state)
  .use-button-tertiary-inverse.use-button-effect-focus-fix:not(div),
  .use-button-tertiary-inverse.use-button-effect-focus-fix a:not([name]):not([id]),
  .use-button-tertiary-inverse.use-button-effect-focus-fix button {
    &:focus {
      &:before{
        outline-color: var(--color--interactive-tertiary-inverse);
        outline-width: 2px;
        background: transparent;
        transform: scale(1);
      }
      &:hover {
        color: var(--color--text-primary);
        &:before {
          outline-color: transparent;
          background: var(--color--interactive-tertiary-inverse-hover, var(--color--interactive-tertiary-hover-inverse));
        }
      }
    }
  }


  // Correct Action focus state (match default state)
  .use-button-action.use-button-effect-focus-fix:not(div),
  .use-button-action.use-button-effect-focus-fix a:not([name]):not([id]),
  .use-button-action.use-button-effect-focus-fix button {
    &:focus {
      &:before {
        background: var(--color--interactive-action) border-box;
        outline-width: 2px;
        outline-color: transparent;
      }
      &:hover {
        color: var(--color--text-primary-inverse);
        &:before {
          background: var(--color--interactive-action-hover) border-box;
        }
      }
    }
  }

  // Correct Action Inverse focus state (match default state)
  .use-button-action-inverse.use-button-effect-focus-fix:not(div),
  .use-button-action-inverse.use-button-effect-focus-fix a:not([name]):not([id]),
  .use-button-action-inverse.use-button-effect-focus-fix button {
    &:focus {
      &:before {
        background: var(--color--interactive-action-inverse) border-box;
        outline-width: 2px;
        outline-color: transparent;
        transform: scale(1);
      }
      &:hover {
        &:before {
          background: var(--color--interactive-action-inverse-hover, var(--color--interactive-action-hover-inverse)) border-box;
        }
      }
    }
  }
}
