.word-fade-card {
  width: 510px;
  padding: 30px;
  background: var(--color--text-brand-01);
  border-radius: 40px;


  h2 {
    margin: 0 0 20px 0;
    color: #FFF;

    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Plus Jakarta Sans";
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 60px */
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      color: var(--color--text-brand-03);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: "Plus Jakarta Sans";
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 38.4px */
      opacity: .3;
    }
  }
}

.animated.in-view {
  @media (prefers-reduced-motion: no-preference) {
    .use-word-fade-a {
      ul {
        li {
          // Define --index for each child <li>
          &:nth-child(1) { --index: 0;}
          &:nth-child(2) { --index: 1;}
          &:nth-child(3) { --index: 2;}
          &:nth-child(4) { --index: 3;}
          &:nth-child(5) { --index: 4;}
          &:nth-child(6) { --index: 5;}
          &:nth-child(7) { --index: 6;}
          &:nth-child(8) { --index: 7;}
          &:nth-child(9) { --index: 8;}
          &:nth-child(10) { --index: 9;}

          --total-items: 3; // Total number of items in list
          --in: .55s; // Duration of animate in
          --hold: 2s; // Duration of item being shown
          --out: .55s; // Duration of animate out
          --gap: -0.25s; // Delay between items' sequences
          --final: .55s; // Timing of all items fading in at end - match `--in`

          // Animation calculations
          --out-delay: calc( var(--in) + var(--hold));
          --offset: calc((var(--in) + var(--hold) + var(--out) + var(--gap)) * var(--index));
          --hold-for-final: calc(
            ((var(--in) + var(--hold) + var(--out) + var(--gap))
            * var(--total-items)) - var(--out)
          );

          /* @keyframes duration | easing-function | delay | iteration-count | direction | fill-mode | play-state | name */
          animation:
            var(--in) ease-out var(--offset) 1 forwards wf-in,
            var(--out) ease-in calc(var(--out-delay) + var(--offset)) 1 forwards wf-out,
            var(--final) linear var(--hold-for-final) 1 forwards wf-final;

          &:last-child {
            animation:
            var(--in) ease-out var(--offset) 1 forwards wf-in,
            var(--final) linear var(--hold-for-final) 1 forwards wf-final;
          }

          @keyframes wf-in {
            from {
              opacity: .4;
            }
            to {
              opacity: 1;
            }
          }

          // @keyframes wf-hold {
          //   from {
          //     opacity: 1;
          //   }
          //   to {
          //     opacity: 1;
          //   }
          // }

          @keyframes wf-out {
            from {
              opacity: 1;
            }
            to {
              opacity: .4;
            }
          }

          @keyframes wf-final {
            to {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

