html,
body {
	max-width: 100%;
	min-height:100vh;
}

html {
 padding: 0;
	margin: 0;
}

body {
	padding: 0;
	margin: 0;

  font-variant-ligatures: none;
}

* {
	box-sizing: border-box;
}
