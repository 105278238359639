// ======= Word Clipper Animation C
.use-text-clipper-c {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    display: inline-flex;
    flex-direction: row;;

    position: relative;

    li {
      margin: 0;
      white-space: nowrap;
      color: var(--color--text-brand-03);
      padding-bottom: .1em; // To accomodate Plus Jakarta descenders falling outside line-height

      &:not(:last-child) {
        position: absolute;
      }

      &:not(:first-child) {
        opacity: 0;
        transform: translateX(-1.5em);
      }

      + li {
        margin-block-start: 0;
      }
    }
  }
}

.animated.in-view {
  @media (prefers-reduced-motion: no-preference) {
    .use-text-clipper-c {
      ul {
        li {
          // Define --index for each child <li>
          &:nth-child(1) { --index: 0;}
          &:nth-child(2) { --index: 1;}
          &:nth-child(3) { --index: 2;}
          &:nth-child(4) { --index: 3;}
          &:nth-child(5) { --index: 4;}
          &:nth-child(6) { --index: 5;}
          &:nth-child(7) { --index: 6;}
          &:nth-child(8) { --index: 7;}
          &:nth-child(9) { --index: 8;}
          &:nth-child(10) { --index: 9;}
          // Add more as needed, or limit number of items on component

          transform: translateX(-1.5em);
          opacity: 0;
          top: 0;
          left: 0;
          width: 100%;


          --in: .55s; // Duration of animate in
          --hold: 2.5s; // Duration of item being shown
          --out: .55s; // Duration of animate out
          --gap: 0s; // Delay between items' sequences

          // Animation calculations
          --hold-delay: var(--in);
          --out-delay: calc(var(--in) + var(--hold));
          --offset: calc( (var(--in) + var(--hold) + var(--out) + var(--gap)) * var(--index));

          /* @keyframes duration | easing-function | delay | iteration-count | direction | fill-mode | play-state | name */
          animation:
            var(--in) ease-out var(--offset) 1 forwards c-in,
            var(--hold) linear calc(var(--hold-delay) + var(--offset)) 1 forwards c-hold,
            var(--out) ease-in calc(var(--out-delay) + var(--offset)) 1 forwards c-out;


          &:first-child {
            opacity: 1;
            transform: translateX(0);
            animation:
              var(--hold) linear calc(var(--hold-delay) + var(--offset)) 1 forwards c-hold,
              var(--out) ease-in calc(var(--out-delay) + var(--offset)) 1 forwards c-out;
          }

          &:last-child {
            animation:
              var(--in) ease-out var(--offset) 1 forwards c-in,
              var(--hold) linear calc(var(--hold-delay) + var(--offset)) 1 forwards c-hold;
          }

          @keyframes c-in {
            0% {
              transform: translateX(-1.5em);
              opacity: 0;
            }
            100% {
              transform: translateX(0);
              opacity: 1;
            }
          }

          @keyframes c-hold {
            0%, 100% {
              transform: translateX(0);
              opacity: 1;
            }
          }

          @keyframes c-out {
            0% {
              transform: translateX(0);
              opacity: 1;
            }
            100% {
              transform: translateX(1.5em);
              opacity: 0;
            }
          }
        }
      }
    }
  }
}
