.PCOM {
  .select-container {
    position: relative;
    display: inline-block;

    select {
      width: fit-content;
      height: 48px;
      border-radius: 4px;
      border: 1px solid var(--ui-border-03, #919191);
      background: var(--ui-field, #fff);
      color: var(--color-text-secondary, #333);
      font-size: 1rem;
      font-weight: 400;
      padding: 10px 30px 10px 12px;
      outline: none;
      appearance: none;

      &:focus {
        border: 3px solid var(--interactive-focus-field, #2192fb);
      }

      &:hover {
        border: 1px solid var(--ui-border-02, #151515);
      }
    }

    .down-arrow {
      position: absolute;
      color: #151515;
      width: 16px;
      height: 16px;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }
}
