.PCOM {
  .order-help {
    margin: 90px 0 30px 0;
    padding: 0;
    text-align: left;

    display: flex;
    flex-direction: row;
    gap: 60px;

    div {
      margin: 0;
      padding: 8px 0;
      flex-basis: 33%;
      flex-grow: 1;
      flex-shrink: 1;

      &.divider {
        padding: 0;
        flex-basis: 1px;
        flex-grow: 0;
        flex-shrink: 0;
        background-color: #DFE1E1;
      }
    }

    h2 {
      margin: 0 0 9px;
      padding: 0 0 7px;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 21.6px */
      color: var(--text-text-primary, #151515);
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */

      strong {
        font-weight: 600;
      }
    }

  }

}
