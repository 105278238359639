.pmc-alert {
  display: none;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0
}

.pmc-alert--banner {
  background-color: #d4eae4
}

.pmc-alert.is-visible,
.cq-wcm-edit .pmc-alert {
  display: block
}

.pmc-alert * {
  box-sizing: border-box
}

.pmc-alert__icon {
  display: inline-block;
  flex: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
  margin: 4px 10px 0 0
}

.pmc-alert__icon--default {
  display: none
}

.pmc-alert__icon--error {
  background-image: url(global-message/assets/icons/icon--alert.svg)
}

.pmc-alert__icon--alert {
  background-image: url(global-message/assets/icons/icon--exclamation.svg)
}

.pmc-alert__icon--info {
  background-image: url(global-message/assets/icons/icon--info.svg)
}

.pmc-alert__icon--success {
  background-image: url(global-message/assets/icons/icon--checkmark.svg)
}

.pmc-alert__inner {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 1200px;
  padding: 15px;
  margin: auto
}

.pmc-alert__content {
  position: relative;
  flex: 1 1 auto
}

.pmc-alert__content>* {
  word-break: break-word;
  padding: 0;
  margin: 0
}

.pmc-alert__content>*+* {
  margin-top: 20px
}

.pmc-alert__dismiss.pmc-alert__dismiss {
  display: inline-block;
  flex: none;
  width: 18px;
  height: 18px;
  -webkit-appearance: none;
  border: 0;
  background-color: transparent;
  background-image: url("global-message/assets/icons/icon--close.svg");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  padding: 0;
  margin: 4px 0 0 auto
}

@media(min-width:481px) {
  .pmc-alert__dismiss.pmc-alert__dismiss {
    margin-left: 60px
  }
}

@media(max-width:480px) {
  .pmc-alert__dismiss.pmc-alert__dismiss {
    margin-left: 40px
  }
}
