.bg937267452 {
  background-image: url('2400x800-dark-GettyImages-1209946552.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767px) {
  .bg937267452 {
    background-image: url("800x970-GettyImages-1209946552.jpg");
  }
}
