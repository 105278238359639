.use-button-effect-flood {
  &.flood-primary-gradient {
    a {

      // overflow: visible !important;
      transition: color .2s ease-in !important;
      white-space: nowrap !important;

      &::before {
        background-color: #512EAB !important;
      }
      &::after {
        width: 20px;
        top: 50%;
        left: -20px;
        transform: translateY(-50%) scale(1);
        border-radius: 50%;
        transition: .7s cubic-bezier(.11,.31,.29,1);
      }

      &:hover {
        &::after {
          left: 0;
          width: calc(100% + 2px);
          transform: translateY(-50%) scale(1);
        }
      }
    }

    &.use-button-primary {
      a {
        &::after {
          background: linear-gradient(90.00deg, #0d004dff 0%, #693CDE 100%);
          border: 1px solid rgba(193, 191, 255, 1.0);
        }

        &:hover {
          &::before {
            background-color: var(--color--interactive-primary) !important;
          }
        }
      }
    }

    &.use-button-primary-inverse {
      a {
        &::after {
          background: linear-gradient(90.00deg, #0d004dff 0%, #693CDE 100%);
          // border: 1px solid rgba(193, 191, 255, 0.20);
          border: rgba(193, 191, 255, 0.2);


        }

        &:hover {
          &::before {
            background-color: var(--color--interactive-primary-inverse) !important;
          }
        }
      }
    }
  }
}
