// This file combines all ganked CSS files, plus provides overrides

// variables need to be outside of namespacing
// Unless they're scoped properly
@import './_ganked-css/pcp-hed.scss';
@import './_ganked-css/nav-footer-variables.scss';


.PCOM-GANKED {
  // background-color: #F00;

  // Page-specific SCSS
  @import './_ganked-css/home.scss';
  @import './_ganked-css/college.scss';
  @import './_ganked-css/student.scss';
  @import './_ganked-css/educators.scss';


  // All Other Gankged Styles
  @import "./_ganked-css/page-properties-dialog.min.scss";
  @import "./_ganked-css/bootstrap-4-3-1.scss";
  @import "./_ganked-css/font-awesome-4.7.0/scss/font-awesome.scss"; // Using 4.7 instead of 4.2
  @import "./_ganked-css/parsley-2-8.min.scss";
  @import "./_ganked-css/static-collection-a.min.scss";
  @import "./_ganked-css/video-common.min.scss";
  @import "./_ganked-css/column-control.min.scss";
  @import "./_ganked-css/column-nav.min.scss";
  @import "./_ganked-css/youtube-video.min.scss";
  @import "./_ganked-css/modal-window-pop-up.min.scss";
  @import "./_ganked-css/events-calendar.min.scss";
  @import "./_ganked-css/static-collection.min.scss";
  @import "./_ganked-css/highered-reimagined.min.scss";
  @import "./_ganked-css/global-message.min.scss";
  @import "./_ganked-css/column-control-collapse.min.scss";
  @import "./_ganked-css/nav-footer.min.scss";
  @import "./_ganked-css/nav-aem-header.min.scss";
  @import "./_ganked-css/he-marketing-block.min.scss";
  @import "./_ganked-css/rte-styles-for-ordered-list.min.scss";
  @import "./_ganked-css/accessible-slick.min.scss";
  @import "./_ganked-css/social-links.min.css";

  // Temporary style LINKs that need to be prevented from working

  // a,
  // a[class] {
  //   background-color: red !important;
  //   box-shadow: 0 0 0 5px red !important;
  // }

  // a.stopIt,
  // a.stopIt[class] {
  //   background-color: green !important;
  //   box-shadow: 0 0 0 5px green !important;
  // }
}
