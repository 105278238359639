/**font- Hind*/
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@400;600&display=swap');

/*font-TT Commons Bold*/
@font-face {
    font-family: 'TT Commons Bold';
    font-style: normal;
    src: url('https://fonts.cdnfonts.com/s/51357/TT Commons Bold.woff') format('woff');
}
/**font- inter*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.SMS ul {
  padding-inline-start: 0;
  margin: 0;
}

.SMS p,
.SMS span,
.SMS input,
.SMS button {
  font-family: "Open Sans", sans-serif;
}

.SMS h1,
.SMS h2,
.SMS h3,
.SMS h4,
.SMS h5,
.SMS h6,
.SMS p {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  padding: 0;
}

.SMS a {
	color: #006F8F;
}
