.PCOM {
  .sign-in-page {
    display: flex;
    justify-content: center;
    align-items: center;

    .left-column {
      width: 50%;
      min-height: 100vh;
      background-image: url("../../assets/image/pdp/sigin-in-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
    }

    .right-column {
      width: 50%;
      padding: 6.25rem 2.5rem;
      text-align: left;
      h1 {
        color: var(--color-text-primary, #151515);
        font-size: 1.75rem;
        font-weight: 400;
        margin-bottom: 20px;
      }
      p {
        text-align: left;
        color: var(--color-text-secondary, #333);
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 36px;
        span {
          color: #007a9c;
          font-weight: 600;
        }
      }

      form {
        display: flex;
        flex-direction: column;
        max-width: 30rem;
        div {
          margin-bottom: 15px;

          label {
            color: var(--color-text-secondary, #333);
            font-size: 0.875rem;
            font-weight: 600;
          }

          input {
            background: #fff;
            border: 1px solid #919191;
            border-radius: 4px;
            color: var(--color-text-primary, #151515);
            font-size: 16px;
            font-weight: 400;
            height: 48px;
            line-height: 0;
            margin: 0;
            outline: none;
            padding: 0 13px;
            width: 100%;
            margin-top: 10px;
            &:hover {
              border: 1px solid #151515;
            }
          }
        }
        p.consent {
          color: var(--color-text-secondary, #333);
          font-size: 0.875rem;
          margin-top: 14px;
        }
      }
    }
  }
}
