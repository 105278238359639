 .bg-1629177016 {
    background-image: url('2400x800-GB-5231.4.4.5.3-2.jpg');
    // background-size: ;
    background-position: top;
    background-repeat: repeat;
}
@media screen and (max-width: 767px) {
  .bg-1629177016 {
    background-image: url("800x970-GB-5231.4.4.5.3.jpg");
  }
}
