.PCOM {
  .radio-container {
    display: flex;
    flex-direction: column;
    padding: 12px 20px;

    label {
      font-size: 1rem;
      color: #333;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &:hover {
        .radio-icon-unchecked {
          box-shadow: 0 0 0 8px #e6e6e6;
        }
        .radio-icon-checked {
          box-shadow: 0 0 0 8px #e6e6e6;
        }
      }
      &:active,
      &:focus {
        .radio-icon-unchecked {
          color: #151515;
          outline-offset: 5px;
          outline: 3px solid #2192fb;
          box-shadow: none;
        }
        .radio-icon-checked {
          outline-offset: 5px;
          outline: 3px solid #2192fb;
          box-shadow: none;
        }
      }

      .label-title {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      }

      .price {
        display: flex;
        flex-direction: row;

        p {
          margin: 0;
          display: flex;
          flex-direction: row;
          align-items: baseline;
          color: var(--color-text-secondary, #333);
          font-size: 1.125rem;
          font-weight: 600;
        }

        span {
          font-size: 0.875rem;
          color: var(--text-text-tertiary, #505759);
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
    }

    .isbn {
      text-align: left;
      text-indent: 33px;
      span {
        color: var(--color-text-tertiary, #505759);
        font-size: 0.75rem;
      }
    }
  }

  .radio-icon-checked {
    // color: var(--color--interactive-primary);
    color: var(--color--reskin--fuchsia);
    width: 22px;
    height: 22px;
    margin-right: 13px;
    vertical-align: middle;
    border-radius: 100%;
  }
  .radio-icon-unchecked {
    color: #919191;
    width: 22px;
    height: 22px;
    margin-right: 13px;
    vertical-align: middle;
    border-radius: 100%;
  }
  .input-checked {
    background: var(--tints-tint-03-accent-01-medium, rgba(158, 0, 126, 0.03));
  }
}
