html[class*="aem-AuthorLayer-"] .modal-window .pop-up {
  top: 16.25%
}

.modal-window .pop-up {
  position: fixed;
  top: 16.25vh;
  height: auto;
  max-height: 85%;
  max-width: 570px;
  padding: 60px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

@media(max-width:599px) {
  .modal-window .pop-up {
    max-width: 90%
  }
}

@media(min-width:600px) {
  .modal-window .pop-up {
    max-width: 570px
  }
}

.modal-window .pop-up .pop-up-close {
  position: absolute;
  top: 0;
  border-radius: 0
}

.modal-window .pop-up__content.isCentered {
  text-align: center
}

.modal-window .pop-up__content label {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.625;
  margin: 0
}

@media(max-width:961px) {
  .modal-window .pop-up__content label {
    margin-top: 20px
  }
}

@media(min-width:962px) {
  .modal-window .pop-up__content label {
    margin-top: 30px
  }
}

.modal-window .pop-up__title {
  font-size: 4rem;
  font-weight: 300;
  color: #005a70;
  margin: 0
}

.modal-window .pop-up__button {
  display: inline-block;
  font-weight: 600;
  background-color: #007fa3;
  color: #fff;
  border-radius: 4px;
  border: 2px solid transparent;
  width: auto;
  max-width: none;
  padding: 10px 1.66em
}

@media(max-width:479px) {
  .modal-window .pop-up__button {
    margin: 15px 0 0 0
  }
}

@media(min-width:480px) {
  .modal-window .pop-up__button {
    margin: 0 0 0 15px
  }
}

.modal-window .pop-up__note {
  font-size: 1.3rem;
  margin: 0
}

.modal-window .pop-up__link {
  font-size: 16px;
  color: #007a9c;
  display: inline-block;
  margin-top: 20px;
  text-decoration: underline
}

.modal-window .pop-up__content label {
  margin-top: 20px;
  margin-bottom: 10px
}

.modal-window__control-wrap {
  display: flex;
  justify-content: center;
  margin: 45px 0 30px 0
}

@media(max-width:479px) {
  .modal-window__control-wrap {
    flex-direction: column
  }
}

@media(min-width:480px) {
  .modal-window__control-wrap {
    flex-direction: row
  }
}

.modal-window__bg-mask {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 254
}

.modal-window .select-wrapper {
  display: inline-block;
  width: 100%;
  position: relative;
  overflow: hidden
}

.modal-window .select-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  pointer-events: none;
  height: 48px;
  width: 48px;
  background-image: url('modal-window-pop-up/icons/ic_chevron_white.svg');
  background-color: #007fa3;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: center;
  border-radius: .25em .25em 0 0;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg)
}

.modal-window .select-wrapper select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: .25em;
  box-shadow: none;
  height: 48px;
  width: 100%;
  padding: 6px 70px 6px 12px
}

.weThinkYouWillEnjoy.modal-window .pop-up__button {
  width: 70%;
  margin-top: 1em
}

@media all and (max-width:767px) {
  .weThinkYouWillEnjoy.modal-window .pop-up__button {
    width: 100%
  }
}
