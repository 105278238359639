.PCOM {
  .bundle-pack-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    font-family: var(--typefamily--primary-stack);

    .bundle-pack {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .bundle-img-text {
        display: flex;
        flex-direction: row;
        text-align: left;
        gap: 30px;
        align-items: flex-start;
        img {
          border-radius: 4px;
          max-width: 125px;
          height: auto;
        }
      }

      .bundle-text,
      .bundle-price {
        h4 {
          color: var(--text-text-primary, #151515);
          font-size: 1rem;
          font-weight: 600;
          padding-bottom: 15px;
        }
        p {
          color: var(--color-text-secondary, #333);
          font-size: 0.875rem;
          font-weight: 400;
        }
      }
      .bundle-price {
        text-align: right;
        min-width: 190px;
      }
    }
  }
  .subscription {
    text-align: left;
    font-size: 1rem;
    .header {
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      vertical-align: middle;
      cursor: pointer;
    }

    .arrow {
      color: #151515;
      align-self: center;
    }
    h6 {
      color: var(--color-text-primary, #151515);
      font-size: 1rem;
      font-weight: 600;
    }
    p {
      color: var(--color-text-secondary, #333);
      font-weight: 400;
      line-height: 1.6;
      b {
        font-weight: 600;
      }
      span {
        color: var(--color-text-link, #007a9c);
        font-weight: 600;
      }
    }
  }
}
