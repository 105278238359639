.PCOM {
  form.input-container {
    position: relative;
    align-self: center;

    .autocomplete-container {
      display: none;
    }

    &:focus-within {
      .autocomplete-container {
        display: block;
      }

      input.search-input {
        // border-bottom-left-radius: 0;
        // border-bottom-right-radius: 0;
      }
    }

    input.search-input {
      display: block;
      font-family: var(--typefamily--primary-stack);
      width: 100%;
      height: 40px;
      padding: 0.5rem 1.5rem;
      background-color: rgba(254, 254, 254, 0.35);
      box-shadow: unset;
      border: 1px solid #dfe1e1;
      border-radius: 100px;
      outline-width: 0;
      outline-style: solid;
      outline-offset: -1px;

      &:hover {
        background-color: #a19d9d;
      }
      &:active,
      &:focus {
        background-color: #fefefe;
        color: #151515;
        outline-width: 3px;
        outline-color: #4fa8ff;
        outline-offset: -2px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    ::placeholder {
      color: #fefefe !important;
    }

    .search-icon {
      height: 18px;
      right: 12px;
      position: absolute;
      top: 0;
      padding-top: 0.5rem;
      background: transparent;
      border: none;

      svg {
        fill: white;
      }

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  // For sticky nav state
  // .is-stuck {
  //   form.input-container {
  //     input.search-input {
  //       border: 1px solid var(--color--reskin--pearson-purple);
  //     }
  //     ::placeholder {
  //       color: var(--color--reskin--amethyst) !important;
  //     }

  //     .search-icon {
  //       svg {
  //         fill: var(--color--reskin--pearson-purple);
  //       }
  //     }
  //   }
  // }

}
