.PCOM {
  .book-tab-container {
      padding: 50px 0;
      max-width: 1080px;
      margin: 0 auto;

      .tab-list {
          display: flex;
          flex-direction: row;

          .tab {
              width: fit-content;
              height: 36px;
              margin-right: 20px;
              border-radius: 10px 10px 0px 0px;
              color: var(--color-text-secondary, #333);
              text-align: center;
              font-size: 1.25rem;
              cursor: pointer;
              span {
                  font-family: var(--typefamily--primary-stack);
                  color: var(--color-text-secondary, #333);
                  text-align: center;
                  font-size: 1.25rem;
                  font-weight: 400;
              }
          }
          .react-tabs__tab--selected {
              border-bottom: 4px solid var(--color-text-primary, #151515);
              outline: none;
              span {
                  color: var(--color-text-primary, #151515);
                  font-weight: 600;
              }
          }
      }

      .tab-content {
          color: var(--color-text-secondary, #333);
          font-family: "Open Sans", sans-serif;
          font-size: 1rem;
          font-weight: 400;
          text-align: left;
          line-height: 1.6;
          margin-top: 30px;
          span {
              font-weight: 600;
              padding-right: 4px;
          }
      }
  }
}

