.use-button-effect-flood {
  &.flood-left-to-right {
    a {
      transition: color .2s ease-in !important;

      &::after {
        width: 10px;
        top: 50%;
        left: -12px;
        transform: translateY(-50%) scale(1);
        transition: .8s cubic-bezier(.34,.59,.67,1);
      }

      &:hover {
        &::after {
          width: 205%;
          left: 0;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
  }
}
