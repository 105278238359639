/**main content */
.PCOM {
  div.background-wrapper {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0px auto;
    padding: 1.5rem 2rem;
    // &::before {
    //   content: "";
    //   position: absolute;
    //   left: 0px;
    //   top: 0px;
    //   z-index: -1;
    //   width: 100%;
    //   height: 50rem;
    //   background-color: var(--color--ui-background-dark);
    //   clip-path: ellipse(117.5% 60% at 100% 15%);
    // }
     &::before {
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: -1;
      width: 100%;
      // height: 50rem;
      // background: linear-gradient(0deg, #FFF 0%, #C1BFFF 25%, #512EAB 57%, #0D004D 86.5%, #05001C 100%);
      // background: linear-gradient(0deg, #FFFFFFFF 0%, #FFFFFF00), #F00;
      background: linear-gradient(0deg, #FFFFFFFF 0%, #FFFFFF00 50%),url('_pdp-background-dark-flipped.svg');
      // background-origin: border-box;
      background-size: cover;
      height: 65rem;
    }
  }

  div.main {
    max-width: 1440px;
    padding: 20px 0.5rem;
    margin: 0 auto;
    display: flex;
    flex-direction: row;

    section {
      padding: 20px 0;
      position: relative;

      h1,
      p {
        font-family: var(--typefamily--primary-stack);
        color: var(--color--text-inverse-text-primary, #fefefe);
        margin-top: 8px;
      }

      img {
        width: 100%;
        height: auto;
        border-radius: 8px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
      }
    }
  }

  section.book-cover {
    width: 30%;
    margin-right: 2.5rem;
  }
  section.book-info-container {
    width: 70%;

    .book-info {
      padding: 30px 0;

      h1.title {
        font-size: 2rem;
        font-weight: 400;
        text-align: left;
      }
      p {
        font-size: 1rem;
        text-align: left;
      }
    }
  }

  section .need-help {
    margin: 0 0 50px;
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 25.6px */

    border-top: 1px solid #DFE1E1;
    border-bottom: 1px solid #DFE1E1;

    .fake-link {
      text-decoration: underline;
    }
  }
}
