.PCOM {
  .toggle-switch-container {
    display: flex;
    align-items: center;
    height: 56px;
    width: 410px;
    border-radius: 40px;
    justify-content: space-between;
    background-color: #505759;
    position: relative;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 210px;
    height: 56px;
    border-radius: 40px;
    background-color: #fafafa;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--typefamily--primary-stack);
    color: #151515;
    font-size: 1rem;
    font-weight: 600;
  }

  .text-left,
  .text-right {
    color: #e6e6e6;
    text-align: center;
    font-family: var(--typefamily--primary-stack);
    font-size: 1rem;
    font-weight: 600;
    width: 50%;
  }
}

