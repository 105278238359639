.column-nav {
  width: 100%;
  margin-bottom: 15px
}

@media screen and (min-width:768px) {
  .column-nav {
    margin-bottom: 30px
  }
}

.column-nav-lvl1 {
  list-style: none
}

.column-nav-lvl1>li {
  margin: 0;
  padding: 0
}

.column-nav-lvl1>li>a {
  display: block;
  text-decoration: none;
  margin: 0;
  padding: 15px 30px 15px 10px;
  border-top: 1px solid #ccc;
  color: #333
}

.column-nav-lvl1>li>a:hover,
.column-nav-lvl1>li>a:focus {
  text-decoration: none;
  color: #000;
  outline: 0
}

@media screen and (min-width:768px) {
  .column-nav-lvl1>li>a {
    padding: 25px 35px 25px 30px
  }
}

.column-nav-lvl2 {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  float: left;
  list-style: none;
  text-align: left;
  background-color: #fff;
  min-width: 100%
}

.column-nav-lvl2>li {
  margin: 0
}

.column-nav-lvl2>li>a {
  display: block;
  text-decoration: none;
  margin: 0;
  padding: 15px 30px 15px 10px;
  border-top: 1px solid #ccc;
  color: #333;
  padding-left: 20px
}

.column-nav-lvl2>li>a:hover,
.column-nav-lvl2>li>a:focus {
  text-decoration: none;
  color: #000;
  outline: 0
}

@media screen and (min-width:768px) {
  .column-nav-lvl2>li>a {
    padding: 25px 35px 25px 30px
  }
}

@media screen and (min-width:768px) {
  .column-nav-lvl2>li>a {
    padding-left: 45px
  }
}

.column-nav-lvl3 {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  float: left;
  list-style: none;
  text-align: left;
  background-color: #fff;
  min-width: 100%
}

.column-nav-lvl3>li {
  margin: 0
}

.column-nav-lvl3>li>a {
  display: block;
  text-decoration: none;
  margin: 0;
  padding: 15px 30px 15px 10px;
  border-top: 1px solid #ccc;
  color: #333;
  padding-left: 30px
}

.column-nav-lvl3>li>a:hover,
.column-nav-lvl3>li>a:focus {
  text-decoration: none;
  color: #000;
  outline: 0
}

@media screen and (min-width:768px) {
  .column-nav-lvl3>li>a {
    padding: 25px 35px 25px 30px
  }
}

@media screen and (min-width:768px) {
  .column-nav-lvl3>li>a {
    padding-left: 60px
  }
}

.column-nav-dropdown {
  position: relative
}

.column-nav-toggle {
  display: inline-block;
  float: right;
  padding: 15px;
  margin-top: -11px;
  margin-right: -30px;
  background: transparent
}

@media screen and (min-width:768px) {
  .column-nav-toggle {
    padding: 10px;
    margin-top: -8px;
    margin-right: -20px
  }
}

.column-nav-highlight {
  background-color: #ccc;
  border-top: 1px solid #fff !important
}

.column-nav .open>a {
  font-weight: bold
}

.column-nav .open>.column-nav-lvl1,
.column-nav .open>.column-nav-lvl2,
.column-nav .open>.column-nav-lvl3 {
  position: static;
  float: none;
  width: auto;
  margin-top: 0;
  display: block
}
