.PCOM {
  header.curved {
    width: 100%;
    padding: 1.25rem 4.6875rem;
    position: relative;
    height: 18.75rem;
    // clip-path: ellipse(117.5% 60% at 0% 15%);
    align-content: center;

    &::before {
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: -1;
      width: 100%;
      height: 11rem;
      background-color: var(--color--ui-background-dark);
    }

    h1.title {
      color: #fefefe;
      font-size: 2.625rem;
      font-weight: 600;
      text-align: left;
      padding-bottom: 5rem;
    }
  }

  main.isbn-search {
    margin: 0 auto;
    max-width: 1440px;
    margin-top: -70px;
    padding: 0 0.5rem 30px 0.5rem;
    // min-height: 100vh;
    background: var(--ui-elements-background-light, #fff);

    .select-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
      margin-bottom: 30px;

      .grid-icon {
        width: 24px;
        height: 24px;
        &.active {
          color: var(--color--text-link);
        }
      }
    }

    .main-wrapper {
      display: flex;
      flex-direction: row;
      gap: 30px;
    }

    section.left-column {
      width: 20%;
      text-align: left;
      display: flex;
      flex-direction: column;
      gap: 25px;
    }

    section.right-column {
      width: 80%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1.25rem;

      .book {
        border-radius: 8px;
        padding: 1.5625rem 1.25rem;
        cursor: pointer;
        max-height: 450px;

        &:hover {
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
          transition: all 0.25s ease-in-out;
          -webkit-transition: all 0.25s ease-in-out;
          -moz-transition: all 0.25s ease-in-out;
          -ms-transition: all 0.25s ease-in-out;
          -o-transition: all 0.25s ease-in-out;
        }
        img {
          width: auto;
          height: 200px;
          aspect-ratio: 4/5;
          border-radius: 10px;
          object-fit: cover;
          box-shadow: 0 1.66071px 8.30357px rgba(28, 34, 46, 0.2);
          margin-bottom: 20px;
        }
        .book-info {
          text-align: left;
          display: flex;
          flex-direction: column;
          gap: 10px;

          h3 {
            font-size: 1.125rem;
            color: var(--color--text-link);
            font-weight: 600;
            line-clamp: 2;
          }
          .edition-author {
            margin: 10px 0 20px 0;
          }
          p {
            width: 100%;
            font-size: 0.875rem;
            color: var(--color-text-primary, #151515);
            font-weight: 400;

            &p.author {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }

          p.price {
            font-size: 0.875rem;
            color: var(--color-text-primary, #151515);
            font-weight: 600;
          }
        }
      }
    }
  }
}
