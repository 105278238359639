/**design token*/
@import url("/node_modules/@pearson-ux/master-tokens/dist/p-com/css/tokens.css");

/**font*/
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

.PCOM {
  /*margin: 64px 0 0; */
  padding: 0;
  text-align: center;
  font-family: var(--typefamily--primary-stack);
  font-style: normal;
}

.PCOM ul {
  padding-inline-start: 0;
  margin: 0;
}

.PCOM ul li {
    list-style-type: none;
}

.PCOM p,
.PCOM span,
.PCOM input,
.PCOM button {
  font-family: var(--typefamily--primary-stack);
}

.PCOM h1,
.PCOM h2,
.PCOM h3,
.PCOM h4,
.PCOM h5,
.PCOM h6,
.PCOM p {
  font-family: var(--typefamily--primary-stack);
  margin: 0;
  padding: 0;
}

.PCOM a {
  text-decoration: none;
  cursor:pointer;
  font-size:1.3rem;
  -webkit-tap-highlight-color: transparent;
}

.PCOM button {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
