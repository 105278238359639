.container {
  --grid-margin-sm: 30px;
  --grid-margin-md: 55px;
  --grid-margin-lg: 75px;
  max-width: 100%
}

@media(max-width:768px) {
  .container {
    padding-inline-end: var(--grid-margin-sm);
    padding-inline-start: var(--grid-margin-sm)
  }
}

@media(min-width:769px) and (max-width:1589px) {
  .container {
    padding-inline-end: var(--grid-margin-md);
    padding-inline-start: var(--grid-margin-md)
  }
}

@media(min-width:1590px) {
  .container {
    padding-inline-end: var(--grid-margin-lg);
    padding-inline-start: var(--grid-margin-lg);
    max-width: 1590px
  }
}

@media(max-width:767px) {
  main .row {
    row-gap: var(--space-small)
  }
}

@media(min-width:768px) {
  main .row {
    row-gap: var(--space-medium)
  }
}

@media(max-width:767px) {
  main .col-12:empty {
    display: none
  }
}

.corners-semiround-sm {
  border-radius: var(--corners-semiround-sm)
}

.corners-semiround-md {
  border-radius: var(--corners-semiround-md)
}

.corners-semiround-pill {
  border-radius: var(--corners-semiround-pill)
}

.icon-right::after {
  margin-inline-start: var(--space-xxsmall)
}

.icon-left {
  padding-inline-start: 2.875em !important
}

.icon-left::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 1.25em
}

.icon-left::after {
  margin-inline-end: var(--space-xxsmall)
}

[class*="button-"][href*=".pdf"],
[class*="button-"][href$=".txt"],
[class*="button-"] a[href$=".pdf"],
[class*="button-"] a[href$=".txt"] {
  padding-inline-start: 2.875em !important
}

[class*="button-"][href*=".pdf"]::after,
[class*="button-"][href$=".txt"]::after,
[class*="button-"] a[href$=".pdf"]::after,
[class*="button-"] a[href$=".txt"]::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 1.25em
}

[class*="button-"][href*=".pdf"]::after,
[class*="button-"][href$=".txt"]::after,
[class*="button-"] a[href$=".pdf"]::after,
[class*="button-"] a[href$=".txt"]::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-file-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-file-sm");
  transform: none;
  margin-right: .25em
}

[class*="button-"][href$=".doc"],
[class*="button-"][href$=".docx"],
[class*="button-"][href$=".ppt"],
[class*="button-"][href$=".pptx"],
[class*="button-"][href$=".docx"],
[class*="button-"][href$=".xls"],
[class*="button-"][href$=".xlsx"],
[class*="button-"][href$=".zip"],
[class*="button-"] a[href$=".doc"],
[class*="button-"] a[href$=".docx"],
[class*="button-"] a[href$=".ppt"],
[class*="button-"] a[href$=".pptx"],
[class*="button-"] a[href$=".docx"],
[class*="button-"] a[href$=".xls"],
[class*="button-"] a[href$=".xlsx"],
[class*="button-"] a[href$=".zip"] {
  padding-inline-start: 2.875em !important
}

[class*="button-"][href$=".doc"]::after,
[class*="button-"][href$=".docx"]::after,
[class*="button-"][href$=".ppt"]::after,
[class*="button-"][href$=".pptx"]::after,
[class*="button-"][href$=".docx"]::after,
[class*="button-"][href$=".xls"]::after,
[class*="button-"][href$=".xlsx"]::after,
[class*="button-"][href$=".zip"]::after,
[class*="button-"] a[href$=".doc"]::after,
[class*="button-"] a[href$=".docx"]::after,
[class*="button-"] a[href$=".ppt"]::after,
[class*="button-"] a[href$=".pptx"]::after,
[class*="button-"] a[href$=".docx"]::after,
[class*="button-"] a[href$=".xls"]::after,
[class*="button-"] a[href$=".xlsx"]::after,
[class*="button-"] a[href$=".zip"]::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 1.25em
}

[class*="button-"][href$=".doc"]::after,
[class*="button-"][href$=".docx"]::after,
[class*="button-"][href$=".ppt"]::after,
[class*="button-"][href$=".pptx"]::after,
[class*="button-"][href$=".docx"]::after,
[class*="button-"][href$=".xls"]::after,
[class*="button-"][href$=".xlsx"]::after,
[class*="button-"][href$=".zip"]::after,
[class*="button-"] a[href$=".doc"]::after,
[class*="button-"] a[href$=".docx"]::after,
[class*="button-"] a[href$=".ppt"]::after,
[class*="button-"] a[href$=".pptx"]::after,
[class*="button-"] a[href$=".docx"]::after,
[class*="button-"] a[href$=".xls"]::after,
[class*="button-"] a[href$=".xlsx"]::after,
[class*="button-"] a[href$=".zip"]::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-download-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-download-sm");
  transform: translateY(0.15em);
  margin-right: .25em
}

.button-primary {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1
}

@media(min-width:376px) {
  .button-primary {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-primary {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-primary::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-primary:hover,
.button-primary:focus,
.button-primary:focus-visible,
.button-primary:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-primary:hover::before,
.button-primary:focus::before,
.button-primary:focus-visible::before,
.button-primary:active::before {
  transform: scale(1.05)
}

.button-primary:focus,
.button-primary:focus-visible {
  outline: 0
}

.button-primary:focus::before,
.button-primary:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-primary:active::before {
  outline-color: transparent
}

.button-primary:only-of-type {
  margin: 0
}

.button-primary.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-primary::before {
  background: var(--color--interactive-primary) border-box
}

.button-primary:hover::before {
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-primary-hover) border-box
}

.button-primary:focus,
.button-primary:focus-visible {
  color: var(--color--text-primary-inverse)
}

.button-primary:focus::before,
.button-primary:focus-visible::before {
  background: var(--color--interactive-primary) border-box
}

.button-primary:active {
  color: var(--color--text-primary-inverse)
}

.button-primary:active::before {
  background: var(--color--interactive-primary-active) border-box
}

.button-primary[disabled],
.button-primary.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.button-primary[disabled]::before,
.button-primary.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.button-primary[disabled]:hover::before,
.button-primary.button-disabled:hover::before,
.button-primary[disabled]:focus::before,
.button-primary.button-disabled:focus::before,
.button-primary[disabled]:focus-visible::before,
.button-primary.button-disabled:focus-visible::before,
.button-primary[disabled]:active::before,
.button-primary.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.button-primary-inverse {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary-inverse)
}

@media(min-width:376px) {
  .button-primary-inverse {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-primary-inverse {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-primary-inverse::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-primary-inverse:hover,
.button-primary-inverse:focus,
.button-primary-inverse:focus-visible,
.button-primary-inverse:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-primary-inverse:hover::before,
.button-primary-inverse:focus::before,
.button-primary-inverse:focus-visible::before,
.button-primary-inverse:active::before {
  transform: scale(1.05)
}

.button-primary-inverse:focus,
.button-primary-inverse:focus-visible {
  outline: 0
}

.button-primary-inverse:focus::before,
.button-primary-inverse:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-primary-inverse:active::before {
  outline-color: transparent
}

.button-primary-inverse:only-of-type {
  margin: 0
}

.button-primary-inverse.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-primary-inverse::before {
  background: var(--color--interactive-primary-inverse)
}

.button-primary-inverse:hover {
  color: var(--color--text-primary-inverse)
}

.button-primary-inverse:hover::before {
  background: var(--color--interactive-primary-inverse-hover, var(--color--interactive-primary-hover-inverse))
}

.button-primary-inverse:focus,
.button-primary-inverse:focus-visible {
  color: var(--color--text-primary-inverse)
}

.button-primary-inverse:focus::before,
.button-primary-inverse:focus-visible::before {
  background: var(--color--interactive-primary-inverse)
}

.button-primary-inverse:active {
  color: var(--color--text-primary-inverse)
}

.button-primary-inverse:active::before {
  background: var(--color--interactive-primary-inverse-active, var(--color--interactive-primary-active-inverse))
}

.button-primary-inverse[disabled],
.button-primary-inverse.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02);
  color: var(--color--interactive-disabled-03)
}

.button-primary-inverse[disabled]::before,
.button-primary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.button-primary-inverse[disabled]:hover::before,
.button-primary-inverse.button-disabled:hover::before,
.button-primary-inverse[disabled]:focus::before,
.button-primary-inverse.button-disabled:focus::before,
.button-primary-inverse[disabled]:focus-visible::before,
.button-primary-inverse.button-disabled:focus-visible::before,
.button-primary-inverse[disabled]:active::before,
.button-primary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.button-primary-inverse[disabled]::before,
.button-primary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.button-primary-inverse[disabled]:hover::before,
.button-primary-inverse.button-disabled:hover::before,
.button-primary-inverse[disabled]:focus::before,
.button-primary-inverse.button-disabled:focus::before,
.button-primary-inverse[disabled]:focus-visible::before,
.button-primary-inverse.button-disabled:focus-visible::before,
.button-primary-inverse[disabled]:active::before,
.button-primary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01)
}

.button-secondary {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1
}

@media(min-width:376px) {
  .button-secondary {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-secondary {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-secondary::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-secondary:hover,
.button-secondary:focus,
.button-secondary:focus-visible,
.button-secondary:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-secondary:hover::before,
.button-secondary:focus::before,
.button-secondary:focus-visible::before,
.button-secondary:active::before {
  transform: scale(1.05)
}

.button-secondary:focus,
.button-secondary:focus-visible {
  outline: 0
}

.button-secondary:focus::before,
.button-secondary:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-secondary:active::before {
  outline-color: transparent
}

.button-secondary:only-of-type {
  margin: 0
}

.button-secondary.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-secondary::before {
  background: var(--color--interactive-secondary) border-box
}

.button-secondary:hover {
  color: var(--color--text-primary-inverse)
}

.button-secondary:hover::before {
  background: var(--color--interactive-secondary-hover) border-box
}

.button-secondary:focus,
.button-secondary:focus-visible {
  color: var(--color--text-primary-inverse)
}

.button-secondary:focus::before,
.button-secondary:focus-visible::before {
  background: var(--color--interactive-secondary) border-box
}

.button-secondary:active {
  color: var(--color--text-primary-inverse)
}

.button-secondary:active::before {
  background: var(--color--interactive-secondary-active) border-box
}

.button-secondary[disabled],
.button-secondary.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.button-secondary[disabled]::before,
.button-secondary.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.button-secondary[disabled]:hover::before,
.button-secondary.button-disabled:hover::before,
.button-secondary[disabled]:focus::before,
.button-secondary.button-disabled:focus::before,
.button-secondary[disabled]:focus-visible::before,
.button-secondary.button-disabled:focus-visible::before,
.button-secondary[disabled]:active::before,
.button-secondary.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.button-secondary-inverse {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary)
}

@media(min-width:376px) {
  .button-secondary-inverse {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-secondary-inverse {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-secondary-inverse::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-secondary-inverse:hover,
.button-secondary-inverse:focus,
.button-secondary-inverse:focus-visible,
.button-secondary-inverse:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-secondary-inverse:hover::before,
.button-secondary-inverse:focus::before,
.button-secondary-inverse:focus-visible::before,
.button-secondary-inverse:active::before {
  transform: scale(1.05)
}

.button-secondary-inverse:focus,
.button-secondary-inverse:focus-visible {
  outline: 0
}

.button-secondary-inverse:focus::before,
.button-secondary-inverse:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-secondary-inverse:active::before {
  outline-color: transparent
}

.button-secondary-inverse:only-of-type {
  margin: 0
}

.button-secondary-inverse.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-secondary-inverse::before {
  background: var(--color--interactive-secondary-inverse)
}

.button-secondary-inverse:hover {
  color: var(--color--text-primary)
}

.button-secondary-inverse:hover::before {
  background: var(--color--interactive-secondary-inverse-hover, var(--color--interactive-secondary-hover-inverse))
}

.button-secondary-inverse:focus,
.button-secondary-inverse:focus-visible {
  color: var(--color--text-primary)
}

.button-secondary-inverse:focus::before,
.button-secondary-inverse:focus-visible::before {
  background: var(--color--interactive-secondary-inverse)
}

.button-secondary-inverse:active {
  color: var(--color--text-primary)
}

.button-secondary-inverse:active::before {
  background: var(--color--interactive-secondary-inverse-active, var(--color--interactive-secondary-active-inverse))
}

.button-secondary-inverse[disabled],
.button-secondary-inverse.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02);
  color: var(--color--interactive-disabled-03)
}

.button-secondary-inverse[disabled]::before,
.button-secondary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.button-secondary-inverse[disabled]:hover::before,
.button-secondary-inverse.button-disabled:hover::before,
.button-secondary-inverse[disabled]:focus::before,
.button-secondary-inverse.button-disabled:focus::before,
.button-secondary-inverse[disabled]:focus-visible::before,
.button-secondary-inverse.button-disabled:focus-visible::before,
.button-secondary-inverse[disabled]:active::before,
.button-secondary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.button-secondary-inverse[disabled]::before,
.button-secondary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.button-secondary-inverse[disabled]:hover::before,
.button-secondary-inverse.button-disabled:hover::before,
.button-secondary-inverse[disabled]:focus::before,
.button-secondary-inverse.button-disabled:focus::before,
.button-secondary-inverse[disabled]:focus-visible::before,
.button-secondary-inverse.button-disabled:focus-visible::before,
.button-secondary-inverse[disabled]:active::before,
.button-secondary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01)
}

.button-tertiary {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary);
  transition: color .125s ease-in-out
}

@media(min-width:376px) {
  .button-tertiary {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-tertiary {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-tertiary::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-tertiary:hover,
.button-tertiary:focus,
.button-tertiary:focus-visible,
.button-tertiary:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-tertiary:hover::before,
.button-tertiary:focus::before,
.button-tertiary:focus-visible::before,
.button-tertiary:active::before {
  transform: scale(1.05)
}

.button-tertiary:focus,
.button-tertiary:focus-visible {
  outline: 0
}

.button-tertiary:focus::before,
.button-tertiary:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-tertiary:active::before {
  outline-color: transparent
}

.button-tertiary:only-of-type {
  margin: 0
}

.button-tertiary.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-tertiary::before {
  outline-color: var(--color--interactive-tertiary);
  outline-offset: -2px;
  background-color: transparent
}

.button-tertiary:hover {
  color: var(--color--text-primary-inverse)
}

.button-tertiary:hover::before {
  outline-color: var(--color--interactive-tertiary-hover);
  background: var(--color--interactive-tertiary-hover)
}

.button-tertiary:focus,
.button-tertiary:focus-visible {
  color: var(--color--text-tertiary)
}

.button-tertiary:focus::before,
.button-tertiary:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

.button-tertiary:active {
  color: var(--color--text-primary-inverse)
}

.button-tertiary:active::before {
  background: var(--color--interactive-tertiary-active)
}

.button-tertiary[disabled],
.button-tertiary.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.button-tertiary[disabled]::before,
.button-tertiary.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.button-tertiary[disabled]:hover::before,
.button-tertiary.button-disabled:hover::before,
.button-tertiary[disabled]:focus::before,
.button-tertiary.button-disabled:focus::before,
.button-tertiary[disabled]:focus-visible::before,
.button-tertiary.button-disabled:focus-visible::before,
.button-tertiary[disabled]:active::before,
.button-tertiary.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.button-tertiary[disabled]::before,
.button-tertiary.button-disabled::before {
  outline-color: var(--color--interactive-disabled-01);
  outline-offset: -2px;
  background: transparent
}

.button-tertiary[disabled]:hover::before,
.button-tertiary.button-disabled:hover::before,
.button-tertiary[disabled]:focus::before,
.button-tertiary.button-disabled:focus::before,
.button-tertiary[disabled]:focus-visible::before,
.button-tertiary.button-disabled:focus-visible::before,
.button-tertiary[disabled]:active::before,
.button-tertiary.button-disabled:active::before {
  background: transparent
}

.button-tertiary-inverse {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary-inverse)
}

@media(min-width:376px) {
  .button-tertiary-inverse {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-tertiary-inverse {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-tertiary-inverse::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-tertiary-inverse:hover,
.button-tertiary-inverse:focus,
.button-tertiary-inverse:focus-visible,
.button-tertiary-inverse:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-tertiary-inverse:hover::before,
.button-tertiary-inverse:focus::before,
.button-tertiary-inverse:focus-visible::before,
.button-tertiary-inverse:active::before {
  transform: scale(1.05)
}

.button-tertiary-inverse:focus,
.button-tertiary-inverse:focus-visible {
  outline: 0
}

.button-tertiary-inverse:focus::before,
.button-tertiary-inverse:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-tertiary-inverse:active::before {
  outline-color: transparent
}

.button-tertiary-inverse:only-of-type {
  margin: 0
}

.button-tertiary-inverse.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-tertiary-inverse::before {
  outline-color: var(--color--interactive-tertiary-inverse);
  outline-offset: -2px;
  background: transparent
}

.button-tertiary-inverse:hover {
  color: var(--color--text-primary)
}

.button-tertiary-inverse:hover::before {
  outline-color: transparent;
  background: var(--color--interactive-tertiary-inverse-hover, var(--color--interactive-tertiary-hover-inverse))
}

.button-tertiary-inverse:focus,
.button-tertiary-inverse:focus-visible {
  color: var(--color--text-primary-inverse)
}

.button-tertiary-inverse:focus::before,
.button-tertiary-inverse:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

.button-tertiary-inverse:active {
  color: var(--color--text-primary)
}

.button-tertiary-inverse:active::before {
  background: var(--color--interactive-tertiary-inverse-active, var(--color--interactive-tertiary-active-inverse))
}

.button-tertiary-inverse[disabled],
.button-tertiary-inverse.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.button-tertiary-inverse[disabled]::before,
.button-tertiary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.button-tertiary-inverse[disabled]:hover::before,
.button-tertiary-inverse.button-disabled:hover::before,
.button-tertiary-inverse[disabled]:focus::before,
.button-tertiary-inverse.button-disabled:focus::before,
.button-tertiary-inverse[disabled]:focus-visible::before,
.button-tertiary-inverse.button-disabled:focus-visible::before,
.button-tertiary-inverse[disabled]:active::before,
.button-tertiary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.button-tertiary-inverse[disabled]::before,
.button-tertiary-inverse.button-disabled::before {
  outline-color: var(--color--interactive-disabled-02);
  background: transparent
}

.button-tertiary-inverse[disabled]:hover::before,
.button-tertiary-inverse.button-disabled:hover::before,
.button-tertiary-inverse[disabled]:focus::before,
.button-tertiary-inverse.button-disabled:focus::before,
.button-tertiary-inverse[disabled]:focus-visible::before,
.button-tertiary-inverse.button-disabled:focus-visible::before,
.button-tertiary-inverse[disabled]:active::before,
.button-tertiary-inverse.button-disabled:active::before {
  background: transparent
}

.button-action {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary)
}

@media(min-width:376px) {
  .button-action {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-action {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-action::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-action:hover,
.button-action:focus,
.button-action:focus-visible,
.button-action:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-action:hover::before,
.button-action:focus::before,
.button-action:focus-visible::before,
.button-action:active::before {
  transform: scale(1.05)
}

.button-action:focus,
.button-action:focus-visible {
  outline: 0
}

.button-action:focus::before,
.button-action:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-action:active::before {
  outline-color: transparent
}

.button-action:only-of-type {
  margin: 0
}

.button-action.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-action::before {
  background: var(--color--interactive-action) border-box
}

.button-action:hover::before {
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-action-hover) border-box
}

.button-action:focus,
.button-action:focus-visible {
  color: var(--color--text-primary)
}

.button-action:focus::before,
.button-action:focus-visible::before {
  background: var(--color--interactive-action-focus) border-box
}

.button-action:active {
  color: var(--color--text-primary-inverse)
}

.button-action:active::before {
  background: var(--color--interactive-action-active) border-box
}

.button-action[disabled],
.button-action.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.button-action[disabled]::before,
.button-action.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.button-action[disabled]:hover::before,
.button-action.button-disabled:hover::before,
.button-action[disabled]:focus::before,
.button-action.button-disabled:focus::before,
.button-action[disabled]:focus-visible::before,
.button-action.button-disabled:focus-visible::before,
.button-action[disabled]:active::before,
.button-action.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.button-action[disabled]::before,
.button-action.button-disabled::before {
  outline-offset: -2px;
  outline-color: var(--color--interactive-disabled-01);
  background: transparent
}

.button-action[disabled]:hover::before,
.button-action.button-disabled:hover::before,
.button-action[disabled]:focus::before,
.button-action.button-disabled:focus::before,
.button-action[disabled]:focus-visible::before,
.button-action.button-disabled:focus-visible::before,
.button-action[disabled]:active::before,
.button-action.button-disabled:active::before {
  background: transparent
}

.button-action-inverse {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary)
}

@media(min-width:376px) {
  .button-action-inverse {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-action-inverse {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-action-inverse::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-action-inverse:hover,
.button-action-inverse:focus,
.button-action-inverse:focus-visible,
.button-action-inverse:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-action-inverse:hover::before,
.button-action-inverse:focus::before,
.button-action-inverse:focus-visible::before,
.button-action-inverse:active::before {
  transform: scale(1.05)
}

.button-action-inverse:focus,
.button-action-inverse:focus-visible {
  outline: 0
}

.button-action-inverse:focus::before,
.button-action-inverse:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-action-inverse:active::before {
  outline-color: transparent
}

.button-action-inverse:only-of-type {
  margin: 0
}

.button-action-inverse.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-action-inverse::before {
  background: var(--color--interactive-action-inverse) border-box
}

.button-action-inverse:hover {
  color: var(--color--text-primary)
}

.button-action-inverse:hover::before {
  background: var(--color--interactive-action-inverse-hover, var(--color--interactive-action-hover-inverse)) border-box
}

.button-action-inverse:focus,
.button-action-inverse:focus-visible {
  color: var(--color--text-primary)
}

.button-action-inverse:focus::before,
.button-action-inverse:focus-visible::before {
  background: var(--color--interactive-action-focus) border-box
}

.button-action-inverse:active {
  color: var(--color--text-primary)
}

.button-action-inverse:active::before {
  background: var(--color--interactive-action-inverse-active, var(--color--interactive-action-active-inverse)) border-box
}

.button-action-inverse[disabled],
.button-action-inverse.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.button-action-inverse[disabled]::before,
.button-action-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.button-action-inverse[disabled]:hover::before,
.button-action-inverse.button-disabled:hover::before,
.button-action-inverse[disabled]:focus::before,
.button-action-inverse.button-disabled:focus::before,
.button-action-inverse[disabled]:focus-visible::before,
.button-action-inverse.button-disabled:focus-visible::before,
.button-action-inverse[disabled]:active::before,
.button-action-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.button-action-inverse[disabled]::before,
.button-action-inverse.button-disabled::before {
  outline-offset: -2px;
  outline-color: var(--color--interactive-disabled-02);
  background: transparent
}

.button-action-inverse[disabled]:hover::before,
.button-action-inverse.button-disabled:hover::before,
.button-action-inverse[disabled]:focus::before,
.button-action-inverse.button-disabled:focus::before,
.button-action-inverse[disabled]:focus-visible::before,
.button-action-inverse.button-disabled:focus-visible::before,
.button-action-inverse[disabled]:active::before,
.button-action-inverse.button-disabled:active::before {
  background: transparent
}

.button-small {
  font-size: var(--typesize-minor);
  padding: .25em 1.25em;
  min-height: 2rem
}

.button-small.icon-only {
  aspect-ratio: 1;
  padding: .5em;
  max-width: 2rem
}

.button-large {
  padding: .75em 1.25em;
  min-height: 3rem
}

.button-large.icon-only {
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

.button-block {
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-right: 0
}

.button-unstyled {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0
}

.button-unstyled::before {
  display: none;
  background: transparent;
  border: 0;
  box-shadow: none
}

[class*="button-circular-"] {
  line-height: var(--leading--1);
  padding: 0;
  aspect: 1
}

[class*="button-circular-"]::before {
  border-radius: 50%;
  aspect-ratio: 1
}

.button-circular-large {
  width: 2.5em;
  height: 2.5em
}

.button-circular-medium {
  width: 2.25em;
  height: 2.25em
}

.button-circular-small {
  width: 2em;
  height: 2em
}

.button-interactive a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-p);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  background: var(--color--label-interactive);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-radius: var(--button-corners);
  padding: var(--spacing-10) var(--spacing-15);
  display: inline-block;
  min-height: 1.5rem;
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  .button-interactive a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-interactive a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-interactive a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-interactive a:hover,
.button-interactive a:focus,
.button-interactive a:focus-visible,
.button-interactive a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-interactive a:hover::before,
.button-interactive a:focus::before,
.button-interactive a:focus-visible::before,
.button-interactive a:active::before {
  transform: scale(1.05)
}

.button-interactive a:focus,
.button-interactive a:focus-visible {
  outline: 0
}

.button-interactive a:focus::before,
.button-interactive a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-interactive a:active::before {
  outline-color: transparent
}

.button-interactive a:only-of-type {
  margin: 0
}

.button-interactive a.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-interactive a:hover {
  color: var(--color--text-link-hover)
}

.button-interactive a:focus {
  color: var(--color--text-link)
}

.button-interactive a:focus::before {
  transform: scale(1)
}

.button-interactive a:active {
  color: var(--color--text-link-hover);
  text-decoration: none
}

.button-non-interactive a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-p);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-radius: var(--button-corners);
  padding: var(--spacing-10) var(--spacing-15);
  display: inline-block;
  min-height: 1.5rem;
  padding: 0 var(--spacing-10);
  pointer-events: none;
  color: var(--color--text-tertiary);
  background: var(--color--label-interactive);
  border: 2px solid var(--color--ui-border-03, var(--color--border-03));
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  .button-non-interactive a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-non-interactive a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-non-interactive a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-non-interactive a:hover,
.button-non-interactive a:focus,
.button-non-interactive a:focus-visible,
.button-non-interactive a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-non-interactive a:hover::before,
.button-non-interactive a:focus::before,
.button-non-interactive a:focus-visible::before,
.button-non-interactive a:active::before {
  transform: scale(1.05)
}

.button-non-interactive a:focus,
.button-non-interactive a:focus-visible {
  outline: 0
}

.button-non-interactive a:focus::before,
.button-non-interactive a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-non-interactive a:active::before {
  outline-color: transparent
}

.button-non-interactive a:only-of-type {
  margin: 0
}

.button-non-interactive a.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-non-interactive a:hover {
  color: var(--color--text-link-hover)
}

.button-non-interactive a:focus {
  color: var(--color--text-link)
}

.button-non-interactive a:focus::before {
  transform: scale(1)
}

.button-non-interactive a:active {
  color: var(--color--text-link-hover);
  text-decoration: none
}

.button-non-interactive a a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-p);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  background: var(--color--label-interactive);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-radius: var(--button-corners);
  padding: var(--spacing-10) var(--spacing-15);
  display: inline-block;
  min-height: 1.5rem;
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  .button-non-interactive a a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-non-interactive a a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-non-interactive a a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-non-interactive a a:hover,
.button-non-interactive a a:focus,
.button-non-interactive a a:focus-visible,
.button-non-interactive a a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-non-interactive a a:hover::before,
.button-non-interactive a a:focus::before,
.button-non-interactive a a:focus-visible::before,
.button-non-interactive a a:active::before {
  transform: scale(1.05)
}

.button-non-interactive a a:focus,
.button-non-interactive a a:focus-visible {
  outline: 0
}

.button-non-interactive a a:focus::before,
.button-non-interactive a a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-non-interactive a a:active::before {
  outline-color: transparent
}

.button-non-interactive a a:only-of-type {
  margin: 0
}

.button-non-interactive a a.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-non-interactive a a:hover {
  color: var(--color--text-link-hover)
}

.button-non-interactive a a:focus {
  color: var(--color--text-link)
}

.button-non-interactive a a:focus::before {
  transform: scale(1)
}

.button-non-interactive a a:active {
  color: var(--color--text-link-hover);
  text-decoration: none
}

.button-interactive-tertiary a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-secondary);
  padding: var(--spacing-10) 25px;
  border-radius: var(--button-corners);
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  .button-interactive-tertiary a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .button-interactive-tertiary a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.button-interactive-tertiary a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.button-interactive-tertiary a:hover,
.button-interactive-tertiary a:focus,
.button-interactive-tertiary a:focus-visible,
.button-interactive-tertiary a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.button-interactive-tertiary a:hover::before,
.button-interactive-tertiary a:focus::before,
.button-interactive-tertiary a:focus-visible::before,
.button-interactive-tertiary a:active::before {
  transform: scale(1.05)
}

.button-interactive-tertiary a:focus,
.button-interactive-tertiary a:focus-visible {
  outline: 0
}

.button-interactive-tertiary a:focus::before,
.button-interactive-tertiary a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.button-interactive-tertiary a:active::before {
  outline-color: transparent
}

.button-interactive-tertiary a:only-of-type {
  margin: 0
}

.button-interactive-tertiary a.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.button-interactive-tertiary a::before {
  background: var(--color--label-interactive);
  border: 1px solid rgba(39, 49, 49, 0.15)
}

.button-interactive-tertiary a:hover {
  color: var(--color--text-secondary)
}

.button-interactive-tertiary a:hover::before {
  background: #e6ecef
}

.button-interactive-tertiary a:focus,
.button-interactive-tertiary a:active {
  color: var(--color--text-secondary);
  background: var(--color--label-interactive)
}

header {
  --locale-selector-src-display: none;
  --locale-selector-custom-display: block;
  --locale-selector-dd-display: none;
  --locale-selector-dd-mt: 0;
  --locale-selector-mb: 0;
  --locale-selector-arrow-rotate: 0
}

header .isSticky [class*="global-message--alert-banner"] {
  display: none
}

header [class*="global-message--alert-banner"] {
  --gm-bgcolor: var(--color--ui-background-med, var(--color--background-medium));
  --gm-text-color: var(--color--text-primary);
  --gm-link-color: var(--color--text-link);
  --gm-icon-alert-color: var(--color--alert-error);
  --gm-icon-warning-color: var(--color--alert-warning);
  --gm-icon-success-color: var(--color--alert-success);
  --gm-icon-info-color: var(--color--alert-information);
  --gm-dismiss-bgcolor: transparent;
  --gm-dismiss-bgcolor-hover: var(--color--ui-background-light, var(--color--background-light));
  --gm-dismiss-bgcolor-focus: var(--color--ui-background-light, var(--color--background-light));
  --gm-dismiss-icon-color: var(--color--ui-background-dark, var(--color--background-dark));
  --gm-dismiss-icon-color-hover: currentColor
}

header [class*="global-message--alert-banner"][class*="-alt-white"] {
  --gm-bgcolor: var(--color--ui-background-light, var(--color--background-light));
  --gm-text-color: var(--color--text-secondary);
  --gm-dismiss-bgcolor-hover: var(--color--ui-01);
  --gm-dismiss-bgcolor-focus: var(--color--ui-01)
}

header [class*="global-message--alert-banner"][class*="-alt-dark"] {
  --gm-bgcolor: var(--color--ui-03);
  --gm-text-color: var(--color--text-primary-inverse);
  --gm-link-color: var(--color--text-link-inverse);
  --gm-dismiss-icon-color: var(--color--ui-background-light, var(--color--background-light));
  --gm-dismiss-icon-color-hover: var(--color--ui-background-dark, var(--color--background-dark));
  --gm-icon-alert-color: var(--color--alert-error-inverse);
  --gm-icon-warning-color: var(--color--alert-warning-inverse);
  --gm-icon-success-color: var(--color--alert-success-inverse);
  --gm-icon-info-color: var(--color--alert-information-inverse)
}

header [class*="global-message--alert-banner"][class*="-alt-grey-light"] {
  --gm-bgcolor: var(--color--ui-01);
  --gm-text-color: var(--color--text-secondary)
}

header [class*="global-message--alert-banner"][class*="-alt-grey-dark"] {
  --gm-bgcolor: var(--color--ui-04);
  --gm-text-color: var(--color--text-primary-inverse);
  --gm-link-color: var(--color--text-link-inverse);
  --gm-dismiss-icon-color: var(--color--ui-background-light, var(--color--background-light));
  --gm-dismiss-icon-color-hover: var(--color--ui-background-dark, var(--color--background-dark));
  --gm-icon-alert-color: var(--color--alert-error-inverse);
  --gm-icon-warning-color: var(--color--alert-warning-inverse);
  --gm-icon-success-color: var(--color--alert-success-inverse);
  --gm-icon-info-color: var(--color--alert-information-inverse)
}

header [class*="global-message--alert-banner"][class*="-alt-accent"] {
  --gm-bgcolor: var(--color--accent-01-light);
  --gm-text-color: var(--color--text-secondary)
}

header [class*="global-message--alert-banner"] [class*="alert--banner"] {
  background-color: var(--gm-bgcolor)
}

header [class*="global-message--alert-banner"] [class*="alert--banner"] p {
  font-size: var(--typesize-default);
  line-height: var(--leading-1);
  color: var(--gm-text-color)
}

header [class*="global-message--alert-banner"] [class*="alert--banner"] a {
  color: var(--gm-link-color)
}

header [class*="global-message--alert-banner"] [class*="alert--banner"] a:link,
header [class*="global-message--alert-banner"] [class*="alert--banner"] a:hover,
header [class*="global-message--alert-banner"] [class*="alert--banner"] a:active,
header [class*="global-message--alert-banner"] [class*="alert--banner"] a:focus {
  color: var(--gm-link-color)
}

header [class*="global-message--alert-banner"] [class*="alert--banner"] .pmc-alert__inner {
  padding: var(--space-xsmall) calc(5 * var(--space-unit));
  align-items: center;
  max-width: 100%
}

header [class*="global-message--alert-banner"] [class*="alert--banner"] .pmc-alert__icon {
  margin-top: 0
}

header [class*="global-message--alert-banner"] [class*="alert--banner"] .pmc-alert__dismiss {
  margin: 0
}

@media(min-width:768px) {
  header [class*="global-message--alert-banner"] [class*="alert--banner"] .pmc-alert__inner {
    padding-right: calc(11 * var(--space-unit));
    padding-left: calc(11 * var(--space-unit))
  }
}

@media(min-width:1590px) {
  header [class*="global-message--alert-banner"] [class*="alert--banner"] .pmc-alert__inner {
    padding-right: calc(15 * var(--space-unit));
    padding-left: calc(15 * var(--space-unit));
    max-width: 1590px
  }
}

header [class*="global-message--alert-banner"] [class*="alert__dismiss"],
header [class*="global-message--alert-banner"] [class*="alert__icon"] {
  background-image: none
}

header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: unset;
  background-image: unset;
  background-repeat: unset;
  background-size: unset;
  margin-top: 0;
  margin-right: 0;
  transition: none;
  height: 24px;
  width: 24px;
  background-color: transparent;
  color: var(--gm-dismiss-icon-color)
}

header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:hover,
header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus {
  outline: 0;
  background-color: transparent
}

header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]::before {
  display: block;
  content: "";
  flex: 0 0 auto;
  border-radius: 50%;
  width: inherit;
  height: inherit;
  background-color: var(--color--ui-01);
  position: absolute;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  transform: scale(0);
  transition: transform .125s ease-in-out;
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent
}

header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg");
  transform: none;
  top: unset;
  left: unset;
  border-radius: 0
}

header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:hover::before,
header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus::before,
header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible::before,
header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:active::before {
  transform: scale(1)
}

header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus,
header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible {
  outline: 0
}

header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus::before,
header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible::before {
  outline-color: var(--color--interactive-focus-field)
}

@media(forced-colors:active) and (prefers-color-scheme:dark) {

  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus,
  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible {
    outline-width: 2px;
    outline-style: solid;
    outline-offset: 1px
  }

  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]::after {
    filter: brightness(0) invert(1)
  }

  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:hover::after,
  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus::after,
  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible::after,
  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:active::after {
    filter: unset
  }

  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]::before {
    outline-color: currentColor;
    outline-offset: -2px;
    transform: unset;
    opacity: unset;
    background-color: unset
  }

  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:hover::before,
  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus::before,
  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible::before,
  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:active::before {
    background-color: currentColor;
    filter: brightness(0) invert(1)
  }
}

@media(forced-colors:active) and (prefers-color-scheme:light) {
  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"] {
    border-radius: 50%;
    outline-width: 2px;
    outline-style: solid;
    outline-color: currentColor
  }

  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:hover,
  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus,
  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible,
  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:active {
    outline-width: 2px;
    outline-style: solid;
    outline-color: currentColor
  }

  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]::after {
    filter: brightness(1) invert(1)
  }

  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:hover::after,
  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus::after,
  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible::after,
  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:active::after {
    filter: unset
  }

  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:hover::before,
  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus::before,
  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible::before,
  header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:active::before {
    filter: brightness(1) invert(1);
    outline: 0
  }
}

header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md")
}

header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:hover,
header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus,
header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible,
header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:active {
  color: var(--gm-dismiss-icon-color-hover)
}

header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]::before {
  background-color: var(--gm-dismiss-bgcolor)
}

header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:hover::before {
  background-color: var(--gm-dismiss-bgcolor-hover)
}

header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus::before,
header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:focus-visible::before,
header [class*="global-message--alert-banner"] [class*="alert__dismiss"][class*="alert__dismiss"]:active::before {
  background-color: var(--gm-dismiss-bgcolor-focus)
}

header [class*="global-message--alert-banner"] [class*="alert__icon"][class*="--alert"] {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-alert-banner-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-information-banner-sm");
  background-color: var(--gm-icon-alert-color)
}

header [class*="global-message--alert-banner"] [class*="alert__icon"][class*="--error"] {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-warning-banner-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-warning-banner-sm");
  background-color: var(--gm-icon-warning-color)
}

header [class*="global-message--alert-banner"] [class*="alert__icon"][class*="--success"] {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-success-banner-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-success-banner-sm");
  background-color: var(--gm-icon-success-color)
}

header [class*="global-message--alert-banner"] [class*="alert__icon"][class*="--info"] {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-information-banner-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-alerts.svg#icon-information-banner-sm");
  background-color: var(--gm-icon-info-color)
}

@media(forced-colors:active) and (prefers-color-scheme:dark) {
  header [class*="global-message--alert-banner"] [class*="alert__icon"] {
    filter: brightness(0) invert(1)
  }
}

@media(forced-colors:active) and (prefers-color-scheme:light) {
  header [class*="global-message--alert-banner"] [class*="alert__icon"] {
    filter: brightness(1) invert(1)
  }
}

@media(max-width:768px) {
  header {
    --locale-selector-mb: 40px;
    --locale-selector-dd-mt: var(--spacing-20)
  }
}

header .locale-selection {
  display: flex;
  align-items: center;
  margin-bottom: var(--locale-selector-mb);
  z-index: 5
}

header .locale-selection:hover {
  --locale-selector-dd-display: block
}

header .locale-selection>div {
  height: 100%
}

header .locale-selection select {
  display: none !important;
  background: 0;
  color: var(--color--text-primary-inverse);
  padding: 0;
  height: auto;
  border: 0;
  width: auto;
  font-size: inherit
}

header .locale-selection select:focus:not([disabled]),
header .locale-selection select:active:not([disabled]) {
  background: 0;
  outline: 2px solid var(--color--interactive-focus-field)
}

header .locale-selection select * {
  color: var(--color--text-primary)
}

header .locale-selection .locale-drpdown__list {
  display: var(--locale-selector-custom-display);
  position: relative;
  height: 100%
}

header .locale-selection .locale-drpdown__list.select-expanded {
  --locale-selector-arrow-rotate: 180deg
}

header .locale-selection * {
  outline: 0
}

header .locale-drpdown__select {
  display: var(--locale-selector-src-display);
  padding-left: var(--spacing-30);
  position: relative
}

header .locale-drpdown__select:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%)
}

header .locale-drpdown__select.selected-en_GB::before,
header .locale-drpdown__select.selected-en-GB::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 24px;
  height: 24px;
  background-size: 40px 24px;
  background-position: center;
  border-radius: 50%;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-flags.svg#icon-flag-gb")
}

header .locale-drpdown__select.selected-en_CA::before,
header .locale-drpdown__select.selected-en-CA::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 24px;
  height: 24px;
  background-size: 40px 24px;
  background-position: center;
  border-radius: 50%;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-flags.svg#icon-flag-ca")
}

header .locale-drpdown__select.selected-en_US::before,
header .locale-drpdown__select.selected-en-US::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 24px;
  height: 24px;
  background-size: 40px 24px;
  background-position: center;
  border-radius: 50%;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-flags.svg#icon-flag-us")
}

header .locale-drpdown__select.selected-en::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-language-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-language-lg")
}

header .locale-drpdown__button,
header .locale-selection__button {
  display: flex;
  align-items: center;
  background: transparent;
  border: 1.5px solid transparent;
  color: var(--color--text-primary-inverse);
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  padding: 0;
  position: relative;
  height: 100%
}

header .locale-drpdown__button:active,
header .locale-selection__button:active,
header .locale-drpdown__button:focus,
header .locale-selection__button:focus,
header .locale-drpdown__button:focus-visible,
header .locale-selection__button:focus-visible {
  outline: 2px solid var(--color--interactive-focus-field)
}

header .locale-drpdown__button[aria-expanded="true"] .icon-expand-md::after,
header .locale-selection__button[aria-expanded="true"] .icon-expand-md::after {
  -webkit-transform: translateY(0) rotate(180deg);
  transform: translateY(0) rotate(180deg)
}

header .locale-drpdown__button::before,
header .locale-selection__button::before {
  margin-right: var(--spacing-5)
}

header .locale-drpdown__button.selected_en_GB::before,
header .locale-selection__button.selected_en_GB::before,
header .locale-drpdown__button.selected_en-GB::before,
header .locale-selection__button.selected_en-GB::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 24px;
  height: 24px;
  background-size: 40px 24px;
  background-position: center;
  border-radius: 50%;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-flags.svg#icon-flag-gb")
}

header .locale-drpdown__button.selected_en_CA::before,
header .locale-selection__button.selected_en_CA::before,
header .locale-drpdown__button.selected_en-CA::before,
header .locale-selection__button.selected_en-CA::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 24px;
  height: 24px;
  background-size: 40px 24px;
  background-position: center;
  border-radius: 50%;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-flags.svg#icon-flag-ca")
}

header .locale-drpdown__button.selected_en_US::before,
header .locale-selection__button.selected_en_US::before,
header .locale-drpdown__button.selected_en-US::before,
header .locale-selection__button.selected_en-US::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 24px;
  height: 24px;
  background-size: 40px 24px;
  background-position: center;
  border-radius: 50%;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-flags.svg#icon-flag-us")
}

header .locale-drpdown__button.selected_en::before,
header .locale-selection__button.selected_en::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-language-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-language-lg")
}

header .locale-drpdown__button::after,
header .locale-selection__button::after {
  margin-left: var(--spacing-10);
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-sm");
  transform: rotate(var(--locale-selector-arrow-rotate, 0));
  transition: var(--he-transition)
}

header .locale-drpdown__dropdown,
header .locale-selection__dropdown {
  display: var(--locale-selector-dd-display) !important;
  position: absolute;
  top: calc(100% + var(--locale-selector-dd-mt));
  right: 0;
  box-shadow: var(--shadow);
  overflow: hidden;
  width: auto;
  min-width: 100%;
  max-width: 80vw;
  padding: 0;
  background-color: var(--color--ui-02);
  border-radius: var(--corners-semiround-sm);
  z-index: 3
}

header .locale-drpdown__dropdown.isOpen,
header .locale-selection__dropdown.isOpen {
  --locale-selector-dd-display: block
}

header .locale-drpdown__dropdown .custom-select-option,
header .locale-selection__dropdown .custom-select-option,
header .locale-drpdown__dropdown li,
header .locale-selection__dropdown li {
  list-style-type: none;
  margin: 0;
  padding: 0
}

header .locale-drpdown__dropdown .custom-select-option.selected,
header .locale-selection__dropdown .custom-select-option.selected,
header .locale-drpdown__dropdown li.selected,
header .locale-selection__dropdown li.selected {
  display: none
}

header .locale-drpdown__dropdown .custom-select-option:first-child,
header .locale-selection__dropdown .custom-select-option:first-child,
header .locale-drpdown__dropdown li:first-child,
header .locale-selection__dropdown li:first-child {
  border-top-left-radius: var(--corners-semiround-sm);
  border-top-right-radius: var(--corners-semiround-sm)
}

header .locale-drpdown__dropdown .custom-select-option:last-child,
header .locale-selection__dropdown .custom-select-option:last-child,
header .locale-drpdown__dropdown li:last-child,
header .locale-selection__dropdown li:last-child {
  border-bottom-left-radius: var(--corners-semiround-sm);
  border-bottom-right-radius: var(--corners-semiround-sm)
}

header .locale-drpdown__dropdown .custom-select-option a,
header .locale-selection__dropdown .custom-select-option a,
header .locale-drpdown__dropdown li a,
header .locale-selection__dropdown li a {
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-5);
  color: var(--color--text-primary);
  white-space: nowrap;
  text-decoration: none;
  background-color: inherit;
  position: relative;
  border-radius: inherit;
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  display: block;
  padding: var(--spacing-10) var(--spacing-15);
  transition: all .125s ease-in-out
}

header .locale-drpdown__dropdown .custom-select-option a:hover,
header .locale-selection__dropdown .custom-select-option a:hover,
header .locale-drpdown__dropdown li a:hover,
header .locale-selection__dropdown li a:hover,
header .locale-drpdown__dropdown .custom-select-option a:focus,
header .locale-selection__dropdown .custom-select-option a:focus,
header .locale-drpdown__dropdown li a:focus,
header .locale-selection__dropdown li a:focus,
header .locale-drpdown__dropdown .custom-select-option a:active,
header .locale-selection__dropdown .custom-select-option a:active,
header .locale-drpdown__dropdown li a:active,
header .locale-selection__dropdown li a:active {
  font-weight: var(--typeweight--semibold);
  background-color: var(--color--ui-01)
}

header .locale-drpdown__dropdown .custom-select-option a:focus-visible,
header .locale-selection__dropdown .custom-select-option a:focus-visible,
header .locale-drpdown__dropdown li a:focus-visible,
header .locale-selection__dropdown li a:focus-visible {
  outline-color: var(--color--interactive-focus-field);
  outline-offset: -2px
}

header .locale-drpdown__dropdown .custom-select-option a::before,
header .locale-selection__dropdown .custom-select-option a::before,
header .locale-drpdown__dropdown li a::before,
header .locale-selection__dropdown li a::before {
  font-weight: var(--typeweight--semibold);
  content: attr(data-text);
  display: block;
  height: 0;
  visibility: hidden;
  overflow: hidden
}

:root {
  --color--text-border: var(--color--ui-border-01, var(--color--border-01));
  --color-sign-in-button: var(--color--interactive-primary);
  --spacing-40: 40px;
  --typefamily-primary-header: 'typefamily-primary', var(--typefamily-primary);
  --he-headerPosition: relative;
  --he-header-min-h: 64px;
  --he-header-y-shift: 0;
  --he-header-grid: auto auto 1fr auto;
  --he-header-gaps: var(--spacing-90);
  --he-header-bg-color: var(--color--ui-background-dark, var(--color--background-dark));
  --he-header-link-color: var(--color--ui-02);
  --he-text-color-global: var(--color--interactive-tertiary-inverse);
  --he-header-usernav-inverse: var(--color--text-primary);
  --he-logo-filter-color: none;
  --he-header-link-color-inverse: var(--color--text-secondary);
  --he-header-desktop-nav-display: block;
  --he-header-mobile-nav-display: none;
  --headerContainerSpacingY: 0;
  --headerContainerSpacingX: var(--spacing-20);
  --he-header-nav-icon-size: 36px;
  --he-header-nav-divider-color: var(--color--ui-01);
  --he-header-underlay-opacity: 1;
  --he-header-cart-icon-size: 24px;
  --he-headrer-display-icon-nav-message: flex;
  --he-heaader-display-search: block;
  --he-header-display-additional: flex;
  --he-headrer-display-reduced-message: none;
  --he-color-text-secure-checkout: var(--color--text-primary-inverse);
  --he-header-display-global-link-desktop: block;
  --he-header-display-global-link-mobile: none;
  --he-header-width-dropdown: 230px;
  --he-header-mx-w-dropdown: 50vw;
  --he-header-search-bg: rgba(254, 254, 254, 0.35);
  --he-header-search-bg-alt: rgba(254, 254, 254, 0.8);
  --he-header-search-bg-focuse: rgba(247, 249, 253, 0.7);
  --he-header-search-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  --he-header-search-color: var(--color--text-secondary-inverse);
  --he-header-search-color-alt: #f7f9fd;
  --he-header-search-border-color: var(--color--icon-tertiary-inverse);
  --he-header-logo-height: 36px;
  --he-header-logo-width: 116px;
  --he-header-icon-nav-grow: 0;
  --he-inverse-text-primary1: var(--color--text-primary-inverse);
  --he-text-secondary1: #333;
  --he-nav--mobile-display: none;
  --he-topnav-display: block;
  --he-topnav-min-h: 40px;
  --he-topnav-bg-c: var(--color--icon-secondary);
  --he-top-nav-base-text-color: #fefefe;
  --he-top-nav-base-text-opacity: .59;
  --he-top-nav-base-text-size: var(--typesize-milli);
  --he-topnav-z: 4;
  --he-topnav-opacity: 1;
  --he-topnav-drdwn-display: none;
  --he-topnav-locale-selector-underline-shift: 29px;
  --he-topnav-locale-selector-underline-w: calc(100% - var(--he-topnav-locale-selector-underline-shift));
  --he-nav-interactive-c: var(--color--interactive-action);
  --he-primery-nav-link-c-base: var(--color--text-primary-inverse);
  --he-primery-nav-text-size-base: var(--typesize-default);
  --he-primery-nav-logo-w: auto;
  --he-primery-nav-locale-display: flex;
  --he-mobile-nav-display: none;
  --he-mobile-nav-level: 0;
  --he-mobile-nav-bgc: var(--color--ui-background-light, var(--color--background-light));
  --he-transition: .125s ease-in-out;
  --he-mobile-nav-min-h: 0;
  --he-mobile-nav-height: calc(100vh - var(--he-header-min-h));
  --he-mobile-nav-base-text-size: var(--typesize-default);
  --he-mobile-nav-base-text-line: var(--leading--1-6);
  --he-mobile-nav-base-text-color: var(--color--text-primary);
  --he-mobile-nav-level-transition: 0
}

:root div.xf-content-height {
  margin: 0;
  min-height: unset
}

header {
  --spacing-25: 25px;
  --spacing-35: 35px
}

header.isSticky {
  --he-topnav-z: 0;
  --he-topnav-opacity: 0;
  --he-nav-interactive-c: var(--color--text-primary);
  --he-primery-nav-link-c-base: var(--color--text-primary);
  padding-top: var(--he-header-min-h)
}

header.isSticky .he-pr-nav__wrapper {
  --he-headerPosition: fixed;
  --he-header-bg-color: var(--color--ui-background-med, var(--color--background-medium));
  --he-text-color-global: var(--color--text-primary);
  --he-header-search-color: var(--color--text-tertiary);
  --he-header-search-border-color: var(--color--text-tertiary);
  --he-header-search-bg: transparent;
  --he-header-search-focus-bg-color: transparent;
  --he-logo-filter-color: brightness(0) saturate(100%) invert(8%) sepia(51%) saturate(1451%) hue-rotate(190deg) brightness(87%) contrast(102%);
  --he-color-text-secure-checkout: var(--color--text-primary);
  --he-header-underlay-opacity: 0;
  --he-header-usernav-inverse: var(--color--interactive-tertiary-inverse);
  --he-inverse-text-primary1: var(--color--text-primary);
  --he-header-p-events: all;
  top: 0
}

@media(max-width:991px) {
  :root {
    --he-header-display-additional: none;
    --he-header-grid: auto 134px 1fr auto;
    --he-header-gaps: 0;
    --he-header-desktop-nav-display: none;
    --he-header-mobile-nav-display: block;
    --headerContainerSpacingX: var(--spacing-20);
    --he-header-display-global-link-desktop: none;
    --he-header-display-global-link-mobile: block;
    --he-header-mx-w-dropdown: 90vw
  }
}

@media(max-width:1024px) {
  :root {
    --he-inverse-text-primary1: var(--color--text-primary)
  }
}

@media(max-width:1590px) {
  :root {
    --he-header-gaps: var(--spacing-60)
  }
}

@media(max-width:1390px) {
  :root {
    --he-header-gaps: var(--spacing-45)
  }
}

@media(max-width:992px) {
  header {
    --he-topnav-display: none;
    --he-primery-nav-logo-w: 37px;
    --he-primery-nav-locale-display: none;
    --he-mobile-nav-display: block;
    --he-mobile-nav-max-h: 375px
  }

  .he-pr-nav__wrapper {
    --he-header-gaps: var(--spacing-20)
  }
}

@media(max-width:480px) {
  :root {
    --he-header-logo-height: 34px;
    --he-header-logo-width: 31px
  }

  header {
    --he-mobile-nav-max-h: unset;
    --he-mobile-nav-level-transition: var(--he-transition)
  }
}

@media screen and (min-width:993px) {

  header .he-nav--mobile-only,
  footer .he-nav--mobile-only {
    display: none !important
  }
}

.aem-AuthorLayer-Edit .he-pr-nav__wrapper,
.he-pr-nav__wrapper[id*='cq-'] {
  --he-header-mobile-nav-display: block
}

.headerIsTransparent .he-pr-nav__wrapper {
  --he-headerPosition: absolute;
  --he-header-bg-color: transparent
}

.headerIsTransparent header.isSticky {
  padding-top: 0
}

html.wf-active body>.container {
  max-width: 100%;
  margin: 0;
  padding: 0
}

.container {
  --footer-separator-margin: var(--grid-margin-md)
}

@media screen and (max-width:768px) {
  .container {
    --footer-separator-margin: var(--grid-margin-sm)
  }
}

.inpn-page-state__crossed {
  --he-header-y-shift: -100%;
  --he-header-p-events: none
}

.inpn-page-state__crossed.inpn-page-state__top {
  --he-header-y-shift: 0;
  --he-header-p-events: all
}

.skip-nav {
  position: absolute;
  display: inline-block;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 10px 15px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0)
}

.skip-nav:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 1px;
  clip: auto;
  flex: none
}

.he-navigation-topnav {
  display: var(--he-topnav-display);
  min-height: var(--he-topnav-min-h);
  padding: 0;
  background-color: var(--he-topnav-bg-c);
  z-index: var(--he-topnav-z);
  opacity: var(--he-topnav-opacity)
}

.he-navigation-topnav .container {
  max-width: unset;
  padding: var(--headerContainerSpacingY) var(--headerContainerSpacingX)
}

.he-navigation-topnav .row {
  justify-content: center
}

.he-navigation-topnav .col-sm-9 {
  position: unset
}

.he-navigation-topnav .col-sm-3 {
  display: flex;
  justify-content: flex-end
}

.he-navigation-topnav .mega-nav {
  display: flex;
  align-items: center;
  padding: 0;
  height: 100%;
  position: unset
}

.he-navigation-topnav .mega-nav .navbar-header {
  display: none
}

.he-navigation-topnav .mega-nav .navbar-collapse {
  display: block;
  height: 100%
}

.he-navigation-topnav .mega-nav .navbar-nav {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-30);
  min-height: var(--he-topnav-min-h)
}

.he-navigation-topnav .mega-nav .dropdown-toggle {
  position: relative;
  font-family: var(--typefamily-primary);
  font-size: var(--he-top-nav-base-text-size);
  font-weight: var(--typeweight--medium);
  color: currentColor;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  height: 100%
}

.he-navigation-topnav .mega-nav .dropdown-toggle:hover {
  text-decoration: none
}

.he-navigation-topnav .mega-nav .dropdown-toggle::after {
  content: unset
}

.he-navigation-topnav .dropdown .dropdown-toggle {
  transition: opacity var(--he-transition);
  opacity: var(--he-top-nav-base-text-opacity)
}

.he-navigation-topnav .dropdown .dropdown-toggle:hover,
.he-navigation-topnav .dropdown .dropdown-toggle:focus {
  --he-top-nav-base-text-opacity: 1
}

.he-navigation-topnav .dropdown .dropdown-toggle:hover::before {
  opacity: 1
}

.he-navigation-topnav .dropdown.t-nav-expandable .dropdown-btn-toggle {
  opacity: var(--he-top-nav-base-text-opacity);
  transition: opacity var(--he-transition)
}

.he-navigation-topnav .dropdown.t-nav-expandable .dropdown-btn-toggle:focus {
  --he-top-nav-base-text-opacity: 1
}

.he-header-icon-navigation {
  height: 100%
}

.he-header-icon-navigation .parsys.section {
  height: 100%
}

.he-header-icon-navigation .locale-selection {
  display: var(--he-primery-nav-locale-display);
  height: 100%;
  margin-right: var(--spacing-20);
  color: var(--he-primery-nav-link-c-base)
}

.he-header-icon-navigation .locale-selection .locale-drpdown__button .locale-selection__button {
  color: inherit
}

.he-header-icon-navigation .locale-drpdown__list,
.he-navigation-topnav .locale-drpdown__list,
.he-header-icon-navigation .locale-selection>div,
.he-navigation-topnav .locale-selection>div {
  position: relative
}

.he-header-icon-navigation .locale-drpdown__list::after,
.he-navigation-topnav .locale-drpdown__list::after,
.he-header-icon-navigation .locale-selection>div::after,
.he-navigation-topnav .locale-selection>div::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: var(--he-topnav-locale-selector-underline-shift);
  width: var(--he-topnav-locale-selector-underline-w);
  border-radius: 3px 3px 0 0;
  height: 3px;
  background-color: var(--he-nav-interactive-c);
  opacity: 0;
  transition: opacity var(--he-transition)
}

.he-header-icon-navigation .locale-drpdown__list .locale-drpdown__button::after,
.he-navigation-topnav .locale-drpdown__list .locale-drpdown__button::after,
.he-header-icon-navigation .locale-selection>div .locale-drpdown__button::after,
.he-navigation-topnav .locale-selection>div .locale-drpdown__button::after,
.he-header-icon-navigation .locale-drpdown__list .locale-selection__button::after,
.he-navigation-topnav .locale-drpdown__list .locale-selection__button::after,
.he-header-icon-navigation .locale-selection>div .locale-selection__button::after,
.he-navigation-topnav .locale-selection>div .locale-selection__button::after {
  transition: transform var(--he-transition)
}

.he-header-icon-navigation .locale-drpdown__list:hover,
.he-navigation-topnav .locale-drpdown__list:hover,
.he-header-icon-navigation .locale-selection>div:hover,
.he-navigation-topnav .locale-selection>div:hover {
  --locale-selector-dd-display: block
}

.he-header-icon-navigation .locale-drpdown__list:hover::after,
.he-navigation-topnav .locale-drpdown__list:hover::after,
.he-header-icon-navigation .locale-selection>div:hover::after,
.he-navigation-topnav .locale-selection>div:hover::after {
  opacity: 1
}

.he-header-icon-navigation .locale-drpdown__list:hover .locale-drpdown__button::after,
.he-navigation-topnav .locale-drpdown__list:hover .locale-drpdown__button::after,
.he-header-icon-navigation .locale-selection>div:hover .locale-drpdown__button::after,
.he-navigation-topnav .locale-selection>div:hover .locale-drpdown__button::after,
.he-header-icon-navigation .locale-drpdown__list:hover .locale-selection__button::after,
.he-navigation-topnav .locale-drpdown__list:hover .locale-selection__button::after,
.he-header-icon-navigation .locale-selection>div:hover .locale-selection__button::after,
.he-navigation-topnav .locale-selection>div:hover .locale-selection__button::after {
  transform: rotate(180deg)
}

.he-header-icon-navigation .locale-drpdown__list.select-expanded::after,
.he-navigation-topnav .locale-drpdown__list.select-expanded::after,
.he-header-icon-navigation .locale-selection>div.select-expanded::after,
.he-navigation-topnav .locale-selection>div.select-expanded::after {
  opacity: 1
}

.he-header-icon-navigation .locale-drpdown__list .locale-drpdown__dropdown,
.he-navigation-topnav .locale-drpdown__list .locale-drpdown__dropdown,
.he-header-icon-navigation .locale-selection>div .locale-drpdown__dropdown,
.he-navigation-topnav .locale-selection>div .locale-drpdown__dropdown {
  left: auto;
  right: 0
}

.he-header-icon-navigation .locale-drpdown__list .locale-drpdown__dropdown .custom-select-option a,
.he-navigation-topnav .locale-drpdown__list .locale-drpdown__dropdown .custom-select-option a,
.he-header-icon-navigation .locale-selection>div .locale-drpdown__dropdown .custom-select-option a,
.he-navigation-topnav .locale-selection>div .locale-drpdown__dropdown .custom-select-option a {
  white-space: nowrap
}

.he-header-desktop-nav .dropdown,
.he-navigation-topnav .dropdown {
  position: unset;
  height: var(--he-topnav-min-h);
  color: var(--he-top-nav-base-text-color)
}

.he-header-desktop-nav .dropdown>.dropdown-toggle::before,
.he-navigation-topnav .dropdown>.dropdown-toggle::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 3px 3px 0 0;
  height: 3px;
  background-color: var(--he-nav-interactive-c);
  opacity: 0;
  transition: opacity var(--he-transition)
}

.he-header-desktop-nav .dropdown.t-nav-expandable,
.he-navigation-topnav .dropdown.t-nav-expandable {
  display: flex;
  align-items: center
}

.he-header-desktop-nav .dropdown.t-nav-expandable .dropdown-toggle,
.he-navigation-topnav .dropdown.t-nav-expandable .dropdown-toggle,
.he-header-desktop-nav .dropdown.t-nav-expandable .dropdown-toggle[data-toggle],
.he-navigation-topnav .dropdown.t-nav-expandable .dropdown-toggle[data-toggle] {
  position: relative
}

.he-header-desktop-nav .dropdown.t-nav-expandable .dropdown-toggle::after,
.he-navigation-topnav .dropdown.t-nav-expandable .dropdown-toggle::after,
.he-header-desktop-nav .dropdown.t-nav-expandable .dropdown-toggle[data-toggle]::after,
.he-navigation-topnav .dropdown.t-nav-expandable .dropdown-toggle[data-toggle]::after {
  content: unset
}

.he-header-desktop-nav .dropdown.t-nav-expandable .dropdown-btn-toggle,
.he-navigation-topnav .dropdown.t-nav-expandable .dropdown-btn-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 3px;
  padding: 2px 0;
  min-width: 18px;
  width: 16px;
  color: var(--he-top-nav-base-text-color)
}

.he-header-desktop-nav .dropdown.t-nav-expandable .dropdown-btn-toggle::after,
.he-navigation-topnav .dropdown.t-nav-expandable .dropdown-btn-toggle::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-sm");
  transition: transform var(--he-transition)
}

.he-header-desktop-nav .dropdown.t-nav-expandable .dropdown-btn-toggle:focus-visible,
.he-navigation-topnav .dropdown.t-nav-expandable .dropdown-btn-toggle:focus-visible {
  outline: 2px solid var(--color--interactive-focus);
  border: 0
}

.he-header-desktop-nav .dropdown.t-nav-expandable>.dropdown-toggle::before,
.he-navigation-topnav .dropdown.t-nav-expandable>.dropdown-toggle::before {
  width: calc(100% + 18px)
}

.he-header-desktop-nav .dropdown.t-nav-expandable:hover .dropdown-menu,
.he-navigation-topnav .dropdown.t-nav-expandable:hover .dropdown-menu,
.he-header-desktop-nav .dropdown.t-nav-expandable.t-nav-expanded .dropdown-menu,
.he-navigation-topnav .dropdown.t-nav-expandable.t-nav-expanded .dropdown-menu {
  --he-topnav-drdwn-display: block
}

.he-header-desktop-nav .dropdown .dropdown-menu,
.he-navigation-topnav .dropdown .dropdown-menu {
  display: var(--he-topnav-drdwn-display) !important;
  position: absolute;
  top: 100%;
  left: var(--spacing-35);
  right: var(--spacing-35);
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: 0;
  max-height: calc(100vh - var(--he-header-min-h));
  overflow-y: auto;
  overscroll-behavior-y: contain;
  box-shadow: var(--shadow-blur);
  background-color: var(--color--ui-background-light, var(--color--background-light))
}

.he-header-desktop-nav .dropdown .dropdown-menu .container,
.he-navigation-topnav .dropdown .dropdown-menu .container {
  padding: var(--spacing-45)
}

.he-header-desktop-nav .dropdown .dropdown-menu .column-control,
.he-navigation-topnav .dropdown .dropdown-menu .column-control {
  margin-top: 0
}

.he-header-desktop-nav .dropdown .dropdown-menu .row,
.he-navigation-topnav .dropdown .dropdown-menu .row {
  margin: 0 calc(-1 * var(--spacing-15))
}

.he-header-desktop-nav .dropdown .dropdown-menu .col-3,
.he-navigation-topnav .dropdown .dropdown-menu .col-3 {
  display: block;
  margin-bottom: 0;
  padding: 0 var(--spacing-15);
  justify-content: flex-start;
  max-width: unset;
  width: auto
}

.he-header-desktop-nav .dropdown .dropdown-menu .col-3 ul,
.he-navigation-topnav .dropdown .dropdown-menu .col-3 ul {
  display: flex;
  flex-flow: column
}

.he-header-desktop-nav .dropdown .dropdown-menu .link-list,
.he-navigation-topnav .dropdown .dropdown-menu .link-list,
.he-header-desktop-nav .dropdown .dropdown-menu .accordion-panel,
.he-navigation-topnav .dropdown .dropdown-menu .accordion-panel {
  padding: 0;
  border-right: 0;
  box-shadow: unset
}

.he-header-desktop-nav .dropdown .dropdown-menu .link-list .accordion-panel-header,
.he-navigation-topnav .dropdown .dropdown-menu .link-list .accordion-panel-header,
.he-header-desktop-nav .dropdown .dropdown-menu .accordion-panel .accordion-panel-header,
.he-navigation-topnav .dropdown .dropdown-menu .accordion-panel .accordion-panel-header {
  color: var(--color--text-secondary)
}

.he-header-desktop-nav .dropdown .dropdown-menu .link-list .accordion-panel-header button,
.he-navigation-topnav .dropdown .dropdown-menu .link-list .accordion-panel-header button,
.he-header-desktop-nav .dropdown .dropdown-menu .accordion-panel .accordion-panel-header button,
.he-navigation-topnav .dropdown .dropdown-menu .accordion-panel .accordion-panel-header button {
  display: none
}

.he-header-desktop-nav .dropdown .dropdown-menu .link-list .pull-right,
.he-navigation-topnav .dropdown .dropdown-menu .link-list .pull-right,
.he-header-desktop-nav .dropdown .dropdown-menu .accordion-panel .pull-right,
.he-navigation-topnav .dropdown .dropdown-menu .accordion-panel .pull-right {
  display: none
}

.he-header-desktop-nav .dropdown .dropdown-menu .link-list .accordion-panel-content,
.he-navigation-topnav .dropdown .dropdown-menu .link-list .accordion-panel-content,
.he-header-desktop-nav .dropdown .dropdown-menu .accordion-panel .accordion-panel-content,
.he-navigation-topnav .dropdown .dropdown-menu .accordion-panel .accordion-panel-content {
  display: block;
  padding: 0
}

.he-header-desktop-nav .dropdown .dropdown-menu .link-list .link-list,
.he-navigation-topnav .dropdown .dropdown-menu .link-list .link-list,
.he-header-desktop-nav .dropdown .dropdown-menu .accordion-panel .link-list,
.he-navigation-topnav .dropdown .dropdown-menu .accordion-panel .link-list {
  margin-bottom: 0
}

.he-header-desktop-nav .dropdown .dropdown-menu .link-list h2,
.he-navigation-topnav .dropdown .dropdown-menu .link-list h2,
.he-header-desktop-nav .dropdown .dropdown-menu .accordion-panel h2,
.he-navigation-topnav .dropdown .dropdown-menu .accordion-panel h2,
.he-header-desktop-nav .dropdown .dropdown-menu .link-list h3,
.he-navigation-topnav .dropdown .dropdown-menu .link-list h3,
.he-header-desktop-nav .dropdown .dropdown-menu .accordion-panel h3,
.he-navigation-topnav .dropdown .dropdown-menu .accordion-panel h3 {
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-6);
  border-bottom: 1px solid var(--color--ui-border-01, var(--color--border-01));
  padding-bottom: var(--spacing-10)
}

.he-header-desktop-nav .dropdown .dropdown-menu .link-list li,
.he-navigation-topnav .dropdown .dropdown-menu .link-list li,
.he-header-desktop-nav .dropdown .dropdown-menu .accordion-panel li,
.he-navigation-topnav .dropdown .dropdown-menu .accordion-panel li {
  padding: var(--spacing-20) 0 0 0;
  border-bottom: 0;
  margin: 0
}

.he-header-desktop-nav .dropdown .dropdown-menu .link-list li a,
.he-navigation-topnav .dropdown .dropdown-menu .link-list li a,
.he-header-desktop-nav .dropdown .dropdown-menu .accordion-panel li a,
.he-navigation-topnav .dropdown .dropdown-menu .accordion-panel li a {
  font-size: var(--typesize-minor);
  line-height: var(--leading--1-5);
  font-weight: var(--typeweight--default);
  color: var(--color--text-secondary)
}

.he-header-desktop-nav .dropdown .dropdown-menu .link-list li a::after,
.he-navigation-topnav .dropdown .dropdown-menu .link-list li a::after,
.he-header-desktop-nav .dropdown .dropdown-menu .accordion-panel li a::after,
.he-navigation-topnav .dropdown .dropdown-menu .accordion-panel li a::after {
  content: unset
}

.he-header-desktop-nav .dropdown .dropdown-menu .link-list li:last-child ul,
.he-navigation-topnav .dropdown .dropdown-menu .link-list li:last-child ul,
.he-header-desktop-nav .dropdown .dropdown-menu .accordion-panel li:last-child ul,
.he-navigation-topnav .dropdown .dropdown-menu .accordion-panel li:last-child ul {
  margin-bottom: 0
}

.he-header-desktop-nav .dropdown .link-list h2,
.he-navigation-topnav .dropdown .link-list h2 {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--semibold-plus);
  line-height: var(--leading--1-1)
}

.he-header-desktop-nav .dropdown .mega-nav-full-width,
.he-navigation-topnav .dropdown .mega-nav-full-width {
  margin-top: 0
}

.he-header-desktop-nav .dropdown .mega-nav-full-width+.mega-nav-full-width,
.he-navigation-topnav .dropdown .mega-nav-full-width+.mega-nav-full-width {
  margin-left: var(--spacing-40)
}

@media(width:1024px) {

  .he-header-desktop-nav .dropdown .mega-nav-full-width,
  .he-navigation-topnav .dropdown .mega-nav-full-width {
    padding: 0
  }

  .he-header-desktop-nav .dropdown .mega-nav-full-width+.mega-nav-full-width,
  .he-navigation-topnav .dropdown .mega-nav-full-width+.mega-nav-full-width {
    margin-left: var(--spacing-20)
  }
}

.he-header-desktop-nav .dropdown .mega-nav-full-width>a,
.he-navigation-topnav .dropdown .mega-nav-full-width>a {
  font-size: var(--typesize-default);
  font-family: var(--typefamily-primary);
  color: var(--he-text-color-global);
  font-weight: var(--typeweight--default)
}

.he-header-desktop-nav .dropdown.active,
.he-navigation-topnav .dropdown.active,
.he-header-desktop-nav .dropdown.t-nav-expandable.t-nav-expanded,
.he-navigation-topnav .dropdown.t-nav-expandable.t-nav-expanded,
.he-header-desktop-nav .dropdown.t-nav-expandable:hover,
.he-navigation-topnav .dropdown.t-nav-expandable:hover {
  --he-top-nav-base-text-opacity: 1
}

.he-header-desktop-nav .dropdown.active .dropdown-toggle::before,
.he-navigation-topnav .dropdown.active .dropdown-toggle::before,
.he-header-desktop-nav .dropdown.t-nav-expandable.t-nav-expanded .dropdown-toggle::before,
.he-navigation-topnav .dropdown.t-nav-expandable.t-nav-expanded .dropdown-toggle::before,
.he-header-desktop-nav .dropdown.t-nav-expandable:hover .dropdown-toggle::before,
.he-navigation-topnav .dropdown.t-nav-expandable:hover .dropdown-toggle::before {
  opacity: 1
}

.he-header-desktop-nav .dropdown.active .dropdown-btn-toggle::after,
.he-navigation-topnav .dropdown.active .dropdown-btn-toggle::after,
.he-header-desktop-nav .dropdown.t-nav-expandable.t-nav-expanded .dropdown-btn-toggle::after,
.he-navigation-topnav .dropdown.t-nav-expandable.t-nav-expanded .dropdown-btn-toggle::after,
.he-header-desktop-nav .dropdown.t-nav-expandable:hover .dropdown-btn-toggle::after,
.he-navigation-topnav .dropdown.t-nav-expandable:hover .dropdown-btn-toggle::after {
  transform: rotate(180deg)
}

.he-header-desktop-nav .dropdown .dropdown-toggle[data-highlighted="true"],
.he-navigation-topnav .dropdown .dropdown-toggle[data-highlighted="true"] {
  --he-top-nav-base-text-opacity: 1
}

.he-header-desktop-nav .dropdown .dropdown-toggle[data-highlighted="true"]::before,
.he-navigation-topnav .dropdown .dropdown-toggle[data-highlighted="true"]::before {
  opacity: 1
}

.mega-nav.he-header-desktop-nav {
  display: var(--he-header-desktop-nav-display);
  position: unset;
  padding: 0
}

.mega-nav.he-header-desktop-nav .container {
  padding: var(--headerContainerSpacingY) var(--headerContainerSpacingX);
  max-width: 100%
}

.mega-nav.he-header-desktop-nav .navbar-header {
  display: none
}

.mega-nav.he-header-desktop-nav .collapse {
  display: flex
}

.mega-nav.he-header-desktop-nav .navbar-nav {
  display: flex;
  flex-flow: row
}

.mega-nav.he-header-desktop-nav .navbar-nav .dropdown:nth-last-child(2) {
  display: none
}

.mega-nav.he-header-desktop-nav .navbar-nav .dropdown:last-child {
  display: none
}

.mega-nav.he-header-desktop-nav .mega-nav-full-width {
  position: unset
}

.mega-nav.he-header-desktop-nav .dropdown-menu,
.mega-nav.he-header-desktop-nav .dropdown-menu.show {
  display: none
}

.mega-nav.he-header-desktop-nav .dropdown-menu.isOpen {
  display: block;
  position: absolute;
  left: 0;
  top: var(--he-header-min-h);
  margin-top: 0;
  border-radius: 0;
  border: 0;
  width: 100%;
  padding: var(--spacing-45) 0;
  box-shadow: var(--shadow-blur);
  max-height: calc(100vh - var(--he-header-min-h));
  overflow-y: auto;
  overscroll-behavior-y: contain
}

.mega-nav.he-header-desktop-nav .dropdown-menu.isOpen li {
  padding: 0
}

.mega-nav.he-header-desktop-nav .dropdown-menu.isOpen li:last-child ul {
  margin-bottom: 0
}

.mega-nav.he-header-desktop-nav .link-list h2 {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--semibold-plus);
  line-height: var(--leading--1-1)
}

.mega-nav.he-header-desktop-nav .mega-nav-full-width {
  margin-top: 0
}

.mega-nav.he-header-desktop-nav .mega-nav-full-width+.mega-nav-full-width {
  margin-left: var(--spacing-40)
}

@media(width:1024px) {
  .mega-nav.he-header-desktop-nav .mega-nav-full-width {
    padding: 0
  }

  .mega-nav.he-header-desktop-nav .mega-nav-full-width+.mega-nav-full-width {
    margin-left: var(--spacing-20)
  }
}

.mega-nav.he-header-desktop-nav .mega-nav-full-width>a {
  font-size: var(--typesize-default);
  font-family: var(--typefamily-primary);
  color: var(--he-text-color-global);
  font-weight: var(--typeweight--default)
}

.mega-nav.he-header-desktop-nav,
.mega-nav.he-header-desktop-nav .navbar-collapse,
.mega-nav.he-header-desktop-nav .navbar-nav,
.mega-nav.he-header-desktop-nav .dropdown,
.mega-nav.he-header-desktop-nav .dropdown-toggle {
  height: 100%
}

.mega-nav.he-header-desktop-nav .navbar-nav .dropdown:nth-last-child(2),
.mega-nav.he-header-desktop-nav .navbar-nav .dropdown:last-child {
  display: flex
}

.mega-nav.he-header-desktop-nav .dropdown,
.mega-nav.he-header-desktop-nav .dropdown.mega-nav-full-width {
  opacity: 1;
  color: var(--he-primery-nav-link-c-base);
  font-size: var(--he-primery-nav-text-size-base)
}

.mega-nav.he-header-desktop-nav .dropdown .dropdown-toggle,
.mega-nav.he-header-desktop-nav .dropdown.mega-nav-full-width .dropdown-toggle {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit
}

.mega-nav.he-header-desktop-nav .dropdown .dropdown-toggle:hover,
.mega-nav.he-header-desktop-nav .dropdown.mega-nav-full-width .dropdown-toggle:hover {
  text-decoration: none
}

.mega-nav.he-header-desktop-nav .dropdown .dropdown-btn-toggle,
.mega-nav.he-header-desktop-nav .dropdown.mega-nav-full-width .dropdown-btn-toggle {
  color: inherit
}

.mega-nav.he-header-desktop-nav .dropdown .dropdown-btn-toggle::after,
.mega-nav.he-header-desktop-nav .dropdown.mega-nav-full-width .dropdown-btn-toggle::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md")
}

.mega-nav.he-header-desktop-nav .dropdown .dropdown-menu,
.mega-nav.he-header-desktop-nav .dropdown.mega-nav-full-width .dropdown-menu {
  width: 100%;
  left: 0;
  padding: 0
}

.he-pr-nav__wrapper #logo {
  width: var(--he-primery-nav-logo-w);
  overflow: hidden
}

.he-pr-nav__wrapper #logo img {
  height: 100%;
  width: auto;
  max-width: unset
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav {
  display: var(--he-mobile-nav-display);
  padding: 0
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar {
  padding: 0
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-toggle {
  background: transparent;
  border: 0;
  padding: 0;
  line-height: var(--leading--1-1);
  position: relative;
  width: 14px;
  height: 14px;
  margin-left: 3px
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-toggle .fa,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-toggle .fa::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-toggle .fa::after {
  content: '';
  width: 100%;
  position: absolute;
  height: 2px;
  left: 0;
  padding: 0;
  border-radius: 10px;
  background: var(--he-text-color-global);
  transition: all .2s ease
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-toggle[aria-expanded="false"] .fa::before {
  top: -6px
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-toggle[aria-expanded="false"] .fa::after {
  top: 6px
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-toggle[aria-expanded="true"] .fa {
  background: 0
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-toggle[aria-expanded="true"] .fa::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-toggle[aria-expanded="true"] .fa::after {
  background: var(--he-text-color-global);
  width: 17px;
  left: 0
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-toggle[aria-expanded="true"] .fa::before {
  top: -1px;
  transform: rotate(45deg)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-toggle[aria-expanded="true"] .fa::after {
  top: -1px;
  transform: rotate(-45deg)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-collapse {
  visibility: collapse;
  display: block;
  width: 0;
  height: 0;
  top: 0;
  left: 0
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-collapse.isOpen {
  position: absolute;
  visibility: visible;
  left: 0;
  top: var(--he-header-min-h);
  height: auto;
  max-height: calc(100vh - var(--he-header-min-h));
  padding: var(--spacing-30) var(--spacing-20) var(--spacing-45);
  overflow-y: auto;
  pointer-events: none
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-collapse.isOpen>* {
  pointer-events: all
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-nav {
  display: none
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-nav {
  display: block;
  padding-top: var(--spacing-10)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse {
  display: none;
  padding: 0;
  overflow: visible hidden;
  max-height: 100vh;
  background-color: transparent
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse.isOpen {
  display: block
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div {
  position: fixed;
  top: var(--he-header-min-h);
  width: 100vw;
  max-width: var(--he-mobile-nav-max-h);
  left: calc(100vw * var(--he-mobile-nav-level));
  min-height: var(--he-mobile-nav-height);
  transition: all var(--he-mobile-nav-level-transition);
  max-height: 100%;
  pointer-events: none
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .row {
  display: block;
  margin: 0
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .col-12 {
  padding: 0;
  margin-bottom: 0;
  width: 100%
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .pull-right {
  display: none
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div>* {
  pointer-events: all;
  background-color: var(--color--ui-background-gray, var(--color--background-gray))
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .navbar-nav {
  visibility: visible;
  background-color: var(--he-mobile-nav-bgc)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown-menu {
  display: none;
  position: absolute;
  width: 100vw;
  max-width: var(--he-mobile-nav-max-h);
  top: 0;
  left: 100vw;
  background-color: var(--he-mobile-nav-bgc);
  max-height: var(--he-mobile-nav-height);
  overflow: visible;
  margin: 0;
  border-radius: 0;
  border: 0
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown-menu.active {
  overflow-y: auto
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown-menu .mega-nav {
  padding: 0
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown-menu .navbar-header {
  display: none
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown .dropdown-toggle {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-15) var(--spacing-20);
  font-family: var(--typefamily-primary);
  font-size: var(--he-mobile-nav-base-text-size);
  color: var(--he-mobile-nav-base-text-color);
  line-height: var(--he-mobile-nav-base-text-line);
  font-weight: var(--typeweight--semibold-plus)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown .dropdown-toggle::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-md");
  transform: rotate(180deg)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown .dropdown-toggle:hover {
  text-decoration: none
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.expanded>.dropdown-menu {
  display: block
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-10)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .dropdown-toggle {
  flex-grow: 1;
  padding: var(--spacing-5) var(--spacing-10);
  border-right: 1px solid var(--color--ui-border-03, var(--color--border-03))
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .dropdown-toggle::after {
  content: unset
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary);
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem;
  border-radius: 0;
  padding: 0;
  max-width: 36px;
  max-height: 36px;
  width: 46px;
  margin-left: var(--spacing-10) !important
}

@media(min-width:376px) {
  .he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:hover,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:focus,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:focus-visible,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:hover::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:focus::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:focus-visible::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:active::before {
  transform: scale(1.05)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:focus,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:focus-visible {
  outline: 0
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:focus::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:active::before {
  outline-color: transparent
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:only-of-type {
  margin: 0
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn::before {
  background: var(--color--interactive-secondary-inverse)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:hover {
  color: var(--color--text-primary)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:hover::before {
  background: var(--color--interactive-secondary-inverse-hover, var(--color--interactive-secondary-hover-inverse))
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:focus,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:focus-visible {
  color: var(--color--text-primary)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:focus::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:focus-visible::before {
  background: var(--color--interactive-secondary-inverse)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:active {
  color: var(--color--text-primary)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn:active::before {
  background: var(--color--interactive-secondary-inverse-active, var(--color--interactive-secondary-active-inverse))
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn[disabled],
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02);
  color: var(--color--interactive-disabled-03)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn[disabled]::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn[disabled]:hover::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn.button-disabled:hover::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn[disabled]:focus::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn.button-disabled:focus::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn[disabled]:focus-visible::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn.button-disabled:focus-visible::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn[disabled]:active::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn[disabled]::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn[disabled]:hover::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn.button-disabled:hover::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn[disabled]:focus::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn.button-disabled:focus::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn[disabled]:focus-visible::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn.button-disabled:focus-visible::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn[disabled]:active::before,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn.button-disabled:active::before {
  background: var(--color--interactive-disabled-01)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .dropdown.has-button .he-header-mobile-nav--dive-btn::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  transform: rotate(180deg)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .mega-nav {
  position: static
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse>div .navbar-collapse {
  display: block;
  width: auto;
  height: auto;
  visibility: visible
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .accordion {
  margin-top: 0
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .accordion-panel {
  --accordion-display: none;
  margin-top: 0;
  box-shadow: none
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .accordion-panel:hover {
  box-shadow: none
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .accordion-panel.accordion-expanded {
  --accordion-display: block
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .accordion-panel-header {
  padding: 0
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .accordion-panel-header[aria-expanded="false"] button span {
  font-weight: inherit;
  animation-name: none
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .accordion-panel-header[aria-expanded="false"] button span:first-of-type {
  animation-name: none;
  font-weight: inherit
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .accordion-panel-header[aria-expanded="true"] button span {
  font-weight: inherit;
  animation-name: none
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .accordion-panel-header[aria-expanded="true"] button span:first-of-type {
  animation-name: none;
  font-weight: inherit
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .accordion-panel-header button {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-15) var(--spacing-20);
  font-family: var(--typefamily-primary);
  font-size: var(--he-mobile-nav-base-text-size);
  color: var(--he-mobile-nav-base-text-color);
  line-height: var(--he-mobile-nav-base-text-line);
  font-weight: var(--typeweight--semibold-plus);
  border: 0
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .accordion-panel-header button::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  transition: transform var(--he-transition)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .accordion-panel-header.isOpen button::after {
  transform: rotate(180deg)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .accordion-panel-content {
  display: var(--accordion-display) !important;
  background-color: var(--color--ui-background-med, var(--color--background-medium));
  padding: var(--spacing-10)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .accordion-panel-content .link-list ul {
  margin-bottom: 0
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .accordion-panel-content .link-list ul li {
  margin-top: 0
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .accordion-panel-content .link-list ul li a {
  padding: var(--spacing-10) var(--spacing-15);
  font-size: var(--typesize-minor);
  color: var(--he-mobile-nav-base-text-color);
  line-height: var(--leading--1-5);
  font-weight: var(--typeweight--default)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .support-accordion {
  background-color: var(--color--ui-background-gray, var(--color--background-gray));
  margin-top: -0.1px
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .support-accordion .accordion-panel {
  border: 0;
  margin: 0;
  background: 0
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .support-accordion .accordion-panel-header {
  padding: 0
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .support-accordion .accordion-panel-header button {
  padding: var(--spacing-20) var(--spacing-20);
  border: 0;
  font-weight: var(--typeweight--default)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .support-accordion .accordion-panel-header button>span {
  font-weight: var(--typeweight--default)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .support-accordion .accordion-panel-header button::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-help-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-help-lg");
  margin-left: 0;
  margin-right: var(--spacing-10)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .support-accordion .accordion-panel-header button::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  transition: transform var(--he-transition)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .support-accordion .accordion-panel-header.isOpen button::after {
  transform: rotate(180deg)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .text {
  margin-top: 0
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .text a {
  padding: var(--spacing-15) var(--spacing-20);
  font-family: var(--typefamily-primary);
  font-size: var(--he-mobile-nav-base-text-size);
  color: var(--he-mobile-nav-base-text-color);
  line-height: var(--leading--1-2);
  font-weight: var(--typeweight--semibold-plus)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .text h2,
.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .text h3 {
  padding: var(--spacing-20) var(--spacing-20) var(--spacing-30) var(--spacing-20);
  font-size: var(--typesize-h4);
  line-height: var(--leading--1-2);
  font-weight: var(--typeweight--default);
  border-bottom: 1px solid var(--color--ui-border-01, var(--color--border-01))
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .text.mobile-nav--back a {
  display: flex;
  align-items: center;
  padding: var(--spacing-20) var(--spacing-15);
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-3);
  color: var(--color--text-secondary)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .text.mobile-nav--back a::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  margin-right: var(--spacing-10)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .text .cart-link {
  display: flex;
  position: relative;
  align-items: center;
  background-color: var(--color--ui-background-gray, var(--color--background-gray));
  padding: var(--spacing-20) var(--spacing-20);
  border: 0;
  font-weight: var(--typeweight--default)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .text .cart-link .cart-products-indicator {
  margin-left: var(--spacing-20);
  margin-bottom: var(--spacing-20)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .text .cart-link::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-cart-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-cart-lg");
  margin-right: var(--spacing-10)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .text.explore-link-text {
  margin-top: var(--spacing-10)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .text.explore-link-text a {
  display: block;
  line-height: var(--he-mobile-nav-base-text-line)
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav>.navbar-collapse .text.explore-link-text+.mega-nav .navbar-nav {
  padding-top: 0
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-collapse[data-level][data-level="0"] {
  --he-mobile-nav-level: 0
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-collapse[data-level][data-level="1"] {
  --he-mobile-nav-level: -1
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-collapse[data-level][data-level="2"] {
  --he-mobile-nav-level: -2
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-collapse[data-level][data-level="3"] {
  --he-mobile-nav-level: -3
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-collapse[data-level][data-level="4"] {
  --he-mobile-nav-level: -4
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-collapse[data-level][data-level="5"] {
  --he-mobile-nav-level: -5
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-collapse[data-level][data-level="6"] {
  --he-mobile-nav-level: -6
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-collapse[data-level][data-level="7"] {
  --he-mobile-nav-level: -7
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-collapse[data-level][data-level="8"] {
  --he-mobile-nav-level: -8
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-collapse[data-level][data-level="9"] {
  --he-mobile-nav-level: -9
}

.he-pr-nav__wrapper .mega-nav.he-header-mobile-nav .navbar-collapse[data-level][data-level="10"] {
  --he-mobile-nav-level: -10
}

.he-header-desktop-nav .navbar-collapse>.mega-nav-global-links {
  display: var(--he-header-display-global-link-mobile)
}

.he-header-mobile-nav .navbar-collapse>.mega-nav-global-links {
  display: var(--he-header-display-global-link-desktop)
}

.he-header-mobile-nav .navbar-collapse.isOpen>.mega-nav-global-links {
  display: var(--he-header-display-global-link-mobile)
}

.dropdown-menu .mega-nav-global-links {
  display: var(--he-header-display-global-link-desktop)
}

.mega-nav-global-links {
  margin-top: var(--spacing-45)
}

.mega-nav-global-links .link-list ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr
}

.mega-nav-global-links .link-list a {
  color: var(--color--text-link);
  font-weight: var(--typeweight--bold);
  display: flex;
  align-items: center
}

.mega-nav-global-links .link-list a::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-linkout-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-linkout-md");
  margin-left: var(--spacing-10)
}

@media screen and (max-width:991px) {
  .mega-nav-global-links .container {
    padding: 0
  }

  .mega-nav-global-links .row {
    margin: 0
  }

  .mega-nav-global-links .col-12 {
    padding: 0
  }

  .mega-nav-global-links .link-list ul {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    margin: 0
  }
}

.he-icon-dropdown {
  padding: 0
}

.he-icon-dropdown .navbar-collapse.isOpen {
  display: block;
  position: absolute;
  top: calc(100% + 12px);
  margin-top: 0;
  background-color: var(--color--ui-background-light, var(--color--background-light));
  padding: var(--spacing-20);
  border-radius: var(--corners-semiround-sm);
  box-shadow: var(--shadow);
  min-width: var(--he-header-width-dropdown);
  max-width: var(--he-header-mx-w-dropdown);
  z-index: 3
}

.he-icon-dropdown .navbar-collapse.isOpen::before {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  top: -17px;
  right: 11px;
  border: 12px solid transparent;
  border-bottom-color: currentColor
}

@media(width:992px) {
  .he-icon-dropdown .navbar-collapse.isOpen::before {
    top: -11px
  }
}

.he-icon-dropdown .navbar-collapse.isOpen .dropdown {
  margin-top: 16px;
  width: 100%;
  overflow: hidden
}

.he-icon-dropdown .navbar-collapse.isOpen .dropdown:first-child {
  margin-top: 0;
  word-break: break-all
}

.he-icon-dropdown .navbar-collapse.isOpen .dropdown .dropdown-title {
  color: var(--he-header-link-color-inverse);
  font-weight: var(--typeweight--bold);
  font-size: var(--typesize-default);
  word-break: break-all
}

.he-icon-dropdown .navbar-collapse.isOpen .dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-5);
  color: var(--he-header-link-color-inverse)
}

.he-icon-dropdown .navbar-collapse.isOpen .dropdown .dropdown-toggle::after {
  content: unset
}

.he-icon-dropdown .navbar-collapse.isOpen .dropdown .dropdown-toggle[aria-disabled] {
  opacity: .5;
  pointer-events: none
}

.he-icon-dropdown.on-left .navbar-collapse.isOpen {
  right: -11px
}

ol,
ul {
  padding: 0 0 0 20px;
  margin: 0
}

*+ol,
*+ul {
  margin: 20px 0 0 0
}

ol li+li,
ul li+li {
  margin-block-start: .5em
}

header ol,
footer ol,
header ul,
footer ul,
header li,
footer li {
  padding: 0;
  margin: 0
}

header li+li,
footer li+li {
  margin: 0
}

header.isSticky {
  pointer-events: none
}

header.isSticky>* {
  pointer-events: all
}

.he-pr-nav__wrapper {
  position: var(--he-headerPosition);
  width: 100%;
  background-color: var(--he-header-bg-color);
  color: var(--color--text-primary-inverse);
  transition: background-color .3s ease-in-out;
  transform: translateY(var(--he-header-y-shift));
  transition: transform .125s ease-in-out;
  z-index: 3
}

.he-pr-nav__wrapper .column-control,
.he-pr-nav__wrapper a {
  transition: none !important
}

.he-pr-nav__wrapper .container {
  max-width: unset;
  padding: 0
}

.he-pr-nav__wrapper>.container {
  padding: var(--headerContainerSpacingY) var(--headerContainerSpacingX);
  position: relative
}

.he-pr-nav__wrapper>.container>.row>.col-12 {
  display: flex;
  column-gap: var(--he-header-gaps);
  align-items: center;
  position: unset;
  min-height: var(--he-header-min-h)
}

.he-pr-nav__wrapper .header__logo {
  height: var(--he-header-logo-height);
  width: var(--he-header-logo-width)
}

.he-pr-nav__wrapper .header__logo #logo,
.he-pr-nav__wrapper .header__logo picture {
  filter: var(--he-logo-filter-color);
  display: block
}

.he-pr-nav__wrapper .dropdown-menu .col-12:last-child ul {
  margin-bottom: 0
}

.he-pr-nav__wrapper .reduced-state-message {
  display: var(--he-headrer-display-reduced-message);
  align-items: center;
  justify-self: flex-end;
  font-size: var(--typesize-minor);
  color: var(--he-color-text-secure-checkout);
  margin: 0 0 0 auto
}

.he-pr-nav__wrapper .reduced-state-message::after {
  margin-left: var(--spacing-10);
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-locked-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-locked-md")
}

.he-pr-nav__wrapper .he-header-icon-navigation {
  display: var(--he-headrer-display-icon-nav-message);
  flex-grow: var(--he-header-icon-nav-grow);
  justify-content: flex-end;
  padding: 0;
  margin-inline-start: auto
}

.he-pr-nav__wrapper .he-header-icon-navigation .col-12>.section {
  display: flex;
  align-items: center
}

.he-pr-nav__wrapper .he-header-icon-navigation .col-12>.section .mega-nav {
  margin-right: var(--spacing-20)
}

.he-pr-nav__wrapper .he-header-icon-navigation .userNav__button {
  padding: 5px 18px;
  margin: 0
}

.he-pr-nav__wrapper .he-header-icon-navigation .userNav__button:before {
  background: var(--color-sign-in-button);
  border-radius: 40px
}

.he-pr-nav__wrapper .custom-header .container {
  position: initial
}

.he-pr-nav__wrapper .custom-header .row {
  margin: 0
}

.he-pr-nav__wrapper .custom-header .usernav-signin-button {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary-inverse);
  white-space: nowrap
}

@media(min-width:376px) {
  .he-pr-nav__wrapper .custom-header .usernav-signin-button {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .he-pr-nav__wrapper .custom-header .usernav-signin-button {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.he-pr-nav__wrapper .custom-header .usernav-signin-button::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.he-pr-nav__wrapper .custom-header .usernav-signin-button:hover,
.he-pr-nav__wrapper .custom-header .usernav-signin-button:focus,
.he-pr-nav__wrapper .custom-header .usernav-signin-button:focus-visible,
.he-pr-nav__wrapper .custom-header .usernav-signin-button:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.he-pr-nav__wrapper .custom-header .usernav-signin-button:hover::before,
.he-pr-nav__wrapper .custom-header .usernav-signin-button:focus::before,
.he-pr-nav__wrapper .custom-header .usernav-signin-button:focus-visible::before,
.he-pr-nav__wrapper .custom-header .usernav-signin-button:active::before {
  transform: scale(1.05)
}

.he-pr-nav__wrapper .custom-header .usernav-signin-button:focus,
.he-pr-nav__wrapper .custom-header .usernav-signin-button:focus-visible {
  outline: 0
}

.he-pr-nav__wrapper .custom-header .usernav-signin-button:focus::before,
.he-pr-nav__wrapper .custom-header .usernav-signin-button:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.he-pr-nav__wrapper .custom-header .usernav-signin-button:active::before {
  outline-color: transparent
}

.he-pr-nav__wrapper .custom-header .usernav-signin-button:only-of-type {
  margin: 0
}

.he-pr-nav__wrapper .custom-header .usernav-signin-button.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.he-pr-nav__wrapper .custom-header .usernav-signin-button::before {
  background: var(--color--interactive-primary-inverse)
}

.he-pr-nav__wrapper .custom-header .usernav-signin-button:hover {
  color: var(--color--text-primary-inverse)
}

.he-pr-nav__wrapper .custom-header .usernav-signin-button:hover::before {
  background: var(--color--interactive-primary-inverse-hover, var(--color--interactive-primary-hover-inverse))
}

.he-pr-nav__wrapper .custom-header .usernav-signin-button:focus,
.he-pr-nav__wrapper .custom-header .usernav-signin-button:focus-visible {
  color: var(--color--text-primary-inverse)
}

.he-pr-nav__wrapper .custom-header .usernav-signin-button:focus::before,
.he-pr-nav__wrapper .custom-header .usernav-signin-button:focus-visible::before {
  background: var(--color--interactive-primary-inverse)
}

.he-pr-nav__wrapper .custom-header .usernav-signin-button:active {
  color: var(--color--text-primary-inverse)
}

.he-pr-nav__wrapper .custom-header .usernav-signin-button:active::before {
  background: var(--color--interactive-primary-inverse-active, var(--color--interactive-primary-active-inverse))
}

.he-pr-nav__wrapper .custom-header .usernav-signin-button[disabled],
.he-pr-nav__wrapper .custom-header .usernav-signin-button.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02);
  color: var(--color--interactive-disabled-03)
}

.he-pr-nav__wrapper .custom-header .usernav-signin-button[disabled]::before,
.he-pr-nav__wrapper .custom-header .usernav-signin-button.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.he-pr-nav__wrapper .custom-header .usernav-signin-button[disabled]:hover::before,
.he-pr-nav__wrapper .custom-header .usernav-signin-button.button-disabled:hover::before,
.he-pr-nav__wrapper .custom-header .usernav-signin-button[disabled]:focus::before,
.he-pr-nav__wrapper .custom-header .usernav-signin-button.button-disabled:focus::before,
.he-pr-nav__wrapper .custom-header .usernav-signin-button[disabled]:focus-visible::before,
.he-pr-nav__wrapper .custom-header .usernav-signin-button.button-disabled:focus-visible::before,
.he-pr-nav__wrapper .custom-header .usernav-signin-button[disabled]:active::before,
.he-pr-nav__wrapper .custom-header .usernav-signin-button.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.he-pr-nav__wrapper .custom-header .usernav-signin-button[disabled]::before,
.he-pr-nav__wrapper .custom-header .usernav-signin-button.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.he-pr-nav__wrapper .custom-header .usernav-signin-button[disabled]:hover::before,
.he-pr-nav__wrapper .custom-header .usernav-signin-button.button-disabled:hover::before,
.he-pr-nav__wrapper .custom-header .usernav-signin-button[disabled]:focus::before,
.he-pr-nav__wrapper .custom-header .usernav-signin-button.button-disabled:focus::before,
.he-pr-nav__wrapper .custom-header .usernav-signin-button[disabled]:focus-visible::before,
.he-pr-nav__wrapper .custom-header .usernav-signin-button.button-disabled:focus-visible::before,
.he-pr-nav__wrapper .custom-header .usernav-signin-button[disabled]:active::before,
.he-pr-nav__wrapper .custom-header .usernav-signin-button.button-disabled:active::before {
  background: var(--color--interactive-disabled-01)
}

.he-pr-nav__wrapper #he-ies-signin-react-modal {
  margin: 0
}

.he-pr-nav__wrapper .he-icon-dropdown .navbar-toggle {
  background: transparent;
  border: 0;
  color: var(--he-text-color-global)
}

.he-pr-nav__wrapper .he-icon-dropdown.he-header-help-nav {
  display: var(--he-header-display-additional)
}

.he-pr-nav__wrapper .he-icon-dropdown.he-header-help-nav .navbar-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--he-header-nav-icon-size);
  padding: 0
}

.he-pr-nav__wrapper .he-icon-dropdown.he-header-help-nav .navbar-toggle .fa {
  display: none
}

.he-pr-nav__wrapper .he-icon-dropdown.he-header-help-nav .navbar-toggle::before {
  content: '';
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-help-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-support.svg#icon-help-lg")
}

.he-pr-nav__wrapper .he-icon-dropdown.he-header-user-nav .navbar-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid currentColor;
  border-radius: 100%;
  width: 36px;
  height: 36px;
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-5);
  padding: unset;
  transition: all .3s ease-in-out
}

.he-pr-nav__wrapper .he-icon-dropdown.he-header-user-nav .navbar-toggle::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  --transform-carret: translateY(-50%);
  position: absolute;
  top: 50%;
  right: -23px;
  color: var(--he-text-color-global);
  transform: var(--transform-carret);
  transition: transform .3s ease-in-out
}

.he-pr-nav__wrapper .he-icon-dropdown.he-header-user-nav .navbar-toggle.is-expanded,
.he-pr-nav__wrapper .he-icon-dropdown.he-header-user-nav .navbar-toggle[aria-expanded="true"] {
  color: var(--he-header-usernav-inverse);
  background-color: var(--he-text-color-global);
  border: 0
}

.he-pr-nav__wrapper .he-icon-dropdown.he-header-user-nav .navbar-toggle.is-expanded::after,
.he-pr-nav__wrapper .he-icon-dropdown.he-header-user-nav .navbar-toggle[aria-expanded="true"]::after {
  transform: var(--transform-carret) rotate(180deg)
}

.he-pr-nav__wrapper .he-icon-dropdown.he-header-user-nav .navbar-collapse.isOpen {
  right: -6px
}

.he-pr-nav__wrapper .he-icon-dropdown.he-header-user-nav .navbar-nav .nav-divider {
  border-bottom: 1px solid var(--he-header-nav-divider-color);
  margin: var(--spacing-20) calc(var(--spacing-20) * -1) 0 calc(var(--spacing-20) * -1)
}

.he-pr-nav__wrapper .he-icon-dropdown.he-header-user-nav .navbar-nav .dropdown-toggle.link-out::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-linkout-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-linkout-md");
  margin-left: var(--spacing-5)
}

.he-pr-nav__wrapper .he-header-desktop-cart {
  display: var(--he-header-display-additional);
  align-items: center;
  justify-content: center;
  margin-right: var(--spacing-20)
}

.he-pr-nav__wrapper .he-header-desktop-cart .cart-link {
  display: block;
  position: relative;
  width: var(--he-header-cart-icon-size);
  height: var(--he-header-cart-icon-size);
  color: var(--he-text-color-global)
}

.he-pr-nav__wrapper .he-header-desktop-cart .cart-link::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-cart-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-ecommerce.svg#icon-cart-lg")
}

.he-pr-nav__wrapper .cart-products-indicator {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: var(--he-header-cart-icon-size);
  left: var(--he-header-cart-icon-size);
  min-width: 18px;
  height: 18px;
  padding: 0 6px;
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-micro);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-5);
  color: var(--color--text-primary);
  background-color: var(--color--accent-01-light);
  transform: translate(-50%, 50%);
  border-radius: 9px
}

.he-pr-nav__wrapper .cart-products-indicator.hasItems {
  display: flex
}

.he-pr-nav__wrapper .custom-header #custom-header {
  margin: 0
}

.he-pr-nav__wrapper .link-list #mainmenu {
  margin: 0;
  font-weight: var(--typeweight--bold);
  color: var(--color--text-link)
}

.he-pr-nav__wrapper .link-list h1,
.he-pr-nav__wrapper .link-list h2,
.he-pr-nav__wrapper .link-list h3,
.he-pr-nav__wrapper .link-list h4,
.he-pr-nav__wrapper .link-list h5,
.he-pr-nav__wrapper .link-list h6 {
  color: var(--color--text-secondary);
  padding-bottom: var(--spacing-15);
  border-bottom: 1px solid var(--color--text-border)
}

.he-pr-nav__wrapper .link-list ul {
  margin-bottom: var(--spacing-30)
}

.he-pr-nav__wrapper .link-list li {
  margin: var(--spacing-20) 0 0;
  border: 0;
  list-style: none
}

.he-pr-nav__wrapper .link-list a {
  display: block;
  word-break: normal;
  font-size: var(--typesize-minor);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-5);
  color: var(--color--text-tertiary);
  padding: 0
}

@media(max-width:991px) {
  .he-pr-nav__wrapper span.accordion-panel-header__text {
    display: none
  }

  .he-pr-nav__wrapper .mega-nav .dropdown {
    position: static
  }

  .he-pr-nav__wrapper .dropdown-menu .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr
  }

  .he-pr-nav__wrapper .dropdown-menu .row .col-12 {
    width: 100%;
    max-width: 100%;
    flex: initial;
    margin-bottom: var(--spacing-45)
  }

  .he-pr-nav__wrapper .link-list li {
    padding: 0
  }
}

@media(max-width:767px) {
  .he-pr-nav__wrapper .dropdown-menu .row {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr
  }

  .he-pr-nav__wrapper .dropdown-menu .row .col-12 {
    margin-bottom: var(--spacing-30)
  }

  .he-pr-nav__wrapper .dropdown-menu .row .col-12:last-child {
    margin-bottom: var(--spacing-45)
  }
}

.he-pr-nav__wrapper.reduced {
  --he-headrer-display-reduced-message: flex;
  --he-headrer-display-icon-nav-message: none;
  --he-header-desktop-nav-display: none;
  --he-header-mobile-nav-display: none;
  --he-heaader-display-search: none;
  --he-header-grid: 134px 1fr
}

.he-pr-nav__wrapper.no-search {
  --he-header-grid: auto auto 1fr;
  --he-header-icon-nav-grow: 1
}

.he-pr-nav__wrapper.no-search .educator-nav__search {
  display: none
}

.he-pr-nav__wrapper div.educator-nav__search {
  flex: 1 1 auto;
  margin-right: auto;
  margin-left: auto;
  max-width: 75%
}

.he-pr-nav__wrapper div.educator-nav__search form .ais-SearchBox-input:hover~.search-box-icon::before {
  color: var(--color--interactive-primary-hover)
}

.he-pr-nav__wrapper div.educator-nav__search form .search-box-icon::before {
  color: var(--he-text-color-global)
}

.he-pr-nav__wrapper div.educator-nav__search .userNav__search::before {
  background: var(--he-text-color-global)
}

.he-pr-nav__wrapper div.c-nav__search form .search-box-input {
  color: var(--he-inverse-text-primary1)
}

.he-pr-nav__wrapper div.c-nav__search form .search-box-input::placeholder {
  color: var(--he-inverse-text-primary1)
}

.he-pr-nav__wrapper div.c-nav__search form .search-box-input:focus {
  color: var(--he-text-secondary1)
}

.he-pr-nav__wrapper div.c-nav__search form .search-box-input:focus::placeholder {
  color: var(--he-text-secondary1)
}

.he-pr-nav__wrapper div.c-nav__search form .search-box-input:hover {
  color: var(--he-text-secondary1)
}

.he-pr-nav__wrapper div.c-nav__search form .search-box-input:hover::placeholder {
  color: var(--he-text-secondary1)
}

.he-pr-nav__wrapper div.c-nav__search form .search-box-input .ui-autocomplete-open {
  color: var(--he-text-secondary1)
}

@media all and (max-width:1024px) {
  .he-pr-nav__wrapper .educator-nav__search .search-box {
    display: none
  }
}

.he-pr-nav__wrapper .educator-nav__search .search-box.isOpen {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1001;
  background: var(--color--ui-02);
  padding: 20px
}

.he-pr-nav__wrapper .educator-nav__search .ui-autocomplete .ui-menu-item__result-count {
  padding: 0
}

.he-pr-nav__wrapper .educator-nav__search .ui-autocomplete .ui-menu-item__found-and-view {
  display: flex;
  width: 100%;
  justify-content: space-between
}

@media all and (max-width:1024px) {
  .he-pr-nav__wrapper .educator-nav__search .ui-autocomplete {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    border-top: 0;
    margin-top: 0
  }

  .he-pr-nav__wrapper .educator-nav__search .ui-autocomplete li {
    position: relative;
    z-index: 11;
    padding-top: 0
  }

  .he-pr-nav__wrapper .educator-nav__search .ui-autocomplete::after {
    content: "";
    position: fixed;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: var(--color--ui-02)
  }
}

.he-pr-nav__wrapper .educator-nav__search label {
  color: var(--color--text-primary-inverse);
  margin-bottom: var(--spacing-15)
}

.he-pr-nav__wrapper .educator-nav__search .search-box-input:hover {
  color: var(--color--text-primary)
}

.he-pr-nav__wrapper .educator-nav__search form .ais-SearchBox-input {
  background-color: rgba(247, 249, 253, 0.35);
  color: var(--color--text-secondary-inverse);
  box-shadow: unset;
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  outline-width: 0;
  outline-style: solid;
  outline-offset: -1px
}

@media all and (max-width:1024px) {
  .he-pr-nav__wrapper .educator-nav__search form .ais-SearchBox-input {
    outline-color: var(--color--ui-border-02, var(--color--border-02))
  }
}

@media all and (max-width:1024px) {
  .he-pr-nav__wrapper .educator-nav__search form .ais-SearchBox-input.ui-autocomplete-open {
    border-radius: 40px
  }
}

.he-pr-nav__wrapper .educator-nav__search form .ais-SearchBox-input:hover {
  background-color: var(--color--ui-02)
}

.he-pr-nav__wrapper .educator-nav__search form .ais-SearchBox-input:active,
.he-pr-nav__wrapper .educator-nav__search form .ais-SearchBox-input:focus {
  background-color: var(--color--ui-02);
  color: var(--color--text-primary);
  outline-width: 3px;
  outline-color: var(--color--interactive-focus);
  outline-offset: -2px
}

.he-pr-nav__wrapper .educator-nav__search form .ais-SearchBox-input::-webkit-input-placeholder,
.he-pr-nav__wrapper .educator-nav__search form .ais-SearchBox-input::placeholder {
  color: var(--color--text-secondary-inverse)
}

@media all and (max-width:1024px) {

  .he-pr-nav__wrapper .educator-nav__search form .ais-SearchBox-input::-webkit-input-placeholder,
  .he-pr-nav__wrapper .educator-nav__search form .ais-SearchBox-input::placeholder {
    color: var(--color--text-secondary)
  }
}

.he-pr-nav__wrapper .educator-nav__search form .ais-SearchBox-input:hover::-webkit-input-placeholder,
.he-pr-nav__wrapper .educator-nav__search form .ais-SearchBox-input:focus::-webkit-input-placeholder,
.he-pr-nav__wrapper .educator-nav__search form .ais-SearchBox-input:hover::placeholder,
.he-pr-nav__wrapper .educator-nav__search form .ais-SearchBox-input:focus::placeholder {
  color: var(--color--text-primary)
}

.he-pr-nav__wrapper .educator-nav__search form .ais-SearchBox-input:hover~.search-box-icon::before {
  color: var(--color--interactive-primary)
}

.he-pr-nav__wrapper .educator-nav__search form .ais-SearchBox-input:focus~.search-box-icon::before {
  color: var(--color--text-primary)
}

.he-pr-nav__wrapper .educator-nav__search form .search-box-icon {
  right: 16px;
  cursor: pointer
}

.he-pr-nav__wrapper .educator-nav__search form .search-box-icon::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-sm");
  color: var(--color--icon-primary-inverse)
}

@media all and (max-width:1024px) {
  .he-pr-nav__wrapper .educator-nav__search form .search-box-icon::before {
    color: var(--color--text-secondary)
  }
}

.he-pr-nav__wrapper .educator-nav__search .closeBtn {
  display: none;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  margin: 0 var(--spacing-10) 0 calc(-1 * var(--spacing-5));
  padding: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent
}

@media all and (max-width:1024px) {
  .he-pr-nav__wrapper .educator-nav__search .closeBtn {
    display: flex
  }
}

.he-pr-nav__wrapper .educator-nav__search .closeBtn::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-lg")
}

.he-pr-nav__wrapper .educator-nav__search .userNav__search {
  display: none;
  border: 0;
  border-radius: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  background: transparent;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer
}

.he-pr-nav__wrapper .educator-nav__search .userNav__search::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-lg");
  background: var(--color--ui-background-light, var(--color--background-light))
}

@media all and (max-width:1024px) {
  .he-pr-nav__wrapper .educator-nav__search .userNav__search {
    display: inline-block
  }
}

.he-pr-nav__wrapper .educator-nav__search .search-subject__title,
.he-pr-nav__wrapper .educator-nav__search .search-product__title,
.he-pr-nav__wrapper .educator-nav__search .search-product__author,
.he-pr-nav__wrapper .educator-nav__search .search-product__footer,
.he-pr-nav__wrapper .educator-nav__search .search-product__price,
.he-pr-nav__wrapper .educator-nav__search .search-product__price {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-minor);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-5);
  color: var(--color--text-secondary)
}

.he-pr-nav__wrapper .educator-nav__search .search-product__author,
.he-pr-nav__wrapper .educator-nav__search .search-product__footer {
  font-size: var(--typesize-milli);
  color: var(--color--text-tertiary);
  margin-bottom: 3px
}

.he-pr-nav__wrapper .educator-nav__search .search-product__author:last-child,
.he-pr-nav__wrapper .educator-nav__search .search-product__footer:last-child {
  margin-bottom: 0
}

.he-pr-nav__wrapper .educator-nav__search .search-product__price {
  line-height: var(--leading--1-2)
}

.he-pr-nav__wrapper .educator-nav__search .search-product__title {
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  padding-bottom: .125em;
  text-overflow: ellipsis
}

.he-pr-nav__wrapper .educator-nav__search .search-product__author {
  -webkit-line-clamp: 2
}

.he-pr-nav__wrapper .educator-nav__search .search-product__footer {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.he-pr-nav__wrapper .educator-nav__search .search-product__footer div {
  display: flex
}

.he-pr-nav__wrapper .educator-nav__search .search-product__footer div .search-product__old-price {
  font-size: var(--typesize-milli);
  color: var(--color--ui-boulder-gray);
  text-decoration: line-through;
  margin-right: 5px;
  padding: 4px 0
}

.he-pr-nav__wrapper .educator-nav__search .search-product__footer div .search-product__price {
  padding: 2px 0
}

.he-pr-nav__wrapper .educator-nav__search a.search-product.ui-menu-item {
  padding: 12px 16px
}

.he-pr-nav__wrapper .educator-nav__search a.search-product.ui-menu-item:hover {
  background: var(--color--ui-background-med, var(--color--background-medium))
}

@media all and (max-width:768px) {
  .he-pr-nav__wrapper .educator-nav__search a.search-product.ui-menu-item {
    padding: 12px 20px
  }
}

.he-pr-nav__wrapper .educator-nav__search ul li+li {
  margin-top: 0
}

.he-pr-nav__wrapper .educator-nav__search a.ui-menu-item__result-text {
  display: block
}

.he-pr-nav__wrapper .recent-searches {
  padding-bottom: 15px
}

.he-pr-nav__wrapper .recent-searches [tabindex]:focus,
.he-pr-nav__wrapper .recent-searches [tabindex]:focus-visible,
.he-pr-nav__wrapper .recent-searches [tabindex]:focus-within {
  outline: 2px solid var(--color--interactive-focus) !important
}

.he-pr-nav__wrapper .recent-searches li:first-child {
  display: block;
  z-index: 12
}

.he-pr-nav__wrapper .recent-searches .ui-menu-item__recently-viewed {
  padding-left: 0 !important
}

.he-pr-nav__wrapper .recent-searches li {
  padding-left: 15px
}

@media all and (max-width:1024px) {
  .he-pr-nav__wrapper .recent-searches li {
    padding-left: 20px
  }
}

.he-pr-nav__wrapper .recent-searches li:first-child {
  padding-left: 0
}

@media all and (max-width:1024px) {
  .he-pr-nav__wrapper .recent-searches li:last-child {
    padding: 5px 20px 15px 20px
  }
}

.he-pr-nav__wrapper .recent-searches .ui-menu-item__recent-search-text {
  height: 40px
}

.he-pr-nav__wrapper .recent-searches .ui-menu-item__recent-search-text h6 {
  line-height: 40px
}

.he-pr-nav__wrapper .recent-searches .ui-menu-item__recent-searches:hover {
  background-color: var(--color--interactive-secondary-inverse-hover, var(--color--interactive-secondary-hover-inverse));
  border-radius: var(--corners-semiround-sm);
  text-decoration: none
}

.he-pr-nav__wrapper .recent-searches .ui-menu-item__clear-history {
  position: absolute;
  right: 20px;
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--bold);
  font-size: var(--typesize-p);
  line-height: 40px;
  height: 40px;
  border: 0;
  background: 0;
  color: var(--color--text-link)
}

.he-pr-nav__wrapper .recent-searches .recent-search__title {
  color: var(--color--text-primary);
  height: 32px;
  line-height: 32px;
  margin-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1
}

.he-pr-nav__wrapper .recent-searches .search-product__title {
  margin-top: 0
}

.he-pr-nav__wrapper .close-recents-btn {
  width: 18px;
  height: 18px;
  top: 11px;
  right: 19px;
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  border: 0;
  background: transparent
}

.he-pr-nav__wrapper .close-recents-btn.hide {
  display: none
}

@media all and (max-width:1024px) {
  .he-pr-nav__wrapper .close-recents-btn {
    right: 33px;
    top: 31px
  }
}

.he-pr-nav__wrapper .close-recents-btn::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md")
}

.he-pr-nav__wrapper .close-recents-btn:hover {
  border-radius: 50%;
  background-color: var(--color--interactive-secondary-inverse-hover, var(--color--interactive-secondary-hover-inverse))
}

.he-pr-nav__wrapper .c-nav__search.c-hero__search .search-box-input.ui-autocomplete-open {
  border-radius: 8px 8px 0 0;
  outline-color: unset
}

.he-pr-nav__wrapper .c-nav__search form .search-box-input.ui-autocomplete-open {
  background: var(--color--ui-02);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25) !important
}

@media all and (min-width:769px) {
  .he-pr-nav__wrapper .c-nav__search form .search-box-input.ui-autocomplete-open {
    border-radius: 8px 8px 0 0;
    padding-left: 16px
  }
}

.he-pr-nav__wrapper .c-nav__search form .search-close-btn {
  align-items: center;
  background: transparent;
  border: 0;
  border-radius: 50%;
  bottom: 8px;
  height: 24px;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: 8px;
  width: 24px;
  z-index: 2
}

.he-pr-nav__wrapper .c-nav__search form .search-close-btn::before {
  background-color: var(--color--ui-06);
  border-radius: 50%;
  bottom: auto;
  content: "";
  display: block;
  flex: 0 0 auto;
  height: 24px;
  left: auto;
  position: absolute;
  right: auto;
  top: auto;
  transform: scale(0);
  transition: transform .125s ease-in-out;
  width: 24px;
  z-index: -1
}

.he-pr-nav__wrapper .c-nav__search form .search-close-btn::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md")
}

.he-pr-nav__wrapper .c-nav__search form .search-close-btn:hover::before,
.he-pr-nav__wrapper .c-nav__search form .search-close-btn:focus::before,
.he-pr-nav__wrapper .c-nav__search form .search-close-btn:active::before {
  transform: scale(1)
}

.he-pr-nav__wrapper .c-nav__search form .search-close-btn:focus {
  outline: 1px solid var(--color--interactive-focus)
}

.he-pr-nav__wrapper .c-nav__search form .search-close-btn.show {
  display: flex
}

.he-pr-nav__wrapper .c-nav__search form .search-close-btn>svg {
  display: none
}

.he-pr-nav__wrapper .search-close-btn {
  display: none
}

.he-pr-nav__wrapper .isOpen>.search-close-btn {
  display: flex
}

.he-pr-nav__wrapper .ui-autocomplete-open~.search-close-btn {
  display: flex
}

.he-pr-nav__wrapper .ui-autocomplete-open~.search-box-icon {
  display: none
}

.he-pr-nav__wrapper .ui-autocomplete {
  background: var(--color--ui-02);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: var(--color--text-primary)
}

.he-pr-nav__wrapper .ui-autocomplete ul {
  list-style-type: none;
  padding: 0;
  margin: 0
}

@media all and (max-width:768px) {
  .he-pr-nav__wrapper .ui-autocomplete {
    height: calc(var(--vh, 1vh) * 100 - 64px);
    left: 0;
    position: absolute;
    right: 0;
    top: 52px;
    z-index: 11
  }
}

@media all and (min-width:769px) {
  .he-pr-nav__wrapper .ui-autocomplete {
    border-radius: 0 0 8px 8px;
    position: absolute;
    width: 100%;
    z-index: 10
  }
}

.he-pr-nav__wrapper .ui-autocomplete mark {
  background: 0;
  font-weight: var(--typeweight--semibold);
  padding: 0
}

.he-pr-nav__wrapper .ui-autocomplete .ui-menu-item__result-count {
  padding: .625rem 1rem;
  display: inline-block;
  width: fit-content
}

.he-pr-nav__wrapper .ui-autocomplete .ui-menu-item__result-text {
  display: none
}

.he-pr-nav__wrapper .ui-autocomplete .search-subject_group__wrapper {
  border-top: 1px solid var(--color--ui-border-01, var(--color--border-01))
}

.he-pr-nav__wrapper .ui-autocomplete .search-subject_group__wrapper .search-subject_group__heading {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-minor);
  font-weight: var(--typeweight--semibold);
  color: var(--color--text-tertiary);
  padding: 16px 16px 4px 16px
}

.he-pr-nav__wrapper .ui-autocomplete .search-subject_group__wrapper:first-child {
  border-top: 0
}

.he-pr-nav__wrapper .ui-autocomplete .search-subject_group__wrapper:first-child .search-subject_group__heading {
  padding-top: 12px
}

.he-pr-nav__wrapper.c-hero__search .ui-autocomplete {
  border-radius: 0 0 8px 8px;
  top: auto
}

@media all and (max-width:768px) {
  .he-pr-nav__wrapper li.ui-menu-item:first-child {
    padding-top: 10px
  }
}

.he-pr-nav__wrapper a.ui-menu-item.ui-menu-item {
  font-weight: var(--typestyle-normal);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: var(--leading--1-5);
  padding: 12px 16px
}

@media all and (min-width:769px) {
  .he-pr-nav__wrapper a.ui-menu-item.ui-menu-item {
    margin: 0
  }
}

.he-pr-nav__wrapper a.ui-menu-item.ui-menu-item:hover,
.he-pr-nav__wrapper a.ui-menu-item.ui-menu-item:focus {
  background-color: var(--color--ui-01);
  text-decoration: none
}

.he-pr-nav__wrapper a.ui-menu-item.ui-menu-item .search-subject__title {
  display: flex;
  align-items: center
}

.he-pr-nav__wrapper a.ui-menu-item.ui-menu-item .search-subject__pagename {
  display: inline-flex;
  align-items: center;
  color: var(--color--accent-01-medium);
  margin-left: 5px
}

.he-pr-nav__wrapper a.ui-menu-item.ui-menu-item .search-subject__pagename::before {
  content: '';
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-sm");
  transform: rotate(180deg);
  color: var(--color--text-primary);
  opacity: .59;
  margin-right: 5px;
  width: 14px;
  height: 14px;
  mask-size: 10px
}

.he-pr-nav__wrapper .search-product__image {
  border-radius: 8px;
  flex-shrink: 0;
  margin-right: 16px;
  overflow: hidden
}

.he-pr-nav__wrapper .search-product__image>img {
  height: auto;
  width: 50px
}

.he-pr-nav__wrapper .search-product__content {
  flex-grow: 1;
  flex-shrink: 1
}

.he-pr-nav__wrapper .search-product__title {
  color: var(--color--text-primary);
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-default);
  font-weight: var(--typestyle-normal);
  line-height: var(--leading--1-5);
  margin-bottom: 4px
}

@media all and (max-width:991px) {
  .he-pr-nav__wrapper .search-product__title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    padding-bottom: .125em;
    text-overflow: ellipsis
  }
}

.he-pr-nav__wrapper .search-product__author {
  color: var(--color--text-tertiary);
  font-family: var(--typefamily-primary);
  font-size: 12px;
  font-weight: var(--typestyle-normal);
  line-height: var(--leading--1-5);
  margin-top: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  padding-bottom: .125em;
  text-overflow: ellipsis
}

.he-pr-nav__wrapper .search-product__author:empty {
  display: none
}

.he-pr-nav__wrapper .ui-menu-item__result-wrap {
  display: flex;
  font-size: var(--typesize-minor);
  justify-content: space-between;
  line-height: var(--leading--1-5);
  padding: 7px 16px
}

.he-pr-nav__wrapper .search-box-icon.hide {
  display: none
}

.he-pr-nav__wrapper .search-box-close-icon.hide {
  display: none !important
}

.he-pr-nav__wrapper .ais-SearchBox-loadingIndicator {
  -webkit-animation: 1s linear infinite both spin;
  animation: 1s linear infinite both spin;
  background: var(--color--ui-01);
  border-radius: 80%;
  bottom: 11px;
  display: block;
  height: 18px;
  position: absolute;
  right: 15px;
  width: 18px
}

.he-pr-nav__wrapper .ais-SearchBox-loadingIndicator::before,
.he-pr-nav__wrapper .ais-SearchBox-loadingIndicator::after {
  content: " ";
  display: block;
  position: absolute
}

.he-pr-nav__wrapper .ais-SearchBox-loadingIndicator::before {
  background: var(--gradient--interactive-accent-01, var(--color--accent-01-gradient));
  border-radius: 0 90px 90px 0;
  height: 18px;
  right: 0;
  top: 0;
  width: 50%;
  z-index: 1
}

.he-pr-nav__wrapper .ais-SearchBox-loadingIndicator::after {
  background: var(--color--ui-field, var(--color--field));
  border-radius: 80%;
  height: 14px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 14px;
  z-index: 1
}

.he-pr-nav__wrapper .ais-SearchBox-loadingIndicator .ais-SearchBox-loadingIcon {
  height: 100%;
  width: 100%
}

.he-pr-nav__wrapper .ais-SearchBox-loadingIndicator g {
  display: none
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

@keyframes spin {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

.he-pr-nav__wrapper main .c-nav__search form {
  position: relative
}

@media(max-width:768px) {
  .he-pr-nav__wrapper main .c-nav__search .ui-autocomplete {
    height: auto
  }
}

.he-pr-nav__wrapper .c-nav__search {
  height: 40px;
  position: relative
}

.he-pr-nav__wrapper .c-nav__search form {
  display: inline-block;
  margin: 0;
  position: relative;
  width: 100%
}

.he-pr-nav__wrapper .c-nav__search form .search-box-icon,
.he-pr-nav__wrapper .c-nav__search form .search-box-close-icon {
  -webkit-transform: translateY(-50%);
  background-color: transparent;
  border: 0;
  font-size: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%)
}

.he-pr-nav__wrapper .c-nav__search form .search-box-icon:focus,
.he-pr-nav__wrapper .c-nav__search form .search-box-close-icon:focus {
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--color--interactive-focus);
  outline-offset: 5px
}

.he-pr-nav__wrapper .c-nav__search form .search-box-icon {
  border-radius: 50%;
  height: 18px;
  right: 14px;
  width: 18px
}

.he-pr-nav__wrapper .c-nav__search form .search-box-icon svg {
  display: none
}

.he-pr-nav__wrapper .c-nav__search form .search-box-close-icon {
  right: 20px
}

.he-pr-nav__wrapper .c-nav__search form .search-box-close-icon::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-md")
}

@media(max-width:768px) {
  .he-pr-nav__wrapper .c-nav__search form .search-box-close-icon {
    right: 19px
  }
}

.he-pr-nav__wrapper .c-nav__search form .search-box-input {
  border-radius: 40px;
  display: block;
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-default);
  font-weight: var(--typeweight-default);
  height: 40px;
  padding: 0 42px 0 24px;
  width: 100%
}

.he-pr-nav__wrapper .c-nav__search form .search-box-input::-webkit-input-placeholder,
.he-pr-nav__wrapper .c-nav__search form .search-box-input::placeholder {
  color: var(--color--text-secondary);
  font-size: var(--typesize-default)
}

.he-pr-nav__wrapper .c-nav__search form .search-box-input::-webkit-search-decoration,
.he-pr-nav__wrapper .c-nav__search form .search-box-input::-webkit-search-cancel-button,
.he-pr-nav__wrapper .c-nav__search form .search-box-input::-webkit-search-results-button,
.he-pr-nav__wrapper .c-nav__search form .search-box-input::-webkit-search-results-decoration {
  display: none
}

.he-pr-nav__wrapper .c-nav__search form .search-box-input:hover::-webkit-input-placeholder,
.he-pr-nav__wrapper .c-nav__search form .search-box-input:focus::-webkit-input-placeholder,
.he-pr-nav__wrapper .c-nav__search form .search-box-input:hover::placeholder,
.he-pr-nav__wrapper .c-nav__search form .search-box-input:focus::placeholder {
  color: var(--color--text-primary)
}

.he-pr-nav__wrapper .c-nav__search form .search-box-input:focus {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus);
  outline-offset: -2px;
  box-shadow: unset
}

.he-pr-nav__wrapper .c-nav__search form .search-box-input.ui-autocomplete-open {
  color: grey
}

@media(min-width:769px) {
  .he-pr-nav__wrapper .c-nav__search form .search-box-input {
    transition: background-color .175s ease-in-out
  }
}

@media(max-width:768px) {
  .he-pr-nav__wrapper .c-nav__search form .search-box-input {
    font-size: var(--typesize-default)
  }
}

.he-pr-nav__wrapper .c-nav__search form.isOpen {
  background-color: var(--color--ui-02);
  border-bottom: 1px solid var(--color--ui-01);
  bottom: 0;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10
}

@media all and (max-width:768px) {
  .he-pr-nav__wrapper .c-nav__search form.isOpen::after {
    background: var(--color--ui-02);
    content: '';
    height: calc(var(--vh, 1vh) * 100 - 64px);
    left: 0;
    position: absolute;
    right: 0;
    top: 64px;
    z-index: 10
  }
}

.he-pr-nav__wrapper .c-nav__search form.isOpen button.ais-SearchBox-submit.search-box-icon {
  display: block
}

.he-pr-nav__wrapper .c-nav__search form.isOpen .ais-SearchBox-loadingIndicator {
  bottom: 23px
}

.he-pr-nav__wrapper .c-nav__search form.isOpen .search-box-input,
.he-pr-nav__wrapper .c-nav__search form.isOpen .search-box-close-icon {
  display: block;
  transition: none
}

.he-pr-nav__wrapper .c-nav__search form.isOpen .search-box-icon {
  display: none
}

.he-pr-nav__wrapper .c-nav__search form.isOpen .search-box-icon::before {
  background: var(--color--interactive-primary)
}

@media(max-width:768px) {
  .he-pr-nav__wrapper .c-nav__search form.isOpen .search-box-icon {
    display: block;
    left: 18px;
    right: auto
  }
}

.he-pr-nav__wrapper .nav__wrapper .c-nav__search {
  height: 40px;
  margin: auto;
  position: static;
  width: 100%
}

@media(min-width:768px) {
  .he-pr-nav__wrapper .nav__wrapper .c-nav__search {
    position: relative;
    width: 62.3%
  }
}

.he-pr-nav__wrapper .nav__wrapper .c-nav__search form {
  height: 40px
}

@media(max-width:768px) {
  .he-pr-nav__wrapper .nav__wrapper .c-nav__search form .search-box-input {
    display: none;
    opacity: 0
  }
}

@media(max-width:768px) {
  .he-pr-nav__wrapper .nav__wrapper .c-nav__search form.isOpen {
    height: calc(100% + 24px);
    top: 50%;
    transform: translateY(-50%);
    width: 100%
  }

  .he-pr-nav__wrapper .nav__wrapper .c-nav__search form.isOpen .search-box-input {
    background-color: var(--color--ui-02);
    border: 0;
    border-bottom: 1px solid var(--color--ui-border-01, var(--color--border-01));
    border-radius: 0;
    box-shadow: none;
    display: block;
    height: 100%;
    opacity: 1;
    padding-left: 44px
  }

  .he-pr-nav__wrapper .nav__wrapper .c-nav__search form.isOpen .search-box-input::-webkit-input-placeholder,
  .he-pr-nav__wrapper .nav__wrapper .c-nav__search form.isOpen .search-box-input::placeholder {
    color: var(--color--text-tertiary)
  }
}

.he-pr-nav__wrapper .page-login .c-nav__search,
.he-pr-nav__wrapper .page-checkout .c-nav__search,
.he-pr-nav__wrapper .page-checkout-login .c-nav__search {
  display: none !important
}

.he-pr-nav__wrapper .ais-SearchBox-form.headerSearch:focus-within .ais-SearchBox-input:not(:focus) {
  background-color: var(--color--ui-02);
  color: var(--color--text-primary)
}

.he-pr-nav__wrapper .ais-SearchBox-form.headerSearch:focus-within .ais-SearchBox-input:not(:focus)::-webkit-input-placeholder,
.he-pr-nav__wrapper .ais-SearchBox-form.headerSearch:focus-within .ais-SearchBox-input:not(:focus)::placeholder {
  color: var(--color--text-primary)
}

.he-pr-nav__wrapper .ais-SearchBox-form.headerSearch:focus-within .search-box-icon::before {
  color: var(--color--interactive-primary-hover)
}

.he-pr-nav__wrapper .screenreader {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  height: 1px;
  width: 1px;
  min-height: unset;
  overflow: hidden;
  position: absolute;
  padding: 0;
  margin: 0
}

.he-pr-nav__wrapper .h1-plus,
.he-pr-nav__wrapper .h1-plus * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h1-plus);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-15);
  margin-bottom: 0
}

.he-pr-nav__wrapper h1,
.he-pr-nav__wrapper .h1,
.he-pr-nav__wrapper .h1 * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h1);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-15);
  margin-bottom: 0
}

.typefamily-primary .he-pr-nav__wrapper h1,
.typefamily-primary .he-pr-nav__wrapper .h1,
.typefamily-primary .he-pr-nav__wrapper .h1 * {
  font-weight: var(--typeweight--light)
}

.he-pr-nav__wrapper .h1-catalog,
.he-pr-nav__wrapper .h1-catalog * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h1-catalog);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-15);
  margin-bottom: 0
}

.he-pr-nav__wrapper h2,
.he-pr-nav__wrapper .h2,
.he-pr-nav__wrapper .h2 * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h2);
  font-weight: var(--typeweight--light);
  line-height: var(--leading--1-3);
  margin-bottom: 0
}

.he-pr-nav__wrapper h3,
.he-pr-nav__wrapper .h3,
.he-pr-nav__wrapper .h3 * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h3);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

.he-pr-nav__wrapper h4,
.he-pr-nav__wrapper .h4,
.he-pr-nav__wrapper .h4 * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-h4);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

.he-pr-nav__wrapper h5,
.he-pr-nav__wrapper .h5,
.he-pr-nav__wrapper .h5 * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-h5);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

.he-pr-nav__wrapper h6,
.he-pr-nav__wrapper .h6,
.he-pr-nav__wrapper .h6 * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

.he-pr-nav__wrapper main h1,
.he-pr-nav__wrapper main h2,
.he-pr-nav__wrapper main h4,
.he-pr-nav__wrapper main h5,
.he-pr-nav__wrapper main h6,
.he-pr-nav__wrapper main .h1,
.he-pr-nav__wrapper main .h2,
.he-pr-nav__wrapper main .h4,
.he-pr-nav__wrapper main .h5,
.he-pr-nav__wrapper main .h6,
.he-pr-nav__wrapper main .exa,
.he-pr-nav__wrapper main .peta,
.he-pr-nav__wrapper main .tera,
.he-pr-nav__wrapper main .giga,
.he-pr-nav__wrapper main .mega,
.he-pr-nav__wrapper main .kilo {
  color: var(--color--text-primary)
}

.he-pr-nav__wrapper main h1 *:not(a[href], a[href] *, button),
.he-pr-nav__wrapper main h2 *:not(a[href], a[href] *, button),
.he-pr-nav__wrapper main h4 *:not(a[href], a[href] *, button),
.he-pr-nav__wrapper main h5 *:not(a[href], a[href] *, button),
.he-pr-nav__wrapper main h6 *:not(a[href], a[href] *, button),
.he-pr-nav__wrapper main .h1 *:not(a[href], a[href] *, button),
.he-pr-nav__wrapper main .h2 *:not(a[href], a[href] *, button),
.he-pr-nav__wrapper main .h4 *:not(a[href], a[href] *, button),
.he-pr-nav__wrapper main .h5 *:not(a[href], a[href] *, button),
.he-pr-nav__wrapper main .h6 *:not(a[href], a[href] *, button),
.he-pr-nav__wrapper main .exa *:not(a[href], a[href] *, button),
.he-pr-nav__wrapper main .peta *:not(a[href], a[href] *, button),
.he-pr-nav__wrapper main .tera *:not(a[href], a[href] *, button),
.he-pr-nav__wrapper main .giga *:not(a[href], a[href] *, button),
.he-pr-nav__wrapper main .mega *:not(a[href], a[href] *, button),
.he-pr-nav__wrapper main .kilo *:not(a[href], a[href] *, button) {
  color: var(--color--text-primary)
}

.he-pr-nav__wrapper main h3,
.he-pr-nav__wrapper main .h3 {
  color: var(--color--text-secondary)
}

.he-pr-nav__wrapper main h3 *:not(a[href], a[href] *, button),
.he-pr-nav__wrapper main .h3 *:not(a[href], a[href] *, button) {
  color: var(--color--text-secondary)
}

.he-pr-nav__wrapper p,
.he-pr-nav__wrapper .p,
.he-pr-nav__wrapper .p *,
.he-pr-nav__wrapper blockquote,
.he-pr-nav__wrapper figcaption,
.he-pr-nav__wrapper cite,
.he-pr-nav__wrapper dl,
.he-pr-nav__wrapper ol,
.he-pr-nav__wrapper ul {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-p);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default)
}

.he-pr-nav__wrapper cite {
  font-style: normal;
  font-weight: inherit;
  display: inline-block
}

.he-pr-nav__wrapper .exa,
.he-pr-nav__wrapper .exa * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-exa);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-1)
}

.he-pr-nav__wrapper .peta,
.he-pr-nav__wrapper .peta * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-peta);
  font-weight: var(--typeweight--light);
  line-height: var(--leading--1-2)
}

.he-pr-nav__wrapper .tera,
.he-pr-nav__wrapper .tera * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-tera);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2)
}

.he-pr-nav__wrapper .giga,
.he-pr-nav__wrapper .giga * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-giga);
  font-weight: var(--typeweight--light);
  line-height: var(--leading--1-2)
}

.he-pr-nav__wrapper .mega,
.he-pr-nav__wrapper .mega * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-mega);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-2)
}

.he-pr-nav__wrapper .kilo,
.he-pr-nav__wrapper .kilo * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-kilo);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2)
}

.he-pr-nav__wrapper .minor,
.he-pr-nav__wrapper .minor * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-minor);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default)
}

.he-pr-nav__wrapper .milli,
.he-pr-nav__wrapper .milli * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-milli);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default)
}

.he-pr-nav__wrapper .micro,
.he-pr-nav__wrapper .micro * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-micro);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default)
}

.he-pr-nav__wrapper [class*="flex-buttons--"][class*="flex-buttons--"] ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 -15px -30px -15px
}

.he-pr-nav__wrapper [class*="flex-buttons--"][class*="flex-buttons--"] li {
  list-style: none;
  position: relative;
  min-height: 1px;
  width: 100%;
  padding: 0 15px;
  margin: 0 0 30px 0
}

.he-pr-nav__wrapper [class*="flex-buttons--"][class*="flex-buttons--"] li::before {
  display: none
}

.he-pr-nav__wrapper [class*="flex-buttons--"][class*="flex-buttons--"] li a[href] {
  text-align: center;
  width: 100%;
  margin: 0 !important
}

@media(min-width:376px) {
  .he-pr-nav__wrapper [class*="flex-buttons--"][class*="flex-buttons--"] li a[href] {
    height: 100%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around
  }
}

@media(min-width:376px) and (max-width:1023px) {
  .he-pr-nav__wrapper .flex-buttons--5 li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }
}

@media(min-width:1024px) {
  .he-pr-nav__wrapper .flex-buttons--5 li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%
  }
}

html[class*="aem-AuthorLayer-"] body:not(.headerIsTransparent) .he-pr-nav__wrapper {
  position: relative
}

@media(min-width:992px) {
  html[class*="aem-AuthorLayer-"] .he-header-mobile-nav {
    display: none
  }
}

html[class*="aem-AuthorLayer-"] .cq-Editable-dom--container:not(.column-control) {
  padding-top: 0;
  padding-bottom: 0
}