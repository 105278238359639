.background-video__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

@media(min-width:481px) and (max-width:767px) {
  .background-video__container {
    padding-bottom: 90px
  }
}

.background-video__container>[class*="container"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: inherit;
  position: relative;
  z-index: 3
}

.background-video__container>[class*="container"]>.row {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  margin: auto
}

.background-video__wrap-outer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: none !important
}

.background-video__wrap-inner {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden
}

.background-video__contrast-mask {
  display: block;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2
}

.background-video__media {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top center;
  margin: 0;
  position: absolute;
  z-index: 1
}

@media(max-width:480px) {
  .background-video__media {
    display: none
  }
}

.background-video__control {
  height: 32px;
  width: 32px;
  line-height: 1;
  padding: 0;
  border: 0;
  border-radius: 0;
  position: absolute;
  right: 30px;
  bottom: 30px;
  z-index: 4;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .2s ease-in-out;
  transition: opacity .2s ease-in-out
}

.background-video__control.is-visible {
  opacity: .60;
  visibility: visible
}

.background-video__control.is-visible:hover,
.background-video__control.is-visible:active,
.background-video__control.is-visible:focus {
  opacity: 1
}

.background-video__control.play {
  background-image: url("video-common/assets/icons/sprites/sprite--play-pause.svg#icon-play--chalk-white")
}

.background-video--dark-controls .background-video__control.play {
  background-image: url("video-common/assets/icons/sprites/sprite--play-pause.svg#icon-play--soft-black")
}

.background-video__control.pause {
  background-image: url("video-common/assets/icons/sprites/sprite--play-pause.svg#icon-pause--chalk-white")
}

.background-video--dark-controls .background-video__control.pause {
  background-image: url("video-common/assets/icons/sprites/sprite--play-pause.svg#icon-pause--soft-black")
}

@media(max-width:480px) {
  .background-video__control {
    display: none
  }
}

.background-video__fallback-container {
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden
}

.background-video__fallback-image {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top center;
  object-position: top center
}

.sibling-uses--pull-up-10percent {
  padding-bottom: calc(10% + 45px)
}

@media(max-width:1169px) {
  .sibling-uses--pull-up-10percent .background-video__control {
    bottom: calc(10% + 45px)
  }
}

.min-height--400 {
  min-height: 400px
}

.min-height--600 {
  min-height: 600px
}

.min-height--45vh {
  min-height: 45vh
}

.min-height--65vh {
  min-height: 65vh
}

@media all and (-ms-high-contrast:none),
(-ms-high-contrast:active) {
  .background-video__container>[class*="container"]::after {
    content: ""
  }

  .min-height--400.background-video__container>[class*="container"]::after {
    height: 400px
  }

  .min-height--600.background-video__container>[class*="container"]::after {
    height: 600px
  }

  .min-height--45vh.background-video__container>[class*="container"]::after {
    height: 45vh
  }

  .min-height--65vh.background-video__container>[class*="container"]::after {
    height: 65vh
  }

  .background-video__container>[class*="container"] .row {
    align-self: center
  }

  .background-video__media,
  .background-video__fallback-image {
    height: auto;
    width: auto;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%)
  }

  .background-video__fallback-image {
    max-width: none
  }

  .background-video__control {
    z-index: 5
  }

  .background-video__wrap-outer+.container {
    z-index: 4
  }

  .background-video__contrast-mask {
    z-index: 3
  }

  .background-video__media {
    z-index: 2
  }

  .background-video__fallback-image {
    z-index: 1
  }
}
