.events-calendar {
  margin-bottom: 15px
}

@media screen and (min-width:768px) {
  .events-calendar {
    padding: 0;
    margin-bottom: 30px
  }
}

.events-calendar-controls {
  text-align: center
}

.events-calendar-controls span {
  display: inline-block;
  margin-top: 5px
}

.events-calendar-controls button {
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: #fff
}

.events-calendar-days,
.events-calendar-day-headers {
  clear: both
}

.events-calendar-day-headers .events-calendar-day {
  color: #900;
  float: left;
  padding: 10px 0 15px;
  border-bottom: 1px gray solid
}

.events-calendar-day {
  display: inline-block;
  position: relative;
  width: 14.28571429%;
  text-align: center
}

.events-calendar-day a {
  color: #000;
  display: inline-block;
  width: 40px;
  max-width: 100%;
  padding: 11px 0;
  cursor: pointer;
  text-decoration: none
}

.events-calendar-day a:hover {
  background-color: #f5e6e6
}

.events-calendar-day .events-calendar-indicator {
  display: inline-block;
  height: 7px;
  width: 7px;
  position: absolute;
  top: calc(100% - 8px);
  right: calc(50% - 4px);
  border-radius: 50%;
  background-color: #000;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden
}

.events-calendar-day.today a {
  background-color: #ccc
}

.events-calendar-day.selected a {
  background-color: #900;
  color: #fff
}

.events-calendar-day.selected .events-calendar-indicator {
  background-color: #fff
}

.events-calendar-day.not-current a {
  cursor: default;
  color: #ddd
}

.events-calendar-day.not-current a:hover {
  background-color: #fff
}
