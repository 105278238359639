.bg1396052241 {
  background-image: url('https://www.pearson.com/content/dam/global-store/global/educator/1600x800-GR-GettyImages-690598374.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767px) {
  .bg1396052241 {
    background-image: url("https://www.pearson.com/content/dam/global-store/en-us/images/800x970-GettyImages-690598374%201.jpg");
  }
}
