
// VARIABLES have been moved to nav-footer-variables.scss and loaded outside of namespacing
// :root {
//   --color--text-border: var(--color--ui-border-01, var(--color--border-01));
//   --color-sign-in-button: var(--color--interactive-primary);
//   --spacing-40: 40px;
//   --typefamily-primary-header: 'typefamily-primary', var(--typefamily-primary);
//   --he-headerPosition: relative;
//   --he-header-min-h: 64px;
//   --he-header-y-shift: 0;
//   --he-header-grid: auto auto 1fr auto;
//   --he-header-gaps: var(--spacing-90);
//   --he-header-bg-color: var(--color--ui-background-dark, var(--color--background-dark));
//   --he-header-link-color: var(--color--ui-02);
//   --he-text-color-global: var(--color--interactive-tertiary-inverse);
//   --he-header-usernav-inverse: var(--color--text-primary);
//   --he-logo-filter-color: none;
//   --he-header-link-color-inverse: var(--color--text-secondary);
//   --he-header-desktop-nav-display: block;
//   --he-header-mobile-nav-display: none;
//   --headerContainerSpacingY: 0;
//   --headerContainerSpacingX: var(--spacing-20);
//   --he-header-nav-icon-size: 36px;
//   --he-header-nav-divider-color: var(--color--ui-01);
//   --he-header-underlay-opacity: 1;
//   --he-header-cart-icon-size: 24px;
//   --he-headrer-display-icon-nav-message: flex;
//   --he-heaader-display-search: block;
//   --he-header-display-additional: flex;
//   --he-headrer-display-reduced-message: none;
//   --he-color-text-secure-checkout: var(--color--text-primary-inverse);
//   --he-header-display-global-link-desktop: block;
//   --he-header-display-global-link-mobile: none;
//   --he-header-width-dropdown: 230px;
//   --he-header-mx-w-dropdown: 50vw;
//   --he-header-search-bg: rgba(254, 254, 254, 0.35);
//   --he-header-search-bg-alt: rgba(254, 254, 254, 0.8);
//   --he-header-search-bg-focuse: rgba(247, 249, 253, 0.7);
//   --he-header-search-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
//   --he-header-search-color: var(--color--text-secondary-inverse);
//   --he-header-search-color-alt: #f7f9fd;
//   --he-header-search-border-color: var(--color--icon-tertiary-inverse);
//   --he-header-logo-height: 36px;
//   --he-header-logo-width: 116px;
//   --he-header-icon-nav-grow: 0;
//   --he-inverse-text-primary1: var(--color--text-primary-inverse);
//   --he-text-secondary1: #333;
//   --he-nav--mobile-display: none;
//   --he-topnav-display: block;
//   --he-topnav-min-h: 40px;
//   --he-topnav-bg-c: var(--color--icon-secondary);
//   --he-top-nav-base-text-color: #fefefe;
//   --he-top-nav-base-text-opacity: .59;
//   --he-top-nav-base-text-size: var(--typesize-milli);
//   --he-topnav-z: 4;
//   --he-topnav-opacity: 1;
//   --he-topnav-drdwn-display: none;
//   --he-topnav-locale-selector-underline-shift: 29px;
//   --he-topnav-locale-selector-underline-w: calc(100% - var(--he-topnav-locale-selector-underline-shift));
//   --he-nav-interactive-c: var(--color--interactive-action);
//   --he-primery-nav-link-c-base: var(--color--text-primary-inverse);
//   --he-primery-nav-text-size-base: var(--typesize-default);
//   --he-primery-nav-logo-w: auto;
//   --he-primery-nav-locale-display: flex;
//   --he-mobile-nav-display: none;
//   --he-mobile-nav-level: 0;
//   --he-mobile-nav-bgc: var(--color--ui-background-light, var(--color--background-light));
//   --he-transition: .125s ease-in-out;
//   --he-mobile-nav-min-h: 0;
//   --he-mobile-nav-height: calc(100vh - var(--he-header-min-h));
//   --he-mobile-nav-base-text-size: var(--typesize-default);
//   --he-mobile-nav-base-text-line: var(--leading--1-6);
//   --he-mobile-nav-base-text-color: var(--color--text-primary);
//   --he-mobile-nav-level-transition: 0
// }

// :root div.xf-content-height {
//   margin: 0;
//   min-height: unset
// }

header {
  --spacing-25: 25px;
  --spacing-35: 35px
}

header.isSticky {
  --he-topnav-z: 0;
  --he-topnav-opacity: 0;
  --he-nav-interactive-c: var(--color--text-primary);
  --he-primery-nav-link-c-base: var(--color--text-primary);
  padding-top: var(--he-header-min-h)
}

header.isSticky .he-pr-nav__wrapper {
  --he-headerPosition: fixed;
  --he-header-bg-color: var(--color--ui-background-med, var(--color--background-medium));
  --he-text-color-global: var(--color--text-primary);
  --he-header-search-color: var(--color--text-tertiary);
  --he-header-search-border-color: var(--color--text-tertiary);
  --he-header-search-bg: transparent;
  --he-header-search-focus-bg-color: transparent;
  --he-logo-filter-color: brightness(0) saturate(100%) invert(8%) sepia(51%) saturate(1451%) hue-rotate(190deg) brightness(87%) contrast(102%);
  --he-color-text-secure-checkout: var(--color--text-primary);
  --he-header-underlay-opacity: 0;
  --he-header-usernav-inverse: var(--color--interactive-tertiary-inverse);
  --he-inverse-text-primary1: var(--color--text-primary);
  --he-header-p-events: all;
  top: 0
}

@media(max-width:991px) {
  :root {
    --he-header-display-additional: none;
    --he-header-grid: auto 134px 1fr auto;
    --he-header-gaps: 0;
    --he-header-desktop-nav-display: none;
    --he-header-mobile-nav-display: block;
    --headerContainerSpacingX: var(--spacing-20);
    --he-header-display-global-link-desktop: none;
    --he-header-display-global-link-mobile: block;
    --he-header-mx-w-dropdown: 90vw
  }
}

@media(max-width:1024px) {
  :root {
    --he-inverse-text-primary1: var(--color--text-primary)
  }
}

@media(max-width:1590px) {
  :root {
    --he-header-gaps: var(--spacing-60)
  }
}

@media(max-width:1390px) {
  :root {
    --he-header-gaps: var(--spacing-45)
  }
}

@media(max-width:992px) {
  header {
    --he-topnav-display: none;
    --he-primery-nav-logo-w: 37px;
    --he-primery-nav-locale-display: none;
    --he-mobile-nav-display: block;
    --he-mobile-nav-max-h: 375px
  }

  .he-pr-nav__wrapper {
    --he-header-gaps: var(--spacing-20)
  }
}

@media(max-width:480px) {
  :root {
    --he-header-logo-height: 34px;
    --he-header-logo-width: 31px
  }

  header {
    --he-mobile-nav-max-h: unset;
    --he-mobile-nav-level-transition: var(--he-transition)
  }
}

@media screen and (min-width:993px) {

  header .he-nav--mobile-only,
  footer .he-nav--mobile-only {
    display: none !important
  }
}

.aem-AuthorLayer-Edit .he-pr-nav__wrapper,
.he-pr-nav__wrapper[id*='cq-'] {
  --he-header-mobile-nav-display: block
}

.headerIsTransparent .he-pr-nav__wrapper {
  --he-headerPosition: absolute;
  --he-header-bg-color: transparent
}

.headerIsTransparent header.isSticky {
  padding-top: 0
}

html.wf-active body>.container {
  max-width: 100%;
  margin: 0;
  padding: 0
}

.container {
  --footer-separator-margin: var(--grid-margin-md)
}

@media screen and (max-width:768px) {
  .container {
    --footer-separator-margin: var(--grid-margin-sm)
  }
}

.inpn-page-state__crossed {
  --he-header-y-shift: -100%;
  --he-header-p-events: none
}

.inpn-page-state__crossed.inpn-page-state__top {
  --he-header-y-shift: 0;
  --he-header-p-events: all
}

.to-top-button {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  padding: .75em 1.25em;
  min-height: 3rem;
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem;
  position: fixed;
  bottom: var(--spacing-30);
  right: var(--spacing-30);
  padding-bottom: var(--spacing-10);
  display: flex;
  flex-flow: row wrap-reverse;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity .2s ease-in-out;
  z-index: 10
}

.to-top-button .icon-right::after {
  margin-inline-start: var(--space-xxsmall)
}

.to-top-button .icon-left {
  padding-inline-start: 2.875em !important
}

.to-top-button .icon-left::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 1.25em
}

.to-top-button .icon-left::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 1.25em
}

.to-top-button .icon-left::after {
  margin-inline-end: var(--space-xxsmall)
}

.to-top-button [class*="button-"][href*=".pdf"],
.to-top-button [class*="button-"][href$=".txt"],
.to-top-button [class*="button-"] a[href$=".pdf"],
.to-top-button [class*="button-"] a[href$=".txt"] {
  padding-inline-start: 2.875em !important
}

.to-top-button [class*="button-"][href*=".pdf"]::after,
.to-top-button [class*="button-"][href$=".txt"]::after,
.to-top-button [class*="button-"] a[href$=".pdf"]::after,
.to-top-button [class*="button-"] a[href$=".txt"]::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 1.25em
}

.to-top-button [class*="button-"][href*=".pdf"]::after,
.to-top-button [class*="button-"][href$=".txt"]::after,
.to-top-button [class*="button-"] a[href$=".pdf"]::after,
.to-top-button [class*="button-"] a[href$=".txt"]::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 1.25em
}

.to-top-button [class*="button-"][href*=".pdf"]::after,
.to-top-button [class*="button-"][href$=".txt"]::after,
.to-top-button [class*="button-"] a[href$=".pdf"]::after,
.to-top-button [class*="button-"] a[href$=".txt"]::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-file-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-file-sm");
  transform: none;
  margin-right: .25em
}

.to-top-button [class*="button-"][href$=".doc"],
.to-top-button [class*="button-"][href$=".docx"],
.to-top-button [class*="button-"][href$=".ppt"],
.to-top-button [class*="button-"][href$=".pptx"],
.to-top-button [class*="button-"][href$=".docx"],
.to-top-button [class*="button-"][href$=".xls"],
.to-top-button [class*="button-"][href$=".xlsx"],
.to-top-button [class*="button-"][href$=".zip"],
.to-top-button [class*="button-"] a[href$=".doc"],
.to-top-button [class*="button-"] a[href$=".docx"],
.to-top-button [class*="button-"] a[href$=".ppt"],
.to-top-button [class*="button-"] a[href$=".pptx"],
.to-top-button [class*="button-"] a[href$=".docx"],
.to-top-button [class*="button-"] a[href$=".xls"],
.to-top-button [class*="button-"] a[href$=".xlsx"],
.to-top-button [class*="button-"] a[href$=".zip"] {
  padding-inline-start: 2.875em !important
}

.to-top-button [class*="button-"][href$=".doc"]::after,
.to-top-button [class*="button-"][href$=".docx"]::after,
.to-top-button [class*="button-"][href$=".ppt"]::after,
.to-top-button [class*="button-"][href$=".pptx"]::after,
.to-top-button [class*="button-"][href$=".docx"]::after,
.to-top-button [class*="button-"][href$=".xls"]::after,
.to-top-button [class*="button-"][href$=".xlsx"]::after,
.to-top-button [class*="button-"][href$=".zip"]::after,
.to-top-button [class*="button-"] a[href$=".doc"]::after,
.to-top-button [class*="button-"] a[href$=".docx"]::after,
.to-top-button [class*="button-"] a[href$=".ppt"]::after,
.to-top-button [class*="button-"] a[href$=".pptx"]::after,
.to-top-button [class*="button-"] a[href$=".docx"]::after,
.to-top-button [class*="button-"] a[href$=".xls"]::after,
.to-top-button [class*="button-"] a[href$=".xlsx"]::after,
.to-top-button [class*="button-"] a[href$=".zip"]::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 1.25em
}

.to-top-button [class*="button-"][href$=".doc"]::after,
.to-top-button [class*="button-"][href$=".docx"]::after,
.to-top-button [class*="button-"][href$=".ppt"]::after,
.to-top-button [class*="button-"][href$=".pptx"]::after,
.to-top-button [class*="button-"][href$=".docx"]::after,
.to-top-button [class*="button-"][href$=".xls"]::after,
.to-top-button [class*="button-"][href$=".xlsx"]::after,
.to-top-button [class*="button-"][href$=".zip"]::after,
.to-top-button [class*="button-"] a[href$=".doc"]::after,
.to-top-button [class*="button-"] a[href$=".docx"]::after,
.to-top-button [class*="button-"] a[href$=".ppt"]::after,
.to-top-button [class*="button-"] a[href$=".pptx"]::after,
.to-top-button [class*="button-"] a[href$=".docx"]::after,
.to-top-button [class*="button-"] a[href$=".xls"]::after,
.to-top-button [class*="button-"] a[href$=".xlsx"]::after,
.to-top-button [class*="button-"] a[href$=".zip"]::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 1.25em
}

.to-top-button [class*="button-"][href$=".doc"]::after,
.to-top-button [class*="button-"][href$=".docx"]::after,
.to-top-button [class*="button-"][href$=".ppt"]::after,
.to-top-button [class*="button-"][href$=".pptx"]::after,
.to-top-button [class*="button-"][href$=".docx"]::after,
.to-top-button [class*="button-"][href$=".xls"]::after,
.to-top-button [class*="button-"][href$=".xlsx"]::after,
.to-top-button [class*="button-"][href$=".zip"]::after,
.to-top-button [class*="button-"] a[href$=".doc"]::after,
.to-top-button [class*="button-"] a[href$=".docx"]::after,
.to-top-button [class*="button-"] a[href$=".ppt"]::after,
.to-top-button [class*="button-"] a[href$=".pptx"]::after,
.to-top-button [class*="button-"] a[href$=".docx"]::after,
.to-top-button [class*="button-"] a[href$=".xls"]::after,
.to-top-button [class*="button-"] a[href$=".xlsx"]::after,
.to-top-button [class*="button-"] a[href$=".zip"]::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-download-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-download-sm");
  transform: translateY(0.15em);
  margin-right: .25em
}

.to-top-button .button-primary {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1
}

@media(min-width:376px) {
  .to-top-button .button-primary {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .to-top-button .button-primary {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.to-top-button .button-primary::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.to-top-button .button-primary:hover,
.to-top-button .button-primary:focus,
.to-top-button .button-primary:focus-visible,
.to-top-button .button-primary:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.to-top-button .button-primary:hover::before,
.to-top-button .button-primary:focus::before,
.to-top-button .button-primary:focus-visible::before,
.to-top-button .button-primary:active::before {
  transform: scale(1.05)
}

.to-top-button .button-primary:focus,
.to-top-button .button-primary:focus-visible {
  outline: 0
}

.to-top-button .button-primary:focus::before,
.to-top-button .button-primary:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.to-top-button .button-primary:active::before {
  outline-color: transparent
}

.to-top-button .button-primary:only-of-type {
  margin: 0
}

.to-top-button .button-primary.icon-only {
  padding: .625em;
  max-width: 2.25rem;
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

.to-top-button .button-primary::before {
  background: var(--color--interactive-primary) border-box
}

.to-top-button .button-primary:hover::before {
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-primary-hover) border-box
}

.to-top-button .button-primary:focus,
.to-top-button .button-primary:focus-visible {
  color: var(--color--text-primary-inverse)
}

.to-top-button .button-primary:focus::before,
.to-top-button .button-primary:focus-visible::before {
  background: var(--color--interactive-primary) border-box
}

.to-top-button .button-primary:active {
  color: var(--color--text-primary-inverse)
}

.to-top-button .button-primary:active::before {
  background: var(--color--interactive-primary-active) border-box
}

.to-top-button .button-primary[disabled],
.to-top-button .button-primary.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.to-top-button .button-primary[disabled]::before,
.to-top-button .button-primary.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.to-top-button .button-primary[disabled]:hover::before,
.to-top-button .button-primary.button-disabled:hover::before,
.to-top-button .button-primary[disabled]:focus::before,
.to-top-button .button-primary.button-disabled:focus::before,
.to-top-button .button-primary[disabled]:focus-visible::before,
.to-top-button .button-primary.button-disabled:focus-visible::before,
.to-top-button .button-primary[disabled]:active::before,
.to-top-button .button-primary.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.to-top-button .button-primary-inverse {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary-inverse)
}

@media(min-width:376px) {
  .to-top-button .button-primary-inverse {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .to-top-button .button-primary-inverse {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.to-top-button .button-primary-inverse::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.to-top-button .button-primary-inverse:hover,
.to-top-button .button-primary-inverse:focus,
.to-top-button .button-primary-inverse:focus-visible,
.to-top-button .button-primary-inverse:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.to-top-button .button-primary-inverse:hover::before,
.to-top-button .button-primary-inverse:focus::before,
.to-top-button .button-primary-inverse:focus-visible::before,
.to-top-button .button-primary-inverse:active::before {
  transform: scale(1.05)
}

.to-top-button .button-primary-inverse:focus,
.to-top-button .button-primary-inverse:focus-visible {
  outline: 0
}

.to-top-button .button-primary-inverse:focus::before,
.to-top-button .button-primary-inverse:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.to-top-button .button-primary-inverse:active::before {
  outline-color: transparent
}

.to-top-button .button-primary-inverse:only-of-type {
  margin: 0
}

.to-top-button .button-primary-inverse.icon-only {
  padding: .625em;
  max-width: 2.25rem;
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

.to-top-button .button-primary-inverse::before {
  background: var(--color--interactive-primary-inverse)
}

.to-top-button .button-primary-inverse:hover {
  color: var(--color--text-primary-inverse)
}

.to-top-button .button-primary-inverse:hover::before {
  background: var(--color--interactive-primary-inverse-hover, var(--color--interactive-primary-hover-inverse))
}

.to-top-button .button-primary-inverse:focus,
.to-top-button .button-primary-inverse:focus-visible {
  color: var(--color--text-primary-inverse)
}

.to-top-button .button-primary-inverse:focus::before,
.to-top-button .button-primary-inverse:focus-visible::before {
  background: var(--color--interactive-primary-inverse)
}

.to-top-button .button-primary-inverse:active {
  color: var(--color--text-primary-inverse)
}

.to-top-button .button-primary-inverse:active::before {
  background: var(--color--interactive-primary-inverse-active, var(--color--interactive-primary-active-inverse))
}

.to-top-button .button-primary-inverse[disabled],
.to-top-button .button-primary-inverse.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02);
  color: var(--color--interactive-disabled-03)
}

.to-top-button .button-primary-inverse[disabled]::before,
.to-top-button .button-primary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.to-top-button .button-primary-inverse[disabled]:hover::before,
.to-top-button .button-primary-inverse.button-disabled:hover::before,
.to-top-button .button-primary-inverse[disabled]:focus::before,
.to-top-button .button-primary-inverse.button-disabled:focus::before,
.to-top-button .button-primary-inverse[disabled]:focus-visible::before,
.to-top-button .button-primary-inverse.button-disabled:focus-visible::before,
.to-top-button .button-primary-inverse[disabled]:active::before,
.to-top-button .button-primary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.to-top-button .button-primary-inverse[disabled]::before,
.to-top-button .button-primary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.to-top-button .button-primary-inverse[disabled]:hover::before,
.to-top-button .button-primary-inverse.button-disabled:hover::before,
.to-top-button .button-primary-inverse[disabled]:focus::before,
.to-top-button .button-primary-inverse.button-disabled:focus::before,
.to-top-button .button-primary-inverse[disabled]:focus-visible::before,
.to-top-button .button-primary-inverse.button-disabled:focus-visible::before,
.to-top-button .button-primary-inverse[disabled]:active::before,
.to-top-button .button-primary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01)
}

.to-top-button .button-secondary {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1
}

@media(min-width:376px) {
  .to-top-button .button-secondary {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .to-top-button .button-secondary {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.to-top-button .button-secondary::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.to-top-button .button-secondary:hover,
.to-top-button .button-secondary:focus,
.to-top-button .button-secondary:focus-visible,
.to-top-button .button-secondary:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.to-top-button .button-secondary:hover::before,
.to-top-button .button-secondary:focus::before,
.to-top-button .button-secondary:focus-visible::before,
.to-top-button .button-secondary:active::before {
  transform: scale(1.05)
}

.to-top-button .button-secondary:focus,
.to-top-button .button-secondary:focus-visible {
  outline: 0
}

.to-top-button .button-secondary:focus::before,
.to-top-button .button-secondary:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.to-top-button .button-secondary:active::before {
  outline-color: transparent
}

.to-top-button .button-secondary:only-of-type {
  margin: 0
}

.to-top-button .button-secondary.icon-only {
  padding: .625em;
  max-width: 2.25rem;
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

.to-top-button .button-secondary::before {
  background: var(--color--interactive-secondary) border-box
}

.to-top-button .button-secondary:hover {
  color: var(--color--text-primary-inverse)
}

.to-top-button .button-secondary:hover::before {
  background: var(--color--interactive-secondary-hover) border-box
}

.to-top-button .button-secondary:focus,
.to-top-button .button-secondary:focus-visible {
  color: var(--color--text-primary-inverse)
}

.to-top-button .button-secondary:focus::before,
.to-top-button .button-secondary:focus-visible::before {
  background: var(--color--interactive-secondary) border-box
}

.to-top-button .button-secondary:active {
  color: var(--color--text-primary-inverse)
}

.to-top-button .button-secondary:active::before {
  background: var(--color--interactive-secondary-active) border-box
}

.to-top-button .button-secondary[disabled],
.to-top-button .button-secondary.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.to-top-button .button-secondary[disabled]::before,
.to-top-button .button-secondary.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.to-top-button .button-secondary[disabled]:hover::before,
.to-top-button .button-secondary.button-disabled:hover::before,
.to-top-button .button-secondary[disabled]:focus::before,
.to-top-button .button-secondary.button-disabled:focus::before,
.to-top-button .button-secondary[disabled]:focus-visible::before,
.to-top-button .button-secondary.button-disabled:focus-visible::before,
.to-top-button .button-secondary[disabled]:active::before,
.to-top-button .button-secondary.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.to-top-button .button-secondary-inverse {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary)
}

@media(min-width:376px) {
  .to-top-button .button-secondary-inverse {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .to-top-button .button-secondary-inverse {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.to-top-button .button-secondary-inverse::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.to-top-button .button-secondary-inverse:hover,
.to-top-button .button-secondary-inverse:focus,
.to-top-button .button-secondary-inverse:focus-visible,
.to-top-button .button-secondary-inverse:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.to-top-button .button-secondary-inverse:hover::before,
.to-top-button .button-secondary-inverse:focus::before,
.to-top-button .button-secondary-inverse:focus-visible::before,
.to-top-button .button-secondary-inverse:active::before {
  transform: scale(1.05)
}

.to-top-button .button-secondary-inverse:focus,
.to-top-button .button-secondary-inverse:focus-visible {
  outline: 0
}

.to-top-button .button-secondary-inverse:focus::before,
.to-top-button .button-secondary-inverse:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.to-top-button .button-secondary-inverse:active::before {
  outline-color: transparent
}

.to-top-button .button-secondary-inverse:only-of-type {
  margin: 0
}

.to-top-button .button-secondary-inverse.icon-only {
  padding: .625em;
  max-width: 2.25rem;
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

.to-top-button .button-secondary-inverse::before {
  background: var(--color--interactive-secondary-inverse)
}

.to-top-button .button-secondary-inverse:hover {
  color: var(--color--text-primary)
}

.to-top-button .button-secondary-inverse:hover::before {
  background: var(--color--interactive-secondary-inverse-hover, var(--color--interactive-secondary-hover-inverse))
}

.to-top-button .button-secondary-inverse:focus,
.to-top-button .button-secondary-inverse:focus-visible {
  color: var(--color--text-primary)
}

.to-top-button .button-secondary-inverse:focus::before,
.to-top-button .button-secondary-inverse:focus-visible::before {
  background: var(--color--interactive-secondary-inverse)
}

.to-top-button .button-secondary-inverse:active {
  color: var(--color--text-primary)
}

.to-top-button .button-secondary-inverse:active::before {
  background: var(--color--interactive-secondary-inverse-active, var(--color--interactive-secondary-active-inverse))
}

.to-top-button .button-secondary-inverse[disabled],
.to-top-button .button-secondary-inverse.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02);
  color: var(--color--interactive-disabled-03)
}

.to-top-button .button-secondary-inverse[disabled]::before,
.to-top-button .button-secondary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.to-top-button .button-secondary-inverse[disabled]:hover::before,
.to-top-button .button-secondary-inverse.button-disabled:hover::before,
.to-top-button .button-secondary-inverse[disabled]:focus::before,
.to-top-button .button-secondary-inverse.button-disabled:focus::before,
.to-top-button .button-secondary-inverse[disabled]:focus-visible::before,
.to-top-button .button-secondary-inverse.button-disabled:focus-visible::before,
.to-top-button .button-secondary-inverse[disabled]:active::before,
.to-top-button .button-secondary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.to-top-button .button-secondary-inverse[disabled]::before,
.to-top-button .button-secondary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.to-top-button .button-secondary-inverse[disabled]:hover::before,
.to-top-button .button-secondary-inverse.button-disabled:hover::before,
.to-top-button .button-secondary-inverse[disabled]:focus::before,
.to-top-button .button-secondary-inverse.button-disabled:focus::before,
.to-top-button .button-secondary-inverse[disabled]:focus-visible::before,
.to-top-button .button-secondary-inverse.button-disabled:focus-visible::before,
.to-top-button .button-secondary-inverse[disabled]:active::before,
.to-top-button .button-secondary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01)
}

.to-top-button .button-tertiary {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary);
  transition: color .125s ease-in-out
}

@media(min-width:376px) {
  .to-top-button .button-tertiary {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .to-top-button .button-tertiary {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.to-top-button .button-tertiary::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.to-top-button .button-tertiary:hover,
.to-top-button .button-tertiary:focus,
.to-top-button .button-tertiary:focus-visible,
.to-top-button .button-tertiary:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.to-top-button .button-tertiary:hover::before,
.to-top-button .button-tertiary:focus::before,
.to-top-button .button-tertiary:focus-visible::before,
.to-top-button .button-tertiary:active::before {
  transform: scale(1.05)
}

.to-top-button .button-tertiary:focus,
.to-top-button .button-tertiary:focus-visible {
  outline: 0
}

.to-top-button .button-tertiary:focus::before,
.to-top-button .button-tertiary:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.to-top-button .button-tertiary:active::before {
  outline-color: transparent
}

.to-top-button .button-tertiary:only-of-type {
  margin: 0
}

.to-top-button .button-tertiary.icon-only {
  padding: .625em;
  max-width: 2.25rem;
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

.to-top-button .button-tertiary::before {
  outline-color: var(--color--interactive-tertiary);
  outline-offset: -2px;
  background-color: transparent
}

.to-top-button .button-tertiary:hover {
  color: var(--color--text-primary-inverse)
}

.to-top-button .button-tertiary:hover::before {
  outline-color: var(--color--interactive-tertiary-hover);
  background: var(--color--interactive-tertiary-hover)
}

.to-top-button .button-tertiary:focus,
.to-top-button .button-tertiary:focus-visible {
  color: var(--color--text-tertiary)
}

.to-top-button .button-tertiary:focus::before,
.to-top-button .button-tertiary:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

.to-top-button .button-tertiary:active {
  color: var(--color--text-primary-inverse)
}

.to-top-button .button-tertiary:active::before {
  background: var(--color--interactive-tertiary-active)
}

.to-top-button .button-tertiary[disabled],
.to-top-button .button-tertiary.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.to-top-button .button-tertiary[disabled]::before,
.to-top-button .button-tertiary.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.to-top-button .button-tertiary[disabled]:hover::before,
.to-top-button .button-tertiary.button-disabled:hover::before,
.to-top-button .button-tertiary[disabled]:focus::before,
.to-top-button .button-tertiary.button-disabled:focus::before,
.to-top-button .button-tertiary[disabled]:focus-visible::before,
.to-top-button .button-tertiary.button-disabled:focus-visible::before,
.to-top-button .button-tertiary[disabled]:active::before,
.to-top-button .button-tertiary.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.to-top-button .button-tertiary[disabled]::before,
.to-top-button .button-tertiary.button-disabled::before {
  outline-color: var(--color--interactive-disabled-01);
  outline-offset: -2px;
  background: transparent
}

.to-top-button .button-tertiary[disabled]:hover::before,
.to-top-button .button-tertiary.button-disabled:hover::before,
.to-top-button .button-tertiary[disabled]:focus::before,
.to-top-button .button-tertiary.button-disabled:focus::before,
.to-top-button .button-tertiary[disabled]:focus-visible::before,
.to-top-button .button-tertiary.button-disabled:focus-visible::before,
.to-top-button .button-tertiary[disabled]:active::before,
.to-top-button .button-tertiary.button-disabled:active::before {
  background: transparent
}

.to-top-button .button-tertiary-inverse {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary-inverse)
}

@media(min-width:376px) {
  .to-top-button .button-tertiary-inverse {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .to-top-button .button-tertiary-inverse {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.to-top-button .button-tertiary-inverse::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.to-top-button .button-tertiary-inverse:hover,
.to-top-button .button-tertiary-inverse:focus,
.to-top-button .button-tertiary-inverse:focus-visible,
.to-top-button .button-tertiary-inverse:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.to-top-button .button-tertiary-inverse:hover::before,
.to-top-button .button-tertiary-inverse:focus::before,
.to-top-button .button-tertiary-inverse:focus-visible::before,
.to-top-button .button-tertiary-inverse:active::before {
  transform: scale(1.05)
}

.to-top-button .button-tertiary-inverse:focus,
.to-top-button .button-tertiary-inverse:focus-visible {
  outline: 0
}

.to-top-button .button-tertiary-inverse:focus::before,
.to-top-button .button-tertiary-inverse:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.to-top-button .button-tertiary-inverse:active::before {
  outline-color: transparent
}

.to-top-button .button-tertiary-inverse:only-of-type {
  margin: 0
}

.to-top-button .button-tertiary-inverse.icon-only {
  padding: .625em;
  max-width: 2.25rem;
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

.to-top-button .button-tertiary-inverse::before {
  outline-color: var(--color--interactive-tertiary-inverse);
  outline-offset: -2px;
  background: transparent
}

.to-top-button .button-tertiary-inverse:hover {
  color: var(--color--text-primary)
}

.to-top-button .button-tertiary-inverse:hover::before {
  outline-color: transparent;
  background: var(--color--interactive-tertiary-inverse-hover, var(--color--interactive-tertiary-hover-inverse))
}

.to-top-button .button-tertiary-inverse:focus,
.to-top-button .button-tertiary-inverse:focus-visible {
  color: var(--color--text-primary-inverse)
}

.to-top-button .button-tertiary-inverse:focus::before,
.to-top-button .button-tertiary-inverse:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

.to-top-button .button-tertiary-inverse:active {
  color: var(--color--text-primary)
}

.to-top-button .button-tertiary-inverse:active::before {
  background: var(--color--interactive-tertiary-inverse-active, var(--color--interactive-tertiary-active-inverse))
}

.to-top-button .button-tertiary-inverse[disabled],
.to-top-button .button-tertiary-inverse.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.to-top-button .button-tertiary-inverse[disabled]::before,
.to-top-button .button-tertiary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.to-top-button .button-tertiary-inverse[disabled]:hover::before,
.to-top-button .button-tertiary-inverse.button-disabled:hover::before,
.to-top-button .button-tertiary-inverse[disabled]:focus::before,
.to-top-button .button-tertiary-inverse.button-disabled:focus::before,
.to-top-button .button-tertiary-inverse[disabled]:focus-visible::before,
.to-top-button .button-tertiary-inverse.button-disabled:focus-visible::before,
.to-top-button .button-tertiary-inverse[disabled]:active::before,
.to-top-button .button-tertiary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.to-top-button .button-tertiary-inverse[disabled]::before,
.to-top-button .button-tertiary-inverse.button-disabled::before {
  outline-color: var(--color--interactive-disabled-02);
  background: transparent
}

.to-top-button .button-tertiary-inverse[disabled]:hover::before,
.to-top-button .button-tertiary-inverse.button-disabled:hover::before,
.to-top-button .button-tertiary-inverse[disabled]:focus::before,
.to-top-button .button-tertiary-inverse.button-disabled:focus::before,
.to-top-button .button-tertiary-inverse[disabled]:focus-visible::before,
.to-top-button .button-tertiary-inverse.button-disabled:focus-visible::before,
.to-top-button .button-tertiary-inverse[disabled]:active::before,
.to-top-button .button-tertiary-inverse.button-disabled:active::before {
  background: transparent
}

.to-top-button .button-action {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary)
}

@media(min-width:376px) {
  .to-top-button .button-action {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .to-top-button .button-action {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.to-top-button .button-action::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.to-top-button .button-action:hover,
.to-top-button .button-action:focus,
.to-top-button .button-action:focus-visible,
.to-top-button .button-action:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.to-top-button .button-action:hover::before,
.to-top-button .button-action:focus::before,
.to-top-button .button-action:focus-visible::before,
.to-top-button .button-action:active::before {
  transform: scale(1.05)
}

.to-top-button .button-action:focus,
.to-top-button .button-action:focus-visible {
  outline: 0
}

.to-top-button .button-action:focus::before,
.to-top-button .button-action:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.to-top-button .button-action:active::before {
  outline-color: transparent
}

.to-top-button .button-action:only-of-type {
  margin: 0
}

.to-top-button .button-action.icon-only {
  padding: .625em;
  max-width: 2.25rem;
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

.to-top-button .button-action::before {
  background: var(--color--interactive-action) border-box
}

.to-top-button .button-action:hover::before {
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-action-hover) border-box
}

.to-top-button .button-action:focus,
.to-top-button .button-action:focus-visible {
  color: var(--color--text-primary)
}

.to-top-button .button-action:focus::before,
.to-top-button .button-action:focus-visible::before {
  background: var(--color--interactive-action-focus) border-box
}

.to-top-button .button-action:active {
  color: var(--color--text-primary-inverse)
}

.to-top-button .button-action:active::before {
  background: var(--color--interactive-action-active) border-box
}

.to-top-button .button-action[disabled],
.to-top-button .button-action.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.to-top-button .button-action[disabled]::before,
.to-top-button .button-action.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.to-top-button .button-action[disabled]:hover::before,
.to-top-button .button-action.button-disabled:hover::before,
.to-top-button .button-action[disabled]:focus::before,
.to-top-button .button-action.button-disabled:focus::before,
.to-top-button .button-action[disabled]:focus-visible::before,
.to-top-button .button-action.button-disabled:focus-visible::before,
.to-top-button .button-action[disabled]:active::before,
.to-top-button .button-action.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.to-top-button .button-action[disabled]::before,
.to-top-button .button-action.button-disabled::before {
  outline-offset: -2px;
  outline-color: var(--color--interactive-disabled-01);
  background: transparent
}

.to-top-button .button-action[disabled]:hover::before,
.to-top-button .button-action.button-disabled:hover::before,
.to-top-button .button-action[disabled]:focus::before,
.to-top-button .button-action.button-disabled:focus::before,
.to-top-button .button-action[disabled]:focus-visible::before,
.to-top-button .button-action.button-disabled:focus-visible::before,
.to-top-button .button-action[disabled]:active::before,
.to-top-button .button-action.button-disabled:active::before {
  background: transparent
}

.to-top-button .button-action-inverse {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary)
}

@media(min-width:376px) {
  .to-top-button .button-action-inverse {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .to-top-button .button-action-inverse {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.to-top-button .button-action-inverse::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.to-top-button .button-action-inverse:hover,
.to-top-button .button-action-inverse:focus,
.to-top-button .button-action-inverse:focus-visible,
.to-top-button .button-action-inverse:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.to-top-button .button-action-inverse:hover::before,
.to-top-button .button-action-inverse:focus::before,
.to-top-button .button-action-inverse:focus-visible::before,
.to-top-button .button-action-inverse:active::before {
  transform: scale(1.05)
}

.to-top-button .button-action-inverse:focus,
.to-top-button .button-action-inverse:focus-visible {
  outline: 0
}

.to-top-button .button-action-inverse:focus::before,
.to-top-button .button-action-inverse:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.to-top-button .button-action-inverse:active::before {
  outline-color: transparent
}

.to-top-button .button-action-inverse:only-of-type {
  margin: 0
}

.to-top-button .button-action-inverse.icon-only {
  padding: .625em;
  max-width: 2.25rem;
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

.to-top-button .button-action-inverse::before {
  background: var(--color--interactive-action-inverse) border-box
}

.to-top-button .button-action-inverse:hover {
  color: var(--color--text-primary)
}

.to-top-button .button-action-inverse:hover::before {
  background: var(--color--interactive-action-inverse-hover, var(--color--interactive-action-hover-inverse)) border-box
}

.to-top-button .button-action-inverse:focus,
.to-top-button .button-action-inverse:focus-visible {
  color: var(--color--text-primary)
}

.to-top-button .button-action-inverse:focus::before,
.to-top-button .button-action-inverse:focus-visible::before {
  background: var(--color--interactive-action-focus) border-box
}

.to-top-button .button-action-inverse:active {
  color: var(--color--text-primary)
}

.to-top-button .button-action-inverse:active::before {
  background: var(--color--interactive-action-inverse-active, var(--color--interactive-action-active-inverse)) border-box
}

.to-top-button .button-action-inverse[disabled],
.to-top-button .button-action-inverse.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.to-top-button .button-action-inverse[disabled]::before,
.to-top-button .button-action-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.to-top-button .button-action-inverse[disabled]:hover::before,
.to-top-button .button-action-inverse.button-disabled:hover::before,
.to-top-button .button-action-inverse[disabled]:focus::before,
.to-top-button .button-action-inverse.button-disabled:focus::before,
.to-top-button .button-action-inverse[disabled]:focus-visible::before,
.to-top-button .button-action-inverse.button-disabled:focus-visible::before,
.to-top-button .button-action-inverse[disabled]:active::before,
.to-top-button .button-action-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.to-top-button .button-action-inverse[disabled]::before,
.to-top-button .button-action-inverse.button-disabled::before {
  outline-offset: -2px;
  outline-color: var(--color--interactive-disabled-02);
  background: transparent
}

.to-top-button .button-action-inverse[disabled]:hover::before,
.to-top-button .button-action-inverse.button-disabled:hover::before,
.to-top-button .button-action-inverse[disabled]:focus::before,
.to-top-button .button-action-inverse.button-disabled:focus::before,
.to-top-button .button-action-inverse[disabled]:focus-visible::before,
.to-top-button .button-action-inverse.button-disabled:focus-visible::before,
.to-top-button .button-action-inverse[disabled]:active::before,
.to-top-button .button-action-inverse.button-disabled:active::before {
  background: transparent
}

.to-top-button .button-small {
  font-size: var(--typesize-minor);
  padding: .25em 1.25em;
  min-height: 2rem
}

.to-top-button .button-small.icon-only {
  aspect-ratio: 1;
  padding: .5em;
  max-width: 2rem
}

.to-top-button .button-large {
  padding: .75em 1.25em;
  min-height: 3rem
}

.to-top-button .button-large.icon-only {
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

.to-top-button .button-block {
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-right: 0
}

.to-top-button .button-unstyled {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0
}

.to-top-button .button-unstyled::before {
  display: none;
  background: transparent;
  border: 0;
  box-shadow: none
}

.to-top-button [class*="button-circular-"] {
  line-height: var(--leading--1);
  padding: 0;
  aspect: 1
}

.to-top-button [class*="button-circular-"]::before {
  border-radius: 50%;
  aspect-ratio: 1
}

.to-top-button .button-circular-large {
  width: 2.5em;
  height: 2.5em
}

.to-top-button .button-circular-medium {
  width: 2.25em;
  height: 2.25em
}

.to-top-button .button-circular-small {
  width: 2em;
  height: 2em
}

.to-top-button .button-interactive a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-p);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  background: var(--color--label-interactive);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-radius: var(--button-corners);
  padding: var(--spacing-10) var(--spacing-15);
  display: inline-block;
  min-height: 1.5rem;
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  .to-top-button .button-interactive a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .to-top-button .button-interactive a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.to-top-button .button-interactive a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.to-top-button .button-interactive a:hover,
.to-top-button .button-interactive a:focus,
.to-top-button .button-interactive a:focus-visible,
.to-top-button .button-interactive a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.to-top-button .button-interactive a:hover::before,
.to-top-button .button-interactive a:focus::before,
.to-top-button .button-interactive a:focus-visible::before,
.to-top-button .button-interactive a:active::before {
  transform: scale(1.05)
}

.to-top-button .button-interactive a:focus,
.to-top-button .button-interactive a:focus-visible {
  outline: 0
}

.to-top-button .button-interactive a:focus::before,
.to-top-button .button-interactive a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.to-top-button .button-interactive a:active::before {
  outline-color: transparent
}

.to-top-button .button-interactive a:only-of-type {
  margin: 0
}

.to-top-button .button-interactive a.icon-only {
  padding: .625em;
  max-width: 2.25rem;
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

.to-top-button .button-interactive a:hover {
  color: var(--color--text-link-hover)
}

.to-top-button .button-interactive a:focus {
  color: var(--color--text-link)
}

.to-top-button .button-interactive a:focus::before {
  transform: scale(1)
}

.to-top-button .button-interactive a:active {
  color: var(--color--text-link-hover);
  text-decoration: none
}

.to-top-button .button-non-interactive a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-p);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-radius: var(--button-corners);
  padding: var(--spacing-10) var(--spacing-15);
  display: inline-block;
  min-height: 1.5rem;
  padding: 0 var(--spacing-10);
  pointer-events: none;
  color: var(--color--text-tertiary);
  background: var(--color--label-interactive);
  border: 2px solid var(--color--ui-border-03, var(--color--border-03));
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  .to-top-button .button-non-interactive a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .to-top-button .button-non-interactive a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.to-top-button .button-non-interactive a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.to-top-button .button-non-interactive a:hover,
.to-top-button .button-non-interactive a:focus,
.to-top-button .button-non-interactive a:focus-visible,
.to-top-button .button-non-interactive a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.to-top-button .button-non-interactive a:hover::before,
.to-top-button .button-non-interactive a:focus::before,
.to-top-button .button-non-interactive a:focus-visible::before,
.to-top-button .button-non-interactive a:active::before {
  transform: scale(1.05)
}

.to-top-button .button-non-interactive a:focus,
.to-top-button .button-non-interactive a:focus-visible {
  outline: 0
}

.to-top-button .button-non-interactive a:focus::before,
.to-top-button .button-non-interactive a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.to-top-button .button-non-interactive a:active::before {
  outline-color: transparent
}

.to-top-button .button-non-interactive a:only-of-type {
  margin: 0
}

.to-top-button .button-non-interactive a.icon-only {
  padding: .625em;
  max-width: 2.25rem;
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

.to-top-button .button-non-interactive a:hover {
  color: var(--color--text-link-hover)
}

.to-top-button .button-non-interactive a:focus {
  color: var(--color--text-link)
}

.to-top-button .button-non-interactive a:focus::before {
  transform: scale(1)
}

.to-top-button .button-non-interactive a:active {
  color: var(--color--text-link-hover);
  text-decoration: none
}

.to-top-button .button-non-interactive a a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-p);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  background: var(--color--label-interactive);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-radius: var(--button-corners);
  padding: var(--spacing-10) var(--spacing-15);
  display: inline-block;
  min-height: 1.5rem;
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  .to-top-button .button-non-interactive a a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .to-top-button .button-non-interactive a a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.to-top-button .button-non-interactive a a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.to-top-button .button-non-interactive a a:hover,
.to-top-button .button-non-interactive a a:focus,
.to-top-button .button-non-interactive a a:focus-visible,
.to-top-button .button-non-interactive a a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.to-top-button .button-non-interactive a a:hover::before,
.to-top-button .button-non-interactive a a:focus::before,
.to-top-button .button-non-interactive a a:focus-visible::before,
.to-top-button .button-non-interactive a a:active::before {
  transform: scale(1.05)
}

.to-top-button .button-non-interactive a a:focus,
.to-top-button .button-non-interactive a a:focus-visible {
  outline: 0
}

.to-top-button .button-non-interactive a a:focus::before,
.to-top-button .button-non-interactive a a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.to-top-button .button-non-interactive a a:active::before {
  outline-color: transparent
}

.to-top-button .button-non-interactive a a:only-of-type {
  margin: 0
}

.to-top-button .button-non-interactive a a.icon-only {
  padding: .625em;
  max-width: 2.25rem;
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

.to-top-button .button-non-interactive a a:hover {
  color: var(--color--text-link-hover)
}

.to-top-button .button-non-interactive a a:focus {
  color: var(--color--text-link)
}

.to-top-button .button-non-interactive a a:focus::before {
  transform: scale(1)
}

.to-top-button .button-non-interactive a a:active {
  color: var(--color--text-link-hover);
  text-decoration: none
}

.to-top-button .button-interactive-tertiary a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-secondary);
  padding: var(--spacing-10) 25px;
  border-radius: var(--button-corners);
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  .to-top-button .button-interactive-tertiary a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .to-top-button .button-interactive-tertiary a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.to-top-button .button-interactive-tertiary a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.to-top-button .button-interactive-tertiary a:hover,
.to-top-button .button-interactive-tertiary a:focus,
.to-top-button .button-interactive-tertiary a:focus-visible,
.to-top-button .button-interactive-tertiary a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.to-top-button .button-interactive-tertiary a:hover::before,
.to-top-button .button-interactive-tertiary a:focus::before,
.to-top-button .button-interactive-tertiary a:focus-visible::before,
.to-top-button .button-interactive-tertiary a:active::before {
  transform: scale(1.05)
}

.to-top-button .button-interactive-tertiary a:focus,
.to-top-button .button-interactive-tertiary a:focus-visible {
  outline: 0
}

.to-top-button .button-interactive-tertiary a:focus::before,
.to-top-button .button-interactive-tertiary a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.to-top-button .button-interactive-tertiary a:active::before {
  outline-color: transparent
}

.to-top-button .button-interactive-tertiary a:only-of-type {
  margin: 0
}

.to-top-button .button-interactive-tertiary a.icon-only {
  padding: .625em;
  max-width: 2.25rem;
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

.to-top-button .button-interactive-tertiary a::before {
  background: var(--color--label-interactive);
  border: 1px solid rgba(39, 49, 49, 0.15)
}

.to-top-button .button-interactive-tertiary a:hover {
  color: var(--color--text-secondary)
}

.to-top-button .button-interactive-tertiary a:hover::before {
  background: #e6ecef
}

.to-top-button .button-interactive-tertiary a:focus,
.to-top-button .button-interactive-tertiary a:active {
  color: var(--color--text-secondary);
  background: var(--color--label-interactive)
}

.to-top-button .screenreader {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  height: 1px;
  width: 1px;
  min-height: unset;
  overflow: hidden;
  position: absolute;
  padding: 0;
  margin: 0
}

@media(min-width:376px) {
  .to-top-button {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .to-top-button {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.to-top-button::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.to-top-button:hover,
.to-top-button:focus,
.to-top-button:focus-visible,
.to-top-button:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.to-top-button:hover::before,
.to-top-button:focus::before,
.to-top-button:focus-visible::before,
.to-top-button:active::before {
  transform: scale(1.05)
}

.to-top-button:focus,
.to-top-button:focus-visible {
  outline: 0
}

.to-top-button:focus::before,
.to-top-button:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.to-top-button:active::before {
  outline-color: transparent
}

.to-top-button:only-of-type {
  margin: 0
}

.to-top-button.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.to-top-button::before {
  background: var(--color--interactive-secondary) border-box
}

.to-top-button:hover {
  color: var(--color--text-primary-inverse)
}

.to-top-button:hover::before {
  background: var(--color--interactive-secondary-hover) border-box
}

.to-top-button:focus,
.to-top-button:focus-visible {
  color: var(--color--text-primary-inverse)
}

.to-top-button:focus::before,
.to-top-button:focus-visible::before {
  background: var(--color--interactive-secondary) border-box
}

.to-top-button:active {
  color: var(--color--text-primary-inverse)
}

.to-top-button:active::before {
  background: var(--color--interactive-secondary-active) border-box
}

.to-top-button[disabled],
.to-top-button.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.to-top-button[disabled]::before,
.to-top-button.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.to-top-button[disabled]:hover::before,
.to-top-button.button-disabled:hover::before,
.to-top-button[disabled]:focus::before,
.to-top-button.button-disabled:focus::before,
.to-top-button[disabled]:focus-visible::before,
.to-top-button.button-disabled:focus-visible::before,
.to-top-button[disabled]:active::before,
.to-top-button.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.to-top-button.icon-only {
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

.to-top-button:after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-sm");
  transform: rotate(180deg)
}

.to-top-button__title {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-micro);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default);
  text-transform: var(--typecase-upper);
  font-weight: var(--typeweight--bold)
}

.to-top-button.is-show {
  opacity: 1;
  pointer-events: auto;
  visibility: visible
}

div#onetrust-consent-sdk {
  display: block;
  opacity: 1;
  --OT-banner-flow: row;
  --OT-banner-aligning: center;
  --OT-banner-bg-color: var(--color--ui-background-dark, var(--color--background-dark));
  --OT-banner-stroke-color: var(--color--text-primary-inverse);
  --OT-banner-stroke-color-inverse: var(--color--text-primary);
  --OT-banner-cta-block-w: auto;
  --OT-banner-cta-block-flow: row;
  --OT-banner-cta-w: auto;
  --OT-banner-cta-mw: unset;
  --OT-banner-cta-grow: unset;
  --OT-banner-cta-bottom-spacing: 0;
  --OT-type-family: var(--typefamily-primary);
  --OT-grid-margin-sm: var(--space-medium);
  --OT-grid-margin-md: calc(11 * var(--space-unit));
  --OT-grid-margin-lg: var(--space-xxlarge);
  --OT-grid-max-width: 1590px;
  --OT-grid-gap: calc(4 * var(--OT-space-unit));
  --OT-heading-typesize: var(--typesize-h6);
  --OT-regular-typesize: var(--typesize-default);
  --OT-modal-w: 538px;
  --OT-modal-top: 45%;
  --OT-modal-transform: translateY(-50%);
  --OT-modal-stroke-color: var(--color--text-primary);
  --OT-modal-text-secondary: var(--color--text-secondary);
  --OT-modal-header-h: calc(20 * var(--OT-space-unit));
  --OT-modal-close-btn-side: calc(10 * var(--OT-space-unit));
  --OT-modal-close-btn-right: calc(4 * var(--OT-space-unit));
  --OT-modal-allowall-w: 100%;
  --OT-modal-content-spacing: var(--spacing-30);
  --OT-modal-buttons-spacing: calc(var(--spacing-10)+var(--spacing-15));
  --OT-modal-footer-maxHeight: calc(40 * var(--OT-space-unit));
  --OT-space-unit: 4px;
  --transition-time: .2s;
  --toggle-w: 64px;
  --toggle-h: 32px;
  --toggle-runner-side: 24px;
  --toggle-gaps: 4px;
  --toggle-off-margin: var(--toggle-gaps);
  --toggle-on-margin: calc(var(--toggle-w) - var(--toggle-runner-side) - var(--toggle-gaps));
  --toggle-check-icon-pull: -70%;
  --toggle-ckeck-icon-left: 11px;
  --toggle-off-icon-pull: -50%;
  --toggle-off-icon-left: 37px
}

div#onetrust-consent-sdk .screenreader {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  height: 1px;
  width: 1px;
  min-height: unset;
  overflow: hidden;
  position: absolute;
  padding: 0;
  margin: 0
}

div#onetrust-consent-sdk [class*="button-"][href*=".pdf"],
div#onetrust-consent-sdk [class*="button-"][href$=".txt"],
div#onetrust-consent-sdk [class*="button-"] a[href$=".pdf"],
div#onetrust-consent-sdk [class*="button-"] a[href$=".txt"] {
  padding-inline-start: 2.875em !important
}

div#onetrust-consent-sdk [class*="button-"][href*=".pdf"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".txt"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".pdf"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".txt"]::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 1.25em
}

div#onetrust-consent-sdk [class*="button-"][href*=".pdf"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".txt"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".pdf"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".txt"]::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 1.25em
}

div#onetrust-consent-sdk [class*="button-"][href*=".pdf"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".txt"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".pdf"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".txt"]::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-file-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-file-sm");
  transform: none;
  margin-right: .25em
}

div#onetrust-consent-sdk [class*="button-"][href$=".doc"],
div#onetrust-consent-sdk [class*="button-"][href$=".docx"],
div#onetrust-consent-sdk [class*="button-"][href$=".ppt"],
div#onetrust-consent-sdk [class*="button-"][href$=".pptx"],
div#onetrust-consent-sdk [class*="button-"][href$=".docx"],
div#onetrust-consent-sdk [class*="button-"][href$=".xls"],
div#onetrust-consent-sdk [class*="button-"][href$=".xlsx"],
div#onetrust-consent-sdk [class*="button-"][href$=".zip"],
div#onetrust-consent-sdk [class*="button-"] a[href$=".doc"],
div#onetrust-consent-sdk [class*="button-"] a[href$=".docx"],
div#onetrust-consent-sdk [class*="button-"] a[href$=".ppt"],
div#onetrust-consent-sdk [class*="button-"] a[href$=".pptx"],
div#onetrust-consent-sdk [class*="button-"] a[href$=".docx"],
div#onetrust-consent-sdk [class*="button-"] a[href$=".xls"],
div#onetrust-consent-sdk [class*="button-"] a[href$=".xlsx"],
div#onetrust-consent-sdk [class*="button-"] a[href$=".zip"] {
  padding-inline-start: 2.875em !important
}

div#onetrust-consent-sdk [class*="button-"][href$=".doc"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".docx"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".ppt"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".pptx"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".docx"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".xls"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".xlsx"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".zip"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".doc"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".docx"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".ppt"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".pptx"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".docx"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".xls"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".xlsx"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".zip"]::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 1.25em
}

div#onetrust-consent-sdk [class*="button-"][href$=".doc"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".docx"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".ppt"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".pptx"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".docx"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".xls"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".xlsx"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".zip"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".doc"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".docx"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".ppt"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".pptx"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".docx"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".xls"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".xlsx"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".zip"]::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 1.25em
}

div#onetrust-consent-sdk [class*="button-"][href$=".doc"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".docx"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".ppt"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".pptx"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".docx"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".xls"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".xlsx"]::after,
div#onetrust-consent-sdk [class*="button-"][href$=".zip"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".doc"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".docx"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".ppt"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".pptx"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".docx"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".xls"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".xlsx"]::after,
div#onetrust-consent-sdk [class*="button-"] a[href$=".zip"]::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-download-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-download-sm");
  transform: translateY(0.15em);
  margin-right: .25em
}

div#onetrust-consent-sdk .button-primary {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1
}

@media(min-width:376px) {
  div#onetrust-consent-sdk .button-primary {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  div#onetrust-consent-sdk .button-primary {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

div#onetrust-consent-sdk .button-primary::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

div#onetrust-consent-sdk .button-primary:hover,
div#onetrust-consent-sdk .button-primary:focus,
div#onetrust-consent-sdk .button-primary:focus-visible,
div#onetrust-consent-sdk .button-primary:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

div#onetrust-consent-sdk .button-primary:hover::before,
div#onetrust-consent-sdk .button-primary:focus::before,
div#onetrust-consent-sdk .button-primary:focus-visible::before,
div#onetrust-consent-sdk .button-primary:active::before {
  transform: scale(1.05)
}

div#onetrust-consent-sdk .button-primary:focus,
div#onetrust-consent-sdk .button-primary:focus-visible {
  outline: 0
}

div#onetrust-consent-sdk .button-primary:focus::before,
div#onetrust-consent-sdk .button-primary:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

div#onetrust-consent-sdk .button-primary:active::before {
  outline-color: transparent
}

div#onetrust-consent-sdk .button-primary:only-of-type {
  margin: 0
}

div#onetrust-consent-sdk .button-primary.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

div#onetrust-consent-sdk .button-primary::before {
  background: var(--color--interactive-primary) border-box
}

div#onetrust-consent-sdk .button-primary:hover::before {
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-primary-hover) border-box
}

div#onetrust-consent-sdk .button-primary:focus,
div#onetrust-consent-sdk .button-primary:focus-visible {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk .button-primary:focus::before,
div#onetrust-consent-sdk .button-primary:focus-visible::before {
  background: var(--color--interactive-primary) border-box
}

div#onetrust-consent-sdk .button-primary:active {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk .button-primary:active::before {
  background: var(--color--interactive-primary-active) border-box
}

div#onetrust-consent-sdk .button-primary[disabled],
div#onetrust-consent-sdk .button-primary.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

div#onetrust-consent-sdk .button-primary[disabled]::before,
div#onetrust-consent-sdk .button-primary.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk .button-primary[disabled]:hover::before,
div#onetrust-consent-sdk .button-primary.button-disabled:hover::before,
div#onetrust-consent-sdk .button-primary[disabled]:focus::before,
div#onetrust-consent-sdk .button-primary.button-disabled:focus::before,
div#onetrust-consent-sdk .button-primary[disabled]:focus-visible::before,
div#onetrust-consent-sdk .button-primary.button-disabled:focus-visible::before,
div#onetrust-consent-sdk .button-primary[disabled]:active::before,
div#onetrust-consent-sdk .button-primary.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

div#onetrust-consent-sdk .button-primary-inverse {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary-inverse)
}

@media(min-width:376px) {
  div#onetrust-consent-sdk .button-primary-inverse {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  div#onetrust-consent-sdk .button-primary-inverse {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

div#onetrust-consent-sdk .button-primary-inverse::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

div#onetrust-consent-sdk .button-primary-inverse:hover,
div#onetrust-consent-sdk .button-primary-inverse:focus,
div#onetrust-consent-sdk .button-primary-inverse:focus-visible,
div#onetrust-consent-sdk .button-primary-inverse:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

div#onetrust-consent-sdk .button-primary-inverse:hover::before,
div#onetrust-consent-sdk .button-primary-inverse:focus::before,
div#onetrust-consent-sdk .button-primary-inverse:focus-visible::before,
div#onetrust-consent-sdk .button-primary-inverse:active::before {
  transform: scale(1.05)
}

div#onetrust-consent-sdk .button-primary-inverse:focus,
div#onetrust-consent-sdk .button-primary-inverse:focus-visible {
  outline: 0
}

div#onetrust-consent-sdk .button-primary-inverse:focus::before,
div#onetrust-consent-sdk .button-primary-inverse:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

div#onetrust-consent-sdk .button-primary-inverse:active::before {
  outline-color: transparent
}

div#onetrust-consent-sdk .button-primary-inverse:only-of-type {
  margin: 0
}

div#onetrust-consent-sdk .button-primary-inverse.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

div#onetrust-consent-sdk .button-primary-inverse::before {
  background: var(--color--interactive-primary-inverse)
}

div#onetrust-consent-sdk .button-primary-inverse:hover {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk .button-primary-inverse:hover::before {
  background: var(--color--interactive-primary-inverse-hover, var(--color--interactive-primary-hover-inverse))
}

div#onetrust-consent-sdk .button-primary-inverse:focus,
div#onetrust-consent-sdk .button-primary-inverse:focus-visible {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk .button-primary-inverse:focus::before,
div#onetrust-consent-sdk .button-primary-inverse:focus-visible::before {
  background: var(--color--interactive-primary-inverse)
}

div#onetrust-consent-sdk .button-primary-inverse:active {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk .button-primary-inverse:active::before {
  background: var(--color--interactive-primary-inverse-active, var(--color--interactive-primary-active-inverse))
}

div#onetrust-consent-sdk .button-primary-inverse[disabled],
div#onetrust-consent-sdk .button-primary-inverse.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02);
  color: var(--color--interactive-disabled-03)
}

div#onetrust-consent-sdk .button-primary-inverse[disabled]::before,
div#onetrust-consent-sdk .button-primary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk .button-primary-inverse[disabled]:hover::before,
div#onetrust-consent-sdk .button-primary-inverse.button-disabled:hover::before,
div#onetrust-consent-sdk .button-primary-inverse[disabled]:focus::before,
div#onetrust-consent-sdk .button-primary-inverse.button-disabled:focus::before,
div#onetrust-consent-sdk .button-primary-inverse[disabled]:focus-visible::before,
div#onetrust-consent-sdk .button-primary-inverse.button-disabled:focus-visible::before,
div#onetrust-consent-sdk .button-primary-inverse[disabled]:active::before,
div#onetrust-consent-sdk .button-primary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

div#onetrust-consent-sdk .button-primary-inverse[disabled]::before,
div#onetrust-consent-sdk .button-primary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk .button-primary-inverse[disabled]:hover::before,
div#onetrust-consent-sdk .button-primary-inverse.button-disabled:hover::before,
div#onetrust-consent-sdk .button-primary-inverse[disabled]:focus::before,
div#onetrust-consent-sdk .button-primary-inverse.button-disabled:focus::before,
div#onetrust-consent-sdk .button-primary-inverse[disabled]:focus-visible::before,
div#onetrust-consent-sdk .button-primary-inverse.button-disabled:focus-visible::before,
div#onetrust-consent-sdk .button-primary-inverse[disabled]:active::before,
div#onetrust-consent-sdk .button-primary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk .button-secondary {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1
}

@media(min-width:376px) {
  div#onetrust-consent-sdk .button-secondary {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  div#onetrust-consent-sdk .button-secondary {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

div#onetrust-consent-sdk .button-secondary::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

div#onetrust-consent-sdk .button-secondary:hover,
div#onetrust-consent-sdk .button-secondary:focus,
div#onetrust-consent-sdk .button-secondary:focus-visible,
div#onetrust-consent-sdk .button-secondary:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

div#onetrust-consent-sdk .button-secondary:hover::before,
div#onetrust-consent-sdk .button-secondary:focus::before,
div#onetrust-consent-sdk .button-secondary:focus-visible::before,
div#onetrust-consent-sdk .button-secondary:active::before {
  transform: scale(1.05)
}

div#onetrust-consent-sdk .button-secondary:focus,
div#onetrust-consent-sdk .button-secondary:focus-visible {
  outline: 0
}

div#onetrust-consent-sdk .button-secondary:focus::before,
div#onetrust-consent-sdk .button-secondary:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

div#onetrust-consent-sdk .button-secondary:active::before {
  outline-color: transparent
}

div#onetrust-consent-sdk .button-secondary:only-of-type {
  margin: 0
}

div#onetrust-consent-sdk .button-secondary.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

div#onetrust-consent-sdk .button-secondary::before {
  background: var(--color--interactive-secondary) border-box
}

div#onetrust-consent-sdk .button-secondary:hover {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk .button-secondary:hover::before {
  background: var(--color--interactive-secondary-hover) border-box
}

div#onetrust-consent-sdk .button-secondary:focus,
div#onetrust-consent-sdk .button-secondary:focus-visible {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk .button-secondary:focus::before,
div#onetrust-consent-sdk .button-secondary:focus-visible::before {
  background: var(--color--interactive-secondary) border-box
}

div#onetrust-consent-sdk .button-secondary:active {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk .button-secondary:active::before {
  background: var(--color--interactive-secondary-active) border-box
}

div#onetrust-consent-sdk .button-secondary[disabled],
div#onetrust-consent-sdk .button-secondary.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

div#onetrust-consent-sdk .button-secondary[disabled]::before,
div#onetrust-consent-sdk .button-secondary.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk .button-secondary[disabled]:hover::before,
div#onetrust-consent-sdk .button-secondary.button-disabled:hover::before,
div#onetrust-consent-sdk .button-secondary[disabled]:focus::before,
div#onetrust-consent-sdk .button-secondary.button-disabled:focus::before,
div#onetrust-consent-sdk .button-secondary[disabled]:focus-visible::before,
div#onetrust-consent-sdk .button-secondary.button-disabled:focus-visible::before,
div#onetrust-consent-sdk .button-secondary[disabled]:active::before,
div#onetrust-consent-sdk .button-secondary.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

div#onetrust-consent-sdk .button-secondary-inverse {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary)
}

@media(min-width:376px) {
  div#onetrust-consent-sdk .button-secondary-inverse {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  div#onetrust-consent-sdk .button-secondary-inverse {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

div#onetrust-consent-sdk .button-secondary-inverse::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

div#onetrust-consent-sdk .button-secondary-inverse:hover,
div#onetrust-consent-sdk .button-secondary-inverse:focus,
div#onetrust-consent-sdk .button-secondary-inverse:focus-visible,
div#onetrust-consent-sdk .button-secondary-inverse:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

div#onetrust-consent-sdk .button-secondary-inverse:hover::before,
div#onetrust-consent-sdk .button-secondary-inverse:focus::before,
div#onetrust-consent-sdk .button-secondary-inverse:focus-visible::before,
div#onetrust-consent-sdk .button-secondary-inverse:active::before {
  transform: scale(1.05)
}

div#onetrust-consent-sdk .button-secondary-inverse:focus,
div#onetrust-consent-sdk .button-secondary-inverse:focus-visible {
  outline: 0
}

div#onetrust-consent-sdk .button-secondary-inverse:focus::before,
div#onetrust-consent-sdk .button-secondary-inverse:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

div#onetrust-consent-sdk .button-secondary-inverse:active::before {
  outline-color: transparent
}

div#onetrust-consent-sdk .button-secondary-inverse:only-of-type {
  margin: 0
}

div#onetrust-consent-sdk .button-secondary-inverse.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

div#onetrust-consent-sdk .button-secondary-inverse::before {
  background: var(--color--interactive-secondary-inverse)
}

div#onetrust-consent-sdk .button-secondary-inverse:hover {
  color: var(--color--text-primary)
}

div#onetrust-consent-sdk .button-secondary-inverse:hover::before {
  background: var(--color--interactive-secondary-inverse-hover, var(--color--interactive-secondary-hover-inverse))
}

div#onetrust-consent-sdk .button-secondary-inverse:focus,
div#onetrust-consent-sdk .button-secondary-inverse:focus-visible {
  color: var(--color--text-primary)
}

div#onetrust-consent-sdk .button-secondary-inverse:focus::before,
div#onetrust-consent-sdk .button-secondary-inverse:focus-visible::before {
  background: var(--color--interactive-secondary-inverse)
}

div#onetrust-consent-sdk .button-secondary-inverse:active {
  color: var(--color--text-primary)
}

div#onetrust-consent-sdk .button-secondary-inverse:active::before {
  background: var(--color--interactive-secondary-inverse-active, var(--color--interactive-secondary-active-inverse))
}

div#onetrust-consent-sdk .button-secondary-inverse[disabled],
div#onetrust-consent-sdk .button-secondary-inverse.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02);
  color: var(--color--interactive-disabled-03)
}

div#onetrust-consent-sdk .button-secondary-inverse[disabled]::before,
div#onetrust-consent-sdk .button-secondary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk .button-secondary-inverse[disabled]:hover::before,
div#onetrust-consent-sdk .button-secondary-inverse.button-disabled:hover::before,
div#onetrust-consent-sdk .button-secondary-inverse[disabled]:focus::before,
div#onetrust-consent-sdk .button-secondary-inverse.button-disabled:focus::before,
div#onetrust-consent-sdk .button-secondary-inverse[disabled]:focus-visible::before,
div#onetrust-consent-sdk .button-secondary-inverse.button-disabled:focus-visible::before,
div#onetrust-consent-sdk .button-secondary-inverse[disabled]:active::before,
div#onetrust-consent-sdk .button-secondary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

div#onetrust-consent-sdk .button-secondary-inverse[disabled]::before,
div#onetrust-consent-sdk .button-secondary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk .button-secondary-inverse[disabled]:hover::before,
div#onetrust-consent-sdk .button-secondary-inverse.button-disabled:hover::before,
div#onetrust-consent-sdk .button-secondary-inverse[disabled]:focus::before,
div#onetrust-consent-sdk .button-secondary-inverse.button-disabled:focus::before,
div#onetrust-consent-sdk .button-secondary-inverse[disabled]:focus-visible::before,
div#onetrust-consent-sdk .button-secondary-inverse.button-disabled:focus-visible::before,
div#onetrust-consent-sdk .button-secondary-inverse[disabled]:active::before,
div#onetrust-consent-sdk .button-secondary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk .button-tertiary {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary);
  transition: color .125s ease-in-out
}

@media(min-width:376px) {
  div#onetrust-consent-sdk .button-tertiary {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  div#onetrust-consent-sdk .button-tertiary {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

div#onetrust-consent-sdk .button-tertiary::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

div#onetrust-consent-sdk .button-tertiary:hover,
div#onetrust-consent-sdk .button-tertiary:focus,
div#onetrust-consent-sdk .button-tertiary:focus-visible,
div#onetrust-consent-sdk .button-tertiary:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

div#onetrust-consent-sdk .button-tertiary:hover::before,
div#onetrust-consent-sdk .button-tertiary:focus::before,
div#onetrust-consent-sdk .button-tertiary:focus-visible::before,
div#onetrust-consent-sdk .button-tertiary:active::before {
  transform: scale(1.05)
}

div#onetrust-consent-sdk .button-tertiary:focus,
div#onetrust-consent-sdk .button-tertiary:focus-visible {
  outline: 0
}

div#onetrust-consent-sdk .button-tertiary:focus::before,
div#onetrust-consent-sdk .button-tertiary:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

div#onetrust-consent-sdk .button-tertiary:active::before {
  outline-color: transparent
}

div#onetrust-consent-sdk .button-tertiary:only-of-type {
  margin: 0
}

div#onetrust-consent-sdk .button-tertiary.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

div#onetrust-consent-sdk .button-tertiary::before {
  outline-color: var(--color--interactive-tertiary);
  outline-offset: -2px;
  background-color: transparent
}

div#onetrust-consent-sdk .button-tertiary:hover {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk .button-tertiary:hover::before {
  outline-color: var(--color--interactive-tertiary-hover);
  background: var(--color--interactive-tertiary-hover)
}

div#onetrust-consent-sdk .button-tertiary:focus,
div#onetrust-consent-sdk .button-tertiary:focus-visible {
  color: var(--color--text-tertiary)
}

div#onetrust-consent-sdk .button-tertiary:focus::before,
div#onetrust-consent-sdk .button-tertiary:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

div#onetrust-consent-sdk .button-tertiary:active {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk .button-tertiary:active::before {
  background: var(--color--interactive-tertiary-active)
}

div#onetrust-consent-sdk .button-tertiary[disabled],
div#onetrust-consent-sdk .button-tertiary.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

div#onetrust-consent-sdk .button-tertiary[disabled]::before,
div#onetrust-consent-sdk .button-tertiary.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk .button-tertiary[disabled]:hover::before,
div#onetrust-consent-sdk .button-tertiary.button-disabled:hover::before,
div#onetrust-consent-sdk .button-tertiary[disabled]:focus::before,
div#onetrust-consent-sdk .button-tertiary.button-disabled:focus::before,
div#onetrust-consent-sdk .button-tertiary[disabled]:focus-visible::before,
div#onetrust-consent-sdk .button-tertiary.button-disabled:focus-visible::before,
div#onetrust-consent-sdk .button-tertiary[disabled]:active::before,
div#onetrust-consent-sdk .button-tertiary.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

div#onetrust-consent-sdk .button-tertiary[disabled]::before,
div#onetrust-consent-sdk .button-tertiary.button-disabled::before {
  outline-color: var(--color--interactive-disabled-01);
  outline-offset: -2px;
  background: transparent
}

div#onetrust-consent-sdk .button-tertiary[disabled]:hover::before,
div#onetrust-consent-sdk .button-tertiary.button-disabled:hover::before,
div#onetrust-consent-sdk .button-tertiary[disabled]:focus::before,
div#onetrust-consent-sdk .button-tertiary.button-disabled:focus::before,
div#onetrust-consent-sdk .button-tertiary[disabled]:focus-visible::before,
div#onetrust-consent-sdk .button-tertiary.button-disabled:focus-visible::before,
div#onetrust-consent-sdk .button-tertiary[disabled]:active::before,
div#onetrust-consent-sdk .button-tertiary.button-disabled:active::before {
  background: transparent
}

div#onetrust-consent-sdk .button-tertiary-inverse {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary-inverse)
}

@media(min-width:376px) {
  div#onetrust-consent-sdk .button-tertiary-inverse {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  div#onetrust-consent-sdk .button-tertiary-inverse {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

div#onetrust-consent-sdk .button-tertiary-inverse::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

div#onetrust-consent-sdk .button-tertiary-inverse:hover,
div#onetrust-consent-sdk .button-tertiary-inverse:focus,
div#onetrust-consent-sdk .button-tertiary-inverse:focus-visible,
div#onetrust-consent-sdk .button-tertiary-inverse:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

div#onetrust-consent-sdk .button-tertiary-inverse:hover::before,
div#onetrust-consent-sdk .button-tertiary-inverse:focus::before,
div#onetrust-consent-sdk .button-tertiary-inverse:focus-visible::before,
div#onetrust-consent-sdk .button-tertiary-inverse:active::before {
  transform: scale(1.05)
}

div#onetrust-consent-sdk .button-tertiary-inverse:focus,
div#onetrust-consent-sdk .button-tertiary-inverse:focus-visible {
  outline: 0
}

div#onetrust-consent-sdk .button-tertiary-inverse:focus::before,
div#onetrust-consent-sdk .button-tertiary-inverse:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

div#onetrust-consent-sdk .button-tertiary-inverse:active::before {
  outline-color: transparent
}

div#onetrust-consent-sdk .button-tertiary-inverse:only-of-type {
  margin: 0
}

div#onetrust-consent-sdk .button-tertiary-inverse.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

div#onetrust-consent-sdk .button-tertiary-inverse::before {
  outline-color: var(--color--interactive-tertiary-inverse);
  outline-offset: -2px;
  background: transparent
}

div#onetrust-consent-sdk .button-tertiary-inverse:hover {
  color: var(--color--text-primary)
}

div#onetrust-consent-sdk .button-tertiary-inverse:hover::before {
  outline-color: transparent;
  background: var(--color--interactive-tertiary-inverse-hover, var(--color--interactive-tertiary-hover-inverse))
}

div#onetrust-consent-sdk .button-tertiary-inverse:focus,
div#onetrust-consent-sdk .button-tertiary-inverse:focus-visible {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk .button-tertiary-inverse:focus::before,
div#onetrust-consent-sdk .button-tertiary-inverse:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

div#onetrust-consent-sdk .button-tertiary-inverse:active {
  color: var(--color--text-primary)
}

div#onetrust-consent-sdk .button-tertiary-inverse:active::before {
  background: var(--color--interactive-tertiary-inverse-active, var(--color--interactive-tertiary-active-inverse))
}

div#onetrust-consent-sdk .button-tertiary-inverse[disabled],
div#onetrust-consent-sdk .button-tertiary-inverse.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

div#onetrust-consent-sdk .button-tertiary-inverse[disabled]::before,
div#onetrust-consent-sdk .button-tertiary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk .button-tertiary-inverse[disabled]:hover::before,
div#onetrust-consent-sdk .button-tertiary-inverse.button-disabled:hover::before,
div#onetrust-consent-sdk .button-tertiary-inverse[disabled]:focus::before,
div#onetrust-consent-sdk .button-tertiary-inverse.button-disabled:focus::before,
div#onetrust-consent-sdk .button-tertiary-inverse[disabled]:focus-visible::before,
div#onetrust-consent-sdk .button-tertiary-inverse.button-disabled:focus-visible::before,
div#onetrust-consent-sdk .button-tertiary-inverse[disabled]:active::before,
div#onetrust-consent-sdk .button-tertiary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

div#onetrust-consent-sdk .button-tertiary-inverse[disabled]::before,
div#onetrust-consent-sdk .button-tertiary-inverse.button-disabled::before {
  outline-color: var(--color--interactive-disabled-02);
  background: transparent
}

div#onetrust-consent-sdk .button-tertiary-inverse[disabled]:hover::before,
div#onetrust-consent-sdk .button-tertiary-inverse.button-disabled:hover::before,
div#onetrust-consent-sdk .button-tertiary-inverse[disabled]:focus::before,
div#onetrust-consent-sdk .button-tertiary-inverse.button-disabled:focus::before,
div#onetrust-consent-sdk .button-tertiary-inverse[disabled]:focus-visible::before,
div#onetrust-consent-sdk .button-tertiary-inverse.button-disabled:focus-visible::before,
div#onetrust-consent-sdk .button-tertiary-inverse[disabled]:active::before,
div#onetrust-consent-sdk .button-tertiary-inverse.button-disabled:active::before {
  background: transparent
}

div#onetrust-consent-sdk .button-action {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary)
}

@media(min-width:376px) {
  div#onetrust-consent-sdk .button-action {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  div#onetrust-consent-sdk .button-action {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

div#onetrust-consent-sdk .button-action::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

div#onetrust-consent-sdk .button-action:hover,
div#onetrust-consent-sdk .button-action:focus,
div#onetrust-consent-sdk .button-action:focus-visible,
div#onetrust-consent-sdk .button-action:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

div#onetrust-consent-sdk .button-action:hover::before,
div#onetrust-consent-sdk .button-action:focus::before,
div#onetrust-consent-sdk .button-action:focus-visible::before,
div#onetrust-consent-sdk .button-action:active::before {
  transform: scale(1.05)
}

div#onetrust-consent-sdk .button-action:focus,
div#onetrust-consent-sdk .button-action:focus-visible {
  outline: 0
}

div#onetrust-consent-sdk .button-action:focus::before,
div#onetrust-consent-sdk .button-action:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

div#onetrust-consent-sdk .button-action:active::before {
  outline-color: transparent
}

div#onetrust-consent-sdk .button-action:only-of-type {
  margin: 0
}

div#onetrust-consent-sdk .button-action.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

div#onetrust-consent-sdk .button-action::before {
  background: var(--color--interactive-action) border-box
}

div#onetrust-consent-sdk .button-action:hover::before {
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-action-hover) border-box
}

div#onetrust-consent-sdk .button-action:focus,
div#onetrust-consent-sdk .button-action:focus-visible {
  color: var(--color--text-primary)
}

div#onetrust-consent-sdk .button-action:focus::before,
div#onetrust-consent-sdk .button-action:focus-visible::before {
  background: var(--color--interactive-action-focus) border-box
}

div#onetrust-consent-sdk .button-action:active {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk .button-action:active::before {
  background: var(--color--interactive-action-active) border-box
}

div#onetrust-consent-sdk .button-action[disabled],
div#onetrust-consent-sdk .button-action.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

div#onetrust-consent-sdk .button-action[disabled]::before,
div#onetrust-consent-sdk .button-action.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk .button-action[disabled]:hover::before,
div#onetrust-consent-sdk .button-action.button-disabled:hover::before,
div#onetrust-consent-sdk .button-action[disabled]:focus::before,
div#onetrust-consent-sdk .button-action.button-disabled:focus::before,
div#onetrust-consent-sdk .button-action[disabled]:focus-visible::before,
div#onetrust-consent-sdk .button-action.button-disabled:focus-visible::before,
div#onetrust-consent-sdk .button-action[disabled]:active::before,
div#onetrust-consent-sdk .button-action.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

div#onetrust-consent-sdk .button-action[disabled]::before,
div#onetrust-consent-sdk .button-action.button-disabled::before {
  outline-offset: -2px;
  outline-color: var(--color--interactive-disabled-01);
  background: transparent
}

div#onetrust-consent-sdk .button-action[disabled]:hover::before,
div#onetrust-consent-sdk .button-action.button-disabled:hover::before,
div#onetrust-consent-sdk .button-action[disabled]:focus::before,
div#onetrust-consent-sdk .button-action.button-disabled:focus::before,
div#onetrust-consent-sdk .button-action[disabled]:focus-visible::before,
div#onetrust-consent-sdk .button-action.button-disabled:focus-visible::before,
div#onetrust-consent-sdk .button-action[disabled]:active::before,
div#onetrust-consent-sdk .button-action.button-disabled:active::before {
  background: transparent
}

div#onetrust-consent-sdk .button-action-inverse {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary)
}

@media(min-width:376px) {
  div#onetrust-consent-sdk .button-action-inverse {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  div#onetrust-consent-sdk .button-action-inverse {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

div#onetrust-consent-sdk .button-action-inverse::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

div#onetrust-consent-sdk .button-action-inverse:hover,
div#onetrust-consent-sdk .button-action-inverse:focus,
div#onetrust-consent-sdk .button-action-inverse:focus-visible,
div#onetrust-consent-sdk .button-action-inverse:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

div#onetrust-consent-sdk .button-action-inverse:hover::before,
div#onetrust-consent-sdk .button-action-inverse:focus::before,
div#onetrust-consent-sdk .button-action-inverse:focus-visible::before,
div#onetrust-consent-sdk .button-action-inverse:active::before {
  transform: scale(1.05)
}

div#onetrust-consent-sdk .button-action-inverse:focus,
div#onetrust-consent-sdk .button-action-inverse:focus-visible {
  outline: 0
}

div#onetrust-consent-sdk .button-action-inverse:focus::before,
div#onetrust-consent-sdk .button-action-inverse:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

div#onetrust-consent-sdk .button-action-inverse:active::before {
  outline-color: transparent
}

div#onetrust-consent-sdk .button-action-inverse:only-of-type {
  margin: 0
}

div#onetrust-consent-sdk .button-action-inverse.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

div#onetrust-consent-sdk .button-action-inverse::before {
  background: var(--color--interactive-action-inverse) border-box
}

div#onetrust-consent-sdk .button-action-inverse:hover {
  color: var(--color--text-primary)
}

div#onetrust-consent-sdk .button-action-inverse:hover::before {
  background: var(--color--interactive-action-inverse-hover, var(--color--interactive-action-hover-inverse)) border-box
}

div#onetrust-consent-sdk .button-action-inverse:focus,
div#onetrust-consent-sdk .button-action-inverse:focus-visible {
  color: var(--color--text-primary)
}

div#onetrust-consent-sdk .button-action-inverse:focus::before,
div#onetrust-consent-sdk .button-action-inverse:focus-visible::before {
  background: var(--color--interactive-action-focus) border-box
}

div#onetrust-consent-sdk .button-action-inverse:active {
  color: var(--color--text-primary)
}

div#onetrust-consent-sdk .button-action-inverse:active::before {
  background: var(--color--interactive-action-inverse-active, var(--color--interactive-action-active-inverse)) border-box
}

div#onetrust-consent-sdk .button-action-inverse[disabled],
div#onetrust-consent-sdk .button-action-inverse.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

div#onetrust-consent-sdk .button-action-inverse[disabled]::before,
div#onetrust-consent-sdk .button-action-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk .button-action-inverse[disabled]:hover::before,
div#onetrust-consent-sdk .button-action-inverse.button-disabled:hover::before,
div#onetrust-consent-sdk .button-action-inverse[disabled]:focus::before,
div#onetrust-consent-sdk .button-action-inverse.button-disabled:focus::before,
div#onetrust-consent-sdk .button-action-inverse[disabled]:focus-visible::before,
div#onetrust-consent-sdk .button-action-inverse.button-disabled:focus-visible::before,
div#onetrust-consent-sdk .button-action-inverse[disabled]:active::before,
div#onetrust-consent-sdk .button-action-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

div#onetrust-consent-sdk .button-action-inverse[disabled]::before,
div#onetrust-consent-sdk .button-action-inverse.button-disabled::before {
  outline-offset: -2px;
  outline-color: var(--color--interactive-disabled-02);
  background: transparent
}

div#onetrust-consent-sdk .button-action-inverse[disabled]:hover::before,
div#onetrust-consent-sdk .button-action-inverse.button-disabled:hover::before,
div#onetrust-consent-sdk .button-action-inverse[disabled]:focus::before,
div#onetrust-consent-sdk .button-action-inverse.button-disabled:focus::before,
div#onetrust-consent-sdk .button-action-inverse[disabled]:focus-visible::before,
div#onetrust-consent-sdk .button-action-inverse.button-disabled:focus-visible::before,
div#onetrust-consent-sdk .button-action-inverse[disabled]:active::before,
div#onetrust-consent-sdk .button-action-inverse.button-disabled:active::before {
  background: transparent
}

div#onetrust-consent-sdk .button-small {
  font-size: var(--typesize-minor);
  padding: .25em 1.25em;
  min-height: 2rem
}

div#onetrust-consent-sdk .button-small.icon-only {
  aspect-ratio: 1;
  padding: .5em;
  max-width: 2rem
}

div#onetrust-consent-sdk .button-large {
  padding: .75em 1.25em;
  min-height: 3rem
}

div#onetrust-consent-sdk .button-large.icon-only {
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

div#onetrust-consent-sdk .button-block {
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-right: 0
}

div#onetrust-consent-sdk .button-unstyled {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0
}

div#onetrust-consent-sdk .button-unstyled::before {
  display: none;
  background: transparent;
  border: 0;
  box-shadow: none
}

div#onetrust-consent-sdk [class*="button-circular-"] {
  line-height: var(--leading--1);
  padding: 0;
  aspect: 1
}

div#onetrust-consent-sdk [class*="button-circular-"]::before {
  border-radius: 50%;
  aspect-ratio: 1
}

div#onetrust-consent-sdk .button-circular-large {
  width: 2.5em;
  height: 2.5em
}

div#onetrust-consent-sdk .button-circular-medium {
  width: 2.25em;
  height: 2.25em
}

div#onetrust-consent-sdk .button-circular-small {
  width: 2em;
  height: 2em
}

div#onetrust-consent-sdk .button-interactive a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-p);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  background: var(--color--label-interactive);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-radius: var(--button-corners);
  padding: var(--spacing-10) var(--spacing-15);
  display: inline-block;
  min-height: 1.5rem;
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  div#onetrust-consent-sdk .button-interactive a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  div#onetrust-consent-sdk .button-interactive a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

div#onetrust-consent-sdk .button-interactive a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

div#onetrust-consent-sdk .button-interactive a:hover,
div#onetrust-consent-sdk .button-interactive a:focus,
div#onetrust-consent-sdk .button-interactive a:focus-visible,
div#onetrust-consent-sdk .button-interactive a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

div#onetrust-consent-sdk .button-interactive a:hover::before,
div#onetrust-consent-sdk .button-interactive a:focus::before,
div#onetrust-consent-sdk .button-interactive a:focus-visible::before,
div#onetrust-consent-sdk .button-interactive a:active::before {
  transform: scale(1.05)
}

div#onetrust-consent-sdk .button-interactive a:focus,
div#onetrust-consent-sdk .button-interactive a:focus-visible {
  outline: 0
}

div#onetrust-consent-sdk .button-interactive a:focus::before,
div#onetrust-consent-sdk .button-interactive a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

div#onetrust-consent-sdk .button-interactive a:active::before {
  outline-color: transparent
}

div#onetrust-consent-sdk .button-interactive a:only-of-type {
  margin: 0
}

div#onetrust-consent-sdk .button-interactive a.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

div#onetrust-consent-sdk .button-interactive a:hover {
  color: var(--color--text-link-hover)
}

div#onetrust-consent-sdk .button-interactive a:focus {
  color: var(--color--text-link)
}

div#onetrust-consent-sdk .button-interactive a:focus::before {
  transform: scale(1)
}

div#onetrust-consent-sdk .button-interactive a:active {
  color: var(--color--text-link-hover);
  text-decoration: none
}

div#onetrust-consent-sdk .button-non-interactive a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-p);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-radius: var(--button-corners);
  padding: var(--spacing-10) var(--spacing-15);
  display: inline-block;
  min-height: 1.5rem;
  padding: 0 var(--spacing-10);
  pointer-events: none;
  color: var(--color--text-tertiary);
  background: var(--color--label-interactive);
  border: 2px solid var(--color--ui-border-03, var(--color--border-03));
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  div#onetrust-consent-sdk .button-non-interactive a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  div#onetrust-consent-sdk .button-non-interactive a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

div#onetrust-consent-sdk .button-non-interactive a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

div#onetrust-consent-sdk .button-non-interactive a:hover,
div#onetrust-consent-sdk .button-non-interactive a:focus,
div#onetrust-consent-sdk .button-non-interactive a:focus-visible,
div#onetrust-consent-sdk .button-non-interactive a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

div#onetrust-consent-sdk .button-non-interactive a:hover::before,
div#onetrust-consent-sdk .button-non-interactive a:focus::before,
div#onetrust-consent-sdk .button-non-interactive a:focus-visible::before,
div#onetrust-consent-sdk .button-non-interactive a:active::before {
  transform: scale(1.05)
}

div#onetrust-consent-sdk .button-non-interactive a:focus,
div#onetrust-consent-sdk .button-non-interactive a:focus-visible {
  outline: 0
}

div#onetrust-consent-sdk .button-non-interactive a:focus::before,
div#onetrust-consent-sdk .button-non-interactive a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

div#onetrust-consent-sdk .button-non-interactive a:active::before {
  outline-color: transparent
}

div#onetrust-consent-sdk .button-non-interactive a:only-of-type {
  margin: 0
}

div#onetrust-consent-sdk .button-non-interactive a.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

div#onetrust-consent-sdk .button-non-interactive a:hover {
  color: var(--color--text-link-hover)
}

div#onetrust-consent-sdk .button-non-interactive a:focus {
  color: var(--color--text-link)
}

div#onetrust-consent-sdk .button-non-interactive a:focus::before {
  transform: scale(1)
}

div#onetrust-consent-sdk .button-non-interactive a:active {
  color: var(--color--text-link-hover);
  text-decoration: none
}

div#onetrust-consent-sdk .button-non-interactive a a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-p);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  background: var(--color--label-interactive);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-radius: var(--button-corners);
  padding: var(--spacing-10) var(--spacing-15);
  display: inline-block;
  min-height: 1.5rem;
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  div#onetrust-consent-sdk .button-non-interactive a a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  div#onetrust-consent-sdk .button-non-interactive a a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

div#onetrust-consent-sdk .button-non-interactive a a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

div#onetrust-consent-sdk .button-non-interactive a a:hover,
div#onetrust-consent-sdk .button-non-interactive a a:focus,
div#onetrust-consent-sdk .button-non-interactive a a:focus-visible,
div#onetrust-consent-sdk .button-non-interactive a a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

div#onetrust-consent-sdk .button-non-interactive a a:hover::before,
div#onetrust-consent-sdk .button-non-interactive a a:focus::before,
div#onetrust-consent-sdk .button-non-interactive a a:focus-visible::before,
div#onetrust-consent-sdk .button-non-interactive a a:active::before {
  transform: scale(1.05)
}

div#onetrust-consent-sdk .button-non-interactive a a:focus,
div#onetrust-consent-sdk .button-non-interactive a a:focus-visible {
  outline: 0
}

div#onetrust-consent-sdk .button-non-interactive a a:focus::before,
div#onetrust-consent-sdk .button-non-interactive a a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

div#onetrust-consent-sdk .button-non-interactive a a:active::before {
  outline-color: transparent
}

div#onetrust-consent-sdk .button-non-interactive a a:only-of-type {
  margin: 0
}

div#onetrust-consent-sdk .button-non-interactive a a.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

div#onetrust-consent-sdk .button-non-interactive a a:hover {
  color: var(--color--text-link-hover)
}

div#onetrust-consent-sdk .button-non-interactive a a:focus {
  color: var(--color--text-link)
}

div#onetrust-consent-sdk .button-non-interactive a a:focus::before {
  transform: scale(1)
}

div#onetrust-consent-sdk .button-non-interactive a a:active {
  color: var(--color--text-link-hover);
  text-decoration: none
}

div#onetrust-consent-sdk .button-interactive-tertiary a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-secondary);
  padding: var(--spacing-10) 25px;
  border-radius: var(--button-corners);
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  div#onetrust-consent-sdk .button-interactive-tertiary a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  div#onetrust-consent-sdk .button-interactive-tertiary a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

div#onetrust-consent-sdk .button-interactive-tertiary a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

div#onetrust-consent-sdk .button-interactive-tertiary a:hover,
div#onetrust-consent-sdk .button-interactive-tertiary a:focus,
div#onetrust-consent-sdk .button-interactive-tertiary a:focus-visible,
div#onetrust-consent-sdk .button-interactive-tertiary a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

div#onetrust-consent-sdk .button-interactive-tertiary a:hover::before,
div#onetrust-consent-sdk .button-interactive-tertiary a:focus::before,
div#onetrust-consent-sdk .button-interactive-tertiary a:focus-visible::before,
div#onetrust-consent-sdk .button-interactive-tertiary a:active::before {
  transform: scale(1.05)
}

div#onetrust-consent-sdk .button-interactive-tertiary a:focus,
div#onetrust-consent-sdk .button-interactive-tertiary a:focus-visible {
  outline: 0
}

div#onetrust-consent-sdk .button-interactive-tertiary a:focus::before,
div#onetrust-consent-sdk .button-interactive-tertiary a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

div#onetrust-consent-sdk .button-interactive-tertiary a:active::before {
  outline-color: transparent
}

div#onetrust-consent-sdk .button-interactive-tertiary a:only-of-type {
  margin: 0
}

div#onetrust-consent-sdk .button-interactive-tertiary a.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

div#onetrust-consent-sdk .button-interactive-tertiary a::before {
  background: var(--color--label-interactive);
  border: 1px solid rgba(39, 49, 49, 0.15)
}

div#onetrust-consent-sdk .button-interactive-tertiary a:hover {
  color: var(--color--text-secondary)
}

div#onetrust-consent-sdk .button-interactive-tertiary a:hover::before {
  background: #e6ecef
}

div#onetrust-consent-sdk .button-interactive-tertiary a:focus,
div#onetrust-consent-sdk .button-interactive-tertiary a:active {
  color: var(--color--text-secondary);
  background: var(--color--label-interactive)
}

div#onetrust-consent-sdk * {
  box-sizing: border-box
}

@keyframes iconOffToOn {
  from {
    color: var(--color--text-primary-inverse);
    opacity: 1;
    transform: translateY(var(--toggle-off-icon-pull)) scale(1) rotate(0);
    border-width: 0;
    left: var(--toggle-off-icon-left);
    width: auto;
    height: auto
  }

  50% {
    opacity: 0;
    color: var(--color--text-primary-inverse);
    transform: translateY(var(--toggle-off-icon-pull)) scale(0.1) rotate(0);
    left: var(--toggle-off-icon-left);
    border-width: 0;
    width: auto;
    height: auto
  }

  55% {
    opacity: 0;
    transform: translateY(var(--toggle-off-icon-pull)) scale(0.1) rotate(0);
    color: transparent;
    left: var(--toggle-ckeck-icon-left);
    border-width: 0 0 3px 3px;
    width: 10px;
    height: 5px
  }

  to {
    opacity: 1;
    left: var(--toggle-ckeck-icon-left);
    transform: scale(1) translateY(var(--toggle-check-icon-pull)) rotate(-45deg);
    color: transparent;
    border-width: 0 0 3px 3px
  }
}

@keyframes iconOnToOff {
  from {
    opacity: 1;
    left: var(--toggle-ckeck-icon-left);
    transform: scale(1) translateY(var(--toggle-check-icon-pull)) rotate(-45deg);
    color: transparent;
    border-width: 0 0 3px 3px;
    width: 10px;
    height: 5px
  }

  50% {
    opacity: 0;
    transform: translateY(var(--toggle-check-icon-pull)) scale(0.1) rotate(0);
    color: transparent;
    left: var(--toggle-ckeck-icon-left);
    border-width: 0 0 3px 3px;
    width: 10px;
    height: 5px
  }

  55% {
    opacity: 0;
    color: var(--color--text-primary-inverse);
    transform: translateY(var(--toggle-off-icon-pull)) scale(0.1) rotate(0);
    left: var(--toggle-off-icon-left);
    border-width: 0;
    width: auto;
    height: auto
  }

  to {
    color: var(--color--text-primary-inverse);
    opacity: 1;
    transform: translateY(var(--toggle-off-icon-pull)) scale(1) rotate(0);
    border-width: 0;
    left: var(--toggle-off-icon-left);
    width: auto;
    height: auto
  }
}

div#onetrust-consent-sdk div#onetrust-banner-sdk {
  background-color: var(--OT-banner-bg-color)
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div.ot-sdk-container {
  width: auto;
  margin: 0;
  padding: 0
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div.ot-sdk-container::after {
  content: unset
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div.ot-sdk-row {
  display: flex;
  flex-flow: var(--OT-banner-flow);
  align-items: var(--OT-banner-aligning);
  justify-content: space-between;
  width: auto;
  max-width: var(--OT-grid-max-width);
  margin: auto
}

@media(max-width:767px) {
  div#onetrust-consent-sdk div#onetrust-banner-sdk div.ot-sdk-row {
    padding-block: var(--space-medium)
  }
}

@media(max-width:768px) {
  div#onetrust-consent-sdk div#onetrust-banner-sdk div.ot-sdk-row {
    padding-inline: var(--OT-grid-margin-sm)
  }
}

@media(min-width:768px) {
  div#onetrust-consent-sdk div#onetrust-banner-sdk div.ot-sdk-row {
    padding-block: calc(8 * var(--space-unit))
  }
}

@media(min-width:769px) and (max-width:1589px) {
  div#onetrust-consent-sdk div#onetrust-banner-sdk div.ot-sdk-row {
    padding-inline: var(--OT-grid-margin-md)
  }
}

@media(min-width:1590px) {
  div#onetrust-consent-sdk div#onetrust-banner-sdk div.ot-sdk-row {
    padding-inline: var(--OT-grid-margin-lg);
    max-width: 1590px
  }
}

@media(max-width:1024px) {
  div#onetrust-consent-sdk div#onetrust-banner-sdk div.ot-sdk-row {
    row-gap: var(--OT-grid-gap)
  }
}

@media(min-width:1025px) {
  div#onetrust-consent-sdk div#onetrust-banner-sdk div.ot-sdk-row {
    column-gap: var(--space-medium)
  }
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div.ot-sdk-row::after {
  content: unset
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-group-container,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group-parent {
  position: relative;
  width: auto;
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
  margin: 0;
  padding: unset;
  transform: unset
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group-parent {
  flex-grow: 1;
  flex-shrink: 0;
  width: var(--OT-banner-cta-block-w);
  float: unset
}

div#onetrust-consent-sdk div#onetrust-banner-sdk #onetrust-policy {
  padding: 0;
  margin: 0;
  font-family: var(--typefamily-primary)
}

div#onetrust-consent-sdk div#onetrust-banner-sdk #onetrust-policy-title,
div#onetrust-consent-sdk div#onetrust-banner-sdk #onetrust-policy-text {
  color: var(--OT-banner-stroke-color)
}

div#onetrust-consent-sdk div#onetrust-banner-sdk #onetrust-policy-title {
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-6);
  margin-block-end: calc(4 * var(--OT-space-unit))
}

div#onetrust-consent-sdk div#onetrust-banner-sdk #onetrust-policy-text {
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-6);
  margin-block-end: 0
}

div#onetrust-consent-sdk div#onetrust-banner-sdk #onetrust-policy-text a {
  font-weight: var(--typeweight--semibold)
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group {
  display: flex;
  flex-flow: var(--OT-banner-cta-block-flow);
  align-items: center;
  justify-content: flex-end;
  gap: calc(5 * var(--OT-space-unit));
  width: auto;
  margin-block-start: 0;
  margin-inline-end: 0
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary-inverse);
  padding: .75em 1.25em;
  min-height: 3rem;
  white-space: nowrap
}

@media(min-width:376px) {

  div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler,
  div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler,
  div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {

  div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler,
  div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler,
  div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:hover,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:hover,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:hover,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:focus,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:focus,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:focus,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:focus-visible,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:focus-visible,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:focus-visible,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:active,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:active,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:hover::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:hover::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:hover::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:active::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:active::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:active::before {
  transform: scale(1.05)
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:focus,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:focus,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:focus,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:focus-visible,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:focus-visible,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:focus-visible {
  outline: 0
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:active::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:active::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:active::before {
  outline-color: transparent
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:only-of-type,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:only-of-type,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:only-of-type {
  margin: 0
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler.icon-only,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler.icon-only,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler::before {
  outline-color: var(--color--interactive-tertiary-inverse);
  outline-offset: -2px;
  background: transparent
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:hover,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:hover,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:hover {
  color: var(--color--text-primary)
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:hover::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:hover::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:hover::before {
  outline-color: transparent;
  background: var(--color--interactive-tertiary-inverse-hover, var(--color--interactive-tertiary-hover-inverse))
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:focus,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:focus,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:focus,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:focus-visible,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:focus-visible,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:focus-visible {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:active,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:active,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:active {
  color: var(--color--text-primary)
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler:active::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler:active::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler:active::before {
  background: var(--color--interactive-tertiary-inverse-active, var(--color--interactive-tertiary-active-inverse))
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler[disabled],
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler[disabled],
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler[disabled],
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler.button-disabled,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler.button-disabled,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler[disabled]::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler[disabled]::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler[disabled]::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler.button-disabled::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler.button-disabled::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler[disabled]:hover::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler[disabled]:hover::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler[disabled]:hover::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler.button-disabled:hover::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler.button-disabled:hover::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler.button-disabled:hover::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler[disabled]:focus::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler[disabled]:focus::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler[disabled]:focus::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler.button-disabled:focus::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler.button-disabled:focus::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler.button-disabled:focus::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler[disabled]:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler[disabled]:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler[disabled]:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler.button-disabled:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler.button-disabled:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler.button-disabled:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler[disabled]:active::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler[disabled]:active::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler[disabled]:active::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler.button-disabled:active::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler.button-disabled:active::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler[disabled]::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler[disabled]::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler[disabled]::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler.button-disabled::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler.button-disabled::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler.button-disabled::before {
  outline-color: var(--color--interactive-disabled-02);
  background: transparent
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler[disabled]:hover::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler[disabled]:hover::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler[disabled]:hover::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler.button-disabled:hover::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler.button-disabled:hover::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler.button-disabled:hover::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler[disabled]:focus::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler[disabled]:focus::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler[disabled]:focus::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler.button-disabled:focus::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler.button-disabled:focus::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler.button-disabled:focus::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler[disabled]:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler[disabled]:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler[disabled]:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler.button-disabled:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler.button-disabled:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler.button-disabled:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler[disabled]:active::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler[disabled]:active::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler[disabled]:active::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler.button-disabled:active::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler.button-disabled:active::before,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler.button-disabled:active::before {
  background: transparent
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler.icon-only,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler.icon-only,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler.icon-only {
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-pc-btn-handler,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-reject-all-handler,
div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button#onetrust-accept-btn-handler {
  flex-grow: var(--OT-banner-cta-grow);
  margin: unset;
  opacity: 1;
  letter-spacing: unset
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button {
  width: var(--OT-banner-cta-w);
  margin-bottom: var(--OT-banner-cta-bottom-spacing);
  max-width: var(--OT-banner-cta-mw)
}

div#onetrust-consent-sdk div#onetrust-banner-sdk div#onetrust-button-group button:last-child {
  margin-inline-end: 0 !important;
  margin-block-end: 0 !important
}

div#onetrust-consent-sdk div#onetrust-pc-sdk {
  display: flex;
  flex-flow: column;
  justify-content: stretch;
  width: var(--OT-modal-w);
  min-width: unset;
  position: fixed;
  top: var(--OT-modal-top);
  transform: var(--OT-modal-transform)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk [role="alertdialog"] {
  display: flex;
  flex-direction: column
}

div#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-logo {
  position: fixed;
  z-index: 3
}

div#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-logo img {
  width: 116px
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-header {
  position: relative;
  height: var(--OT-modal-header-h);
  box-sizing: border-box;
  flex-shrink: 0
}

@media(min-width:768px) {
  div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-header {
    padding: var(--spacing-20) var(--spacing-30)
  }
}

@media(max-width:767px) {
  div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-header {
    padding: var(--spacing-15) var(--spacing-20);
    --OT-modal-header-h: 70px
  }
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-header #close-pc-btn-handler {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: var(--OT-modal-close-btn-side);
  height: var(--OT-modal-close-btn-side);
  top: 50%;
  right: var(--OT-modal-close-btn-right);
  transform: translateY(-50%);
  background: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-header #close-pc-btn-handler::after {
  color: var(--OT-modal-stroke-color);
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg")
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-plus-minus {
  display: flex;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  margin-right: var(--spacing-20)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-plus-minus span {
  height: 12px;
  width: 2px;
  background-color: var(--OT-modal-stroke-color);
  border-radius: 2px;
  left: 50%;
  top: 0;
  margin-left: -1px
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-plus-minus span:last-child {
  transform: rotate(90deg)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-content div.ot-acc-hdr,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst section.ot-acc-hdr {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: var(--spacing-20) var(--spacing-15);
  justify-content: stretch;
  width: 100%;
  box-sizing: border-box
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content {
  position: unset;
  flex-grow: 1;
  width: auto;
  margin: 0
}

@media(min-width:768px) {
  div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content {
    padding: var(--spacing-30)
  }
}

@media(max-width:767px) {
  div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content {
    padding: var(--spacing-20)
  }
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content #ot-pc-title {
  color: var(--OT-modal-stroke-color);
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-h6);
  line-height: var(--leading--1-1);
  font-weight: var(--typeweight--semibold);
  margin: 0;
  margin-bottom: 15px
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content #ot-pc-desc {
  color: var(--OT-modal-stroke-color);
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-minor);
  line-height: var(--leading--1-5);
  margin-bottom: var(--spacing-20)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler {
  background: none !important;
  max-width: unset;
  width: var(--OT-modal-allowall-w);
  letter-spacing: unset;
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  padding: .75em 1.25em;
  min-height: 3rem
}

@media(min-width:376px) {
  div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:hover,
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:focus,
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:focus-visible,
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:active::before {
  transform: scale(1.05)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:focus,
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:focus-visible {
  outline: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:active::before {
  outline-color: transparent
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:only-of-type {
  margin: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler::before {
  background: var(--color--interactive-secondary) border-box
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:hover {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:hover::before {
  background: var(--color--interactive-secondary-hover) border-box
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:focus,
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:focus-visible {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:focus-visible::before {
  background: var(--color--interactive-secondary) border-box
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:active {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler:active::before {
  background: var(--color--interactive-secondary-active) border-box
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler[disabled],
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler[disabled]::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler[disabled]:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler.button-disabled:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler[disabled]:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler.button-disabled:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler[disabled]:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler.button-disabled:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler[disabled]:active::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content button#accept-recommended-btn-handler.icon-only {
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content a,
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content a.privacy-notice-link,
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-always-active,
div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-link-btn {
  font-family: 'typefamily-primary', var(--OT-type-family);
  color: var(--color--text-link);
  font-size: var(--typesize-minor);
  line-height: var(--leading--1-5)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content a.privacy-notice-link {
  display: inline-block;
  margin-top: var(--spacing-15);
  text-decoration: none
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content #ot-category-title {
  color: var(--OT-modal-stroke-color);
  font-family: var(--OT-type-family);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  font-weight: var(--typeweight--semibold);
  margin-bottom: var(--spacing-15);
  padding: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-cat-grp {
  margin-top: var(--spacing-20)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-cat-grp div.ot-accordion-layout.ot-cat-item {
  border: 0 solid var(--color--ui-06);
  border-width: 0 1px 1px 1px;
  border-radius: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-cat-grp div.ot-accordion-layout.ot-cat-item:first-of-type {
  margin-top: 0;
  border-top-width: 1px
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-cat-grp div.ot-accordion-layout.ot-cat-item .ot-cat-header {
  flex-grow: 1;
  font-family: var(--OT-type-family);
  font-size: var(--typesize-minor);
  line-height: var(--leading--1-5);
  font-weight: var(--typeweight--default);
  color: var(--OT-modal-stroke-color)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-cat-grp div.ot-accordion-layout.ot-cat-item label.ot-switch {
  width: var(--toggle-w);
  height: var(--toggle-h)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-cat-grp div.ot-accordion-layout.ot-cat-item .ot-tgl {
  position: relative;
  justify-self: unset;
  top: unset;
  left: unset;
  right: unset;
  bottom: unset;
  transform: none;
  height: var(--toggle-h);
  margin: calc(var(--spacing-5) * -1) 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-cat-grp div.ot-accordion-layout.ot-cat-item .ot-tgl span.ot-switch-nob {
  border-radius: 18px;
  background-color: var(--color--interactive-disabled-02);
  color: var(--color--text-primary-inverse);
  transition: all var(--transition-time) linear;
  border: 0;
  outline: 3px solid transparent;
  outline-offset: 1px
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-cat-grp div.ot-accordion-layout.ot-cat-item .ot-tgl span.ot-switch-nob::before {
  content: '';
  position: absolute;
  width: var(--toggle-runner-side);
  height: var(--toggle-runner-side);
  border-radius: 20px;
  background-color: currentColor;
  top: 50%;
  transform: translateY(-50%);
  left: var(--toggle-off-margin);
  transition: left var(--transition-time) ease
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-cat-grp div.ot-accordion-layout.ot-cat-item .ot-tgl span.ot-switch-nob::after {
  content: '\2715';
  position: absolute;
  color: var(--color--text-primary-inverse);
  left: var(--toggle-off-icon-left);
  top: 50%;
  transform: scale(1) translateY(-50%) rotate(0);
  border: 0 solid var(--color--text-primary-inverse);
  font-size: 21px;
  line-height: 1;
  animation-duration: var(--transition-time);
  animation-name: iconOnToOff
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-cat-grp div.ot-accordion-layout.ot-cat-item .ot-tgl input[type="checkbox"]:checked+.ot-switch span.ot-switch-nob {
  background-color: var(--color--interactive-primary)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-cat-grp div.ot-accordion-layout.ot-cat-item .ot-tgl input[type="checkbox"]:checked+.ot-switch span.ot-switch-nob::before {
  background-color: currentColor;
  transform: translateY(-50%);
  left: var(--toggle-on-margin)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-cat-grp div.ot-accordion-layout.ot-cat-item .ot-tgl input[type="checkbox"]:checked+.ot-switch span.ot-switch-nob::after {
  width: 10px;
  height: 5px;
  animation-duration: var(--transition-time);
  animation-delay: 0;
  animation-name: iconOffToOn;
  transform: scale(1) translateY(var(--toggle-check-icon-pull)) rotate(-45deg);
  left: var(--toggle-ckeck-icon-left);
  color: transparent;
  border-width: 0 0 3px 3px
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-cat-grp div.ot-accordion-layout.ot-cat-item .ot-tgl input:focus+.ot-switch {
  border: 0;
  outline: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-cat-grp div.ot-accordion-layout.ot-cat-item .ot-tgl input:focus+.ot-switch span.ot-switch-nob {
  outline-color: var(--color--interactive-focus-field)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-cat-grp div.ot-accordion-layout.ot-cat-item .ot-tgl:hover .ot-switch-nob {
  box-shadow: var(--shadow)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-cat-grp div.ot-accordion-layout.ot-cat-item .ot-acc-txt {
  background-color: var(--color--ui-01);
  padding: var(--spacing-15);
  margin: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-cat-grp div.ot-accordion-layout.ot-cat-item .ot-acc-txt p {
  width: 100%;
  font-family: var(--OT-type-family);
  font-size: var(--typesize-minor);
  line-height: var(--leading--1-5);
  font-weight: var(--typeweight--default);
  color: var(--OT-modal-stroke-color);
  padding: 0;
  margin: 0;
  margin-bottom: var(--spacing-10)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-cat-grp div.ot-accordion-layout.ot-cat-item .ot-acc-txt p:last-child {
  margin-bottom: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-cat-grp div.ot-accordion-layout.ot-cat-item .ot-acc-txt .ot-hlst-cntr {
  padding: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div#ot-pc-content .ot-cat-grp div.ot-accordion-layout.ot-cat-item .ot-acc-txt .ot-link-btn {
  margin-top: var(--spacing-10)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst {
  flex-grow: 1;
  position: unset
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-anchor {
  display: none !important
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr {
  padding: var(--OT-modal-content-spacing) 0 var(--spacing-15) 0;
  margin: 0 var(--OT-modal-content-spacing);
  border-bottom: 1px solid var(--color--ui-border-01, var(--color--border-01));
  width: auto
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal {
  position: relative;
  overflow: visible;
  width: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt {
  position: absolute;
  right: 0;
  left: auto;
  width: 282px;
  display: block;
  top: 100%;
  margin: 0;
  height: auto;
  max-width: 90vw;
  padding: var(--spacing-30);
  box-shadow: var(--shadow-blur)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt #clear-filters-handler {
  margin: 0;
  margin-bottom: var(--spacing-10);
  font-family: var(--OT-type-family);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  font-weight: var(--typeweight--default);
  color: var(--color--text-link)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-scrlcnt {
  overflow: visible
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-opts .ot-fltr-opt {
  width: auto;
  margin: 0;
  margin-top: var(--spacing-5)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-opts .ot-fltr-opt:first-child {
  margin-top: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-opts .ot-fltr-opt .ot-chkbox {
  display: flex;
  align-items: center
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-opts .ot-fltr-opt .ot-chkbox label {
  padding: 0;
  padding-left: var(--spacing-15);
  font-family: var(--OT-type-family);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  font-weight: var(--typeweight--default);
  color: var(--OT-modal-stroke-color)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns {
  display: flex;
  margin: 0;
  padding: 0;
  padding-top: var(--spacing-20)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary);
  transition: color .125s ease-in-out
}

@media(min-width:376px) {
  div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:hover,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:focus,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:focus-visible,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:active::before {
  transform: scale(1.05)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:focus,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:focus-visible {
  outline: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:active::before {
  outline-color: transparent
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:only-of-type {
  margin: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler::before {
  outline-color: var(--color--interactive-tertiary);
  outline-offset: -2px;
  background-color: transparent
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:hover {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:hover::before {
  outline-color: var(--color--interactive-tertiary-hover);
  background: var(--color--interactive-tertiary-hover)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:focus,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:focus-visible {
  color: var(--color--text-tertiary)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:active {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:active::before {
  background: var(--color--interactive-tertiary-active)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler[disabled],
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler[disabled]::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler[disabled]:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler.button-disabled:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler[disabled]:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler.button-disabled:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler[disabled]:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler.button-disabled:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler[disabled]:active::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler[disabled]::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler.button-disabled::before {
  outline-color: var(--color--interactive-disabled-01);
  outline-offset: -2px;
  background: transparent
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler[disabled]:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler.button-disabled:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler[disabled]:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler.button-disabled:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler[disabled]:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler.button-disabled:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler[disabled]:active::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler.button-disabled:active::before {
  background: transparent
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1
}

@media(min-width:376px) {
  div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:hover,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:focus,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:focus-visible,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:active::before {
  transform: scale(1.05)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:focus,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:focus-visible {
  outline: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:active::before {
  outline-color: transparent
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:only-of-type {
  margin: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler::before {
  background: var(--color--interactive-secondary) border-box
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:hover {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:hover::before {
  background: var(--color--interactive-secondary-hover) border-box
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:focus,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:focus-visible {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:focus-visible::before {
  background: var(--color--interactive-secondary) border-box
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:active {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:active::before {
  background: var(--color--interactive-secondary-active) border-box
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler[disabled],
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler[disabled]::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler[disabled]:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler.button-disabled:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler[disabled]:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler.button-disabled:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler[disabled]:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler.button-disabled:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler[disabled]:active::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler {
  flex-grow: 1;
  margin: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-apply-handler:last-child,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr #ot-fltr-modal #ot-fltr-cnt .ot-fltr-btns button#filter-cancel-handler:last-child {
  margin-left: var(--spacing-10)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-lst-cnt {
  width: auto;
  margin: 0;
  padding: var(--OT-modal-content-spacing);
  padding-top: 0;
  box-sizing: border-box;
  max-height: calc(100% - var(--OT-modal-footer-maxHeight))
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-lst-cnt #ot-sel-blk {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: visible;
  height: 58px;
  border-bottom: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-top: 1px solid var(--color--ui-border-01, var(--color--border-01));
  transform: translateY(-1px)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-lst-cnt #ot-sel-blk .ot-sel-all {
  display: flex;
  align-items: center;
  padding: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-lst-cnt #ot-sel-blk .ot-sel-all .ot-sel-all-hdr {
  width: auto
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-lst-cnt #ot-sel-blk .ot-sel-all .ot-consent-hdr {
  width: auto;
  max-width: unset;
  white-space: nowrap;
  font-family: var(--OT-type-family);
  font-size: var(--typesize-minor);
  line-height: var(--leading--1-5);
  font-weight: var(--typeweight--default);
  color: var(--OT-modal-text-secondary)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-lst-cnt .ot-chkbox label {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  height: 1px;
  width: 1px;
  min-height: unset;
  overflow: hidden;
  position: absolute;
  padding: 0;
  margin: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"]:checked:not([disabled]):hover {
  z-index: auto
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr {
  display: flex
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr {
  right: unset;
  top: unset
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler {
  background: 0;
  width: unset;
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary);
  padding: .75em 1.25em;
  min-height: 3rem;
  padding: .625em;
  max-width: 2.25rem;
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

@media(min-width:376px) {
  div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:hover,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:focus,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:focus-visible,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:active::before {
  transform: scale(1.05)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:focus,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:focus-visible {
  outline: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:active::before {
  outline-color: transparent
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:only-of-type {
  margin: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler::before {
  background: var(--color--interactive-secondary-inverse)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:hover {
  color: var(--color--text-primary)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:hover::before {
  background: var(--color--interactive-secondary-inverse-hover, var(--color--interactive-secondary-hover-inverse))
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:focus,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:focus-visible {
  color: var(--color--text-primary)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:focus-visible::before {
  background: var(--color--interactive-secondary-inverse)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:active {
  color: var(--color--text-primary)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:active::before {
  background: var(--color--interactive-secondary-inverse-active, var(--color--interactive-secondary-active-inverse))
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler[disabled],
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02);
  color: var(--color--interactive-disabled-03)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler[disabled]::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler[disabled]:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler.button-disabled:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler[disabled]:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler.button-disabled:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler[disabled]:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler.button-disabled:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler[disabled]:active::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler[disabled]::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler[disabled]:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler.button-disabled:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler[disabled]:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler.button-disabled:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler[disabled]:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler.button-disabled:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler[disabled]:active::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler.button-disabled:active::before {
  background: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler.icon-only {
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler:only-of-type {
  margin-left: var(--spacing-10)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-filter-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-filter-lg");
  position: relative
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr button#filter-btn-handler>svg {
  display: none
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-lst-title {
  display: flex;
  align-items: center;
  margin: 10px 0 30px 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-lst-title h3 {
  font-family: var(--OT-type-family);
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-1);
  color: var(--color--text-tertiary);
  margin-left: var(--spacing-10)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-lst-title button.ot-link-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 30px;
  height: 30px
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-lst-title button.ot-link-btn::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-previous-md")
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-lst-title button.ot-link-btn svg {
  display: none
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-search-cntr {
  position: relative;
  flex-grow: 1;
  padding-left: 35px
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-search-cntr input#vendor-search-handler {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  color: var(--color--text-secondary);
  background-color: var(--color--ui-field, var(--color--field));
  border: 0;
  border-radius: .25em;
  outline-width: .0625em;
  outline-style: solid;
  outline-color: var(--color--ui-border-03, var(--color--border-03));
  display: block;
  height: 2.9375em;
  width: 100%;
  padding: .75em .8125em;
  margin: 0;
  transition: all .1125s ease-in-out;
  outline-color: var(--color--ui-border-01, var(--color--border-01))
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-search-cntr input#vendor-search-handler::-webkit-input-placeholder,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-search-cntr input#vendor-search-handler::placeholder {
  color: var(--color--text-secondary)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-search-cntr input#vendor-search-handler:focus {
  outline-color: var(--color--interactive-focus-field);
  outline-width: .1875em;
  outline-offset: -0.125em
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-search-cntr::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-search-md");
  position: absolute;
  right: var(--spacing-15);
  top: 50%;
  transform: translateY(-50%)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-search-cntr>svg {
  display: none
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-sel-all-chkbox {
  flex-grow: 0;
  width: auto;
  padding: 0 var(--spacing-15) 0 var(--spacing-10)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox {
  right: unset
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox.ot-host-tgl {
  position: absolute;
  z-index: 2
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"] {
  width: unset;
  height: unset;
  opacity: unset;
  position: unset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 1em;
  height: 1em;
  aspect-ratio: 1;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color--ui-field, var(--color--field));
  outline-width: .125em;
  outline-style: solid;
  outline-color: var(--color--ui-border-03, var(--color--border-03));
  margin: 0 .8125rem 0 0;
  position: relative;
  transition: all .125s ease-in-out;
  border-radius: .125em;
  margin-right: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"]::before {
  content: "";
  display: block;
  width: 2.25em;
  height: 2.25em;
  border-radius: 50%;
  background-color: var(--color--ui-06);
  flex: none;
  margin: auto;
  position: absolute;
  z-index: -1;
  transform: scale(0);
  opacity: 0;
  transition: all .25s ease-in-out;
  outline-width: .1875em;
  outline-style: solid;
  outline-color: transparent;
  outline-offset: -0.1875em
}

.small-controlsdiv#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"]::before {
  width: 2em;
  height: 2em
}

.form-inverse div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"]::before {
  background-color: var(--color--ui-04)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"]:hover:not([disabled]) {
  outline-color: var(--color--ui-border-02, var(--color--border-02))
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"]:hover:not([disabled])::before {
  transform-origin: center;
  transform: scale(1);
  opacity: 1
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"]:focus:not([disabled]) {
  outline-color: var(--color--ui-border-02, var(--color--border-02))
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"]:focus:not([disabled])::before {
  background-color: transparent;
  transform-origin: center;
  transform: scale(1);
  opacity: 1;
  outline-color: var(--color--interactive-focus-field)
}

.small-controlsdiv#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
  outline-width: .125rem;
  outline-offset: -0.125rem
}

.small-controlsdiv#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"]:focus,
.small-controlsdiv#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"]:focus-visible {
  outline-offset: -0.125rem
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"]:checked {
  outline-color: var(--color--interactive-primary)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"]:checked:not([disabled]):hover {
  outline-color: var(--color--interactive-primary)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"]:focus,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"]:focus-visible {
  outline-color: var(--color--interactive-focus);
  outline-offset: -0.0525em
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"][required][aria-checked="false"][aria-invalid="false"] {
  outline-color: var(--color--alert-error)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"]:focus {
  outline-offset: unset
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"]:checked:not([disabled]) {
  background: var(--color--interactive-primary)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"]:checked:not([disabled]):focus {
  outline-color: var(--color--interactive-primary)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"]:checked::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 14px;
  aspect-ratio: 1;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-check");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-check");
  width: .875em;
  background: var(--color--ui-field, var(--color--field));
  margin: auto;
  position: absolute
}

@media(forced-colors:active) and (prefers-color-scheme:dark) {
  div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"]:checked::after {
    filter: brightness(0) invert(1)
  }
}

@media(forced-colors:active) and (prefers-color-scheme:light) {
  div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"]:checked::after {
    filter: brightness(1) invert(1)
  }
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"][disabled] {
  color: var(--color--interactive-disabled-02);
  background-color: var(--color--ui-01);
  outline-color: var(--color--interactive-disabled-02);
  pointer-events: none
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"][disabled]::-webkit-input-placeholder,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"][disabled]::placeholder {
  color: var(--color--interactive-disabled-02)
}

.form-inverse div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"][disabled] {
  color: var(--color--interactive-disabled-01);
  background-color: var(--color--interactive-disabled-02)
}

.form-inverse div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"][disabled]::-webkit-input-placeholder,
.form-inverse div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"][disabled]::placeholder {
  color: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"][disabled]:checked {
  outline-color: var(--color--ui-01)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"][disabled]:checked::after {
  background-color: var(--color--interactive-disabled-02)
}

.form-inverse div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"][disabled] {
  outline-color: var(--color--interactive-disabled-03)
}

.form-inverse div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"][disabled]:checked {
  outline-color: var(--color--interactive-disabled-02)
}

.form-inverse div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"][disabled]:checked::after {
  background: var(--color--interactive-disabled-03)
}

.small-controlsdiv#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[type="checkbox"]:checked::after {
  width: .75rem;
  height: .75rem
}

div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox label::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox label::after {
  content: unset
}

div#onetrust-consent-sdk div#onetrust-pc-sdk .ot-host-item {
  padding: 0;
  border-bottom: 1px solid var(--color--ui-border-01, var(--color--border-01))
}

div#onetrust-consent-sdk div#onetrust-pc-sdk .ot-host-item:last-child {
  border-bottom: unset
}

div#onetrust-consent-sdk div#onetrust-pc-sdk .ot-host-item .ot-host-name,
div#onetrust-consent-sdk div#onetrust-pc-sdk .ot-host-item .ot-host-name a,
div#onetrust-consent-sdk div#onetrust-pc-sdk .ot-host-item .ot-host-expand {
  margin: 0;
  font-family: var(--OT-type-family);
  font-size: var(--typesize-minor);
  line-height: var(--leading--1-5);
  font-weight: var(--typeweight--default);
  color: var(--OT-modal-stroke-color)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk .ot-host-item .ot-host-name a.cookie-label,
div#onetrust-consent-sdk div#onetrust-pc-sdk .ot-host-item .ot-host-name a a.cookie-label,
div#onetrust-consent-sdk div#onetrust-pc-sdk .ot-host-item .ot-host-expand a.cookie-label {
  font-weight: var(--typeweight--semibold);
  color: var(--color--text-link) !important;
  text-decoration: underline
}

div#onetrust-consent-sdk div#onetrust-pc-sdk .ot-host-item .ot-host-expand {
  color: var(--color--text-link) !important;
  margin: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk .ot-host-item .ot-tgl-cntr {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  width: unset;
  position: unset;
  right: unset;
  left: unset;
  top: unset;
  bottom: unset;
  margin: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk .ot-host-item div.ot-plus-minus {
  margin-right: var(--spacing-20)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk .ot-host-item div.ot-plus-minus span {
  background-color: var(--color--ui-05)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk .ot-host-item .ot-acc-txt {
  width: auto;
  margin: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk .ot-host-item .ot-acc-txt .ot-host-opt .ot-host-info {
  display: block;
  padding: var(--spacing-20);
  width: auto;
  margin-bottom: 2px
}

div#onetrust-consent-sdk div#onetrust-pc-sdk .ot-host-item .ot-acc-txt .ot-host-opt .ot-host-info:last-child {
  margin-bottom: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk .ot-host-item .ot-acc-txt .ot-host-opt .ot-host-info div {
  padding: 0;
  font-family: var(--OT-type-family);
  font-size: var(--typesize-minor);
  line-height: var(--leading--2);
  font-weight: var(--typeweight--default);
  color: var(--OT-modal-stroke-color)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk .ot-host-item .ot-acc-txt .ot-host-opt .ot-host-info div a {
  color: var(--color--text-link) !important;
  text-decoration: none
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer {
  position: unset;
  flex-shrink: 0;
  border-top: 1px solid var(--color--ui-06);
  max-height: var(--OT-modal-footer-maxHeight);
  z-index: 1
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container {
  display: flex;
  width: auto;
  margin: 0;
  background: var(--color--ui-background-light, var(--color--background-light));
  text-align: unset
}

@media(min-width:768px) {
  div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container {
    padding: var(--spacing-30)
  }
}

@media(max-width:767px) {
  div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container {
    padding: var(--spacing-20)
  }
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon) {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary);
  transition: color .125s ease-in-out;
  padding: .75em 1.25em;
  min-height: 3rem;
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-right: 0
}

@media(min-width:376px) {
  div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon) {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon) {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon)::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):hover,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):focus,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):focus-visible,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):active::before {
  transform: scale(1.05)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):focus,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):focus-visible {
  outline: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):active::before {
  outline-color: transparent
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):only-of-type {
  margin: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon).icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon)::before {
  outline-color: var(--color--interactive-tertiary);
  outline-offset: -2px;
  background-color: transparent
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):hover {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):hover::before {
  outline-color: var(--color--interactive-tertiary-hover);
  background: var(--color--interactive-tertiary-hover)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):focus,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):focus-visible {
  color: var(--color--text-tertiary)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):active {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):active::before {
  background: var(--color--interactive-tertiary-active)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon)[disabled],
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon).button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon)[disabled]::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon).button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon)[disabled]:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon).button-disabled:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon)[disabled]:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon).button-disabled:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon)[disabled]:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon).button-disabled:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon)[disabled]:active::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon).button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon)[disabled]::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon).button-disabled::before {
  outline-color: var(--color--interactive-disabled-01);
  outline-offset: -2px;
  background: transparent
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon)[disabled]:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon).button-disabled:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon)[disabled]:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon).button-disabled:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon)[disabled]:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon).button-disabled:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon)[disabled]:active::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon).button-disabled:active::before {
  background: transparent
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon).icon-only {
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  padding: .75em 1.25em;
  min-height: 3rem;
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-right: 0
}

@media(min-width:376px) {
  div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:hover,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:focus,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:focus-visible,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:active::before {
  transform: scale(1.05)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:focus,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:focus-visible {
  outline: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:active::before {
  outline-color: transparent
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:only-of-type {
  margin: 0
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler::before {
  background: var(--color--interactive-secondary) border-box
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:hover {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:hover::before {
  background: var(--color--interactive-secondary-hover) border-box
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:focus,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:focus-visible {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:focus-visible::before {
  background: var(--color--interactive-secondary) border-box
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:active {
  color: var(--color--text-primary-inverse)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:active::before {
  background: var(--color--interactive-secondary-active) border-box
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler[disabled],
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler[disabled]::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler[disabled]:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler.button-disabled:hover::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler[disabled]:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler.button-disabled:focus::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler[disabled]:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler.button-disabled:focus-visible::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler[disabled]:active::before,
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler.icon-only {
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon),
div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler {
  flex-grow: 1;
  margin: 0;
  background: none !important;
  letter-spacing: unset;
  max-width: unset
}

@media(max-width:768px) {

  div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.ot-pc-refuse-all-handler:not(#filter-btn-handler):not(.ot-remove-objection-handler):not([aria-expanded]):not(.ot-link-btn):not(#clear-filters-handler):not(.ot-close-icon):not(:only-of-type),
  div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler:not(:only-of-type) {
    max-width: calc(100% - var(--spacing-20))
  }
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler {
  margin-left: var(--spacing-20)
}

div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer-logo {
  display: none
}

@media(max-width:1024px) {
  div#onetrust-consent-sdk {
    --OT-banner-aligning: flex-start;
    --OT-banner-flow: column;
    --OT-banner-cta-block-w: 100%;
    --OT-banner-cta-grow: 1;
    --OT-banner-cta-mw: 50%;
    --OT-modal-content-spacing: var(--spacing-20)
  }
}

@media(max-width:768px) {
  div#onetrust-consent-sdk div#onetrust-pc-sdk {
    top: 0;
    width: 100vw;
    max-width: unset;
    max-height: unset;
    height: 100vh !important;
    transform: unset
  }

  div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container {
    display: block
  }

  div#onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer .ot-btn-container button.save-preference-btn-handler {
    margin-left: 0;
    margin-top: var(--spacing-20)
  }
}

@media(max-width:767px) {
  div#onetrust-consent-sdk {
    --OT-banner-cta-block-flow: column;
    --OT-banner-cta-w: 100%;
    --OT-banner-cta-mw: 100%
  }
}

.he-footer-global {
  font-family: var(--typefamily-primary);
  --locale-selector-src-display: none;
  --locale-selector-custom-display: block;
  --locale-selector-dd-display: none;
  --locale-selector-dd-mt: 0;
  --locale-selector-mb: 0;
  --locale-selector-arrow-rotate: 0;
  --he-dialog-outer-spacing: 100px;
  --he-dialog-max-w: 540px;
  --width-social-links-column: 200px;
  --width-social-link-item: 23px;
  margin-top: 0;
  // background: var(--color--ui-background-dark, var(--color--background-dark))
  background: var(--color--reskin--pearson-black);
}

.he-footer-global .container {
  --grid-margin-sm: 30px;
  --grid-margin-md: 55px;
  --grid-margin-lg: 75px;
  max-width: 100%
}

@media(max-width:768px) {
  .he-footer-global .container {
    padding-inline-end: var(--grid-margin-sm);
    padding-inline-start: var(--grid-margin-sm)
  }
}

@media(min-width:769px) and (max-width:1589px) {
  .he-footer-global .container {
    padding-inline-end: var(--grid-margin-md);
    padding-inline-start: var(--grid-margin-md)
  }
}

@media(min-width:1590px) {
  .he-footer-global .container {
    padding-inline-end: var(--grid-margin-lg);
    padding-inline-start: var(--grid-margin-lg);
    max-width: 1590px
  }
}

@media(max-width:767px) {
  .he-footer-global main .row {
    row-gap: var(--space-small)
  }
}

@media(min-width:768px) {
  .he-footer-global main .row {
    row-gap: var(--space-medium)
  }
}

@media(max-width:767px) {
  .he-footer-global main .col-12:empty {
    display: none
  }
}

.he-footer-global .h1-plus,
.he-footer-global .h1-plus * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h1-plus);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-15);
  margin-bottom: 0
}

.he-footer-global h1,
.he-footer-global .h1,
.he-footer-global .h1 * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h1);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-15);
  margin-bottom: 0
}

.typefamily-primary .he-footer-global h1,
.typefamily-primary .he-footer-global .h1,
.typefamily-primary .he-footer-global .h1 * {
  font-weight: var(--typeweight--light)
}

.he-footer-global .h1-catalog,
.he-footer-global .h1-catalog * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h1-catalog);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-15);
  margin-bottom: 0
}

.he-footer-global h2,
.he-footer-global .h2,
.he-footer-global .h2 * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h2);
  font-weight: var(--typeweight--light);
  line-height: var(--leading--1-3);
  margin-bottom: 0
}

.he-footer-global h3,
.he-footer-global .h3,
.he-footer-global .h3 * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h3);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

.he-footer-global h4,
.he-footer-global .h4,
.he-footer-global .h4 * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-h4);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

.he-footer-global h5,
.he-footer-global .h5,
.he-footer-global .h5 * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-h5);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

.he-footer-global h6,
.he-footer-global .h6,
.he-footer-global .h6 * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-2);
  margin-bottom: 0
}

.he-footer-global main h1,
.he-footer-global main h2,
.he-footer-global main h4,
.he-footer-global main h5,
.he-footer-global main h6,
.he-footer-global main .h1,
.he-footer-global main .h2,
.he-footer-global main .h4,
.he-footer-global main .h5,
.he-footer-global main .h6,
.he-footer-global main .exa,
.he-footer-global main .peta,
.he-footer-global main .tera,
.he-footer-global main .giga,
.he-footer-global main .mega,
.he-footer-global main .kilo {
  color: var(--color--text-primary)
}

.he-footer-global main h1 *:not(a[href], a[href] *, button),
.he-footer-global main h2 *:not(a[href], a[href] *, button),
.he-footer-global main h4 *:not(a[href], a[href] *, button),
.he-footer-global main h5 *:not(a[href], a[href] *, button),
.he-footer-global main h6 *:not(a[href], a[href] *, button),
.he-footer-global main .h1 *:not(a[href], a[href] *, button),
.he-footer-global main .h2 *:not(a[href], a[href] *, button),
.he-footer-global main .h4 *:not(a[href], a[href] *, button),
.he-footer-global main .h5 *:not(a[href], a[href] *, button),
.he-footer-global main .h6 *:not(a[href], a[href] *, button),
.he-footer-global main .exa *:not(a[href], a[href] *, button),
.he-footer-global main .peta *:not(a[href], a[href] *, button),
.he-footer-global main .tera *:not(a[href], a[href] *, button),
.he-footer-global main .giga *:not(a[href], a[href] *, button),
.he-footer-global main .mega *:not(a[href], a[href] *, button),
.he-footer-global main .kilo *:not(a[href], a[href] *, button) {
  color: var(--color--text-primary)
}

.he-footer-global main h3,
.he-footer-global main .h3 {
  color: var(--color--text-secondary)
}

.he-footer-global main h3 *:not(a[href], a[href] *, button),
.he-footer-global main .h3 *:not(a[href], a[href] *, button) {
  color: var(--color--text-secondary)
}

.he-footer-global p,
.he-footer-global .p,
.he-footer-global .p *,
.he-footer-global blockquote,
.he-footer-global figcaption,
.he-footer-global cite,
.he-footer-global dl,
.he-footer-global ol,
.he-footer-global ul {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-p);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default)
}

.he-footer-global cite {
  font-style: normal;
  font-weight: inherit;
  display: inline-block
}

.he-footer-global .exa,
.he-footer-global .exa * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-exa);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-1)
}

.he-footer-global .peta,
.he-footer-global .peta * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-peta);
  font-weight: var(--typeweight--light);
  line-height: var(--leading--1-2)
}

.he-footer-global .tera,
.he-footer-global .tera * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-tera);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2)
}

.he-footer-global .giga,
.he-footer-global .giga * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-giga);
  font-weight: var(--typeweight--light);
  line-height: var(--leading--1-2)
}

.he-footer-global .mega,
.he-footer-global .mega * {
  font-family: var(--typefamily-alt);
  font-size: var(--typesize-mega);
  font-weight: var(--typeweight--semibold);
  line-height: var(--leading--1-2)
}

.he-footer-global .kilo,
.he-footer-global .kilo * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-kilo);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2)
}

.he-footer-global .minor,
.he-footer-global .minor * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-minor);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default)
}

.he-footer-global .milli,
.he-footer-global .milli * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-milli);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default)
}

.he-footer-global .micro,
.he-footer-global .micro * {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-micro);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default)
}

.he-footer-global .column-control,
.he-footer-global .column-control.cq-Editable-dom--container {
  position: relative;
  padding-right: 0;
  padding-left: 0
}

@media(min-width:769px) {

  .he-footer-global .column-control,
  .he-footer-global .column-control.cq-Editable-dom--container {
    padding-top: var(--space-xlarge);
    padding-bottom: var(--space-xlarge)
  }
}

@media(max-width:768px) {

  .he-footer-global .column-control,
  .he-footer-global .column-control.cq-Editable-dom--container {
    padding-top: var(--space-large);
    padding-bottom: var(--space-large)
  }
}

@media(hover:none) and (pointer:coarse) {
  .he-footer-global .par-main>.column-control:not(.c-hero-image, .overflow-auto, .overflow-unset) {
    overflow-x: hidden
  }
}

@media(prefers-reduced-motion:no-preference) {

  .he-footer-global .column-control.animated>[class*="container"]>.row>.col-12>*,
  .he-footer-global .column-control.animated>.row>.col-12>*,
  .he-footer-global .column-control.animated>.background-video__container>[class*="container"]>.row>.col-12>* {
    opacity: 0;
    transform: translate(0, 20px)
  }

  .he-footer-global .column-control.animated.in-view>[class*="container"]>.row>.col-12>*,
  .he-footer-global .column-control.animated.in-view>.row>.col-12>*,
  .he-footer-global .column-control.animated.in-view>.background-video__container>[class*="container"]>.row>.col-12>* {
    animation-duration: .5s;
    animation-name: slidein;
    animation-fill-mode: forwards
  }
}

.he-footer-global .headerIsTransparent main :nth-child(1 of .column-control):not(.column-control .column-control)::before {
  content: "";
  display: block;
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.55) 0, rgba(0, 0, 0, 0.521) 10%, rgba(0, 0, 0, 0.487) 19.2%, rgba(0, 0, 0, 0.448) 27.7%, rgba(0, 0, 0, 0.405) 35.5%, rgba(0, 0, 0, 0.36) 42.8%, rgba(0, 0, 0, 0.313) 49.6%, rgba(0, 0, 0, 0.265) 56%, rgba(0, 0, 0, 0.219) 62%, rgba(0, 0, 0, 0.174) 67.7%, rgba(0, 0, 0, 0.132) 73.2%, rgba(0, 0, 0, 0.093) 78.5%, rgba(0, 0, 0, 0.06) 83.8%, rgba(0, 0, 0, 0.032) 89.1%, rgba(0, 0, 0, 0.012) 94.5%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
  height: 250px;
  top: 0;
  z-index: 1;
  pointer-events: none
}

.he-footer-global .headerIsTransparent main :nth-child(1 of .column-control):not([class*="background-video__control--"], .column-control .column-control) {
  overflow: hidden
}

[data-breadcrumb="true"].he-footer-global .headerIsTransparent main :nth-child(1 of .column-control):not([class*="background-video__control--"], .column-control .column-control) {
  padding-top: calc(32 * var(--space-unit))
}

[data-breadcrumb="false"].he-footer-global .headerIsTransparent main :nth-child(1 of .column-control):not([class*="background-video__control--"], .column-control .column-control) {
  padding-top: calc(24.8 * var(--space-unit))
}

.he-footer-global .headerIsTransparent main :nth-child(1 of .column-control):not(.column-control .column-control)>[class*="container"]>.row>.col-12>*:not(.column-control) {
  position: relative;
  z-index: 2
}

.he-footer-global body>footer {
  position: relative;
  background-color: var(--color--ui-background-dark, var(--color--background-dark))
}

@media(min-width:768px) {
  .he-footer-global .c-footer__main>.container>.row>.col-12 {
    display: grid;
    grid-column-gap: 30px
  }
}

@media(min-width:1025px) {
  .he-footer-global .c-footer__main>.container>.row>.col-12 {
    grid-template-columns: 1.5fr .5fr .5fr 140px
  }
}

@media(max-width:1024px) {
  .he-footer-global .c-footer__main>.container>.row>.col-12 {
    grid-template-columns: 1.5fr 1fr 1fr 140px
  }
}

@media(width:768px) {
  .he-footer-global .c-footer__main>.container>.row>.col-12 {
    grid-template-rows: auto auto;
    grid-template-columns: 1.5fr 1fr 1.25fr
  }
}

.he-footer-global .c-footer__logo {
  display: inline-flex;
  height: 34px
}

.he-footer-global .c-footer__tagline.c-footer__tagline {
  margin-top: 24px
}

.he-footer-global .c-footer__tagline.c-footer__tagline * {
  color: var(--color--text-primary-inverse)
}

.he-footer-global .c-footer__social-links.c-footer__social-links {
  margin-top: 20px
}

@media(max-width:767px) {
  .he-footer-global .c-footer__social-links.c-footer__social-links {
    margin-bottom: 45px
  }
}

.he-footer-global .c-footer__social-links .social-links__list {
  flex-wrap: wrap
}

@media(min-width:376px) {
  .he-footer-global .c-footer__social-links .social-links__list {
    justify-content: flex-start
  }
}

.he-footer-global .c-footer__social-links .social-links__item {
  flex: 0 0 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: 16px;
  margin-bottom: 16px
}

.he-footer-global .c-footer__social-links .social-links__item+.social-links__item {
  margin-left: 0
}

.he-footer-global .c-footer__social-links [class*="social-links--"] {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  min-width: 24px;
  min-height: 24px;
  background-color: transparent;
  background-size: auto 12px;
  background-image: none
}

.he-footer-global .c-footer__social-links [class*="social-links--"]::after {
  transition: transform .125s ease-in-out
}

.he-footer-global .c-footer__social-links [class*="social-links--"][class*="social-links--"]::after {
  background-color: var(--color--icon-primary-inverse)
}

.he-footer-global .c-footer__social-links [class*="social-links--"]:hover,
.he-footer-global .c-footer__social-links [class*="social-links--"]:active,
.he-footer-global .c-footer__social-links [class*="social-links--"]:focus {
  outline-offset: .5rem
}

.he-footer-global .c-footer__social-links [class*="social-links--"]:hover::after,
.he-footer-global .c-footer__social-links [class*="social-links--"]:active::after,
.he-footer-global .c-footer__social-links [class*="social-links--"]:focus::after {
  transform: scale(1.5)
}

.he-footer-global .c-footer__social-links .social-links--facebook::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-facebook-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-facebook-lg")
}

.he-footer-global .c-footer__social-links .social-links--twitter::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-twitter-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-twitter-lg")
}

.he-footer-global .c-footer__social-links .social-links--youtube::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-youtube-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-youtube-lg")
}

.he-footer-global .c-footer__social-links .social-links--instagram::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-instagram-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-instagram-lg")
}

.he-footer-global .c-footer__social-links .social-links--linkedin::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-linkedin-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-social.svg#icon-linkedin-lg")
}

.he-footer-global .c-footer__link-list.c-footer__link-list {
  margin-top: 0
}

@media(max-width:767px) {
  .he-footer-global .c-footer__link-list.c-footer__link-list {
    margin-top: 45px
  }

  .he-footer-global .c-footer__link-list.c-footer__link-list:first-of-type:not(.text) {
    margin-top: 0
  }
}

.he-footer-global .c-footer__link-list.c-footer__link-list h1,
.he-footer-global .c-footer__link-list.c-footer__link-list h2,
.he-footer-global .c-footer__link-list.c-footer__link-list h3,
.he-footer-global .c-footer__link-list.c-footer__link-list h4,
.he-footer-global .c-footer__link-list.c-footer__link-list h5,
.he-footer-global .c-footer__link-list.c-footer__link-list h6 {
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--bold);
  line-height: var(--leading--1-1);
  color: var(--color--text-primary-inverse)
}

.he-footer-global .c-footer__link-list.c-footer__link-list h1+ul,
.he-footer-global .c-footer__link-list.c-footer__link-list h2+ul,
.he-footer-global .c-footer__link-list.c-footer__link-list h3+ul,
.he-footer-global .c-footer__link-list.c-footer__link-list h4+ul,
.he-footer-global .c-footer__link-list.c-footer__link-list h5+ul,
.he-footer-global .c-footer__link-list.c-footer__link-list h6+ul {
  margin-top: 8px
}

@media(max-width:767px) {

  .he-footer-global .c-footer__link-list.c-footer__link-list h1+ul,
  .he-footer-global .c-footer__link-list.c-footer__link-list h2+ul,
  .he-footer-global .c-footer__link-list.c-footer__link-list h3+ul,
  .he-footer-global .c-footer__link-list.c-footer__link-list h4+ul,
  .he-footer-global .c-footer__link-list.c-footer__link-list h5+ul,
  .he-footer-global .c-footer__link-list.c-footer__link-list h6+ul {
    margin-top: 15px
  }
}

.he-footer-global .c-footer__link-list.c-footer__link-list li {
  border-bottom: 0
}

.he-footer-global .c-footer__link-list.c-footer__link-list li a {
  font-weight: var(--typeweight--default);
  color: var(--color--text-primary-inverse)
}

.he-footer-global .c-footer__link-list.c-footer__link-list li a:hover,
.he-footer-global .c-footer__link-list.c-footer__link-list li a:active,
.he-footer-global .c-footer__link-list.c-footer__link-list li a:focus {
  color: var(--color--text-primary-inverse)
}

@media(min-width:768px) {
  .he-footer-global .c-footer__link-list.c-footer__link-list li {
    padding: 8px 0
  }
}

@media(max-width:768px) {
  .he-footer-global .c-footer__link-list.c-footer__link-list li:last-child {
    border-bottom: 0
  }
}

@media(max-width:767px) {
  .he-footer-global .c-footer__link-list.c-footer__link-list li {
    margin-top: 15px
  }

  .he-footer-global .c-footer__link-list.c-footer__link-list li:first-child {
    margin-top: 0
  }

  .he-footer-global .c-footer__link-list.c-footer__link-list li a {
    padding: 0
  }
}

@media(width:768px) {
  .he-footer-global .c-footer__column-get-the-app {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 4
  }
}

@media(max-width:768px) {
  .he-footer-global .c-footer__column-get-the-app>.row>.col-12 {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 140px 140px;
    grid-template-areas: "badge-header badge-header""badge-one badge-two";
    padding-bottom: 15px
  }

  .he-footer-global .c-footer__column-get-the-app .c-footer__link-list {
    grid-area: badge-header
  }

  .he-footer-global .c-footer__column-get-the-app .c-footer__badge-1 {
    grid-area: badge-one
  }

  .he-footer-global .c-footer__column-get-the-app .c-footer__badge-2 {
    grid-area: badge-two
  }
}

*+.he-footer-global .c-footer__app-badge.c-footer__app-badge {
  margin-top: 16px
}

.he-footer-global .c-footer__app-badge.c-footer__app-badge a {
  display: inline-block
}

.he-footer-global .c-footer__app-badge.c-footer__app-badge img {
  height: 40px
}

.he-footer-global .c-footer__legal-notification {
  border-top: 1px solid var(--color--ui-border-02, var(--color--border-02))
}

.he-footer-global .c-footer__legal-notification .legal-notification>.container {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0
}

@media(max-width:767px) {
  .he-footer-global .c-footer__legal-notification .legal-notification>.container {
    flex-direction: column
  }
}

.he-footer-global .c-footer__legal-notification .legal-notification-copy {
  font-size: var(--typesize-default);
  color: var(--color--text-primary-inverse);
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  white-space: nowrap;
  -webkit-box-ordinal-group: 3;
  order: 2
}

@media(max-width:767px) {
  .he-footer-global .c-footer__legal-notification .legal-notification-copy {
    margin-top: 60px
  }
}

.he-footer-global .c-footer__legal-notification .legal-notification-links-container {
  flex: 1 1 auto;
  order: 1;
  padding-right: 30px
}

.he-footer-global .c-footer__legal-notification .legal-notification-links {
  display: flex;
  flex-wrap: wrap
}

@media(min-width:768px) {
  .he-footer-global .c-footer__legal-notification .legal-notification-links {
    flex: 1 1 auto
  }
}

@media(max-width:767px) {
  .he-footer-global .c-footer__legal-notification .legal-notification-links {
    flex-direction: column
  }
}

.he-footer-global .c-footer__legal-notification .legal-notification-links li {
  list-style-type: none
}

@media(min-width:1025px) {
  .he-footer-global .c-footer__legal-notification .legal-notification-links li {
    margin-right: 45px;
    margin-bottom: 15px
  }
}

@media(max-width:767px) {
  .he-footer-global .c-footer__legal-notification .legal-notification-links li {
    margin-bottom: 15px
  }
}

.he-footer-global .c-footer__legal-notification .legal-notification-links a {
  font-weight: var(--typeweight--default);
  color: var(--color--text-primary-inverse)
}

.he-footer-global .c-footer__legal-notification .legal-notification-links a:hover,
.he-footer-global .c-footer__legal-notification .legal-notification-links a:active,
.he-footer-global .c-footer__legal-notification .legal-notification-links a:focus {
  color: var(--color--text-primary-inverse)
}

@media(min-width:768px) and (max-width:1024px) {
  .he-footer-global .c-footer__legal-notification .legal-notification>.container {
    display: flex;
    flex-flow: row;
    justify-content: flex-end
  }

  .he-footer-global .c-footer__legal-notification .legal-notification-copy {
    font-size: var(--typesize-default);
    text-align: right;
    white-space: normal;
    display: block;
    flex-shrink: 1;
    width: auto
  }

  .he-footer-global .c-footer__legal-notification .legal-notification-links-container {
    min-width: 78%;
    flex-grow: 1;
    padding-right: 26px
  }

  .he-footer-global .c-footer__legal-notification .legal-notification-links {
    display: grid;
    gap: 15px;
    grid-template-columns: .8fr 1.2fr
  }
}

.he-footer-global .accordion-panel-hide {
  display: none
}

.he-footer-global .accordion-panel {
  background-color: var(--color--ui-02);
  box-shadow: var(--shadow);
  border-radius: var(--corners-semiround-sm);
  transition: box-shadow .125s ease-in-out
}

.he-footer-global .accordion-panel+* {
  margin-top: 20px
}

.he-footer-global .accordion-panel-content {
  color: var(--color--text-secondary);
  margin-top: 0
}

@media all and (max-width:768px) {
  .he-footer-global .accordion-panel-content {
    padding: 0 32px 15px 20px
  }
}

@media all and (min-width:769px) {
  .he-footer-global .accordion-panel-content {
    padding: 0 50px 24px 24px
  }
}

@media print {
  .he-footer-global .accordion-panel-hide {
    display: block
  }
}

.he-footer-global .accordion-panel:hover {
  box-shadow: var(--shadow-blur)
}

.he-footer-global .accordion-panel-header {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-p);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--default);
  padding: 0;
  margin: 0;
  color: var(--color--text-primary)
}

@media all and (min-width:769px) {
  .he-footer-global .accordion-panel-header {
    font-size: var(--typesize-h5)
  }
}

.he-footer-global .accordion-panel-header button {
  all: unset
}

.he-footer-global .accordion-panel-header a,
.he-footer-global .accordion-panel-header button {
  font-weight: var(--typeweight--default);
  color: var(--color--interactive-secondary);
  text-decoration: none;
  cursor: pointer;
  border: 0;
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center
}

@media all and (max-width:768px) {

  .he-footer-global .accordion-panel-header a,
  .he-footer-global .accordion-panel-header button {
    padding: 15px 9px 10px 20px
  }
}

@media all and (min-width:769px) {

  .he-footer-global .accordion-panel-header a,
  .he-footer-global .accordion-panel-header button {
    padding: 24px
  }
}

.he-footer-global .accordion-panel-header a:hover,
.he-footer-global .accordion-panel-header button:hover,
.he-footer-global .accordion-panel-header a:active,
.he-footer-global .accordion-panel-header button:active,
.he-footer-global .accordion-panel-header a:focus,
.he-footer-global .accordion-panel-header button:focus {
  text-decoration: none
}

.he-footer-global .accordion-panel-header a:focus,
.he-footer-global .accordion-panel-header button:focus {
  color: var(--color--text-primary)
}

.he-footer-global .accordion-panel-header a:focus-visible,
.he-footer-global .accordion-panel-header button:focus-visible {
  outline-width: 3px;
  outline-style: solid;
  outline-color: var(--color--interactive-focus);
  outline-offset: -3px
}

.he-footer-global .accordion-panel-header a span:first-of-type,
.he-footer-global .accordion-panel-header button span:first-of-type {
  display: inline-block;
  animation-duration: .125s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards
}

@media(min-width:769px) {

  .he-footer-global .accordion-panel-header a span:first-of-type,
  .he-footer-global .accordion-panel-header button span:first-of-type {
    width: calc(100% - 20px)
  }
}

@media(max-width:768px) {

  .he-footer-global .accordion-panel-header a span:first-of-type,
  .he-footer-global .accordion-panel-header button span:first-of-type {
    width: auto;
    flex: 1 1 calc(100% - 20px)
  }
}

.he-footer-global .accordion-panel-header a [class*="fa-"],
.he-footer-global .accordion-panel-header button [class*="fa-"] {
  font-size: 0;
  margin-left: auto;
  margin-top: 0
}

.he-footer-global .accordion-panel-header a [class*="fa-"]::before,
.he-footer-global .accordion-panel-header button [class*="fa-"]::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-md");
  background-color: var(--color--icon-primary);
  transition: transform 300ms ease-in-out
}

.he-footer-global .accordion-panel-header a .fa-minus::before,
.he-footer-global .accordion-panel-header button .fa-minus::before {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg)
}

.he-footer-global .accordion-panel-header[aria-expanded="true"] a span:first-of-type,
.he-footer-global .accordion-panel-header[aria-expanded="true"] button span:first-of-type {
  animation-name: setBoldType;
  font-weight: var(--typeweight--semibold)
}

.he-footer-global .accordion-panel-header[aria-expanded="false"] a span:first-of-type,
.he-footer-global .accordion-panel-header[aria-expanded="false"] button span:first-of-type {
  animation-name: removeBoldType;
  font-weight: var(--typeweight--default)
}

@supports selector(:focus-visible) {

  .accordion-panel-header a:focus-visible,
  .accordion-panel-header button:focus-visible {
    outline-width: 3px;
    outline-style: solid;
    outline-color: var(--color--interactive-focus)
  }
}

@media all and (max-width:768px) {
  .he-footer-global .accordion+* {
    margin-top: 30px
  }
}

@media all and (min-width:769px) {
  .he-footer-global .accordion+* {
    margin-top: 40px
  }
}

*+.he-footer-global .accordion {
  margin-top: 20px
}

.he-footer-global .accordion-secondary .accordion-panel {
  background-color: var(--color--ui-04)
}

.he-footer-global .accordion-secondary .accordion-panel-content {
  color: var(--color--text-secondary-inverse)
}

.he-footer-global .accordion-secondary .accordion-panel-header a span:first-of-type,
.he-footer-global .accordion-secondary .accordion-panel-header button span:first-of-type {
  color: var(--color--interactive-secondary-inverse)
}

.he-footer-global .accordion-secondary .accordion-panel-header a [class*="fa-"]::before,
.he-footer-global .accordion-secondary .accordion-panel-header button [class*="fa-"]::before {
  background-color: var(--color--interactive-secondary-inverse)
}

@media(prefers-reduced-motion:no-preference) {
  .he-footer-global .accordion.animated .accordion-panel {
    opacity: 0;
    transform: translate(0, 20px)
  }

  .he-footer-global .accordion.animated.in-view .accordion-panel {
    animation-duration: .5s;
    animation-name: slidein;
    animation-fill-mode: forwards
  }

  @supports(font-variation-settings:normal) {
    @keyframes setBoldType {
      from {
        font-weight: var(--typeweight--default)
      }

      to {
        font-weight: var(--typeweight--semibold)
      }
    }

    @keyframes removeBoldType {
      from {
        font-weight: var(--typeweight--semibold)
      }

      to {
        font-weight: var(--typeweight--default)
      }
    }
  }
}

.he-footer-global .has-padding--none.has-padding--none,
.he-footer-global .has-padding--0.has-padding--0 {
  padding: 0
}

@media(min-width:769px) {
  .he-footer-global .has-padding--15.has-padding--15 {
    padding: var(--space-xsmall)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding--15.has-padding--15 {
    padding: var(--space-xxsmall)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding--30.has-padding--30 {
    padding: var(--space-medium)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding--30.has-padding--30 {
    padding: var(--space-small)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding--45.has-padding--45 {
    padding: var(--space-large)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding--45.has-padding--45 {
    padding: var(--space-medium)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding--60.has-padding--60 {
    padding: var(--space-xlarge)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding--60.has-padding--60 {
    padding: var(--space-large)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding--75.has-padding--75 {
    padding: var(--space-xxlarge)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding--75.has-padding--75 {
    padding: var(--space-xlarge)
  }
}

.he-footer-global .has-padding-vertical--none.has-padding-vertical--none,
.he-footer-global .has-padding-vertical--0.has-padding-vertical--0 {
  padding-top: 0;
  padding-bottom: 0
}

@media(min-width:769px) {
  .he-footer-global .has-padding-vertical--15.has-padding-vertical--15 {
    padding-top: var(--space-xsmall);
    padding-bottom: var(--space-xsmall)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-vertical--15.has-padding-vertical--15 {
    padding-top: var(--space-xxsmall);
    padding-bottom: var(--space-xxsmall)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-vertical--30.has-padding-vertical--30 {
    padding-top: var(--space-medium);
    padding-bottom: var(--space-medium)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-vertical--30.has-padding-vertical--30 {
    padding-top: var(--space-small);
    padding-bottom: var(--space-small)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-vertical--45.has-padding-vertical--45 {
    padding-top: var(--space-large);
    padding-bottom: var(--space-large)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-vertical--45.has-padding-vertical--45 {
    padding-top: var(--space-medium);
    padding-bottom: var(--space-medium)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-vertical--60.has-padding-vertical--60 {
    padding-top: var(--space-xlarge);
    padding-bottom: var(--space-xlarge)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-vertical--60.has-padding-vertical--60 {
    padding-top: var(--space-large);
    padding-bottom: var(--space-large)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-vertical--75.has-padding-vertical--75 {
    padding-top: var(--space-xxlarge);
    padding-bottom: var(--space-xxlarge)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-vertical--75.has-padding-vertical--75 {
    padding-top: var(--space-xlarge);
    padding-bottom: var(--space-xlarge)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-vertical--90.has-padding-vertical--90 {
    padding-top: var(--space-xxxlarge);
    padding-bottom: var(--space-xxxlarge)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-vertical--90.has-padding-vertical--90 {
    padding-top: var(--space-xxlarge);
    padding-bottom: var(--space-xxlarge)
  }
}

.he-footer-global .has-padding-bottom--none.has-padding-bottom--none,
.he-footer-global .has-padding-bottom--0.has-padding-bottom--0 {
  padding-bottom: 0
}

@media(min-width:769px) {
  .he-footer-global .has-padding-bottom--15.has-padding-bottom--15 {
    padding-bottom: var(--space-xsmall)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-bottom--15.has-padding-bottom--15 {
    padding-bottom: var(--space-xxsmall)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-bottom--30.has-padding-bottom--30 {
    padding-bottom: var(--space-medium)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-bottom--30.has-padding-bottom--30 {
    padding-bottom: var(--space-small)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-bottom--45.has-padding-bottom--45 {
    padding-bottom: var(--space-large)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-bottom--45.has-padding-bottom--45 {
    padding-bottom: var(--space-medium)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-bottom--60.has-padding-bottom--60 {
    padding-bottom: var(--space-xlarge)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-bottom--60.has-padding-bottom--60 {
    padding-bottom: var(--space-large)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-bottom--75.has-padding-bottom--75 {
    padding-bottom: var(--space-xxlarge)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-bottom--75.has-padding-bottom--75 {
    padding-bottom: var(--space-xlarge)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-bottom--90.has-padding-bottom--90 {
    padding-bottom: var(--space-xxxlarge)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-bottom--90.has-padding-bottom--90 {
    padding-bottom: var(--space-xxlarge)
  }
}

.he-footer-global .has-padding-top--none.has-padding-top--none,
.he-footer-global .has-padding-top--0.has-padding-top--0 {
  padding-top: 0
}

@media(min-width:769px) {
  .he-footer-global .has-padding-top--15.has-padding-top--15 {
    padding-top: var(--space-xsmall)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-top--15.has-padding-top--15 {
    padding-top: var(--space-xxsmall)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-top--30.has-padding-top--30 {
    padding-top: var(--space-medium)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-top--30.has-padding-top--30 {
    padding-top: var(--space-small)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-top--45.has-padding-top--45 {
    padding-top: var(--space-large)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-top--45.has-padding-top--45 {
    padding-top: var(--space-medium)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-top--60.has-padding-top--60 {
    padding-top: var(--space-xlarge)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-top--60.has-padding-top--60 {
    padding-top: var(--space-large)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-top--75.has-padding-top--75 {
    padding-top: var(--space-xxlarge)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-top--75.has-padding-top--75 {
    padding-top: var(--space-xlarge)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-top--90.has-padding-top--90 {
    padding-top: var(--space-xxxlarge)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-top--90.has-padding-top--90 {
    padding-top: var(--space-xxlarge)
  }
}

.he-footer-global .has-padding-horizontal--none,
.he-footer-global .has-padding-horizontal--0 {
  padding-right: 0;
  padding-left: 0
}

@media(min-width:769px) {
  .he-footer-global .has-padding-horizontal--15 {
    padding-right: var(--space-xsmall);
    padding-left: var(--space-xsmall)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-horizontal--15 {
    padding-right: var(--space-xxsmall);
    padding-left: var(--space-xxsmall)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-horizontal--20 {
    padding-right: var(--space-small);
    padding-left: var(--space-small)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-horizontal--20 {
    padding-right: var(--space-xsmall);
    padding-left: var(--space-xsmall)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-horizontal--30 {
    padding-right: var(--space-medium);
    padding-left: var(--space-medium)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-horizontal--30 {
    padding-right: var(--space-small);
    padding-left: var(--space-small)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-horizontal--45 {
    padding-right: var(--space-large);
    padding-left: var(--space-large)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-horizontal--45 {
    padding-right: var(--space-medium);
    padding-left: var(--space-medium)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-horizontal--60 {
    padding-right: var(--space-xlarge);
    padding-left: var(--space-xlarge)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-horizontal--60 {
    padding-right: var(--space-large);
    padding-left: var(--space-large)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-horizontal--75 {
    padding-right: var(--space-xxlarge);
    padding-left: var(--space-xxlarge)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-horizontal--75 {
    padding-right: var(--space-xlarge);
    padding-left: var(--space-xlarge)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-horizontal--90 {
    padding-right: var(--space-xxxlarge);
    padding-left: var(--space-xxxlarge)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-horizontal--90 {
    padding-right: var(--space-xxlarge);
    padding-left: var(--space-xxlarge)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-horizontal--105 {
    padding-right: calc(24 * var(--space-unit));
    padding-left: calc(24 * var(--space-unit))
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-horizontal--105 {
    padding-right: var(--space-xxxlarge);
    padding-left: var(--space-xxxlarge)
  }
}

@media(min-width:769px) {
  .he-footer-global .has-padding-horizontal--120 {
    padding-right: var(--space-xxxxlarge);
    padding-left: var(--space-xxxxlarge)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-horizontal--120 {
    padding-right: calc(24 * var(--space-unit));
    padding-left: calc(24 * var(--space-unit))
  }
}

.he-footer-global [class*="has-padding-bottom--0"] {
  padding-bottom: 0
}

.he-footer-global [class*="has-padding-top--0"] {
  padding-top: 0
}

.he-footer-global [class*="has-padding-right--0"] {
  padding-right: 0
}

.he-footer-global [class*="has-padding-left--0"] {
  padding-left: 0
}

@media(min-width:769px) {
  .he-footer-global .has-padding-bottom--0__has-padding-top--15 {
    padding-top: var(--space-xsmall)
  }

  .he-footer-global .has-padding-bottom--0__has-padding-top--30 {
    padding-top: var(--space-medium)
  }

  .he-footer-global .has-padding-bottom--0__has-padding-top--45 {
    padding-top: var(--space-large)
  }

  .he-footer-global .has-padding-bottom--0__has-padding-top--60 {
    padding-top: var(--space-xlarge)
  }

  .he-footer-global .has-padding-top--0__has-padding-bottom--15 {
    padding-bottom: var(--space-xsmall)
  }

  .he-footer-global .has-padding-top--0__has-padding-bottom--30 {
    padding-bottom: var(--space-medium)
  }

  .he-footer-global .has-padding-top--0__has-padding-bottom--45 {
    padding-bottom: var(--space-large)
  }

  .he-footer-global .has-padding-top--0__has-padding-bottom--60 {
    padding-bottom: var(--space-xlarge)
  }

  .he-footer-global .has-padding-left--15__has-padding-right--0 {
    padding-left: var(--space-xsmall)
  }

  .he-footer-global .has-padding-left--30__has-padding-right--0 {
    padding-left: var(--space-medium)
  }

  .he-footer-global .has-padding-left--45__has-padding-right--0 {
    padding-left: var(--space-large)
  }

  .he-footer-global .has-padding-left--60__has-padding-right--0 {
    padding-left: var(--space-xlarge)
  }

  .he-footer-global .has-padding-left--0__has-padding-right--15 {
    padding-right: var(--space-xsmall)
  }

  .he-footer-global .has-padding-left--0__has-padding-right--30 {
    padding-right: var(--space-medium)
  }

  .he-footer-global .has-padding-left--0__has-padding-right--45 {
    padding-right: var(--space-large)
  }

  .he-footer-global .has-padding-left--0__has-padding-right--60 {
    padding-right: var(--space-xlarge)
  }
}

@media(max-width:768px) {
  .he-footer-global .has-padding-bottom--0__has-padding-top--15 {
    padding-top: var(--space-xxsmall)
  }

  .he-footer-global .has-padding-bottom--0__has-padding-top--30 {
    padding-top: var(--space-small)
  }

  .he-footer-global .has-padding-bottom--0__has-padding-top--45 {
    padding-top: var(--space-medium)
  }

  .he-footer-global .has-padding-bottom--0__has-padding-top--60 {
    padding-top: var(--space-large)
  }

  .he-footer-global .has-padding-top--0__has-padding-bottom--15 {
    padding-bottom: var(--space-xxsmall)
  }

  .he-footer-global .has-padding-top--0__has-padding-bottom--30 {
    padding-bottom: var(--space-small)
  }

  .he-footer-global .has-padding-top--0__has-padding-bottom--45 {
    padding-bottom: var(--space-medium)
  }

  .he-footer-global .has-padding-top--0__has-padding-bottom--60 {
    padding-bottom: var(--space-large)
  }

  .he-footer-global .has-padding-left--15__has-padding-right--0 {
    padding-left: var(--space-xxsmall)
  }

  .he-footer-global .has-padding-left--30__has-padding-right--0 {
    padding-left: var(--space-small)
  }

  .he-footer-global .has-padding-left--45__has-padding-right--0 {
    padding-left: var(--space-medium)
  }

  .he-footer-global .has-padding-left--60__has-padding-right--0 {
    padding-left: var(--space-large)
  }

  .he-footer-global .has-padding-left--0__has-padding-right--15 {
    padding-right: var(--space-xxsmall)
  }

  .he-footer-global .has-padding-left--0__has-padding-right--30 {
    padding-right: var(--space-small)
  }

  .he-footer-global .has-padding-left--0__has-padding-right--45 {
    padding-right: var(--space-medium)
  }

  .he-footer-global .has-padding-left--0__has-padding-right--60 {
    padding-right: var(--space-large)
  }
}

.he-footer-global .screenreader {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  height: 1px;
  width: 1px;
  min-height: unset;
  overflow: hidden;
  position: absolute;
  padding: 0;
  margin: 0
}

.he-footer-global .c-dropdown__btn {
  border: 0;
  padding: 0;
  background: 0;
  color: #fff;
  z-index: 501;
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 0 45px 0
}

.he-footer-global .c-dropdown__btn:after {
  transition: .2s all
}

.he-footer-global .c-dropdown__btn-image {
  width: 24px;
  height: 24px
}

.he-footer-global .c-dropdown__btn-name {
  padding: 0 12px 0 15px;
  display: flex;
  align-items: center;
  margin: 0
}

.he-footer-global .c-dropdown__btn:focus,
.he-footer-global .c-dropdown__btn:focus-visible {
  outline: 1px solid var(--color--interactive-focus);
  outline-offset: 0
}

.he-footer-global .accordion {
  --accordion-display: none
}

.he-footer-global .accordion-panel {
  background: 0;
  box-shadow: none
}

.he-footer-global .accordion-panel:hover {
  box-shadow: none
}

.he-footer-global .accordion-panel-header {
  margin-bottom: var(--spacing-5);
  line-height: var(--leading--1-1);
  font-size: var(--typesize-h6);
  font-weight: var(--typeweight--bold);
  padding: 0
}

.he-footer-global .accordion-panel-header button {
  padding: 0;
  border-color: transparent;
  background-color: transparent;
  font-size: var(--typesize-h6);
  color: var(--color--text-primary-inverse);
  font-family: inherit;
  display: none
}

.he-footer-global .accordion-panel-header button span:first-of-type {
  font-weight: var(--typeweight--bold);
  color: inherit
}

@media screen and (max-width:767px) {
  .he-footer-global .accordion-panel-header button span:first-of-type {
    animation-name: none
  }
}

.he-footer-global .accordion-panel-header button span:last-child {
  align-items: center;
  display: none
}

.he-footer-global .accordion-panel-header__text {
  color: var(--color--text-primary-inverse);
  display: block
}

.he-footer-global .accordion-panel-header.isOpen {
  --accordion-display: block
}

.he-footer-global .accordion-panel-header.isOpen button span:last-child:before {
  transform: rotate(-180deg)
}

.he-footer-global .accordion-panel-content {
  display: var(--accordion-display);
  padding: 0;
  border: 2px solid transparent
}

.he-footer-global .accordion-panel-hide {
  display: block
}

@media screen and (max-width:767px) {
  .he-footer-global .accordion-panel-header button {
    display: flex
  }

  .he-footer-global .accordion-panel-header button:focus,
  .he-footer-global .accordion-panel-header button:focus-visible {
    border-color: var(--color--interactive-focus)
  }

  .he-footer-global .accordion-panel-header button:focus {
    outline-color: var(--color--interactive-focus)
  }

  .he-footer-global .accordion-panel-header button:focus:not(:focus-visible) {
    outline-color: transparent
  }

  .he-footer-global .accordion-panel-header button span:last-child {
    display: flex
  }

  .he-footer-global .accordion-panel-header button span:last-child:before {
    background-color: var(--color--text-primary-inverse)
  }

  .he-footer-global .accordion-panel-header__text {
    display: none
  }

  .he-footer-global .accordion-panel-content {
    display: none
  }

  .he-footer-global .accordion-panel-show {
    display: block
  }
}

.he-footer-global ul,
.he-footer-global ol,
.he-footer-global .accordion {
  margin-top: 0
}

.he-footer-global .c-footer__main+.c-footer__bottom::before {
  content: "";
  display: block;
  height: 1px;
  width: calc(100% + var(--footer-separator-margin) * 2);
  background-color: var(--color--reskin--amethyst);
  // background-color: var(--color--ui-border-01, var(--color--border-01));
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%)
}

.he-footer-global .c-footer__main .row {
  margin: 0;
  flex-flow: initial
}

.he-footer-global .c-footer__main .col-12 {
  margin: 0;
  padding: 0;
  max-width: initial;
  flex: initial;
  width: initial
}

.he-footer-global .c-footer__main>.row {
  display: grid;
  justify-content: space-between;
  grid-template-columns: var(--width-social-links-column) 1fr;
  margin: 0 auto
}

.he-footer-global .c-footer__main>.row>.col-12 {
  display: block
}

.he-footer-global .c-footer__navigation {
  padding-bottom: var(--spacing-30)
}

.he-footer-global .c-footer__navigation .row {
  display: flex
}

.he-footer-global .c-footer__navigation .row .col-12 {
  width: 100%;
  padding: 0 var(--spacing-10)
}

.he-footer-global .c-footer__link-list ul {
  font-family: var(--typefamily-primary)
}

@media screen and (min-width:768px) {

  .he-footer-global .c-footer__link-list ul li+li,
  .he-footer-global .c-footer__link-list ol li+li {
    margin: 0
  }
}

.he-footer-global .c-footer__social-links.c-footer__social-links {
  margin: 0 0 var(--spacing-30) 0
}

.he-footer-global .c-footer__social-links .social-links__list {
  padding: 0
}

.he-footer-global .c-footer__social-links .social-links__item {
  margin-top: 0;
  flex: 0 0 var(--width-social-link-item);
  min-width: var(--width-social-link-item);
  min-height: var(--width-social-link-item);
  margin-right: var(--spacing-15);
  margin-bottom: var(--spacing-15)
}

.he-footer-global .c-footer__logo {
  margin-bottom: var(--spacing-45)
}

@media screen and (max-width:1390px) {
  .he-footer-global .c-footer__main {
    padding-bottom: var(--spacing-15)
  }

  .he-footer-global .c-footer__navigation {
    padding-bottom: 0
  }

  .he-footer-global .c-footer__navigation .row {
    display: flex;
    flex-flow: row wrap
  }

  .he-footer-global .c-footer__navigation .row .col-12 {
    width: 33%;
    margin-bottom: var(--spacing-45)
  }
}

@media screen and (max-width:767px) {
  .he-footer-global .c-footer__main {
    padding-bottom: var(--spacing-45)
  }

  .he-footer-global .c-footer__main>.row {
    display: flex;
    flex-flow: row wrap
  }

  .he-footer-global .c-footer__main>.row>.col-12 {
    display: block;
    width: 100%
  }

  .he-footer-global .c-footer__navigation .row .col-12 {
    width: 100%;
    padding: 0
  }

  .he-footer-global .c-footer__navigation .row .col-12:last-child {
    margin-bottom: 0
  }

  .he-footer-global .c-footer__navigation .accordion-panel-header button:focus,
  .he-footer-global .c-footer__navigation .accordion-panel-header button:focus-visible {
    color: var(--color--text-primary-inverse)
  }
}

.he-footer-global .c-footer__bottom {
  padding: var(--spacing-40) 0;
  border-bottom: 0
}

.he-footer-global .c-footer__bottom>.row {
  display: flex;
  align-items: center
}

.he-footer-global .c-footer__bottom>.row>.col-12 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%
}

.he-footer-global .c-footer__bottom .c-footer__link-list {
  width: auto;
  margin: 0
}

.he-footer-global .c-footer__bottom .c-footer__link-list ul {
  display: flex;
  align-items: center;
  list-style: none
}

.he-footer-global .c-footer__bottom .c-footer__link-list li {
  margin-right: var(--spacing-40);
  flex-shrink: 0;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0
}

.he-footer-global .c-footer__bottom .c-footer__link-list li:last-child {
  margin-right: var(--spacing-45)
}

.he-footer-global .c-footer__bottom-copyright {
  flex-shrink: 0;
  color: var(--color--text-tertiary-inverse)
}

.he-footer-global .c-footer__bottom-copyright.text {
  margin: 0
}

.he-footer-global .c-footer__bottom-copyright br {
  display: none
}

@media screen and (min-width:768px) and (max-width:1389px) {
  .he-footer-global .c-footer__bottom-copyright {
    text-align: right
  }

  .he-footer-global .c-footer__bottom-copyright br {
    display: block
  }
}

@media screen and (max-width:1390px) {
  .he-footer-global .c-footer__bottom .c-footer__link-list li {
    margin-right: var(--spacing-30)
  }

  .he-footer-global .c-footer__bottom .c-footer__link-list li:last-child {
    margin-right: var(--spacing-30)
  }
}

@media screen and (max-width:1279px) {
  .he-footer-global .c-footer__bottom>.row>.col-12 {
    justify-content: space-between;
    width: 100%;
    align-items: flex-start
  }

  .he-footer-global .c-footer__bottom .c-footer__link-list ul {
    display: grid;
    grid-template-columns: 1fr 1fr
  }

  .he-footer-global .c-footer__bottom .c-footer__link-list li {
    margin: 0 0 var(--spacing-15);
    width: 100%
  }

  .he-footer-global .c-footer__bottom-copyright {
    flex-shrink: initial;
    text-align: right;
    max-width: 160px
  }

  .he-footer-global .c-footer__bottom-copyright.text {
    padding-top: var(--spacing-10)
  }
}

@media screen and (max-width:767px) {
  .he-footer-global .c-footer__bottom {
    padding: var(--spacing-45) 0
  }

  .he-footer-global .c-footer__bottom>.row>.col-12 {
    flex-flow: row wrap
  }

  .he-footer-global .c-footer__bottom .c-footer__link-list {
    margin-bottom: var(--spacing-45)
  }

  .he-footer-global .c-footer__bottom .c-footer__link-list ul {
    display: flex;
    flex-flow: row wrap
  }

  .he-footer-global .c-footer__bottom .c-footer__link-list li {
    margin: 0 0 var(--spacing-10);
    width: 100%
  }

  .he-footer-global .c-footer__bottom-copyright {
    text-align: left;
    max-width: 100%
  }
}

.he-footer-global .link-list a {
  text-decoration: none
}

.he-footer-global .link-list a:hover {
  text-decoration: underline
}

.he-footer-global .c-footer__navigation .accordion-panel-header a span:first-of-type,
.he-footer-global .c-footer__navigation .accordion-panel-header[aria-expanded="false"] a span:first-of-type,
.he-footer-global .c-footer__navigation .accordion-panel-header[aria-expanded="true"] a span:first-of-type,
.he-footer-global .c-footer__navigation .accordion-panel-header button span:first-of-type,
.he-footer-global .c-footer__navigation .accordion-panel-header[aria-expanded="false"] button span:first-of-type,
.he-footer-global .c-footer__navigation .accordion-panel-header[aria-expanded="true"] button span:first-of-type {
  animation: unset;
  transition: unset;
  animation-name: unset;
  font-weight: var(--typeweight--bold)
}

.he-footer-global .c-footer__navigation .accordion-panel-content {
  margin-top: var(--spacing-15)
}

@media screen and (min-width:768px) {
  .he-footer-global .c-footer__navigation .accordion-panel-content {
    margin-top: 0
  }

  .he-footer-global .c-footer__navigation .accordion-panel-hide {
    display: block;
    position: unset;
    left: unset
  }
}

@media(max-width:768px) {
  .he-footer-global {
    --locale-selector-mb: 40px;
    --locale-selector-dd-mt: var(--spacing-20)
  }
}

.he-footer-global .locale-selection {
  display: flex;
  align-items: center;
  margin-bottom: var(--locale-selector-mb);
  z-index: 5
}

.he-footer-global .locale-selection:hover {
  --locale-selector-dd-display: block
}

.he-footer-global .locale-selection>div {
  height: 100%
}

.he-footer-global .locale-selection select {
  display: none !important;
  background: 0;
  color: var(--color--text-primary-inverse);
  padding: 0;
  height: auto;
  border: 0;
  width: auto;
  font-size: inherit
}

.he-footer-global .locale-selection select:focus:not([disabled]),
.he-footer-global .locale-selection select:active:not([disabled]) {
  background: 0;
  outline: 2px solid var(--color--interactive-focus-field)
}

.he-footer-global .locale-selection select * {
  color: var(--color--text-primary)
}

.he-footer-global .locale-selection .locale-drpdown__list {
  display: var(--locale-selector-custom-display);
  position: relative;
  height: 100%
}

.he-footer-global .locale-selection .locale-drpdown__list.select-expanded {
  --locale-selector-arrow-rotate: 180deg
}

.he-footer-global .locale-selection * {
  outline: 0
}

.he-footer-global .locale-drpdown__select {
  display: var(--locale-selector-src-display);
  padding-left: var(--spacing-30);
  position: relative
}

.he-footer-global .locale-drpdown__select:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%)
}

.he-footer-global .locale-drpdown__select.selected-en_GB::before,
.he-footer-global .locale-drpdown__select.selected-en-GB::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 24px;
  height: 24px;
  background-size: 40px 24px;
  background-position: center;
  border-radius: 50%;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-flags.svg#icon-flag-gb")
}

.he-footer-global .locale-drpdown__select.selected-en_CA::before,
.he-footer-global .locale-drpdown__select.selected-en-CA::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 24px;
  height: 24px;
  background-size: 40px 24px;
  background-position: center;
  border-radius: 50%;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-flags.svg#icon-flag-ca")
}

.he-footer-global .locale-drpdown__select.selected-en_US::before,
.he-footer-global .locale-drpdown__select.selected-en-US::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 24px;
  height: 24px;
  background-size: 40px 24px;
  background-position: center;
  border-radius: 50%;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-flags.svg#icon-flag-us")
}

.he-footer-global .locale-drpdown__select.selected-en::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-language-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-language-lg")
}

.he-footer-global .locale-drpdown__button,
.he-footer-global .locale-selection__button {
  display: flex;
  align-items: center;
  background: transparent;
  border: 1.5px solid transparent;
  color: var(--color--text-primary-inverse);
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  padding: 0;
  position: relative;
  height: 100%
}

.he-footer-global .locale-drpdown__button:active,
.he-footer-global .locale-selection__button:active,
.he-footer-global .locale-drpdown__button:focus,
.he-footer-global .locale-selection__button:focus,
.he-footer-global .locale-drpdown__button:focus-visible,
.he-footer-global .locale-selection__button:focus-visible {
  outline: 2px solid var(--color--interactive-focus-field)
}

.he-footer-global .locale-drpdown__button[aria-expanded="true"] .icon-expand-md::after,
.he-footer-global .locale-selection__button[aria-expanded="true"] .icon-expand-md::after {
  -webkit-transform: translateY(0) rotate(180deg);
  transform: translateY(0) rotate(180deg)
}

.he-footer-global .locale-drpdown__button::before,
.he-footer-global .locale-selection__button::before {
  margin-right: var(--spacing-5)
}

.he-footer-global .locale-drpdown__button.selected_en_GB::before,
.he-footer-global .locale-selection__button.selected_en_GB::before,
.he-footer-global .locale-drpdown__button.selected_en-GB::before,
.he-footer-global .locale-selection__button.selected_en-GB::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 24px;
  height: 24px;
  background-size: 40px 24px;
  background-position: center;
  border-radius: 50%;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-flags.svg#icon-flag-gb")
}

.he-footer-global .locale-drpdown__button.selected_en_CA::before,
.he-footer-global .locale-selection__button.selected_en_CA::before,
.he-footer-global .locale-drpdown__button.selected_en-CA::before,
.he-footer-global .locale-selection__button.selected_en-CA::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 24px;
  height: 24px;
  background-size: 40px 24px;
  background-position: center;
  border-radius: 50%;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-flags.svg#icon-flag-ca")
}

.he-footer-global .locale-drpdown__button.selected_en_US::before,
.he-footer-global .locale-selection__button.selected_en_US::before,
.he-footer-global .locale-drpdown__button.selected_en-US::before,
.he-footer-global .locale-selection__button.selected_en-US::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  cursor: inherit;
  background-color: transparent;
  width: 24px;
  height: 24px;
  background-size: 40px 24px;
  background-position: center;
  border-radius: 50%;
  background-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-flags.svg#icon-flag-us")
}

.he-footer-global .locale-drpdown__button.selected_en::before,
.he-footer-global .locale-selection__button.selected_en::before {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-language-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-account.svg#icon-language-lg")
}

.he-footer-global .locale-drpdown__button::after,
.he-footer-global .locale-selection__button::after {
  margin-left: var(--spacing-10);
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-sm");
  transform: rotate(var(--locale-selector-arrow-rotate, 0));
  transition: var(--he-transition)
}

.he-footer-global .locale-drpdown__dropdown,
.he-footer-global .locale-selection__dropdown {
  display: var(--locale-selector-dd-display) !important;
  position: absolute;
  top: calc(100% + var(--locale-selector-dd-mt));
  right: 0;
  box-shadow: var(--shadow);
  overflow: hidden;
  width: auto;
  min-width: 100%;
  max-width: 80vw;
  padding: 0;
  background-color: var(--color--ui-02);
  border-radius: var(--corners-semiround-sm);
  z-index: 3
}

.he-footer-global .locale-drpdown__dropdown.isOpen,
.he-footer-global .locale-selection__dropdown.isOpen {
  --locale-selector-dd-display: block
}

.he-footer-global .locale-drpdown__dropdown .custom-select-option,
.he-footer-global .locale-selection__dropdown .custom-select-option,
.he-footer-global .locale-drpdown__dropdown li,
.he-footer-global .locale-selection__dropdown li {
  list-style-type: none;
  margin: 0;
  padding: 0
}

.he-footer-global .locale-drpdown__dropdown .custom-select-option.selected,
.he-footer-global .locale-selection__dropdown .custom-select-option.selected,
.he-footer-global .locale-drpdown__dropdown li.selected,
.he-footer-global .locale-selection__dropdown li.selected {
  display: none
}

.he-footer-global .locale-drpdown__dropdown .custom-select-option:first-child,
.he-footer-global .locale-selection__dropdown .custom-select-option:first-child,
.he-footer-global .locale-drpdown__dropdown li:first-child,
.he-footer-global .locale-selection__dropdown li:first-child {
  border-top-left-radius: var(--corners-semiround-sm);
  border-top-right-radius: var(--corners-semiround-sm)
}

.he-footer-global .locale-drpdown__dropdown .custom-select-option:last-child,
.he-footer-global .locale-selection__dropdown .custom-select-option:last-child,
.he-footer-global .locale-drpdown__dropdown li:last-child,
.he-footer-global .locale-selection__dropdown li:last-child {
  border-bottom-left-radius: var(--corners-semiround-sm);
  border-bottom-right-radius: var(--corners-semiround-sm)
}

.he-footer-global .locale-drpdown__dropdown .custom-select-option a,
.he-footer-global .locale-selection__dropdown .custom-select-option a,
.he-footer-global .locale-drpdown__dropdown li a,
.he-footer-global .locale-selection__dropdown li a {
  font-size: var(--typesize-default);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-5);
  color: var(--color--text-primary);
  white-space: nowrap;
  text-decoration: none;
  background-color: inherit;
  position: relative;
  border-radius: inherit;
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  display: block;
  padding: var(--spacing-10) var(--spacing-15);
  transition: all .125s ease-in-out
}

.he-footer-global .locale-drpdown__dropdown .custom-select-option a:hover,
.he-footer-global .locale-selection__dropdown .custom-select-option a:hover,
.he-footer-global .locale-drpdown__dropdown li a:hover,
.he-footer-global .locale-selection__dropdown li a:hover,
.he-footer-global .locale-drpdown__dropdown .custom-select-option a:focus,
.he-footer-global .locale-selection__dropdown .custom-select-option a:focus,
.he-footer-global .locale-drpdown__dropdown li a:focus,
.he-footer-global .locale-selection__dropdown li a:focus,
.he-footer-global .locale-drpdown__dropdown .custom-select-option a:active,
.he-footer-global .locale-selection__dropdown .custom-select-option a:active,
.he-footer-global .locale-drpdown__dropdown li a:active,
.he-footer-global .locale-selection__dropdown li a:active {
  font-weight: var(--typeweight--semibold);
  background-color: var(--color--ui-01)
}

.he-footer-global .locale-drpdown__dropdown .custom-select-option a:focus-visible,
.he-footer-global .locale-selection__dropdown .custom-select-option a:focus-visible,
.he-footer-global .locale-drpdown__dropdown li a:focus-visible,
.he-footer-global .locale-selection__dropdown li a:focus-visible {
  outline-color: var(--color--interactive-focus-field);
  outline-offset: -2px
}

.he-footer-global .locale-drpdown__dropdown .custom-select-option a::before,
.he-footer-global .locale-selection__dropdown .custom-select-option a::before,
.he-footer-global .locale-drpdown__dropdown li a::before,
.he-footer-global .locale-selection__dropdown li a::before {
  font-weight: var(--typeweight--semibold);
  content: attr(data-text);
  display: block;
  height: 0;
  visibility: hidden;
  overflow: hidden
}

.he-footer-global .he-SierviceModal-wrapper {
  display: none;
  position: fixed;
  flex-flow: column;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  padding: var(--he-dialog-outer-spacing);
  overflow: auto
}

.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href*=".pdf"],
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".txt"],
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".pdf"],
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".txt"] {
  padding-inline-start: 2.875em !important
}

.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href*=".pdf"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".txt"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".pdf"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".txt"]::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 1.25em
}

.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href*=".pdf"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".txt"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".pdf"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".txt"]::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-file-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-file-sm");
  transform: none;
  margin-right: .25em
}

.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".doc"],
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".docx"],
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".ppt"],
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".pptx"],
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".docx"],
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".xls"],
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".xlsx"],
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".zip"],
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".doc"],
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".docx"],
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".ppt"],
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".pptx"],
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".docx"],
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".xls"],
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".xlsx"],
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".zip"] {
  padding-inline-start: 2.875em !important
}

.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".doc"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".docx"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".ppt"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".pptx"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".docx"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".xls"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".xlsx"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".zip"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".doc"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".docx"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".ppt"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".pptx"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".docx"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".xls"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".xlsx"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".zip"]::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 1.25em
}

.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".doc"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".docx"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".ppt"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".pptx"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".docx"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".xls"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".xlsx"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"][href$=".zip"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".doc"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".docx"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".ppt"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".pptx"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".docx"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".xls"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".xlsx"]::after,
.he-footer-global .he-SierviceModal-wrapper [class*="button-"] a[href$=".zip"]::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-download-sm");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-download-sm");
  transform: translateY(0.15em);
  margin-right: .25em
}

.he-footer-global .he-SierviceModal-wrapper .button-primary {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1
}

@media(min-width:376px) {
  .he-footer-global .he-SierviceModal-wrapper .button-primary {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .he-footer-global .he-SierviceModal-wrapper .button-primary {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.he-footer-global .he-SierviceModal-wrapper .button-primary::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.he-footer-global .he-SierviceModal-wrapper .button-primary:hover,
.he-footer-global .he-SierviceModal-wrapper .button-primary:focus,
.he-footer-global .he-SierviceModal-wrapper .button-primary:focus-visible,
.he-footer-global .he-SierviceModal-wrapper .button-primary:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.he-footer-global .he-SierviceModal-wrapper .button-primary:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary:active::before {
  transform: scale(1.05)
}

.he-footer-global .he-SierviceModal-wrapper .button-primary:focus,
.he-footer-global .he-SierviceModal-wrapper .button-primary:focus-visible {
  outline: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-primary:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.he-footer-global .he-SierviceModal-wrapper .button-primary:active::before {
  outline-color: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-primary:only-of-type {
  margin: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-primary.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.he-footer-global .he-SierviceModal-wrapper .button-primary::before {
  background: var(--color--interactive-primary) border-box
}

.he-footer-global .he-SierviceModal-wrapper .button-primary:hover::before {
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-primary-hover) border-box
}

.he-footer-global .he-SierviceModal-wrapper .button-primary:focus,
.he-footer-global .he-SierviceModal-wrapper .button-primary:focus-visible {
  color: var(--color--text-primary-inverse)
}

.he-footer-global .he-SierviceModal-wrapper .button-primary:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary:focus-visible::before {
  background: var(--color--interactive-primary) border-box
}

.he-footer-global .he-SierviceModal-wrapper .button-primary:active {
  color: var(--color--text-primary-inverse)
}

.he-footer-global .he-SierviceModal-wrapper .button-primary:active::before {
  background: var(--color--interactive-primary-active) border-box
}

.he-footer-global .he-SierviceModal-wrapper .button-primary[disabled],
.he-footer-global .he-SierviceModal-wrapper .button-primary.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.he-footer-global .he-SierviceModal-wrapper .button-primary[disabled]::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.he-footer-global .he-SierviceModal-wrapper .button-primary[disabled]:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary.button-disabled:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary[disabled]:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary.button-disabled:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary[disabled]:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary.button-disabled:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary[disabled]:active::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary-inverse)
}

@media(min-width:376px) {
  .he-footer-global .he-SierviceModal-wrapper .button-primary-inverse {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .he-footer-global .he-SierviceModal-wrapper .button-primary-inverse {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:hover,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:focus,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:focus-visible,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:active::before {
  transform: scale(1.05)
}

.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:focus,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:focus-visible {
  outline: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:active::before {
  outline-color: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:only-of-type {
  margin: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse::before {
  background: var(--color--interactive-primary-inverse)
}

.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:hover {
  color: var(--color--text-primary-inverse)
}

.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:hover::before {
  background: var(--color--interactive-primary-inverse-hover, var(--color--interactive-primary-hover-inverse))
}

.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:focus,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:focus-visible {
  color: var(--color--text-primary-inverse)
}

.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:focus-visible::before {
  background: var(--color--interactive-primary-inverse)
}

.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:active {
  color: var(--color--text-primary-inverse)
}

.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse:active::before {
  background: var(--color--interactive-primary-inverse-active, var(--color--interactive-primary-active-inverse))
}

.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse[disabled],
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02);
  color: var(--color--interactive-disabled-03)
}

.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse[disabled]::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse[disabled]:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse.button-disabled:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse[disabled]:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse.button-disabled:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse[disabled]:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse.button-disabled:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse[disabled]:active::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse[disabled]::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse[disabled]:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse.button-disabled:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse[disabled]:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse.button-disabled:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse[disabled]:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse.button-disabled:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse[disabled]:active::before,
.he-footer-global .he-SierviceModal-wrapper .button-primary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01)
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1
}

@media(min-width:376px) {
  .he-footer-global .he-SierviceModal-wrapper .button-secondary {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .he-footer-global .he-SierviceModal-wrapper .button-secondary {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary:hover,
.he-footer-global .he-SierviceModal-wrapper .button-secondary:focus,
.he-footer-global .he-SierviceModal-wrapper .button-secondary:focus-visible,
.he-footer-global .he-SierviceModal-wrapper .button-secondary:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary:active::before {
  transform: scale(1.05)
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary:focus,
.he-footer-global .he-SierviceModal-wrapper .button-secondary:focus-visible {
  outline: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary:active::before {
  outline-color: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary:only-of-type {
  margin: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary::before {
  background: var(--color--interactive-secondary) border-box
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary:hover {
  color: var(--color--text-primary-inverse)
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary:hover::before {
  background: var(--color--interactive-secondary-hover) border-box
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary:focus,
.he-footer-global .he-SierviceModal-wrapper .button-secondary:focus-visible {
  color: var(--color--text-primary-inverse)
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary:focus-visible::before {
  background: var(--color--interactive-secondary) border-box
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary:active {
  color: var(--color--text-primary-inverse)
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary:active::before {
  background: var(--color--interactive-secondary-active) border-box
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary[disabled],
.he-footer-global .he-SierviceModal-wrapper .button-secondary.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary[disabled]::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary[disabled]:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary.button-disabled:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary[disabled]:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary.button-disabled:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary[disabled]:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary.button-disabled:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary[disabled]:active::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary)
}

@media(min-width:376px) {
  .he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:hover,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:focus,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:focus-visible,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:active::before {
  transform: scale(1.05)
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:focus,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:focus-visible {
  outline: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:active::before {
  outline-color: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:only-of-type {
  margin: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse::before {
  background: var(--color--interactive-secondary-inverse)
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:hover {
  color: var(--color--text-primary)
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:hover::before {
  background: var(--color--interactive-secondary-inverse-hover, var(--color--interactive-secondary-hover-inverse))
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:focus,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:focus-visible {
  color: var(--color--text-primary)
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:focus-visible::before {
  background: var(--color--interactive-secondary-inverse)
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:active {
  color: var(--color--text-primary)
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse:active::before {
  background: var(--color--interactive-secondary-inverse-active, var(--color--interactive-secondary-active-inverse))
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse[disabled],
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02);
  color: var(--color--interactive-disabled-03)
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse[disabled]::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse[disabled]:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse.button-disabled:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse[disabled]:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse.button-disabled:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse[disabled]:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse.button-disabled:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse[disabled]:active::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse[disabled]::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse[disabled]:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse.button-disabled:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse[disabled]:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse.button-disabled:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse[disabled]:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse.button-disabled:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse[disabled]:active::before,
.he-footer-global .he-SierviceModal-wrapper .button-secondary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01)
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary);
  transition: color .125s ease-in-out
}

@media(min-width:376px) {
  .he-footer-global .he-SierviceModal-wrapper .button-tertiary {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .he-footer-global .he-SierviceModal-wrapper .button-tertiary {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary:hover,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary:focus,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary:focus-visible,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary:active::before {
  transform: scale(1.05)
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary:focus,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary:focus-visible {
  outline: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary:active::before {
  outline-color: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary:only-of-type {
  margin: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary::before {
  outline-color: var(--color--interactive-tertiary);
  outline-offset: -2px;
  background-color: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary:hover {
  color: var(--color--text-primary-inverse)
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary:hover::before {
  outline-color: var(--color--interactive-tertiary-hover);
  background: var(--color--interactive-tertiary-hover)
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary:focus,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary:focus-visible {
  color: var(--color--text-tertiary)
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary:active {
  color: var(--color--text-primary-inverse)
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary:active::before {
  background: var(--color--interactive-tertiary-active)
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary[disabled],
.he-footer-global .he-SierviceModal-wrapper .button-tertiary.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary[disabled]::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary[disabled]:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary.button-disabled:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary[disabled]:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary.button-disabled:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary[disabled]:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary.button-disabled:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary[disabled]:active::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary[disabled]::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary.button-disabled::before {
  outline-color: var(--color--interactive-disabled-01);
  outline-offset: -2px;
  background: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary[disabled]:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary.button-disabled:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary[disabled]:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary.button-disabled:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary[disabled]:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary.button-disabled:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary[disabled]:active::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary.button-disabled:active::before {
  background: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary-inverse)
}

@media(min-width:376px) {
  .he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:hover,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:focus,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:focus-visible,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:active::before {
  transform: scale(1.05)
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:focus,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:focus-visible {
  outline: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:active::before {
  outline-color: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:only-of-type {
  margin: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse::before {
  outline-color: var(--color--interactive-tertiary-inverse);
  outline-offset: -2px;
  background: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:hover {
  color: var(--color--text-primary)
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:hover::before {
  outline-color: transparent;
  background: var(--color--interactive-tertiary-inverse-hover, var(--color--interactive-tertiary-hover-inverse))
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:focus,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:focus-visible {
  color: var(--color--text-primary-inverse)
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:focus-visible::before {
  outline-color: var(--color--interactive-focus-field);
  background: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:active {
  color: var(--color--text-primary)
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse:active::before {
  background: var(--color--interactive-tertiary-inverse-active, var(--color--interactive-tertiary-active-inverse))
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse[disabled],
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse[disabled]::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse[disabled]:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse.button-disabled:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse[disabled]:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse.button-disabled:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse[disabled]:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse.button-disabled:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse[disabled]:active::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse[disabled]::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse.button-disabled::before {
  outline-color: var(--color--interactive-disabled-02);
  background: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse[disabled]:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse.button-disabled:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse[disabled]:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse.button-disabled:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse[disabled]:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse.button-disabled:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse[disabled]:active::before,
.he-footer-global .he-SierviceModal-wrapper .button-tertiary-inverse.button-disabled:active::before {
  background: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-action {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary)
}

@media(min-width:376px) {
  .he-footer-global .he-SierviceModal-wrapper .button-action {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .he-footer-global .he-SierviceModal-wrapper .button-action {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.he-footer-global .he-SierviceModal-wrapper .button-action::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.he-footer-global .he-SierviceModal-wrapper .button-action:hover,
.he-footer-global .he-SierviceModal-wrapper .button-action:focus,
.he-footer-global .he-SierviceModal-wrapper .button-action:focus-visible,
.he-footer-global .he-SierviceModal-wrapper .button-action:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.he-footer-global .he-SierviceModal-wrapper .button-action:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-action:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-action:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-action:active::before {
  transform: scale(1.05)
}

.he-footer-global .he-SierviceModal-wrapper .button-action:focus,
.he-footer-global .he-SierviceModal-wrapper .button-action:focus-visible {
  outline: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-action:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-action:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.he-footer-global .he-SierviceModal-wrapper .button-action:active::before {
  outline-color: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-action:only-of-type {
  margin: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-action.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.he-footer-global .he-SierviceModal-wrapper .button-action::before {
  background: var(--color--interactive-action) border-box
}

.he-footer-global .he-SierviceModal-wrapper .button-action:hover::before {
  color: var(--color--text-primary-inverse);
  background: var(--color--interactive-action-hover) border-box
}

.he-footer-global .he-SierviceModal-wrapper .button-action:focus,
.he-footer-global .he-SierviceModal-wrapper .button-action:focus-visible {
  color: var(--color--text-primary)
}

.he-footer-global .he-SierviceModal-wrapper .button-action:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-action:focus-visible::before {
  background: var(--color--interactive-action-focus) border-box
}

.he-footer-global .he-SierviceModal-wrapper .button-action:active {
  color: var(--color--text-primary-inverse)
}

.he-footer-global .he-SierviceModal-wrapper .button-action:active::before {
  background: var(--color--interactive-action-active) border-box
}

.he-footer-global .he-SierviceModal-wrapper .button-action[disabled],
.he-footer-global .he-SierviceModal-wrapper .button-action.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.he-footer-global .he-SierviceModal-wrapper .button-action[disabled]::before,
.he-footer-global .he-SierviceModal-wrapper .button-action.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.he-footer-global .he-SierviceModal-wrapper .button-action[disabled]:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-action.button-disabled:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-action[disabled]:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-action.button-disabled:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-action[disabled]:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-action.button-disabled:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-action[disabled]:active::before,
.he-footer-global .he-SierviceModal-wrapper .button-action.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.he-footer-global .he-SierviceModal-wrapper .button-action[disabled]::before,
.he-footer-global .he-SierviceModal-wrapper .button-action.button-disabled::before {
  outline-offset: -2px;
  outline-color: var(--color--interactive-disabled-01);
  background: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-action[disabled]:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-action.button-disabled:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-action[disabled]:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-action.button-disabled:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-action[disabled]:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-action.button-disabled:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-action[disabled]:active::before,
.he-footer-global .he-SierviceModal-wrapper .button-action.button-disabled:active::before {
  background: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-action-inverse {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-primary)
}

@media(min-width:376px) {
  .he-footer-global .he-SierviceModal-wrapper .button-action-inverse {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .he-footer-global .he-SierviceModal-wrapper .button-action-inverse {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.he-footer-global .he-SierviceModal-wrapper .button-action-inverse::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:hover,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:focus,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:focus-visible,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:active::before {
  transform: scale(1.05)
}

.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:focus,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:focus-visible {
  outline: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:active::before {
  outline-color: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:only-of-type {
  margin: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-action-inverse.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.he-footer-global .he-SierviceModal-wrapper .button-action-inverse::before {
  background: var(--color--interactive-action-inverse) border-box
}

.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:hover {
  color: var(--color--text-primary)
}

.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:hover::before {
  background: var(--color--interactive-action-inverse-hover, var(--color--interactive-action-hover-inverse)) border-box
}

.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:focus,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:focus-visible {
  color: var(--color--text-primary)
}

.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:focus-visible::before {
  background: var(--color--interactive-action-focus) border-box
}

.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:active {
  color: var(--color--text-primary)
}

.he-footer-global .he-SierviceModal-wrapper .button-action-inverse:active::before {
  background: var(--color--interactive-action-inverse-active, var(--color--interactive-action-active-inverse)) border-box
}

.he-footer-global .he-SierviceModal-wrapper .button-action-inverse[disabled],
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse.button-disabled {
  pointer-events: none;
  color: var(--color--interactive-disabled-02)
}

.he-footer-global .he-SierviceModal-wrapper .button-action-inverse[disabled]::before,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse.button-disabled::before {
  background: var(--color--interactive-disabled-01)
}

.he-footer-global .he-SierviceModal-wrapper .button-action-inverse[disabled]:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse.button-disabled:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse[disabled]:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse.button-disabled:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse[disabled]:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse.button-disabled:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse[disabled]:active::before,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse.button-disabled:active::before {
  background: var(--color--interactive-disabled-01);
  transform: none
}

.he-footer-global .he-SierviceModal-wrapper .button-action-inverse[disabled]::before,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse.button-disabled::before {
  outline-offset: -2px;
  outline-color: var(--color--interactive-disabled-02);
  background: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-action-inverse[disabled]:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse.button-disabled:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse[disabled]:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse.button-disabled:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse[disabled]:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse.button-disabled:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse[disabled]:active::before,
.he-footer-global .he-SierviceModal-wrapper .button-action-inverse.button-disabled:active::before {
  background: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-small {
  font-size: var(--typesize-minor);
  padding: .25em 1.25em;
  min-height: 2rem
}

.he-footer-global .he-SierviceModal-wrapper .button-small.icon-only {
  aspect-ratio: 1;
  padding: .5em;
  max-width: 2rem
}

.he-footer-global .he-SierviceModal-wrapper .button-large {
  padding: .75em 1.25em;
  min-height: 3rem
}

.he-footer-global .he-SierviceModal-wrapper .button-large.icon-only {
  aspect-ratio: 1;
  padding: 1em;
  max-width: 3rem
}

.he-footer-global .he-SierviceModal-wrapper .button-block {
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-right: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-unstyled {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-unstyled::before {
  display: none;
  background: transparent;
  border: 0;
  box-shadow: none
}

.he-footer-global .he-SierviceModal-wrapper [class*="button-circular-"] {
  line-height: var(--leading--1);
  padding: 0;
  aspect: 1
}

.he-footer-global .he-SierviceModal-wrapper [class*="button-circular-"]::before {
  border-radius: 50%;
  aspect-ratio: 1
}

.he-footer-global .he-SierviceModal-wrapper .button-circular-large {
  width: 2.5em;
  height: 2.5em
}

.he-footer-global .he-SierviceModal-wrapper .button-circular-medium {
  width: 2.25em;
  height: 2.25em
}

.he-footer-global .he-SierviceModal-wrapper .button-circular-small {
  width: 2em;
  height: 2em
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-p);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  background: var(--color--label-interactive);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-radius: var(--button-corners);
  padding: var(--spacing-10) var(--spacing-15);
  display: inline-block;
  min-height: 1.5rem;
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  .he-footer-global .he-SierviceModal-wrapper .button-interactive a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .he-footer-global .he-SierviceModal-wrapper .button-interactive a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive a:hover,
.he-footer-global .he-SierviceModal-wrapper .button-interactive a:focus,
.he-footer-global .he-SierviceModal-wrapper .button-interactive a:focus-visible,
.he-footer-global .he-SierviceModal-wrapper .button-interactive a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive a:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-interactive a:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-interactive a:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-interactive a:active::before {
  transform: scale(1.05)
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive a:focus,
.he-footer-global .he-SierviceModal-wrapper .button-interactive a:focus-visible {
  outline: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive a:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-interactive a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive a:active::before {
  outline-color: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive a:only-of-type {
  margin: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive a.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive a:hover {
  color: var(--color--text-link-hover)
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive a:focus {
  color: var(--color--text-link)
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive a:focus::before {
  transform: scale(1)
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive a:active {
  color: var(--color--text-link-hover);
  text-decoration: none
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-p);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-radius: var(--button-corners);
  padding: var(--spacing-10) var(--spacing-15);
  display: inline-block;
  min-height: 1.5rem;
  padding: 0 var(--spacing-10);
  pointer-events: none;
  color: var(--color--text-tertiary);
  background: var(--color--label-interactive);
  border: 2px solid var(--color--ui-border-03, var(--color--border-03));
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  .he-footer-global .he-SierviceModal-wrapper .button-non-interactive a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .he-footer-global .he-SierviceModal-wrapper .button-non-interactive a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a:hover,
.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a:focus,
.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a:focus-visible,
.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a:active::before {
  transform: scale(1.05)
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a:focus,
.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a:focus-visible {
  outline: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a:active::before {
  outline-color: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a:only-of-type {
  margin: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a:hover {
  color: var(--color--text-link-hover)
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a:focus {
  color: var(--color--text-link)
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a:focus::before {
  transform: scale(1)
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a:active {
  color: var(--color--text-link-hover);
  text-decoration: none
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  font-size: var(--typesize-p);
  line-height: var(--leading--1-6);
  color: var(--color--text-link);
  background: var(--color--label-interactive);
  border: 1px solid var(--color--ui-border-01, var(--color--border-01));
  border-radius: var(--button-corners);
  padding: var(--spacing-10) var(--spacing-15);
  display: inline-block;
  min-height: 1.5rem;
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  .he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a:hover,
.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a:focus,
.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a:focus-visible,
.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a:active::before {
  transform: scale(1.05)
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a:focus,
.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a:focus-visible {
  outline: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a:active::before {
  outline-color: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a:only-of-type {
  margin: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a:hover {
  color: var(--color--text-link-hover)
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a:focus {
  color: var(--color--text-link)
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a:focus::before {
  transform: scale(1)
}

.he-footer-global .he-SierviceModal-wrapper .button-non-interactive a a:active {
  color: var(--color--text-link-hover);
  text-decoration: none
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a {
  font-family: var(--typefamily-primary);
  font-weight: var(--typeweight--semibold);
  font-size: var(--typesize-default);
  line-height: var(--leading--1-5);
  white-space: normal;
  text-decoration: none;
  color: var(--color--text-primary-inverse);
  background-color: transparent;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  padding: .375em 1.25em;
  position: relative;
  z-index: 1;
  color: var(--color--text-secondary);
  padding: var(--spacing-10) 25px;
  border-radius: var(--button-corners);
  margin: 0 var(--spacing-10) var(--spacing-10) 0 !important
}

@media(min-width:376px) {
  .he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a {
    display: inline-flex;
    margin: 0 15px 20px 0
  }
}

@media(max-width:375px) {
  .he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-right: 0
  }
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--button-corners);
  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  transition: all .125s ease-in-out
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a:hover,
.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a:focus,
.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a:focus-visible,
.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a:active {
  color: var(--color--text-primary-inverse);
  text-decoration: none;
  transform: none
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a:hover::before,
.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a:focus-visible::before,
.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a:active::before {
  transform: scale(1.05)
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a:focus,
.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a:focus-visible {
  outline: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a:focus::before,
.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a:focus-visible::before {
  outline-width: 3px;
  outline-color: var(--color--interactive-focus-field)
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a:active::before {
  outline-color: transparent
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a:only-of-type {
  margin: 0
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a.icon-only {
  aspect-ratio: 1;
  padding: .625em;
  max-width: 2.25rem
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a::before {
  background: var(--color--label-interactive);
  border: 1px solid rgba(39, 49, 49, 0.15)
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a:hover {
  color: var(--color--text-secondary)
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a:hover::before {
  background: #e6ecef
}

.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a:focus,
.he-footer-global .he-SierviceModal-wrapper .button-interactive-tertiary a:active {
  color: var(--color--text-secondary);
  background: var(--color--label-interactive)
}

.he-footer-global .he-SierviceModal-wrapper::before,
.he-footer-global .he-SierviceModal-wrapper .he-ServiceModal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0
}

.he-footer-global .he-SierviceModal-wrapper::before {
  content: '';
  background-color: var(--color--ui-overlay-dark, var(--color--overlay-dark));
  opacity: 0;
  transition: opacity 1s ease-in-out
}

.he-footer-global .he-SierviceModal-wrapper .he-SierviceModal {
  position: relative;
  width: 100%;
  max-width: var(--he-dialog-max-w);
  background-color: var(--color--ui-02);
  border-radius: 8px;
  z-index: 1
}

.he-footer-global .he-SierviceModal-wrapper .he-SierviceModal .he-SierviceModal-header {
  height: var(--spacing-60)
}

.he-footer-global .he-SierviceModal-wrapper .he-SierviceModal .he-SierviceModal-body {
  padding: 0 var(--spacing-45) var(--spacing-45) var(--spacing-45)
}

.he-footer-global .he-SierviceModal-wrapper .he-SierviceModal .he-SierviceModal-body h2 {
  font-family: var(--typefamily-primary);
  font-size: var(--typesize-h3);
  font-weight: var(--typeweight--default);
  line-height: var(--leading--1-2)
}

.he-footer-global .he-SierviceModal-wrapper .he-SierviceModal #localeServiceModal {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  text-align: center
}

.he-footer-global .he-SierviceModal-wrapper .he-SierviceModal #localeServiceModal p {
  margin-top: var(--spacing-30);
  margin-bottom: var(--spacing-10)
}

.he-footer-global .he-SierviceModal-wrapper .he-SierviceModal #localeServiceModal .locale-dialog-cta {
  margin-top: var(--spacing-20)
}

// .he-footer-global .he-SierviceModal-wrapper .he-SierviceModal .decorative-pic{align-self:center;width:128px;height:128px;background-image:url(../../../../../content/dam/global-store/global/educator/blue-globe.svg);background-size:contain;background-repeat:no-repeat}
.he-footer-global .he-SierviceModal-wrapper .he-SierviceModal .he-SierviceModal-closeBtn {
  position: absolute;
  display: flex;
  width: 25px;
  height: 25px;
  right: 13px;
  top: 18px;
  align-items: center;
  justify-content: center;
  border: 0;
  background: 0
}

.he-footer-global .he-SierviceModal-wrapper .he-SierviceModal .he-SierviceModal-closeBtn::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background: currentColor;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg");
  mask-image: url("highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-navigation.svg#icon-close-lg")
}

.he-footer-global .he-SierviceModal-wrapper.isOpen {
  display: flex;
  pointer-events: all
}

.he-footer-global .he-SierviceModal-wrapper.isOpen::before {
  opacity: 1
}

@media(max-width:767px) {
  .he-footer-global {
    --he-dialog-outer-spacing: 100px var(--spacing-15)
  }
}

.he-footer-global * {
  box-sizing: border-box
}

.he-footer-global .container {
  margin-left: auto;
  margin-right: auto
}

html.wf-active body>.container {
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%
}

.xf-web-container .accordion-panel-content {
  display: block
}

.xf-web-container .he-footer-global .c-footer__link-list ul {
  padding-left: unset
}
