.bg168781763 {
  background-image: url('2400x800-shutterstock_1507117235.jpg');
  background-size: cover;
  background-position: center;
background-repeat: no-repeat;
}
@media screen and (max-width: 767px) {
  .bg168781763 {
    background-image: url("800x970-shutterstock_1507117235.jpg");
  }
}

iframe.hidden {display:none}
