
// MOVED to
:root {
  --color--text-border: var(--color--ui-border-01, var(--color--border-01));
  --color-sign-in-button: var(--color--interactive-primary);
  --spacing-40: 40px;
  --typefamily-primary-header: 'typefamily-primary', var(--typefamily-primary);
  --he-headerPosition: relative;
  --he-header-min-h: 64px;
  --he-header-y-shift: 0;
  --he-header-grid: auto auto 1fr auto;
  --he-header-gaps: var(--spacing-90);
  --he-header-bg-color: var(--color--ui-background-dark, var(--color--background-dark));
  --he-header-link-color: var(--color--ui-02);
  --he-text-color-global: var(--color--interactive-tertiary-inverse);
  --he-header-usernav-inverse: var(--color--text-primary);
  --he-logo-filter-color: none;
  --he-header-link-color-inverse: var(--color--text-secondary);
  --he-header-desktop-nav-display: block;
  --he-header-mobile-nav-display: none;
  --headerContainerSpacingY: 0;
  --headerContainerSpacingX: var(--spacing-20);
  --he-header-nav-icon-size: 36px;
  --he-header-nav-divider-color: var(--color--ui-01);
  --he-header-underlay-opacity: 1;
  --he-header-cart-icon-size: 24px;
  --he-headrer-display-icon-nav-message: flex;
  --he-heaader-display-search: block;
  --he-header-display-additional: flex;
  --he-headrer-display-reduced-message: none;
  --he-color-text-secure-checkout: var(--color--text-primary-inverse);
  --he-header-display-global-link-desktop: block;
  --he-header-display-global-link-mobile: none;
  --he-header-width-dropdown: 230px;
  --he-header-mx-w-dropdown: 50vw;
  --he-header-search-bg: rgba(254, 254, 254, 0.35);
  --he-header-search-bg-alt: rgba(254, 254, 254, 0.8);
  --he-header-search-bg-focuse: rgba(247, 249, 253, 0.7);
  --he-header-search-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  --he-header-search-color: var(--color--text-secondary-inverse);
  --he-header-search-color-alt: #f7f9fd;
  --he-header-search-border-color: var(--color--icon-tertiary-inverse);
  --he-header-logo-height: 36px;
  --he-header-logo-width: 116px;
  --he-header-icon-nav-grow: 0;
  --he-inverse-text-primary1: var(--color--text-primary-inverse);
  --he-text-secondary1: #333;
  --he-nav--mobile-display: none;
  --he-topnav-display: block;
  --he-topnav-min-h: 40px;
  --he-topnav-bg-c: var(--color--icon-secondary);
  --he-top-nav-base-text-color: #fefefe;
  --he-top-nav-base-text-opacity: .59;
  --he-top-nav-base-text-size: var(--typesize-milli);
  --he-topnav-z: 4;
  --he-topnav-opacity: 1;
  --he-topnav-drdwn-display: none;
  --he-topnav-locale-selector-underline-shift: 29px;
  --he-topnav-locale-selector-underline-w: calc(100% - var(--he-topnav-locale-selector-underline-shift));
  --he-nav-interactive-c: var(--color--interactive-action);
  --he-primery-nav-link-c-base: var(--color--text-primary-inverse);
  --he-primery-nav-text-size-base: var(--typesize-default);
  --he-primery-nav-logo-w: auto;
  --he-primery-nav-locale-display: flex;
  --he-mobile-nav-display: none;
  --he-mobile-nav-level: 0;
  --he-mobile-nav-bgc: var(--color--ui-background-light, var(--color--background-light));
  --he-transition: .125s ease-in-out;
  --he-mobile-nav-min-h: 0;
  --he-mobile-nav-height: calc(100vh - var(--he-header-min-h));
  --he-mobile-nav-base-text-size: var(--typesize-default);
  --he-mobile-nav-base-text-line: var(--leading--1-6);
  --he-mobile-nav-base-text-color: var(--color--text-primary);
  --he-mobile-nav-level-transition: 0
}

:root div.xf-content-height {
  margin: 0;
  min-height: unset
}
