input.parsley-error,
select.parsley-error,
textarea.parsley-error {
  color: #a94442;
  border: 1px solid #a94442
}

.parsley-errors-list {
  color: #a94442;
  padding: 0;
  margin: 0;
  list-style-type: none;
  opacity: 0
}

.parsley-errors-list li {
  margin: 0
}

.parsley-errors-list.filled {
  opacity: 1;
  clear: both
}
