.PCOM {
  .PriceContainer {
    display: flex;
    padding: 30px;
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
    font-family: var(--typefamily--primary-stack);

    .Row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .Text {
      color: var(--color-text-primary, #151515);
      font-size: 1rem;
      font-weight: 400;
    }

    .Total {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-top: 1px solid var(--ui-elements-border-01, #dfe1e1);
      border-bottom: 1px solid var(--ui-elements-border-01, #dfe1e1);
      padding: 30px 0;

      h3 {
        color: var(--color-text-primary, #151515);
        font-size: 1.125rem;
        font-weight: 600;
      }

      p {
        color: var(--color-text-primary, #151515);
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
  }
}
