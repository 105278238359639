:root {
  --color--ui-background-light: #ffffffff;
  --color--ui-background-gray: #fafafaff;
  --color--ui-background-med: #f3f8fbff;
  --color--ui-background-med-gray: #dfe1e1ff;
  --color--ui-background-dark-gray: #333333ff;
  --color--ui-background-dark: #151515ff;
  --color--ui-01: #f5f5f5ff;
  --color--ui-02: #fefefeff;
  --color--ui-03: #151515ff;
  --color--ui-04: #505759ff;
  --color--ui-05: #919191ff;
  --color--ui-06: #e6e6e6ff;
  --color--ui-border-01: #dfe1e1ff;
  --color--ui-border-02: #151515ff;
  --color--ui-border-03: #919191ff;
  --color--ui-field: #ffffffff;
  --color--ui-overlay-dark: #151515b3;
  --color--ui-overlay-light: #50575980;
  --gradient--ui-dark: linear-gradient(125.83deg, #00000040 0%, #0000001d 41%, #0000001d 56.00000000000001%, #00000040 100%);
  --gradient--ui-light: linear-gradient(313.98deg, #99999940 0%, #f2f2f240 41%, #f2f2f240 56.00000000000001%, #99999940 100%);
  --gradient--ui-light-on-dark: linear-gradient(43.98deg, #cccccc40 0%, #f2f2f21d 41%, #f2f2f21d 56.00000000000001%, #cccccc40 100%);
  --color--text-primary: #151515ff;
  --color--text-secondary: #333333ff;
  --color--text-tertiary: #505759ff;
  --color--text-link: #007a9cff;
  --color--text-link-hover: #005d77ff;
  --color--text-link-active: #004b60ff;
  --color--text-link-focus: #005d77ff;
  --color--text-primary-inverse: #fefefeff;
  --color--text-secondary-inverse: #fafafaff;
  --color--text-tertiary-inverse: #f5f5f5ff;
  --color--text-quaternary-inverse: #a8abacff;
  --color--text-link-inverse: #fefefeff;
  --color--text-link-inverse-hover: #fafafaff;
  --color--text-link-inverse-active: #f5f5f5ff;
  --color--icon-primary: #151515ff;
  --color--icon-secondary: #333333ff;
  --color--icon-tertiary: #505759ff;
  --color--icon-quaternary: #a8abacff;
  --color--icon-accent-01: #9e007eff;
  --color--icon-link: #007a9cff;
  --color--icon-link-hover: #005d77ff;
  --color--icon-link-active: #004b60ff;
  --color--icon-primary-inverse: #fefefeff;
  --color--icon-secondary-inverse: #fafafaff;
  --color--icon-tertiary-inverse: #f5f5f5ff;
  --color--icon-link-inverse: #fefefeff;
  --color--icon-link-inverse-hover: #fafafaff;
  --color--icon-link-inverse-active: #f5f5f5ff;
  --color--interactive-action: #ffbb1cff;
  --color--interactive-action-hover: #151515ff;
  --color--interactive-action-active: #151515ff;
  --color--interactive-action-focus: #ffffffff;
  --color--interactive-primary: #9e007eff;
  --color--interactive-primary-hover: #722351ff;
  --color--interactive-primary-active: #722351ff;
  --color--interactive-secondary: #151515ff;
  --color--interactive-secondary-hover: #505759ff;
  --color--interactive-secondary-active: #505759ff;
  --color--interactive-tertiary: #151515ff;
  --color--interactive-tertiary-hover: #151515ff;
  --color--interactive-tertiary-active: #151515ff;
  --color--interactive-accent-03-primary: #269c93ff;
  --color--interactive-accent-03-primary-hover: #28aba1ff;
  --color--interactive-accent-03-primary-active: #2abaafff;
  --color--label-interactive: #f3f8fbff;
  --color--label-non-interactive: #f5f5f5ff;
  --color--interactive-action-inverse: #ffbb1cff;
  --color--interactive-action-inverse-hover: #ffd06bff;
  --color--interactive-action-inverse-focus: #ffffffff;
  --color--interactive-action-inverse-active: #ffd06bff;
  --color--interactive-primary-inverse: #ae367eff;
  --color--interactive-primary-inverse-hover: #be4b90ff;
  --color--interactive-primary-inverse-active: #be4b90ff;
  --color--interactive-secondary-inverse: #ffffffff;
  --color--interactive-secondary-inverse-hover: #e1ffffff;
  --color--interactive-secondary-inverse-active: #e1ffffff;
  --color--interactive-tertiary-inverse: #ffffffff;
  --color--interactive-tertiary-inverse-hover: #ffffffff;
  --color--interactive-tertiary-inverse-active: #ffffffff;
  --color--interactive-focus: #4fa8ffff;
  --color--interactive-focus-field: #2192fbff;
  --color--interactive-disabled-01: #e6e6e6ff;
  --color--interactive-disabled-02: #919191ff;
  --color--interactive-disabled-03: #767676ff;
  --color--interactive-disabled-04: #666666ff;
  --gradient--interactive-accent-01: linear-gradient(279.07deg, #ff8275ff 6%, #9e007eff 73%, #722351ff 100%);
  --color--alert-background: #effbfcff;
  --color--alert-error: #d30018ff;
  --color--alert-success: #008638ff;
  --color--alert-warning: #fc630fff;
  --color--alert-information: #151515ff;
  --color--alert-error-inverse: #eb3d1aff;
  --color--alert-success-inverse: #12b2a6ff;
  --color--alert-warning-inverse: #ff9c1aff;
  --color--alert-information-inverse: #fefefeff;
  --color--accent-01-light: #ffbb1cff;
  --color--accent-01-primary: #ff757aff;
  --color--accent-01-medium: #9e007eff;
  --color--accent-01-dark: #722362ff;
  --color--accent-02-light: #d2db0eff;
  --color--accent-02-primary: #84bd00ff;
  --color--accent-02-medium: #008638ff;
  --color--accent-02-dark: #003a00ff;
  --color--accent-03-light: #94e7eaff;
  --color--accent-03-primary: #12b2a6ff;
  --color--accent-03-medium: #003057ff;
  --color--accent-03-dark: #000a31ff;
  --color--tint-accent-01-medium-03: #9e007e08
}

:root {
  --corners-semiround-sm: 0.25em;
  --corners-semiround-md: 0.5em;
  --corners-semiround-pill: 2.5em;
  --button-corners: var(--corners-semiround-pill)
}

:root {
  --shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  --shadow-blur: 0px 0px 20px rgba(0, 0, 0, 0.15)
}

:root {
  --spacing-90: 90px;
  --spacing-60: 60px;
  --spacing-45: 45px;
  --spacing-30: 30px;
  --spacing-20: 20px;
  --spacing-15: 15px;
  --spacing-10: 10px;
  --spacing-5: 5px;
  --space-unit: 5px;
  --space-zero: calc(0 * var(--space-unit));
  --space-xxxsmall: calc(1 * var(--space-unit));
  --space-xxsmall: calc(2 * var(--space-unit));
  --space-xsmall: calc(3 * var(--space-unit));
  --space-small: calc(4 * var(--space-unit));
  --space-medium: calc(6 * var(--space-unit));
  --space-large: calc(9 * var(--space-unit));
  --space-xlarge: calc(12 * var(--space-unit));
  --space-xxlarge: calc(15 * var(--space-unit));
  --space-xxxlarge: calc(18 * var(--space-unit));
  --space-xxxxlarge: calc(24 * var(--space-unit))
}

:root {
  --color--background-light: var(--color--ui-background-light, #ffffffff);
  --color--background-gray: var(--color--ui-background-gray, #fafafaff);
  --color--background-medium: var(--color--ui-background-med, #f3f8fbff);
  --color--background-medium-gray: var(--color--ui-background-med-gray, #dfe1e1ff);
  --color--background-dark: var(--color--ui-background-dark, #151515ff);
  --color--border-01: var(--color--ui-border-01, #dfe1e1ff);
  --color--border-02: var(--color--ui-border-02, #151515ff);
  --color--border-03: var(--color--ui-border-03, #919191ff);
  --color--field: var(--color--ui-field, #ffffffff);
  --color--overlay-dark: var(--color--ui-overlay-dark, #151515b3);
  --color--overlay-light: var(--color--ui-overlay-light, #50575980);
  --color--text-link-hover-inverse: var(--color--text-link-inverse-hover, #fafafaff);
  --color--text-link-active-inverse: var(--color--text-link-inverse-active, #f5f5f5ff);
  --color--icon-link-hover-inverse: var(--color--icon-link-inverse-hover, #fafafaff);
  --color--icon-link-active-inverse: var(--color--icon-link-inverse-active, #f5f5f5ff);
  --color--interactive-action-hover-inverse: var(--color--interactive-action-inverse-hover, #ffd06bff);
  --color--interactive-action-active-inverse: var(--color--interactive-action-inverse-active, #ffd06bff);
  --color--interactive-primary-hover-inverse: var(--color--interactive-primary-inverse-hover, #be4b90ff);
  --color--interactive-primary-active-inverse: var(--color--interactive-primary-inverse-active, #be4b90ff);
  --color--interactive-secondary-hover-inverse: var(--color--interactive-secondary-inverse-hover, #e1ffffff);
  --color--interactive-secondary-active-inverse: var(--color--interactive-secondary-inverse-active, #e1ffffff);
  --color--interactive-tertiary-hover-inverse: var(--color--interactive-tertiary-inverse-hover, #ffffffff);
  --color--interactive-tertiary-active-inverse: var(--color--interactive-tertiary-inverse-active, #ffffffff);
  --color--gradient-dark: linear-gradient(125.83deg, #00000040 0%, #0000001d 41%, #0000001d 56.00000000000001%, #00000040 100%);
  --color--gradient-light: linear-gradient(313.98deg, #99999940 0%, #f2f2f240 41%, #f2f2f240 56.00000000000001%, #99999940 100%);
  --color--gradient-light-on-dark: linear-gradient(43.98deg, #cccccc40 0%, #f2f2f21d 41%, #f2f2f21d 56.00000000000001%, #cccccc40 100%);
  --color--accent-01-gradient: linear-gradient(279.07deg, #ff8275ff 6%, #9e007eff 73%, #722351ff 100%)
}

:root {
  --typefamily-fallback: Helvetica, Verdana, sans-serif;
  --typefamily-alt: var(--typefamily-primary);
  --typefamily-primary: "Open Sans Static", var(--typefamily-fallback)
}

@supports (font-variation-settings:normal) {
  :root {
    --typefamily-primary: "Open Sans Variable", var(--typefamily-fallback);
    --typefamily-primary-italic: "Open Sans Italic Variable", var(--typefamily-fallback)
  }
}

:root {
  --typesize-exa: clamp(3.125rem, -1.8750rem + 10.4167vw, 7.5rem);
  --typesize-peta: clamp(3.125rem, -0.4464rem + 7.4405vw, 6.25rem);
  --typesize-tera: clamp(3.125rem, -0.1607rem + 6.8452vw, 6rem);
  --typesize-giga: clamp(3.125rem, 0.9821rem + 4.4643vw, 5rem);
  --typesize-mega: clamp(3.125rem, 1.4107rem + 3.5714vw, 4.625rem);
  --typesize-kilo: clamp(3.125rem, 1.6964rem + 2.9762vw, 4.375rem);
  --typesize-h1-plus: clamp(3.125rem, 4.35vw + 1rem, 4rem);
  --typesize-h1: clamp(2.5rem, 3.25vw + 1rem, 3.125rem);
  --typesize-h1-catalog: clamp(2.375rem, 2.75vw + 1rem, 2.625rem);
  --typesize-h2: clamp(2.125rem, 2.35vw + 1rem, 2.5rem);
  --typesize-h3: clamp(1.75rem, 1.5vw + 1rem, 1.875rem);
  --typesize-h4: 1.5rem;
  --typesize-h5: clamp(1.25rem, 0.6vw + 1rem, 1.375rem);
  --typesize-h6: 1.125rem;
  --typesize-default: 1rem;
  --typesize-p: var(--typesize-default);
  --typesize-minor: 0.875rem;
  --typesize-milli: 0.75rem;
  --typesize-micro: 0.625rem;
  --typeweight--light: 300;
  --typeweight--default: 400;
  --typeweight--medium: 500;
  --typeweight--semibold: var(--typeweight--semibold-plus);
  --typeweight--semibold-plus: 645;
  --typeweight--bold: var(--typeweight--semibold-plus);
  --leading--tightest: 0.825;
  --leading--1: 1;
  --leading--1-1: 1.1;
  --leading--1-15: 1.15;
  --leading--1-2: 1.2;
  --leading--1-3: 1.3;
  --leading--1-4: 1.4;
  --leading--1-5: 1.5;
  --leading--1-6: 1.6;
  --leading--default: var(--leading--1-6);
  --leading--1-7: 1.7;
  --leading--1-8: 1.8;
  --leading--2: 2;
  --typecase-upper: uppercase;
  --typecase-lower: lowercase;
  --typestyle-normal: normal;
  --typestyle-italic: italic;
  --typestyle-oblique: oblique
}

// @font-face {
//   font-family: "Open Sans Variable";
//   font-style: normal;
//   font-display: block;
//   font-weight: 300 800;
//   font-stretch: 75% 100%;
//   unicode-range: U+0-24F, U+259, U+2BB, U+2BC, U+2C6, U+2DA, U+2DC, U+1E00-1EFF, U+2000-206F, U+2074, U+20A0-20CF, U+2113, U+2122, U+2191, U+2193, U+2212, U+2215, U+2C60-2C7F, U+A720-A7FF, U+FEFF, U+FFFD;
//   src: url(/pcp-hed/assets/webfonts/primary/variable/unicode-range/latin+latin-ext/open-sans-variable.woff2) format("woff2 supports variations");
//   src: url(/pcp-hed/assets/webfonts/primary/variable/unicode-range/latin+latin-ext/open-sans-variable.woff2) format("woff2-variations")
// }

// @font-face {
//   font-family: "Open Sans Italic Variable";
//   font-style: italic;
//   font-weight: 300 800;
//   font-stretch: 75% 100%;
//   unicode-range: U+0-24F, U+259, U+2BB, U+2BC, U+2C6, U+2DA, U+2DC, U+1E00-1EFF, U+2000-206F, U+2074, U+20A0-20CF, U+2113, U+2122, U+2191, U+2193, U+2212, U+2215, U+2C60-2C7F, U+A720-A7FF, U+FEFF, U+FFFD;
//   src: url(/pcp-hed/assets/webfonts/primary/variable/unicode-range/latin+latin-ext/opensans-italic-variable.woff2) format("woff2 supports variations");
//   src: url(/pcp-hed/assets/webfonts/primary/variable/unicode-range/latin+latin-ext/opensans-italic-variable.woff2) format("woff2-variations")
// }

// @font-face {
//   font-family: "Open Sans Static";
//   font-style: normal;
//   font-display: block;
//   font-weight: 300;
//   src: url(/pcp-hed/assets/webfonts/primary/static/open-sans-static-300.woff2) format("woff2")
// }

// @font-face {
//   font-family: "Open Sans Static";
//   font-style: italic;
//   font-display: block;
//   font-weight: 300;
//   src: url(/pcp-hed/assets/webfonts/primary/static/open-sans-static-300-italic.woff2) format("woff2")
// }

// @font-face {
//   font-family: "Open Sans Static";
//   font-style: normal;
//   font-display: block;
//   font-weight: 400;
//   src: url(/pcp-hed/assets/webfonts/primary/static/open-sans-static-400.woff2) format("woff2")
// }

// @font-face {
//   font-family: "Open Sans Static";
//   font-style: italic;
//   font-display: block;
//   font-weight: 400;
//   src: url(/pcp-hed/assets/webfonts/primary/static/open-sans-static-400-italic.woff2) format("woff2")
// }

// @font-face {
//   font-family: "Open Sans Static";
//   font-style: normal;
//   font-display: block;
//   font-weight: 500;
//   src: url(/pcp-hed/assets/webfonts/primary/static/open-sans-static-500.woff2) format("woff2")
// }

// @font-face {
//   font-family: "Open Sans Static";
//   font-style: italic;
//   font-display: block;
//   font-weight: 500;
//   src: url(/pcp-hed/assets/webfonts/primary/static/open-sans-static-500-italic.woff2) format("woff2")
// }

// @font-face {
//   font-family: "Open Sans Static";
//   font-style: normal;
//   font-display: block;
//   font-weight: 600;
//   src: url(/pcp-hed/assets/webfonts/primary/static/open-sans-static-600.woff2) format("woff2")
// }

// @font-face {
//   font-family: "Open Sans Static";
//   font-style: italic;
//   font-display: block;
//   font-weight: 600;
//   src: url(/pcp-hed/assets/webfonts/primary/static/open-sans-static-600-italic.woff2) format("woff2")
// }

// @font-face {
//   font-family: "Open Sans Static";
//   font-style: normal;
//   font-display: block;
//   font-weight: 700;
//   src: url(/pcp-hed/assets/webfonts/primary/static/open-sans-static-700.woff2) format("woff2")
// }

// @font-face {
//   font-family: "Open Sans Static";
//   font-style: italic;
//   font-display: block;
//   font-weight: 700;
//   src: url(/pcp-hed/assets/webfonts/primary/static/open-sans-static-700-italic.woff2) format("woff2")
// }
