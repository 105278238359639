.PCOM {
  .course-detail-container {
    text-align: left;
    font-size: 1rem;
    font-family: var(--typefamily--primary-stack);
    .header {
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      vertical-align: middle;
      cursor: pointer;

      h6 {
        color: var(--color-text-primary, #151515);
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.6;
      }
    }

    .arrow {
      color: #151515;
      align-self: center;
    }
    .course-detail-info {
      text-align: left;

      p {
        color: var(--color-text-secondary, #333);
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
      }
    }
  }

  .course-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .InputContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      height: 100px;

      .Text {
        font-family: var(--typefamily--primary-stack);
        color: #333;
        font-size: 0.875rem;
        font-weight: 600;
        text-align: left;
        padding-bottom: 8px;
      }

      .Input {
        height: 48px;
        border-radius: 4px;
        border: 1px solid #ccc;
        background: var(--ui-elements-field, #fff);
        padding: 12px 13px;
        outline: none;

        &:focus,
        &:active {
          border: 3px solid #2192fb;
        }

        &:hover {
          border: 1px solid #151515;
        }
      }

      .info-icon {
        margin-left: 10px;
        width: 14px;
        height: auto;
        align-items: center;
      }

      .SuccessText {
        font-family: var(--typefamily--primary-stack);
        color: #008638;
        font-size: 0.875rem;
        font-weight: 400;
        text-align: left;
      }

      .ErrorText {
        font-family: var(--typefamily--primary-stack);
        color: #d30018;
        font-size: 0.875rem;
        font-weight: 400;
        text-align: left;
      }
    }
  }
}
