.PCOM {
  .primary-button-container {
    background-color: var(--color--reskin--amber);
    color: var(--color--reskin--pearson-purple);
    border: 2px solid var(--color--reskin--amber);
    border-radius: 40px;
    font-size: 1rem;
    cursor: pointer;
    font-family: var(--typefamily--primary-stack);
    font-weight: 600;
    padding: 6px 24px;
    align-items: center;
    white-space: nowrap;

    // &:hover {
    //   background-color: var(--color--interactive-primary-hover);
    //   color: var(--color--text-primary-inverse);
    //   border: 2px solid var(--color--interactive-primary-hover);
    //   transform: scale(1.03);
    //   transition: all 0.2s ease-in-out;
    // }

    // &:focus {
    //   border: 3px solid var(--interactive-focus-field, #2192fb);
    //   background-color: var(--color--interactive-primary);
    //   color: var(--color--text-link-inverse);
    // }

    // &:disabled {
    //   background: var(--interactive-01-disabled, #e6e6e6);
    //   border: 2px solid var(--interactive-01-disabled, #e6e6e6);
    //   color: var(--interactive-03-disabled, #767676);
    //   cursor: not-allowed;
    // }
  }

  .secondary-button-container {
    background: var(--interactive-interactive-secondary, #05112a);
    color: var(--text-primary-inverse, #fefefe);
    border-radius: 40px;
    border: 2px solid var(--interactive-interactive-secondary, #05112a);
    font-size: 1rem;
    cursor: pointer;
    font-family: var(--typefamily--primary-stack);
    font-weight: 700;
    padding: 6px 24px;
    //  height: 48px;
    align-items: center;

    &:hover {
      background-color: var(--interactive-secondary-active, #505759);
      color: var(--text-primary-inverse, #fefefe);
      border: 2px solid var(--interactive-secondary-active, #505759);
      transform: scale(1.03);
      transition: all 0.2s ease-in-out;
    }

    &:focus {
      border: 3px solid var(--interactive-focus-field, #2192fb);
      background-color: var(--interactive-secondary-active, #505759);
      color: var(--text-primary-inverse, #fefefe);
    }

    &:disabled {
      background: var(--interactive-01-disabled, #e6e6e6);
      border: 2px solid var(--interactive-01-disabled, #e6e6e6);
      color: var(--interactive-03-disabled, #767676);
      cursor: not-allowed;
    }
  }

  .action-button-container {
    background-color: var(--color--interactive-action);
    color: var(--color--text-primary);
    border-radius: 40px;
    border: 2px solid var(--color--interactive-action);
    font-size: 1rem;
    cursor: pointer;
    font-family: var(--typefamily--primary-stack);
    font-weight: 700;
    padding: 14px 24px;
    // height: 56px;
    align-items: center;

    &:hover {
      background-color: var(--color--interactive-action-hover);
      color: var(--color--text-primary-inverse);
      border: 2px solid var(--color--interactive-action-hover);
      transform: scale(1.03);
      transition: all 0.2s ease-in-out;
    }

    &:focus {
      border: 3px solid var(--interactive-focus-field, #2192fb);
      background-color: var(--color--tertiary-action);
      color: #151515;
    }

    &:disabled {
      background: var(--color--tertiary-action);
      // background: var(--interactive-01-disabled, #e6e6e6);
      border: 2px solid var(--interactive-01-disabled, #e6e6e6);
      color: #919191;
      cursor: not-allowed;
    }
  }

  .tertiary-button-container {
    display: inline-block;
    background-color: var(--color--tertiary-action);
    color: var(--color-text-primary, #151515);
    border-radius: 40px;
    border: 2px solid var(--interactive-tertiary, #151515);
    font-size: 1rem;
    cursor: pointer;
    font-family: var(--typefamily--primary-stack);
    font-weight: 700;
    padding: 6px 24px;
    //  height: 48px;
    align-items: center;

    &:hover {
      background-color: #151515;
      border: 2px solid #151515;
      color: var(--color--text-primary-inverse);
      transform: scale(1.03);
      transition: all 0.2s ease-in-out;
    }

    &:focus {
      border: 3px solid var(--interactive-focus-field, #2192fb);
      background-color: var(--color--tertiary-action);
      color: #151515;
    }

    &:disabled {
      background: var(--interactive-01-disabled, #e6e6e6);
      border: 2px solid var(--interactive-01-disabled, #e6e6e6);
      color: var(--interactive-03-disabled, #767676);
      cursor: not-allowed;
    }
  }
}
