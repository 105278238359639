.use-button-effect-inner-ripple {
  a {
    .effects {
      display: block;
      position: absolute;
      z-index: -1;
      inset: 0;
      transition: transform .125s ease-in-out;
      clip-path: inset(0 round var(--button-corners));
      will-change: width, heigth;

      &:before,
      &:after {
        content: "";
        position: absolute;
        z-index: -1;
        inset: 0;
        left: -4px;
        top: 50%;
        transform: scale(1) translate(-50%, -50%);
        // width is defined individually below
        height: auto;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        opacity: 0;
        box-shadow: 0 0 0 2px #F00 inset, 0 0 0 2px #F00;
      }

      &:before {
        transition: all 1.8s .10s cubic-bezier(.12,.76,.67,1); // Re-defining scale transition for ripple effect
        width: 275%;
      }

      &:after {
        transition: all 1.8s .35s cubic-bezier(.12,.76,.67,1); // Re-defining scale transition for ripple effect
        width: 200%;
      }
    }

    &:hover {
      .effects {
        transform: scale(1.05);
      }
    }

    &:active {
      .effects {
        transform: scale(1);

        &:before,
        &:after {
          opacity: 1;
          width: 0;
          height: 0;
          transition: 0s;
        }
      }
    }
  }

  &.use-button-primary {
    a {
      .effects {
        &::before,
        &::after {
          box-shadow: 0 0 0 3px var(--color--ui-background-light);
        }
      }
      &:hover {
        .effects {
          &::before,
          &::after {
            box-shadow: 0 0 0 3px var(--color--ui-background-light);
          }
        }
      }
    }
  }
  &.use-button-primary-inverse {
    a {
      .effects {
        &::before,
        &::after {
          box-shadow: 0 0 0 3px var(--color--ui-background-light);
        }
      }
      &:hover {
        .effects {
          &::before,
          &::after {
            box-shadow: 0 0 0 3px var(--color--ui-background-light);
          }
        }
      }
    }
  }
  &.use-button-secondary {
    a {
      .effects {
        &::before,
        &::after {
          box-shadow: 0 0 0 3px var(--color--ui-background-light);
        }
      }
      &:hover {
        .effects {
          &::before,
          &::after {
            box-shadow: 0 0 0 3px var(--color--ui-background-light);
          }
        }
      }
    }
  }
  &.use-button-secondary-inverse {
    a {
      .effects {
        &::before,
        &::after {
          box-shadow: 0 0 0 3px var(--color--ui-background-dark);
        }
      }
      &:hover {
        .effects {
          &::before,
          &::after {
            box-shadow: 0 0 0 3px var(--color--ui-background-dark);
          }
        }
      }
    }
  }
  &.use-button-tertiary {
    a {
      .effects {
        &::before,
        &::after {
          box-shadow: 0 0 0 3px var(--color--ui-background-dark);
        }
      }
      &:hover {
        .effects {
          &::before,
          &::after {
            box-shadow: 0 0 0 3px var(--color--ui-background-light);
          }
        }
      }
    }
  }
  &.use-button-tertiary-inverse {
    a {
      .effects {
        &::before,
        &::after {
          box-shadow: 0 0 0 3px var(--color--ui-background-light);
        }
      }
      &:hover {
        .effects {
          &::before,
          &::after {
            box-shadow: 0 0 0 3px var(--color--ui-background-dark);
          }
        }
      }
    }
  }
  &.use-button-action {
    a {
      .effects {
        &::before,
        &::after {
          box-shadow: 0 0 0 3px var(--color--ui-background-dark);
        }
      }
      &:hover {
        .effects {
          &::before,
          &::after {
            box-shadow: 0 0 0 3px var(--color--ui-background-light);
          }
        }
      }
    }
  }
  &.use-button-action-inverse {
    a {
      .effects {
        &::before,
        &::after {
          box-shadow: 0 0 0 3px var(--color--ui-background-dark);
        }
      }
      &:hover {
        .effects {
          &::before,
          &::after {
            box-shadow: 0 0 0 3px var(--color--ui-background-dark);
          }
        }
      }
    }
  }
}
