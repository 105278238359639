.PCOM-GANKED {
  .use-button-effect-disable-zoom-on-hover {

    &.use-button-primary:not(div):hover::before,
    &.use-button-primary a:not([name]):not([id]):hover::before,
    &.use-button-primary button:hover::before,
    &.use-button-primary:not(div):focus::before,
    &.use-button-primary a:not([name]):not([id]):focus::before,
    &.use-button-primary button:focus::before,
    &.use-button-primary:not(div):focus-visible::before,
    &.use-button-primary a:not([name]):not([id]):focus-visible::before,
    &.use-button-primary button:focus-visible::before,
    &.use-button-primary:not(div):active::before,
    &.use-button-primary a:not([name]):not([id]):active::before,
    &.use-button-primary button:active::before,

    &.use-button-primary-inverse:not(div):hover::before,
    &.use-button-primary-inverse a:not([name]):not([id]):hover::before,
    &.use-button-primary-inverse button:hover::before,
    &.use-button-primary-inverse:not(div):focus::before,
    &.use-button-primary-inverse a:not([name]):not([id]):focus::before,
    &.use-button-primary-inverse button:focus::before,
    &.use-button-primary-inverse:not(div):focus-visible::before,
    &.use-button-primary-inverse a:not([name]):not([id]):focus-visible::before,
    &.use-button-primary-inverse button:focus-visible::before,
    &.use-button-primary-inverse:not(div):active::before,
    &.use-button-primary-inverse a:not([name]):not([id]):active::before,
    &.use-button-primary-inverse button:active::before,

    &.use-button-secondary:not(div):hover::before,
    &.use-button-secondary a:not([name]):not([id]):hover::before,
    &.use-button-secondary button:hover::before,
    &.use-button-secondary:not(div):focus::before,
    &.use-button-secondary a:not([name]):not([id]):focus::before,
    &.use-button-secondary button:focus::before,
    &.use-button-secondary:not(div):focus-visible::before,
    &.use-button-secondary a:not([name]):not([id]):focus-visible::before,
    &.use-button-secondary button:focus-visible::before,
    &.use-button-secondary:not(div):active::before,
    &.use-button-secondary a:not([name]):not([id]):active::before,
    &.use-button-secondary button:active::before,

    &.use-button-secondary-inverse:not(div):hover::before,
    &.use-button-secondary-inverse a:not([name]):not([id]):hover::before,
    &.use-button-secondary-inverse button:hover::before,
    &.use-button-secondary-inverse:not(div):focus::before,
    &.use-button-secondary-inverse a:not([name]):not([id]):focus::before,
    &.use-button-secondary-inverse button:focus::before,
    &.use-button-secondary-inverse:not(div):focus-visible::before,
    &.use-button-secondary-inverse a:not([name]):not([id]):focus-visible::before,
    &.use-button-secondary-inverse button:focus-visible::before,
    &.use-button-secondary-inverse:not(div):active::before,
    &.use-button-secondary-inverse a:not([name]):not([id]):active::before,
    &.use-button-secondary-inverse button:active::before,

    &.use-button-tertiary:not(div):hover::before,
    &.use-button-tertiary a:not([name]):not([id]):hover::before,
    &.use-button-tertiary button:hover::before,
    &.use-button-tertiary:not(div):focus::before,
    &.use-button-tertiary a:not([name]):not([id]):focus::before,
    &.use-button-tertiary button:focus::before,
    &.use-button-tertiary:not(div):focus-visible::before,
    &.use-button-tertiary a:not([name]):not([id]):focus-visible::before,
    &.use-button-tertiary button:focus-visible::before,
    &.use-button-tertiary:not(div):active::before,
    &.use-button-tertiary a:not([name]):not([id]):active::before,
    &.use-button-tertiary button:active::before,

    &.use-button-tertiary-inverse:not(div):hover::before,
    &.use-button-tertiary-inverse a:not([name]):not([id]):hover::before,
    &.use-button-tertiary-inverse button:hover::before,
    &.use-button-tertiary-inverse:not(div):focus::before,
    &.use-button-tertiary-inverse a:not([name]):not([id]):focus::before,
    &.use-button-tertiary-inverse button:focus::before,
    &.use-button-tertiary-inverse:not(div):focus-visible::before,
    &.use-button-tertiary-inverse a:not([name]):not([id]):focus-visible::before,
    &.use-button-tertiary-inverse button:focus-visible::before,
    &.use-button-tertiary-inverse:not(div):active::before,
    &.use-button-tertiary-inverse a:not([name]):not([id]):active::before,
    &.use-button-tertiary-inverse button:active::before,

    &.use-button-action:not(div):hover::before,
    &.use-button-action a:not([name]):not([id]):hover::before,
    &.use-button-action button:hover::before,
    &.use-button-action:not(div):focus::before,
    &.use-button-action a:not([name]):not([id]):focus::before,
    &.use-button-action button:focus::before,
    &.use-button-action:not(div):focus-visible::before,
    &.use-button-action a:not([name]):not([id]):focus-visible::before,
    &.use-button-action button:focus-visible::before,
    &.use-button-action:not(div):active::before,
    &.use-button-action a:not([name]):not([id]):active::before,
    &.use-button-action button:active::before,

    &.use-button-action-inverse:not(div):hover::before,
    &.use-button-action-inverse a:not([name]):not([id]):hover::before,
    &.use-button-action-inverse button:hover::before,
    &.use-button-action-inverse:not(div):focus::before,
    &.use-button-action-inverse a:not([name]):not([id]):focus::before,
    &.use-button-action-inverse button:focus::before,
    &.use-button-action-inverse:not(div):focus-visible::before,
    &.use-button-action-inverse a:not([name]):not([id]):focus-visible::before,
    &.use-button-action-inverse button:focus-visible::before,
    &.use-button-action-inverse:not(div):active::before,
    &.use-button-action-inverse a:not([name]):not([id]):active::before,
    &.use-button-action-inverse button:active::before

    {
      transform: scale(1);
    }
  }
}

