.PCOM {
  .tab-container {
    margin: 10px 0;

    .tab-list {
      display: flex;
      flex-direction: row;

      .tab {
        width: 33.33%;
        height: 70px;
        align-items: center;
        align-self: flex-end;
        border-radius: 24px 24px 0px 0px;
        background: var(--color--text-secondary, #333);
        // background: var(--color--reskin--pearson-purple);
        color: var(--color--interactive-01-disabled);
        transition: background-color 0.3s;
        cursor: pointer;

        h1 {
          font-family: var(--typefamily--primary-stack);
          font-size: 1.5rem;
          font-weight: 400;
        }
        p {
          font-family: var(--typefamily--primary-stack);
          font-size: 0.875em;
          text-align: center;
          font-weight: 400;
          margin: 0;
          padding: 0;
        }
        &:first-child {
          height: 94px;
          padding-top: 24px;
          position: relative;
          &::before {
            height: 24px;
            content: "Best Value";
            color: var(--color-text-primary, #151515);
            text-transform: uppercase;
            background: var(--color--accent-03-primary);
            border-radius: 24px 24px 0px 0px;
            font-size: 1rem;
            font-weight: 700;
            width: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
          }
        }
        &:nth-child(2) {
          margin: 0 10px;
        }
      }
    }

    .react-tabs__tab--selected {
      // background-color: var(--color--ui-background-gray) !important;
      background-color: white !important;
      outline: none;
      h1,
      p {
        color: var(--color--text-primary, #151515) !important;
        font-weight: 600 !important;
      }
    }

    .tab-content {
      width: 100%;
      padding: 0 30px;
      border-radius: 0 0 8px 8px;
      background: var(--color--ui-02, #fefefe);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
      transition: background-color 0.3s;
      -webkit-border-radius: 0 0 8px 8px;
      -moz-border-radius: 0 0 8px 8px;
      -ms-border-radius: 0 0 8px 8px;
      -o-border-radius: 0 0 8px 8px;
    }

    .react-tabs__tab-panel--selected {
      //background-color: lightblue;
    }
  }

  .price-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 1.875rem 0;

    .bundle-container {
      flex-direction: column;
      width: 80%;
    }

    .button-container {
      margin-left: 2.8rem;
      align-self: baseline;
      display: flex;
      flex-direction: column;
      align-items: center;

      .selected-price {
        h1 {
          color: var(--color-text-primary, #151515);
          font-size: 1.875rem;
          font-weight: 400;
          margin: 0;
        }
        p {
          color: var(--interactive-disabled-interactive-03, #767676);
          font-size: 0.75rem;
          margin: 0;
        }
      }
    }
  }

  .detail-feature {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 10px 30px 10px;
    gap: 10rem;

    ul {
      text-align: left;

      h3 {
        font-family: var(--typefamily--primary-stack);
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.2;
        color: var(--color-text-primary, #151515);
      }
      li {
        color: var(--color-text-secondary, #333);
        font-size: 0.875rem;
        margin: 10px 0;

        .check-icon {
          vertical-align: middle;
          color: #9e007e;
          width: 16px;
          margin-right: 8px;
        }
      }
    }
  }

  .policy {
    color: var(--color-text-secondary, #333);
    font-size: 0.875rem;
    text-align: left;
    padding-bottom: 30px;
  }
}
