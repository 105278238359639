.PCOM {
  .summary-container {
    //max-width: 520px;
    height: fit-content;
    padding: 30px;
    border-radius: 8px;
    background: var(--primary-bkgd-white, #fefefe);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    color: var(--color-text-primary, #151515);
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      p {
        font-size: 1rem;
        font-weight: 400;
        span {
          color: var(--color-text-link, #007a9c);
          text-align: right;
          font-size: 0.875rem;
          font-weight: 600;
          margin: 0 20px;
          .edit-arrow {
            vertical-align: middle;
          }
        }
        &:first-child {
          padding-bottom: 10px;
        }
      }
    }
    .add-promo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: end;
      padding: 30px 0;
      margin: 30px 0;
      gap: 20px;
      border-top: 1px solid var(--ui-elements-border-01, #dfe1e1);
      border-bottom: 1px solid var(--ui-elements-border-01, #dfe1e1);

      p {
        font-size: 1rem;
        font-weight: 600;
      }

      .arrow {
        color: #151515;
        align-self: center;
      }
    }

    .total {
      margin-bottom: 20px;
      h4 {
        font-size: 1.125rem;
        font-weight: 600;
      }
      h3 {
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
  }
}

