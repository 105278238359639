.PCOM {
  .bundle-option-container {
    border-radius: 24px;
    border: 1px solid var(--ui-elements-border-01, #dfe1e1);
    background: var(--ui-elements-background-light, #fff);

    .header {
      border-radius: 22px 22px 0px 0px;
      background: var(--color--interactive-primary);
      color: var(--color-text-inverse-text-primary, #fefefe);
      text-align: center;
      font-size: 1rem;
      font-weight: 600;
      padding: 5px 0;
    }
    .option-info {
      h4 {
        padding: 20px;
        text-align: center;
        color: var(--color--text-primary, #333);
        font-size: 1.25rem;
        font-weight: 600;
        text-align: left;

        .capitalize {
          text-transform: capitalize;
        }
      }
      p {
        color: var(--color-text-secondary, #333);
      }
      .input-container {
        border: 1px solid red;
      }
    }
    &.radio-checked {
      // border: 2px solid var(--color--interactive-primary) !important;
      border: 2px solid var(--color--reskin--amethyst) !important;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);

      .header {
        background: var(--color--reskin--amethyst);
      }
    }
  }

  .regular-option-container {
    margin-top: 24px;
    border-radius: 24px;
    border: 1px solid var(--ui-elements-border-01, #dfe1e1);
    background: var(--ui-elements-background-light, #fff);

    .option-info {
      h4 {
        padding: 20px;
        text-align: center;
        color: var(--color--text-primary, #333);
        font-size: 1.25rem;
        font-weight: 600;
        text-align: left;

        .capitalize {
          text-transform: capitalize;
        }
      }
      p {
        color: var(--color-text-secondary, #333);
      }
    }
    &.radio-checked {
      border: 2px solid var(--color--interactive-primary) !important;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    }
  }
}
