div.metaTagsTabPanel div.cq-selection input {
  width: 205px !important
}

div.x-combo-list-inner {
  width: 498px !important
}

div.x-combo-list {
  width: 498px !important
}
