// === Squish on Click
.use-button-effect-squish-on-click {
  a {
    &:hover,
    &:hover:active {
        &:before {
          transform: scale(1.05) !important;
        }
    }
    &:active,
    &:focus:active {

      .effects {
        transform: scale(1);
      }

      &:before {
        transform: scale(1) !important;
      }
    }
  }
}
