.motion {
  &.primary-button-container {
    display: inline-flex;
    font-family: var(--typefamily-primary);
    font-weight: var(--typeweight--semibold);
    font-size: var(--typesize-default);
    line-height: var(--leading--1-5);
    text-align: center;
    white-space: normal;
    text-decoration: none;
    background-color: transparent;
    vertical-align: middle;
    border: 0;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    min-height: 2.25rem;
    padding: .375em calc(1.875em - 9px) .375rem 1.875rem;
    position: relative;
    z-index: 1;
    // color: var(--color--text-primary-inverse);
    color: var(--color--text-primary);
    white-space: nowrap;
    gap: .75rem;

    &:before{
      content: "";
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--button-corners);
      outline-width: 2px;
      outline-style: solid;
      outline-color: transparent;
      transition: all .125s ease-in-out;
      // background: var(--color--interactive-primary-inverse);
      background: var(--color--interactive-secondary-inverse);
    }

    &:hover {
      &:before {
        background: var(--color--interactive-secondary-inverse-hover);
        transform: scale(1.05);
      }
    }

    &:focus {
      &:before {
        outline-width: 0 !important;
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      border-radius: 4em;
      left: 0;
      top:0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all 0.5s;
      box-shadow: 0 0 10px 20px white;
    }

    &:active {
      &:before {
        transform: scale(1);
      }

      &:after {
        box-shadow: 0 0 0 0 white;
        position: absolute;
        border-radius: 4em;
        left: 0;
        top:0;
        opacity: 1;
        transition: 0s;
      }
    }


    &.signin {
      margin-left: 1rem;
    }

    /* Animated Icons */
    .icon-arrow {
      --arrowSpacing: 5px;
      --arrowHoverTransition: 150ms cubic-bezier(0.215,0.61,0.355,1);
      --arrowHoverOffset: translateX(9px);
      --arrowTipTransform: none;
      --arrowLineOpacity: 0;
      position: relative;
      top: 1px;
      // margin-left: var(--arrowSpacing);
      fill: currentColor;
      stroke: none;

      .icon-arrow__line-path {
        opacity: var(--arrowLineOpacity);
        transition: opacity var(--hoverTransition,var(--arrowHoverTransition));
      }

      .icon-arrow__tip-path {
        transform: var(--arrowTipTransform);
        transition: transform var(--hoverTransition,var(--arrowHoverTransition));
      }
    }

    &:hover {
      .icon-arrow {
        .icon-arrow__line-path {
          --arrowLineOpacity: 1;
        }

        .icon-arrow__tip-path {
          --arrowTipTransform: var(--arrowHoverOffset);
        }
      }
    }
  }

}

