#TopStrapNav {
  // border: 3px solid red;
  // position: sticky;
  // top: 0;
  // z-index: 1000;

  margin: 0;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background: var(--color--reskin--pearson-black);

  ul.lines-of-business-nav {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 30px;
    list-style: none;

    li {
      margin: 0;
      padding: 0;

      a {
        display: block;
        margin: 0;
        padding: 10px 5px 9px;
        font-size: 12px;
        line-height: 150%;
        font-weight: 600;
        text-decoration: none;
        text-transform: uppercase;
        color: var(--color--reskin--light-purple);
        border-bottom: 3px solid transparent;

        &:hover,
        &.active {
          border-bottom-color: var(--color--reskin--amber);
          color: var(--color--text-primary-inverse);
        }
      }
    }
  }


  .locale-selection {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    z-index: 5;
    box-sizing: border-box;

    > div {
      position: relative;
      height: 100%;
    }

    .locale-selection__button.selected_en-US {
      display: flex;
      align-items: center;
      background: transparent;
      border: 1.5px solid transparent;
      color: var(--color--reskin--light-purple);
      font-family: var(--typefamily-primary);
      font-size: var(--typesize-default);
      line-height: var(--leading--1-5);
      padding: 0;
      position: relative;
      height: 100%;

      &:before {
        margin-right: 5px;
        display: inline-block;
        line-height: inherit;
        content: "";
        flex: none;
        cursor: inherit;
        background-color: transparent;
        width: 24px;
        height: 24px;
        background-size: 40px 24px;
        background-position: center;
        border-radius: 50%;
        background-image: url('_ganked-css/highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-flags.svg#icon-flag-us');
      }

      &:after {
        margin-left: var(--spacing-5);
        display: inline-block;
        line-height: inherit;
        content: "";
        flex: none;
        mask-size: 100%;
        mask-repeat: no-repeat;
        mask-position: center;
        background: currentColor;
        width: 16px;
        height: 16px;
        mask-image: url('_ganked-css/highered-reimagined/themes/platform/vanillaCake/assets/icons/sprites/sprite-controls.svg#icon-expand-sm');
        transform: rotate(var(--locale-selector-arrow-rotate, 0));
        transition: var(--he-transition);
      }
    }

  }
}
