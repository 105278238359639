.PCOM-GANKED {

  // PCOM-GANKED .use-button-primary-inverse.use-button-primary-inverse a:not([name]):not([id])::before
  // Home Page Overrides
  .OVERRIDE-HOME-DiSaBlEd {
    // box-shadow: 10px 10px 10px 0 red !important;
    &:after{
      // box-shadow: 10px 10px 10px 0 red !important;
    }

    &.use-button-primary-inverse.use-button-primary-inverse a,
    &.primarybtn {
      color: var(--color--text-primary) !important;
      &:hover {
        color: var(--color--text-primary-inverse) !important;
      }
    }

    &.content-tile.content-tile-panel-item-main {
      border-radius: 24px;
    }

    &.card-button {

      &:before {
        background-color: var(--color--reskin--amethyst) !important
      }
    }

    &.corners-semiround-md {
      border-radius: 24px;
    }
  }

  .OVERRIDE-COLLEGE-DiSaBlEd {
    &.content-tile.content-tile-panel-item-main,
    &.corners-semiround-md,
    &.add24borders {
      border-radius: 24px;
    }

    &.primarybtn {
      color: var(--color--text-primary) !important;

      &:hover {
        color: var(--color--text-primary-inverse) !important;
      }
    }

    &.content-tile.content-tile-panel-item-main {
      border-radius: 24PX;
    }
  }

  .OVERRIDE-STUDENTS-DiSaBlEd {
    @media (min-width: 1920px) {
      &.column-control--bg.bg1396052241.column-control--mask-dark-solid {
        clip-path: none !important
      }
    }
    @media (min-width: 376px) and (max-width: 1919px) {
      &.column-control--bg.bg1396052241.column-control--mask-dark-solid {
        clip-path: none !important
      }
    }


    &.primarybtn {
      color: var(--color--text-primary) !important;

      &:hover {
        color: var(--color--text-primary-inverse) !important;
      }
    }

    &.content-tile.content-tile-panel-item-main {
      border-radius: 24px;
    }

    &.c-collection__banner {
      border-radius: 24px;
    }

    &.content-tile.add24borders {
      border-radius: 24px;
    }
  }
}

.PCOM {
  .OVERRIDE-PDP-DiSaBlEd {
    &.selected-price {
      .action-button-container {
        background-color: var(--color--reskin--turquoise);
        border: 2px solid var(--color--reskin--turquoise);
        color: var(--color--text-primary);

        &:hover {
          background-color: var(--color--reskin--fuchsia);
          border-color: var(--color--reskin--fuchsia);
          color: var(--color--text-primary-inverse);
        }
      }
    }
  }
}
