/**
 * Do not edit directly
 * Version: v1.1.5
 * Generated on Wed, 08 Jan 2025 02:22:59 GMT
 */

:root {
  --master-tokens-version: 'v1.1.5';
  --color--accent-01-dark: #7b1571ff;
  --color--accent-01-light: #ec8de2ff;
  --color--accent-01-medium: #b820a9ff;
  --color--accent-01-primary: #df41cfff;
  --color--accent-02-dark: #7f6701ff;
  --color--accent-02-light: #ffe266ff;
  --color--accent-02-medium: #bf9a00ff;
  --color--accent-02-primary: #ffce00ff;
  --color--accent-03-dark: #168584ff;
  --color--accent-03-light: #9aeeedff;
  --color--accent-03-medium: #22c7c6ff;
  --color--accent-03-primary: #56e2e1ff;
  --color--alert-background: #f9f9fcff;
  --color--alert-error-inverse: #ff362aff;
  --color--alert-error: #d0281fff;
  --color--alert-information-inverse: #fcfcfeff;
  --color--alert-information: #060609ff;
  --color--alert-success-inverse: #00b057ff;
  --color--alert-success: #009147ff;
  --color--alert-warning-inverse: #ff8b21ff;
  --color--alert-warning: #fc630fff;
  --color--icon-accent-01: #572cb1ff;
  --color--icon-link-active: #290066ff;
  --color--icon-link-hover: #290066ff;
  --color--icon-link-inverse-active: #b79bfeff;
  --color--icon-link-inverse-hover: #b79bfeff;
  --color--icon-link-inverse: #b79bfeff;
  --color--icon-link: #572cb1ff;
  --color--icon-primary-inverse: #fcfcfeff;
  --color--icon-primary: #060609ff;
  --color--icon-quaternary: #bcb9ccff;
  --color--icon-secondary-inverse: #f9f9fcff;
  --color--icon-secondary: #211f28ff;
  --color--icon-tertiary-inverse: #f0eff5ff;
  --color--icon-tertiary: #656270ff;
  --color--interactive-01-disabled: #e8e7efff;
  --color--interactive-02-disabled: #bcb9ccff;
  --color--interactive-03-disabled: #8e8b9dff;
  --color--interactive-accent-03-primary-active: #56e2e1ff;
  --color--interactive-accent-03-primary-hover: #56e2e1ff;
  --color--interactive-accent-03-primary: #22c7c6ff;
  --color--interactive-action-active: #ffce00ff;
  --color--interactive-action-hover: #05001cff;
  --color--interactive-action-inverse-active: #feeb99ff;
  --color--interactive-action-inverse-focus: #ffffffff;
  --color--interactive-action-inverse-hover: #feeb99ff;
  --color--interactive-action-inverse: #ffce00ff;
  --color--interactive-action: #ffce00ff;
  --color--interactive-focus-field: #2192fbff;
  --color--interactive-focus: #4fa8ffff;
  --color--interactive-primary-active: #0d004dff;
  --color--interactive-primary-hover: #0d004dff;
  --color--interactive-primary-inverse-active: #8b8cd1ff;
  --color--interactive-primary-inverse-hover: #8b8cd1ff;
  --color--interactive-primary-inverse: #572cb1ff;
  --color--interactive-primary: #572cb1ff;
  --color--interactive-secondary-active: #05001cff;
  --color--interactive-secondary-hover: #05001cff;
  --color--interactive-secondary-inverse-active: #e0dffcff;
  --color--interactive-secondary-inverse-hover: #d6d5fbff;
  --color--interactive-secondary-inverse: #ffffffff;
  --color--interactive-secondary: #0d004dff;
  --color--interactive-tertiary-active: #05001cff;
  --color--interactive-tertiary-hover: #05001cff;
  --color--interactive-tertiary-inverse-active: #ffffffff;
  --color--interactive-tertiary-inverse-hover: #ffffffff;
  --color--interactive-tertiary-inverse: #ffffffff;
  --color--interactive-tertiary: #05001cff;
  --color--label-interactive: #f0eff5ff;
  --color--label-non-interactive: #e8e7efff;
  --color--text-brand-01: #0d004dff;
  --color--text-brand-02: #512eabff;
  --color--text-brand-03: #c1bfffff;
  --color--text-brand-04: #d6d5fbff;
  --color--text-link-active: #290066ff;
  --color--text-link-hover: #290066ff;
  --color--text-link-inverse-active: #b79bfeff;
  --color--text-link-inverse-hover: #b79bfeff;
  --color--text-link-inverse: #b79bfeff;
  --color--text-link: #572cb1ff;
  --color--text-primary-inverse: #fcfcfeff;
  --color--text-primary: #060609ff;
  --color--text-quaternary-inverse: #cfcddbff;
  --color--text-quaternary: #bcb9ccff;
  --color--text-secondary-inverse: #f9f9fcff;
  --color--text-secondary: #211f28ff;
  --color--text-tertiary-inverse: #f0eff5ff;
  --color--text-tertiary: #656270ff;
  --color--tint-accent-01-medium-03: #572cb10a;
  --color--ui-01: #f0eff5ff;
  --color--ui-02: #fcfcfeff;
  --color--ui-03: #060609ff;
  --color--ui-04: #656270ff;
  --color--ui-05: #bcb9ccff;
  --color--ui-06: #e1dfeaff;
  --color--ui-background-brand-00: #05001cff;
  --color--ui-background-brand-01: #0d004dff;
  --color--ui-background-brand-02: #512eabff;
  --color--ui-background-brand-03: #c1bfffff;
  --color--ui-background-brand-04: #d6d5fbff;
  --color--ui-background-dark-gray: #5b5ea3ff;
  --color--ui-background-dark: #0d004dff;
  --color--ui-background-gray: #fbfaffff;
  --color--ui-background-light: #ffffffff;
  --color--ui-background-med-gray: #e0dffcff;
  --color--ui-background-med: #f5f4feff;
  --color--ui-background-neutral: #f9f9fcff;
  --color--ui-background-sp-a-50: #211f2880;
  --color--ui-background-sp-a-70: #211f28bf;
  --color--ui-border-01: #dad8e3ff;
  --color--ui-border-02: #060609ff;
  --color--ui-border-03: #bcb9ccff;
  --color--ui-field-alt: #fcfcfc59;
  --color--ui-field-control-background: #f2f2f2ff;
  --color--ui-field-control: #474747ff;
  --color--ui-field: #ffffffff;
  --color--ui-overlay-brand-dark: #0d004db3;
  --color--ui-overlay-brand-light: #d6d5fb80;
  --color--ui-overlay-dark: #060609b3;
  --color--ui-overlay-light: #65627080;
  --effect--shadow-brand-dark-01: 0px 2px 4px 0px #05001cff;
  --effect--shadow-brand-dark-02: 0px 6px 8px 0px #05001cff;
  --effect--shadow-brand-light-01: 0px 2px 4px 0px #c0beffff;
  --effect--shadow-brand-light-02: 0px 6px 12px 0px #c0beffff;
  --effect--shadow-brand-light-03: 16px 16px 16px 0px #c0beffff;
  --effect--shadow-neutral-01: 0px 2px 4px 0px #00000040;
  --effect--shadow-neutral-02: 0px 0px 20px 0px #00000026;
  --gradient--interactive-accent-01: linear-gradient(90.00deg, #0d004dff 0%, #512eabff 50%, #c1bfffff 100%);
  --gradient--interactive-fill-a-01: linear-gradient(180.00deg, #f2f3ffff 48%, #512eabff 100%);
  --gradient--interactive-fill-a-02: linear-gradient(180.00deg, #f2f3ffff 49%, #dcd8f4ff 62%, #bcb1e3ff 74%, #512eabff 100%);
  --gradient--ui-background-block-fade: linear-gradient(180.00deg, #fbfaffff 0%, #fbfaff00 100%);
  --gradient--ui-background-inline-fade: linear-gradient(60.97deg, #fafafaff 0%, #ffffff00 68%);
  --gradient--ui-dark: linear-gradient(125.83deg, #00000040 0%, #0000001d 41%, #0000001d 56.00000000000001%, #00000040 100%);
  --gradient--ui-light-on-dark: linear-gradient(43.98deg, #cccccc40 0%, #f2f2f21d 41%, #f2f2f21d 56.00000000000001%, #cccccc40 100%);
  --gradient--ui-light: linear-gradient(313.98deg, #99999940 0%, #f2f2f240 41%, #f2f2f240 56.00000000000001%, #99999940 100%);
}
